import React, { useState, useEffect } from "react";

import {
  Button,
  Row,
  Card,
  Col,
  Form,
  message,
  Input,
  Radio,
  Upload,
  Select,
  Table,
  Modal,
  Layout,
  Popconfirm,
  Switch,
  Image,
} from "antd";
import {
  PlusCircleFilled,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
//import "./Cartypes.scss";
import excel from "../assets/vector-icons/excel.png";
//import pdf from "../assets/vector-icons/pdf.png";
// import word from "../assets/vector-icons/word.png";
import search from "../assets/vector-icons/search.png";
//import exchange from "../assets/vector-icons/exchange.png";

//import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import ApiClient from "../../helpers/ApiClient";
//import { identity } from "@antv/util";
const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";
const ActivitiesImageUpload = ({
  category,
  fileList,
  setFileList,
  formName,
  limit,
  limitSize,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );
  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "application/pdf" ||
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    const checkFileSize = file.size < 1126400;
    if (!checkFileSize) {
      message.error(" Image must be smaller than 1Mb!");
    }

    return checkJpgOrPng && checkFileSize;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      setIsUploading(true);

      return;
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
    }
    if (info.file.status === "error") {
      setIsUploading(false);

      return;
    }
    if (info.file.status === "done") {
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item name={formName}>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          // onRemove={(val) => deleteBanner(val.uid)}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList
              ? fileList.length >= limitSize
                ? null
                : uploadLogoButton
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        destroyOnClose={true}
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

const Carsmapping = () => {
  const [form] = Form.useForm();
  const { Content } = Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const [carImage, setCarIamge] = useState([]);
  const [carType, setCarType] = useState([]);
  const [formLayout, setFormLayout] = useState("vertical");
  const [previewCarImage, setPreviewCarImage] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [previewCarVisible, setPeviewCarVisible] = useState(false);
  const handleCancel = () => {
    setPeviewCarVisible(false);
  };
  //const [imgUrl, setImgUrl] = useState();

  const [loading, setLoading] = useState(false);
  const [carRentals, setCarRentals] = useState([]);

  const [updateCarId, setupdateCarId] = useState([]);
  const updateRecord = (currentData, carID) => {
    setupdateCarId(carID);
    setEditMode(true);
    const list = [];
    form.setFieldsValue({
      ...currentData,
      CarTypes: currentData.carType.CarTypeID,
      ModelName: currentData.ModelName,
      ModelYear: currentData.ModelYear,
      CarName: currentData.CarName,
      FuelType: currentData.FuelType,
      ChasisNumber: currentData.ChasisNumber,
      CarImages: currentData.CarImages,
      SeatCapacity: currentData.SeatCapacity,
      LuggageCapacity: currentData.LuggageCapacity,
      ACAvailable: currentData.ACAvailable,
      HowManyDoors: currentData.HowManyDoors,
      GPSAvailable: currentData.GPSAvailable,
      extraAmenities: currentData.extraAmenities,
      OtherInfo: currentData.OtherInfo,
    });

    list.push({
      name: "slide.jpg",
      status: "done",
      url: BASE + currentData.CarImages.substring(1),
      response: {
        status: 200,
        data: {
          filepaths: [BASE + currentData.CarImages],
        },
      },
    });

    setCarIamge(list);

    setModalVisible(true);
  };
  const showModal = () => {
    form.resetFields();
    setModalVisible(true);
  };
  useEffect(() => {
    getCarRentalList();
    getCarTypes();
  }, []);
  const getCarRentalList = () => {
    ApiClient.get("carextranet/getallcarrentals")
      .then((res) => {
        // console.log(res,"resp")
        if (res.status === 200) {
          // console.log(res, "resp")
          let data = res?.data?.map((item, index) => {
            return {
              ...item,
              Sno: index + 1,
              CarId: item?.CarID ?? "",
              ModelName: item?.ModelName ?? "",
              ModelType: item?.carType?.CarType ?? "",
              FuelTypeText:
                item.FuelType === 0
                  ? "Petrol"
                  : item.FuelType === 1
                    ? "Diesel"
                    : "EV",
              ACAvailableText: item.ACAvailable === true ? "Yes" : "No",
              LuggageCapacity: item?.LuggageCapacity ?? "",
              ChasisNumber: item?.ChasisNumber ?? "",
              SeatCapacity: item?.SeatCapacity ?? "",
              OtherInfo: item?.OtherInfo ?? "",
              GPSAvailableText: item?.GPSAvailable === true ? "Yes" : "No",
              extraAmenities: item?.extraAmenities ?? "",
              HowManyDoors: item?.HowManyDoors ?? "",
            };
          });

          // console.log(data, "data")
          setCarRentals(data);
        } else {
        }
      })
      .catch((error) => { });
  };
  const getCarTypes = () => {
    ApiClient.get("carextranet/getallcartype")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          setCarType(data);
          
        } else {
        }
      })
      .catch((error) => { });
  };
  const onFinish = (values) => {
    let carPath = "";
    if (carImage.length > 0) {
      carPath = carImage[0].response.data.filepaths[0];
    }
    let data = {
      CarTypeID: values.CarTypes,
      ModelName: values.ModelName,
      ModelYear: values.ModelYear,
      FuelType: values.FuelType,
      ChasisNumber: values.ChasisNumber,
      CarName: values.CarName,
      CarImages: carPath ? carPath : "",
      SeatCapacity: values.SeatCapacity,
      LuggageCapacity: values.LuggageCapacity,
      ACAvailable: values.ACAvailable,
      HowManyDoors: values.HowManyDoors,
      GPSAvailable: values.GPSAvailable,
      extraAmenities: values.extraAmenities,
      OtherInfo: values.OtherInfo,
    };
    if (editMode) {
      return updateCarRecord(data);
    }
    return addNew(data);
  };

  const updateCarRecord = (data) => {
    ApiClient.put(`carextranet/updatecarrentalbyid/${updateCarId}`, {}, data)
      .then((response) => {
        if (response.status === 200) {
          message.success(response.message, 3);
        } else {
          message.error(response.message, 3);
        }
        setModalVisible(false);
        getCarRentalList();
      })
      .catch((error) => { });
  };
  const addNew = (data) => {
    ApiClient.post("carextranet/createcarrentalbyid", data)
      .then((response) => {
        if (response.status === 200) {
          message.success(response.message, 3);
        } else {
          message.error(response.message, 3);
        }
        setModalVisible(false);
        getCarRentalList();
      })
      .catch((error) => { });
  };

  const tableColumns = [
    {
      title: "Sno.",
      dataIndex: "Sno",
    },
    {
      title: "Model Name",
      dataIndex: "ModelName",
    },
    {
      title: "Car Name",
      dataIndex: "CarName",
    },
    {
      title: "Model Year",
      dataIndex: "ModelYear",
    },
    {
      title: "Fuel Type",
      dataIndex: "FuelTypeText",
    },
    {
      title: "Chasis Number",
      dataIndex: "ChasisNumber",
    },
    {
      title: "Seat Capacity",
      dataIndex: "SeatCapacity",
    },
    {
      title: "Luggage Capacity(kg)",
      dataIndex: "LuggageCapacity",
    },
    {
      title: "ACAvailable",
      dataIndex: "ACAvailableText",
    },
    {
      title: "GPSAvailable",
      dataIndex: "GPSAvailableText",
    },
    {
      title: "Model Type",
      dataIndex: "ModelType",
    },
    {
      title: "Extra Amenities",
      dataIndex: "extraAmenities",
    },
    {
      title: "Other Info",
      dataIndex: "OtherInfo",
    },
    {
      title: "Car Image",
      render: (rec) => CarImageView(rec),
    },
    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => tableActions(rec),
    },
  ];
  const handelStatus = (data) => {
    if (data.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };



  const changeStatus = (info) => {
    ApiClient.put("carextranet/toggleCarRentalStatus/" + info.CarID)
      .then((response) => {
        if (response.status === 200) {
          message.success("Car Status updated successfully !");

          getCarTypes();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => { });
  };

  const showCarImage = (data) => {
    if (data) {
      setPreviewCarImage(data);
      setPeviewCarVisible(true);
    }
  };
  const CarImageView = (data) => {
    return (
      <div className="edit-icon" style={{ textAlign: "center" }}>
        <EyeOutlined
          onClick={() => showCarImage(data.CarImages)}
          style={{ color: "red", fontSize: "20px", textAlign: "center" }}
        />
      </div>
    );
  };
  const tableActions = (data) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => updateRecord(data, data.CarId)}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteRecord(data.CarId)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const deleteRecord = (CarID) => {
    ApiClient.delete("carextranet/deletecarrental/" + CarID)
      .then((res) => {
        if (res.status === 200) {
          message.success("Deleted  successfully", 3);
          getCarRentalList();
        }
      })
      .catch((error) => { });
  };

  const { Option } = Select;
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 80 }} placeholder="+91">
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );

  const clear = () => {
    setModalVisible(false);
  };
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Cars Mapping{" "}
                          <HelpInfoHelper screenName={"/admin/carsmapping"} />
                        </h5>
                        <p>{carRentals.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />
                      <img src={excel} alt="excel" />
                      {/* <img src={pdf} alt="pdf" /> */}
                      <p className="add-deposit-icon" onClick={showModal}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>
                  {/* {// console.log(carRentals, "carRentals")} */}
                  {carRentals.length > 0 ? (
                    <div>
                      <Table
                        className="table-scroll-none"
                        bordered
                        dataSource={carRentals}
                        columns={tableColumns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  ) : (
                    <h5>No Records Found!</h5>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title="Cars Mapping"
        className="promo-modal-header modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button key="submit" type="danger" onClick={clear}>
              Cancel
            </Button>
            <Button key="add" type="primary" onClick={form.submit}>
              {editMode ? "Update" : "Add"}
            </Button>
          </div>,
        ]}
        width={"1100px"}
      >
        <Form
          layout={formLayout}
          initialValues={{ layout: formLayout }}
          name="basic"
          form={form}
          // initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col md={8} xs={24}>
              <Form.Item
                label="Car Types"
                rules={[{ required: true }]}
                name="CarTypes"
              >
                {/* <Select placeholder="Please select">
                  {carType.length > 0
                    ? carType
                    .filter(item => item.Status !== 0) // Filter out items with status 0
                    .map(item => (
                      <Option key={item.CarTypeID} value={item.CarTypeID}>
                        {item.CarType}
                      </Option>
                    ))
                    : null}
                </Select> */}
                <Select placeholder="Please select">
                  {carType.length > 0
                    ? carType
                      .filter(item => item.Status !== 0) // Filter out items with status 0
                      .map(item => (
                        <Option key={item.CarTypeID} value={item.CarTypeID}>
                          {item.CarType}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                name="ModelName"
                label="Model Name"
                rules={[
                  {
                    required: true,
                    message: "Please input your Model Name",
                  },
                ]}
              >
                <Input placeholder="Please Enter Your Model Name" />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label="Car Name"
                rules={[{ required: true }]}
                name="CarName"
              >
                <Input placeholder="Please Enter Your Car Name" />
              </Form.Item>
              {/* <Form.Item
                label="Model Year"
                rules={[{ required: true }]}
                name="ModelYear"
              >
                <Select placeholder="Please select">
                  <Option value="2015">2015 </Option>
                  <Option value="2016">2016</Option>
                  <Option value="2017">2017</Option>
                  <Option value="2018">2018</Option>
                  <Option value="2019">2019</Option>
                  <Option value="2020">2020</Option>
                </Select>
              </Form.Item> */}
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={8} xs={24}>
              <Form.Item
                label="Fuel Type"
                rules={[{ required: true }]}
                name="FuelType"
              >
                <Select placeholder="Please select Your Fuel Type">
                  <Option value={0}>Petrol </Option>
                  <Option value={1}>Diesel</Option>
                  <Option value={2}>EV</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label="Model Year"
                rules={[{ required: true }]}
                name="Model Year"
              >
                {/* <Select placeholder="Please select">
                  <Option value="2015">2015 </Option>
                  <Option value="2016">2016</Option>
                  <Option value="2017">2017</Option>
                  <Option value="2018">2018</Option>
                  <Option value="2019">2019</Option>
                  <Option value="2020">2020</Option>
                </Select> */}
                <Input />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                name="SeatCapacity"
                label="Seat Capacity"
                rules={[
                  {
                    // type: "Number",
                    required: true,
                    message: "Please Select Your Seat Capacity",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={8} xs={24}>
              <Form.Item
                name="LuggageCapacity"
                label="Luggage Capacity"
                rules={[
                  {
                    // type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={6} xs={24}>
              <Form.Item label="AC Type" name="ACAvailable">
                <Radio.Group>
                  <Radio value={true}>Ac</Radio>
                  <Radio value={false}>Non Ac</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item label="GPS Available " name="GPSAvailable">
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={8} xs={24}>
              <Form.Item
                label="How Many Doors"
                rules={[{ required: true }]}
                name="HowManyDoors"
              >
                <Select placeholder="Please select">
                  <Option value="0">2 </Option>
                  <Option value="1">3</Option>
                  <Option value="2">4</Option>
                  <Option value="3">5</Option>
                  <Option value="4">6</Option>
                  <Option value="5">7</Option>
                  {/* <Option value="6">8</Option>
                  <Option value="7">9</Option>
                  <Option value="8">10</Option> */}
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                name="extraAmenities"
                label="Extra Amenities(more than one)"
                rules={[
                  {
                    // type: "Number",
                    required: true,
                    // message: "Please input your Pincode",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                name="OtherInfo"
                label="Other Info"
                rules={[
                  {
                    // type: "Number",
                    required: true,
                    // message: "Please input your Pincode",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                name="ChasisNumber"
                label="Chasis Number"
                rules={[
                  {
                    // type: "Number",
                    required: true,
                    message: "Please Enter your Chasis Number",
                  },
                ]}
              >
                <Input placeholder="Please Enter Your Chasis Number" />
              </Form.Item>
            </Col>
            <Col xs={6} md={8}>
              <Form.Item
                label="CAR Upload"
                name="CarUpload"
              // onChange={setAdhar}
              >
                <ActivitiesImageUpload
                  category="car"
                  fileList={carImage}
                  setFileList={setCarIamge}
                  formName="CARUpload"
                  limit={true}
                  limitSize={1}
                // deleteBanner={DeleteBannerImage}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
            <Button type="primary" danger className="cancel-btn">
              Clear
            </Button>
          </Form.Item> */}
        </Form>
      </Modal>
      <Modal
        destroyOnClose={true}
        visible={previewCarVisible}
        title={null}
        footer={null}
        onCancel={handleCancel}
      >
        <div>
          {previewCarImage ? (
            <Image
              src={`${BASE}${previewCarImage.substring(1)}`}
              alt="imageCarPreview"
              style={{ width: "100%" }}
            />
          ) : (
            <p>No Image Found</p>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Carsmapping;
