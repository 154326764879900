import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import ApiClient from "../../../helpers/ApiClient";

const HotelBookingList = (props) => {
  //   console.log("hotel cancel props", props);
  const [referenceId, setReferenceId] = useState(null);

  useEffect(() => {
    fetchUserId();
  }, []);

  const fetchUserId = () => {
    ApiClient.get("admin/b2c/user/" + 70)
      .then((resp) => resp)
      .then((resp) => {
        //console.log("booking list resp", resp);
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  const handleCancelId = (e) => {
    setReferenceId(e.target.value);
  };

  const hotelCancellation = () => {
    ApiClient.get("hotels/cancelBooking/" + referenceId)
      .then((resp) => resp)
      .then((resp) => {
       // console.log("hotel cancel ticket resp", resp);
      })
      .catch((err) => {
       console.log(err);
        return err;
      });
  };

  return (
    <div>
      <ANTD.Input
        placeholder="Booking reference ID"
        onChange={handleCancelId}
      />
      <button onClick={hotelCancellation}>Hotel Cancel</button>
    </div>
  );
};

export default HotelBookingList;
