
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth,GoogleAuthProvider,FacebookAuthProvider} from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyDw_iNbr2yFGMSaHXdkj7EnaG97J9kr7W8",
  authDomain: "anjmal-mail.firebaseapp.com",
  projectId: "anjmal-mail",
  storageBucket: "anjmal-mail.appspot.com",
  messagingSenderId: "1006249635109",
  appId: "1:1006249635109:web:4979d51959799719bd7ecc",
  measurementId: "G-X03BJY0YRY"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app)
const provider = new GoogleAuthProvider();
const fbAuthProvider = new FacebookAuthProvider();
export {auth,provider,fbAuthProvider};