import React , { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import StarImg from "../../../assets/images/star-icon.png";
import moment from "moment";
import { getHotelPricce } from "../../AllTickets/Hotel/hotelhelper";
import hotelimage from '../../../assets/images/hotelimage.jpeg'

const styles = StyleSheet.create({
  page: {
    padding: 10,
  },
  section: {
    padding: "0 8px",
    color: "#555",
    background: "#FFF",
    border: "3px solid #D3D3D3",
  },
  header: {
    padding: "7px 0",
    marginBottom: "5px",
  },
  company: { width: "100%", marginBottom: "5px" },
  company_h: {
    textAlign: "right",
    fontSize: "6px",
    fontWeight: "normal",
    margin: 0,
    // color: "#E32025",
  },
  company_p: {
    textAlign: "right",
    fontSize: "8px",
    fontWeight: "normal",
    margin: 0,
    // color: "#E32025",
  },
  company_wrapper: {
    width: "100%",
    marginTop: "4px",
    borderTop: "1px solid #dcd9d9", // Add top border
    borderBottom: "1px solid #dcd9d9",
    borderRight: "1px solid #dcd9d9",
    borderLeft: "1px solid #dcd9d9",
  },
  company_wrapp: {
    width: "100%",
    marginTop: "4px",
    borderTop: "1px solid #dcd9d9",
    borderBottom: ".1px solid #dcd9d9",
    borderRight: ".1px solid #dcd9d9",
    borderLeft: ".1px solid #dcd9d9",
  },
  company_left: {
    width: "20%",
    padding: "3px 6px",
    // borderRight: "1px solid black",
  },
  Company_L: {
    width: "5%",
    borderLeft: "1px solid #dcd9d9",
  },
  Company_LL: {
    width: "1%",
    borderLeft: "1px solid #dcd9d9",
  },
  company_right: {
    width: "65%",
    padding: "3px 6px",
  },
  company_h: {
    fontSize: "6px",
    fontWeight: "normal",
    margin: 0,
    //color: "#E32025",
  },
  company_h: {
    fontSize: 6,
    fontWeight: "normal",
  },
  company_p: {
    fontSize: 8,
    fontWeight: "normal",
  },
  company_h: {
    textAlign: "center",
    fontSize: "6px",
    fontWeight: "normal",
    margin: 0,
    // color: "#E32025",
  },
  company_p: {
    textAlign: "right",
    fontSize: "8px",
    fontWeight: "normal",
    margin: 0,
    color: "#E32025",
  },
  invoice_h: {
    textAlign: "center",
    fontSize: "11px",
    fontWeight: "bold",
    margin: 0,
  },
  invoice: {
    width: "100%",
  },
  invoice_origin_h: {
    fontSize: "9px",
    fontWeight: "normal",
    width: "100%",
    marginBottom: 4,
    color: "#000",
  },
  origin: {
    width: "40%",
  },
  origin1: {
    width: "60%",
  },
  origin_title: { width: "30%", padding: "3px 6px" },
  origin_h: {
    fontSize: "8px",
    fontWeight: "bold",
  },
  origin_p: {
    fontSize: "7px",
    fontWeight: "normal",
  },
  origin_title1: { width: "40%", padding: "3px 6px" },
  origin_deatil1: { width: "50%", padding: "3px 6px" },
  table_p: {
    fontSize: "8px",
    fontWeight: "normal",
    marginBottom: 0,
  },
  origin_deatil: { width: "50%", padding: "3px 6px" },
  origin_deatil_right: { width: "50%" },
  origin_title_right: { width: "50%" },
  origin_deatil2: { width: "30%", padding: "3px 6px" },
  origin_deatil3: { width: "70%" },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 3,
    flexWrap: "wrap",
  },

  table_head: {
    width: "100%",
    color: "#000",
    //border: "1px solid #000",
  },
  table_des: {
    width: "30%",
    //  borderRight: "1px solid black",
    padding: "0 3px",
  },
  table_pnr: {
    width: "25%",
    // borderRight: "1px solid black",

    // padding: "0 3px",
  },
  table_pnr2: {
    width: "35%",
    // borderRight: "1px solid black",

    // padding: "0 3px",
  },
  table_pnr1: {
    width: "20%",
    //borderRight: "1px solid black",

    // padding: "0 3px",
  },

  
  borderBottom1: { borderBottom: "1px solid grey" },
  borderBottom: { borderBottom: "1px solid #dcd9d9" },
  table_p: {
    padding: "2px",
    fontSize: "7px",
    fontWeight: "bolder",
    color: "#000",
  },
  table_p1: {
    padding: "2px",
    fontSize: "7px",
    color: "#525151",
  },
  table_sub_index: {
    width: "10%",

    padding: "0 3px",
  },

  text_right: {
    textAlign: "right",
  },
  table_sub_wrapper: {},

  sub_table1: { marginTop: 10 },
  sub_table: {
    // border: "1px solid black",
    width: "60%",
    marginLeft: "auto",
  },

  m_0: {
    margin: 0,
  },
  p_0: {
    padding: 0,
  },
  sub_table_ph: {
    width: "40%",
    fontSize: "8px",
    fontWeight: "normal",
    padding: "3px 6px",
    // borderRight: "1px solid black",
  },
  boldText: {
    fontWeight: "bold",
    color: "#000",
  },
  sub_table_pt: {
    width: "40%",
    fontSize: "8px",
    fontWeight: "normal",
    padding: "3px 10px",
  },
  imgWrapper: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
});
const getTotalPrice = (data) => {
  var Total = data.subTotal;
  if (data?.convienenceData?.amount) {
    if (data.convienenceData.type === 1) {
      Total += data.convienenceData.amount * data.passengers.length;
    } else if (data.convienenceData.type === 0) {
      Total += (Total / 100) * data.convienenceData.amount;
    }
  }
  return Total;
};
const starIconStyle = {
  width: 10,
  height: 10,
  marginRight: 2,
};

const renderStarRating = (rating) => {
  const stars = [];
  for (let i = 0; i < rating; i++) {
    stars.push(<Image key={i} src={StarImg} style={starIconStyle} />);
  }
  return stars;
};

const getTax = (data) => {
  var Total = data.totalPrice;
  var subTotal = data.subTotal;
  var tax = Total - subTotal;
  return tax;
};
const HotelNewInvoiceDoc = ({ invoiceData, logo, type }) => {
  let {
    baseAmount,
    taxAmount,
    convienenceFee,
    discount,
    grandTotal,
    insuranceTotal,
    noOfNights,
  } = getHotelPricce(invoiceData);
  const getNumberOfNights = () => {
    const checkInDate = moment(invoiceData.CheckInDate);
    const checkOutDate = moment(invoiceData.CheckOutDate);
    const numberOfNights = checkOutDate.diff(checkInDate, "days");
    return numberOfNights;
  };

  const [imageData, setImageData] = useState(null);
  const imageUrl = invoiceData.hotelImage
  const proxyUrl = `https://corsproxy.io/?`;
  
  fetch(imageUrl)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob();
    })
    .then(blob => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageData(reader.result);
      };
      reader.readAsDataURL(blob);
    })
    .catch(error => {
      console.error('Error fetching image:', error);
    });
  
  const BASE = process.env.REACT_APP_BASE_URL;

  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.invoice_origin}>
          <Text style={styles.invoice_origin_h}>
            {moment(invoiceData?.CreatedOn).format("DD/MM/YYYY HH:MM")}
          </Text>
        </View>
        <View style={styles.section}>
          <View style={styles.header}>
            <View style={{ ...styles.company_wrapper, ...styles.row }}>
              <View style={{ ...styles.company_left, ...styles.row }}>
                <View style={styles.origin_deatil2}>
                {invoiceData.adminDetails.length === 0 && (
                <Image
                src={BASE + invoiceData.agentDetails.ProfilePic.substring(1)}
                style={{ height: 30, width: 150, marginBottom: 3 }}
              ></Image>
                )}
                </View>
              </View>
              <View style={styles.Company_L}></View>
              <View>
                <View style={{...styles.origin_deatil3,paddingTop:5,paddingRight:15}}>
                  <Text style={{ ...styles.company_h ,color:"#000",fontSize:7}}>
                    <Text style={{...styles.boldText,fontSize:9,textAlign:"left"}}>{invoiceData.adminDetails &&
                    invoiceData.adminDetails.length > 0
                      ? invoiceData.adminDetails[0].CompanyName
                      : invoiceData.agentDetails.AgencyName}
                    </Text>{" "}
                    </Text>
                    <View>
                      <Text  style={{ ...styles.company_h ,color:"#000",fontSize:7,textAlign:"left"}}>
                    {invoiceData.adminDetails &&
                    invoiceData.adminDetails.length > 0
                      ? invoiceData.adminDetails[0].Address
                      : invoiceData.agentDetails.Address2}
                    |{" "}
                    {invoiceData.adminDetails &&
                    invoiceData.adminDetails.length > 0
                      ? invoiceData.adminDetails[0].CityName
                      : invoiceData.agentDetails.City} | {" "}
                    {invoiceData.adminDetails &&
                    invoiceData.adminDetails.length > 0
                      ? invoiceData.adminDetails[0].CountryName
                      : invoiceData.agentDetails.Country}{" "}
                    | phone:
                    {invoiceData.adminDetails &&
                    invoiceData.adminDetails.length > 0
                      ? invoiceData.adminDetails[0].PhoneNumber
                      : invoiceData.agentDetails.Phone}
                    |{" "} Email:
                    {invoiceData.adminDetails &&
                    invoiceData.adminDetails.length > 0
                      ? invoiceData.adminDetails[0].Email
                      : invoiceData.agentDetails.Email}
                  </Text>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                ...styles.invoice,
                marginTop: 10,
                marginBottom: 10,
                ...styles.boldText,
              }}
            >
              <Text style={styles.invoice_h}>Invoice</Text>
            </View>
            <View style={{ ...styles.company_wrapper, ...styles.row }}>
            
            <View style={{marginLeft:5,flexDirection: "row",marginTop:5,marginBottom:5}}>
              <View style={styles.origin_title_right}>
                <Text style={{ ...styles.origin_h, fontWeight:"bolder",color:"#000" ,fontSize:11}}>
                  {invoiceData.guests[0].FirstName}
                </Text>
              </View>
            </View>
           
            <View style={{ ...styles.origin_wrapper, ...styles.row }}>
              <View style={{ ...styles.origin, ...styles.row,marginLeft:5 }}>
                <View style={styles.origin_title_right}>
                  <Text style={{ ...styles.origin_h,marginBottom:2,marginTop:5 }}>Phone </Text>
                </View>
                <View style={styles.origin_title_right}>
                  <Text style={{...styles.origin_p,marginBottom:2,marginTop:5}}>: {invoiceData?.phoneNo}</Text>
                </View>
                <View style={styles.origin_title_right}>
                  <Text style={{ ...styles.origin_h,marginBottom:2 }}>Email </Text>
                </View>
                <View style={styles.origin_title_right}>
                  <Text style={{...styles.origin_p,marginBottom:2}}>: {invoiceData?.email}</Text>
                </View>
                <View style={styles.origin_title_right}>
                  <Text style={{ ...styles.origin_h,marginBottom:2 }}>Customer GSTIN </Text>
                </View>
                <View style={styles.origin_title_right}>
                  <Text style={{...styles.origin_p,marginBottom:2}}>:</Text>
                </View>
                <View style={styles.origin_title_right}>
                  <Text style={{ ...styles.origin_h,marginBottom:2 }}>Customer PAN </Text>
                </View>
                <View style={styles.origin_title_right}>
                  <Text style={{...styles.origin_p,marginBottom:2}}>
                    : {invoiceData?.guests[0].Pan}
                  </Text>
                </View>
              </View>
              <View style={{ ...styles.origin, ...styles.row }}>
                <View style={styles.origin_title_right}>
                  <Text style={{ ...styles.origin_h,marginBottom:2,marginTop:5 }}>Invoice Date.</Text>
                </View>
                <View style={styles.origin_deatil_right}>
                  <Text style={{...styles.origin_p,marginBottom:2,marginTop:5}}>
                    : {moment(invoiceData?.BookingDate).format("DD-MM-YYYY")}
                  </Text>
                </View>
                {invoiceData?.invoiceNo !== "" && (
  <>
    <View style={styles.origin_title_right}>
      <Text style={{ ...styles.origin_h, marginBottom: 2 }}>Invoice No.</Text>
    </View>
    <View style={styles.origin_deatil_right}>
      <Text style={{ ...styles.origin_p, marginBottom: 2 }}>: {invoiceData?.invoiceNo}</Text>
    </View>
  </>
)}
                <View style={styles.origin_title_right}>
                  <Text style={{ ...styles.origin_h,marginBottom:2 }}>Booking Id.</Text>
                </View>
                <View style={styles.origin_deatil_right}>
                  <Text style={{...styles.origin_p,marginBottom:2}}>
                    : {invoiceData?.RefNumber}
                  </Text>
                  
                </View>

                <View style={styles.origin_title_right}>
                  <Text style={{ ...styles.origin_h,marginBottom:2 }}>reference No.</Text>
                </View>
                <View style={styles.origin_deatil_right}>
                  <Text style={{...styles.origin_p,marginBottom:2}}>
                    : {invoiceData?.ConfirmationNumber}
                  </Text>
                  
                </View>
                <View style={styles.origin_title_right}>
                  <Text style={{ ...styles.origin_h,marginBottom:2 }}> Hotel Booking No</Text>
                </View>
                <View style={styles.origin_deatil_right}>
                  <Text style={{...styles.origin_p,marginBottom:2}}>
                    : {invoiceData?.supplierBookingNo}
                  </Text>
                  
                </View>

              </View>
            </View>
            </View>
            <View style={{ ...styles.company_wrapper }}>
            <View style={{ flexDirection: "row" }}>
               <View>
                {/* Display Hotel Image */}
                {/* <Image
              src={invoiceData?.hotelImage}
                  style={{ width: 100, height: 100 }}
                  
                /> */}
               
               {imageData ? (
                <Image src={imageData} style={{ height: 100, width: 100, marginBottom: 3 }} />
              ) : (
                <Image src={hotelimage} style={{ height: 100, width: 100, marginBottom: 3 }}/>
              )}
          {/* <Image
          source={{ uri: invoiceData?.hotelImage }}
          style={{ width: 100, height: 100 }}
        /> */}
              </View>
              <View style={{ marginLeft: 10, flex: 1 ,marginTop:10}}>
                {/* Display Hotel Name, Star Rating, and Address */}
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    ...styles.boldText,
                  }}
                >
                  {invoiceData.HotelName}{" "}
                  {renderStarRating(parseInt(invoiceData.StarRating, 10))}
                </Text>
                <Text
                  style={{ fontSize: 8, fontWeight: "bold", color: "#000",margin:2 }}
                >
                  Address: {invoiceData.HotelAddress.address}
                </Text>
                <Text
                  style={{ fontSize: 8, fontWeight: "bold", color: "#000",margin:2 }}
                >
                  Phone:{invoiceData.HotelContact ? invoiceData.HotelContact.phone : "Not Available" }
                </Text>
                <View
                  style={{
                    marginTop: 10,
                    flexDirection: "row",
                    ...styles.company_wrapp,
                  }}
                >
                  <Text style={{ fontSize: 8, color: "#000", margin: 10 }}>
                    Check-In:{" "}
                    {moment(invoiceData.CheckInDate).format("MMM DD, YYYY")}
                  </Text>
                  <View style={styles.Company_L}></View>
                  <Text style={{ fontSize: 8, color: "#000", margin: 10,marginRight:40 }}>
                    Check-Out:{" "}
                    {moment(invoiceData.CheckOutDate).format("MMM DD, YYYY")}
                  </Text>
                  <View style={styles.Company_L}></View>
                  <Text style={{ fontSize: 8, margin: 10, color: "#000" }}>
                    {getNumberOfNights()} Nights | No. of Rooms:{" "}
                    {invoiceData.NoOfRooms}
                  </Text>
                </View>
              </View>
            </View>
            </View>


           
            <View
              style={{
                marginBottom: 5,
                marginTop: 3,
                fontSize: 9,
                fontWeight: "bolder",
                color: "#000",
              }}
            >
              <View style={styles.origin_title_right}>
                <Text  style={{
                    fontSize: 11,
                    fontWeight: "bolder",
                    color: "#000",
                  }}>Room Details</Text>
              </View>
            </View>
            {invoiceData.Rooms.map((room, index) => (
  <View key={index}>
    <Text
      style={{
        fontSize: 10,
        fontWeight: "bolder",
        marginTop: 3,
        color: "#000",
      }}
    >
      Room {index + 1}:
    </Text>
    <View style={{ ...styles.company_wrapper }}>
      <Text
        style={{
          fontSize: 11,
          ...styles.boldText,
          Color: "#000",
          margin: 5,
        }}
      >
        Guest Details:
      </Text>
      {invoiceData.guests
        .filter((guest) => Number(guest.RoomSerialNo) === Number(room.roomSerialNo))
        .slice(0, 1)
        .map((guest, guestIndex) => (
          <Text key={index} style={{ fontSize: 10, margin: 5, color: "#000" }}>
            {guest.FirstName} {guest.LastName}
          </Text>
        ))}
      <View style={{ ...styles.borderBottom }}></View>
      {room && (
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              fontSize: 9,
              ...styles.boldText,
              margin: 10,
              width: "30%",
            }}
          >
            {room.roomName.split(",").slice(0, 1).join(",")}
          </Text>
          <View style={styles.Company_L}></View>
          <View style={{ width: "30%",paddingTop:4 }}>
            <Text style={{ fontSize: 9, ...styles.boldText }}>
              Adults Count: {room.adultCount}
            </Text>
            <Text style={{ fontSize: 9, ...styles.boldText }}>
              Child Count: {room.childCount}
            </Text>
          </View>
          <View style={styles.Company_L}></View>
          
          <View style={{ width: "30%" }}>
            <Text style={{ fontSize: 9, ...styles.boldText }}>
              Inclusions:
            </Text>
            

{/* Add a conditional check to handle the case when invoiceData.Inclusions is null */}
{invoiceData.Inclusions !== null ? (
    <Text key={index} style={{ fontSize: 10, margin: 5, color: "#000" }}>
      {invoiceData.Inclusions[0].inclusions === null ? invoiceData.Inclusions[0].boardBasis.description : invoiceData?.Inclusions[0]?.inclusions.join(", ")}
    </Text>
  )
 : (
  <Text style={{ fontSize: 10, margin: 5, color: "#000" }}>
    {/* Add your fallback text here, e.g., an empty string or a message */}
    No inclusions available
  </Text>
)}

          </View>
        </View>
      )}
    </View>
  </View>
))}




            <View
              style={{
                marginBottom: 3,
                marginTop: 3,
                fontSize: 10,
                fontWeight: "bold",
                color: "#000",
              }}
            >
              <View style={styles.origin_title_right}>
                <Text style={{ ...styles.origin_h,fontSize:10 }}>Payment Details</Text>
              </View>
            </View>
           
            <View style={styles.table_head}>
              <View style={{ ...styles.row, ...styles.m_0 }}>
                <View style={styles.table_pnr}>
                  <Text style={{...styles.table_p,color:"#2b3f99",fontSize:9}}>ROOM TARIFF</Text>
                </View>

                <View style={styles.table_pnr2}>
                  <Text style={{...styles.table_p,color:"#2b3f99",fontSize:9,textAlign:"right"}}>
                    TAX RECOVERY CHARGES AND SERVICE FEES
                  </Text>
                </View>
                <View style={styles.table_pnr1}>
                  <Text style={{...styles.table_p,color:"#2b3f99",fontSize:9,textAlign:"right"}}>DISCOUNTS</Text>
                </View>
                
                <View style={styles.table_pnr1}>
                  <Text style={{...styles.table_p,color:"#2b3f99",fontSize:9,textAlign:"right"}}>Total</Text>
                </View>
              </View>
                    <View
                      style={{
                        ...styles.row,
                        ...styles.m_0,
                        ...styles.borderBottom1,
                      }}
                    >
                      <View style={styles.table_pnr}>
                        <Text style={{...styles.table_p1,...styles.boldText,fontSize:8}}>{invoiceData.Rooms[0].roomName
                          .split(",")
                          .slice(0, 1)
                          .join(",")} ({invoiceData.NoOfRooms}* {invoiceData?.Currency} {parseFloat((Number(baseAmount) / Number(invoiceData?.CurrencyRatio)).toFixed(2))})</Text>
                      </View>

                      <View style={styles.table_pnr2}>
                        <Text style={{...styles.table_p1,...styles.boldText,fontSize:8,textAlign:"right"}}>
                        {invoiceData?.Currency} {parseFloat(((Number(taxAmount)+Number(convienenceFee)) / Number(invoiceData?.CurrencyRatio)).toFixed(2))}
                          
                        </Text>
                      </View>
                      <View style={styles.table_pnr1}>
                        <Text style={{...styles.table_p1,...styles.boldText,fontSize:8,textAlign:"right"}}>
                        {invoiceData?.Currency} {parseFloat((Number(discount) / Number(invoiceData?.CurrencyRatio)).toFixed(2))}
                        </Text>
                      </View>
                      
                      <View style={styles.table_pnr1}>
                        <Text style={{...styles.table_p1,...styles.boldText,fontSize:8,textAlign:"right"}}>
                        {invoiceData?.Currency} {parseFloat((Number(grandTotal) / Number(invoiceData?.CurrencyRatio)).toFixed(2))}
                        </Text>
                      </View>
                    </View>
                
            </View>

            <View style={styles.sub_table1}>
               <Text style={{ alignSelf: 'flex-end',...styles.boldText,fontSize:13,marginRight:0 ,textAlign:"right"}}> Total Net Fare : <Text style={{textAlign:"right"}}> {invoiceData?.Currency} {parseFloat((Number(grandTotal) / Number(invoiceData?.CurrencyRatio)).toFixed(2))} </Text></Text>
            </View>
            <View style={{ marginTop: "20px", width: "100%" }}>
              <Text style={{ fontSize: "9px",color:"#000" }}>
               <Text style={{...styles.boldText}}> Note:</Text> This is an electronically generated invoice and does not
                require a physical signature
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default HotelNewInvoiceDoc;