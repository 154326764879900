import React, { useState, useRef } from "react";

import { Radio, Col, Row, Form, Select, DatePicker, Button, Input } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import AirportAutoComplete from "../../common/AutoCompleteSelect/AirportAutoCompleteSelect";
import { OnlyFutureDateValidator } from "../../helpers/CustomValidators";
import { useEffect } from "react";
import { getFlightQueryParams } from "./SearchRequestHelper";

const MultiCityLIst = ({ setTogle, updateinputfield = () => { }, getnoofroutes = () => { }, props }) => {
  let dept = useRef([]);
  let to = useRef([]);
  let mdateBox = useRef([]);
  const [selectedDate, setSelectedDate] = useState(null);
  // console.log(props, "propsss");

  const [refresh, setRefresh] = useState(false);
  const [disabledates, setdisabledates] = useState([]);
  const disabledOriginDate = (key, date) => {
    // console.log(key, date, disabledates, "datee");
    // if (disabledates?.[1] && key === 0) {
    //   return date.valueOf() >= (new Date(disabledates?.[1])) || date.valueOf() < moment().startOf("day");
    // } else
    if (key === 1) {
      if (disabledates?.[2]) {
        return date.valueOf() < (new Date(disabledates?.[0])) || date.valueOf() >= (new Date(disabledates?.[2]));
      }
      return date.valueOf() < (new Date(disabledates?.[0]) || moment().startOf("day"));
    }
    else
      if (key === 2) {
        if (disabledates?.[3]) {
          return date.valueOf() < (new Date(disabledates?.[1])) || date.valueOf() >= (new Date(disabledates?.[3]));
        }
        return date.valueOf() < (new Date(disabledates?.[1]) || moment().startOf("day"));
      }
      else
        if (key === 3) {
          if (disabledates?.[4]) {
            return date.valueOf() < (new Date(disabledates?.[2])) || date.valueOf() >= (new Date(disabledates?.[2]));
          }
          return date.valueOf() < (new Date(disabledates?.[2]) || moment().startOf("day"));
        }
    return date.valueOf() < moment().startOf("day");

  };
  const MulticityDates = (date, datestring, key) => {
    // console.log(date, datestring, disabledates?.[key], "famil");
    if (disabledates?.[key]) {
      disabledates[key] = datestring;
      setdisabledates(disabledates);
    }
    else {
      setdisabledates([...disabledates, datestring])
    }

  }
  const OriginDestinationField = ({ count, field, add, index, remove }) => {
    const [showMDate, setShowMDate] = useState(false);

    const handleOnSubmit = (ref, isfieldKey, fieldKey) => {
      if (isfieldKey) {
        ref.current[fieldKey].focus();
        if (ref === mdateBox) {
          setShowMDate(true);
        }
      }
      updateinputfield(fieldKey);
    };
    return (
      <Row className="multi-rows">
        <Col md={8} className="autosea-multicity-align autosea-multicity-align-lines " >
          <AirportAutoComplete
            formItemProps={{
              label: "Depart From",
              ...field,
              name: [field.name, "origin"],
              fieldKey: [field.fieldKey, "origin"],

              rules: [{ required: true, message: "Please Select a City" }],
            }}
            selectProps={{ size: "large", placeholder: "From" }}
            refName={dept}
            focusRef={to}
            isfieldKey={true}
            fieldKey={field.name}
            handleOnSubmit={handleOnSubmit}
          />
          <i class='fa fa-map-marker mapmap-mapm-map1' ></i>

        </Col>
        <Col md={8} className="autosea-multicity-align fly-to-multi-city-age">
          {/* {console.log("iam Coming")} */}
          <AirportAutoComplete
            formItemProps={{
              label: "Flying To",
              ...field,
              name: [field.name, "destination"],
              fieldKey: [field.fieldKey, "destination"],

              rules: [{ required: true, message: "Please Select a City" }],
            }}
            selectProps={{ size: "large", placeholder: "To" }}
            refName={to}
            isfieldKey={true}
            fieldKey={field.name}
            focusRef={mdateBox}
            handleOnSubmit={handleOnSubmit}
          />
          <i class='fa fa-map-marker mapmap-mapm-map1' ></i>

        </Col>
        <Col md={7} className="date-multicity-align date-multicity-align-line">
          <Form.Item
            label="Departure Date"
            {...field}
            name={[field.name, "departureDateTime"]}
            fieldKey={[field.fieldKey, "departureDateTime"]}
            rules={[{ required: true, message: "Depart Date" }]}
          >
            <DatePicker
              placeholder="Departure Date"
              className="train-search-btn"
              size="large"
              style={{ width: "100%" }}
              format={"YYYY-MM-DD"}

              ref={(el) => (mdateBox.current[field.name] = el)}
              open={showMDate}
              defaultPickerValue={selectedDate || moment()}
              // disabledDate={disabledOriginDate}
              disabledDate={(date) => disabledOriginDate(field?.fieldKey, date)}
              onChange={(date, datestring) => {
                setSelectedDate(date);
                MulticityDates(date, datestring, field.fieldKey)
                if (field.name === count) {
                  //setTogle(true);
                  //setRefresh(true);
                } else {
                  dept.current[field.key + 1].focus();
                  setShowMDate((prev) => !prev);
                }
              }}
              onOpenChange={() => setShowMDate((prev) => !prev)}
            />
          </Form.Item>
        </Col>
        {field.name === 2 || field.name === 3 ? (
          <MinusCircleOutlined
            className="minus_multicity"
            onClick={() => remove(index)}
          />
        ) : null}
      </Row>
    );
  };
  const intitalValues = DefaultMulticityLoad();
  const countfields = (tr) => {
    getnoofroutes(tr)
  }
  return (
    <Col md={16} xs={22} >
      {/* style={{border:"2px solid #8080807d", backgroundColor:'#8080807d' }} */}
      <div id="multiCityForm" className="multicity">
        <Form.List name="originDestinationFields">
          {(fields, { add, remove }) => {
            //getnoofroutes(fields?.length);
            return (
              <>
                {fields.map((field, index) => {
                  //getnoofroutes(fields?.length);
                  return (
                    <OriginDestinationField
                      count={fields.length - 1}
                      field={field}
                      index={index}
                      key={field.key}
                      remove={remove}
                    />
                  );
                })}

                {fields.length < 4 ? (
                  <PlusCircleOutlined className="plus-icon" onClick={() => { add(); countfields(fields?.length + 1); }}>hvuyvuvu</PlusCircleOutlined>
                ) : null}
              </>
            );
          }}
        </Form.List>
      </div>
    </Col>
  );
};

export const DefaultMulticityLoad = () => {
  const value =
    localStorage.getItem("multicity") != undefined
      ? {
        originDestinationFields: JSON.parse(
          localStorage.getItem("multicity")
        ).map((i) => {
          return {
            ...i,
            departureDateTime: i.departureDateTime
              ? moment(i.departureDateTime)
              : "",
          };
        }),
      }
      : {
        originDestinationFields: [{}, {}],
      };
  return value;
};

export default React.memo(MultiCityLIst);
