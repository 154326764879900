
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Popover,
  Radio,
  message,
  Modal,
} from "antd";

import GlobalStatesContext from "../../../common/providers/GlobalStatesContext";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { QuestionCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import Login from "../../../components/Login/Login";
import APIClient from "../../../helpers/ApiClient";

import { CloseOutlined } from "@ant-design/icons";

export const getPromoDiscount = (promoData, total) => {
  let promoAmount = 0;

  if (promoData && promoData?.status) {
    if (promoData.DiscountType === 1) {
      promoAmount = Number((total / 100) * promoData.Discount);
    } else {
      promoAmount = Number(promoData.Discount);
    }
  }

  return Number(promoAmount);
};

export const getHotelGrandTotal = (hotelDetailsObj, hotelSearchData, ConvFee, promoData, selectedInsuranceData, redeemAmount
) => {
  let totalRoomBaseFare = 0;
  let totalRoomTax = 0;
  let totalOtherCharges = 0;
  let totalExtraGuestCharges = 0;
  let noOfNights = 0;
  let convamount = 0;
  let promoDiscount = 0;
  let noOfRooms = 0;
  let adminCommission = 0;
  let agentMarkup = 0;
  let markup = 0;
  let insuranceTotal = 0;
  let totalCommission = 0;
  let redeemTotal = 0;
  let hotelDiscount = 0;
  let roomDetails = {
    roomCount: 0,
    roomNightPrice: 0,
    totalTax: 0,
    total: 0

  };

  if (Object.keys(hotelSearchData).length > 0) {
    let checkin = new Date(hotelSearchData.checkInDate);
    let checkout = new Date(hotelSearchData.checkOutDate);
    let diffTime = checkout - checkin;
    let diffDays = Math.ceil(diffTime / (1000 * 24 * 60 * 60));

    noOfNights = Number(diffDays);
  } else return;

  if (hotelDetailsObj?.combineRoom?.length > 0) {
    noOfRooms = hotelDetailsObj?.combineRoom[0]?.combineRooms.length;
    hotelDetailsObj?.combineRoom.forEach((roomDetail) => {
      if (roomDetail?.priceDetails) {
        const priceObj = roomDetail?.priceDetails;

        totalRoomBaseFare += Number(priceObj.base);
        totalRoomTax += Number(priceObj.tax);
        totalOtherCharges += Number(priceObj.otherCharges);
        totalExtraGuestCharges += Number(priceObj?.extraGuestCharges ?? 0);
        agentMarkup += Number(priceObj?.agentMarkup ?? 0);
        adminCommission += Number(priceObj?.adminCommission ?? 0);
        markup += Number(priceObj?.markup ?? 0);
        totalCommission += Number(roomDetail?.commission ?? 0);
        hotelDiscount += Number(priceObj?.discount ?? 0);
      }
    });
  }

  let grandBaseFare = Number(totalRoomBaseFare);
  let grandTax = Number(totalRoomTax);
  let grandOtherCharges = Number(totalOtherCharges);
  let grandExtraGuestCharges = Number(totalExtraGuestCharges);
  let grandHotelDiscount = Number(hotelDiscount);

  let totalTax = Number(grandTax) + Number(grandOtherCharges);

  roomDetails = {
    roomCount: noOfRooms,
    roomNightPrice: grandBaseFare,
    totalTax: totalTax,
    tax: grandTax,
    otherCharges: grandOtherCharges,
    extraGuestCharges: grandExtraGuestCharges,
    totalHotelDiscount: grandHotelDiscount,
  };

  let total = hotelDetailsObj?.combineRoom[0]?.price




  if (ConvFee) {
    if (ConvFee.type === 1) {
      convamount = Number(ConvFee.amount);
    } else {
      convamount = Number((total / 100) * Number(ConvFee.amount));
    }
  }



  if (
    selectedInsuranceData.status === 1 &&
    selectedInsuranceData?.serviceType === 2
  ) {
    let totalPax = hotelSearchData.roomGuests.reduce(
      (acc, cur) => acc + Number(cur.noOfAdults) + Number(cur.noOfChilds),
      0
    );

    insuranceTotal = totalPax * Number(selectedInsuranceData.amount);
  }
  promoDiscount = getPromoDiscount(promoData, total);

  let totalAmount = Number(total) + Number(convamount) + Number(insuranceTotal);
  totalAmount = Number(totalAmount) - Number(promoDiscount);

  if (redeemAmount?.CouponAmt) {
    redeemTotal = Number(redeemAmount?.CouponAmt);
  }
  totalAmount -= redeemTotal;
  return {
    roomDetails,
    noOfNights,
    promoDiscount,
    totalAmount: Number(totalAmount).toFixed(2),
    convamount,
    totalCommission,
    adminCommission,
    agentMarkup,
    markup,
    insuranceTotal: Number(insuranceTotal).toFixed(2),
    redeemTotal: redeemTotal,
  };
};
const HotelFairBox = ({ hotelDetailsObj, hotelSearchData, isPromoVisible, location }) => {
  const [form] = Form.useForm();
  let dateFormat = "YYYY-MM-DD";
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const {
    state: {
      otherData: { ConvFee, promoData, selectedInsuranceData, redeemAmount },
    },
    AddConvFee,
    validatePromoCode,
    RemovePromo,
    getInsuranceByServiceType,
    validateRedeemCoupon,
    RemoveRedeemCoupon,
  } = useContext(GlobalStatesContext);

  const [promoCode, setPromoCode] = useState([]);
  const [redeemData, setRedeemData] = useState({});
  const {
    roomDetails,
    noOfNights,
    promoDiscount,
    totalAmount,
    convamount,
    insuranceTotal,
    totalCommission,
    redeemTotal,
  } = getHotelGrandTotal(
    hotelDetailsObj,
    hotelSearchData,
    ConvFee,
    promoData,
    selectedInsuranceData,
    redeemAmount
  );
  // console.log(hotelDetailsObj, "bvghg")
  useEffect(() => {
    if (!agent) {
      AddConvFee(2);
      getpromo();
    }
  }, [agent]);
  useEffect(() => {
    getInsuranceByServiceType(2);
  }, []);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, dateFormat).isSameOrAfter(moment(), 'day')
          );
          if (data.length > 0) {
            let busPromo = data.filter(
              (promo) =>
                promo.ServiceType === 2 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );

            setPromoCode(busPromo);
          }
        }
      })
      .catch((error) => {
        setPromoCode([]);

        console.error(error);
      });
  };
  const [Loc, setLoc] = useState({});
  useEffect(() => {
    if (location) {
      setLoc(location);
    }
  }, [location]);
  const handleApply = (code) => {
    if (user != null) {
      form.setFieldsValue({
        promo: code,
      });

      form.submit();
    } else {
      message.error("please Sign-In to Avail Offers")
    }
  };

  const roomFare = () => {
    return (
      <div className="pax-count-acc-body">
        <p>({`${roomDetails.roomCount} Rooms x ${noOfNights} Nights`})</p>
        <p>
          {activeCurrency} {currencyValue(hotelDetailsObj?.combineRoom[0]?.priceDetails?.totalBasePrice)}{" "}
        </p>
      </div>
    );
  };
  useEffect(() => {
    if (user) {
      getRedeemCoupon(user.UserID);
    }
  }, [user]);
  const getRedeemCoupon = (userID) => {
    APIClient.get("admin/getUserCouponsWalletAmt/" + userID)
      .then((res) => {
        if (res.status === 200) {
          setRedeemData(res.data);
        }
      })
      .catch((error) => {
        setRedeemData({});
      });
  };
  const [modalVisible, setModalVisible] = useState({
    visible: false,
    type: "USER",
  });
  const showModal1 = (type) => {
    /*============= check user logged or not =========== */
    setModalVisible({ visible: true, type: type });

  };
  return (
    <>
      <div className="sticky-card-container">
      
        <p className="h-fare">Base Fare</p>
        
        <Row justify={"space-between"} style={{ whiteSpace: "nowrap" }} className="grand_total_card_row">
          <Col>
            <p style={{ fontSize: 13, fontWeight: 600 }}>Room Price</p>
          </Col>
          <Col className="d-flex">
            <p style={{ paddingleft: 6, whiteSpace: "nowrap", fontSize: 13, fontWeight: 600 }}>
              {activeCurrency} {currencyValue(hotelDetailsObj?.combineRoom[0]?.priceDetails?.totalBasePrice)}
            </p>

            <Popover
              overlayClassName="pricepopup"
              placement="left"
              content={roomFare()}
              title="Room Price"
            >
              <QuestionCircleOutlined style={{ paddingTop: '3%', marginLeft: '3%', paddingRight: 1, color: "#35459c" }} />
            </Popover>
          </Col>
        </Row>
        <Row justify={"space-between"} className="grand_total_card_row">
         
          <Col>
            <p style={{ fontSize: 13, fontWeight: 600 }}>Taxes & Fee's</p>
          </Col>
          <Col className="d-flex">
            <p style={{ paddingRight: 2, whiteSpace: "nowrap", fontSize: 13, fontWeight: 600 }}>
              {activeCurrency} {currencyValue(hotelDetailsObj?.combineRoom[0]?.priceDetails?.totalTax)}
            </p>
            <Popover
              overlayClassName="pricepopup"
              placement="left"
              content={
                <>
                  
                  {hotelDetailsObj?.combineRoom[0]?.priceDetails?.serviceTax > 0 && (
                    <div className="pax-count-acc-body">
                      <div className="pax-type">
                        <p>Service Tax</p>
                      </div>

                      <div className="service-price">
                        <p style={{ whiteSpace: "nowrap" }}>

                          {activeCurrency} {currencyValue(hotelDetailsObj?.combineRoom[0]?.priceDetails?.serviceTax)}
                        </p>
                      </div>
                    </div>
                  )}
                  {hotelDetailsObj?.combineRoom[0]?.priceDetails?.tax > 0 && (
                    <div className="pax-count-acc-body">
                      <div className="pax-type">
                        <p>Tax</p>
                      </div>

                      <div className="service-price">
                        <p style={{ whiteSpace: "nowrap" }}>

                          {activeCurrency} {currencyValue(hotelDetailsObj?.combineRoom[0]?.priceDetails?.tax)}
                        </p>
                      </div>
                    </div>
                  )}

                  {hotelDetailsObj?.combineRoom[0]?.priceDetails?.otherCharges > 0 && (
                    <div className="pax-count-acc-body">
                      <div className="pax-type">
                        <p>Other Tax</p>
                      </div>

                      <div className="service-price">
                        <p style={{ whiteSpace: "nowrap" }}>

                          {activeCurrency} {currencyValue(hotelDetailsObj?.combineRoom[0]?.priceDetails?.otherCharges)}
                        </p>
                      </div>
                    </div>
                  )}
                </>
              }
              title="Taxes and Fees"
            >
              <QuestionCircleOutlined style={{ paddingTop: '3%', marginLeft: '3%', paddingRight: 3, color: "#35459c" }} />
            </Popover>
          </Col>
        </Row>
        {roomDetails.extraGuestCharges > 0 ?
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Extra Guest Charge</p>
            </Col>
            <Col className="d-flex">
              <p>
                {activeCurrency} {currencyValue(roomDetails.extraGuestCharges)}
              </p>
            </Col>
          </Row> : null}
        {roomDetails.totalHotelDiscount > 0 ?
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Hotel Discount </p>
            </Col>
            <Col className="d-flex">
              <p style={{ whiteSpace: "nowrap" }} >
                {activeCurrency}{" "}
                {currencyValue(roomDetails.totalHotelDiscount)}
              </p>
            </Col>
          </Row>
          : null}

        {promoData.status && promoDiscount > 0 ? (
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Discount</p>
            </Col>
            <Col className="d-flex">
              <p style={{ whiteSpace: "nowrap" }}>
                {activeCurrency} {currencyValue(promoDiscount)}
              </p>
            </Col>
          </Row>
        ) : null}

       
        {convamount > 0 ?
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p style={{ fontSize: 13, fontWeight: 600 }}>Convenience Fee</p>
            </Col>
            <Col className="d-flex">
              <p style={{ whiteSpace: "nowrap", fontSize: 13, fontWeight: 600 }}>
                {activeCurrency} {currencyValue(convamount)}
              </p>
            </Col>
          </Row> : null}

        <div className="pax-total-price">
          <div className="tot-far">
            <div className="pax-type">
              <p className="t-fare">
                <strong>Total:</strong>
                <span className="all-taxes">Including all taxes and fees</span>
              </p>
            </div>
            <div className="total">
             
              <p className="amount">{activeCurrency}{" "} {currencyValue(totalAmount)}</p>
            </div>
          </div>

          {agent && totalCommission > 0 ? (
            <div className="pax-total-price1">
              <div className="pax-type">
                <p className="pax-comm">Commission Earned:</p>
              </div>
              <div className="total">
                <p className="pax-comm"> {activeCurrency} </p>
                <p className="amount ml-1 pax-comm">
                  {" "}
                  {currencyValue(totalCommission)}
                </p>
              </div>
            </div>
          ) : null}
        </div>
        {/* </Card> */}
      </div>

      {!agent && user?.Role?.RoleLevel !== 3 && isPromoVisible ? (
        <div className="buspromo_wrapper">
          {promoData.status == false ? (
          
            <div className="promo-hot">
              <p className="name">Apply Promo</p>
              {user != null ?
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={(d) => {
                    validatePromoCode({
                      ServiceType: 2,
                      Amount: Number(totalAmount),
                      PromoCode: d.promo,
                      UserId: user?.UserID ?? 1,
                      userMail: user?.Email,
                    });
                  }}
                >
                  <Row gutter={[16, 16]}>
                    <Col md={12} sm={12} xs={12}>
                      <Form.Item
                        name="promo"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Input
                          className="inputbg"
                          placeholder="Enter Your Promo code"
                          autoComplete="off"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={12}>
                      <Form.Item>
                        <Button className="btn-pro" type="primary" onClick={() => form.submit()}>
                          Apply
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form> : <div className="promo-input">
                  <p>Please <span style={{ color: "blue", cursor: "pointer" }} onClick={() => showModal1("USER")}>Sign-In</span> to Avail Offers</p>
                </div>
              }
              {promoCode.length ? (
                promoCode.map((item, key) => {
                  return (
                    <>
                      <div className="promo-cp-coupons" key={key}>
                        <div className="inline-cp-promo">
                          <Form>
                            <Form.Item>
                              <Radio
                                onClick={() => handleApply(item.Code)}
                                key={item.Code}
                              ></Radio>
                            </Form.Item>
                          </Form>
                          <p className="promo-key-cp">{item.Code}</p>
                          {item.DiscountType === 1 ? (
                            <p className="save-cp-offer">
                              Save {Math.floor(item.Discount) + " %"}
                            </p>
                          ) : (
                            <p className="save-cp-offer">
                              Save {activeCurrency}&nbsp;
                              {currencyValue(item.Discount)}
                            </p>
                          )}
                        </div>

                        <div className="promo-percentage-cp">
                          <p>{item.Description}</p>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="promo-cp-coupons">
                  <div className="promo-percentage-cp pl-0 pt-0">
                    <p>No Promo Code Available</p>
                  </div>
                </div>
              )}
            </div>
            // </Card>
          ) : (
            <Card bordered={false} className="hotel-card-wrapper">
              <div className="promo-card-header">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="name"
                >
                  Promo Coupon{" "}
                  <CloseOutlined
                    onClick={() => {
                      RemovePromo();
                      form.resetFields();
                    }}
                  />{" "}
                </p>
              </div>
              <div className="promo-input">
                <p className="mb-0">
                  <span className="applied"> {promoData.Code} </span> Promo Code
                  Applied
                </p>
              </div>
            </Card>
          )}
        </div>
      ) : null}

      {user && redeemData?.couponAmt > 0 ? (
        redeemAmount.status === true ? (
          <Card className="flight-cards-details mt-3">
            <div className="d-flex justify-content-between align-items-center">
              <p className="name mb-0">
                Redeemed From Coupon Wallet: {redeemTotal}
              </p>
              {!isPromoVisible ? null : (
                <CloseOutlined
                  onClick={() => {
                    RemoveRedeemCoupon();
                  }}
                />
              )}
            </div>
          </Card>
        ) : !isPromoVisible ? null : (
          <Card className="flight-cards-details mt-3">
            <p className="font-weight-bold mb-1">
              Coupon Wallet: 
              {"("}
              {redeemData?.couponAmt} {")"}
            </p>

            <div className="book-pay-btn">
              {" "}
              <Button
                type="primary"
                className=" redeem_btn"
                onClick={() => {
                  validateRedeemCoupon({
                    userId: user?.UserID ?? 0,
                    roleType: user?.Role?.RoleId ?? 0,
                    membershipId: user?.Membership ?? 0,
                    couponAmt: redeemData?.couponAmt ?? 0,
                  });
                }}
              >
                Redeem Coupon
              </Button>
            </div>
          </Card>
        )
      ) : null}
      <Modal
        centered
        visible={modalVisible.visible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        className="login-modal"
        footer={false}
      >
        <Login
          location={Loc}
          setModalVisible={(value) => setModalVisible(value)}
          type={modalVisible.type}
        />
      </Modal>
    </>
  );
};

export default HotelFairBox;
