export const FlightCabinClass = {
    All: 1,
    Economy: 2,
    PremiumEconomy: 3,
    Business: 4,
    PremiumBusiness: 5,
    First: 6
}

export const JourneyTypeEnum = {
    OneWay: 1,
    RoundTrip: 2,
    MultiStop: 3,
    AdvanceSearch: 4,
    SpecialReturn: 5
}

export const FlightBaggageType = {
    KG: 1,
    PIECES: 2
}

export const FareType = {
    Refundable: 1,
    NonRefundable: 2
}

export const PaxType = {
    Adult: "ADT",
    Child: "CHD",
    Infant: "INF"
}

export const FareTag = {
    BaseFare: 1,
    OtherCharges: 2,
    FuelCharges: 3,
    ServiceFee: 4,
    TransactionFee: 5,
    ServiceCharges: 6,
    AirportTax: 7,
    SkyCafeMeals: 8,
    AirportDevelopmentFee: 9,
    CuteFee: 10,
    ConvienenceFee: 11
}

export const TripIndicator = {
    Onward: 1,
    Return: 2
}
