

import React, { useState, useEffect } from "react"
import { Button, Card, Col, Form, Modal, Radio, Row, Layout, Table, Popconfirm, Switch, Select, DatePicker, Checkbox, message, InputNumber, Input } from "antd";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
import search from "../assets/vector-icons/search.png";
import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import { PaxType, TripIndicator, FareTag,FlightCabinClass,JourneyTypeEnum } from "./FlightExtranetHelper"
import moment from "moment";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const FlightExtranetPNR = () => {

    const { Option } = Select;
    const { Content } = Layout;
    const [form] = Form.useForm()
    const [isModalVisibal, setIsModalVisibal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [currentId, setCurrentId] = useState(0)
    const { user } = useAuthContext();
    const dateFormat = "DD-MM-YYYY"
    const [dataSource, setDataSource] = useState([])
    const [flightSearchCriteria, setFlightSearchCriteria] = useState(null)


    useEffect(() => {
        getFlightPNR()
        const localStorageResp = localStorage.getItem("FlightExtranet_SearchCriteria")
        console.log("localStorageResp", JSON.parse(localStorageResp))
        setFlightSearchCriteria(JSON.parse(localStorageResp))

    }, [])



    const handleAddClick = () => {
        form.resetFields();
        setIsModalVisibal(true);
        setIsEdit(false);
    };

    const handleEditClick = (rec) => {
        form.setFieldsValue({
            ...rec,
        })
        setIsEdit(true)
        setIsModalVisibal(true)
    }

    const tableActions = (rec) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <EditOutlined
                        onClick={() => {
                            handleEditClick(rec);
                            setCurrentId(rec.Id);
                        }}
                        style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => deleteFlightPNR(rec.Id)}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </Popconfirm>
                </div>
            </div>
        );
    };

    const handelStatus = (data) => {
        if (data.Status == 1) {
            return (
                <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    defaultChecked
                    onChange={() => changeStatus(data, 0)}
                />
            );
        } else {
            return (
                <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    onChange={() => changeStatus(data, 1)}
                />
            );
        }
    };

    const changeStatus = (info) => {

    }

    const handelFormSubmit = (value) => {

        const reqBody = {
            TripIndicator: value?.TripIndicator ?? 1,
            FlightId: flightSearchCriteria?.FlightId ?? 0,
            PNR: value.PNR,
            Seats: value.Seats,
            Status: 1

        }

        if (isEdit) {
            reqBody["ModifiedBy"] = user?.UserID ?? 1
            updateFlightPNR(reqBody)
        } else {
            reqBody["CreatedBy"] = user?.UserID ?? 1
            reqBody["ModifiedBy"] = user?.UserID ?? 1
            createFlightPNR(reqBody)
        }

    }

    //#region API CALLING



    const getFlightPNR = () => {
        ApiClient.get("flight-pnr/list").then((response) => {
            if (response.statusCode === 200) {
                const data = response.data
                setDataSource(data)
            }
        }).catch((e) => message.error(e.message))
    }

    const createFlightPNR = (reqBody) => {
        ApiClient.post("create/flight-pnr", reqBody).then(
            (response) => {
                if (response.statusCode === 200) {
                    setIsModalVisibal(false);
                    message.success(response.message);
                    getFlightPNR();
                } else {
                    message.error(response.message);
                }
            }
        );
    }

    const updateFlightPNR = (reqBody) => {
        ApiClient.put(
            "update/flight-pnr/" + currentId,
            {},
            reqBody
        ).then((response) => {
            if (response.statusCode === 200) {
                setIsModalVisibal(false);
                message.success(response.message);
                getFlightPNR();
            } else {
                message.error("Error Updating");
            }
        }).catch((error) => message.error(error.message));
    }

    const deleteFlightPNR = (Id) => {
        ApiClient.delete("delete/flight-pnr/" + Id).then(
            (res) => {
                if (res.statusCode == 200) {
                    message.success("Deleted  successfully", 3);
                    getFlightPNR();
                } else message.error(res.message);
            }
        ).catch((er) => message.error(er.message));
    }

    //#endregion API CALLING


    const columns = [
        {
            title: "Actions",
            render: (rec) => {
                return tableActions(rec);
            },
        },
        {
            title: "JourneyType",
            //dataIndex: "JourneyType",
            render: (jt) => Object.keys(JourneyTypeEnum).find(key => JourneyTypeEnum[key] === jt?.FlightSearchCriteria?.JourneyType)
        },
        {
            title: "Origin",
            // dataIndex: "Origin",
            ellipsis: true,
            render: (item) => item?.FlightSearchCriteria?.Origin ?? ""
        },
        {
            title: "Destination",
            // dataIndex: "Destination",
            ellipsis: true,
            render: (item) => item?.FlightSearchCriteria?.Destination ?? ""
        },
        {
            title: "JourneyDate",
            // dataIndex: "JourneyDate",,
            render: (item) => item?.FlightSearchCriteria?.JourneyDate ?? ""
        },

        {
            title: "CabinClass",
            // dataIndex: "CabinClass",
            ellipsis: true,
            render: (cc) => Object.keys(FlightCabinClass).find(key => FlightCabinClass[key] === cc?.FlightSearchCriteria?.CabinClass)
        },

        {
            title: "ReturnDate",
            // dataIndex: "ReturnDate",
            render: (item) => item?.FlightSearchCriteria?.ReturnDate ?? "NA"
        },
        {
            title: "TripIndicator",
            dataIndex: "TripIndicator",
            render: (tp) => Object.keys(TripIndicator).find(key => TripIndicator[key] === tp)
        },

        {
            title: "PNR",
            dataIndex: "PNR",
        },
        {
            title: "Seats",
            dataIndex: "Seats",
        },
        {
            title: "AvailableSeats",
            dataIndex: "AvailableSeats",
        },

        {
            title: "Status",
            render: (currentItem) => handelStatus(currentItem),
        },
    ]


    return (<div>
        <div>
            <Content>
                <Row>
                    <Col span={24}>
                        <div>
                        <div className="navigationmain-box" >
                                <a className="navigation-color"  href="/admin/flight-extranet/search-criteria">Route</a><i class="arrow right"></i>
                                <a className="navigation-color"  href="/admin/flight-extranet/segments">Segments</a><i class="arrow right"></i>
                                <a className="navigation-color" href="/admin/flight-extranet/fare-details">Fares</a><i class="arrow right"></i>
                                <a className="navigation-color" href="/admin/flight-extranet/fare-rules">Fare Rules</a><i class="arrow right"></i>
                                <a className="navigation-color" style={{ color: "orange" }}>Pnr</a><i class="arrow right"></i>
               
                            </div>
                            <Card bordered={false}>
                                <div>
                                    <h5>
                                        Flights PNR{" "}
                                        <HelpInfoHelper screenName={"/admin/cartypes"} />
                                    </h5>
                                </div>
                                <div className="action-images">
                                    <img src={search} alt="search" />
                                    <img src={excel} alt="excel" />
                                    <img src={pdf} alt="pdf" />
                                    <p className="add-plus-icon" onClick={handleAddClick}>
                                        <PlusCircleFilled />
                                    </p>
                                </div>
                                {dataSource.length > 0 ? (
                                    <div>
                                        <Table
                                            className="table-scroll-none"
                                            bordered
                                            dataSource={dataSource}
                                            columns={columns}
                                            pagination={{
                                                defaultPageSize: 25,
                                                showSizeChanger: true,
                                                pageSizeOptions: ["25", "50", "100", "125"],
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <b>No record Found</b>
                                )}
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Content>
        </div>
        <Modal
            title={[
                <div>
                    <h6><strong>{isEdit ? "Update PNR" : "Add PNR"}</strong></h6>
                </div>
            ]}
            onCancel={(e) => setIsModalVisibal(false)}
            onOk={(e) => setIsModalVisibal(false)}
            visible={isModalVisibal}
            footer={[
                <div>
                    <Button htmlType="submit" type="primary" onClick={() => form.submit()}>
                        {isEdit ? "Update" : "Add"}
                    </Button>
                    <Button onClick={() => form.resetFields()}>
                        Clear
                    </Button>
                </div>
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handelFormSubmit}
            >
                <Row gutter={16} >
                    {flightSearchCriteria?.JourneyType == 2 && (
                        <Col xs={24} md={8}>
                            <Form.Item
                                label="Trip Indicator"
                                name="TripIndicator"
                            >
                                <Select
                                >
                                    <Option value={1}>Onward</Option>
                                    <Option value={2}>Return</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    )}

                    <Col xs={24} md={8}>
                        <Form.Item
                            label="PNR"
                            placeholder=" Input PNR"
                            name="PNR"
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8}>
                        <Form.Item
                            label="Seats"
                            name="Seats"
                            required={[
                                { required: true, message: "Seats is required" }
                            ]}
                        >
                            <InputNumber min={1} defaultValue={0} />
                        </Form.Item>
                    </Col>

                </Row>

            </Form>

        </Modal>
    </div >)
}

export default FlightExtranetPNR