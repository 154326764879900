import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Row,
  Modal,
  message,
  Spin,
  Image,
} from "antd";
import moment from "moment";
import TicketCar from "./TicketCar";
import LogoImg from "../../../assets/images/logos/trip-logo.png";
import { useAuthContext } from "../../providers/AuthProvider";
import { useSytContext } from "../../providers/SytProvider";
import queryString from "query-string";
import APIClient from "../../../helpers/ApiClient";
import TicketTransfer from "./TicketTransfer";
import TicketSidebar from "../TicketSidebar";
import { LoadingOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const TransferTicketScreen = ({ mode }) => {
  const {
    user,
    isLogin: { agent },
  } = useAuthContext();
  const { agentLogo, logo } = useSytContext();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const ticketSearchParams = queryString.parse(window.location.search);

  const [ticketData, setTicketData] = useState({});

  const [loadingTicket, setLoadingTicket] = useState(true);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  //   let { grandTotal } = getBusTicketPrice(ticketData);
  const BASE = process.env.REACT_APP_BASE_URL;
  const fetchTicketDetail = (ref) => {
    setLoadingTicket(true);
    APIClient.get("transfers/bookingDetails?refNo=" + ref)
      .then((res) => {
        // {console.log(res,"rescart363")}
        if (res?.status == 200) {
          if (mode === "USER") {
            let userId = user ? user.UserID ?? 1 : 1;
            if (res?.data.UserId === userId) {
              setTicketData(res.data);
            } else {
              setTicketData({});
            }
          } else {
            setTicketData(res.data);
          }
        } else {
          setTicketData({});
        }
        setLoadingTicket(false);
      })
      .catch((error) => {
        setTicketData({});
        setLoadingTicket(false);
      });
  };

  const cancelTicket = () => {
    setModalVisible(true);
  };

  const submitCancelForm = (val) => {
    setLoading(true);
    // if (ticketData.EmailId == "" && ticketData.BookingRefNo == "") {
    //   return;
    // }
    // let data = {
    //   email: ticketData.EmailId,
    //   carID: ticketData.CarId,
    //   referenceNo: ticketData.BookingRefNo,
    // };
    let data = {
      Remarks: ticketData?.TransferName ?? "",
      BookingRefno: ticketData?.ReferenceNo,
    }
    APIClient.post("transfers/cancelTicket", data)
      .then((res) => {
        setLoading(false);
        if (res.statusCode == 200) {
          message.success(
            `${res.data.Status}. Your total refund amount is Rs. ${res.data.RefundedAmount} `,
            10
          );
          getTicketDetails();
          setModalVisible(false);
          form.resetFields();
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    getTicketDetails();
  }, [ticketSearchParams.ref]);

  const getTicketDetails = () => {
    fetchTicketDetail(ticketSearchParams.ref)
  };

  return (
    <div className="flight-ticket-collapse">
      {/* <div className="print-agentlogo">
        {agent ? (
          agentLogo ? (
            <Image
              className="printImageStyle"
              src={BASE + agentLogo.substring(1)}
            ></Image>
          ) : logo ? (
            <Image
              className="printImageStyle"
              src={BASE + logo.substring(1)}
            ></Image>
          ) : (
            <Image className="printImageStyle" src={LogoImg}></Image>
          )
        ) : logo ? (
          <Image
            className="printImageStyle"
            src={BASE + logo.substring(1)}
          ></Image>
        ) : (
          <Image className="printImageStyle" src={LogoImg}></Image>
        )}
      </div> */}
      <Card>
        <div className="fligh-ticket-container">
          {loadingTicket ? (
            <div style={{ textAlign: "center" }} className="flight-ticket">
              <Spin indicator={antIcon} tip="Loading..." />
            </div>
          ) : Object.keys(ticketData).length > 0 ? (
            <div className="flight-ticket">
              {/* <PDFViewer>
                  <BusDoc ticketData={ticketData} />
                </PDFViewer> */}
              <Row gutter={[32, 16]} className="ticket-row">
                <Col md={18} className="ticket-coloum">
                  <TicketTransfer ticketData={ticketData} />
                </Col>

                <Col xs={24} md={6} className="tic-info-flight">
                  <div className="web-tic-info">
                    <TicketSidebar
                      ticketData={ticketData}
                      ticketSearchParams={ticketSearchParams}
                      type="Transfer"
                      onCancelTicket={cancelTicket}
                      getTicketDetails={getTicketDetails}
                    />
                  </div>

                  <div className="mobile-tic-info">
                    <Collapse accordion>
                      <Panel header="Manage Tickets" key="1">
                        <TicketSidebar
                          ticketData={ticketData}
                          ticketSearchParams={ticketSearchParams}
                          type="Transfer"
                          onCancelTicket={cancelTicket}
                          getTicketDetails={getTicketDetails}
                        />
                      </Panel>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <p className="no-tickt">No Ticket Found</p>
          )}
        </div>
      </Card>

      <Modal
        title="Cancel Ticket"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              onClick={submitCancelForm}
              loading={loading}
            >
              Cancel Ticket
            </Button>
          </div>,
        ]}
        width={"600px"}
      >
        {Object.keys(ticketData).length > 0 ? (
          ticketData.BookingStatus === 3 ||
            ticketData.BookingStatus === 2 ||
            ticketData.BookingStatus === 7 ? (
            <div className="wrapper">
              <p>
                <b>Reference No</b> : {ticketData.ReferenceNo}
              </p>
              <p>
                <b>Journey Details</b>
                {/* {ticketData.PickUpDetailName} <b>TO </b>
                {ticketData.DropOffDetailName} on {ticketData.TransferDate}  */}
                ( {ticketData.TransferName} )
              </p>
              <div>
                <p>Pick up : {ticketData.PickUpDetailName}</p>
                <p>Drop point : {ticketData.DropOffDetailName}</p>
                <p>journey Date : {moment(ticketData.TransferDate).format("DD-MM-YYYY")}</p>
              </div>
              <p>
                <b>Total Fare</b> :  {ticketData.Price[0].totalBasePrice}
              </p>

              {/* <Form
                layout="vertical"
                className="bus-cancel-form"
                form={form}
                // onFinish={submitCancelForm}
              >
                <Form.Item
                  label="Select Seat No."
                  name="seatNo"
                  rules={[{ required: true, message: "Please Select Seat No" }]}
                >
                  <Checkbox.Group>
                    <Row gutter={[8, 8]}>
                      {ticketData.passengerInfo.length > 0
                        ? ticketData.passengerInfo.map((item, i) => (
                            <Col key={i} md={24} sm={24} xs={24}>
                              <Checkbox value={item.seatNos}>
                                {item.seatNos} ({item.names})
                              </Checkbox>
                            </Col>
                          ))
                        : ""}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Form> */}
            </div>
          ) : null
        ) : null}
      </Modal>
    </div>
  );
};
export default TransferTicketScreen;
