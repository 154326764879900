import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import APIClient from "../../../src/helpers/ApiClient";
import moment from "moment";
import UpdatedComponent from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../../src/helpers/ApiClient";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const Role = (props) => {
  const [form] = ANTD.Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [addValue, setValue] = useState();
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [excelList, setExcelList] = useState([]);
  useEffect(() => {
    getRolesList();
  }, []);

  const getRolesList = () => {
    APIClient.get("admin/role")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          let filteredRoles = data?.filter(
            (item) => item.RoleID != 2 && item.RoleID != 5
          );
          setRolesList(filteredRoles);
          defineExcelData(filteredRoles);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        Name: item.Name,
        CreatedDate: item.CreatedDate,
        ModifiedDate: item.ModifiedDate,
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  /*  Add Role */
  const add = (roleObj) => {
    // console.log("fg",val.target.value)
    // let roleObj = {
    //   Name: addValue,
    //   Level: "1",
    //   CreatedBy: 1,
    // };
    APIClient.post("admin/role", roleObj)
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.status == 200) {
          setModalVisible(false);
          ANTD.message.success("Successfully added role", 3);
          getRolesList();
        } else if (response.status == 400) {
          ANTD.message.error("Role Already existed", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleModal = () => {
    setShowUpdateBtn(false);
    form.resetFields();
    setModalVisible(true);
  };

  const deleteRole = (id) => {
    APIClient.delete("admin/role/" + id)
      .then((res) => {
        if (res.status == 200) {
          ANTD.message.success("Role Deleted  successfully", 3);
          getRolesList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const tableActions = (currentRecord) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord)}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm
            title="Are you sure want to delete?"
            onConfirm={() => deleteRole(currentRecord.RoleID)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };

  const editCurrentItem = (rec) => {
    setCurrentId(rec.RoleID);
    form.setFieldsValue({
      role: rec.Name,
    });

    setShowUpdateBtn(true);
    setModalVisible(true);
  };

  const updateRole = (editObj) => {
    // let editObj = {
    //   Name: addValue,
    //   Level: "1",
    //   CreatedBy: "1",
    // };

    ApiClient.put("admin/role/" + currentId, {}, editObj).then((res) => {
      if (res.status == 200) {
        getRolesList();
        setModalVisible(false);
        ANTD.message.success("Role Updated successfully", 3);
      } else {
        ANTD.message.error(" Error Uploading Data", 3);
      }
    });
  };

  function getInputValue(event) {
    setValue(event.target.value);
  }

  let lastIndex = 0;
  const uniqueKey = () => {
    lastIndex++;
    return lastIndex;
  };

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
    },
    {
      title: "Role Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },

    {
      title: "Created Date",
      dataIndex: "CreatedDate",
    },
    {
      title: "Modified Date",
      dataIndex: "ModifiedDate",
    },
    {
      title: "Actions",
      render: (rec) => tableActions(rec),
    },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = rolesList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setRolesList(searchResults);
    } else {
      getRolesList();
      //  setRolesList(rolesList);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );
  const saverole = (val) => {
    let roleObj = {
      Name: val.role,
      Level: "1",
      CreatedBy: 1,
    };
    if (showUpdateBtn) updateRole(roleObj);
    else add(roleObj);
  }
  return (
    <div>
      <div className="manage-markup-section">
        <div className="container-fluid">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          View Roles{" "}
                          <HelpInfoHelper screenName={"/admin/role"} />
                        </h5>
                        <p>{rolesList.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <ANTD.Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData(rolesList)}
                      />
                      <img
                        src={excel}
                        alt="excel"
                        onClick={(e) => props.exportExcel(excelList, "Roles")}
                      />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getRolesList()}
                      />
                      <p className="add-deposit-icon" onClick={handleModal}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>
                  <div>
                    <ANTD.Table
                      scroll={{ x: true }}
                      bordered
                      rowKey={uniqueKey}
                      dataSource={rolesList}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </div>
      </div>

      <ANTD.Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong> Add Role </strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            {showUpdateBtn ? (
              <ANTD.Button key="add" type="primary" onClick={form.submit}>
                Update
              </ANTD.Button>
            ) : (
              <ANTD.Button key="add" type="primary" onClick={form.submit}>
                Add
              </ANTD.Button>
            )}
          </div>,
        ]}
      >
        <ANTD.Form layout={"vertical"} form={form} onFinish={saverole}>
          <ANTD.Row>
            <ANTD.Col span={24}>
              <ANTD.Form.Item
                label="Role"
                name="role"
                rules={[{ required: true, message: "Please enter role" }]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>
        </ANTD.Form>
      </ANTD.Modal>
    </div>
  );
};
export default UpdatedComponent(Role);
