import React from "react";
import {
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import bg from "../../../assets/images/bg/ticketbg.png";
import shield from "../../../assets/images/bg/shield.png";
import LogoImg from "../../../assets/images/Anjmallogo.jpg";
import moment from "moment";
import ReactHTMLParser from 'react-html-parser';

import Html from "react-pdf-html";

let dateFormat = "MMM, DD-YYYY";
const csssheet = {
  p: {
    margin: 0,
    fontSize: 6,
  },
};

const styles = StyleSheet.create({
  textDanger: {
    color: "#dc3545",
  },

  mb: {
    marginBottom: 10,
  },

  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    flexGrow: 1,
  },

  wHalf: {
    flex: 0.5,
    marginBottom: 10,
  },
  tw: {
    flex: 0.2,
  },
  fourthHalf: {
    flex: 0.25,
    paddingRight: 5,
  },

  width32: {
    flex: 0.32,
  },
  trw: {
    flex: 0.3,
  },
  fw: {
    flex: 0.4,
  },
  sw: {
    flex: 0.6,
  },
  sew: {
    flex: 0.7,
  },

  mb8: {
    marginBottom: 8,
  },
  description: {
    paddingLeft: 5,
  },

  hr: {
    borderBottom: "1px solid #e2e2e2",
    flex: 1,
    margin: "10px 0",
  },

  page: {
    flexDirection: "row",
    padding: "15px 10px",
  },
  section: {
    flex: 1,
  },
  innerSection: {
    flex: 1,
    position: "relative",
  },
  pageBg: {
    position: "absolute",
    flex: 1,
    width: "100%",
    height: 100,
  },
  busWrapper: {
    padding: "25px 10px 10px 10px",
  },

  whiteText: { color: "#fff", fontSize: 12, marginBottom: 10, fontWeight: 600 },
  title: { fontSize: 12, fontWeight: 600, wordBreak: "break-word" },
  fadetitle: { color: "#a8a8a8", fontSize: 9, marginBottom: 2 },
  smallText: { fontSize: 10, marginBottom: 2 },
  xsmallText: {
    fontSize: 9,
    marginBottom: 3,
  },
  greytext: { color: "#666", fontSize: 9 },
  card: {
    padding: "10px 5px",
    marginBottom: 10,
    border: "1px solid #e2e2e2",
    borderRadius: 4,
    backgroundColor: "#FFFFFF",
  },

  insuranceCard: {
    border: "1px solid #02cb66",
    borderRadius: 6,
    textAlign: "center",
    padding: "10px 5px",
  },

  insurancetitle: {
    color: "#18a160",
    fontSize: 10,
  },

  dottedBorder: {
    borderTop: "1px dotted #a8a8a8",
    width: "100%",
    textAlign: "center",
  },

  coverage: {
    color: "#294782",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "10px 0",
  },

  coveragetitle: {
    color: "#294782",
    fontSize: 9,
  },
});
export const getVisaTicketStatus = (status) => {
  switch (status) {
    case 1:
      return <p style={{ color: "#f9e218" }}>CREATED</p>;

    case 2:
      return <p style={{ color: "#FFA500" }}>BLOCKED</p>;

    case 3:
      return <p style={{ color: "#008000" }}>CONFIRMED</p>;

    default:
      return "";
  }
};
const parser = (str) => {
  if (str) {
    let doc = new DOMParser().parseFromString(str, "text/html");
    // console.log(doc);
    return doc.firstChild.innerHTML;

    
  }
};
const VisaDoc = ({ticketData, Logo, withFare}) => {
   // {console.log(ticketData,"ticketData")}
  const BASE = process.env.REACT_APP_BASE_URL;

  let discount=0

  const totalAmount =Number(ticketData.visa.Fees);

  let promoData = JSON.parse(ticketData.promoData);
  if (promoData) {
    if (promoData.DiscountType == 1) {
      discount = Number((Number(totalAmount) / 100) * promoData?.Discount);
    } else {
      discount = Number(promoData.Discount);
    }
  }
  return (
    <Document>
      <Page Page size="A4" style={styles.page} wrap={true}>
        {ticketData?.BookingStatus ? (
          <View style={styles.section}>
          {Logo ? (
            <Image
              src={BASE + Logo.substring(1)}
              style={{ height: 50, width: 100, marginBottom: 10 }}
            ></Image>
          ) : (
            <Image style={{ width: 100, heigh: 50 }} src={LogoImg} />
          )}
          <View style={styles.innerSection}>
            <Image src={bg} style={styles.pageBg} />
            <View style={styles.busWrapper}>
              

              <View style={styles.card} wrap={false}>
                <View style={styles.row}>
                  <View style={styles.fw}>
                    <Text style={styles.title}>{ticketData.visa.Country}</Text>
                    <Text style={styles.fadetitle}>{ticketData.visa.Continent}</Text>
                  </View>

                  <View style={styles.fw}>
                    <Text style={styles.title}>{ticketData.ReferenceNumber}</Text>
                    <Text style={styles.fadetitle}>Visa Reference Number</Text>
                  </View>

                  <View style={styles.sw}>
                    <Text style={styles.title}>
                      {ticketData.BookingConfirmationNumber}
                    </Text>
                    <Text style={styles.fadetitle}>
                      Booking Confirmation No
                    </Text>
                  </View>
                </View>

                <View style={styles.hr}></View>

                <View style={styles.row}>
                  <View style={styles.fourthHalf}>
                    <Text style={styles.title}>
                      {ticketData.visaId}
                    </Text>
                    <Text style={styles.fadetitle}>Visa ID</Text>
                  </View>
                  <View style={styles.fourthHalf}>
                    <Text style={styles.title}>
                      {getVisaTicketStatus(ticketData.BookingStatus)}
                    </Text>
                    <Text style={styles.fadetitle}>Status</Text>
                  </View>
                  <View style={styles.fourthHalf}>
                    <Text style={styles.title}>
                      {ticketData.passengers.length}
                    </Text>
                    <Text style={styles.fadetitle}>Number of Passengers</Text>
                  </View>
                  <View style={styles.fourthHalf}>
                    <Text style={styles.title}>Paid</Text>
                    <Text style={styles.fadetitle}>Payment Status</Text>
                  </View>
                </View>
              </View>
              {/* Customer info */}

              <View style={styles.card} wrap={false}>
                <View style={styles.mb}>
                  <Text style={styles.title}>Visa Details</Text>
                  <Text style={styles.fadetitle}>
                  Here are the details of your Visa for Dubai Visa Online
                  </Text>
                </View>

                <View style={styles.row}>
                  <View style={styles.width32}>
                    <Text style={styles.fadetitle}>Visa Name</Text>
                    <Text style={styles.smallText}>
                      {ticketData.visa.VisaName}
                    </Text>
                  </View>

                   <View style={styles.width32}>
                    <Text style={styles.fadetitle}>PassportNumber</Text>
                    <Text style={styles.smallText}>
                      {ticketData.passengers[0]?.passportNumber}
                    </Text>
                  </View> 
                  
                  {/* <View style={styles.width32}>
                    <Text style={styles.fadetitle}>Nationality</Text>
                    <Text style={styles.smallText}>
                      {ticketData.customerNationality}
                    </Text>
                  </View> */}

                  <View style={styles.width32}>
                    <Text style={styles.fadetitle}>Visa Type</Text>
                    <Text style={styles.smallText}>
                      {ticketData.visa.TypeofVisa}
                    </Text>
                  </View>
                  <View style={styles.width32}>
                    <Text style={styles.fadetitle}>Validity</Text>
                    <Text style={styles.smallText}>
                      {ticketData.visa.Validity} Months
                    </Text>
                  </View>
                </View>

                <View style={styles.row}>
                  <View style={styles.width32}>
                    <Text style={styles.fadetitle}>Processing Time</Text>
                    <Text style={styles.smallText}>
                      {ticketData.visa.ProcessingTime} days
                    </Text>
                  </View>
                  <View style={styles.width32}>
                    <Text style={styles.fadetitle}>Stayperiod</Text>
                    <Text style={styles.smallText}>
                      {ticketData.visa.Stayperiod} Months
                    </Text>
                  </View>
                 
                  <View style={styles.width32}>
                    <Text style={styles.fadetitle}>Entry</Text>
                    <Text style={styles.smallText}>
                      {ticketData.visa.Entry}
                    </Text>
                  </View>
                  <View style={styles.width32}>
                    <Text style={styles.fadetitle}>Applied Date</Text>
                    <Text style={styles.smallText}>
                      {moment(ticketData.createdDate).format(dateFormat)}
                    </Text>
                  </View>

                  
                </View>
              </View>

              
              <View style={styles.card} wrap={false}>
                  <View style={styles.mb}>
                    <Text style={styles.title}>Contact Details</Text>
                    
                  </View>

                  <View style={styles.row}>
                    <View style={styles.width32}>
                      <Text style={styles.fadetitle}>Name</Text>
                      <Text style={styles.smallText}>
                        {`${ticketData.customerFirstName} ${ticketData.customerLastName}`}
                      </Text>
                    </View>

                    <View style={styles.width32}>
                      <Text style={styles.fadetitle}>Mobile Number</Text>
                      <Text style={styles.smallText}>
                        {ticketData.customerMobile}
                      </Text>
                    </View>

                    <View style={styles.width32}>
                      <Text style={styles.fadetitle}>Email</Text>
                      <Text style={styles.smallText}>
                        {ticketData.customerEmail}
                      </Text>
                    </View>
                  </View>
                </View>

              {/* Passenger details */}

              <View style={styles.card} wrap={false}>
                <View style={styles.mb}>
                  <Text style={styles.title}>Passenger Details</Text>
                </View>
                {ticketData.passengers.map((passenger, i) => (
                  <View style={styles.row} key={i + "passenger"}>
                    <View style={styles.width32}>
                      <Text style={styles.fadetitle}>Name</Text>
                      <Text style={styles.smallText}>
                        {`${passenger.firstName} ${passenger.lastName}`}
                      </Text>
                    </View>

                    <View style={styles.width32}>
                      <Text style={styles.fadetitle}>Date of Birth</Text>
                      <Text style={styles.smallText}>{moment(passenger.dob).format(dateFormat)}</Text>
                    </View>

                    <View style={styles.width32}>
                    <Text style={styles.fadetitle}>Nationality</Text>
                    <Text style={styles.smallText}>
                      {passenger.nationality}
                    </Text>
                  </View>

                    {/* <View style={styles.tw}>
                      <Text style={styles.fadetitle}>Passport No.</Text>
                      <Text style={styles.smallText}>
                        {passenger.passportNumber}
                      </Text>
                    </View> */}
                  </View>
                ))}
              </View>

              {/* Visa details */}

             

              {/* Payment details */}

              {withFare ? (
                  <View>
                    <View style={styles.card} wrap={false}>
                      <View style={styles.mb}>
                        <Text style={styles.title}>Fare Details</Text>
                      </View>

                      <View style={{ ...styles.row, ...styles.mb8 }}>
                        <View style={styles.sw}>
                          <Text style={styles.smallText}>Base Fare</Text>
                        </View>

                        <View style={styles.fourthHalf}>
                          <Text style={styles.smallText}>  {ticketData?.currency}  {parseFloat((Number(ticketData.visa.Fees) / Number(ticketData?.currencyRatio)).toFixed(2))}</Text>
                        </View>
                      </View>






                      {/* {ticketData?.insuranceRequired === 1 &&
                      ticketData.insuranceData ? (
                        <View style={{ ...styles.row, ...styles.mb8 }}>
                          <View style={styles.sw}>
                            <Text style={styles.smallText}>
                              Insurance Amount
                            </Text>
                          </View>

                          <View style={styles.fourthHalf}>
                            <Text style={styles.smallText}>
                              INR {ticketData.insuranceData??0}
                            </Text>
                          </View>
                        </View>
                      ) : null} */}
                      {discount > 0 ? 
                      <View style={{ ...styles.row, ...styles.mb8 }}>
                        <View style={styles.sw}>
                          <Text style={styles.smallText}>Discount</Text>
                        </View>

                        <View style={styles.fourthHalf}>
                          <Text style={styles.smallText}>  {ticketData?.currency}  {parseFloat((Number(discount) / Number(ticketData?.currencyRatio)).toFixed(2))}</Text>
                        </View>
                      </View>
                      : null }


                      <View style={{ ...styles.row, ...styles.mb8 }}>
                        <View style={styles.sw}>
                          <Text style={styles.smallText}>Total</Text>
                        </View>

                        <View style={styles.fourthHalf}>
                          <Text style={styles.smallText}>  {ticketData?.currency}  {parseFloat((Number(ticketData.totalAmount) / Number(ticketData?.currencyRatio)).toFixed(2))}</Text>
                        </View>
                      </View>

                    </View>
                  </View>
                ) : null}

              {/* Additional sections can be added based on your requirements */}
              <View style={styles.card} wrap={false}>
              <View style={styles.mb}>
                    <Text style={styles.smallText}>whychooseUs</Text>
                    <Text style={styles.smallText}>
                      {ReactHTMLParser(ticketData.visa.whychooseUs)} 
           </Text>
                  </View>
              </View>
            </View>
          </View>
          </View>
        ) : null }
      </Page>
    </Document>
  );
};

export default VisaDoc;
