import React from "react";
import { Col, Divider, Row, Skeleton } from "antd";
const CarSkeleton = () => {
  return (
    <>
      <div className="sort-by-filter-bus">
        <Skeleton active={true} paragraph={{ rows: 0 }} />
      </div>
      <div className="skeleton-bus-results-card ">
        <Row gutter={16}>
          <Col md={8} sm={8} xs={8} className="skeleton-travels-name">
            <Skeleton active={true} paragraph={{ rows: 1 }} />
          </Col>
          <Col md={12} sm={12} xs={12}>
            <Row gutter={16}>
              <Col md={8} sm={8} xs={8} className="skeleton-timings">
                <Skeleton active={true} paragraph={{ rows: 2 }} />
              </Col>
              <Col
                md={16}
                sm={16}
                xs={16}
                className="skeleton-boarding-address"
              >
                <Skeleton active={true} paragraph={{ rows: 1 }} />
              </Col>
            </Row>
          </Col>
          <Col md={4} sm={4} xs={4} className="skeleton-bus-price">
            <Skeleton active={true} paragraph={{ rows: 2 }} />
          </Col>
        </Row>

        <Divider />
        <div className="skeleton-bus-tabs">
          <Row
            gutter={16}
            style={{
              justifyContent: "flex-end",
            }}
          >
            <Col md={4} sm={4} xs={4}>
              <Skeleton active={true} paragraph={{ rows: 0 }} />
            </Col>
            <Col md={4} sm={4} xs={4}>
              <Skeleton active={true} paragraph={{ rows: 0 }} />
            </Col>
            <Col md={4} sm={4} xs={4}>
              <Skeleton active={true} paragraph={{ rows: 0 }} />
            </Col>
            <Col md={4} sm={4} xs={4}>
              <Skeleton active={true} paragraph={{ rows: 0 }} />
            </Col>
          </Row>
        </div>
      </div>
      <div className="skeleton-bus-results-card">
        <Row gutter={16}>
          <Col md={8} sm={8} xs={8} className="skeleton-travels-name">
            <Skeleton active={true} paragraph={{ rows: 1 }} />
          </Col>
          <Col md={12} sm={12} xs={12}>
            <Row gutter={16}>
              <Col md={8} sm={8} xs={8} className="skeleton-timings">
                <Skeleton active={true} paragraph={{ rows: 2 }} />
              </Col>
              <Col
                md={16}
                sm={16}
                xs={16}
                className="skeleton-boarding-address"
              >
                <Skeleton active={true} paragraph={{ rows: 1 }} />
              </Col>
            </Row>
          </Col>
          <Col md={4} sm={4} xs={4} className="skeleton-bus-price">
            <Skeleton active={true} paragraph={{ rows: 2 }} />
          </Col>
        </Row>

        <Divider />
        <div className="skeleton-bus-tabs">
          <Row
            gutter={16}
            style={{
              justifyContent: "flex-end",
            }}
          >
            <Col md={4} sm={4} xs={4}>
              <Skeleton active={true} paragraph={{ rows: 0 }} />
            </Col>
            <Col md={4} sm={4} xs={4}>
              <Skeleton active={true} paragraph={{ rows: 0 }} />
            </Col>
            <Col md={4} sm={4} xs={4}>
              <Skeleton active={true} paragraph={{ rows: 0 }} />
            </Col>
            <Col md={4} sm={4} xs={4}>
              <Skeleton active={true} paragraph={{ rows: 0 }} />
            </Col>
          </Row>
        </div>
      </div>
      <div className="skeleton-bus-results-card">
        <Row gutter={16}>
          <Col md={8} sm={8} xs={8} className="skeleton-travels-name">
            <Skeleton active={true} paragraph={{ rows: 1 }} />
          </Col>
          <Col md={12} sm={12} xs={12}>
            <Row gutter={16}>
              <Col md={8} sm={8} xs={8} className="skeleton-timings">
                <Skeleton active={true} paragraph={{ rows: 2 }} />
              </Col>
              <Col
                md={16}
                sm={16}
                xs={16}
                className="skeleton-boarding-address"
              >
                <Skeleton active={true} paragraph={{ rows: 1 }} />
              </Col>
            </Row>
          </Col>
          <Col md={4} sm={4} xs={4} className="skeleton-bus-price">
            <Skeleton active={true} paragraph={{ rows: 2 }} />
          </Col>
        </Row>

        <Divider />
        <div className="skeleton-bus-tabs">
          <Row
            gutter={16}
            style={{
              justifyContent: "flex-end",
            }}
          >
            <Col md={4} sm={4} xs={4}>
              <Skeleton active={true} paragraph={{ rows: 0 }} />
            </Col>
            <Col md={4} sm={4} xs={4}>
              <Skeleton active={true} paragraph={{ rows: 0 }} />
            </Col>
            <Col md={4} sm={4} xs={4}>
              <Skeleton active={true} paragraph={{ rows: 0 }} />
            </Col>
            <Col md={4} sm={4} xs={4}>
              <Skeleton active={true} paragraph={{ rows: 0 }} />
            </Col>
          </Row>
        </div>
      </div>
      <div className="skeleton-bus-results-card">
        <Row gutter={16}>
          <Col md={8} sm={8} xs={8} className="skeleton-travels-name">
            <Skeleton active={true} paragraph={{ rows: 1 }} />
          </Col>
          <Col md={12} sm={12} xs={12}>
            <Row gutter={16}>
              <Col md={8} sm={8} xs={8} className="skeleton-timings">
                <Skeleton active={true} paragraph={{ rows: 2 }} />
              </Col>
              <Col
                md={16}
                sm={16}
                xs={16}
                className="skeleton-boarding-address"
              >
                <Skeleton active={true} paragraph={{ rows: 1 }} />
              </Col>
            </Row>
          </Col>
          <Col md={4} sm={4} xs={4} className="skeleton-bus-price">
            <Skeleton active={true} paragraph={{ rows: 2 }} />
          </Col>
        </Row>

        <Divider />
        <div className="skeleton-bus-tabs">
          <Row
            gutter={16}
            style={{
              justifyContent: "flex-end",
            }}
          >
            <Col md={4} sm={4} xs={4}>
              <Skeleton active={true} paragraph={{ rows: 0 }} />
            </Col>
            <Col md={4} sm={4} xs={4}>
              <Skeleton active={true} paragraph={{ rows: 0 }} />
            </Col>
            <Col md={4} sm={4} xs={4}>
              <Skeleton active={true} paragraph={{ rows: 0 }} />
            </Col>
            <Col md={4} sm={4} xs={4}>
              <Skeleton active={true} paragraph={{ rows: 0 }} />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default CarSkeleton;
