import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import { Tabs } from "antd";
import "./Travelrequests.scss";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Divider,
  Select,
  DatePicker,
  Layout,
  Card,
} from "antd";
import {
  CheckCircleOutlined,
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  AppleOutlined,
  AndroidOutlined,
  SelectOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const Travelrequests = () => {
  const { Header, Footer, Sider, Content } = Layout;
  const { TabPane } = Tabs;
  return (
    <div className="collapseCustomWhite">
      <div className=" providerapi">
        <Layout className="ourteam-bg-panel">
          <div className="thankyou-panel1">
            <div className="page-width-1">
              <Row justify="center">
                <Col md="24" xs={24}>
                  <h3>Travel Requests</h3>
                </Col>
              </Row>

              <Card>
                <Row justify="center">
                  <Col md={8} xs={24}>
                    <div>
                      <h4>
                        <a className="scroll-color" href="#card-resid-1">
                          <i className="fa fa-suitcase" aria-hidden="true"></i>
                          &nbsp; SHORTLISTED BY TRAVEL DESK
                        </a>
                      </h4>
                    </div>
                  </Col>
                  <Col md={8} xs={24}>
                    <div>
                      <h4>
                        <a className="scroll-color" href="#card-resid-2">
                          <i className="fa fa-suitcase" aria-hidden="true"></i>
                          &nbsp; YOUR TRAVEL REQUESTS
                        </a>
                      </h4>
                    </div>
                  </Col>
                  <Col md={8} xs={24}>
                    <div>
                      <h4>
                        <a className="scroll-color" href="#card-resid-3">
                          <i className="fa fa-suitcase" aria-hidden="true"></i>
                          &nbsp; FOR YOU TO APPROVE
                        </a>
                      </h4>
                    </div>
                  </Col>
                </Row>
              </Card>

              <Card className="card-request" id="card-resid-1">
                <Row justify="center">
                  <Col md={24} xs={24}>
                    <p className="top-bar-one">
                      <i className="fa fa-suitcase" aria-hidden="true"></i>
                      &nbsp;SHORTLISTED BY TRAVEL DESK
                    </p>
                    <p className="top-bar-one-1">
                      You don’t have any Shortlisted Travel Options
                    </p>
                  </Col>
                </Row>
              </Card>
              <Card className="card-request" id="card-resid-2">
                <Row justify="center">
                  <Col md={24} xs={24}>
                    <p className="top-bar-one">
                      <i className="fa fa-suitcase" aria-hidden="true"></i>
                      &nbsp;RAISED BY YOU
                    </p>
                    <p>No requests</p>
                  </Col>
                </Row>

                <Card>
                  <p className="approad-ct">APPROVED</p>
                  <Row justify="center" className="ct-flight-card">
                    <Col md={2} xs={24}>
                      <img
                        src={
                          require("../../assets/images/ct-flight.PNG").default
                        }
                        alt=""
                      />
                    </Col>
                    <Col md={8} xs={24}>
                      <div>
                        <p className="one-ct-1">Flight to New Delhi</p>
                        <p className="one-ct-2">New Delhi To Bengaluru</p>
                        <p className="one-ct-3">Wed, 23 Feb' 2022</p>
                      </div>
                    </Col>
                    <Col md={4} xs={24}>
                      <div>
                        <p className="one-ct-tr">Travellers</p>
                        <p className="one-ct-2">Hikmat Bk</p>
                      </div>
                    </Col>
                    <Col md={6} xs={24}>
                      <p className="one-ct-tr">Approved by</p>
                      <p className="one-ct-2">Bhargava V</p>
                      <p className="one-ct-3">On: Tue, 22 Feb' 2022</p>
                    </Col>
                    <Col md={4} xs={24}>
                      <Button className="ct-details-btn">View Details</Button>
                    </Col>
                  </Row>
                </Card>

                <Card className="inner-ct-card">
                  <p className="approad-1">Pending</p>
                  <Row justify="center" className="ct-flight-card">
                    <Col md={2} xs={24}>
                      <img
                        src={
                          require("../../assets/images/ct-hotel.PNG").default
                        }
                        alt=""
                      />
                    </Col>
                    <Col md={8} xs={24}>
                      <div>
                        <p className="one-ct-1">Hotel in Delhi</p>
                        <div className="star-rating-icon">
                          <p className="one-ct-2">Crowne Plaza Okhla</p>
                          <ul>
                            <li>
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </li>
                            <li>
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </li>
                            <li>
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </li>
                            <li>
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </li>
                            <li>
                              <i className="fa fa-star" aria-hidden="true"></i>
                            </li>
                          </ul>
                        </div>

                        <p className="one-ct-3">
                          Tue, 15 Mar' 2022-Wed, 16 Mar' 2022 | Plot No. 1,
                          Community Centre, Okhla Phase - 1, New Delhi-110020
                        </p>
                        <Button className="out-ct-policy">OUT OF POLICY</Button>
                      </div>
                    </Col>
                    <Col md={4} xs={24}>
                      <div>
                        <p className="one-ct-tr">Travellers</p>
                        <p className="one-ct-2">Hikmat Bk</p>
                        <p className="one-ct-3">1 Night</p>
                      </div>
                    </Col>
                    <Col md={6} xs={24}>
                      <p className="one-ct-tr">Approved by</p>
                      <p className="one-ct-2">Admin</p>
                      <p className="one-ct-3">On: Tue, 22 Feb' 2022</p>
                    </Col>
                    <Col md={4} xs={24}>
                      <Button className="ct-details-btn">View Details</Button>
                    </Col>
                  </Row>
                </Card>

                <Card className="inner-ct-card">
                  <p className="approad-1">Pending</p>
                  <Row justify="center" className="ct-flight-card">
                    <Col md={2} xs={24}>
                      <img
                        src={require("../../assets/images/ct-car.png").default}
                        alt=""
                      />
                    </Col>
                    <Col md={8} xs={24}>
                      <div>
                        <p className="one-ct-1">
                          Indica Swift<span></span>
                        </p>
                        <p className="one-ct-2">HATCHBACK | AC | 4Seats</p>
                        <p className="one-ct-2">
                          Economical Car with Mysafety Promise
                        </p>
                        <p className="one-ct-3">28 kms included</p>
                        <p className="one-ct-3">24.0/km after 28kms</p>
                        <p className="one-ct-3">
                          Free cacelllation, till departure time
                        </p>
                        <Button className="out-ct-policy">OUT OF POLICY</Button>
                      </div>
                    </Col>
                    <Col md={4} xs={24}>
                      <div>
                        <p className="one-ct-tr">Travellers</p>
                        <p className="one-ct-2">Hikmat Bk</p>
                        <p className="one-ct-3">1 Night</p>
                      </div>
                    </Col>
                    <Col md={6} xs={24}>
                      <p className="one-ct-tr">Approved by</p>
                      <p className="one-ct-2">Admin</p>
                      <p className="one-ct-3">On: Tue, 22 Feb' 2022</p>
                    </Col>
                    <Col md={4} xs={24}>
                      <Button className="ct-details-btn">View Details</Button>
                    </Col>
                  </Row>
                </Card>
              </Card>
              <Card className="card-request" id="card-resid-3">
                <Row justify="center">
                  <Col md={24} xs={24}>
                    <p className="top-bar-one">
                      <i className="fa fa-suitcase" aria-hidden="true"></i>
                      &nbsp;SHORTLISTED BY TRAVEL DESK
                    </p>
                    <p>No requests</p>
                  </Col>
                </Row>

                <Card>
                  <p className="approad-ct">APPROVED</p>
                  <Row justify="center" className="ct-flight-card">
                    <Col md={2} xs={24}>
                      <img
                        src={
                          require("../../assets/images/ct-flight.PNG").default
                        }
                        alt=""
                      />
                    </Col>
                    <Col md={8} xs={24}>
                      <div>
                        <p className="one-ct-1">Flight to New Delhi</p>
                        <p className="one-ct-2">New Delhi To Bengaluru</p>
                        <p className="one-ct-3">Wed, 23 Feb' 2022</p>
                      </div>
                    </Col>
                    <Col md={4} xs={24}>
                      <div>
                        <p className="one-ct-tr">Travellers</p>
                        <p className="one-ct-2">Hikmat Bk</p>
                      </div>
                    </Col>
                    <Col md={6} xs={24}>
                      <p className="one-ct-tr">Approved by</p>
                      <p className="one-ct-2">Bhargava V</p>
                      <p className="one-ct-3">On: Tue, 22 Feb' 2022</p>
                    </Col>
                    <Col md={4} xs={24}>
                      <Button className="ct-details-btn">View Details</Button>
                    </Col>
                  </Row>
                </Card>

                <Card className="inner-ct-card">
                  <p className="approad-1">Pending</p>
                  <Row justify="center" className="ct-flight-card">
                    <Col md={2} xs={24}>
                      <img
                        src={
                          require("../../assets/images/ct-hotel.PNG").default
                        }
                        alt=""
                      />
                    </Col>
                    <Col md={8} xs={24}>
                      <div>
                        <p className="one-ct-1">Hotel in Delhi</p>
                        <p className="one-ct-2">Crowne Plaza Okhla</p>
                        <p className="one-ct-3">
                          Tue, 15 Mar' 2022-Wed, 16 Mar' 2022 | Plot No. 1,
                          Community Centre, Okhla Phase - 1, New Delhi-110020
                        </p>
                        <Button className="out-ct-policy">OUT OF POLICY</Button>
                      </div>
                    </Col>
                    <Col md={4} xs={24}>
                      <div>
                        <p className="one-ct-tr">Travellers</p>
                        <p className="one-ct-2">Hikmat Bk</p>
                        <p className="one-ct-3">1 Night</p>
                      </div>
                    </Col>
                    <Col md={6} xs={24}>
                      <p className="one-ct-tr">Approved by</p>
                      <p className="one-ct-2">Admin</p>
                      <p className="one-ct-3">On: Tue, 22 Feb' 2022</p>
                    </Col>
                    <Col md={4} xs={24}>
                      <Button className="ct-details-btn">View Details</Button>
                    </Col>
                  </Row>
                </Card>

                <Card className="inner-ct-card">
                  <p className="approad-1">Pending</p>
                  <Row justify="center" className="ct-flight-card">
                    <Col md={2} xs={24}>
                      <img
                        src={require("../../assets/images/ct-car.png").default}
                        alt=""
                      />
                    </Col>
                    <Col md={8} xs={24}>
                      <div>
                        <p className="one-ct-1">Indica Swift</p>
                        <p className="one-ct-2">
                          Economical Car with Mysafety Promise
                        </p>
                        <p className="one-ct-3">28 kms included</p>
                        <p className="one-ct-3">24.0/km after 28kms</p>
                        <p className="one-ct-3">
                          Free cacelllation, till departure time
                        </p>
                        <Button className="out-ct-policy">OUT OF POLICY</Button>
                      </div>
                    </Col>
                    <Col md={4} xs={24}>
                      <div>
                        <p className="one-ct-tr">Travellers</p>
                        <p className="one-ct-2">Hikmat Bk</p>
                        <p className="one-ct-3">1 Night</p>
                      </div>
                    </Col>
                    <Col md={6} xs={24}>
                      <p className="one-ct-tr">Approved by</p>
                      <p className="one-ct-2">Admin</p>
                      <p className="one-ct-3">On: Tue, 22 Feb' 2022</p>
                    </Col>
                    <Col md={4} xs={24}>
                      <Button className="ct-details-btn">View Details</Button>
                    </Col>
                  </Row>
                </Card>
              </Card>
            </div>
          </div>
        </Layout>
      </div>
    </div>
  );
};

export default Travelrequests;
