import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Switch,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Upload,
  DatePicker,
  Select,
  Table,
  Image,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  IdcardOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import search from "../../assets/vector-icons/search.png";
import excel from "../../assets/vector-icons/excel.png";
import pdf from "../../assets/vector-icons/pdf.png";
import ApiClient from "../../../helpers/ApiClient";

const ManageDesignations = () => {
  const { Content } = Layout;
  const [modalVisible, setModalVisible] = React.useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [formManageDesignation] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [currentID, setCurrentID] = useState(-1);
  const [teams, setTeams] = useState([]);
  const [degisnationList, setDegisnationList] = useState([]);
  const [formLayout, setFormLayout] = React.useState("vertical");

  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 23 },
        }
      : null;

  const add = () => {
    message.success("Buses Markup Added Successfully...", 3);
    setModalVisible(false);
  };
  const clear = () => {
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const closeModal = () => {
    setModalVisible({
      visible: false,
    });
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              handleEditClick(rec);
              setCurrentID(rec.DesignationId);
            }}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteDesignation(rec.DesignationId)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const deleteDesignation = (id) => {
    ApiClient.delete("CorporateExtranet/DeleteDesignation/" + id).then(
      (res) => {
        if (res.status == 200) {
          message.success("Designation Deleted  successfully", 3);
          getAllDesignation();
        }
      }
    );
  };
  const handleEditClick = (rec) => {
    formManageDesignation.setFieldsValue({
      TeamId: rec.TeamId ?? "",
      Description: rec.Description ?? "",
      Status: rec.Status ?? "",
    });

    setIsEditMode(true);

    showModal();
  };
  const handleAddClick = () => {
    formManageDesignation.resetFields();
    showModal();
    setIsEditMode(false);
  };
  const handleSubmit = (formvalues) => {
    if (isEditMode) {
      submitUpdateDesignation(formvalues);
    } else {
      submitNewDesignation(formvalues);
    }
  };
  const submitUpdateDesignation = (formvalues) => {
    const updatedFormData = {
      ...formvalues,
    };
    ApiClient.put(
      "CorporateExtranet/UpdateDesignation/" + currentID,
      {},
      updatedFormData
    ).then((response) => {
      // console.log(response);
      if (response.status === 200) {
        setModalVisible(false);
        message.success("Designation updated successfully");
        getAllDesignation();
        formManageDesignation.resetFields();
      } else {
        message.error(" Error Updating Designation");
      }
    });
  };
  const submitNewDesignation = (formvalues) => {
    ApiClient.post("CorporateExtranet/CreateDesignation", formvalues).then(
      (response) => {
        if (response.status === 200) {
          setModalVisible(false);
          message.success("Designation created successfully");
          getAllDesignation();
          formManageDesignation.resetFields();
        } else {
          message.error(" Error Creating Designation");
        }
      }
    );
  };
  useEffect(() => {
    getAllDesignation();
    getAllTeams();
  }, []);
  const getAllTeams = () => {
    ApiClient.get("CorporateExtranet/GetTeams")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setTeams(data);
        }
      })
      .catch((error) => {});
  };
  const getAllDesignation = () => {
    ApiClient.get("CorporateExtranet/GetDesignation")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setDataSource(data);
          setDegisnationList(data.length);
        }
      })
      .catch((error) => {});
  };
  const columns = [
    {
      title: "Team Name",
      dataIndex: "TeamName",
    },
    {
      title: "Description",
      dataIndex: "Description",
    },
    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];
  const handelStatus = (data) => {
    if (data.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };
  const changeStatus = (info) => {
    // let obj = {
    //   Id: info.CarTypeID,
    //   status: Status,
    // };
    // ApiClient.put("carextranet/toggleCarTypeStatus/" + info.CarTypeID)
    //   .then((response) => {
    //     if (response.status == 200) {
    //       message.success("Car Type Status updated successfully !");
    //       getAllCarType();
    //     } else {
    //       message.error(response.message, 3);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };
  return (
    <div>
      <div className="promo providerapi">
        <Layout>
          <div className="manage-markup-section w-100">
            <Content className="admin-container">
              <Row>
                <Col span={24}>
                  <Card>
                    <div className="card-add-heading">
                      <div className="rows-count">
                        <div>
                          <h5>Manage Designations Details</h5>
                          <p>{degisnationList ?? 0} rows found !</p>
                        </div>
                      </div>
                      <div
                        className="exlpdf"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <img
                          src={search}
                          alt="search"
                          style={{ maxHeight: "35px", maxWidth: "35px" }}
                        />
                        <img
                          src={excel}
                          alt="excel"
                          style={{ maxHeight: "35px", maxWidth: "35px" }}
                        />
                        <p
                          className="add-icon"
                          onClick={() => handleAddClick()}
                        >
                          <PlusOutlined />
                        </p>
                      </div>
                    </div>

                    <Table
                      className="table-scroll-none"
                      style={{ color: "red" }}
                      columns={columns}
                      dataSource={dataSource}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </Card>
                </Col>
              </Row>
            </Content>
          </div>
        </Layout>

        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>Manage Designations</strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header modal-header-bg12"
          visible={modalVisible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          footer={[
            <div>
              <Button
                type="primary"
                onClick={() => formManageDesignation.submit()}
              >
                {isEditMode ? "Update" : "Add"}
              </Button>
              <Button
                type="primary"
                danger
                className="cancel-btn"
                onClick={() => formManageDesignation.resetFields()}
              >
                Clear
              </Button>
            </div>,
          ]}
        >
          <Form
            {...formItemLayout}
            layout={formLayout}
            initialValues={{ layout: formLayout }}
            form={formManageDesignation}
            onFinish={handleSubmit}
          >
            <Row>
              <Col md={12} xs={24}>
                <Form.Item
                  name="TeamId"
                  label="Team Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Username!",
                    },
                  ]}
                >
                  <Select
                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                    placeholder="Select Your Team"
                  >
                    {teams.length > 0 ? (
                      teams.map((i, ind) => (
                        <Select.Option key={"team" + ind} value={i.TeamId}>
                          {i.TeamName}
                        </Select.Option>
                      ))
                    ) : (
                      <Select.Option value={0}>No Team</Select.Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  name="Description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Description!",
                    },
                  ]}
                >
                  <Input
                    prefix={<EditOutlined className="site-form-item-icon" />}
                    placeholder="Some Team Description"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="Status"
                  label="Status"
                  rules={[
                    { required: true, message: "Please Select Your Status" },
                  ]}
                >
                  <Select
                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                    placeholder="Select Status"
                  >
                    <Select.Option value={0}>yes</Select.Option>
                    <Select.Option value={1}>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </div>
  );
};
export default ManageDesignations;
