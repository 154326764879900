import React, { useState} from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Select,
  Table,
  Tooltip,
} from "antd";
//import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
//import exchange from "../assets/vector-icons/exchange.png";
import { EyeOutlined } from "@ant-design/icons";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Option } = Select;
const { Panel } = Collapse;
const { Content } = Layout;

const APIAccessLogs = () => {
  const [formLayout, setFormLayout] = useState("vertical");
  const [showTable, setShowTable] = useState(false);
  const [ApiLogsForm] = Form.useForm();
  const [showRequestModal, setRequestModal] = useState(false);
  const [apiLogList, setApiLogList] = useState([]);
  const [hotelLogList, setHotelLogList] = useState([]);
  const [activityLogList, setActivityLogList] = useState([]);
  const [busLogList, setBusLogList] = useState([]);
  const [tabledataSource, setTableDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showAPIRequestModal, setAPIRequestModal] = useState(false);
  const [logRequest, setLogRequest] = useState("");
  const [logResponse, setLogResponse] = useState("");
  const [ApiRequest, setApiRequest] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const fromDateDis = moment();
  const [toDateDis, setToDateDis] = useState(moment());
  const [fromDateValue, setFromDateValue] = useState(moment());

  const getApiLogs = (val) => {
    setLoading(true);
    ApiClient.get("flights/airLog/search", val)
      .then((res) => {
        if (res.statusCode === 200) {
          setApiLogList(res.data);
          let data = res.data.filter((item, index) => index % 2 == 0);
          // console.log(data, res.data);
          setTableDataSource(data);
        }
        setShowTable(true);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const getHotelApiLogs = (val) => {
    setLoading(true);
    ApiClient.get("hotels-v2/hotelLog/search", val)
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          setHotelLogList(res.data);
          setTableDataSource(res.data);
          setShowTable(true);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("api error", e);
      });
  };
  const getBusApiLogs = (val) => {
    setLoading(true);
    ApiClient.get("buses/busLog/search", val)
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setBusLogList(data);
          setTableDataSource(data);
          setShowTable(true);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("api error", e);
      });
  };
  const getActivityApiLogs = (val) => {
    setLoading(true);
    ApiClient.get("activities/activitiesLog/search", val)
      .then((res) => {
        setLoading(false);
        if (res.statusCode === 200) {
          setActivityLogList(res.data);
          setTableDataSource(res.data);
          setShowTable(true);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("api error", e);
      });
  };

  function onChange(value) {}

  const Request = (rec) => {
    return (
      <span
        style={{ cursor: "pointer", color: "Green" }}
        onClick={(e) => {
          setLogRequest(JSON.stringify(rec.request, null, 4));
          setLogResponse(JSON.stringify(rec.response, null, 4));
          setRequestModal(true);
        }}
      >
        <EyeOutlined />
      </span>
    );
  };
  // const Response = () => {
  //     return (
  //         <span style={{ cursor: "pointer", color:"red" }} onClick={(e) => setResponseModal(true)}><EyeOutlined /></span>
  //     )
  // }
  const APIRequest = (rec) => {
    return (
      <span
        style={{ cursor: "pointer", color: "red" }}
        onClick={(e) => {
          let filter = apiLogList.filter((item) =>
            item.response
              ? item.logType === "1" &&
                item.response.traceId == rec.response.traceId
              : ""
          );
          if (filter.length < 1) {
            setAPIRequestModal(true);
            setApiRequest("");
            setApiResponse("");
            return;
          }
          setApiRequest(JSON.stringify(filter[0].request, null, 4));
          setApiResponse(JSON.stringify(filter[0].response, null, 4));
          setAPIRequestModal(true);
        }}
      >
        <EyeOutlined />
      </span>
    );
  };

  const hotelAPIRequest = (rec) => {
    return (
      <span
        style={{ cursor: "pointer", color: "red" }}
        onClick={(e) => {
          setApiRequest(JSON.stringify(rec.request, null, 4));
          setApiResponse(JSON.stringify(rec.response, null, 4));
          setAPIRequestModal(true);
        }}
      >
        <EyeOutlined />
      </span>
    );
  };
  // const APIResponse = () => {
  //     return (
  //         <span style={{ cursor: "pointer" }} onClick={(e) => setAPIResponseModal(true)}><EyeOutlined /></span>
  //     )
  // }

  const getApiProviderName = (rec) => {
    if (rec.apiMethodName === "Search") {
      return rec.response.fares && rec.response.fares.length > 0
        ? rec.response.fares[0].supplierName
        : "";
    } else if (rec.apiMethodName === "AirFareRules") {
      return rec.request ? rec.request.supplierName : "";
    } else {
      return "";
    }
  };

  const busAPIRequest = (rec) => {
    return (
      <div className="eyewrapper" style={{ cursor: "pointer", color: "Green" }}>
        <EyeOutlined
          onClick={(e) => {
            setApiRequest(JSON.stringify(rec.apiRequest, null, 4));
            setApiResponse(JSON.stringify(rec.apiResponse, null, 4));
            setAPIRequestModal(true);
          }}
        />
      </div>
    );
  };
  const flightColumns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      render: (item, rec, index) => index + 1,
    },
    {
      title: "Access Log Id",
      render: (item, rec) => (rec.response ? rec.response.traceId : ""),
    },
    // {
    //   title: "Access Date",
    //   dataIndex: "accessDate",
    // },
    // {
    //   title: "Domain Name",
    //   dataIndex: "domainName",
    // },
    // {
    //   title: "Client Name",
    //   dataIndex: "clientName",
    // },
    // {
    //   title: "Client Type",
    //   dataIndex: "clientType",
    // },
    {
      title: "API Provider Name",
      render: (item, rec) => getApiProviderName(rec),
    },
    {
      title: "Service",
      render: () => <>Flight</>,
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    // },
    {
      title: "API URL",
      dataIndex: "apiURL",
    },
    // {
    //   title: "IsBookingRequest",
    //   dataIndex: "isBookingRequest",
    // },
    // {
    //   title: "Role",
    //   dataIndex: "role",
    // },
    // {
    //   title: "Mobile",
    //   dataIndex: "mobile",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    // },
    {
      title: "Logs",
      render: (item, rec) => Request(rec),
    },
    {
      title: "API Logs",
      render: (item, rec) => APIRequest(rec),
    },
    // {
    //     title: "APIRequest",
    //     dataIndex: "apiRequest"
    // },
    // {
    //     title: "APIResponse",
    //     dataIndex: "apiResponse"
    // }
  ];

  const busColumns = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      sorter: (a, b) => a.SNo - b.SNo,
    },

    {
      title: "Service",
      render: () => <>Bus</>,
    },
    {
      title: "Dated",
      dataIndex: "createdAt",
      render: (d) => moment(d).format("DD-MM-YYYY hh:mm"),
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: "API Logs",
      render: (item, rec) => busAPIRequest(rec),
    },

    {
      title: "API URL",
      dataIndex: "apiURL",
      sorter: (a, b) => a.apiURL.localeCompare(b.apiURL),
    },
  ];
  const hotelColumns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      render: (item, rec, index) => index + 1,
    },
    // {
    //   title: "Access Log Id",
    //   render: (item, rec) => (rec.response ? rec.response.traceId : ""),
    // },
    // {
    //   title: "Access Date",
    //   dataIndex: "accessDate",
    // },
    // {
    //   title: "Domain Name",
    //   dataIndex: "domainName",
    // },
    // {
    //   title: "Client Name",
    //   dataIndex: "clientName",
    // },
    // {
    //   title: "Client Type",
    //   dataIndex: "clientType",
    // },
    // {
    //   title: "API Provider Name",
    //   render: (item, rec) => getApiProviderName(rec),
    // },
    {
      title: "Service",
      render: () => <>Hotel</>,
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    // },
    {
      title: "API URL",
      dataIndex: "apiURL",
    },
    // {
    //   title: "IsBookingRequest",
    //   dataIndex: "isBookingRequest",
    // },
    // {
    //   title: "Role",
    //   dataIndex: "role",
    // },
    // {
    //   title: "Mobile",
    //   dataIndex: "mobile",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    // },
    // {
    //   title: "Logs",
    //   render: (item, rec) => Request(rec),
    // },
    {
      title: "API Logs",
      render: (item, rec) => hotelAPIRequest(rec),
    },
    // {
    //     title: "APIRequest",
    //     dataIndex: "apiRequest"
    // },
    // {
    //     title: "APIResponse",
    //     dataIndex: "apiResponse"
    // }
  ];
  const activitiesColumns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      render: (item, rec, index) => index + 1,
    },
    // {
    //   title: "Access Log Id",
    //   render: (item, rec) => (rec.response ? rec.response.traceId : ""),
    // },
    // {
    //   title: "Access Date",
    //   dataIndex: "accessDate",
    // },
    // {
    //   title: "Domain Name",
    //   dataIndex: "domainName",
    // },
    // {
    //   title: "Client Name",
    //   dataIndex: "clientName",
    // },
    // {
    //   title: "Client Type",
    //   dataIndex: "clientType",
    // },
    // {
    //   title: "API Provider Name",
    //   render: (item, rec) => getApiProviderName(rec),
    // },
    {
      title: "Service",
      render: () => <>Activities</>,
    },
    // {
    //   title: "Email",
    //   dataIndex: "email",
    // },
    {
      title: "API URL",
      dataIndex: "apiURL",
    },
    // {
    //   title: "IsBookingRequest",
    //   dataIndex: "isBookingRequest",
    // },
    // {
    //   title: "Role",
    //   dataIndex: "role",
    // },
    // {
    //   title: "Mobile",
    //   dataIndex: "mobile",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    // },
    // {
    //   title: "Logs",
    //   render: (item, rec) => Request(rec),
    // },
    {
      title: "API Logs",
      render: (item, rec) => hotelAPIRequest(rec),
    },
    // {
    //     title: "APIRequest",
    //     dataIndex: "apiRequest"
    // },
    // {
    //     title: "APIResponse",
    //     dataIndex: "apiResponse"
    // }
  ];
  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 23 },
        }
      : null;

  const submitForm = (val) => {
    val.fromDate = val.fromDate ? val.fromDate.format("YYYY-MM-DD") : "";
    val.toDate = val.toDate ? val.toDate.format("YYYY-MM-DD") : "";

    setTableDataSource([]);
    setTableHeaders(val.serviceType);
    if (val.serviceType === 1) {
      getApiLogs(val);
    } else if (val.serviceType === 2) {
      getHotelApiLogs(val);
    } else if (val.serviceType === 3) {
      getBusApiLogs(val);
    } else if (val.serviceType === 4) {
      getActivityApiLogs(val);
    }
  };

  const setTableHeaders = (serviceType) => {
    switch (serviceType) {
      case 1:
        setColumns(flightColumns);
        break;
      case 2:
        setColumns(hotelColumns);
        break;
      case 3:
        setColumns(busColumns);
        break;
      case 4:
        setColumns(activitiesColumns);
        break;
      default:
        return;
    }
  };
  function copyToClipboard(text) {
    const elem = document.createElement("textarea");
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  }

  const handleTimeAndDate = (value) => {
    if (value === 5) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };
  const disabledFromDate = (currentDate) => {
    return currentDate > fromDateDis;
  };
  const disabledtoDate = (currentDate) => {
    return currentDate > toDateDis;
  };
  const onChangeFromDate = (date) => {
    setFromDateValue(date);
    let newTodate = moment(date).add(7, "days");
    setToDateDis(newTodate);
  };

  const APILogsHeader = () => (
    <>
      API Logs <HelpInfoHelper screenName={"/admin/apilogs"} />
    </>
  );

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <div>
                  <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <Tooltip
                        placement="top"
                        title={isActive ? "Show" : "Hide"}
                      >
                        {/* <CaretRightOutlined rotate={isActive ? 90 : -90} /> */}
                      </Tooltip>
                    )}
                    expandIconPosition="right"
                    className="panel_wrapper"
                    collapsible="header"
                    defaultActiveKey={["1"]}
                  >
                    <Panel header={<APILogsHeader />} key="1">
                      <Form
                        {...formItemLayout}
                        form={ApiLogsForm}
                        layout={formLayout}
                        initialValues={{
                          options: 1,
                          provider: "",
                          serviceType: "",
                        }}
                        onFinish={submitForm}
                      >
                        <Row gutter={16}>
                          <Col className="gutter-row" md={6} xs={24}>
                            <Form.Item
                              label="Time and Date"
                              name="options"
                              rules={[{ required: true }]}
                            >
                              <Select
                                placeholder="Please select"
                                onChange={handleTimeAndDate}
                              >
                                <Option value={1}>Last One Hour</Option>
                                <Option value={2}>Yesterday</Option>
                                <Option value={3}>Last Week</Option>

                                <Option value={5}>Custom Dates</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          {isDateDisplay ? (
                            <>
                              <Col md={6} sm={12} xs={24}>
                                <Form.Item label="From Date" name="fromDate">
                                  <DatePicker
                                    format="DD-MM-YYYY"
                                    style={{ width: "100%" }}
                                    disabledDate={disabledFromDate}
                                    onChange={(date, _) =>
                                      onChangeFromDate(date)
                                    }
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={6} sm={12} xs={24}>
                                <Form.Item label="To Date" name="toDate">
                                  <DatePicker
                                    format="DD-MM-YYYY"
                                    style={{ width: "100%" }}
                                    disabledDate={disabledtoDate}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          ) : null}
                          <Col className="gutter-row" md={6} xs={24}>
                            <Form.Item
                              label="Service"
                              name="serviceType"
                              rules={[{ required: true }]}
                            >
                              <Select placeholder="Please select">
                                <Option value={1}>Flights</Option>
                                <Option value={2}>Hotels</Option>
                                {/* <Option value={3}>Bus</Option>
                                <Option value={4}>Activities</Option> */}
                                <Option value={5}>Transfer</Option>                              </Select>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-class" md={6} xs={24}>
                            <Form.Item
                              label="API Provider Name"
                              name="provider"
                            >
                              <Input placeholder="Enter API Provider Name" />
                            </Form.Item>
                          </Col>
                          {/* <Col className="gutter-class" md={6} xs={24}>
                            <Form.Item>
                              <div
                                className="checkbox-flex"
                                style={{ paddingTop: "12%" }}
                              >
                                <Checkbox>Is Booking Request</Checkbox>
                                <Checkbox>Status</Checkbox>
                              </div>
                            </Form.Item>
                          </Col> */}
                        </Row>
                        <Row className="reports-btns">
                          <Button type="primary" htmlType="submit">
                            Search
                          </Button>
                          <Button
                            type="danger"
                            onClick={(e) => {
                              setShowTable(false);
                              ApiLogsForm.resetFields();
                            }}
                          >
                            Reset
                          </Button>
                        </Row>
                      </Form>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </Col>
            {showTable && (
              <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    {tabledataSource.length > 0 ? (
                      <>
                        <div className="card-add-heading">
                          <div className="rows-count">
                            <div>
                              <h5>View API Logs</h5>
                              <p>{tabledataSource.length} rows found !</p>
                            </div>
                          </div>
                          <div className="action-images">
                            {/* <img src={excel} alt="excel" /> */}

                            <img src={search} alt="search" />
                            {/* <img src={exchange} alt="exchange" /> */}
                          </div>
                        </div>
                        <div>
                          <div>
                            <Table
                              className="table-scroll-none"
                              bordered
                              dataSource={tabledataSource}
                              columns={columns}
                              pagination={{
                                defaultPageSize: 25,
                                showSizeChanger: true,
                                pageSizeOptions: ["25", "50", "100", "125"],
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ) : loading ? (
                      <Spin
                        size="large"
                        tip="Loading..."
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <b>No Results Found!</b>
                    )}
                  </Card>
                </div>
              </Col>
            )}
          </Row>
          <Modal
            width="900px"
            visible={showRequestModal}
            title="LOGS"
            onCancel={(e) => {
              setRequestModal(false);
            }}
            footer={false}
          >
            <div>
              <div className="copy-btn">
                <Popconfirm
                  title="Code Copied, Click on OK"
                  onConfirm={() =>
                    copyToClipboard(
                      "Log Request: " +
                        logRequest +
                        ", Log Response :" +
                        logResponse
                    )
                  }
                  onCancel={false}
                  okText="Ok"
                  cancelText="No"
                >
                  <Button>Copy</Button>
                </Popconfirm>
              </div>
              <h6>Request</h6>
              <pre>{logRequest}</pre>
              <br />
              <h6>Response</h6>
              <pre>{logResponse}</pre>
            </div>
          </Modal>
          {/* <Modal visible={showResponseModal} title="Response"
                                    onCancel={(e) => { setResponseModal(false) }} footer={false}>
                                    <div>
                                        "PNR":"YI956G","ReferenceNo":"300119000695","RequestType":1,"CancellationType":0,
                                        "Segments":["AirlineCode":"6E","Origin":"HYD","Destination":"BLR","FlightNumber":"151"],
                                        "Remarks":null,"CancelTickets":["TicketID":"1891944","Name":"Mr Anil Gourishettu",
                                        "TicketID":"1891945","Name":"Mstr Reajesh kumar","TicketID":"1891946","Name":"Mstr Kumar Rajesk"],"TicketIds":null
                                    </div>
                                </Modal> */}
          <Modal
            width="900px"
            visible={showAPIRequestModal}
            title="API LOGS"
            onCancel={(e) => {
              setAPIRequestModal(false);
            }}
            footer={false}
          >
            <div>
              <div className="copy-btn">
                <Popconfirm
                  title="Code Copied, Click on OK"
                  onConfirm={() =>
                    copyToClipboard(
                      "API Request: " +
                        ApiRequest +
                        ", API Response :" +
                        ApiResponse
                    )
                  }
                  onCancel={false}
                  okText="Ok"
                  cancelText="No"
                >
                  <Button>Copy</Button>
                </Popconfirm>
              </div>
              <h6>API Request</h6>
              <pre>{ApiRequest}</pre>
              <br />
              <h6>API Response</h6>
              <pre>{ApiResponse}</pre>
            </div>
          </Modal>
        </Content>
      </div>
    </div>
  );
};

export default APIAccessLogs;
