import React, { useState } from "react";
// import hotelNoImage from "../../assets/images/hotels/no_img.png";
import fli8 from "../../assets/images/Icons/plane-ic.png"
const CustomFImg = ({ src, className }) => {
    const [showImage, setShowImage] = useState(true);

    return (
        <img
            src={showImage ? src : fli8}
            alt={className}
            style={{ width: "50px", height: "50px" }}
            onError={() => setShowImage(false)}
        />
    );
};


export default CustomFImg;