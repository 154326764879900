import React, { useState, useEffect } from "react";

import {
  Button,
  Row,
  Card,
  Col,
  Form,
  message,
  Input,
  Radio,
  Upload,
  Select,
  Table,
  Modal,
  Layout,
  Popconfirm,
  Switch,
  Image,
  Checkbox,
} from "antd";
import {
  PlusCircleFilled,
  UploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import "./DocumentReqForVisa.scss";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";

import search from "../assets/vector-icons/search.png";

import ApiClient from "../../helpers/ApiClient";
import ReactQuill from "react-quill";
import modules from "../../helpers/reactQuillModules";

const DocumentReqForVisa = () => {
  const { Content } = Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [RecId, setId] = useState(-1);
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("vertical");
  const [visaEssentialDoc, setVisaEssentialDoc] = useState([]);

  const { TextArea } = Input;
  const [VisaMaster, setVisaMaster] = useState([]);

  const [content, setContent] = useState({
    Otherdocuments: "",
  });

  const getContent = (val, type) => {
    setContent((prev) => ({
      ...prev,
      [type]: val,
    }));
  };

  const getVisaMaster = () => {
    ApiClient.get("visa/getAllManageMasterVisa").then((res) => {
      if (res.status == 200) {
        setVisaMaster(res.data);
      }
    });
  };

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const [VisaDocOptions, setVisaDocOptions] = useState({});

  const handleAddClick = () => {
    form.resetFields();
    showModal();
    setIsEditMode(false);
  };
  const handleEditClick = (rec) => {
    form.setFieldsValue({
      visaId: rec.visaId,
      essentialsDocs: JSON.parse(rec.essentialsDocs),
    });

    setId(rec.Id);

    setContent({
      Otherdocuments: rec.otherDocs,
    });
    setIsEditMode(true);
    showModal();
  };

  const [dataSource, setDataSource] = useState([]);
  const getAllDocsReqForVisa = () => {
    ApiClient.get("visa/getDocRequiredForVisa").then((res) => {
      if (res.status == 200) {
        setDataSource(res.data);
      }
    });
  };
  useEffect(() => {
    getAllDocsReqForVisa();
    getAllVisaEssentialDoc();
    getVisaMaster();
  }, []);
  const getAllVisaEssentialDoc = () => {
    ApiClient.get("visa/getVisaDocuments").then((res) => {
      if (res.status == 200) {
        setVisaEssentialDoc(res.data);

        let options = res.data.reduce(function (r, a) {
          r[a.Type] = r[a.Type] || [];
          r[a.Type].push(a);
          return r;
        }, Object.create({}));
        setVisaDocOptions(options);
      }
    });
  };
  const submitForm = (formvalues) => {
    ApiClient.post("visa/addDocRequiredForVisa", formvalues).then(
      (response) => {
        if (response.status === 200) {
          setModalVisible(false);
          message.success("record Addess Successfully");

          getAllDocsReqForVisa();
          form.resetFields();
        } else {
          message.error(response.message);
        }
      }
    );
  };

  const handleSubmit = (formvalues) => {
    const VisaRecord = VisaMaster.filter(
      (item) => item.Id == formvalues.visaId
    );

    formvalues.visaName = VisaRecord[0].VisaName;
    formvalues.essentialsDocs = JSON.stringify(formvalues.essentialsDocs);
    formvalues.otherDocs = content.Otherdocuments;

    if (isEditMode) {
      submitUpdateForm(formvalues);
    } else {
      submitForm(formvalues);
    }
  };

  const submitUpdateForm = (formvalues) => {
    const updatedFormData = {
      ...formvalues,
    };

    ApiClient.put(
      "visa/updateDocRequiredForVisa/" + RecId,
      {},
      updatedFormData
    ).then((response) => {
      if (response.status === 200) {
        setModalVisible(false);
        message.success(response.message);

        getAllDocsReqForVisa();
        form.resetFields();
      } else {
        message.error(response.message);
      }
    });
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const deleteCarType = (id) => {
    ApiClient.delete("visa/deleteDocRequiredForVisa/" + id).then((res) => {
      if (res.status == 200) {
        message.success(res.message, 3);
        getAllDocsReqForVisa();
      }
    });
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => handleEditClick(rec)}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteCarType(rec.Id)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const { Option } = Select;

  const columns = [
    {
      title: "Visa Name",
      dataIndex: "visaName",
    },

    {
      title: "Essentials Docs",
      dataIndex: "essentialsDocs",
    },

    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Document Required For Visa{" "}
                          <HelpInfoHelper screenName={"/admin/cartypes"} />
                        </h5>
                        <p></p>
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />
                      <img src={excel} alt="excel" />
                      <img src={pdf} alt="pdf" />
                      <p className="add-deposit-icon" onClick={handleAddClick}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>

                  {dataSource.length ? (
                    <div>
                      <Table
                        className="table-scroll-none"
                        bordered
                        dataSource={dataSource}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  ) : (
                    <b>No record Found</b>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title="Document Required For Visa"
        className="promo-modal-header cartypes-value modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <Form
          layout="vertical"
          name="basic"
          form={form}
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Visa"
                rules={[{ required: true }]}
                name="visaId"
              >
                <Select placeholder="Please Select Your Entry">
                  {VisaMaster.length
                    ? VisaMaster.map((item, index) => {
                        return (
                          <Option key={"visaMaster" + index} value={item.Id}>
                            {item.VisaName}
                          </Option>
                        );
                      })
                    : ""}
                </Select>
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="Essential documents"
                name="essentialsDocs"
                rules={[{ required: true }]}
              >
                <Checkbox.Group
                  className="d-flex flex-column
                  "
                >
                  <Row gutter={[0, 16]}>
                    {Object.entries(VisaDocOptions).map(([key, data], i) => (
                      <Col key={i + "vsaopt"} md={12} xs={24}>
                        <div className="mt-2">
                          <p className="block font-weight-bold mb-1 "> {key}</p>

                          {data.map((cat) => (
                            <Checkbox
                              key={cat.Detail}
                              value={cat.Detail}
                              className="m-0"
                            >
                              {cat.Detail}
                            </Checkbox>
                          ))}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>

            <Col md={24} xs={24}>
              <Form.Item label="Other Documents">
                <ReactQuill
                  modules={modules}
                  value={content.Otherdocuments}
                  onChange={(e) => {
                    getContent(e, "Otherdocuments");
                  }}
                  placeholder="please input ..."
                />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
                <Button type="primary" htmlType="submit">
                  {isEditMode ? "Update" : "Add"}
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    form.resetFields();
                  }}
                  danger
                  className="cancel-btn"
                >
                  Clear
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default DocumentReqForVisa;
