import React, { useEffect, useState } from "react";
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Table,
  Image,
  Upload,
} from "antd";

import APIClient from "../../helpers/ApiClient";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import UpdatedComponent from "../ExportExcel";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import VerifyPasswordModal from "../../common/VerifyPasswordModal/VerifyPasswordModal";
import { useAuthContext } from "../../common/providers/AuthProvider";

const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/singleImage";

const BankDetails = (props) => {
  const { user } = useAuthContext();
  const [modalVisible, setModalVisible] = useState(false);
  const [upLoadImage, setUpLoadImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [Details, setDetails] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [id, setId] = useState(-1);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [formType, setFormType] = useState(-1);
  const [currID, setCurrID] = useState(-1);

  useEffect(() => {
    getBankDetails();
  }, []);

  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const getBankDetails = () => {
    APIClient.get("admin/bankDetails")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            let imgUrl = "";
            if (item.Image) {
              imgUrl = BASE + item.Image.substring(1);
            }
            return {
              ...item,
              SNo: index + 1,
              Image: item.Image,
              imgUrl,
            };
          });
          setDetails(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const add = () => {
    form.resetFields();
    setImageUrl(null);
    if (isEditMode) {
      setIsEditMode(false);
    }

    setModalVisible(true);
  };
  const clear = () => {
    setModalVisible(false);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = Details.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDetails(searchResults);
    } else {
      getBankDetails();
    }
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const handleExcel = () => {
    const excelData = Details.map((item) => {
      return {
        S_No: item.SNo,
        BankName: item.BankName,
        AccountNumber: item.AccountNumber,
        BranchName: item.BankName,
        IFSCNumber: item.IFSCNumber,
        Comment: item.Comment,
      };
    });

    props.exportExcel(excelData, "BankDetails");
  };

  const updateFlightComm = (currentData) => {
    setIsEditMode(true);
    setId(currentData.ID);
    let UploadImage;

    if (currentData.Image) {
      UploadImage = {
        file: {
          uid: currentData.ID,
          name: "img.png",
          type: "image/png",
          status: "done",
          response: {
            status: 200,
            message: "Image upload successful",
            data: {
              filepath: currentData.Image,
            },
          },
        },
      };
      setImageUrl(currentData.imgUrl);
    }

    form.setFieldsValue({
      ...currentData,
      UploadImage: UploadImage,
    });
    setModalVisible(true);
  };

  const deleteFlightComm = (id) => {
    APIClient.delete("admin/bankDetails/" + id)
      .then((res) => {
        if (res.status == 200) {
          message.success("Deleted  successfully", 3);
          getBankDetails();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const tableDelete = (currentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => updateFlightComm(currentItem)}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => {
              setCurrID(currentItem.ID);
              setVerifyModalVisible(true);
              setFormType(2);
            }}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const submitForm = (value) => {
    let img = "";
    if (value.UploadImage && value?.UploadImage?.file) {
      if (value.UploadImage.file.response.status === 200) {
        img = value.UploadImage.file.response.data.filepath;
      }
    }

    let data = {
      Image: img,
      BankName: value.BankName,
      BranchName: value.BranchName,
      AccountNumber: value.AccountNumber,
      IFSCNumber: value.IFSCNumber,
      Comment: value.Comment,
      CreatedBy: 1,
      DeviceOS: 1,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
    };

    isEditMode ? submitUpdateMap(data) : submitMapDetails(data);
  };

  const submitMapDetails = (formMapData) => {
    APIClient.post("admin/bankDetails", formMapData)
      .then((response) => {
        if (response.status == 200) {
          message.success("Details Added SuccessFully", 3);

          setModalVisible(false);
          getBankDetails();
          form.resetFields();
        } else if (response.status == 400) {
          message.error("Duplicate Bankdetails", 3);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitUpdateMap = (formMapData) => {
    APIClient.put(`admin/bankDetails/${id}`, {}, formMapData)
      .then((response) => {
        if (response.status == 200) {
          message.success("Bank Details Updated SuccessFully", 3);

          setModalVisible(false);
          getBankDetails();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columnsbank = [
    {
      title: "Bank Logo",
      dataIndex: "imgUrl",
      render: (imgUrl) =>
        imgUrl ? (
          <div style={{ maxHeight: "60px" }}>
            <Image style={{ width: "100%", maxHeight: "60px" }} src={imgUrl} />
          </div>
        ) : null,
    },
    {
      title: "Bank Name",
      dataIndex: "BankName",
      sorter: (a, b) => a.BankName.localeCompare(b.BankName),
    },
    {
      title: "Account Number",
      dataIndex: "AccountNumber",
    },
    {
      title: "Branch Name",
      dataIndex: "BranchName",
    },
    {
      title: "IFSC Code",
      dataIndex: "IFSCNumber",
    },
    {
      title: "Comment",
      dataIndex: "Comment",
    },

    {
      title: "Actions",
      render: (currentItem) => tableDelete(currentItem),
    },
  ];

  function handleChange(info) {
    if (info.file.status === "error") {
      message.error(" Error Uploading Image", 3);
      setUpLoadImage(false);
      return;
    }
    if (info.file.status === "uploading") {
      setUpLoadImage(true);
      return;
    }
    if (info.file.status === "done") {
      if (info.file.response.status == 200) {
        getBase64(info.file.originFileObj, (imageUrl) => {
          setImageUrl(imageUrl);
          setUpLoadImage(false);
        });
      }
      setUpLoadImage(false);
    }
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const uploadButton = (
    <div>
      {upLoadImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleVerifyPassword = (values) => {
    APIClient.post("admin/verifyTransactionPassword", {
      UserID: user.UserID,
      Password: values,
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          message.success(res.message);

          if (formType == 1) {
            form.submit();
          } else if (formType == 2) {
            deleteFlightComm(currID);
          }
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  return (
    <div className="promo providerapi">
      <div className="manage-markup-section">
        <div className="admin-container">
          <div className="card-bt-gap">
            <Card bordered={false}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <div>
                    <h5>
                      View Bank Details{" "}
                      <HelpInfoHelper screenName={"/admin/bankdetails"} />
                    </h5>
                    <p>{Details.length}rows found !</p>
                  </div>
                </div>
                <div className="action-images">
                  {showSearchBox && (
                    <Input
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px" }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <img
                    src={search}
                    alt="search"
                    onClick={(e) => searchData()}
                  />
                  <img src={excel} alt="excel" onClick={handleExcel} />
                  <p className="add-icon" onClick={add}>
                    <PlusOutlined />
                  </p>
                </div>
              </div>

              <Table
                className="table-scroll-none"
                bordered
                dataSource={Details}
                columns={columnsbank}
                pagination={{
                  defaultPageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100", "125"],
                }}
              />
            </Card>
          </div>
        </div>
      </div>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong> {isEditMode ? "Update" : "Add"} Bank Details</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              onClick={() => {
                setVerifyModalVisible(true);
                setFormType(1);
              }}
            >
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button key="submit" type="danger" onClick={clear}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        <Form form={form} layout={"vertical"} onFinish={submitForm}>
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Bank Name"
                name="BankName"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Enter Bank Name" size={"large"} />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                label="Branch Name"
                name="BranchName"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Enter Branch Name" size={"large"} />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                label="Account Number"
                name="AccountNumber"
                rules={[
                  { required: true, message: "Required" },
                  {
                    min: 11,
                    pattern: "^[0-9]*$",
                    max: 17,
                    message: "Must be in between 11 to 17 digits",
                  },
                ]}
              >
                <Input placeholder="Enter Account Number" size={"large"} />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                label="IFSC Number"
                name="IFSCNumber"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Enter IFSC Number" size={"large"} />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                label="Comment "
                name="Comment"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Comment" size={"large"} />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="Upload Image"
                name="UploadImage"
                // rules={[{ required: true, message: "Image is required" }]}
              >
                <Upload
                  name="image"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action={uploadActionUrl}
                  data={{ category: "bankimg" }}
                  onChange={handleChange}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {verifyModalVisible && (
        <VerifyPasswordModal
          visible={verifyModalVisible}
          setVerifyModalVisible={setVerifyModalVisible}
          handleVerifyPassword={handleVerifyPassword}
        />
      )}
    </div>
  );
};

export default UpdatedComponent(BankDetails);
