/**
 * function to get Minimum Price of airline of segment length
 */
const getMiniumAirlinePrice = (flightDetails, segmentLength, airlineName) => {
  let min;
  flightDetails.forEach((flight) => {
    if (flight.airLineName == airlineName) {
      let fare = flight.fareFamilies.fareFamilies[0].adultPublishFare;
      let segments = flight.flightSegments.length;
      if (!min || fare < min) {
        if (segments == segmentLength) {
          min = fare;
        }
      }
    }
  });
  return min;
};

/**
 * get all different airline Names from flights and create antd table Column
 */
function getCount(name, flightDetails) {
  if (name === "All") {
    return flightDetails.length;
  } else {
    return flightDetails.filter((item) => item.airLineName === name).length;
  }
}
export const createMatrixColumns = (flightDetails) => {
  let columns = [
    {
      title: "All Flights",
      dataIndex: "type",
      count: getCount("All", flightDetails),
    },
  ];

  let airlineNames = flightDetails
    .map((flight) => {
      return flight.airLineName;
    })
    .filter(
      (currentAirlineName, index, allAirlineNames) =>
        allAirlineNames.indexOf(currentAirlineName) == index
    );

  columns = [
    ...columns,
    ...airlineNames.map((item) => {
      return {
        title: item,
        dataIndex: item,
        count: getCount(item, flightDetails),
      };
    }),
  ];
  return columns;
};

/**
 * Create Data Source from Columns and flights
 */

export const createDataSourceFromFlightsAndColumns = (
  columns,
  flightDetails
) => {
  let data_source = [
    { type: "Non stop", length: 1 },
    { type: "One Stop", length: 2 },
    { type: "Two Stops", length: 3 },
  ];
  data_source.map((source_item, index, sourceAllItems) => {
    columns.forEach((column, colIndex) => {
      if (colIndex == 0) {
        return;
      } else {
        source_item[column.dataIndex] = getMiniumAirlinePrice(
          flightDetails,
          source_item.length,
          column.dataIndex
        );
      }
    });
  });
  return data_source;
};
