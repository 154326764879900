import { Col, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import moment from "moment";
import icon from "../../../assets/images/Icons/plane-ic.png"

import "./topDomesticRoutes.scss";

const { TabPane } = Tabs;
const MobileSlidersettings = {
  arrows: true,
  dots: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: false,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

const TopDomesticRoutes = ({ deals, type }) => {
  const [dealsList, setDealsList] = useState([]);
  useEffect(() => {
    // console.log(deals, type, "d2");
    if (deals.length > 0) {
      // setDealsList(deals.filter((item) => item.serviceType === 1));
      if (type === "Flights")
        setDealsList(deals.filter((item) => item.ServiceType === 1));
      if (type === "Hotels")
        setDealsList(deals.filter((item) => item.ServiceType === 2));
      if (type === "Holidays")
        setDealsList(deals.filter((item) => item.ServiceType === 3));
    }
  }, [deals]);

  const filterData = dealsList?.filter((d) => d.Status == 1);
  // console.log(dealsList,filterData, "deals");
  return (
    <div className="domestic-airsearch">
      <Row>
        {type === "Flights" && <Col md={24} xs={24}>
          {filterData.length == 0 ? (null) : (
            <h3>Top Flight Routes</h3>)}
        </Col>}

        {type === "Flights" && <Col md={24} xs={24}>
          <div>
            <Slider {...MobileSlidersettings}>
              {filterData.length > 0 &&
                filterData.map((item, i) => (
                  <div key={i}>

                    <Link
                      to={`flight/results?adultCount=1&airTravelType=oneWay&cabinClass=Economy&childCount=0&infantCount=0&stopOver=none&includeCarrier=&departureDateTime=${moment()
                        .add(10, "days")
                        .format("YYYY-MM-DD")}&flightDateFlex=1&origin=${item.origin.split("-")[2]
                        }&destination=${item.destination.split("-")[2]}`}
                    >
                      <div className="ducarosuel">
                        {/* <div className="img">
                          <img src={item.} alt="" />
                        </div> */}
                        <div className="origin-item-sel">
                          <img className="fli8-ic" src={icon} alt="" />
                          <div className="city-due">{item.origin.split("-")[0]}<br />({item.origin.split("-")[3]})</div>
                          <div className="time-due">
                            {moment()
                              .add(10, "days")
                              .format("DD, MMM YYYY")}
                          </div>
                          <div className="city-due">
                            {item.destination.split("-")[0]}<br />({item.destination.split("-")[3]})
                          </div>
                        </div>
                        {/* <div className="price_go_test">
                                <p className="starting-test">
                                  <span>Starting From</span>
                                  <span className="rs-price">
                                    <i
                                      className="fa fa-inr"
                                      aria-hidden="true"
                                    ></i>
                                    {item?.FromAmount}
                                  </span>
                                </p>
                              </div> */}
                      </div>
                    </Link>

                  </div>
                ))}
            </Slider>
          </div>
          {/* <div className="bubbles-container">
            <svg className="bubble" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
              <circle cx="50" cy="50" r="50" />
              <g className="bubble-liquid">
                <circle cx="50" cy="50" r="30" />
                <circle cx="40" cy="60" r="20" />
                <circle cx="60" cy="55" r="25" />
                <circle cx="10" cy="45" r="5" />
              </g>
            </svg>
          </div> */}
          {/* <Tabs defaultActiveKey="1"> */}
          {/* <TabPane tab="NEW DELHI" key="1">
              <div>
                <Slider {...MobileSlidersettings}>
                  {console.log(dealsList,"item-fli8s")}
                  {dealsList.length > 0 &&
                    dealsList
                      .filter((item) => item.source === "DEL")
                      .map((item, i) => (
                        <div key={i}>
                          <Link
                            to={`flight/results?adultCount=1&airTravelType=oneWay&cabinClass=Economy&childCount=0&infantCount=0&stopOver=none&includeCarrier=&departureDateTime=${moment()
                              .add(10, "days")
                              .format("YYYY-MM-DD")}&flightDateFlex=1&origin=${
                              item.source
                            }&destination=${item.destination}`}
                          >
                            <div className="ducarosuel">
                              <div className="origin-item-sel">
                                <div className="city-due">{item.FromAirportCode.split("-")?.[0]}</div>
                                <div className="time-due">
                                  {moment()
                                    .add(10, "days")
                                    .format("DD, MMM YYYY")}
                                </div>
                                <div className="city-due">
                                  {item.destination}
                                </div>
                              </div>
                              <div className="price_go_test">
                                <p className="starting-test">
                                  <span>Starting From</span>
                                  <span className="rs-price">
                                    <i
                                      className="fa fa-inr"
                                      aria-hidden="true"
                                    ></i>
                                    {item?.ToAirportCode.split("-")?.[0]}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                </Slider>
              </div>
            </TabPane> */}
          {/* <TabPane tab="DUBAI" key="2">
              <div>
                <Slider {...MobileSlidersettings}>
                  
                  {dealsList.length > 0 &&
                    dealsList
                      .filter((item) => item.source === "DXB" || item.source === "XNB" )
                      .map((item, i) => (
                        <div key={i}>
                          <Link
                            to={`flight/results?adultCount=1&airTravelType=oneWay&cabinClass=Economy&childCount=0&infantCount=0&stopOver=none&includeCarrier=&departureDateTime=${moment()
                              .add(10, "days")
                              .format("YYYY-MM-DD")}&flightDateFlex=1&origin=${
                              item.source
                            }&destination=${item.destination}`}
                          >
                            <div className="ducarosuel">
                              <div className="origin-item-sel">
                                <div className="city-due">{item.FromAirportCode.split("-")?.[0]}</div>
                                <div className="time-due">
                                  {moment()
                                    .add(10, "days")
                                    .format("DD, MMM YYYY")}
                                </div>
                                <div className="city-due">
                                  {item.destination}
                                </div>
                              </div>
                              <div className="price_go_test">
                                <p className="starting-test">
                                  <span>Starting From</span>
                                  <span className="rs-price">
                                    <i
                                      className="fa fa-inr"
                                      aria-hidden="true"
                                    ></i>
                                    {item?.ToAirportCode.split("-")?.[0]}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                </Slider>
              </div>
            </TabPane> */}

          {/* <TabPane tab="HYDERABAD" key="3">
              <div>
                <Slider {...MobileSlidersettings}>
                  {dealsList.length > 0 &&
                    dealsList
                      .filter((item) => item.source === "HYD")
                      .map((item, i) => (
                        <div key={i}>
                          <Link
                            to={`flight/results?adultCount=1&airTravelType=oneWay&cabinClass=Economy&childCount=0&infantCount=0&stopOver=none&includeCarrier=&departureDateTime=${moment()
                              .add(10, "days")
                              .format("YYYY-MM-DD")}&flightDateFlex=1&origin=${
                              item.source
                            }&destination=${item.destination}`}
                          >
                            <div className="ducarosuel">
                              <div className="origin-item-sel">
                                <div className="city-due">{item.FromAirportCode.split("-")?.[0]}</div>
                                <div className="time-due">
                                  {moment()
                                    .add(10, "days")
                                    .format("DD, MMM YYYY")}
                                </div>
                                <div className="city-due">
                                {item?.ToAirportCode.split("-")?.[0]}
                                </div>
                              </div>
                              <div className="price_go_test">
                                <p className="starting-test">
                                  <span>Starting From</span>
                                  <span className="rs-price">
                                    <i
                                      className="fa fa-inr"
                                      aria-hidden="true"
                                    ></i>
                                    {item?.FromAmount}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                </Slider>
              </div>
            </TabPane> */}

          {/* <TabPane tab="BANGALORE" key="4">
              <div>
                <Slider {...MobileSlidersettings}>
                  {dealsList.length > 0 &&
                    dealsList
                      .filter((item) => item.source === "BLR")
                      .map((item, i) => (
                        <div key={i}>
                          <Link
                            to={`flight/results?adultCount=1&airTravelType=oneWay&cabinClass=Economy&childCount=0&infantCount=0&stopOver=none&includeCarrier=&departureDateTime=${moment()
                              .add(10, "days")
                              .format("YYYY-MM-DD")}&flightDateFlex=1&origin=${
                              item.source
                            }&destination=${item.destination}`}
                          >
                            <div className="ducarosuel">
                              <div className="origin-item-sel">
                                <div className="city-due">{item.FromAirportCode.split("-")?.[0]}</div>
                                {/* {console.log(item.FromAirportCode.split("-")?.[0],"chck")} *
                                <div className="time-due">
                                  {moment()
                                    .add(10, "days")
                                    .format("DD, MMM YYYY")}
                                </div>
                                <div className="city-due">
                                {item?.ToAirportCode.split("-")?.[0]}
                                </div>
                              </div>
                              <div className="price_go_test">
                                <p className="starting-test">
                                  <span>Starting From</span>
                                  <span className="rs-price">
                                    <i
                                      className="fa fa-inr"
                                      aria-hidden="true"
                                    ></i>
                                    {item?.FromAmount}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                </Slider>
              </div>
            </TabPane> */}
          {/* <TabPane tab="MUMBAI" key="4">
              <div>
                <Slider {...MobileSlidersettings}>
                  {dealsList.length > 0 &&
                    dealsList
                      .filter((item) => item.source === "BOM")
                      .map((item, i) => (
                        <div key={i}>
                          <Link
                            to={`flight/results?adultCount=1&airTravelType=oneWay&cabinClass=Economy&childCount=0&infantCount=0&stopOver=none&includeCarrier=&departureDateTime=${moment()
                              .add(10, "days")
                              .format("YYYY-MM-DD")}&flightDateFlex=1&origin=${
                              item.source
                            }&destination=${item.destination}`}
                          >
                            <div className="ducarosuel">
                              <div className="origin-item-sel">
                                <div className="city-due">{item.FromAirportCode.split("-")?.[0]}</div>
                                <div className="time-due">
                                  {moment()
                                    .add(10, "days")
                                    .format("DD, MMM YYYY")}
                                </div>
                                <div className="city-due">
                                {item?.ToAirportCode.split("-")?.[0]}
                                </div>
                              </div>
                              <div className="price_go_test">
                                <p className="starting-test">
                                  <span>Starting From</span>
                                  <span className="rs-price">
                                    <i
                                      className="fa fa-inr"
                                      aria-hidden="true"
                                    ></i>
                                    {item?.FromAmount}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                </Slider>
              </div>
            </TabPane> */}
          {/* <TabPane tab="KOLKATA" key="5">
              <div>
                <Slider {...MobileSlidersettings}>
                  {dealsList.length > 0 &&
                    dealsList
                      .filter((item) => item.source == "CCU")
                      .map((item, i) => (
                        <div key={i}>
                          <Link
                            to={`flight/results?adultCount=1&airTravelType=oneWay&cabinClass=Economy&childCount=0&infantCount=0&stopOver=none&includeCarrier=&departureDateTime=${moment()
                              .add(10, "days")
                              .format("YYYY-MM-DD")}&flightDateFlex=1&origin=${
                              item.source
                            }&destination=${item.destination}`}
                          >
                            <div className="ducarosuel">
                              <div className="origin-item-sel">
                                <div className="city-due">{item.FromAirportCode.split("-")?.[0]}</div>
                                <div className="time-due">
                                  {moment()
                                    .add(10, "days")
                                    .format("DD, MMM YYYY")}
                                </div>
                                <div className="city-due">
                                {item?.ToAirportCode.split("-")?.[0]}
                                </div>
                              </div>
                              <div className="price_go_test">
                                <p className="starting-test">
                                  <span>Starting From</span>
                                  <span className="rs-price">
                                    <i
                                      className="fa fa-inr"
                                      aria-hidden="true"
                                    ></i>
                                    {item?.FromAmount}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                </Slider>
              </div>
            </TabPane> */}
          {/* <TabPane tab="OTHERS" key="7">
              <div>
                <Slider {...MobileSlidersettings}>
                  {dealsList.length > 0 &&
                    dealsList
                      .filter(
                        (item) =>
                          item.source !== "HYD" &&
                          item.source !== "DXB" &&
                          item.source !== "BOM" &&
                          item.source !== "BLR" &&
                          item.source !== "DEL"
                      )
                      .map((item, i) => (
                        <div key={i}>
                          <Link
                            to={`flight/results?adultCount=1&airTravelType=oneWay&cabinClass=Economy&childCount=0&infantCount=0&stopOver=none&includeCarrier=&departureDateTime=${moment()
                              .add(10, "days")
                              .format("YYYY-MM-DD")}&flightDateFlex=1&origin=${
                              item.source
                            }&destination=${item.destination}`}
                          >
                            <div className="ducarosuel">
                              <div className="origin-item-sel">
                                <div className="city-due">{item.FromAirportCode.split("-")?.[0]}</div>
                                <div className="time-due">
                                  {moment()
                                    .add(10, "days")
                                    .format("DD, MMM YYYY")}
                                </div>
                                <div className="city-due">
                                {item?.ToAirportCode.split("-")?.[0]}
                                </div>
                              </div>
                              <div className="price_go_test">
                                <p className="starting-test">
                                  <span>Starting From</span>
                                  <span className="rs-price">
                                    <i
                                      className="fa fa-inr"
                                      aria-hidden="true"
                                    ></i>
                                    {item?.FromAmount}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                </Slider>
              </div>
            </TabPane> */}
          {/* </Tabs> */}
        </Col>}
        {type === "Hotels" && <Col md={24} xs={24}>
          <h3>Top Hotel Cities</h3>
        </Col>}
        {type === "Cars" && <Col md={24} xs={24}>
          <h3>Top Bus Routes</h3>
        </Col>}
        {
          type === "Holidays" && <Col md={24} xs={24}>
            <div>
              <Slider {...MobileSlidersettings}>
                {filterData.length > 0 &&
                  filterData.map((item, i) => (
                    // console.log("return",item?.destinationId.split("&")[1].split("=")[1]),
                    <div key={i}>
                      <Link
                        to={`/bus/results?destinationId=${item?.destinationId.split("&")[1].split("=")[1]}&destinationName=${item.destinationId.split("&")[0].split("=")[1]}&journeyDate=${moment()
                          .add(10, "days")
                          .format("DD-MM-YYYY")}&sourceId=${item?.sourceId.split("&")[1].split("=")[1]}&sourceName=${item.sourceId.split("&")[0].split("=")[1]}`}
                      >
                        <div className="ducarosuel">
                          <div className="origin-item-sel">
                            <div className="city-due">{item.sourceId.split("&")[0].split("=")[1]}</div>
                            <div className="time-due">
                              {moment()
                                .add(10, "days")
                                .format("DD, MMM YYYY")}
                            </div>
                            <div className="city-due">
                              {item.destinationId.split("&")[0].split("=")[1]}
                            </div>
                          </div>
                          {/* <div className="price_go_test">
                                <p className="starting-test">
                                  <span>Starting From</span>
                                  <span className="rs-price">
                                    <i
                                      className="fa fa-inr"
                                      aria-hidden="true"
                                    ></i>
                                    {item?.FromAmount}
                                  </span>
                                </p>
                              </div> */}
                        </div>
                      </Link>
                    </div>
                  ))}
              </Slider>
            </div>
          </Col>
        }
      </Row>
    </div>
  );
};

export default TopDomesticRoutes;
