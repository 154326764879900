

import React, { useState, useEffect } from 'react';


const PaymentComponent = ({ pgData }) => {

    useEffect(() => {
        ccavanuePayment()
        document.getElementById("ccavanue").click()

    }, [pgData]);
    // console.log(pgData,"dabal123");

    const [encRequest, setEncRequest] = useState("");
    const [accessCode, setAccessCode] = useState("");
    const [isRedirecting, setIsRedirecting] = useState(false);


    //console.log(encRequest, accessCode,"encRequest")
    const ccavanuePayment = () => {
        //setIsRedirecting(true);
        setEncRequest(pgData.encRequest);
        setAccessCode(pgData.accessCode);
    }

   // console.log(encRequest, accessCode, "ccavanue")

    return (

        <div>

            <form
                action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
                method="post"
          
            >
             
            <input type="hidden" name="encRequest" value={pgData.encRequest}/> 
                <input type="hidden" name="access_code" value={pgData.accessCode}/> 
            
                <button id="ccavanue" style={{ display: "none" }} type="submit">Proceed to Payment</button>
            </form>
            

        </div>

        
    );
};

export default PaymentComponent;



