import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Layout, Row } from "antd";
import "../Busticketsuccess/Busticketsuccess.scss";

const { Content } = Layout;

const ActivitiesTicketSuccess = ({ activitiesTicketDetails }) => {
  return (
    <Content className="admin-container cms-pages-width retrieve-linebar">
      <Card className="retrieve-booking busmtb">
        <Row gutter={[16, 16]}>
          <Col lg={12} md={16} sm={16} xs={24}>
            <h4 className="ticket-lti-bus">Activities ltinerary</h4>
            {/* sub--row--start */}

            {activitiesTicketDetails.message === "FAILED" && <p>Failed</p>}
            {activitiesTicketDetails?.TourDetails.map((tour) => (
              <div className="fic-tickets-data-bus">
                <h6 className="round-bus-tic">{tour.tourName}</h6>
                <div className="bd-bus-info">
                  <Row gutter={[8, 8]}>
                    <Col md={5} sm={10} xs={24}>
                      <img className="img-fluid" src={tour.tourImage} />
                    </Col>
                    <Col md={19} sm={14} xs={24}>
                      <h6>{tour.optionName}</h6>

                      <div
                        dangerouslySetInnerHTML={{
                          __html: tour.tourShortDescription,
                        }}
                      ></div>
                    </Col>
                  </Row>
                </div>

                <Row gutter={[8, 8]}>
                  {/* <Col md={12} sm={12} xs={24}>
                    <p className="pnr text-left">
                      Provider Ref No: {tour.ProviderRefNo}
                    </p>
                  </Col> */}

                  <Col md={12} sm={12} xs={24}>
                    <p className="pnr text-left">
                      Transfer Name: {tour.transferName}
                    </p>
                  </Col>
                </Row>
              </div>
            ))}
          </Col>

          <Col lg={12} md={8} sm={8} xs={24} className="flight-email-dd-bus">
            {activitiesTicketDetails?.TourDetails.length > 0 ? (
              <>
                <div className="paymet-booking-tic">
                  <h4>Activities Ticket Booking Payment Successful</h4>
                  <p>
                    A confirmation email has been sent your provided email
                    address
                  </p>
                  <p>
                    Ref-No :{" "}
                    {activitiesTicketDetails?.TourDetails?.[0].referenceNumber}
                  </p>
                  <p>
                    Status : <span className="succes-pay-value">Success</span>
                  </p>
                </div>

                <Row>
                  <Col lg={12} md={18} xs={24}>
                    <Button className="tic-retrive-book" htmlType="submit">
                      <Link
                        to={`activities/ticket?ref=${activitiesTicketDetails?.TourDetails?.[0].referenceNumber}`}
                      >
                        Retrieve Booking
                      </Link>
                    </Button>
                  </Col>
                </Row>
              </>
            ) : null}
          </Col>
        </Row>
      </Card>
    </Content>
  );
};

export default ActivitiesTicketSuccess;
