import { Col, Row, Tabs } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import "../car-search/Car.scss"

const { TabPane } = Tabs;

const TopCars = ({ dealsList }) => {
  let tomorrowDate = moment().add(1, "days").format("YYYY-MM-DD");
  let afterTomorrowDate = moment().add(2, "days").format("YYYY-MM-DD");
  return (
    <>
      <div className="container mb-4" style={{paddingBottom:"15px"}}>
        <Row>
          <Col md={24} xs={24}>
            <h2 className="top-fl-route">Top Cars Routes</h2>
          </Col>
        </Row>
        <div className="sec-bg-top">
          <Row>
            <Col md={24} xs={24}>
              <div className="tab-content current">
                {/* {console.log(dealsList, "cary")} */}
                <Tabs>
                <TabPane tab="Out-Station" key="2">
                    {dealsList
                      .filter((item) => item.TravelType == 2)
                      .map((route, index) => {
                        return (
                          <div
                            key={route.Id + "outstation"}
                            className="top-routes-list  mb-3 "
                          >
                            <Row gutter={20} className="align-items-center">
                              <Col md={12} xs={24}>
                                <div className="src-dest pr-3">
                                  <a className="hm_link">{`${route.Source ?? ""
                                    } ${route.Destination
                                      ? `${"to"}${" "}${route.Destination ?? ""
                                      }`
                                      : ""
                                    }`}</a>
                                </div>
                              </Col>
                              <Col md={12} xs={24}>
                                <div className="search-btn">
                                  <Link
                                    to={`/cars/result?sourceID=${route.SourceId ?? ""
                                      }&destinationID=${route.DesitnationId ?? ""
                                      }&fromDate=${tomorrowDate ?? ""}&toDate=${afterTomorrowDate ?? ""
                                      }&TravelType=${route.TravelType ?? ""
                                      }&TripType=${route.TripType ?? ""
                                      }&sourceName=${route.Source
                                      }&destinationName=${route.Destination}`}
                                    className="srch_btn"
                                  >
                                    Search car
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                  </TabPane>
                  <TabPane tab="Local" key="1">
                    {dealsList
                      .filter((item) => item.TravelType == 1)
                      .map((route, index) => {
                        return (
                          <div
                            key={route.Id + "local"}
                            className="top-routes-list  mb-3 "
                          >
                            <Row gutter={20} className="align-items-center">
                              <Col md={12} xs={24}>
                                <div className="src-dest pr-3">
                                  <a className="hm_link">{`${route.Source ?? ""
                                    } ${route.Destination
                                      ? `${"to"}${" "}${route.Destination ?? ""
                                      }`
                                      : ""
                                    }`}</a>
                                </div>
                              </Col>

                              <Col md={12} xs={24}>
                                <div className="search-btn">
                                  <Link
                                    to={`/cars/result?sourceID=${route.SourceId ?? ""
                                      }&destinationID=${route.DesitnationId ?? ""
                                      }&fromDate=${tomorrowDate ?? ""}&toDate=${afterTomorrowDate ?? ""
                                      }&TravelType=${route.TravelType ?? ""
                                      }&TripType=${route.TripType ?? ""
                                      }&sourceName=${route.Source
                                      }&destinationName=${route.Destination}`}
                                    className="srch_btn"
                                  >
                                    Search Car
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                  </TabPane>
                  
                  {/* <TabPane tab="Transfer" key="3">
                    {dealsList
                      .filter((item) => item.TravelType == 3)
                      .map((route, index) => {
                        return (
                          <div
                            key={route.Id + "transfer"}
                            className="top-routes-list  mb-3 "
                          >
                            <Row gutter={20} className="align-items-center">
                              <Col md={12} xs={24}>
                                <div className="src-dest pr-3">
                                  <a className="hm_link">{`${
                                    route.Source ?? ""
                                  } ${
                                    route.Destination
                                      ? `${"to"}${" "}${
                                          route.Destination ?? ""
                                        }`
                                      : ""
                                  }`}</a>
                                </div>
                              </Col>
                              <Col md={12} xs={24}>
                                <div className="search-btn">
                                  <Link
                                    to={`/cars/result?sourceID=${
                                      route.SourceId ?? ""
                                    }&destinationID=${
                                      route.DesitnationId ?? ""
                                    }&fromDate=${tomorrowDate ?? ""}&toDate=${
                                      afterTomorrowDate ?? ""
                                    }&TravelType=${
                                      route.TravelType ?? ""
                                    }&TripType=${
                                      route.TripType ?? ""
                                    }&sourceName=${
                                      route.Source
                                    }&destinationName=${route.Destination}`}
                                    className="srch_btn"
                                  >
                                    Search car
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                  </TabPane> */}
                </Tabs>{" "}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default TopCars;
