import React from "react";
import CmsPages from "../../common/CMSPages/CmsPages";
import "./About.scss";
import bgImg from "../../assets/images/abt-us.jpg"
const AboutUs = () => {
  // return <CmsPages cmsId={2} pageTitle="About Us" />;
  return (
    <div className="About-us">
      <div className="hdr-img">
        <img width='100' src={bgImg} alt="Anjmal" />
      </div>
      <div className="About-us-page">
      <div className="hdr">
        <h2>About Us</h2>
      </div>
      <div className="container-box">
        <div className="col-md-8 col-xs-12 wb-padding textContainerStyle">
          <h2 className="heading-txt">
            Welcome to AnjMal Travel and Tours
          </h2>
          <p className="paragraph">
            The destination for your dream vacation! Our purpose is to provide you with the best travel packages and the best itinerary to ensure that your travel and stay is a memorable one. Regardless of your destination or the time of travel, we promise to offer you nothing but the best. From Air Ticket Bookings, Hotel Reservations, Transportation, Events & Conferences to Passports and Visas, get it all under one roof!
          </p>
        </div>
        <div className="col-md-4 col-xs-12 image">
          <img src="https://www.anjmal.com/wp-content/uploads/2019/10/anjmal-about.png" alt="AnjMal Travel" className="img-responsive" />
        </div>
      </div>
      <div style={{ padding: "2rem" }}></div>
      <div className="wrapper-part" >
        <div className="content" >
          <h2 className="heading1">
            The destination<br />For your dream vacation
          </h2>
        </div>
      </div>
      <div style={{ padding: "2rem" }}></div>
      <div className="container1" >
        
        <center>
          
          <h2 className='heading2'>More about us</h2>
          
        </center>
      
        <div className='cont'>
          <img
            src="https://www.anjmal.com/wp-content/uploads/2019/11/hoshi-sir.jpeg"
            alt="Mr. Hoshi.A.Karanjia"
            className='imagestyle'
          />
          <p className='para'>
          <span style={{color:"#35459c", fontWeight:"600"}}>Mr. Hoshi.A.Karanjia</span> is the sole proprietor of AnjMal Travels &amp; Tours and has been a sparkling entity in the hotel industry for about 27 years. He began his career welcoming tour groups as a front office executive at the Oberoi Towers, Mumbai. Having a strong desire to apprehend the internal and external operation of the travel industry, brought about an urge to travel and serve people traveling in and out of India from all around the world. He last served in the hotel industry as a group vice president of Sales &amp; Marketing at the Fariyas Hotel. Although Mr.Karanjia didn’t get the chance to work in the travel industry, he fulfilled his dream of being a part of the industry by starting his very own travels and tourism company, on the 14th of August 2004. H. Karanjia did not plunge into the business instantly, but after carefully analyzing his strengths and also his weaknesses, he finally decided to step into the travel industry, which is also an essential part of the service industry.
     
           As most people know, “Behind every Successful Man is a Strong and Motivating Wife” so it is the same with Mr. Karanjia, who is constantly encouraged, and strengthened by his wife, Mrs. Anjoo Karanjia. His wife, who has played an integral part in the management of the organization, and Malcolm his adorable son, has been the main inspiration, motivating H. Karanjia to start his own business.
        
          </p>
        </div>
        <p> The name ‘<span style={{color:"#bd0c21", fontWeight:"600"}}>Anjmal</span>’ is the unification of the two names Anjoo and Malcolm, symbolizing the existence of the 2 strong pillars in his life &amp; his work. <br />
          AnjMal Travels &amp; Tours has been growing gradually yet maintaining its standards and quality of work. The agency took off with successful tours conducted to Russia, Malaysia, Sri Lanka, Bangkok, India, and many more, thus creating its niche in the tourism industry. Thus, finally leading to AnjMal Travel &amp; Tours specializing in Russian tourism both inbound and outbound. AnjMal Travels &amp; Tours currently is a member of ETAA (Enterprising Travel Agent’s Association) with H. Karanjia as a director, on the board of associations.
        </p>
        <p>
          AnjMal Travels &amp; Tours values its loyal customers, who have been returning to us, allowing us to serve them better. We aim to constantly develop and provide innovative services based on a positive approach to every type of travel requirement. Its dedicated team of professionals with their expertise and sales excellence ensures its clients’ complete satisfaction, thereby making their travel experiences a memorable one.
        </p>
      </div>
      <div style={{ padding: "2rem" }}></div>
      <div className="container-contact">
        <h2 className="playfair">Contact us to know more</h2>
      </div>
      <div style={{ padding: "2rem" }}></div>
      <div className="row mb-5" >
        <div className='sub-cont'>
          <h4 style={{ lineHeight: '1.5em' }}>
            Did not find your package?<br />
            Feel free to ask us, and we will tailor-make the best deal for you!<br />
          </h4>
          <p>
            Looking to discover trending destinations around the world but unable to find the right package of your choice? We will customize a personal itinerary for you based on your travel requirements and organize your sightseeing to render a hassle-free holiday. Understanding that every individual’s travel requirement is different, all our tour packages are tailor-made as per our client’s preference.
          </p>
          <p>
            <a href='/contact_us_form' className="btn btn-primary" style={{ marginTop: '2em', textDecoration: "none", listStyle: 'none' }}>Contact Now</a>
          </p>
        </div>
        <div className='img-1'>
          <img className="img-fluid" src="https://www.anjmal.com/wp-content/uploads/2018/12/missing-package.jpg" alt="" />
        </div>
        <p></p>
      </div>
      {/* <div className="footer-container" >
        <div className="row">
          <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
            <div className='feature'>
              <div className="col-md-3">
                <img src="https://www.anjmal.com/wp-content/uploads/2018/12/deck-chair-1.png" alt="" className='icon' />
              </div>
              <div className="col-md-9" style={{ paddingTop: '10px' }}>
                <h4><strong style={{ fontWeight: "600" }}>Top Destinations</strong></h4>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
            <div className='feature'>
              <div className="col-md-3">
                <img src="https://www.anjmal.com/wp-content/uploads/2018/12/wallet-1.png" alt="" className='icon' />
              </div>
              <div className="col-md-9" style={{ paddingTop: '10px' }}>
                <h4><strong style={{ fontWeight: '600' }}>The Best Prices</strong></h4>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-4 col-sm-4 col-xs-12">
            <div className='feature'>
              <div className="col-md-3">
                <img src="https://www.anjmal.com/wp-content/uploads/2018/12/suitcase.png" alt="" className='icon' />
              </div>
              <div className="col-md-9" style={{ paddingTop: '10px' }}>
                <h4><strong style={{ fontWeight: '600' }}>Amazing Services</strong></h4>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div> */}
      </div>
    </div>
  );
};

export default AboutUs;
