import {
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusCircleFilled,
} from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Modal,
    Popconfirm,
    Radio,
    Row,
    Switch,
    Table,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
    default as APIClient,
    default as ApiClient,
} from "../../helpers/ApiClient";
import "../../admin/Adminstyles.scss";
//import { getCmsType } from "../../pages/Holidays/holidayHelper";
import search from "../assets/vector-icons/search.png";

const CMSType = (props) => {
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [CMStypedata, setCMStypedata] = useState([]);

    const [isEditMode, setIsEditMode] = useState(false);

    const [currentId, setCurrentId] = useState();
    const [showSearchBox, setShowSearchBox] = useState(false);

    useEffect(() => {
        getCMSType();
    }, []);

    const getCMSType = () => {
        APIClient.get("admin/cmstype")
            .then((res) => {
                if (res.status === 200) {
                    let data = res.data.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                            CreatedDate: moment(item.createDate).format("DD-MM-YYYY"),
                            ModifiedDate: moment(item.modifiedDate).format("DD-MM-YYYY"),
                        };
                    });
                    // console.log(data)
                    setCMStypedata(data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    /*  Add Configuration */
    const SubmitCMSType = (formvalues) => {
        // console.log("formvalues", formvalues)
        APIClient.post("admin/save/cmstype", formvalues)
            .then((response) => {
                return response;
            })
            .then((response) => {
                if (response.status === 200) {
                    setModalVisible(false);
                    message.success(response.message, 3);
                    getCMSType();
                } else if (response.status === 400) {
                    message.error(response.message, 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleModal = () => {
        setIsEditMode(false);
        form.resetFields();
        setModalVisible(true);
    };

    const deleteRole = (id) => {
        APIClient.delete("admin/delete/cmstype/" + id)
            .then((res) => {
                if (res.status === 200) {
                    message.success("CMSType Deleted successfully", 3);
                    getCMSType();
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const tableActions = (currentRecord) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <EditOutlined
                        onClick={() => editCurrentItem(currentRecord)}
                        style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure want to delete?"
                        onConfirm={() => deleteRole(currentRecord.Id)}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </Popconfirm>
                </div>
            </div>
        );
    };

    const editCurrentItem = (rec) => {
        setCurrentId(rec.Id);
        form.setFieldsValue({
            ...rec,
        });

        setIsEditMode(true);
        setModalVisible(true);
    };

    const UpdateCMSType = (formvalues) => {
        const updatedFormData = {
            ...formvalues,
          };
        // console.log("formupdate",updatedFormData)
        // console.log("formupdate", currentId)
        ApiClient.put("admin/update/cmstype/" + currentId,{},updatedFormData).then((res) => {

            if (res.status === 200) {
                setModalVisible(false); 
                message.success("CMSType Updated successfully", 3);
                getCMSType();
                form.resetFields();
            } else {
                message.error(" Error Uploading Data", 3);
            }
        });
    };

    let lastIndex = 0;
    const uniqueKey = () => {
        lastIndex++;
        return lastIndex;
    };

    const searchData = (searchData) => {
        setShowSearchBox(true);
    };

    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "SNo",
        },
        {
            title: "CMS Type",
            dataIndex: "CMSType",
        },

        {
            title: "Status",
            render: (currentData) => handelStatus(currentData),
        },
        {
            title:"CreatedDate",
            dataIndex:"CreatedDate"

        },

        {
            title: "Actions",
            render: (rec) => tableActions(rec),
        },
    ];

    const handelStatusChange = (data) => {
    // console.log(data)
        let statusdata={};
        if(data.Status===1){
           statusdata  = {
                CMSType: data.CMSType,
                Status:0,
            };
        }
        else{
            statusdata  = {
                CMSType: data.CMSType,
                Status:1,
            };
        }
        // console.log(statusdata)
        
        ApiClient.put("admin/update/cmstype/" + data.Id,{}, statusdata).then((res) => {
        
            if (res.status === 200) {
                // console.log("response", res);
                message.success("CMSType Status Updated successfully", 3);
                getCMSType();
            } else {
                message.error(" Error in Status Updating", 3);
            }
        });
    }

    const handelStatus = (data) => {
        return (
            <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                checked={data.Status === 1 ? true : false}
                onChange={() => handelStatusChange(data)}
            />
        );
    };

    const searchTableData = (e) => {
        var searchVal = e.target.value;
        if (searchVal.length > 0) {
            const searchResults = CMStypedata.filter(function (item) {
                if (item !== undefined) {
                    return JSON.stringify(item)
                        .toLowerCase()
                        .includes(searchVal.toLowerCase());
                }
            });
            setCMStypedata(searchResults);
        } else {
            getCMSType();
            //  setConfigurations(ConfigurationList);
        }
    };

    const closeSearch = () => {
        setShowSearchBox(false);
    };

    const closeSearchInput = (
        <div>
            <CloseOutlined onClick={closeSearch} />
        </div>
    );

    const SubitForm = (formvalues) => {
        if (isEditMode) {
            UpdateCMSType(formvalues);
        } else {
            SubmitCMSType(formvalues);
        }
    };

    return (
        <div>
            <div className="manage-markup-section">
                <div className="container-fluid">
                    <Row>
                        <Col span={24}>
                            <div className="card-bt-gap">
                                <Card bordered={false}>
                                    <div className="card-SubmitConfiguration-heading">
                                        <div className="rows-count">
                                            <div>
                                                <h5>View Cms Type </h5>
                                                <p>{CMStypedata.length} rows found !</p>
                                            </div>
                                        </div>
                                        <div className="action-images">
                                            {showSearchBox && (
                                                <Input
                                                    placeholder="Search"
                                                    onChange={(e) => searchTableData(e)}
                                                    suffix={closeSearchInput}
                                                    style={{ padding: "0px 12px",width:"20%" }}
                                                />
                                            )}
                                            &nbsp;&nbsp;
                                            <img
                                                src={search}
                                                alt="search"
                                                onClick={(e) => searchData(CMStypedata)}
                                            />
                                            <p className="m-0 add-deposit-icon" onClick={handleModal}>
                                                <PlusCircleFilled />
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <Table
                                            scroll={{ x: true }}
                                            bordered
                                            rowKey={uniqueKey}
                                            dataSource={CMStypedata}
                                            columns={columns}
                                            pagination={{
                                                defaultPageSize: 25,
                                                showSizeChanger: true,
                                                pageSizeOptions: ["25", "50", "100", "125"],
                                            }}
                                        />
                                    </div>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <Modal
                title={[
                    <div>
                        <h6 style={{ marginBottom: "0px" }}>
                            <strong> {isEditMode ? "Update" : "Add"} Cms Type </strong>
                        </h6>
                    </div>,
                ]}
                className="promo-modal-header modal-header-bg12"
                visible={modalVisible}
                onOk={(e) => setModalVisible(false)}
                onCancel={(e) => setModalVisible(false)}
                footer={[
                    <div>
                        <Button htmlType="submit" type="primary" onClick={form.submit}>
                            {isEditMode ? "Update" : "Add"}
                        </Button>
                        <Button type="danger" onClick={() => form.resetFields()}>
                            Clear
                        </Button>
                    </div>,
                ]}
            >
                <Form layout={"vertical"} form={form} onFinish={SubitForm}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="CMS Type"
                                name="CMSType"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter CMS Type",
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: "Please Select Status" }]}
                            >
                                <Radio.Group>
                                    <Radio value={1}>Active</Radio>
                                    <Radio value={0}>In Active</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};
export default CMSType;
