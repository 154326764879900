import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    Modal,
    message,
    Popconfirm,
    Row,
    Table,
    Select,
    Radio,
    Switch
} from "antd";

import {
    PlusCircleFilled,
    EditOutlined,
    DeleteOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import moment from "moment";
import exportExcel from "../ExportExcel";
import "../../admin/Adminstyles.scss";
import ApiClient from "../../helpers/ApiClient";
const { Content } = Layout;
const { Option } = Select;
const AllServicesSessiontime = () => {
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [rolesList, setRolesList] = useState([]);
    const [tableList, setTableList] = useState([]);
    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [currentId, setCurrentId] = useState(-1);
    const [showSearchBox, setShowSearchBox] = useState(false);

    useEffect(() => {
        getsessiontimeList();
    }, []);

    const getsessiontimeList = () => {
        ApiClient.get("admin/getsession_timeout")
            .then((res) => {
                if (res.status == 200) {
                    let data = res.data.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                            CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
                            ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
                        };
                    });

                    setRolesList(data);
                    setTableList(data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const add = (val) => {
        ApiClient.post("admin/create/session_timeout", val)
            .then((response) => {
                return response;
            })
            .then((response) => {

                if (response.status == 200) {
                    setModalVisible(false);
                    message.success("Successfully created session time", 3);

                } else if (response.status == 400) {
                    message.error("Unable Create session time", 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
        getsessiontimeList();
        form.resetFields();
    };

    const handleModal = () => {
        if (showUpdateBtn) {
            setShowUpdateBtn(false);
            form.resetFields();
        }
        setModalVisible(true);
    };

    const deleteRole = (Record) => {
        let Id = Record.id;
        ApiClient.delete("admin/delete/session_timeout/" + Id)
            .then((res) => {
                if (res.status == 200) {
                    message.success("Session Timeout Deleted  successfully", 3);

                }
            })
            .catch((error) => {
                console.error(error);
            });
        getsessiontimeList();

    };
    const updatesessiontime = (val) => {
        ApiClient.put(`admin/update/session_timeout/${currentId}`, {}, val).then((resp) => {
            if (resp.status === 200) {

                setModalVisible(false);
                message.success("SessionTimeout Updated successfully", 3);
                setCurrentId(-1);
            }
        });
        getsessiontimeList();
        form.resetFields();
    };

    const submitForm = (val) => {
        let roleObj = {
            serviceType: val?.serviceType,
            sessionTime: val?.sessiontime,
            status: val?.Status
        };
        if (showUpdateBtn) updatesessiontime(roleObj);
        else add(roleObj);
    };

    const tableActions = (currentRecord, Name) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <EditOutlined
                        onClick={() => editCurrentItem(currentRecord, Name)}
                        style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure want to delete?"
                        onConfirm={() => deleteRole(Name)}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </Popconfirm>
                </div>
            </div>
        );
    };

    const editCurrentItem = (id, Name) => {
        setShowUpdateBtn(true);
        setCurrentId(id.id);
        form.setFieldsValue({
            serviceType: Name?.serviceType,
            sessiontime: Name?.sessionTime,
            Status: Name?.status
        });
        setModalVisible(true);
    };

    let lastIndex = 0;
    const uniqueKey = () => {
        return lastIndex++;
    };

    const searchData = () => {
        setShowSearchBox(true);
    };
    const handelStatus = (name, data) => {
        // console.log(name, data, "test");
        if (data?.status == 1) {
            return (
                <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    defaultChecked
                    onChange={() => changeStatus(data, 0)}
                />
            );
        } else {
            return (
                <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    onChange={() => changeStatus(data, 1)}
                />
            );
        }
    };

    const changeStatus = (val, status) => {
        // console.log(val, status, "test2");
        let obj = {
            serviceType: val?.serviceType,
            sessionTime: val?.sessionTime,
            status: status
        };
        ApiClient.put(`admin/update/session_timeout/${val?.id}`, {}, obj).then((resp) => {
            if (resp.status === 200) {
                setModalVisible(false);
                message.success("SessionTimeout Status Changed", 3);
            }
        });
        getsessiontimeList();

    }
    const columns = [
        {
            title: "Sr. No.",
            dataIndex: "SNo",
            sorter: (a, b) => a.SNo - b.SNo,
        },
        {
            title: "Service Name",
            dataIndex: "serviceType",
            sorter: (a, b) => a.Name.localeCompare(b.Name),
            render: (e) => getservicename(e),
        },
        {
            title: "Session Time",
            dataIndex: "sessionTime",
            sorter: (a, b) => a.sessionTime.localeCompare(b.sessionTime),
            render: (e) => e + " Min",
        },
        {
            title: "Status",
            dataIndex: "status",
            sorter: (a, b) => a.status - b.status,
            render: (val, currentItem) => handelStatus(val, currentItem),
        },
        {
            title: "Created Date",
            dataIndex: "CreatedDate",
            sorter: (a, b) => a.CreatedDate.localeCompare(b.CreatedDate),
        },
        {
            title: "Modified Date",
            dataIndex: "ModifiedDate",
            sorter: (a, b) => a.ModifiedDate.localeCompare(b.ModifiedDate),
        },
        {
            title: "Actions",
            render: (sessionID, Name) => tableActions(sessionID, Name),
        },
    ];
    const getservicename = (servicetype) => {
        if (servicetype === 1) {
            return "Flights"
        }
        if (servicetype === 2) {
            return "Hotels"
        }
        if (servicetype === 3) {
            return "Car Transfers"
        }
    }

    const searchTableData = (e) => {
        var searchVal = e.target.value;
        if (searchVal.length > 0) {
            const searchResults = tableList.filter(function (item) {
                if (item != undefined) {
                    return JSON.stringify(item)
                        .toLowerCase()
                        .includes(searchVal.toLowerCase());
                }
            });
            setTableList(searchResults);
        } else {
            setTableList(rolesList);
        }
    };

    const closeSearch = () => {
        setShowSearchBox(false);
        setTableList(rolesList);
    };

    const closeSearchInput = <CloseOutlined onClick={closeSearch} />;

    const handleExcel = () => {
        let excelData = tableList;
        excelData = excelData.map((item, i) => ({
            SNo: i + 1,
            ServiceName: getservicename(item.serviceType),
            SessionDuration: item.sessionTime,
            CreatedDate: item.CreatedDate,
            ModifiedDate: item.ModifiedDate,
        }));

        exportExcel(excelData, "Roles");
    };

    return (
        <Layout>
            <div className="manage-Session-timeout">
                <Content className="container-fluid">
                    <div className="card-bt-gap">
                        <Card bordered={false}>
                            <div className="card-add-heading">
                                <div className="rows-count">
                                    <h5>View Session Timings</h5>
                                    <p>{tableList.length} rows found !</p>
                                </div>
                                <div className="action-images">
                                    {showSearchBox && (
                                        <Input

                                            autoComplete="off"
                                            placeholder="Search"
                                            onChange={(e) => searchTableData(e)}
                                            suffix={closeSearchInput}
                                            style={{ padding: "0px 12px" }}
                                        />
                                    )}
                                    &nbsp;&nbsp;
                                    <img src={search} alt="search" onClick={() => searchData()} />
                                    <img src={excel} alt="excel" onClick={() => handleExcel()} />
                                    <img
                                        src={exchange}
                                        alt="exchange"
                                        onClick={() => {
                                            setTableList([]);
                                            getsessiontimeList();
                                        }}
                                    />
                                    <p className="add-deposit-icon" onClick={handleModal}>
                                        <PlusCircleFilled />
                                    </p>
                                </div>
                            </div>

                            <Table
                                scroll={{ x: true }}
                                bordered
                                rowKey={uniqueKey}
                                dataSource={tableList}
                                columns={columns}
                                pagination={{
                                    defaultPageSize: 25,
                                    showSizeChanger: true,
                                    pageSizeOptions: ["25", "50", "100", "125"],
                                }}
                            />
                        </Card>
                    </div>
                </Content>
            </div>
            <Modal
                title={[
                    <div>
                        <h6 style={{ marginBottom: "0px" }}>
                            <strong>{showUpdateBtn ? "Update Session Time" : "Add Session Time"}</strong>
                        </h6>
                    </div>,
                ]}
                className="promo-modal-header"
                visible={modalVisible}
                onOk={(e) => setModalVisible(false)}
                onCancel={(e) => setModalVisible(false)}
                footer={[
                    <Button key="add" type="primary" onClick={form.submit}>
                        {showUpdateBtn ? "Update" : "Add"}
                    </Button>,
                ]}
            >
                <Form layout="vertical" form={form} onFinish={submitForm}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="Services"
                                name="serviceType"
                                rules={[{ required: true, message: "Please select service" }]}
                            >
                                <Select

                                >
                                    <Option value={1}>
                                        Flights
                                    </Option>
                                    <Option value={2}>
                                        Hotels
                                    </Option>
                                    <Option value={3}>
                                        Car Transfers
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label="SessionTime"
                                name="sessiontime"
                                rules={[{ required: true, message: "Please enter session time" }]}
                            >
                                <Input suffix="min" type="number" />
                            </Form.Item>
                        </Col>
                        <Col md={24} xs={24}>
                            <Form.Item label="Status" name="Status">
                                <Radio.Group
                                    rules={[{ required: true, message: "Please choose Status!" }]}
                                >
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={0}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </Layout>
    );
};
export default AllServicesSessiontime;
