import React from "react";
import ReactDOM from "react-dom";
import "react-quill/dist/quill.snow.css";
import "./index.scss";
import App from "./App";
import SytProvider from "../src/common/providers/SytProvider";
import * as serviceWorker from "./serviceWorker";

import {
  AuthProvider,
  CurrencyProvider,
  GlobalStatesProvider,
} from "../src/common/providers/";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <SytProvider>
        <CurrencyProvider>
          <GlobalStatesProvider>
            <App />
          </GlobalStatesProvider>
        </CurrencyProvider>
      </SytProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
serviceWorker.register();
