import { Button, Col, Divider, List, Popover, Row, Modal } from "antd";
import { React, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import carDoor from "../../../../assets/images/svg/car-door.png";
import "./VisaCard.scss";
import ReactHtmlParser from "react-html-parser";
import noVisaImage from "../../../assets/images/image-not-found-scaled-1150x647.png";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { GlobalStatesContext } from "../../../common/providers";
import { useSytContext } from "../../../common/providers/SytProvider";

const BASE = process.env.REACT_APP_BASE_URL;

const VisaCard = ({ VisaObj }) => {
  const { updateSelectedVisaData } = useContext(GlobalStatesContext);

  const { visaGuideFaqs } = useSytContext();
  const { activeCurrency, currencyValue } = useCurrencyContext();

  let history = useHistory();
  const goToDetailPage = (VisaObj) => {
    updateSelectedVisaData(VisaObj);
    history.push("/visa/visaDetails?visaCode=" + VisaObj.visaCode);
  };
  return (
    <div>
      <Row className="visa-ratings-act align-items-center">
        <Col md={5} xs={24} className="overflow-hidden visa-image-wrapper ">
          {VisaObj.Images.length ? (
            <img
              className="visa-image "
              src={BASE + VisaObj.Images[0].Image.substring(1)}
              alt=""
            />
          ) : (
            <img className="visa-image " src={noVisaImage} alt="static-image" />
          )}
        </Col>

        <Col md={13} xs={24} className="visa-info-content py-2">
          <div className="seats-chair">
            <div className="visa-name">
              <p
                className="nisha-cars"
                onClick={() => {
                  goToDetailPage(VisaObj);
                }}
              >
                {VisaObj.VisaName
                  ? VisaObj.VisaName + ", " + VisaObj.Country
                  : "Visa Name"}
              </p>
              <span className="sub-heading">
                {VisaObj.VisaDescription ? VisaObj.VisaDescription : ""}
              </span>
            </div>

            <div className="sub-header-info mb-2">
              <b>{VisaObj.Continent} </b> | {VisaObj.Country} |{" "}
              <b>{`${VisaObj.TypeofVisa} Type`} </b>
            </div>
          </div>

          <div className="extra-params d-flex  ">
            <ul>
              <li>{VisaObj.ProcessingTime ?? ""} Day(s) Processing Time</li>
              <li>{VisaObj.Stayperiod ?? ""} Month(s) Stay Period</li>
              <li>{VisaObj.Validity ?? ""} Month(s) Validity</li>
            </ul>
            <ul>
              <li>{VisaObj.Entry ?? ""} Entry Available</li>
            </ul>
          </div>
        </Col>

        <Col md={6} xs={24} className="cars-btn-price ">
          <div className="price-drops">
            <div>
              <h5 className="">{activeCurrency} {currencyValue(VisaObj.Fees ?? "")}</h5>
            </div>

            <div className="debtndrops">
              <Button className="btn"
                style={{ backgroundColor: "#bd0c21", color: "White", borderRadius:'40px' }}
                onClick={() => {
                  goToDetailPage(VisaObj);
                }}
              >
                View Details
              </Button>
              {visaGuideFaqs.length ? (
                <div className="visaGuide">
                  <Link
                    exact={true}
                    to="/visa/visaGuidelines"
                    target={"_blank"}
                  >
                    Visa Guidelines
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <Modal
            title="Cancel Policy"
            className="promo-modal-header modal-header-bg12"
           
            footer={null}
            width={"1100px"}
          >
            <div>
              <pre>{ReactHtmlParser(VisaObj.CancelPolicy ?? "No Policy")}</pre>
            </div>
          </Modal>
        </Col>
      </Row>
    </div>
  );
};

export default VisaCard;
