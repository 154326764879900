import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from "../common/providers/AuthProvider";
const AgentProtectedRoute = ({ component: Component, routeKey, ...rest }) => {
  const {
    isLogin: { agent },
    user,
    accessToken,
  } = useAuthContext();
  let services = user?.Services ? user?.Services : [];

  return (
    <Route
      {...rest}
      render={(props) => {
        return agent && accessToken ? (
          services.includes(routeKey) ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/unauthorized",
                state: { from: props.location },
              }}
            />
          )
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
};

export default AgentProtectedRoute;
