import React, { useEffect, useState } from "react";

import "./ContactForm.css";
import { Card, Col, Input, Layout, Table, Row, Popover } from "antd";
import excel from "../assets/vector-icons/excel.png";
import exchange from "../assets/vector-icons/exchange.png";
import search from "../assets/vector-icons/search.png";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";
import UpdatedComponent from "../ExportExcel";
import ApiClient from "../../helpers/ApiClient";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Content } = Layout;

function ContactForm(props) {
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [contactForms, setContactForms] = useState([]);

  useEffect(() => {
    GetForms();
  }, []);

  const GetForms = () => {
    ApiClient.get("admin/contacts")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              DepartureDate: moment(item.DepartureDate).format("DD-MM-YYYY"),
              CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          setContactForms(data);
        }
      })
      .catch((e) => {});
  };

  const tableColumns = [
    {
      title: "S. No.",
      dataIndex: "SNo",
    },

    {
      title: "Category",
      dataIndex: "CategoryName",
      sorter: (a, b) => a.CategoryName?.localeCompare(b.CategoryName),
    },

    {
      title: "First Name",
      dataIndex: "FirstName",
      sorter: (a, b) => a.FirstName?.localeCompare(b.FirstName),
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
    },
    {
      title: "ISD Code",
      dataIndex: "ISDCode",
    },
    {
      title: "Phone",
      dataIndex: "MobileNumber",
    },
    {
      title: "Email",
      dataIndex: "EmailAddress",
      sorter: (a, b) => a.EmailAddress?.localeCompare(b.EmailAddress),
    },
    {
      title: "Order ID",
      dataIndex: "OrderNumber",
    },
    {
      title: "Departure Date",
      dataIndex: "DepartureDate",
    },
    {
      title: "Message",
      dataIndex: "YourMessage",
    },

    {
      title: "Created Date",
      dataIndex: "CreatedDate",
    },
    {
      title: "Modified Date",
      dataIndex: "ModifiedDate",
    },
  ];

  /* === === === === Search Button Starts === === === === */

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = contactForms.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setContactForms(searchResults);
    } else {
      GetForms();
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const searchData = () => {
    setShowSearchBox(true);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  /* === === === === Search Button ends === === === === */

  /* === === === === Excel Button starts === === === === */

  const handleExcel = () => {
    const excelData = contactForms.map((item) => {
      return {
        ...item,
      };
    });

    props.exportExcel(excelData, "ContactFormDetails");
  };

  return (
    <div className="manage-markup-section">
      <Content className="container-fluid">
        <div className="card-bt-gap">
          <Card bordered={false}>
            <div className="card-add-heading">
              <div className="rows-count">
                <div>
                  <h5>
                    View Contact Us Forms{" "}
                    <HelpInfoHelper screenName={"/admin/contactforms"} />
                  </h5>
                  <p>{contactForms.length} rows found !</p>
                </div>
              </div>
              <div className="action-images">
                {showSearchBox && (
                  <Input
                    placeholder="Search"
                    onChange={(e) => searchTableData(e)}
                    suffix={closeSearchInput}
                    style={{ padding: "0px 12px" }}
                  />
                )}
                &nbsp;&nbsp;
                <img src={search} alt="search" onClick={(e) => searchData()} />
                <img src={excel} alt="excel" onClick={handleExcel} />
                <img src={exchange} alt="exchange" onClick={() => GetForms()} />
              </div>
            </div>
            <div>
              <Table
                className="table-scroll-none"
                bordered
                dataSource={contactForms}
                columns={tableColumns}
                pagination={{
                  defaultPageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100", "125"],
                }}
              />
            </div>
          </Card>
        </div>
      </Content>
    </div>
  );
}

export default UpdatedComponent(ContactForm);
