import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Modal,
  Popover,
  Row,
  Select,
  Table,
} from "antd";
import ReactHtmlParser from 'react-html-parser'
import ReactQuill from "react-quill";

import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import { useAuthContext } from "../../common/providers/AuthProvider";

import APIClient from "../../helpers/ApiClient";
import modules from "../../helpers/reactQuillModules";
// import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import {
  CloseOutlined,
  InfoCircleTwoTone,
  PlusCircleFilled,
} from "@ant-design/icons";
import "../CMS/cms.scss";
import UpdatedComponent from "../ExportExcel";
import { set } from "@antv/util";

const { Option } = Select;
const cmsOptions = [
  // {
  //   id: 1,
  //   name: "Our Team",
  // },
  // {
  //   id: 2,
  //   name: "About Us",
  // },
  {
    id: 3,
    name: "Testimonials",
  },
  {
    id: 4,
    name: "FAQs",
  },
  {
    id: 5,
    name: "Terms of Conditions",
  },
  {
    id: 6,
    name: "Privacy Policy",
  },
  {
    id: 7,
    name: "Disclaimer",
  },
  {
    id: 8,
    name: "Careers",
  },
  {
    id: 9,
    name: "Social Responsibility",
  },
  {
    id: 10,
    name: "Check Flight Info",
  },
  {
    id: 11,
    name: "Refund Policy",
  },
  {
    id: 12,
    name: "Investor Relations",
  },
];

const CMS = () => {
  const { user } = useAuthContext();
  const [CmsForm] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [content, setContent] = useState("");
  const [cmsList, setCMSList] = useState([]);
  const [excelList, setExcelList] = useState([]);

  const [showSearchBox, setShowSearchBox] = useState(false);

  const [cmsSearchId, setCmsSearchId] = useState(1);

  const [isEdit, setIsEdit] = useState(false);
  const [currID, setCurrID] = useState(-1);

  useEffect(() => {
    getCMSList(1);
  }, []);

  const getCMSList = (id) => {
    APIClient.get(`admin/cms/${id}`)
      .then((response) => {
        if (response?.status == 200) {
          setCMSList(response.data);
        } else {
          setCMSList([]);
        }
      })
      .catch((error) => {
        setCMSList([]);
      });
  };

  // const defineExcelData = (data) => {
  //   const filteredData = data.map((item) => {
  //     const obj = {
  //       Page: item.CMSName,
  //       Title: JSON.stringify(item.Description),
  //       Description: JSON.stringify(item.Description),
  //     };
  //     return obj;
  //   });
  //   setExcelList(filteredData);
  // };
  const createCms = (req) => {
    APIClient.post("admin/cms", req).then((res) => {
      if (res?.status == 200) {
        if (res?.message) message.success(res.message, 3);
        toggleModal();
        getCMSList(req.CMSID);
        setCmsSearchId(req.CMSID);
      } else {
        if (res?.message) message.error(res.message, 3);
        else message.error("Failed", 3);
      }
    });
  };

  const updateCmsApi = (req) => {
    APIClient.put(`admin/cms/${currID}`, {}, req).then((res) => {
      if (res?.status == 200) {
        if (res?.message) message.success(res.message, 3);
        setIsEdit(false);
        setCurrID(-1);
        toggleModal();
        getCMSList(req.CMSID);
        setCmsSearchId(req.CMSID);
      } else {
        if (res?.message) message.error(res.message, 3);
        else message.error("Failed", 3);
      }
    });
  };

  const onFinish = (values) => {
    if (!content) {
      message.error("Description Required");
      return;
    }
    let req = {
      CMSID: values.CMSID,
      UserID: user?.UserID,
      Title: values.Title,
      Description: content,
      CreatedBy: user?.UserID,
      ModifiedBy: 0,
    };

    if (isEdit) {
      delete req.CreatedBy;
      req.ModifiedBy = user?.UserID;
      updateCmsApi(req);
    } else {
      createCms(req);
    }
  };

  const deleteCms = (id, cmsId) => {
    APIClient.delete("admin/cms/" + id)
      .then((res) => {
        if (res?.status == 200) {
          if (res?.message) message.success(res.message, 3);
          getCMSList(cmsId);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Failed", 3);
        }
      })
      .catch((error) => {});
  };

  // const defineExcelData = (data) => {
  //   const filteredData = data.map((item) => {
  //     const obj = {
  //       CMSID: item.CMSID,
  //       UserID: item.UserID,
  //       CMSBannerID: item.CMSBannerID,
  //       Description: item.Description,
  //       Title: item.Title,
  //       Image: item.Image,
  //     };
  //     return obj;
  //   });
  //   setExcelList(filteredData);
  // };

  const handleDescription = (content) => {
    if (content === "<p><br></p>") {
      content = "";
    }
    setContent(content);
  };
  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = cmsList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setCMSList(searchResults);
    } else {
      getCMSList();
    }
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    lastIndex++;
    return lastIndex;
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );
  const searchData = () => {
    setShowSearchBox(true);
  };

  const toggleModal = () => {
    if (isEdit) {
      setIsEdit(false);
    }
    CmsForm.resetFields();
    setContent("");
    setModalVisible((prev) => !prev);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "Title",
      render: (text) => (
        <div style={{ lineHeight: '1.5' }}>
          {text}
        </div>
      ),
    },

    {
      title: "Description",
      dataIndex: "Description",
      render: (text) => (
        <div style={{ maxHeight: '100px',marginBottom:5, overflowY: 'auto',lineHeight: '1.5' }}>{ReactHtmlParser(text)}</div>
      ),
    },

    { title: "Actions", render: (currentItem) => tableActions(currentItem) },
  ];

  const updateCms = (currentData) => {
    setIsEdit(true);
    setModalVisible(true);
    setCurrID(currentData.Id);
    setContent(currentData.Description);
    CmsForm.setFieldsValue({
      CMSID: currentData.CMSID,
      Title: currentData.Title,
    });
  };

  const tableActions = (rec) => {
    return (
      <div>
        <span className="edit-icon" onClick={() => updateCms(rec)}>
          <i className="fa fa-edit"></i>
        </span>
        &nbsp;&nbsp;&nbsp;
        <Popconfirm
          title="Are u sure to delete?"
          onConfirm={() => deleteCms(rec.Id, rec.CMSID)}
        >
          <span className="delete-icon">
            <i className="fa fa-trash"></i>
          </span>
        </Popconfirm>
      </div>
    );
  };

  return (
    <>
      <div className="manage-markup-section">
        <div className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <div>
                    <h5>
                      View CMS <HelpInfoHelper screenName={"/admin/cms"} />
                    </h5>
                    <p>{cmsList.length} rows found !</p>
                  </div>
                </div>
                <div className="action-images">
                  {showSearchBox && (
                    <Input
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px" }}
                    />
                  )}
                  <img
                    src={search}
                    alt="search"
                    onClick={(e) => searchData(cmsList)}
                  />
                  {/* <img
                    src={excel}
                    alt="excel"
                    onClick={(e) => props.exportExcel(excelList, "CMS")}
                  /> */}
                  {/* <img src={pdf} alt="pdf" onClick={(e) => exportPDF()} /> */}
                  {/* <img src={word} alt="word" /> */}

                  {/* <img src={exchange} alt="exchange" /> */}
                  <p className="add-deposit-icon" onClick={toggleModal}>
                    <PlusCircleFilled />
                  </p>
                </div>
                {/* </div> */}
              </div>

              <Form>
                <Row>
                  <Col span={8}>
                    <Form.Item label="Page">
                      <Select
                        placeholder="Select Page"
                        onChange={(id) => {
                          getCMSList(id);
                          setCmsSearchId(id);
                        }}
                        defaultValue={cmsSearchId}
                        value={cmsSearchId}
                      >
                        {cmsOptions.map((cms) => (
                          <Option key={"cmsoption" + cms.id} value={cms.id}>
                            {cms.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Table
                scroll={{ x: true }}
                bordered
                rowKey={uniqueKey}
                dataSource={cmsList}
                columns={columns}
                className="cms-table"
              />
            </Card>
          </div>
        </div>
      </div>

      <Modal
        title={isEdit ? "Update CMS" : "Add CMS"}
        className="promo-modal-header modal-header-bg12"
        visible={modalVisible}
        onOk={toggleModal}
        onCancel={toggleModal}
        footer={[
          <div>
            <Button type="primary" htmlType="submit" onClick={CmsForm.submit}>
              {isEdit ? "Update" : "Add"}
            </Button>
            <Button type="primary" danger onClick={() => toggleModal()}>
              Cancel
            </Button>
          </div>,
        ]}
        width={"700px"}
      >
        <Form layout="vertical" name="basic" form={CmsForm} onFinish={onFinish}>
          <Row gutter={12}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Page"
                name="CMSID"
                rules={[{ required: true, message: "Required" }]}
              >
                <Select>
                  {cmsOptions.map((cms) => (
                    <Option key={cms.id + "cmsoption"} value={cms.id}>
                      {cms.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                label="Title"
                name="Title"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Description (Required)">
            <ReactQuill
              modules={modules}
              value={content}
              placeholder="Type Here ...."
              onChange={handleDescription}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default UpdatedComponent(CMS);
