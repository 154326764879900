// import {
//   getConvFee,
//   getPromoDiscount,
// } from "../../common/AllTickets/promoConvhelper";

// export const getFlightGrandTotalPrice = (
//   airPriceResp,
//   ConvFee,
//   promoData,
//   flightSearchObj,
//   selectedInsuranceData,
//   redeemAmount,
//   mealdetails,
//   baggagedetails
// ) => {
//   let adultCount = parseInt(flightSearchObj.adultCount);
//   let childCount = parseInt(flightSearchObj.childCount);
//   let infantCount = parseInt(flightSearchObj.infantCount);
//   let totalPax = adultCount + childCount + infantCount;
//   let insuranceTotal = 0;
//   let totalBaseFare = 0;
//   let totalAmount = 0;
//   let otherCharges = 0;
//   let convamount = 0;
//   let discount = 0;
//   let commission = 0;
//   let markup = 0;
//   let agentMarkup = 0;
//   let adminCommission = 0;
//   let grandTotal = 0;
//   let redeemTotal = 0;
//   let mealAmount = 0;
//   let baggageAmount = 0;
//   let ssrAmount = 0;
//   airPriceResp.flightDetails.forEach((flight) => {
//     flight.fareFamilies.forEach((fareFamily) => {
//       fareFamily.flightFares.forEach((fare) => {
//         if (fare.fareTag == "Base") {
//           if (fare.paxType == "ADT") {
//             totalBaseFare += adultCount * Number(fare.amount);
//           } else if (fare.paxType == "CHD") {
//             totalBaseFare += childCount * Number(fare.amount);
//           } else {
//             totalBaseFare += infantCount * Number(fare.amount);
//           }
//         } else if (fare.fareTag == "Tax") {
//           if (fare.paxType == "ADT") {
//             otherCharges += adultCount * Number(fare.amount);
//           } else if (fare.paxType == "CHD") {
//             otherCharges += childCount * Number(fare.amount);
//           } else {
//             otherCharges += infantCount * Number(fare.amount);
//           }
//         } else if (fare.fareTag == "commission") {
//           commission += Number(fare.amount);
//         } else if (fare.fareTag == "markup") {
//           markup += Number(fare.amount);
//         } else if (fare.fareTag == "agentMarkup") {
//           agentMarkup += Number(fare.amount);
//         } else if (fare.fareTag == "adminCommission") {
//           adminCommission += Number(fare.amount);
//         }
//       });
//     });
//   });
//   if (
//     selectedInsuranceData.status === 1 &&
//     selectedInsuranceData?.serviceType === 1
//   ) {
//     insuranceTotal = Number(totalPax) * Number(selectedInsuranceData.amount);
//   }

//   totalAmount = Number(totalBaseFare) + Number(otherCharges);

//   convamount = getConvFee(ConvFee, totalAmount);

//   grandTotal = totalAmount + Number(convamount);

//   discount = getPromoDiscount(promoData, totalAmount);
//   grandTotal -= discount;

//   grandTotal += Number(insuranceTotal);
//   if (redeemAmount?.CouponAmt) {
//     redeemTotal = Number(redeemAmount?.CouponAmt);
//   }
//   grandTotal -= redeemTotal;
//   // {
//   //   console.log(mealdetails?.map((meal) => {
//   //     meal?.map((connMeal) => {
//   //       (connMeal)
//   //     })
//   //   }), "mdd")
//   // }

//   // mealdetails?.map((meal) => {
//   //   mealAmount += Number(meal?.amount)
//   // })

//   mealdetails?.map((mealOpt) => {
//     // { console.log(mealOpt, "opt") };
//     mealOpt?.map((connMeal, i) => {
//       // { console.log(connMeal, "opt2") };
//       Object.values(connMeal).forEach((amt) => {
//         // { console.log(amt, "amt-1") };
//         if (amt?.amount != null) {
//           mealAmount += Number(amt?.amount);
//         }
//       });
//     })
//   })
//   // mealdetails?.map((mealOpt) => {
//   //   // Check if mealOpt is an array before using map
//   //   if (Array.isArray(mealOpt)) {
//   //     mealOpt?.map((connMeal) => {
//   //       // Check if connMeal is an array before using map
//   //       if (Array.isArray(connMeal)) {
//   //         connMeal?.map((amt) => {
//   //           if (amt?.amount != null) {
//   //             mealAmount += Number(amt?.amount);
//   //           }
//   //         });
//   //       } else if (typeof connMeal === 'object') {
//   //         // Iterate over object properties
//   //         Object.values(connMeal).forEach((amt) => {
//   //           if (amt?.amount != null) {
//   //             mealAmount += Number(amt?.amount);
//   //           }
//   //         });
//   //       }
//   //     });
//   //   } else {
//   //     // Handle the case when mealOpt is not an array (optional)
//   //     console.error('mealOpt is not an array:', mealOpt);
//   //   }
//   // });


//   // baggagedetails?.map((baggage) => {
//   //   baggageAmount += Number(baggage?.amount)
//   // })
//   // {console.log(baggagedetails,"ba")}
//   baggagedetails?.map((baggage) => {

//     baggage?.map((connBag, i) => {

//       Object.values(connBag).forEach((amt1) => {

//         if (amt1?.amount != null) {
//           baggageAmount += Number(amt1?.amount);
//         }
//       });
//     })
//   })
//   ssrAmount = mealAmount + baggageAmount;
//   // grandTotal += mealAmount;
//   // grandTotal += baggageAmount;
//   grandTotal += ssrAmount;

//   mealdetails?.map((meal) => {

//     meal?.map((connMeal) => {
//       connMeal?.map((amt) => {
//         if (amt?.amount != null) {
//           mealAmount += Number(amt?.amount)
//         }
//       })
//     })
//   })
//   baggagedetails?.map((baggage) => {
//     baggageAmount += Number(baggage?.amount)
//   })
//   ssrAmount = mealAmount + baggageAmount;
//   // grandTotal += mealAmount;
//   // grandTotal += baggageAmount;
//   grandTotal += ssrAmount;

//   mealdetails?.map((meal) => {

//     meal?.map((connMeal) => {
//       connMeal?.map((amt) => {
//         if (amt?.amount != null) {
//           mealAmount += Number(amt?.amount)
//         }
//       })
//     })
//   })
//   baggagedetails?.map((baggage) => {
//     baggageAmount += Number(baggage?.amount)
//   })
//   ssrAmount = mealAmount + baggageAmount;
//   // grandTotal += mealAmount;
//   // grandTotal += baggageAmount;
//   grandTotal += ssrAmount;

//   return {
//     grandTotal: Number(grandTotal).toFixed(2),
//     commission: Number(commission).toFixed(2),
//     convamount: Number(convamount).toFixed(2),
//     discount: Number(discount).toFixed(2),
//     markup,
//     agentMarkup,
//     adminCommission,
//     insuranceTotal: Number(insuranceTotal).toFixed(2),
//     redeemTotal: redeemTotal,
//     mealAmount: mealAmount,
//     baggageAmount: baggageAmount,
//   };
// };

// export const getFareTotal = (flightSearchObj, flightFares) => {
//   let totalBaseFare = 0;
//   let tax = 0;
//   let totalAmount = 0;
//   let adultPrice = 0;
//   let ChildPrice = 0;
//   let infantPrice = 0;
//   let adultCount = parseInt(flightSearchObj.adultCount);
//   let childCount = parseInt(flightSearchObj.childCount);
//   let infantCount = parseInt(flightSearchObj.infantCount);
//   flightFares.forEach((fareObj) => {
//     if (fareObj.fareTag === "Base") {
//       if (fareObj.paxType === "ADT") {
//         adultPrice += Number(fareObj.amount) * adultCount;
//       } else if (fareObj.paxType === "CHD") {
//         ChildPrice += Number(fareObj.amount) * childCount;
//       } else {
//         infantPrice += Number(fareObj.amount) * infantCount;
//       }
//     } else if (fareObj.fareTag == "Tax") {
//       if (fareObj.paxType == "ADT") {
//         tax += adultCount * Number(fareObj.amount);
//       } else if (fareObj.paxType == "CHD") {
//         tax += childCount * Number(fareObj.amount);
//       } else {
//         tax += infantCount * Number(fareObj.amount);
//       }
//     }
//   });

//   totalBaseFare = adultPrice + ChildPrice + infantPrice;

//   totalAmount = totalBaseFare + tax;

//   return {
//     adultPrice: Number(adultPrice).toFixed(2),
//     ChildPrice: Number(ChildPrice).toFixed(2),
//     infantPrice: Number(infantPrice).toFixed(2),
//     totalBaseFare: Number(totalBaseFare).toFixed(2),
//     tax: Number(tax).toFixed(2),
//     totalAmount: Number(totalAmount).toFixed(2),
//   };
// };

import {
  getConvFee,
  getPromoDiscount,
} from "../../common/AllTickets/promoConvhelper";

export const getFlightGrandTotalPrice = (
  airPriceResp,
  ConvFee,
  promoData,
  flightSearchObj,
  selectedInsuranceData,
  redeemAmount,
  mealdetails,
  baggagedetails,
  seatdetails,
) => {
  let adultCount = parseInt(flightSearchObj.adultCount);
  let childCount = parseInt(flightSearchObj.childCount);
  let infantCount = parseInt(flightSearchObj.infantCount);
  let totalPax = adultCount + childCount + infantCount;
  let insuranceTotal = 0;
  let totalBaseFare = 0;
  let totalAmount = 0;
  let otherCharges = 0;
  let convamount = 0;
  let discount = 0;
  let commission = 0;
  let markup = 0;
  let agentMarkup = 0;
  let adminCommission = 0;
  let grandTotal = 0;
  let redeemTotal = 0;
  let mealAmount = 0;
  let baggageAmount = 0;
  let seatAmount = 0;
  let ssrAmount = 0;
  airPriceResp.flightDetails.forEach((flight) => {
    flight.fareFamilies.forEach((fareFamily) => {
      fareFamily.flightFares.forEach((fare) => {
        if (fare.fareTag == "Base") {
          if (fare.paxType == "ADT") {
            totalBaseFare += adultCount * Number(fare.amount);
          } else if (fare.paxType == "CHD") {
            totalBaseFare += childCount * Number(fare.amount);
          } else {
            totalBaseFare += infantCount * Number(fare.amount);
          }
        } else if (fare.fareTag == "Tax") {
          if (fare.paxType == "ADT") {
            otherCharges += adultCount * Number(fare.amount);
          } else if (fare.paxType == "CHD") {
            otherCharges += childCount * Number(fare.amount);
          } else {
            otherCharges += infantCount * Number(fare.amount);
          }
        } else if (fare.fareTag == "commission") {
          commission += Number(fare.amount);
        } else if (fare.fareTag == "markup") {
          markup += Number(fare.amount);
        } else if (fare.fareTag == "agentMarkup") {
          agentMarkup += Number(fare.amount);
        } else if (fare.fareTag == "adminCommission") {
          adminCommission += Number(fare.amount);
        }
      });
    });
  });
  if (
    selectedInsuranceData.status === 1 &&
    selectedInsuranceData?.serviceType === 1
  ) {
    insuranceTotal = Number(totalPax) * Number(selectedInsuranceData.amount);
  }

  totalAmount = Number(totalBaseFare) + Number(otherCharges);

  convamount = getConvFee(ConvFee, totalAmount);

  grandTotal = totalAmount + Number(convamount);

  discount = getPromoDiscount(promoData, totalAmount);
  grandTotal -= discount;

  grandTotal += Number(insuranceTotal);
  if (redeemAmount?.CouponAmt) {
    redeemTotal = Number(redeemAmount?.CouponAmt);
  }
  grandTotal -= redeemTotal;
  // {
  //   console.log(mealdetails?.map((meal) => {
  //     meal?.map((connMeal) => {
  //       (connMeal)
  //     })
  //   }), "mdd")
  // }

  // mealdetails?.map((meal) => {
  //   mealAmount += Number(meal?.amount)
  // })

  mealdetails?.map((mealOpt) => {
    // { console.log(mealOpt, "opt") };
    mealOpt?.map((connMeal, i) => {
      // { console.log(connMeal, "opt2") };
      Object.values(connMeal).forEach((amt) => {
        // { console.log(amt, "amt-1") };
        if (amt?.amount != null) {
          mealAmount += Number(amt?.amount);
        }
      });
    })
  })
  // mealdetails?.map((mealOpt) => {
  //   // Check if mealOpt is an array before using map
  //   if (Array.isArray(mealOpt)) {
  //     mealOpt?.map((connMeal) => {
  //       // Check if connMeal is an array before using map
  //       if (Array.isArray(connMeal)) {
  //         connMeal?.map((amt) => {
  //           if (amt?.amount != null) {
  //             mealAmount += Number(amt?.amount);
  //           }
  //         });
  //       } else if (typeof connMeal === 'object') {
  //         // Iterate over object properties
  //         Object.values(connMeal).forEach((amt) => {
  //           if (amt?.amount != null) {
  //             mealAmount += Number(amt?.amount);
  //           }
  //         });
  //       }
  //     });
  //   } else {
  //     // Handle the case when mealOpt is not an array (optional)
  //     console.error('mealOpt is not an array:', mealOpt);
  //   }
  // });


  // baggagedetails?.map((baggage) => {
  //   baggageAmount += Number(baggage?.amount)
  // })
  // { console.log(seatdetails, "ba") }
  baggagedetails?.map((baggage) => {

    baggage?.map((connBag, i) => {

      Object.values(connBag).forEach((amt1) => {

        if (amt1?.amount != null) {
          baggageAmount += Number(amt1?.amount);
        }
      });
    })
  })
  seatdetails?.map((seat) => {

    seat?.map((connseat, i) => {

      Object.values(connseat).forEach((price) => {

        if (price?.price != null) {
          seatAmount += Number(price?.price);
        }
      });
    })
  })
  ssrAmount = mealAmount + baggageAmount + seatAmount;
  // grandTotal += mealAmount;
  // grandTotal += baggageAmount;
  grandTotal += ssrAmount;

  return {
    grandTotal: Number(grandTotal).toFixed(2),
    commission: Number(commission).toFixed(2),
    convamount: Number(convamount).toFixed(2),
    discount: Number(discount).toFixed(2),
    markup,
    agentMarkup,
    adminCommission,
    insuranceTotal: Number(insuranceTotal).toFixed(2),
    redeemTotal: redeemTotal,
    mealAmount: mealAmount,
    baggageAmount: baggageAmount,
    seatAmount: seatAmount,
  };
};

export const getFareTotal = (flightSearchObj, flightFares) => {
  let totalBaseFare = 0;
  let tax = 0;
  let totalAmount = 0;
  let adultPrice = 0;
  let ChildPrice = 0;
  let infantPrice = 0;
  let adultCount = parseInt(flightSearchObj.adultCount);
  let childCount = parseInt(flightSearchObj.childCount);
  let infantCount = parseInt(flightSearchObj.infantCount);
  // console.log(flightSearchObj,flightFares, "fare");
  flightFares.forEach((fareObj) => {
    if (fareObj.fareTag === "Base") {
      if (fareObj.paxType === "ADT") {
        adultPrice += Number(fareObj.amount) * adultCount;
      } else if (fareObj.paxType === "CHD") {
        ChildPrice += Number(fareObj.amount) * childCount;
      } else {
        infantPrice += Number(fareObj.amount) * infantCount;
      }
    } else if (fareObj.fareTag == "Tax") {
      if (fareObj.paxType == "ADT") {
        tax += adultCount * Number(fareObj.amount);
      } else if (fareObj.paxType == "CHD") {
        tax += childCount * Number(fareObj.amount);
      } else {
        tax += infantCount * Number(fareObj.amount);
      }
    }
  });

  totalBaseFare = adultPrice + ChildPrice + infantPrice;

  totalAmount = totalBaseFare + tax;

  return {
    adultPrice: Number(adultPrice).toFixed(2),
    ChildPrice: Number(ChildPrice).toFixed(2),
    infantPrice: Number(infantPrice).toFixed(2),
    totalBaseFare: Number(totalBaseFare).toFixed(2),
    tax: Number(tax).toFixed(2),
    totalAmount: Number(totalAmount).toFixed(2),
  };
};

