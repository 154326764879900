import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Switch,
  Table,
  Popover,
} from "antd";

import APIClient from "../../helpers/ApiClient";

import UpdatedComponent from "../ExportExcel";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const Membershipinfo = (props) => {
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const [imgUrl, setImgUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [Details, setDetails] = useState([]);

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [excelList, setExcelList] = useState([]);
  const [id, setId] = useState(-1);

  useEffect(() => {
    getMembershipDetails();
  }, []);

  const getMembershipDetails = () => {
    APIClient.get("admin/memberships")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              ...item,
              SNo: index + 1,
              Name: item.Name,
            };
          });
          setDetails(data);
          defineExcelData(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const clear = () => {
    setModalVisible(false);
  };

  const updateMembership = (currentData) => {
    setId(currentData.MembershipID);

    form2.setFieldsValue({
      Membership: currentData.Name,
    });
    setModalVisible(true);
  };

  const tableActions = (currentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => updateMembership(currentItem)}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteFlightComm(currentItem.MembershipID)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const deleteFlightComm = (id) => {
    APIClient.delete("admin/membership/" + id)
      .then((res) => {
        if (res.status == 200) {
          message.success("Deleted  successfully", 3);
          getMembershipDetails();
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {});
  };

  const columnsmanagebalstore = [
    {
      title: "S.No",
      dataIndex: "SNo",
    },
    {
      title: "Member",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },

    // {
    //   title: "Status",

    //   render: (currentItem) => handelStatus(currentItem),
    // },

    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  const handelStatus = (data) => {
    if (data.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };
  const changeStatus = (values, Status) => {
    let MembershipID = values.MembershipID;

    let data = {
      Name: values.Name,
      Status: Status,
      CreatedBy: 12,
      DeviceOS: 1,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
    };
    UpdateStatus(data, MembershipID);
  };

  const UpdateStatus = (data, id) => {
    APIClient.put(`admin/membership/${id}`, {}, data)
      .then((response) => {
        if (response.status == 400) {
          message.success("Details Updated SuccessFully", 3);
          getMembershipDetails();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSubmitDetails = (values) => {
    const data = {
      Name: values.Membership,
      CreatedBy: 12,
      DeviceOS: 1,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
    };
    UpdateMembership(data);
  };
  const onFinish = (values) => {
    const data = {
      Name: values.Membership,
      CreatedBy: 12,
      DeviceOS: 1,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
    };
    PostMembership(data);
  };
  const UpdateMembership = (formMapData) => {
    APIClient.put(`admin/membership/${id}`, {}, formMapData)
      .then((response) => {
        if (response.status == 400) {
          message.success("Details Updated SuccessFully", 3);
          setModalVisible(false);
          getMembershipDetails();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const PostMembership = (formMapData) => {
    APIClient.post("admin/membership", formMapData)
      .then((response) => {
        if (response.status == 200) {
          message.success("Membership Added SuccessFully", 3);

          //setModalVisible(false);
          getMembershipDetails();
          form.resetFields();
        } else if (response.status == 400 || response.code) {
          message.error("Duplicate details", 3);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = Details.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDetails(searchResults);
    } else {
      getMembershipDetails();
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };
  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        Member: item.Name,
        Status: item.Status,
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  return (
    <div>
      <div className="manage-markup-section">
        <div className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <div>
                    <h5>
                      Membership{" "}
                      <HelpInfoHelper screenName={"/admin/membershipinfo"} />
                    </h5>
                  </div>
                </div>
                <div className="action-images">
                  {showSearchBox && (
                    <Input
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px" }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <img
                    src={search}
                    alt="search"
                    onClick={(e) => searchData()}
                  />
                  <img
                    src={excel}
                    alt="excel"
                    onClick={(e) => props.exportExcel(excelList, "Membership")}
                  />
                </div>
              </div>

              <Form layout={"vertical"} form={form} onFinish={onFinish}>
                <Row gutter={16} align="middle">
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Add Membership"
                      name="Membership"
                      rules={[
                        { required: true, message: "Required" },
                        {
                          pattern: "^[a-zA-Z]*$",
                          message: "Only alphabets are allowed",
                        },
                      ]}
                    >
                      <Input placeholder="Name" />
                    </Form.Item>
                  </Col>

                  <Col md={6} xs={24}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table
                scroll={{ x: true }}
                columns={columnsmanagebalstore}
                dataSource={Details}
                pagination={{
                  defaultPageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100", "125"],
                }}
              />
            </Card>
          </div>
        </div>
      </div>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>Update Membership</h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        form={form}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button key="add" type="primary" onClick={form2.submit}>
              Update
            </Button>
            <Button key="submit" type="danger" onClick={clear}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        <Form layout={"vertical"} form={form2} onFinish={onSubmitDetails}>
          <Row>
            <Col md={24} xs={24}>
              <Form.Item
                label="Membership"
                name="Membership"
                rules={[
                  { required: true, message: "Required" },
                  {
                    pattern: "^[a-zA-Z]*$",
                    message: "Only alphabets are allowed",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdatedComponent(Membershipinfo);
