import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
//import * as ReactBoostrap from "react-bootstrap";
// import * as ANTD from "antd";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import moment from "moment";
import "rc-datepicker/lib/style.css";
import React, { useEffect, useState } from "react";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import ApiClient from "../../helpers/ApiClient";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
// import word from "../assets/vector-icons/word.png";
import search from "../assets/vector-icons/search.png";
import "./EmbassyDetails.scss";

import AirlineAutoCompleteSelect from "../../common/AutoCompleteSelect/AirlineAutoCompleteSelect";
import { useRef } from "react";
import AirportAutoComplete from "../../common/AutoCompleteSelect/AirportAutoCompleteSelect";
import AirportAutoCompleteforVisa from "../../common/AutoCompleteSelect/AirportAutoCompleteSelectforVisaembassy";

const { RangePicker } = DatePicker;

const EmbassyDetails = () => {
  const { Header, Footer, Sider, Content } = Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const [VisaMaster, setVisaMaster] = useState([]);
  const { Panel } = Collapse;
  const [isEditMode, setIsEditMode] = useState(true);
  const [id, setId] = useState(-1);
  const [CurrecyList, setCurrencyList] = useState([]);
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("vertical");
  const { TextArea } = Input;
  const [dataFlip, setDataFlip] = useState(false);
  const origin = useRef();
  const destination = useRef();

  const [countryLists, setCountryList] = useState([]);
  const [LanguageList, setLanguageList] = useState({});

  const [cityListByCountry, setCityListByCountry] = useState([]);

  const getCountriesLists = () => {
    ApiClient.get("admin/country")
      .then((res) => {
        if (res.status == 200) {
          setCountryList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getLanguageByCountry = (Country) => {
    setLanguageList({});
    ApiClient.get("admin/getLanguagesByCountryName/" + Country)
      .then((res) => {
        if (res.status == 200) {
          setLanguageList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCitiesyByCountry = (country) => {
    setCityListByCountry([]);

    ApiClient.post("admin/citiesbycountry", { country: country }).then(
      (res) => {
        if (res.status == 200) {
          setCityListByCountry(res.data);
        }
      }
    );
  };

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const handleAddClick = () => {
    form.resetFields();
    showModal();
    setIsEditMode(false);
  };
  const handleEditClick = (rec) => {
    let bestTimeToVisit = [];
    if (rec.bestTimeToVisit) {
      let dates = JSON.parse(rec.bestTimeToVisit);

      if (dates.length === 2) {
        bestTimeToVisit = [moment(dates[0]), moment(dates[1])];
      }
    }

    form.setFieldsValue({
      ...rec,
      independenceDay: moment(rec.independenceDay),
      Visa: `${rec.visaId}~${rec.visaName}`,
      bestTimeToVisit: bestTimeToVisit,
    });

    setIsEditMode(true);

    showModal();
  };
  const initialFormData = {
    name: "",
    description: "",
    status: "",
  };
  const [dataSource, setDataSource] = useState([]);
  const getAllEmbassyDetails = () => {
    ApiClient.get("visa/getEmbassyDetails").then((res) => {
      if (res.status == 200) {
        setDataSource(res.data);
      }
    });
  };
  const getVisaMaster = () => {
    ApiClient.get("visa/getAllManageMasterVisa").then((res) => {
      if (res.status == 200) {
        setVisaMaster(res.data);
      }
    });
  };
  useEffect(() => {
    getVisaMaster();
    getAllEmbassyDetails();
    getCurrencies();
    getCountriesLists();
  }, []);
  const submitNewEmbassyDetails = (formvalues) => {
    formvalues = {
      ...formvalues,
      independenceDay: formvalues.independenceDay
        ? moment(formvalues.independenceDay).format("YYYY-MM-DD")
        : null,
    };
    ApiClient.post("visa/addEmbassyDetails", formvalues).then((response) => {
      if (response.status === 200) {
        setModalVisible(false);
        message.success("Embassy Details created successfully");
        getAllEmbassyDetails();
        form.resetFields();
      } else {
        message.error(" Error Creating Embassy Details");
      }
    });
  };

  const handleSubmit = (formvalues) => {
    const VisaRecord = VisaMaster.filter(
      (item) => item.Id == formvalues.visaId
    );

    formvalues.visaName = VisaRecord[0].VisaName;
    if (isEditMode) {
      submitUpdateEmbassyDetails(formvalues);
    } else {
      submitNewEmbassyDetails(formvalues);
    }
  };

  const submitUpdateEmbassyDetails = (formvalues) => {
    const updatedFormData = {
      ...formvalues,
    };
    ApiClient.put("visa/updateEmbassyDetails/" + id, {}, updatedFormData).then(
      (response) => {
        if (response.status === 200) {
          setModalVisible(false);
          message.success("Embassy updated successfully");
          getAllEmbassyDetails();
          form.resetFields();
        } else {
          message.error(" Error Updating Embassy ");
        }
      }
    );
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const getCurrencies = () => {
    ApiClient.get("currency/getallcurrency")
      .then((res) => {
        if (res.status == 200) {
          setCurrencyList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const deleteEmbassyDetails = (id) => {
    ApiClient.delete("visa/deleteEmbassyDetails/" + id).then((res) => {
      if (res.status == 200) {
        message.success("Embassy Deleted  successfully", 3);
        getAllEmbassyDetails();
      }
    });
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              handleEditClick(rec);
              setId(rec.Id);
            }}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteEmbassyDetails(rec.Id)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const { Option } = Select;
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 80 }} placeholder="+91">
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );
  const config = {
    rules: [{ type: "object", required: true, message: "Please select time!" }],
  };
  const columns = [
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
    {
      title: "visa Name",
      dataIndex: "visaName",
    },

    {
      title: "Basic Req",
      dataIndex: "basicReq",
    },
    {
      title: "Embassy Address",
      dataIndex: "embassyAddress",
    },
    {
      title: "Embassy Contact",
      dataIndex: "embassyContact",
    },
    {
      title: "Embassy Email",
      dataIndex: "embassyEmail",
    },
    {
      title: "Embassy Url",
      dataIndex: "embassyUrl",
    },
    {
      title: "Region",
      dataIndex: "region",
    },
    {
      title: "Major City",
      dataIndex: "majorCity",
    },
    {
      title: "Main Airline",
      dataIndex: "mainAirline",
    },
    {
      title: "International Airport",
      dataIndex: "internationalAirport",
    },
    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
  ];

  const handelStatus = (data) => {
    console.log(data,data.Status, "statusemb");
    if (data.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 0)}
        />
      );
    }
  };

  const handleOnSubmit = (ref) => {};

  const changeStatus = (info) => {
    // let obj = {
    //   Id: info.CarTypeID,
    //   status: Status,
    // };
    // ApiClient.put("carextranet/toggleCarTypeStatus/" + info.CarTypeID)
    //   .then((response) => {
    //     if (response.status == 200) {
    //       message.success("Car Type Status updated successfully !");
    //       getAllCarType();
    //     } else {
    //       message.error(response.message, 3);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Embassy Details{" "}
                          <HelpInfoHelper screenName={"/admin/cartypes"} />
                        </h5>
                        <p></p>
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />
                      <img src={excel} alt="excel" />
                      <img src={pdf} alt="pdf" />
                      <p className="add-deposit-icon" onClick={handleAddClick}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>
                  {dataSource.length ? (
                    <div>
                      <Table
                        bordered
                        dataSource={dataSource}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  ) : (
                    <b>No record Found</b>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title="Embassy Details"
        className="promo-modal-header modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <Form
          form={form}
          layout={formLayout}
          initialValues={{ layout: formLayout }}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col md={6} xs={24}>
              <Form.Item
                label="Visa "
                rules={[{ required: true }]}
                name="visaId"
              >
                <Select placeholder="Please Select Your Entry">
                  {VisaMaster.length
                    ? VisaMaster.map((item, index) => {
                        return (
                          <Option key={"visaMaster" + index} value={item.Id}>
                            {item.VisaName}
                          </Option>
                        );
                      })
                    : ""}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Basic Requirements to visit"
                rules={[{ required: true }]}
                name="basicReq"
              >
                <Select placeholder="Please Select Your Requirement">
                  <Select.Option value="PanCard">Pan Card</Select.Option>
                  <Select.Option value="AdhaarCard">Adhaar Card</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Travel Checklist"
                rules={[{ required: true }]}
                name="travelCheckList"
              >
                <Select placeholder="Please Select Your Travel Checklist">
                  <Select.Option value="Wallet">Wallet</Select.Option>
                  <Select.Option value="Keys">Keys</Select.Option>
                  <Select.Option value="PassPort">PassPort</Select.Option>
                  <Select.Option value="CashCredit">Cash Credit</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                name="embassyUrl"
                label="Embassy Website Address"
                rules={[
                  {
                    required: true,
                    message: "Please input your  Embassy website address",
                  },
                  { type: "url" },
                ]}
              >
                <Input placeholder="Please enter your  Embassy website address" />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                name="embassyAddress"
                label="Embassy Address"
                rules={[
                  {
                    required: true,
                    message: "Please input your Embassy Address",
                  },
                ]}
              >
                <Input placeholder="Please enter your Embassy Address" />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                name="embassyContact"
                label="Embassy Contact number"
                rules={[
                  {
                    required: true,
                    message: "Please input your Embassy Contact number",
                  },
                  {
                    minLength: 10,
                    maxLength: 10,
                    pattern: "^[0-9]{10}$",
                    message: "Must be 10 digits",
                  },
                ]}
              >
                <Input
                  placeholder="Please enter your Embassy Contact number"
                  min={0}
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                name="embassyEmail"
                label=" Embassy Email Address"
                rules={[
                  {
                    required: true,
                    message: "Please input your  Embassy Email Address",
                  },
                  { type: "email", message: "Please Insert a Valid Email" },
                ]}
              >
                <Input placeholder="Please enter your  Embassy Email Address" />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                name="embassyAddress2"
                label="Embassy Address2"
                rules={[
                  {
                    message: "Please input your Embassy Address",
                  },
                ]}
              >
                <Input placeholder="Please enter your Embassy Address" />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                name="embassyContact2"
                label="Embassy Contact Number2"
                rules={[
                  {
                    message: "Please input your Embassy Contact number",
                  },
                  {
                    minLength: 10,
                    maxLength: 10,
                    pattern: "^[0-9]{10}$",
                    message: "Must be 10 digits",
                  },
                ]}
              >
                <Input
                  placeholder="Please enter your Embassy Contact number"
                  min={0}
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                name="embassyEmail2"
                label=" Embassy Email Address2"
                rules={[
                  {
                    message: "Please input your  Embassy Email Address",
                  },
                  { type: "email", message: "Please Insert a Valid Email" },
                ]}
              >
                <Input placeholder="Please enter your  Embassy Email Address" />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                name="embassyAddress3"
                label="Embassy Address3"
                rules={[
                  {
                    message: "Please input your Embassy Address",
                  },
                ]}
              >
                <Input placeholder="Please enter your Embassy Address" />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                name="embassyContact3"
                label="Embassy Contact Number3"
                rules={[
                  {
                    message: "Please input your Embassy Contact number",
                  },
                  {
                    minLength: 10,
                    maxLength: 10,
                    pattern: "^[0-9]{10}$",
                    message: "Must be 10 digits",
                  },
                ]}
              >
                <Input
                  placeholder="Please enter your Embassy Contact number"
                  min={0}
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                name="embassyEmail3"
                label=" Embassy Email Address3"
                rules={[
                  {
                    message: "Please input your  Embassy Email Address",
                  },
                  { type: "email", message: "Please Insert a Valid Email" },
                ]}
              >
                <Input placeholder="Please enter your  Embassy Email Address" />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                name="embassyAddress4"
                label="Embassy Address4"
                rules={[
                  {
                    message: "Please input your Embassy Address",
                  },
                ]}
              >
                <Input placeholder="Please enter your Embassy Address" />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                name="embassyContact4"
                label="Embassy Contact Number4"
                rules={[
                  {
                    message: "Please input your Embassy Contact number",
                  },
                  {
                    minLength: 10,
                    maxLength: 10,
                    pattern: "^[0-9]{10}$",
                    message: "Must be 10 digits",
                  },
                ]}
              >
                <Input
                  placeholder="Please enter your Embassy Contact number"
                  min={0}
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                name="embassyEmail4"
                label=" Embassy Email Address4"
                rules={[
                  {
                    message: "Please input your  Embassy Email Address",
                  },
                  { type: "email", message: "Please Insert a Valid Email" },
                ]}
              >
                <Input placeholder="Please enter your  Embassy Email Address" />
              </Form.Item>
            </Col>
          </Row>
          <Collapse accordion>
            <Panel header="Facts And Figure" key="1">
              <Row gutter={16}>
                <Col md={6} sm={6} xs={6}>
                  <Form.Item
                    label="Region"
                    rules={[{ required: true }]}
                    name="region"
                  >
                    <Select placeholder="Please Select Your Region ">
                      <Option value="South">South</Option>
                      <Option value="North">North</Option>
                      <Option value="East">East</Option>
                      <Option value="West">West</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <Form.Item
                    label="Country"
                    rules={[{ required: true }]}
                    name="capital"
                  >
                    <Select
                      placeholder="Please Select Your Capital "
                      onChange={(e) => {
                        getCitiesyByCountry(e);
                        getLanguageByCountry(e);
                      }}
                      showSearch
                    >
                      {countryLists.map((i, index) => (
                        <Option key={"country" + index} value={i.Country}>
                          {i.Country}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <Form.Item
                    label="Major Cities"
                    rules={[{ required: true }]}
                    name="majorCity"
                  >
                    <Select placeholder="Please Select Your Cities " showSearch>
                      {cityListByCountry.length
                        ? cityListByCountry.map((i, index) => (
                            <Option key={"city" + index} value={i}>
                              {i}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <Form.Item
                    label="Language"
                    rules={[{ required: true }]}
                    name="language"
                  >
                    <Select placeholder="Please Select Language ">
                      {Object.keys(LanguageList).length
                        ? Object.keys(LanguageList.Languages).length
                          ? Object.keys(LanguageList.Languages).map(
                              (item, index) => {
                                return (
                                  <Option value={LanguageList.Languages[item]}>
                                    {" "}
                                    {LanguageList.Languages[item]}
                                  </Option>
                                );
                              }
                            )
                          : ""
                        : ""}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <Form.Item
                    label="Area"
                    rules={[{ required: true }]}
                    name="area"
                  >
                    <Input placeholder="Please Enter Your Area" />
                  </Form.Item>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <Form.Item
                    label="Currency"
                    rules={[{ required: true }]}
                    name="currency"
                  >
                    <Select placeholder="Please Select Your Currency">
                      {CurrecyList.length
                        ? CurrecyList.map((item, index) => {
                            return (
                              <Option
                                key={"Currency" + index}
                                value={item.Code}
                              >
                                {item.Code}-{item.CurrencyName}
                              </Option>
                            );
                          })
                        : ""}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  {/* <Form.Item
                    label="Main Airline"
                    rules={[{ required: true }]}
                    name="mainAirline"
                  >
                    <Select placeholder="Please Select Your Airline">
                      <Option value="Indigo">Indigo</Option>
                      <Option value="AirIndia">Air India</Option>
                      <Option value="GoAir">Go Air</Option>
                    </Select>
                  </Form.Item> */}

                  <AirlineAutoCompleteSelect
                    formItemProps={{
                      label: "Main Airline",
                      name: "mainAirline",
                    }}
                    selectProps={{
                      placeholder: "Search Airline Name",
                      mode: "tags",
                    }}
                    api={"flights/airlineNames/search/"}
                    keytext="nameAirline"
                    keyvalue="nameAirline"
                  />
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item
                    label="Independence Day"
                    name="independenceDay"
                    rules={[
                      {
                        required: true,
                        message: "Please select Independence Day",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="Select From date"
                    />
                  </Form.Item>
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <label>International Airports</label>

                  <AirportAutoCompleteforVisa
                    formItemProps={{
                      name: "internationalAirport",

                      rules: [
                        {
                          required: true,
                          message: "Please Specify The City",
                        },
                      ],
                    }}
                    selectProps={{
                      placeholder: "City Name",
                    }}
                    refName={origin}
                    focusRef={destination}
                    handleOnSubmit={handleOnSubmit}
                    dataFlip={dataFlip}
                  />
                </Col>
                <Col md={6} sm={6} xs={6}>
                  <Form.Item
                    label="Best Time To Visit"
                    rules={[{ required: true }]}
                    name="bestTimeToVisit"
                  >
                    <RangePicker picker="month" />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <Form.Item {...tailLayout} style={{ marginBottom: 0, marginTop: 10 }}>
            <Button type="primary" htmlType="submit">
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button type="primary" danger className="cancel-btn">
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EmbassyDetails;
