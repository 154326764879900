import React, { useEffect, useState } from "react";
import { Button, Form, message, Radio, Space, Spin } from "antd";
import { useHistory } from "react-router";
import { useAuthContext } from "../common/providers/AuthProvider";
import { LoadingOutlined } from "@ant-design/icons";
import ApiClient from "./ApiClient";
import PaymentComponent from "../helpers/CCavanuePayCheckout";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);


const PaymentGateway = ({ data, directPayment = false, loadingSpin }) => {
  const { user } = useAuthContext();
  let history = useHistory();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    OrderId: "string",
    OrderAmount: 0,
    OrderCurrency: "string",
    OrderNote: "string",
    CustomerName: "string",
    CustomerEmail: "string",
    CustomerPhone: "string",
  });
  const [PaymentOptionsVisible, setPaymentOptionsVisible] = useState(false);
  const [pgData, setPgData] = useState({});
  const [openCCavanueCheckout, setOpenCCavanueCheckout] = useState(false);
  const showPaymentOptions = () => setPaymentOptionsVisible(true);

  useEffect(() => {
    if (user && !directPayment) {
      if (user.Role.RoleId === 5 || user.Role.RoleId === 2) {
        showPaymentOptions();
      }
    } else {
      ProcessDpoPayment();
      // createCashFreePayment();
    }
  }, []);
  // const ProcessUsingCashfree = () => {
  //   let cashfree_form = document.getElementById("redirectform");
  //   cashfree_form.submit();
  // };
  const ProcessDpoPayment = (res) => {
    // console.log(res, "pay");
    // // let pay_form = resp.
    // window.location.href = res?.data?.payment_link;
    createDPOlink();
  }

  const submitform = ({ PgType }) => {
    if (PgType === 1) {
      // createCashFreePayment();
      createDPOlink();
    } else if (PgType === 3) {
      createDepositePayment(PgType);
    }
  };
  const createDPOlink = () => {
    setFormData({ ...data });

    ApiClient.post("admin/createpgorder", {
      ...data,
      DeviceOS: 3,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      PgType: 1,
      UserId: user?.UserID ?? 1,
      RoleType: user?.Role?.RoleId ?? 4,
    }).then((res) => {
      if (res.status === 200) {
        // console.log(res, "pay");
        // let pay_form = resp.
        if (res?.data?.pgType == 1) {
          ccavanueCheckoutData(res.data)
        } else if (res?.data?.pgType == 2) {
          window.location.href = res?.data?.payment_link;
        } else {
          window.location.href = res?.data?.url;
        }
        // window.location.href = res?.data?.payment_link;
        // setFormData({ ...data, signature: res.data });
        // ProcessUsingCashfree();
        // ProcessDpoPayment(res);
      } else {
        alert("Error from Api");
      }
    });
  };
  const ccavanueCheckoutData = (resp) => {
    setPgData(resp)
    setOpenCCavanueCheckout(true)
  }
  const createCashFreePayment = () => {
    setFormData({ ...data });

    ApiClient.post("admin/createPgSignature", {
      ...data,
      DeviceOS: 3,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      PgType: 1,
      UserId: user?.UserID ?? 1,
      RoleType: user?.Role?.RoleId ?? 4,
    }).then((res) => {
      if (res.status === 200) {

        setFormData({ ...data, signature: res.data });
        // ProcessUsingCashfree();
      } else {
        alert("Error from Api");
      }
    });
  };
  const createDepositePayment = (PgType) => {
    setFormData({ ...data });
    let walletAmount = parseFloat(user?.Walletdetails?.Amount);
    let ticketPrice = parseFloat(data?.OrderAmount);

    if (walletAmount >= ticketPrice) {
      ApiClient.post("admin/despositePayment", {
        ...data,
        DeviceOS: 3,
        DeviceOSVersion: "string",
        DeviceToken: "string",
        PgType: PgType,
        UserId: user?.UserID ?? 1,
        RoleType: user?.Role?.RoleId ?? 4,
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.url) {
              window.location.href = res.data.url;
            } else {
              message.error("Failed. Please Choose Another Payment Option. ");
            }
          } else if (res.status === 400) {
            message.error(res.message);
          }
        })
        .catch((e) => { });
    } else {
      message.error(
        "Insufficient Wallet Balance. Please Choose Another Payment Option. "
      );
    }
  };

  return (
    <div>
      {PaymentOptionsVisible && (
        <Form layout="vertical" form={form} onFinish={submitform}>
          <Form.Item
            name="PgType"
            label="Choose Payment Type"
            rules={[{ required: true, message: "Select Payment Gateway" }]}
          >
            <Radio.Group size="large">
              <Space direction="vertical">
                {user.Role.RoleId === 2 && <Radio value={3}>Wallet </Radio>}
                {user.Role.RoleId === 5 && <Radio value={3}>Deposit </Radio>}
                <Radio value={1}>Payment Gateway</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {loadingSpin ? (
            <Button style={{ background: "#023ba7" }} className="payment-btn" disabled>
              <Spin indicator={antIcon} />
            </Button>
          ) : (
            <Button className="payment-btn" onClick={() => form.submit()}>
              Continue Payment
            </Button>
          )}
        </Form>
      )}

      <form
        id="redirectform"
        method="post"
        action={process.env.REACT_APP_CASHFREE_URL}
      >
        <input
          type="hidden"
          name="appId"
          value={process.env.REACT_APP_PG_APPID}
        />
        <input type="hidden" name="orderId" value={formData.OrderId} />
        <input type="hidden" name="orderAmount" value={formData.OrderAmount} />
        <input
          type="hidden"
          name="orderCurrency"
          value={formData.OrderCurrency}
        />
        <input type="hidden" name="orderNote" value={formData.OrderNote} />
        <input
          type="hidden"
          name="customerName"
          value={formData.CustomerName}
        />
        <input
          type="hidden"
          name="customerEmail"
          value={formData.CustomerEmail}
        />
        <input
          type="hidden"
          name="customerPhone"
          value={formData.CustomerPhone}
        />
        <input
          type="hidden"
          name="returnUrl"
          value={`${process.env.REACT_APP_BASE_DOMAIN}/api/v1/admin/response/pg/redirect`}
        />
        <input
          type="hidden"
          name="notifyUrl"
          value=""
        // value={`${process.env.REACT_APP_BASE_DOMAIN}/api/v1/admin/response/pg/redirect`}
        />
        <input type="hidden" name="signature" value={formData.signature} />
      </form>
      {
        openCCavanueCheckout && (
          <PaymentComponent
            pgData={pgData}

          />
        )
      }
    </div>
  );
};

export default PaymentGateway;
