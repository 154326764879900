import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Table,
  Layout,
  Modal,
  Collapse,
} from "antd";

// import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
// import exchange from "../assets/vector-icons/exchange.png";
import AirlineNameAutoCompleteSelect from "../../common/AutoCompleteSelect/CMSAirlineNameAutoCompleteSelect";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import {
  PlusOutlined,
  EditOutlined,
  IdcardOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import modules from "../../helpers/reactQuillModules";
import "./CmsFareRules.scss";

import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;

const CmsFareRules = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [CmsForm] = Form.useForm();

  const [cmsFareRules, setCmsFareRules] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [currID, setCurrID] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [inputBox, setInputBox] = useState(-1);
  const [excelList, setExcelList] = useState([]);
  const [membershipList, setMembershipList] = useState([]);
  const [roleType, setRoleType] = useState(1);
  const initialState = {
    otherRulesDescription: "",
    reschedulePolicyDescription: "",
    cancelPolicyDescription: "",
    covidRulesDescription: "",
  };
  const [content, setContent] = useState(initialState);

  const getContent = (content, type) => {
    if (content === "<p><br></p>") {
      content = "";
    }

    setContent((prev) => ({
      ...prev,
      [type]: content,
    }));
  };

  useEffect(() => {
    getMembership();
    getCmsFareRules();
  }, []);

  const getMembership = () => {
    ApiClient.get("admin/memberships")
      .then((res) => {
        if (res.status == 200) {
          setMembershipList(res.data);
        } else {
          setMembershipList([]);
        }
      })
      .catch((error) => {
        setMembershipList([]);
      });
  };
  const getCmsFareRules = () => {
    ApiClient.get("admin/getFareRules")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setCmsFareRules(data);
          defineExcelData(data);
        } else {
          setCmsFareRules([]);
        }
      })
      .catch((e) => {
        setCmsFareRules([]);
      });
  };

  const submitForm = (values) => {
    if (values.serviceType === 1 || values.serviceType === 2) {
      let airlineData = values.Airline.split("&&");
      var data = {
        ...values,
        airlineName: airlineData[0],
        airlineCode: airlineData[1],
        ...content,
      };
    } else {
      var data = {
        ...values,
        ...content,
      };
    }

    isEdit ? updateCmsFareRules(data) : addCmsFareRules(data);
  };

  const addCmsFareRules = (data) => {
    ApiClient.post("admin/addFareRules", data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Cms Fare Rules added successfully !");
          getMembership();
          getCmsFareRules();
          setModalVisible(false);
          CmsForm.resetFields();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateCmsFareRules = (data) => {
    ApiClient.put("admin/updateFareRules/" + currID, {}, data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Cms Fare Rules updated successfully !");
          getMembership();
          getCmsFareRules();
          setModalVisible(false);
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteCmsFareRules = (id) => {
    ApiClient.delete("admin/deleteFareRules/" + id)
      .then((response) => {
        if (response.status == 200) {
          message.success("Cms Fare Rules Deleted successfully !");
          getCmsFareRules();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const showModal = () => {
    if (isEdit) {
      setInputBox(-1);
      setIsEdit(false);
      setContent(initialState);
      CmsForm.resetFields();
    }
    setModalVisible({
      visible: true,
    });
  };

  const updateCms = (currentData) => {
    setIsEdit(true);
    setModalVisible(true);
    setCurrID(currentData.id);
    setInputBox(currentData.serviceType);
    CmsForm.setFieldsValue({
      serviceType: currentData.serviceType,
      Airline: `${currentData.airlineName}&&${currentData.airlineCode}`,
      fareType: currentData.fareType,
      membershipID: currentData.membershipID,
      policyType: currentData.policyType,
      roleID: currentData.roleID,
    });

    let obj = {
      cancelPolicyDescription: currentData.cancelPolicyDescription ?? "",
      reschedulePolicyDescription:
        currentData.reschedulePolicyDescription ?? "",
      covidRulesDescription: currentData.covidRulesDescription ?? "",
      otherRulesDescription: currentData.otherRulesDescription ?? "",
    };
    setContent(obj);
  };
  const getServiceName = (data) => {
    switch (data) {
      case 1:
        return "Flights ";
      case 2:
        return "";
      case 3:
        return " Hotels";
      case 4:
        return "Hotels";
      case 5:
        return "Activities";
      default:
        break;
    }
  };
  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        ServiceType: getServiceName(item.ServiceType),
        TermsAndConditions: item.TermsAndConditions,
        OperatorName: item.OperatorName,
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const tableActions = (currentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}
          onClick={() => updateCms(currentItem)}
        >
          <EditOutlined
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteCmsFareRules(currentItem.id)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "Service Name",
      dataIndex: "serviceType",
      render: (serviceType) => getServiceName(serviceType),
      sorter: (a, b) => a.ServiceType - b.ServiceType,
    },
    {
      title: "Operator Name",
      dataIndex: "airlineName",
      render: (text, rec) => {
        if ([1, 2].includes(rec.serviceType)) return text;
        else return "ALL";
      },
    },
    {
      title: "Fare Type",
      dataIndex: "fareType",
      render: (text, rec) => {
        if ([1, 2].includes(rec.serviceType)) return text;
        else return "ALL";
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role) => {
        return role?.Name === "Agent" ? "Partner" : role?.Name;
      },
    },
    {
      title: "Membership",
      dataIndex: "membership",
      render: (membership) => {
        return membership?.Name;
      },
    },

    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = cmsFareRules.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setCmsFareRules(searchResults);
    } else {
      getCmsFareRules();
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  const handleServiceType = (val) => {
    setContent(initialState);
    setInputBox(val);
  };
  const handleRoleType = (val) => setRoleType(val);

  return (
    <div className="promo providerapi">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          View Cms Fare Rules{" "}
                          <HelpInfoHelper screenName={"/admin/CmsFareRules"} />
                        </h5>
                        {cmsFareRules.length > 0 && (
                          <p>{cmsFareRules.length} rows found !</p>
                        )}
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      {/* <img
                          src={excel}
                          alt="excel"
                          // onClick={handleExcel}
                          onClick={(e) =>
                            props.exportExcel(excelList, "CMS Terms & Offers")
                          }
                        /> */}
                      {/*  <img src={exchange} alt="exchange" /> */}
                      <p className="add-icon" onClick={showModal}>
                        <PlusOutlined />
                      </p>
                    </div>
                  </div>
                  {cmsFareRules.length > 0 ? (
                    <div>
                      <Table
                        className="table-scroll-none"
                        bordered
                        dataSource={cmsFareRules}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  ) : (
                    <b>No record Found</b>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>
                {isEdit ? "Update Cms Fare Rules" : "Add Cms Fare Rules"}
              </strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header admin-bc-level modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        width={900}
        footer={[
          <div>
            <Button htmlType="submit" type="primary" onClick={CmsForm.submit}>
              {isEdit ? "Update" : "Add"}
            </Button>
            <Button
              type="danger"
              onClick={() => {
                CmsForm.resetFields();
                setContent(initialState);
              }}
            >
              Clear
            </Button>
          </div>,
        ]}
      >
        <Form layout={"vertical"} form={CmsForm} onFinish={submitForm}>
          <Row gutter={(12, 12)} className="mb-2">
            <Col md={12} xs={24}>
              <Form.Item
                label="Service List"
                rules={[{ required: true }]}
                name="serviceType"
              >
                <Select
                  prefix={<IdcardOutlined className="site-form-item-icon" />}
                  placeholder="Please Select"
                  onSelect={handleServiceType}
                >
                  <Option value={1}> Flights</Option>

                  <Option value={3}> Hotels</Option>

                  <Option value={5}>Activities</Option>
                </Select>
              </Form.Item>
            </Col>
            {inputBox === 1 || inputBox === 2 ? (
              <>
                <Col md={12} xs={24}>
                  <AirlineNameAutoCompleteSelect
                    formItemProps={{
                      label: "Airline",
                      name: "Airline",
                      rules: [{ required: true }],
                    }}
                    selectProps={{ placeholder: "Search Airline Name" }}
                    api={"flights/airlineNames/search/"}
                    keytext="nameAirline"
                    keyvalue="codeIataAirline"
                  />
                </Col>
                <Col md={12} xs={24}>
                  <Form.Item
                    label="Fare Type"
                    rules={[{ required: true }]}
                    name="fareType"
                  >
                    <Select
                      prefix={
                        <IdcardOutlined className="site-form-item-icon" />
                      }
                      placeholder="Please Select"
                    >
                      <Option value="General">General</Option>
                      <Option value="CorporateFare">CorporateFare</Option>
                      <Option value="SmeFare">Sme Fare</Option>
                      <Option value="CouponFare">Coupon Fare</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" md={12} xs={24}>
                  <Form.Item
                    label="Role Type"
                    name="roleID"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Please Select"
                      onSelect={(val) => handleRoleType(val)}
                    >
                      <Option value={2}>Users</Option>
                      <Option value={4}>Guest</Option>
                      <Option value={5}>Partner</Option>
                    </Select>
                  </Form.Item>
                </Col>
                {roleType === 5 && (
                  <Col className="gutter-row" md={12} xs={24}>
                    <Form.Item
                      label="Membership"
                      name="membershipID"
                      rules={[{ required: true }]}
                    >
                      <Select
                        placeholder="Select Membership"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {membershipList.map((data, index) => (
                          <Option key={index} value={data.MembershipID}>
                            {data.Name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
              </>
            ) : null}
          </Row>
          <Collapse accordion>
            <Panel header="Cancel Policy Description" key="1">
              <Form.Item>
                <ReactQuill
                  modules={modules}
                  value={content.cancelPolicyDescription}
                  placeholder="please input ..."
                  onChange={(e) => getContent(e, "cancelPolicyDescription")}
                />
              </Form.Item>
            </Panel>
            {inputBox === 1 || inputBox === 2 ? (
              <Panel header="Reschedule Policy Description" key="2">
                <Form.Item>
                  <ReactQuill
                    modules={modules}
                    value={content.reschedulePolicyDescription}
                    placeholder="please input ..."
                    onChange={(e) =>
                      getContent(e, "reschedulePolicyDescription")
                    }
                  />
                </Form.Item>
              </Panel>
            ) : null}
            {inputBox === 1 || inputBox === 2 || inputBox === 3 ? (
              <Panel header="Covid Rules Description" key="3">
                <Form.Item>
                  <ReactQuill
                    modules={modules}
                    value={content.covidRulesDescription}
                    placeholder="please input ..."
                    onChange={(e) => getContent(e, "covidRulesDescription")}
                  />
                </Form.Item>
              </Panel>
            ) : null}
            <Panel header="Other Rules Description" key="4">
              <Form.Item>
                <ReactQuill
                  modules={modules}
                  value={content.otherRulesDescription}
                  placeholder="please input ..."
                  onChange={(e) => getContent(e, "otherRulesDescription")}
                />
              </Form.Item>
            </Panel>
          </Collapse>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdatedComponent(CmsFareRules);
