import React, { useState, useCallback, useEffect } from "react";
import { Form, Spin, Select } from "antd";
import APIClient from "../../helpers/ApiClient";
import queryString from "query-string";
const { Option } = Select;
const HolidayAutoComplete = (props) => {
  useEffect(() => {
    let value = props.refName.current.props.value;

    if (!value || value == "" || value == " ") {
      return;
    }
    const hotelSearchParams = queryString.parse(value);
    let name = "";
    let countryId = null;
    let cityId = null;
    if (hotelSearchParams.countryId && hotelSearchParams.countryName) {
      name = hotelSearchParams.countryName;
      countryId = hotelSearchParams.countryId;
      if (hotelSearchParams.cityId && hotelSearchParams.cityName) {
        name = hotelSearchParams.cityName;
        cityId = hotelSearchParams.cityId;
      }
    }

    fetchData(name, cityId, countryId);
  }, props.refName.current);

  const onSelect = () => {
    if (props.focusRef) {
      props.handleOnSubmit(props.focusRef);
    }
  };

  const [details, setDetails] = useState({
    data: [],

    fetching: false,
  });
  const debounceOnChange = useCallback(debounce(fetchData, 800), []);

  const createOptions = (results, cityId, countryId) => {
    let arr = [];

    if (cityId != null) {
      results.cities = results.cities.filter((item) => item.cityId == cityId);
    } else if (countryId != null) {
      results.countries = results.countries.filter(
        (item) => item.countryId == countryId
      );
    }

    results.countries.forEach((result) => {
      let country = `countryName=${result.countryName}&&countryId=${result.countryId}`;
      arr.push({
        value: country,
        name: result.countryName,
      });
    });

    results.cities.forEach((result) => {
      let country = `countryName=${result.countryName}&&countryId=${result.countryId}`;
      let city = `cityName=${result.cityName}&&cityId=${result.cityId}`;
      arr.push({
        value: `${country}&&${city}`,
        name: `${result.cityName}, ${result.countryName}`,
      });
    });

    return arr;
  };

  function fetchData(value, cityId = null, countryId = null) {
    setDetails({ data: [], fetching: true });
    APIClient.get(`${props.api}${value}`)
      .then((res) => {
        if (res.status === 200 && res) {
          setDetails({
            data: createOptions(res.data, cityId, countryId),
            fetching: false,
          });
          return;
        }
        setDetails({ data: [], fetching: false });
      })
      .catch((error) => {
        console.error(error);
        setDetails({ data: [], fetching: false });
      });
  }

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  // console.log(props, details.data);
  return (
    <Form.Item {...props.formItemProps}>
      <Select
        style={{ width: "100%" }}
        showSearch
        ref={props.refName}
        notFoundContent={
          details.fetching ? <Spin size="small" /> : "No Matches found."
        }
        filterOption={false}
        onSearch={debounceOnChange}
        {...props.selectProps}
        onSelect={onSelect}
        defaultActiveFirstOption
      >
        {details.data.map((d) => (
          <Option value={d.value} key={"activityKey" + d.value}>
            {d.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default HolidayAutoComplete;
