
import { Button, Card, Col, Form, Modal, Radio, Row, Layout, Table, Popconfirm, Switch, Select, DatePicker, Checkbox, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import FlightExtranetAirportAutoComplete from "../../common/AutoCompleteSelect/FlightExtranetAirportAutoComplete";
import excel from "../assets/vector-icons/excel.png";
import { useHistory } from "react-router-dom";
import pdf from "../assets/vector-icons/pdf.png";
import search from "../assets/vector-icons/search.png";
import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import "./FlightExtranetStyle.scss"
import moment from "moment";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import { JourneyTypeEnum, FlightCabinClass } from "./FlightExtranetHelper"


const FlightSearchCriteria = () => {
    let history = useHistory();
    const { Option } = Select;
    const { Content } = Layout;
    const handleOnSubmit = (ref) => { };
    const [searchCriteriaForm] = Form.useForm()
    const [isModalVisibal, setIsModalVisibal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [journeyType, setJourneyType] = useState(1)
    const [dataSource, setDataSource] = useState([])
    const [dataFlip, setDataFlip] = useState(false);
    const [currentId, setCurrentId] = useState(0)
    const [isDirectFlight, setIsDirectFlight] = useState(true)
    const { user } = useAuthContext();
    const origin = useRef();
    const destination = useRef();
    const dateFormat = "DD-MM-YYYY"

    useEffect(() => {
        getFlightSearchCriteria()
    }, [])

    const handelJourneyType = (e) => {

        setJourneyType(e)
    }

    const handleAddClick = () => {
        searchCriteriaForm.resetFields();
        setIsModalVisibal(true);
        setIsEdit(false);
    };

    const handleEditClick = (rec) => {
        searchCriteriaForm.setFieldsValue({
            ...rec,
            JourneyDate: moment(rec.JourneyDate, dateFormat) ?? "",
            ReturnDate: rec.ReturnDate ? moment(rec.ReturnDate, dateFormat) : null
        })
        setIsEdit(true)
        setIsModalVisibal(true)
    }

    const tableActions = (rec) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <EditOutlined
                        onClick={() => {
                            handleEditClick(rec);
                            setCurrentId(rec.FlightId);
                        }}
                        style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => deleteFlightSearchCriteria(rec.FlightId)}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </Popconfirm>
                </div>
            </div>
        );
    };

    const handelStatus = (data) => {
        if (data.Status == 1) {
            return (
                <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    defaultChecked
                    onChange={() => changeStatus(data, 0)}
                />
            );
        } else {
            return (
                <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    onChange={() => changeStatus(data, 1)}
                />
            );
        }
    };

    const changeStatus = (info) => {

    }

    const handelStops = (e) => {
        setIsDirectFlight(e.target.checked)
    }

    const handelFormSubmit = (value) => {

        const reqBody = {
            JourneyType: value.JourneyType,
            CabinClass: value.CabinClass,
            Origin: value.Origin ? value.Origin.split('-')[0] : null,
            Destination: value.Destination ? value.Destination.split('-')[0] : null,
            OriginDisplayName: value.Origin,
            DestinationDisplayName: value.Destination,
            JourneyDate: value.JourneyDate.format(dateFormat),
            ReturnDate: value.ReturnDate ? value.ReturnDate.format(dateFormat) : null,
            IsDirectFlight: isDirectFlight,
            Status: 1
        }

        if (isEdit) {
            reqBody["ModifiedBy"] = user?.UserID ?? 1
            updateFlightSearchCriteria(reqBody)
        } else {
            reqBody["CreatedBy"] = user?.UserID ?? 1
            reqBody["ModifiedBy"] = user?.UserID ?? 1
            createFlightSearchCriteria(reqBody)
        }

    }

    //#region API CALLING



    const getFlightSearchCriteria = () => {
        ApiClient.get("flightsearchcriteria/list").then((response) => {
            if (response.statusCode === 200) {
                const data = response.data
                setDataSource(data)
            }
        }).catch((e) => message.error(e.message))
    }

    const createFlightSearchCriteria = (reqBody) => {
        ApiClient.post("create/flightsearchcriteria", reqBody).then(
            (response) => {
                if (response.statusCode === 200) {
                    setIsModalVisibal(false);
                    message.success(response.message);
                    getFlightSearchCriteria();
                    //add FlightId in localStorage
                    const FlightExtranetLocalObj = {
                        FlightId: response.data.FlightId,
                        JourneyType: response.data.JourneyType,
                        // origin: response.data.Origin,
                        // destination: response.data.Destination,
                        OriginDisplayName: response.data.OriginDisplayName,
                        DestinationDisplayName: response.data.DestinationDisplayName,
                        isDirectFlight: response.data.IsDirectFlight,
                        journeyDate: response.data.JourneyDate,
                        returnDate: response?.data?.ReturnDate ?? null
                    }
                    localStorage.setItem('FlightExtranet_SearchCriteria', JSON.stringify(FlightExtranetLocalObj));
                    Modal.confirm({
                        title: 'Route Created successfully!!',
                        content: 'Please click OK to manage Segments for this Created Route',
                        onOk() {
                            history.push('/admin/flight-extranet/segments?from=searchCriteria')
                        },
                    })
                } else {
                    message.error(response.message);
                }
            }
        );
    }

    const updateFlightSearchCriteria = (reqBody) => {
        ApiClient.put(
            "update/flightsearchcriteria/" + currentId,
            {},
            reqBody
        ).then((response) => {
            if (response.statusCode === 200) {
                setIsModalVisibal(false);
                message.success(response.message);
                getFlightSearchCriteria();
                Modal.confirm({
                    title: 'Route Updated successfully!!',
                    content: 'Please click OK to manage Segments for this Updated Route',
                    onOk() {
                        history.push('/admin/flight-extranet/segments')
                    },
                })
            } else {
                message.error("Error Updating");
            }
        }).catch((error) => message.error(error.message));
    }

    const deleteFlightSearchCriteria = (flightId) => {
        ApiClient.delete("delete/flightsearchcriteria/" + flightId).then(
            (res) => {
                if (res.statusCode == 200) {
                    message.success("Deleted  successfully", 3);
                    getFlightSearchCriteria();
                } else message.error(res.message);
            }
        ).catch((er) => message.error(er.message));
    }

    //#endregion API CALLING


    const columns = [
        {
            title: "Actions",
            render: (rec) => {
                return tableActions(rec);
            },
        },
        {
            title: "JourneyType",
            dataIndex: "JourneyType",
            render: (jt) => Object.keys(JourneyTypeEnum).find(key => JourneyTypeEnum[key] === jt)
        },
        {
            title: "Origin",
            dataIndex: "Origin",
            ellipsis: true
        },
        {
            title: "Destination",
            dataIndex: "Destination",
            ellipsis: true
        },

        {
            title: "CabinClass",
            dataIndex: "CabinClass",
            render: (cc) => Object.keys(FlightCabinClass).find(key => FlightCabinClass[key] === cc)
        },

        {
            title: "JourneyDate",
            dataIndex: "JourneyDate",
        },
        {
            title: "ReturnDate",
            dataIndex: "ReturnDate",
        },
        {
            title: "IsDirectFlight",
            dataIndex: "IsDirectFlight",
            render: (item) => item == true ? "True" : "False"
        },
        {
            title: "Status",
            render: (currentItem) => handelStatus(currentItem),
        },
    ]

    return (
        <div>
            <div>
                <Content>
                    <Row>
                        <Col span={24}>
                            <div>  <div className="navigationmain-box" >
                                <a className="navigation-color" style={{ marginLeft: "1.5%", color: "orange" }} href="/admin/toursextranet-packages">Route</a><i class="arrow right"></i>
                                <a className="navigation-color" href="/admin/flight-extranet/segments">Segments</a><i class="arrow right"></i>
                                <a className="navigation-color" href="/admin/flight-extranet/fare-details">Fares</a><i class="arrow right"></i>
                                <a className="navigation-color" href="/admin/flight-extranet/fare-rules">Fare Rules</a><i class="arrow right"></i>
                                <a className="navigation-color" href="/admin/flight-extranet/pnr">Pnr</a><i class="arrow right"></i>

                            </div>
                                <Card bordered={false}>
                                    <div>
                                        <h5>
                                            Flights Search Criteria{" "}
                                            <HelpInfoHelper screenName={"/admin/cartypes"} />
                                        </h5>
                                    </div>
                                    <div className="action-images">
                                        <img src={search} alt="search" />
                                        <img src={excel} alt="excel" />
                                        <img src={pdf} alt="pdf" />
                                        <p className="add-plus-icon" onClick={handleAddClick}>
                                            <PlusCircleFilled />
                                        </p>
                                    </div>
                                    {dataSource.length > 0 ? (
                                        <div>
                                            <Table
                                                className="table-scroll-none"
                                                bordered
                                                dataSource={dataSource}
                                                columns={columns}
                                                pagination={{
                                                    defaultPageSize: 25,
                                                    showSizeChanger: true,
                                                    pageSizeOptions: ["25", "50", "100", "125"],
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <b>No record Found</b>
                                    )}
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </div>
            <Modal
                title={[
                    <div>
                        <h6><strong>{isEdit ? "Add SearchCriteria" : "Update SearchCriteria"}</strong></h6>
                    </div>
                ]}
                onCancel={(e) => setIsModalVisibal(false)}
                onOk={(e) => setIsModalVisibal(false)}
                visible={isModalVisibal}
                footer={[
                    <div>
                        <Button htmlType="submit" type="primary" onClick={() => searchCriteriaForm.submit()}>
                            {isEdit ? "Update" : "Add"}
                        </Button>
                        <Button onClick={() => searchCriteriaForm.resetFields()}>
                            Clear
                        </Button>
                    </div>
                ]}
                width={"1100px"}
            >
                <Form
                    form={searchCriteriaForm}
                    layout="vertical"
                    onFinish={handelFormSubmit}
                >
                    <Row gutter={16} >
                        <Col xs={24} md={8}>
                            <Form.Item
                                label="Journey Type"
                                name="JourneyType"
                                placeholder="Journey Type"
                                rules={[{ required: true }]}
                            >
                                {/* <Radio.Group onChange={(e) => handelJourneyType(e)} defaultValue={1} value={journeyType} >
                                    <Radio value={1}>One Way</Radio>
                                    <Radio value={2}>Round Trip</Radio>
                                    <Radio value={3}>MultiStop</Radio>
                                    <Radio value={4}>AdvanceSearch</Radio>
                                    <Radio value={5}>SpecialReturn</Radio>
                                </Radio.Group> */}
                                <Select
                                    onChange={(e) => handelJourneyType(e)}
                                    value={journeyType}
                                >
                                    <Option value={1}>One Way</Option>
                                    <Option value={2}>Round Trip</Option>
                                    <Option value={3}>MultiStop</Option>
                                    <Option value={4}>AdvanceSearch</Option>
                                    <Option value={5}>SpecialReturn</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label="Cabin Class"
                                placeholder="Select Cabin Class"
                                name="CabinClass"
                                rules={[{ required: true }]}
                            >
                                <Select
                                // onChange={(e) => handelJourneyType(e)}
                                // value={journeyType}
                                >
                                    {/* <Option value={1}>ALL</Option> */}
                                    <Option value={2}>Economy </Option>
                                    <Option value={3}>Premium Economy</Option>
                                    <Option value={4}>Business</Option>
                                    <Option value={5}>Premium Business </Option>
                                    <Option value={6}>First  </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item
                                label="Stops"
                                name="IsDirectFlight"
                            >
                                <Checkbox checked={isDirectFlight} value={isDirectFlight} onChange={handelStops}>Direct Flight</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <FlightExtranetAirportAutoComplete
                                formItemProps={{
                                    name: "Origin",
                                    rules: [
                                        {
                                            required: true,
                                            message: "Origin Is Required",
                                        },
                                    ],
                                }}
                                selectProps={{
                                    placeholder: "Origin",
                                }}
                                refName={origin}
                                focusRef={destination}
                                handleOnSubmit={handleOnSubmit}
                                dataFlip={dataFlip}
                                label="Origin"
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <FlightExtranetAirportAutoComplete
                                formItemProps={{
                                    name: "Destination",
                                    rules: [
                                        {
                                            required: true,
                                            message: "Destination Is Required",
                                        },
                                    ],
                                }}
                                selectProps={{
                                    placeholder: "Destination",
                                }}
                                refName={destination}
                                handleOnSubmit={handleOnSubmit}
                                dataFlip={dataFlip}
                                label="Destination"
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Journey Date"
                                name="JourneyDate"
                                placeholder="JourneyDate"
                                required={[
                                    { required: true, message: "Journey Date Is required" }
                                ]}
                            >
                                <DatePicker
                                    style={{ width: "100%" }}
                                    placeholder={dateFormat}
                                    format={dateFormat}
                                />
                            </Form.Item>
                        </Col>
                        {
                            journeyType == 2 && (

                                <Col xs={24} md={12}>
                                    <Form.Item
                                        label="Return Date"
                                        name="ReturnDate"
                                        placeholder="ReturnDate"
                                        required={[
                                            { required: true, message: "Return Date Is required" }
                                        ]}
                                    >
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            placeholder={dateFormat}
                                            format={dateFormat}
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }

                    </Row>

                </Form>

            </Modal>
        </div >)
}

export default FlightSearchCriteria