import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Layout,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import "rc-datepicker/lib/style.css";
import React, { useEffect, useRef, useState } from "react";
import ApiClient from "../../helpers/ApiClient";
import { OriDesValidate } from "../../helpers/CustomValidators";
import search from "../assets/vector-icons/search.png";
import "./TopCitiesAndDestination.scss";
import BuildPackageAutoComplete from "../../common/AutoCompleteSelect/BuildPackageAutoComplete";

import HotelAutoSelect from "../../common/AutoCompleteSelect/HotelAutoSelect";
import BusesAutoSelect from "../../common/AutoCompleteSelect/BusesAutoSelect";
import HolidayAutoComplete from "../../common/AutoCompleteSelect/HolidayAutoComplete";
import AirportAutoCompleteSelectForCharterFlights from "../../common/AutoCompleteSelect/AirportAutoCompleteSelectForCharterFlights";
import ActivitiesAutoComplete from "../../common/AutoCompleteSelect/ActivitiesAutoComplete";

const { Option } = Select;
const { Panel } = Collapse;

const TopCitiesAndDestination = () => {
  const { Content } = Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [id, setId] = useState(-1);
  const [form] = Form.useForm();
  const [serviceType, setServiceType] = useState(0);
  const [dataFlip, setDataFlip] = useState(false);

  const [countryLists, setCountryList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [ServiceTypeList, setServiceTypeList] = useState([]);

  const [searchCity, setSearchCity] = useState("");

  const origin = useRef();
  const destination = useRef();
  const city = useRef(null);
  const dateBox = useRef(null);
  const tocityBox = useRef(null);

  const handleOnSubmit = (ref) => {};

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const handleAddClick = () => {
    form.resetFields();
    showModal();
    setIsEditMode(false);
  };
  const handleEditClick = (rec) => {
    form.setFieldsValue({
      ...rec,
    });
    setServiceType(rec.ServiceType);

    setIsEditMode(true);

    showModal();
  };

  const getCountriesLists = () => {
    ApiClient.get("admin/country")
      .then((res) => {
        if (res.status == 200) {
          setCountryList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getAllServicesTopCities = () => {
    setDataSource([]);
    ApiClient.get("admin/getAllServicesTopCities").then((res) => {
      if (res.status == 200) {
        setDataSource(res.data);
      }
    });
  };
  const getAllServicesTypes = () => {
    setDataSource([]);
    ApiClient.get("admin/services").then((res) => {
      if (res.status == 200) {
        setServiceTypeList(res.data);
      }
    });
  };
  useEffect(() => {
    getAllServicesTopCities();
    getCountriesLists();
    getAllServicesTypes();
  }, []);

  const SubmitTopCity = (formvalues) => {
    ApiClient.post("admin/createTopCity", formvalues).then((response) => {
      if (response.status === 200) {
        setModalVisible(false);
        message.success(response.message);

        getAllServicesTopCities();
        form.resetFields();
      } else {
        message.error(" Error Creating Top City ");
      }
    });
  };

  const handleSubmit = (formvalues) => {
    formvalues.ServiceName = getServiceType(formvalues.ServiceType);

    if (isEditMode) {
      UpdateTopCity(formvalues);
    } else {
      SubmitTopCity(formvalues);
    }
  };

  const UpdateTopCity = (formvalues) => {
    const updatedFormData = {
      ...formvalues,
    };

    ApiClient.put("admin/updateTopCity/" + id, {}, updatedFormData).then(
      (response) => {
        if (response.status === 200) {
          setModalVisible(false);
          message.success(response.message);

          getAllServicesTopCities();
          form.resetFields();
        } else {
          message.error(" Error Updating Top Cities ");
        }
      }
    );
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const deleteCarType = (id) => {
    ApiClient.delete("admin/deleteTopCity/" + id).then((res) => {
      if (res.status == 200) {
        message.success(res.message, 3);
        getAllServicesTopCities();
      }
    });
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              setId(rec.Id);
              handleEditClick(rec);
            }}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteCarType(rec.Id)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const { Option } = Select;

  const getServiceType = (num) => {
    let type = "";
    ServiceTypeList.map((item) => {
      if (item.ServiceType == num) {
        type = item.ServiceName;
      }
    });
    return type;
  };

  const fightColumns = [
    {
      title: "Service Name",
      dataIndex: "ServiceType",
      key: "ServiceType",
      render: (text, record, index) => {
        return getServiceType(text);
      },
    },

    {
      title: "ServiceType Enum",
      dataIndex: "ServiceType",
    },
    {
      title: "Origin",
      dataIndex: "origin",
    },
    {
      title: "Destination",
      dataIndex: "destination",
    },

    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];

  const hotelColumns = [
    {
      title: "Service Name",
      dataIndex: "ServiceType",
      key: "ServiceType",
      render: (text, record, index) => {
        return getServiceType(text);
      },
    },

    {
      title: "ServiceType Enum",
      dataIndex: "ServiceType",
    },
    {
      title: "Hotel City",
      dataIndex: "hotelCityCode",
    },

    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];

  const busColumns = [
    {
      title: "Service Name",
      dataIndex: "ServiceType",
      key: "ServiceType",
      render: (text, record, index) => {
        return getServiceType(text);
      },
    },

    {
      title: "ServiceType Enum",
      dataIndex: "ServiceType",
    },
    {
      title: "Origin",
      dataIndex: "sourceId",
    },
    {
      title: "Destination",
      dataIndex: "destinationId",
    },

    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];

  const HolidayColumns = [
    {
      title: "Service Name",
      dataIndex: "ServiceType",
      key: "ServiceType",
      render: (text, record, index) => {
        return getServiceType(text);
      },
    },

    {
      title: "ServiceType Enum",
      dataIndex: "ServiceType",
    },
    {
      title: "Holiday City",
      dataIndex: "HolidayCityCode",
    },

    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];
  const ActivitiesColumns = [
    {
      title: "Service Name",
      dataIndex: "ServiceType",
      key: "ServiceType",
      render: (text, record, index) => {
        return getServiceType(text);
      },
    },

    {
      title: "ServiceType Enum",
      dataIndex: "ServiceType",
    },
    {
      title: "Activity City",
      dataIndex: "activitiesCityCode",
    },

    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];
  const BuildpackageColumns = [
    {
      title: "Service Name",
      dataIndex: "ServiceType",
      key: "ServiceType",
      render: (text, record, index) => {
        return getServiceType(text);
      },
    },

    {
      title: "ServiceType Enum",
      dataIndex: "ServiceType",
    },
    {
      title: "Build Package",
      dataIndex: "destinationCityCode",
    },

    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];
  const VisaColumns = [
    {
      title: "Service Name",
      dataIndex: "ServiceType",
      key: "ServiceType",
      render: (text, record, index) => {
        return getServiceType(text);
      },
    },

    {
      title: "ServiceType Enum",
      dataIndex: "ServiceType",
    },
    {
      title: "Country",
      dataIndex: "Country",
    },

    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];

  const RenderColumns = (serviceType) => {
    if (serviceType == 1 || serviceType === 10) {
      return fightColumns;
    } else if (serviceType === 2) {
      return hotelColumns;
    } else if (serviceType === 3) {
      return busColumns;
    } else if (serviceType === 6) {
      return HolidayColumns;
    } else if (serviceType === 4) {
      return ActivitiesColumns;
    } else if (serviceType === 5) {
      return BuildpackageColumns;
    } else if (serviceType === 8) {
      return VisaColumns;
    }
  };

  const handelStatus = (data) => {
    if (data.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };

  const changeStatus = (info) => {
    // let obj = {
    //   Id: info.CarTypeID,
    //   status: Status,
    // };
    ApiClient.put("carextranet/toggleCarTypeStatus/" + info.CarTypeID)
      .then((response) => {
        if (response.status == 200) {
          message.success("Car Type Status updated successfully !");

          getAllServicesTopCities();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>Top Cities </h5>
                        <p></p>
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />

                      <p className="add-deposit-icon" onClick={handleAddClick}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>
                  <div>
                    {dataSource.length > 0 ? (
                      <Collapse
                        defaultActiveKey={["1"]}
                        accordion
                        expandIconPosition={"right"}
                      >
                        {Object.values(
                          dataSource.reduce((result, item) => {
                            if (!result[item.ServiceType]) {
                              result[item.ServiceType] = { ...item };
                            }
                            return result;
                          }, {})
                        ).map((item, idx) => {
                          return (
                            <Panel
                              header={item.ServiceName}
                              key={idx + "panel"}
                            >
                              <Table
                                className="table-scroll-none"
                                bordered
                                dataSource={dataSource.filter(
                                  (i) => i.ServiceType === item.ServiceType
                                )}
                                columns={RenderColumns(item.ServiceType)}
                                pagination={{
                                  defaultPageSize: 25,
                                  showSizeChanger: true,
                                  pageSizeOptions: ["25", "50", "100", "125"],
                                }}
                              />
                            </Panel>
                          );
                        })}
                      </Collapse>
                    ) : null}
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title="Service Type"
        className="promo-modal-header cartypes-value modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <Form
          layout="vertical"
          name="basic"
          form={form}
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label="Service Type"
                rules={[{ required: true }]}
                name="ServiceType"
              >
                <Select
                  onSelect={(e) => {
                    setServiceType(e);
                  }}
                >
                  {ServiceTypeList.length
                    ? ServiceTypeList.map((item, index) => {
                        return (
                          <Option
                            key={"service" + index}
                            value={item.ServiceType}
                          >
                            {item.ServiceName}
                          </Option>
                        );
                      })
                    : ""}
                </Select>
              </Form.Item>
            </Col>

            {(serviceType === 1 || serviceType === 10) && (
              <>
                <Col md={12}>
                  <AirportAutoCompleteSelectForCharterFlights
                    formItemProps={{
                      name: "origin",

                      rules: [
                        {
                          required: true,
                          message: "Please Specify The City",
                        },
                      ],
                    }}
                    selectProps={{
                      placeholder: "Origin",
                    }}
                    refName={origin}
                    focusRef={destination}
                    handleOnSubmit={handleOnSubmit}
                    dataFlip={dataFlip}
                    label="Origin"
                  />
                </Col>
                <Col md={12}>
                  {" "}
                  <AirportAutoCompleteSelectForCharterFlights
                    formItemProps={{
                      name: "destination",

                      rules: [
                        {
                          required: true,
                          message: "Please Specify The Destination City",
                        },
                      ],
                    }}
                    selectProps={{
                      placeholder: "Destination",
                    }}
                    refName={destination}
                    handleOnSubmit={handleOnSubmit}
                    dataFlip={dataFlip}
                    label="Destination"
                  />
                </Col>
              </>
            )}

            {serviceType === 2 && (
              <Col md={24}>
                <HotelAutoSelect
                  formItemProps={{
                    name: "hotelCityCode",
                    rules: [
                      { required: true, message: "Please Specify The City" },
                    ],
                  }}
                  selectProps={{
                    size: "large",
                    placeholder: "Enter City Name",
                  }}
                  api={"hotels-v2/searchhotelcity/"}
                  refName={city}
                  focusRef={dateBox}
                  handleOnSubmit={handleOnSubmit}
                  modifySearch={false}
                />
              </Col>
            )}

            {serviceType === 3 && (
              <>
                <Col md={12}>
                  <BusesAutoSelect
                    formItemProps={{
                      name: "sourceId",
                      rules: [
                        { required: true, message: "Please Specify The City" },
                        {
                          validator: (_, value) => {
                            let destinationId =
                              form.getFieldValue("destinationId");
                            return OriDesValidate(destinationId, value);
                          },
                        },
                      ],
                    }}
                    selectProps={{
                      size: "large",
                      placeholder: "Origin",
                    }}
                    api={"buses/cities/search/"}
                    refName={city}
                    focusRef={tocityBox}
                    handleOnSubmit={handleOnSubmit}
                    modifySearch={false}
                    dataFlip={dataFlip}
                  />
                </Col>
                <Col md={12}>
                  <BusesAutoSelect
                    formItemProps={{
                      name: "destinationId",
                      rules: [
                        { required: true, message: "Please Specify The City" },
                        {
                          validator: (_, value) => {
                            let sourceId = form.getFieldValue("sourceId");
                            return OriDesValidate(sourceId, value);
                          },
                        },
                      ],
                    }}
                    selectProps={{
                      size: "large",
                      placeholder: "Destination",
                    }}
                    api={"buses/cities/search/"}
                    refName={tocityBox}
                    focusRef={city}
                    handleOnSubmit={handleOnSubmit}
                    modifySearch={false}
                    dataFlip={dataFlip}
                  />
                </Col>
              </>
            )}

            {serviceType === 4 && (
              <Col md={24}>
                <ActivitiesAutoComplete
                  formItemProps={{
                    label: "City",
                    name: "activitiesCityCode",
                    rules: [{ required: true }],
                  }}
                  selectProps={{
                    size: "large",
                    placeholder: "Search by Destination",
                  }}
                  api={"activities/searchCity/"}
                  refName={city}
                  focusRef={city}
                  handleOnSubmit={handleOnSubmit}
                />
              </Col>
            )}
            {serviceType === 5 && (
              <Col md={24}>
                <BuildPackageAutoComplete
                  formItemProps={{
                    label: "Search City",
                    name: "destinationCityCode",
                    rules: [{ required: true }],
                  }}
                  selectProps={{
                    size: "large",
                    placeholder: "Search by Destination",
                  }}
                  api={"mapping/buildPackage/cities/"}
                  refName={city}
                  form={form}
                  searchCity={searchCity}
                  modifySearch={false}
                  isCartModify={false}
                />
              </Col>
            )}

            {/* {serviceType === 6 && (
              <Col md={24}>
                <HolidayAutoComplete
                  formItemProps={{
                    label: "City",
                    name: "HolidayCityCode",
                    rules: [{ required: true }],
                  }}
                  selectProps={{
                    size: "large",
                    placeholder: "Search by Destination",
                  }}
                  api={"ToursExtranet/getTourCitiesBykeyword/"}
                  refName={city}
                  focusRef={dateBox}
                  handleOnSubmit={handleOnSubmit}
                  modifySearch={false}
                />
              </Col>
            )} */}

            {serviceType === 8 && (
              <Col md={24}>
                <Form.Item name="Country" className="m-0">
                  <Select showSearch placeholder="Search For A Country">
                    {countryLists.length ? (
                      countryLists.map((item, index) => {
                        return (
                          <Option
                            key={"Search_country" + index}
                            value={item.Country}
                          >
                            {item.Country}
                          </Option>
                        );
                      })
                    ) : (
                      <Option>No Item found</Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            )}

            <Col md={24} xs={24}>
              <Form.Item label="Status" name="Status">
                <Radio.Group
                  rules={[{ required: true, message: "Please choose Status!" }]}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit">
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                form.resetFields();
              }}
              danger
              className="cancel-btn"
            >
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TopCitiesAndDestination;
