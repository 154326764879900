import React, { useState, useCallback, useEffect } from "react";
import { Form, Spin, Select } from "antd";
import APIClient from "../../helpers/ApiClient";
import defaultimage from "../../assets/images/Icons/defaultflagicon.png"

import queryString from "query-string";
import { useLocalStorage } from "../../helpers/useStorage";
const { Option, OptGroup } = Select;
const HotelAutoCompleteSelectByCategory = (props) => {
  // console.log(props,"htprops");
  const [topCities, settopCities] = useState([]);
  useEffect(() => {
    let value = props?.refName?.current?.props?.value;
    const hotelSearchParams = queryString.parse(value);

    if (!value || value == "" || value == " ") {
      return;
    }
    
    fetchData(
      hotelSearchParams.cityName.split(",")[0],
      hotelSearchParams.cityId
    );
  }, props?.refName?.current);

  const onSelect = () => {
    if (props.focusRef) {
      props.handleOnSubmit(props.focusRef);
    }
  };

  const [details, setDetails] = useState({
    data: [],
    fetching: false,
  });

  const debounceOnChange = useCallback(debounce(fetchData, 800), []);

  const createOptions = (results, cityId) => {
    let arr = [];

    if (cityId != null) {
      results = results?.filter((item) => item.cityId == cityId);
    }

    results?.forEach((result) => {
      arr.push({
        cityId: `cityName=${result.cityName}&&cityId=${result.cityId}`,
        cityName: result.cityName,
      });
    });
    // console.log("arr", arr);
    return arr;
  };

  function fetchData(value, cityId = null) {
    setDetails({ data: [], fetching: true });
    if (value || cityId) {
      setDetails({ data: [], fetching: true });
      APIClient.get(`${props.api}${value}`)
        .then((res) => {
          if (res.status === 200 && res) {
            setDetails({
              data: createOptions(res.data, cityId),
              fetching: false,
            });
            return;
          }
          setDetails({ data: [], fetching: false });
        })
        .catch((error) => {
          console.error(error);
          setDetails({ data: [], fetching: false });
        });
    }
  }

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const [HotelRecentSearches, setHotelRecentSearches] = useLocalStorage(
    props.recentKey,
    []
  );

  const recentSearches = (e) => {
    // if (e) {
    //   const optionObj = details.data.find((item) => item.cityId == e);
    //   if (optionObj) {
    //     if (HotelRecentSearches.length > 0) {
    //       let array = [];
    //       array = [...HotelRecentSearches];
    //       if (array.length > 4) {
    //         array.pop();
    //       }
    //       if (optionObj) {
    //         setHotelRecentSearches([
    //           optionObj,
    //           ...array.filter((item) => item.cityId !== e),
    //         ]);
    //       }
    //       return;
    //     }
    //     setHotelRecentSearches([optionObj]);
    //   }
    // }
  };
  const getflag = (code) => {
    // console.log(code, "codef");
    if (code === "yu" || code === "tp") {
      let codeflag = defaultimage;
      return codeflag;
    } else {
      // if (code == "yu") {
      //   console.log("yu is comming");
      // }
      let codeflag = require(`../../assets/country-flag-svg/${code}.svg`).default;
      return codeflag;
    }
  }
  return (
    <Form.Item {...props.formItemProps}>
      <Select
        style={{ width: "100%" }}
        showSearch
        ref={props.refName}
        notFoundContent={
          details.fetching ? <Spin size="small" /> : "No Matches found."
        }
        filterOption={false}
        onSearch={debounceOnChange}
        {...props.selectProps}
        onSelect={(e) => {
          onSelect();
          recentSearches(e);
        }}
      >
        {/* {HotelRecentSearches.length && (
          <OptGroup label="Recent Searches">
            {HotelRecentSearches.map((d) => (
              <Option value={d.cityId} key={"recent" + d.cityId}>
            
                <div className="d-flex align-items-center justify-content-between  ">
                  <div>
                    {" "}
                    <p className="m-0 ">{d.cityName}</p>
                    <p
                      className="m-0 city-column"
                      style={{ fontSize: "11px", color: "#9B9B9B" }}
                    >
                      {d.cityName.split(",")[1]}
                    </p>
                  </div>
                  <div className="city-column">
                    <p className="m-0" style={{ color: "#9B9B9B" }}>
                      CITY
                    </p>
                  </div>
                </div>
              </Option>
            ))}
          </OptGroup>
        )} */}
        {/* {props.topCities.length && (
          <OptGroup label="Top Cities">
            {topCities.map((item, index) => {
              return (
                <Option value={item.hotelCityCode} key={"hotelKey" + item.Id}>
                  <div className="d-flex align-items-center justify-content-between  ">
                    <div>
                      {" "}
                      <p className="m-0">
                        {item.hotelCityCode.split(",")[0].split("=")[1]},{" "}
                        {item.hotelCityCode.split(",")[1].split("&&")[0]}
                      </p>
                      <p
                        className="m-0 city-column"
                        style={{ fontSize: "11px", color: "#9B9B9B" }}
                      >
                        {item.hotelCityCode.split(",")[1].split("&&")[0]}
                      </p>
                    </div>
                    <div className="city-column">
                      <p className="m-0" style={{ color: "#9B9B9B" }}>
                        CITY
                      </p>
                    </div>
                  </div>
                </Option>
              );
            })}
          </OptGroup>
        )} */}
        {details?.data.length && (
          <OptGroup >
            {details?.data.map((d) => (
              <Option value={d.cityId} key={"hotelKey" + d.cityId}>
                {/* {d.cityName} */}
                 <div className="d-flex align-items-center justify-content-between  ">
                 <i class="fa fa-hotel forplace-wor-dropdownflight " ></i>

                  <div className="for-elepsis-work-dropdownhotels ">
                    {" "}
                    <p className="m-0 for-elepsis-work-dropdownhotels">{d.cityName}</p>
                    <p
                      className="m-0 city-column for-elepsis-work-dropdownhotels"
                      style={{ fontSize: "11px", color: "#9B9B9B" }}
                    >
                      {d.cityName.split(",")[1]}
                    </p>
                  </div>
                  {/* <div className="city-column">
                    <p className="m-0" style={{ color: "#9B9B9B" }}>
                      CITY
                      &nbsp;
                      <img
                         src={require(`../../assets/country-flag-svg/${d?.countryCode.toLowerCase()}.svg`).default}
                        width="20"
                        alt={d?.countryCode}></img>
                    </p>
                  </div> */}
                   <div className="city-column">
                        <p className="m-0" style={{ color: "#9B9B9B" }}>
                          <span> {d.countryCode}</span>
                          &nbsp;
                          <img
                            src={d?.countryCode ? getflag(d?.countryCode?.toLowerCase()) : ""}
                             width="20"
                            alt={d?.countryCode}></img>
                        </p>
                      </div>
                </div>
              </Option>
            ))}
          </OptGroup>
        )}
      </Select>
    </Form.Item>
  );
};

export default HotelAutoCompleteSelectByCategory;
