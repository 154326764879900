import React, { useEffect, useState, useRef } from "react"
import { Button, Card, Col, Form, Modal, Radio, Row, Layout, Cascader, Table, Popconfirm, Switch, Select, DatePicker, Checkbox, message, Collapse, TimePicker, Input, InputNumber, } from "antd";
import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import moment from "moment";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import FlightExtranetAirportAutoComplete from "../../common/AutoCompleteSelect/FlightExtranetAirportAutoComplete";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
import search from "../assets/vector-icons/search.png";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import "./FlightExtranetStyle.scss"
import { MinusCircle } from "react-feather";
import {
    OnlyFutureDateValidator,
    PaxAgeValidator,
} from "../../helpers/CustomValidators";
import OverviewAirlineName from "../../common/AutoCompleteSelect/OverviewAirlineName";
import FlightExtranetAirlineName from "../../common/AutoCompleteSelect/FlightExtranetAirlinName";
import { TripIndicator } from "./FlightExtranetHelper"
import queryString from "query-string";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const FlightExtranetSegments = () => {
    const history = useHistory();
    const { Panel } = Collapse;
    const [form] = Form.useForm()
    const handleOnSubmit = (ref) => { };
    const { Option } = Select;
    const { Content } = Layout;
    const [isModalVisibal, setIsModalVisibal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [dataFlip, setDataFlip] = useState(false);
    const [currentId, setCurrentId] = useState(0)
    const [isDirectFlight, setIsDirectFlight] = useState(true)
    const [journeyType, setJourneyType] = useState(1)
    const [isdirectFlight, setisdirectFlight] = useState(true);
    const { user } = useAuthContext();
    // const origin = useRef();
    // const destination = useRef();
    const dateFormat = "DD-MM-YYYY"
    const timeFormat = "HH:mm"
    const [flightSearchCriteria, setFlightSearchCriteria] = useState(null)
    const { TextArea } = Input;
    const [flightIdOnUpdate, setFlightOnUpdate] = useState(0)
    const [tripIndicator, setTripIndicator] = useState(1)

    const currentDate = moment().format(dateFormat)

    // useEffect(() => {
    //     getFlightSegments()
    //     const localStorageResp = localStorage.getItem("FlightExtranet_SearchCriteria")
    //     setFlightSearchCriteria(JSON.parse(localStorageResp))
    // }, [])
    useEffect(() => {
        getFlightSegments();
        const params = queryString.parse(window.location.search);
        if (params.from === "searchCriteria") {

            const localStorageResp = localStorage.getItem('FlightExtranet_SearchCriteria')
            setFlightSearchCriteria(JSON.parse(localStorageResp))
            const jsonObj = JSON.parse(localStorageResp);

            let segmentInfo = {
                DepatureDate: jsonObj?.journeyDate ? moment(jsonObj.journeyDate, dateFormat) : "",
                Origin: jsonObj?.OriginDisplayName ?? ""
            }

            if (jsonObj.isDirectFlight) {
                segmentInfo["Destination"] = jsonObj?.DestinationDisplayName ?? ""
            }


            form.setFieldsValue({

                // flightSegment: [{
                //     DepatureDate: moment(jsonObj.journeyDate, dateFormat) ?? null,
                //     Origin: jsonObj.OriginDisplayName
                // }]
                TripIndicator: tripIndicator ?? 1,
                flightSegment: [segmentInfo]

            });
            if (jsonObj.isDirectFlight)
                setisdirectFlight(false);

            handleAddClick();
        }
    }, [])

    useEffect(() => {

        if (flightSearchCriteria?.JourneyType == 2) {

            if (tripIndicator == 1) {
                let segmentInfo = {
                    DepatureDate: flightSearchCriteria?.journeyDate ? moment(flightSearchCriteria.journeyDate, dateFormat) : "",
                    Origin: flightSearchCriteria?.OriginDisplayName ?? ''
                }

                if (flightSearchCriteria?.isDirectFlight) {
                    segmentInfo["Destination"] = flightSearchCriteria?.DestinationDisplayName ?? ''
                }

                form.setFieldsValue({
                    TripIndicator: tripIndicator ?? 1,
                    flightSegment: [segmentInfo]

                });

            }
            if (tripIndicator == 2) {

                let segmentInfo = {
                    DepatureDate: flightSearchCriteria?.returnDate ? moment(flightSearchCriteria.returnDate, dateFormat) : "",
                    Origin: flightSearchCriteria?.DestinationDisplayName ?? ''
                }

                if (flightSearchCriteria?.isDirectFlight) {
                    segmentInfo["Destination"] = flightSearchCriteria?.OriginDisplayName ?? ""
                }

                form.setFieldsValue({
                    flightSegment: [segmentInfo]

                });
            }

        }

    }, [tripIndicator])

    const handelTripIndicator = (val) => {
        setTripIndicator(val)

    }

    const disabledOriginDate = (currentDate) => {
        return currentDate < moment().startOf("day");
    };

    const handleAddClick = () => {
        const params = queryString.parse(window.location.search);
        if (params.from != "searchCriteria") {
            form.resetFields();
        }
        setIsModalVisibal(true);
        setIsEdit(false);
    };

    const handleEditClick = (rec) => {


        setFlightOnUpdate(rec.FlightId)

        form.setFieldsValue({
            TripIndicator: rec.TripIndicator,
            flightSegment: [{
                operatingAirline: `${rec.AirlineName}&&${rec.AirlineCode}`,
                ArrivalDate: rec.ArrivalDate ? moment(rec.ArrivalDate, dateFormat) : moment(currentDate, dateFormat),
                ArrivalTerminal: rec.ArrivalTerminal,
                ArrivalTime: rec.ArrivalTime ? moment(rec.ArrivalTime, timeFormat) : moment("00:00", timeFormat),
                Duration: rec.Duration ? moment(rec.Duration, timeFormat) : moment("00:00", timeFormat),
                BookingClass: rec.BookingClass,
                CabinBaggage: rec.CabinBaggage,
                CabinBaggageType: rec.CabinBaggageType,
                CheckInBaggage: rec.CheckInBaggage,
                CheckInBaggageType: rec.CheckInBaggageType,
                DepatureDate: rec.DepatureDate ? moment(rec.DepatureDate, dateFormat) : moment(currentDate, dateFormat),
                DepatureTerminal: rec.DepatureTerminal,
                DepatureTime: rec.DepatureTime ? moment(rec.DepatureTime, timeFormat) : moment("00:00", timeFormat),
                Destination: `${rec.DestinationAirportCode}-${rec.DestinationAirportName}-${rec.DestinationCityName}-${rec.DestinationCountryCode}-${rec.DestinationCountryName}`,
                FareType: rec.FareType,
                FlightNumber: rec.FlightNumber,
                FlightRemarks: rec.FlightRemarks,
                LayoverTime: rec.LayoverTime,
                Origin: `${rec.OriginAirportCode}-${rec.OriginAirportName}-${rec.OriginCityName}-${rec.OriginCountryCode}-${rec.OriginCountryName}`,

            }]
        })
        setIsEdit(true)
        setIsModalVisibal(true)
    }

    const tableActions = (rec) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <EditOutlined
                        onClick={() => {
                            handleEditClick(rec);
                            setCurrentId(rec.SegmentId);
                        }}
                        style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => deleteFlightSegments(rec.SegmentId)}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </Popconfirm>
                </div>
            </div>
        );
    };

    const columns = [
        {
            title: "Actions",
            render: (rec) => {
                return tableActions(rec);
            },
        },
        {
            title: "TripIndicator",
            dataIndex: "TripIndicator",
            render: (tp) => Object.keys(TripIndicator).find(key => TripIndicator[key] === tp)
        },
        {
            title: "FlightNumber",
            dataIndex: "FlightNumber",
            ellipsis: true
        },
        {
            title: "AirlineName",
            // dataIndex: "AirlineName",
            render: (item) => `${item.AirlineName} (${item.AirlineCode})`,
            ellipsis: true
        },
        {
            title: "Origin",
            // dataIndex: "Origin",
            render: (item) => `${item.OriginAirportCode}-${item.OriginAirportName}-${item.OriginCityName}-${item.OriginCountryCode}-${item.OriginCountryName}`,
            ellipsis: true
        },
        {
            title: "Destination",
            // dataIndex: "Destination",
            render: (item) => `${item.DestinationAirportCode}-${item.DestinationAirportName}-${item.DestinationCityName}-${item.DestinationCountryCode}-${item.DestinationCountryName}`,
            ellipsis: true
        },

        {
            title: "DepDateTime",
            // dataIndex: "JourneyDate",
            render: (item) => `${item.DepatureDate} ${item.DepatureTime}`,
            ellipsis: true
        },
        {
            title: "ArrDateTime",
            // dataIndex: "ReturnDate",
            render: (item) => `${item.ArrivalDate} ${item.ArrivalTime}`,
            ellipsis: true
        },
        {
            title: "BkgClass",
            dataIndex: "BookingClass",
        },
        {
            title: "CabinBaggage",
            // dataIndex: "CabinBaggage",
            render: (item) => `${item.CabinBaggage} ${item.CabinBaggageTypeName}`,
            ellipsis: true
        },
        {
            title: "CheckInBaggage",
            // dataIndex: "CheckInBaggage",
            render: (item) => `${item.CheckInBaggage} ${item.CheckInBaggageTypeName}`,
            ellipsis: true
        },
        {
            title: "DepTerminal",
            dataIndex: "DepatureTerminal",
        },
        {
            title: "ArrTerminal",
            dataIndex: "ArrivalTerminal",
        },
        {
            title: "Status",
            render: (currentItem) => handelStatus(currentItem),
        },
    ]

    const handleFormSubmit = (value) => {
        let reqBody = {

            TripIndicator: value?.TripIndicator ?? 1,
            FlightId: flightSearchCriteria?.FlightId ?? 0,
            Segments: [],
        }

        value.flightSegment.forEach((item) => {

            const airlineInfo = item.operatingAirline.split('&&')
            const originInfo = item.Origin.split('-')
            const destinationInfo = item.Destination.split('-')

            const segmentsObj = {
                FareType: item.FareType,
                CheckInBaggageType: item.CheckInBaggageType,
                CabinBaggageType: item.CabinBaggageType,
                FlightNumber: item.FlightNumber,
                AirlineName: airlineInfo[0],
                AirlineCode: airlineInfo[1],
                DepatureDate: moment(item.DepatureDate).format(dateFormat),
                DepatureTime: moment(item.DepatureTime).format(timeFormat),
                ArrivalDate: moment(item.ArrivalDate).format(dateFormat),
                ArrivalTime: moment(item.ArrivalTime).format(timeFormat),
                Duration: moment(item.Duration).format(timeFormat),
                DepatureTerminal: item.DepatureTerminal,
                ArrivalTerminal: item.ArrivalTerminal,
                BookingClass: item.BookingClass,
                LayoverTime: item.LayoverTime,
                CheckInBaggage: item.CheckInBaggage,
                CabinBaggage: item.CabinBaggage,
                FlightRemarks: item.FlightRemarks,
                OriginAirportCode: originInfo[0],
                OriginAirportName: originInfo[1],
                OriginCityCode: originInfo[0],
                OriginCityName: originInfo[2],
                OriginCountryCode: originInfo[3],
                OriginCountryName: originInfo[4],
                DestinationAirportCode: destinationInfo[0],
                DestinationAirportName: destinationInfo[1],
                DestinationCityCode: destinationInfo[0],
                DestinationCityName: destinationInfo[2],
                DestinationCountryCode: destinationInfo[3],
                DestinationCountryName: destinationInfo[4],
                Status: 1
            }
            if (isEdit) {
                segmentsObj["ModifiedBy"] = user?.UserID ?? 1
            }
            else {
                segmentsObj["CreatedBy"] = user?.UserID ?? 1
                segmentsObj["ModifiedBy"] = user?.UserID ?? 1
            }

            reqBody.Segments.push(segmentsObj)
        })

        if (isEdit) {
            const updateObj = {
                TripIndicator: value?.TripIndicator ?? 1,
                FlightId: flightIdOnUpdate,
                ...reqBody.Segments[0]
            }

            updateFlightSegments(updateObj)

        } else createFlightSegments(reqBody)
        // isEdit ? updateFlightSegments(reqBody) : createFlightSegments(reqBody)

    }

    const handelStatus = (data) => {
        if (data.Status == 1) {
            return (
                <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    defaultChecked
                    onChange={() => changeStatus(data, 0)}
                />
            );
        } else {
            return (
                <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    onChange={() => changeStatus(data, 1)}
                />
            );
        }
    };

    const changeStatus = (info) => {

    }

    //#region API CALLING

    const getFlightSegments = () => {
        ApiClient.get("flight-segments/list").then((response) => {
            if (response.statusCode === 200) {
                const data = response.data
                setDataSource(data)
            }
        }).catch((e) => message.error(e.message))
    }

    const createFlightSegments = (reqBody) => {
        ApiClient.post("save/flight-segments", reqBody).then(
            (response) => {
                if (response.statusCode === 200) {
                    setIsModalVisibal(false);
                    message.success(response.message);
                    getFlightSegments();
                    //add FlightId in localStorage
                    Modal.confirm({
                        title: 'Flight Segments Created successfully!!',
                        content: 'Please click OK to manage Fares for this Created Segments',
                        onOk() {
                            history.push('/admin/flight-extranet/fare-details')
                        },
                    })
                    localStorage.setItem('ExFlightId', JSON.stringify(response.data.FlightId));
                } else {
                    message.error(response.message);
                }
            }
        );
    }

    const updateFlightSegments = (reqBody) => {
        ApiClient.put(
            "update/flight-segment/" + currentId,
            {},
            reqBody
        ).then((response) => {
            if (response.statusCode === 200) {
                setIsModalVisibal(false);
                message.success(response.message);
                getFlightSegments();
                Modal.confirm({
                    title: 'Flight Segments Updated successfully!!',
                    content: 'Please click OK to manage Fares for this Updated Segments',
                    onOk() {
                        history.push('/admin/flight-extranet/fare-details')
                    },
                })
            } else {
                message.error("Error Updating");
            }
        }).catch((error) => message.error(error.message));
    }

    const deleteFlightSegments = (flightId) => {
        ApiClient.delete("delete/flight-segment/" + flightId).then(
            (res) => {
                if (res.statusCode == 200) {
                    message.success("Deleted  successfully", 3);
                    getFlightSegments();
                } else message.error(res.message);
            }
        ).catch((er) => message.error(er.message));
    }

    const initialValuesFlight = [
        {
            Origin: "",
            Destination: "",
            DepatureDate: "",
            DepatureTime: "",
            ArivalDate: "",
            ArrivalTime: "",
            FlightNumber: "",
        },
    ];

    //#endregion API CALLING

    const FlightDetails = ({ isminus, field, add, index, remove }) => {

        const origin = useRef();
        const destination = useRef();
        const dateBox = useRef();
        const [showDate, setShowDate] = useState(false);
        const handleOnSubmit = (ref) => {
            ref.current.focus();
            if (ref === dateBox) {
                setShowDate(true);
            }
        };

        return (
            <div className="mx-2 mb-1">
                <div className="flight-title">
                    <p className=" mb-0">Flight-{index + 1} Segments</p>
                </div>
                <Row gutter={16}>
                    <Col md={8} xs={24}>

                        <FlightExtranetAirportAutoComplete
                            formItemProps={{
                                label: "Origin",
                                name: [field.name, "Origin"],
                                fieldKey: [field.fieldKey, "Origin"],

                                rules: [
                                    {
                                        required: true,
                                    },
                                ],
                            }}
                            selectProps={{
                                placeholder: "Origin",
                            }}
                            refName={origin}
                            focusRef={destination}
                            handleOnSubmit={handleOnSubmit}
                            dataFlip={""}
                            label="Origin"
                        />

                    </Col>
                    <Col md={8} xs={24}>
                        <FlightExtranetAirportAutoComplete
                            formItemProps={{
                                name: [field.name, "Destination"],
                                fieldKey: [field.fieldKey, "Destination"],
                                rules: [
                                    {
                                        required: true,
                                        message: "Destination Is Required",
                                    },
                                ],
                            }}
                            selectProps={{
                                placeholder: "Destination",
                            }}
                            refName={destination}
                            focusRef={dateBox}
                            handleOnSubmit={handleOnSubmit}
                            dataFlip={dataFlip}
                            label="Destination"
                        />
                    </Col>
                    <Col md={8} xs={24}>
                        <FlightExtranetAirlineName
                            formItemProps={{
                                label: "Operating Airline",
                                name: [field.name, "operatingAirline"],

                                rules: [
                                    {
                                        required: true,
                                        message: "Please Specify The Operating Airline",
                                    },
                                ],
                            }}
                            selectProps={{
                                placeholder: "Select Operating Airline",
                                allowClear: true,
                            }}
                            api={"extranet/airlinenames/search/"}
                        />
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item
                            label="Departure Date"
                            {...field}
                            name={[field.name, "DepatureDate"]}
                            fieldKey={[field.fieldKey, "DepatureDate"]}
                            rules={[
                                { required: true },
                                { validator: OnlyFutureDateValidator },
                            ]}
                        >
                            <DatePicker
                                style={{ width: "100%" }}
                                className="train-search-btn"
                                format={dateFormat}
                                disabledDate={disabledOriginDate}
                                //  onChange={() => setTogglePax(true)}
                                ref={dateBox}
                            // open={showDate}
                            // onOpenChange={() => {
                            //   setShowDate((prev) => !prev);
                            // }}
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-class" md={4} xs={24}>
                        <Form.Item
                            label="Departure Time"
                            {...field}
                            name={[field.name, "DepatureTime"]}
                            fieldKey={[field.fieldKey, "DepatureTime"]}
                            rules={[{ required: true }]}
                        >
                            <TimePicker format={timeFormat} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item
                            label="Arrival Date"
                            {...field}
                            name={[field.name, "ArrivalDate"]}
                            fieldKey={[field.fieldKey, "ArrivalDate"]}
                            rules={[
                                { required: true },
                                { validator: OnlyFutureDateValidator },
                            ]}
                        >
                            {/* <DatePicker
                      placeholder="Arrival Date"
                      style={{ width: "100%" }}
                    /> */}
                            <DatePicker
                                style={{ width: "100%" }}
                                className="train-search-btn"
                                format={dateFormat}
                                disabledDate={disabledOriginDate}
                            //  onChange={() => setTogglePax(true)}
                            // ref={dateBox}
                            // open={showDate}
                            // onOpenChange={() => {
                            //   setShowDate((prev) => !prev);
                            // }}
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-class" md={4} xs={24}>
                        <Form.Item
                            label="Arrival Time "
                            {...field}
                            name={[field.name, "ArrivalTime"]}
                            fieldKey={[field.fieldKey, "ArrivalTime"]}
                            rules={[{ required: true }]}
                        >
                            <TimePicker format={timeFormat} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-class" md={4} xs={24}>
                        <Form.Item
                            label="Duration"
                            {...field}
                            name={[field.name, "Duration"]}
                            fieldKey={[field.fieldKey, "Duration"]}
                            rules={[{ required: true }]}
                        >
                            <TimePicker format={timeFormat} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>


                    <Col md={4} xs={24}>
                        <Form.Item
                            label="Flight Number"
                            name={[field.name, "FlightNumber"]}
                            rules={[{ required: true, message: "Add Flight Number" }]}
                        >
                            <Input placeholder=" Insert FlightNumber" />
                        </Form.Item>
                    </Col>
                    <Col md={4} xs={24}>
                        <Form.Item
                            label="Fare Type"
                            name={[field.name, "FareType"]}
                        >
                            <Select
                                placeholder="Select FareType"
                            >
                                <Option value={1}>Refundable</Option>
                                <Option value={2}>NonRefundable</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col md={8} xs={24}>
                        <Form.Item
                            label="Journey Duration"
                            name={[field.name, "journeyDuration"]}
                            rules={[{ required: true, message: "Add Journey Duration" }]}
                        >
                            <TimePicker style={{ width: "100%" }} placeholder="Select Time" />
                        </Form.Item>
                    </Col> */}

                    <Col md={4} xs={24}>
                        <Form.Item
                            label="Departure Terminal"
                            name={[field.name, "DepatureTerminal"]}
                            rules={[{ required: true, message: "Add Terminal" }]}
                        >
                            <Input placeholder=" Insert Departure Terminal" />
                        </Form.Item>
                    </Col>

                    <Col md={4} xs={24}>
                        <Form.Item
                            label="Arrival Terminal"
                            name={[field.name, "ArrivalTerminal"]}
                            rules={[{ required: true, message: "Add Terminal" }]}
                        >
                            <Input placeholder=" Insert Arrival Terminal" />
                        </Form.Item>
                    </Col>

                    <Col md={4} xs={24}>
                        <Form.Item
                            label="Booking Class"
                            name={[field.name, "BookingClass"]}
                        // rules={[{ required: true, message: "Add Booking Class" }]}
                        >
                            <Input placeholder="Insert Booking Class" />
                        </Form.Item>
                    </Col>
                    <Col md={4} xs={24}>
                        <Form.Item
                            label="Layover Time"
                            name={[field.name, "LayoverTime"]}
                        // rules={[{ required: true, message: "Add Booking Class" }]}
                        >
                            <InputNumber min={0} addonAfter="Min" />
                        </Form.Item>
                    </Col>

                    {/* <Col md={4} xs={24}>
                        <Form.Item
                            label="Cabin Baggage"
                            name={[field.name, "CabinBaggage"]}
                            rules={[{ required: true, message: "Add Cabin Baggage" }]}
                        >
                            <InputNumber min={1} max={10} placeholder="Cabin Baggage" />
                        </Form.Item>
                    </Col> */}

                    {/* <Col md={4} xs={24}>
                        <Form.Item
                            label="Baggage Type"
                            name={[field.name, "CabinBaggageType"]}
                            rules={[{ required: true, message: "Baggage Type Required" }]}
                        >
                            <Select
                            >
                                <Option value={1}>KG</Option>
                                <Option value={2}>PIECES </Option>
                            </Select>
                        </Form.Item>
                    </Col> */}

                    <Col md={6} xs={24}>
                        <Form.Item
                            label="CabinBaggage"
                            name={[field.name, "CabinBaggage"]}
                            rules={[
                                {
                                    required: true,
                                    message: "Add Check In Baggage",
                                },
                                {
                                    minLength: 3,
                                    maxLength: 1,
                                    pattern: "^[0-9]{1,3}$",
                                    message: "Must be Number",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Add Cabin Baggage"
                                addonBefore={
                                    <Form.Item

                                        name={[field.name, "CabinBaggageType"]}
                                        rules={[{ required: true, message: "Baggage Type Required" }]}
                                        noStyle
                                    >
                                        <Select
                                            placeholder="Type"

                                        >
                                            <Option value={1}>KG</Option>
                                            <Option value={2}>PIECES </Option>
                                        </Select>
                                    </Form.Item>
                                }

                            />
                        </Form.Item>
                    </Col>

                    {/* <Col md={4} xs={24}>
                        <Form.Item
                            label="Check In Baggage"
                            name={[field.name, "CheckInBaggage"]}
                            rules={[{ required: true, message: "Add Check In Baggage" }]}
                        >
                            <InputNumber min={1} max={25} placeholder="Check In Baggage" />
                        </Form.Item>
                    </Col>
                    <Col md={4} xs={24}>
                        <Form.Item
                            label="Baggage Type"
                            name={[field.name, "CheckInBaggageType"]}
                            rules={[{ required: true, message: "Baggage Type Required" }]}
                        >
                            <Select
                            >
                                <Option value={1}>KG</Option>
                                <Option value={2}>PIECES </Option>
                            </Select>
                        </Form.Item>
                    </Col> */}
                    <Col md={6} xs={24}>
                        <Form.Item
                            label="CheckInBaggage"
                            name={[field.name, "CheckInBaggage"]}
                            rules={[
                                {
                                    required: true,
                                    message: "Add Check In Baggage",
                                },
                                {
                                    minLength: 3,
                                    maxLength: 1,
                                    pattern: "^[0-9]{1,3}$",
                                    message: "Must be Number",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Add CheckIn Baggage"
                                addonBefore={
                                    <Form.Item

                                        name={[field.name, "CheckInBaggageType"]}
                                        rules={[{ required: true, message: "Baggage Type Required" }]}
                                        noStyle
                                    >
                                        <Select
                                            placeholder="Type"

                                        >
                                            <Option value={1}>KG</Option>
                                            <Option value={2}>PIECES </Option>
                                        </Select>
                                    </Form.Item>
                                }

                            />
                        </Form.Item>
                    </Col>

                    <Col>
                        <Form.Item
                            label="Flight Remarks"
                            name={[field.name, "FlightRemarks"]}

                        >
                            <TextArea placeholder="Flight Remarks" />
                        </Form.Item>
                    </Col>



                    <Col span={1} style={{ alignSelf: "center" }}>
                        {isminus > 1 ? (
                            <MinusCircle
                                className="dynamic-delete-button"
                                onClick={() => remove(field.name)}
                            />
                        ) : null}
                    </Col>
                </Row>
            </div>
        );
    };


    return (
        <div>
            <div>
                <Content>
                    <Row>
                        <Col span={24}>
                            <div>
                                <div className="navigationmain-box" >
                                    <a className="navigation-color" href="/admin/flight-extranet/search-criteria">Route</a><i class="arrow right"></i>
                                    <a className="navigation-color" style={{ color: "orange" }} >Segments</a><i class="arrow right"></i>
                                    <a className="navigation-color" href="/admin/flight-extranet/fare-details">Fares</a><i class="arrow right"></i>
                                    <a className="navigation-color" href="/admin/flight-extranet/fare-rules">Fare Rules</a><i class="arrow right"></i>
                                    <a className="navigation-color" href="/admin/flight-extranet/pnr">Pnr</a><i class="arrow right"></i>

                                </div>
                                <Card bordered={false}>
                                    <div>
                                        <h5>
                                            Flights Segments{" "}
                                            <HelpInfoHelper screenName={"/admin/cartypes"} />
                                        </h5>
                                    </div>
                                    <div className="action-images">
                                        <img src={search} alt="search" />
                                        <img src={excel} alt="excel" />
                                        <img src={pdf} alt="pdf" />
                                        <p className="add-plus-icon" onClick={handleAddClick}>
                                            <PlusCircleFilled />
                                        </p>
                                    </div>
                                    {dataSource.length > 0 ? (
                                        <Collapse
                                            defaultActiveKey={["1"]}
                                            accordion
                                            expandIconPosition={"right"}
                                        >
                                            {dataSource.map((item, index) => {
                                                return (
                                                    <Panel header={`${item.FlightType}: ${item.Origin} To ${item.Destination}`} key={index + "panel"}>
                                                        {item.Segments.length > 0 ? (<div>
                                                            <Table
                                                                className="table-scroll-none"
                                                                bordered
                                                                dataSource={item.Segments}
                                                                columns={columns}
                                                                pagination={{
                                                                    defaultPageSize: 25,
                                                                    showSizeChanger: true,
                                                                    pageSizeOptions: ["25", "50", "100", "125"],
                                                                }}
                                                            />
                                                        </div>) : (<b>No record Found</b>)}
                                                    </Panel>
                                                )
                                            })}
                                        </Collapse>

                                    ) : (
                                        <b>No record Found</b>
                                    )}
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </div>
            <Modal
                title={[
                    <div>
                        <h6><strong>{isEdit ? "Update Segments" : "Add Segments"}</strong></h6>
                    </div>
                ]}
                onCancel={(e) => setIsModalVisibal(false)}
                onOk={(e) => setIsModalVisibal(false)}
                visible={isModalVisibal}
                footer={[
                    <div>
                        <Button htmlType="submit" type="primary" onClick={() => form.submit()}>
                            {isEdit ? "Update" : "Add"}
                        </Button>
                        <Button onClick={() => form.resetFields()}>
                            Clear
                        </Button>
                    </div>
                ]}
                width={"1100px"}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleFormSubmit}
                    initialValues={{
                        Status: 0,
                        flightSegment: [...initialValuesFlight]
                    }}
                >
                    {flightSearchCriteria?.JourneyType == 2 && (
                        <Row gutter={(12, 12)} className="mb-2">
                            <Col md={8} xs={24}>
                                <Form.Item
                                    label="Trip Indicator"
                                    name="TripIndicator"
                                >
                                    <Select
                                        onChange={(e) => handelTripIndicator(e)}
                                        value={tripIndicator}
                                    >
                                        <Option value={1}>Onward</Option>
                                        <Option value={2}>Return</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    <Row gutter={[16]}>

                        <div className="border ">
                            <h6 className="background-primary-orange text-center text-white px-2 py-1">
                                Flight Segments
                            </h6>
                            <Row gutter={[16]} className="px-2">

                                <Col className="gutter-row" md={24} xs={24}>
                                    <Form.List name="flightSegment">
                                        {(fields, { add, remove }) => {
                                            return (
                                                <>
                                                    {fields.map((field, index) => {
                                                        return (
                                                            <FlightDetails
                                                                isminus={fields.length}
                                                                field={field}
                                                                index={index}
                                                                key={field.key}
                                                                remove={remove}
                                                            />
                                                        );
                                                    })}
                                                    {fields.length < 4 && isdirectFlight && !isEdit ? (
                                                        <div className="text-right p-2">
                                                            <Button type="primary" onClick={() => add()}>
                                                                Add More
                                                            </Button>
                                                        </div>
                                                    ) : null}
                                                </>
                                            );
                                        }}
                                    </Form.List>
                                </Col>
                            </Row>
                        </div>
                    </Row>
                </Form>

            </Modal>
        </div >
    )
}

export default FlightExtranetSegments