import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Switch,
  Table,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  default as APIClient,
  default as ApiClient,
} from "../../../src/helpers/ApiClient";
import "../../admin/Adminstyles.scss";
import search from "../assets/vector-icons/search.png";

const Configurations = (props) => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [ConfigurationList, setConfigurations] = useState([]);

  const [isEditMode, setIsEditMode] = useState(false);

  const [currentId, setCurrentId] = useState();
  const [showSearchBox, setShowSearchBox] = useState(false);

  useEffect(() => {
    getConfigurations();
  }, []);

  const getConfigurations = () => {
    APIClient.get("admin/getAllConfiguration")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              CreatedDate: moment(item.createDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.modifiedDate).format("DD-MM-YYYY"),
            };
          });

          setConfigurations(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /*  Add Configuration */
  const SubmitConfiguration = (formvalues) => {
    APIClient.post("admin/addConfiguration", formvalues)
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.status == 200) {
          setModalVisible(false);
          message.success(response.message, 3);
          getConfigurations();
        } else if (response.status == 400) {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleModal = () => {
    setIsEditMode(false);
    form.resetFields();
    setModalVisible(true);
  };

  const deleteRole = (id) => {
    APIClient.delete("admin/role/" + id)
      .then((res) => {
        if (res.status == 200) {
          message.success("Configuration Deleted  successfully", 3);
          getConfigurations();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const tableActions = (currentRecord) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord)}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure want to delete?"
            onConfirm={() => deleteRole(currentRecord.ConfigurationId)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const editCurrentItem = (rec) => {
    setCurrentId(rec.ConfigurationId);
    form.setFieldsValue({
      ...rec,
    });

    setIsEditMode(true);
    setModalVisible(true);
  };

  const UpdateConfiguration = (formvalues) => {
    ApiClient.put("admin/role/" + currentId, {}, formvalues).then((res) => {
      if (res.status == 200) {
        getConfigurations();
        setModalVisible(false);
        message.success("Configuration Updated successfully", 3);
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    lastIndex++;
    return lastIndex;
  };

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
    },
    {
      title: "Configuration Name",
      dataIndex: "ConfigurationName",
    },

    {
      title: "Status",
      render: (currentData) => handelStatus(currentData),
    },

    {
      title: "Actions",
      render: (rec) => tableActions(rec),
    },
  ];

  const handelStatusChange = () => {};
  const handelStatus = (data) => {
    return (
      <Switch
        checkedChildren="Active"
        unCheckedChildren="Inactive"
        checked={data.Status == 1 ? true : false}
        onChange={() => handelStatusChange(data)}
      />
    );
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = ConfigurationList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setConfigurations(searchResults);
    } else {
      getConfigurations();
      //  setConfigurations(ConfigurationList);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const SubitForm = (formvalues) => {
    if (isEditMode) {
      UpdateConfiguration(formvalues);
    } else {
      SubmitConfiguration(formvalues);
    }
  };

  return (
    <div>
      <div className="manage-markup-section">
        <div className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-SubmitConfiguration-heading">
                    <div className="rows-count">
                      <div>
                        <h5>View Configurations </h5>
                        <p>{ConfigurationList.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData(ConfigurationList)}
                      />
                      <p className="m-0 add-deposit-icon" onClick={handleModal}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>
                  <div>
                    <Table
                      scroll={{ x: true }}
                      bordered
                      rowKey={uniqueKey}
                      dataSource={ConfigurationList}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong> {isEditMode ? "Update" : "Add"} Configurations </strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button htmlType="submit" type="primary" onClick={form.submit}>
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button type="danger" onClick={() => form.resetFields()}>
              Clear
            </Button>
          </div>,
        ]}
      >
        <Form layout={"vertical"} form={form} onFinish={SubitForm}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Configuration Name"
                name="ConfigurationName"
                rules={[
                  {
                    required: true,
                    message: "Please enter Configuration Name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Status"
                name="Status"
                rules={[{ required: true, message: "Please Select Status" }]}
              >
                <Radio.Group>
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>In Active</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default Configurations;
