import React from "react";
import { Route, Redirect, Link } from "react-router-dom";
import { checkRouteAccess } from "./ProtectedMenu";

const AdminRoute = ({
  component: Component,
  accessList,
  accessToken,
  ...rest
}) => {
  
  return (
    <Route
      {...rest}
      render={(props) => {
        // {console.log(accessList,"list123")}
        return accessToken &&
          checkRouteAccess(props.location.pathname, accessList) ? (
          // <Component {...props} />,


          Number(accessList.indexOf(props.location.pathname)) === 29 ? (<Redirect
            to={{
              pathname: "/admin",
              state: { from: props.location },
            }}
          />) : (<Component
            {...props}
          />)
        ) : (
          Number(accessList.indexOf(props.location.pathname)) === 29 ? (<Redirect
            to={{
              pathname: "/admin",
              state: { from: props.location },
            }}
          />) : (
            <Redirect
              to={{
                pathname: "/unauthorized",
                state: { from: props.location },
              }}
            />
          )
        );
      }}
    />
  );
};

export default AdminRoute;
