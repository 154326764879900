import React, { useEffect, useState } from "react";
import { Button, Card, Col, Collapse, DatePicker, Input, InputNumber, Radio, Row, Form, Select, Spin, Switch, Table, TimePicker, message, Modal, Popconfirm, Upload, Popover } from "antd";

import ReactQuill from "react-quill";
import moment from "moment";
import APIClient from "../../helpers/ApiClient";
import modules from "../../helpers/reactQuillModules";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import UpdatedComponent from "../ExportExcel";

import "./Packages.scss";

import { CloseOutlined, DeleteOutlined, EditOutlined, PlusCircleFilled, PlusOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";

const ActivitiesImageUpload = ({ category, fileList, setFileList, formName, limit, limitSize, deleteBanner }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );

  function beforeUpload(file) {
    const checkJpgOrPng = file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    const checkFileSize = file.size < 1126400;
    if (!checkFileSize) {
      message.error(" Image must be smaller than 1Mb!");
    }

    return checkJpgOrPng && checkFileSize;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    if (info.file.status === "uploading") {
      setFileList(info.fileList);
      setIsUploading(false);
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
      setFileList(info.fileList);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setFileList(info.fileList);
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item name={formName}>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          onRemove={(val) => deleteBanner(val.uid)}
          beforeUpload={beforeUpload}
        >
          {limit ? (fileList.length >= limitSize ? null : uploadLogoButton) : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

const { Panel } = Collapse;
const { TextArea } = Input;
const dateFormat = "DD-MM-YYYY";

const ActivitiesPackages = (props) => {
  const [form] = Form.useForm();
  const [id, setId] = useState(-1);
  const [isEditMode, setIsEditMode] = useState(false);

  const [showSearchBox, setShowSearchBox] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activitiesList, setActivitiesList] = useState([]);
  const [activitiesCities, setActivitiesCities] = useState([]);
  const [activitiesCategories, setActivitiesCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [sliderImage, setSliderImage] = useState([]);
  const [thumbnailImage, setThumbnailImage] = useState([]);
  const [excelList, setExcelList] = useState([]);
  const initialState = {
    tourDescription: "",
    faqDetails: "",
    cancellationPolicyName: "",
    cancellationPolicyDescription: "",
    tourExclusion: "",
    tourInclusion: "",
    importantInformation: "",
    itenararyDescription: "",
    usefulInformation: "",
    termsAndConditions: "",
    questions: "",
    whatsInThisTour: "",
    childCancellationPolicyName: "",
    childCancellationPolicyDescription: "",
  };
  const [content, setContent] = useState(initialState);

  const getContent = (content, type) => {
    setContent((prev) => ({
      ...prev,
      [type]: content,
    }));
  };

  const updateActivitiesForm = (currentFormData) => {
    setId(currentFormData.tourId);
    let obj = {
      tourDescription: currentFormData.tourDescription ?? "",
      faqDetails: currentFormData.faqDetails ?? "",
      cancellationPolicyName: currentFormData.cancellationPolicyName ?? "",
      cancellationPolicyDescription: currentFormData.cancellationPolicyDescription ?? "",
      tourExclusion: currentFormData.tourExclusion ?? "",
      tourInclusion: currentFormData.tourInclusion ?? "",
      importantInformation: currentFormData.importantInformation ?? "",
      itenararyDescription: currentFormData.itenararyDescription ?? "",
      usefulInformation: currentFormData.usefulInformation ?? "",
      termsAndConditions: currentFormData.termsAndConditions ?? "",
      questions: currentFormData.questions ?? "",

      childCancellationPolicyName: currentFormData.childCancellationPolicyName ?? "",
      childCancellationPolicyDescription: currentFormData.childCancellationPolicyDescription ?? "",
    };

    // thumbNail images ************
    let thubImgArr = [];
    thubImgArr.push({
      uid: currentFormData.tourId,
      name: currentFormData.tourName,
      status: "done",
      url: `${BASE}${currentFormData.imagePath.substring(1)}`,
      response: {
        status: 200,
        data: {
          filepaths: [currentFormData.imagePath],
        },
      },
    });

    setThumbnailImage(thubImgArr);
    let array = [];
    if (currentFormData.tourImages.length > 0) {
      for (let i = 0; i < currentFormData.tourImages.length; i++) {
        array.push({
          uid: currentFormData.tourImages[i].tourImageId,
          name: currentFormData.tourImages[i].imageCaptionName,
          status: "done",
          url: `${BASE}${currentFormData.tourImages[i].imagePath.substring(1)}`,
          response: {
            status: 200,
            data: {
              filepaths: [currentFormData.tourImages[i].imagePath],
            },
          },
        });
      }

      setSliderImage(array);
    }

    setContent(obj);
    form.setFieldsValue({
      ...currentFormData,
      Starrating: currentFormData.rating,
      Continent: currentFormData.continent,
      showOnHomePage: currentFormData.showOnHomePage,
      validToDate: moment(currentFormData.validToDate),
      validFromDate: moment(currentFormData.validFromDate),
      meal: currentFormData.meal ?? "",
      openingHour: moment(currentFormData.openingHour, "HH:mm:ss"),
      closingHour: moment(currentFormData.closingHour, "HH:mm:ss"),
      isSlot: currentFormData?.isSlot == true ? 1 : 2,
      recommended: currentFormData?.recommended == true ? 1 : 2,
      isPrivate: currentFormData?.isPrivate == true ? 1 : 2,
      onlyChild: currentFormData?.onlyChild == true ? 1 : 2,
      countryName: currentFormData.countryId,
      cityName: currentFormData.cityId,
      cityTourType: currentFormData.categoryId,
    });
    setModalVisible(true);
    setIsEditMode(true);
  };

  const onFinish = (val) => {
    let thumbnailImgPath = "";

    if (thumbnailImage.length > 0) {
      thumbnailImgPath = thumbnailImage[0].response.data.filepaths[0];
    }

    let sliderImageArray = [];

    if (sliderImage.length > 0) {
      for (let i = 0; i < sliderImage.length; i++) {
        if (sliderImage[i].response.status === 200) {
          sliderImageArray.push({
            tourImageId: sliderImage[i].uid,
            imagePath: sliderImage[i].response.data.filepaths[0],
            imageCaptionName: sliderImage[i].name,
            isFrontImage: 1,
            isBannerImage: 1,
            isBannerRotateImage: 0,
          });
        }
      }
    }

    let obj = {
      CountryID: val.countryName,
      cityId: val.cityName,
      validFromDate: val.validFromDate.format("YYYY-MM-DD"),
      validToDate: val.validToDate.format("YYYY-MM-DD"),
      tourName: val.tourName ?? "",
      reviewCount: 0,
      rating: val.Starrating,
      openingHour: val.openingHour.format("HH:mm:ss"),
      closingHour: val.closingHour.format("HH:mm:ss"),
      imagePath: thumbnailImgPath,
      categoryId: val.cityTourType,
      tourShortDescription: val.tourShortDescription ?? "",
      cancellationPolicyName: content.cancellationPolicyName ?? "",
      isSlot: val.isSlot ?? 0,
      recommended: val.recommended ?? 0,
      isPrivate: val.isPrivate ?? 0,
      isTransport: val.isTransport ?? 0,
      isLiveGuid: 0,
      amount: val.amount,
      discount: val.discount,
      rewardPoints: 0,
      departurePoint: val.departurePoint,
      reportingTime: val.reportingTime,
      tourLanguage: val.tourLanguage,
      tourDescription: content.tourDescription,
      tourInclusion: content.tourInclusion,
      tourExclusion: content.tourExclusion,
      importantInformation: content.importantInformation,
      itenararyDescription: content.itenararyDescription,
      usefulInformation: content.usefulInformation,
      faqDetails: content.faqDetails,
      childFromAge: val.childFromAge,
      childToAge: val.childToAge,
      infantFromAge: val.infantFromAge,
      infantToAge: val.infantToAge,
      infantCount: val.infantCount ?? 0,
      onlyChild: val.onlyChild ?? 0,
      startTime: val.openingHour,
      status: 1,
      termsAndConditions: content.termsAndConditions,
      whatsInThisTour: content.whatsInThisTour,
      cancellationPolicyDescription: content.cancellationPolicyDescription,
      childCancellationPolicyName: content.childCancellationPolicyName,
      childCancellationPolicyDescription: content.childCancellationPolicyDescription,
      contractId: 0,
      latitude: "72N",
      longitude: "23S",
      meal: val.meal,
      questions: content.questions,
      tourImages: sliderImageArray,
      createdBy: 1,
      createdDate: new Date(),
      modifiedBy: 1,
      modifiedDate: new Date(),
      deviceOS: 0,
      deviceOSVersion: "",
      deviceToken: "",
      applicationType: 0,
      firBaseToken: "",
      continent: val.Continent,
      showOnHomePage: val.showOnHomePage,
    };

    isEditMode ? updateActivities(obj, id) : saveActivities(obj);
  };

  /***** End of Date And Time Pick ****/
  const tableActions = (currentData) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined onClick={() => updateActivitiesForm(currentData)} style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }} />
          {/* <InfoCircleOutlined onClick={openUserInfo} style={{ color: '#bd0c21', fontSize: '20px', textAlign: 'center', marginLeft: "25px" }} /> */}
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm title="Are you sure to delete?" onConfirm={() => deleteCategory(currentData.tourId)}>
            <DeleteOutlined style={{ color: "red", fontSize: "20px", textAlign: "center" }} />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const { Option } = Select;

  const changeStatus = (info, status) => {
    const id = info.tourId;
    delete info.SNo;
    delete info.tourId;
    let obj = {
      ...info,
      status: status,
    };

    updateActivities(obj, id);
  };

  const handelStatus = (data) => {
    if (data.status == 1) {
      return <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked onChange={() => changeStatus(data, 0)} />;
    } else {
      return <Switch checkedChildren="Active" unCheckedChildren="Inactive" onChange={() => changeStatus(data, 1)} />;
    }
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      key: "sno",
    },
    {
      title: "Tour Name",
      dataIndex: "tourName",
    },
    {
      title: "Country",
      dataIndex: "countryName",
      sorter: (a, b) => a.countryName.localeCompare(b.countryName),
    },
    {
      title: "City",
      dataIndex: "cityName",
      sorter: (a, b) => a.cityName.localeCompare(b.cityName),
    },
    {
      title: "Category",
      dataIndex: "cityTourType",
      sorter: (a, b) => a.cityTourType.localeCompare(b.cityTourType),
    },
    {
      title: "ValidFromDate",
      dataIndex: "validFromDate",
      sorter: (a, b) => a.validFromDate.localeCompare(b.validFromDate),
    },
    {
      title: "ValidToDate",
      dataIndex: "validToDate",
      sorter: (a, b) => a.validToDate.localeCompare(b.validToDate),
    },
    {
      title: "StarRating",
      dataIndex: "rating",
      sorter: (a, b) => a.rating.localeCompare(b.rating),
    },
    {
      title: "Duration",
      dataIndex: "duration",
      sorter: (a, b) => a.duration.localeCompare(b.duration),
    },
    {
      title: "StartTime",
      dataIndex: "startTime",
      sorter: (a, b) => a.startTime.localeCompare(b.startTime),
      render: (startTime) => (startTime ? moment(startTime).format("HH:mm:ss") : null),
    },
    {
      title: "Language",
      dataIndex: "tourLanguage",
    },
    // {
    //   title: "ChildAge",
    //   // dataIndex: "childAge",
    //   // sorter: (a, b) => a.childAge.localeCompare(b.childAge),
    //   render: (currentData) => getchildAge(currentData),
    // },
    // {
    //   title: "InfantAge",
    //   // dataIndex: "infantAge",
    //   // sorter: (a, b) => a.infantAge.localeCompare(b.infantAge),
    //   render: (currentData) => getinfantAge(currentData),
    // },
    {
      title: "InfantCount",
      dataIndex: "infantCount",
      sorter: (a, b) => a.infantCount.localeCompare(b.infantCount),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount.localeCompare(b.amount),
    },
    {
      title: "Discount",
      dataIndex: "discount",
      sorter: (a, b) => a.discount.localeCompare(b.discount),
    },
    {
      title: "Status",
      // dataIndex: 'status',
      key: "category",
      // render: (text, order) =>
      //   <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked={false} />
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (currentData) => tableActions(currentData),
    },
  ];

  const DeleteThumbnailImage = (id) => {
    console.log(id);
    const filteredThumbnail = thumbnailImage.filter((item) => item.uid != id);
    setThumbnailImage(filteredThumbnail);
  };

  const DeleteBannerImage = (id) => {
    APIClient.delete("extranet/activitiesImages/" + id)
      .then((response) => {
        if (response.status == 200) {
          getAllActivities();
          message.success("Deleted Successsfully");
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  const add = () => {
    form.resetFields();
    if (isEditMode) {
      setIsEditMode(false);
      setContent(initialState);
      setSliderImage([]);
      setThumbnailImage([]);
    }
    setModalVisible(true);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = activitiesList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item).toLowerCase().includes(searchVal.toLowerCase());
        }
      });
      setActivitiesList(searchResults);
    } else {
      getAllActivities();
    }
  };

  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  /* API CALLS */

  const getAllActivities = () => {
    APIClient.get(`extranet/getAllActivities`)
      .then((resp, index) => {
        if (resp.status == 200) {
          let data = resp.data.map((activities, index) => {
            return {
              SNo: index + 1,
              ...activities,
            };
          });

          setActivitiesList(data);
          setLoading(false);
          defineExcelData(data);
        } else if (resp.status == 400) {
          message.error("Failed To Activities Data", 3);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        Country: item.countryName,
        City: item.cityName,
        Category: item.cityTourType,
        ValidFromDate: item.validFromDate,
        ValidToDate: item.validToDate,
        StarRating: item.rating,
        Duration: item.duration,
        StartTime: item.startTime ? moment(item.startTime).format("HH:mm:ss") : null,

        Language: item.tourLanguage,
        Amount: item.amount,
        Discount: item.discount,
        Status: item.status === 1 ? "Active" : "Inactive",
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const getActivitiesCities = () => {
    APIClient.get("extranet/activitiesCities")
      .then((resp) => {
        if (resp.status == 200) {
          setActivitiesCities(resp.data);
        } else if (resp.status == 400) {
          message.error("Failed To Fetch Cities", 3);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCountries = () => {
    APIClient.get("admin/country")
      .then((resp) => {
        if (resp.status == 200) {
          let data = resp.data.filter((item) => item.activitiesCountryId != null);

          setCountries(data);
        } else if (resp.status == 400) {
          message.error("Failed To Fetch Countries", 3);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getActivitiesCategory = () => {
    APIClient.get("extranet/categories")
      .then((resp) => {
        if (resp.status == 200) {
          setActivitiesCategories(resp.data);
        } else if (resp.status == 400) {
          message.error("Failed To Fetch Cities", 3);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveActivities = (data) => {
    APIClient.post("extranet/createActivity", data)
      .then((resp) => {
        if (resp.status == 200) {
          message.success("Activities Saved SuccessFully", 3);
          setModalVisible(false);
          getAllActivities();
          form.resetFields();
        } else if (resp.status == 400) {
          message.error("Failed to Save Activities", 3);
          setModalVisible(false);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateActivities = (formMapData, tourId) => {
    formMapData.tourImages = formMapData.tourImages.map((item) => {
      return {
        ...item,
        tourId: tourId,
      };
    });

    APIClient.put(`extranet/updateActivity/${tourId}`, {}, formMapData)
      .then((response) => {
        if (response.status == 200) {
          message.success("Activities Updated SuccessFully", 3);
          setModalVisible(false);
          getAllActivities();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteCategory = (tourId) => {
    APIClient.delete("extranet/deleteActivity/" + tourId)
      .then((res) => {
        if (res.status == 200) {
          message.success("Category Deleted  successfully", 3);
          getAllActivities();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getCountries();
    getActivitiesCities();
    getActivitiesCategory();
    getAllActivities();
  }, []);

  const validateMessages = {
    required: "",
  };

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <div className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    {/* <div className="table-heading-count"> */}
                    <div className="rows-count">
                      <div>
                        <h5>
                          Activities <HelpInfoHelper screenName={"/admin/activities"} />
                        </h5>
                        <p>{activitiesList.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && <Input placeholder="Search" onChange={(e) => searchTableData(e)} suffix={closeSearchInput} style={{ padding: "0px 12px" }} />}
                      &nbsp;&nbsp;
                      <img src={search} alt="search" onClick={(e) => searchData()} />
                      <img src={excel} alt="excel" onClick={(e) => props.exportExcel(excelList, "tour")} />
                      {/*
                        <img src={pdf} alt="pdf" /> */}
                      {/* <img src={word} alt="word" /> */}
                      {/*  <img src={exchange} alt="exchange" /> */}
                      <p className="add-deposit-icon" onClick={add}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                    {/* </div> */}
                  </div>

                  <div>
                    {/* onChange={onChange} expandedRowRender= {rowExpand}*/}

                    {loading ? (
                      <Spin size="large" tip="Loading..." style={{ width: "100%" }} />
                    ) : activitiesList.length > 0 ? (
                      <Table
                        className="table-scroll-none table-data"
                        bordered
                        dataSource={activitiesList}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    ) : (
                      <b>No Results Found!</b>
                    )}
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        title="Add Activities"
        className="promo-modal-header admin-bc-level modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button key="add" type="primary" htmlType="submit" className="edit-btn" onClick={() => form.submit()}>
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button type="primary" danger className="cancel-btn" onClick={() => form.resetFields()}>
              Clear
            </Button>
          </div>,
        ]}
        width={"1100px"}
      >
        <Form
          validateMessages={validateMessages}
          name="basic"
          layout="vertical"
          form={form}
          initialValues={{
            childFromAge: 3,
            childToAge: 10,
            infantFromAge: 0,
            infantToAge: 2,
            infantCount: 0,
            tourLanguage: "English",
            discount: 0,
            isSlot: 2,
            showOnHomePage: 2,
            Recommended: 2,
            isTransport: 2,
            onlyChild: 2,
            isPrivate: 2,
          }}
          onFinish={onFinish}
        >
          <Row gutter={8} className="form-row">
            <Col md={4} sm={12} xs={24}>
              <Form.Item
                label="Continent"
                // rules={[
                //   { required: true, message: "Please select your country" },
                // ]}
                name="Continent"
              >
                <Select showSearch placeholder="Select Continent" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  <Option value={1}>Asia</Option>
                  <Option value={2}>Africa</Option>
                  <Option value={7}>Antarctica</Option>
                  <Option value={3}>Europe</Option>
                  <Option value={4}>North America</Option>
                  <Option value={6}>Oceania</Option>
                  <Option value={5}>South America</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={4} sm={12} xs={24}>
              <Form.Item label="Country" rules={[{ required: true }]} name="countryName">
                <Select showSearch placeholder="Select Country" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {countries.map((item, index) => (
                    <Option key={index} value={item.activitiesCountryId}>
                      {`${item.Country}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={4} sm={12} xs={24}>
              <Form.Item label="City" rules={[{ required: true }]} name="cityName">
                <Select showSearch placeholder="Select City" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {activitiesCities.map((item, index) => (
                    <Option key={index} value={item.activitiesCityId}>
                      {`${item.cityName}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col md={4} sm={12} xs={24}>
              <Form.Item label="Category" rules={[{ required: true }]} name="cityTourType">
                <Select showSearch placeholder="Select Category" filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {activitiesCategories.map((item, index) => (
                    <Option key={index} value={item.categoryId}>
                      {`${item.categoryName}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} sm={12} xs={24}>
              <Form.Item name="tourName" label="Tour Name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item name="tourShortDescription" label="Tour Short Description" rules={[{ required: true }]}>
                <TextArea />
              </Form.Item>
            </Col>
            <Col md={4} sm={12} xs={24}>
              <Form.Item label="From Date" name="validFromDate" rules={[{ required: true }]}>
                <DatePicker format={dateFormat} disabledDate={disabledOriginDate} style={{ width: "100%" }} placeholder="From Date" maxDate={new Date()} />
              </Form.Item>
            </Col>
            <Col md={4} sm={12} xs={24}>
              <Form.Item label="To Date" name="validToDate" rules={[{ required: true }]}>
                <DatePicker format={dateFormat} disabledDate={disabledOriginDate} style={{ width: "100%" }} placeholder="To Date" maxDate={new Date()} />
              </Form.Item>
            </Col>

            <Col md={4} sm={12} xs={24}>
              <Form.Item label="Opening hours" name="openingHour" rules={[{ required: true }]}>
                <TimePicker defaultOpenValue={moment("00:00:00", "HH:mm:ss")} style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col md={4} sm={12} xs={24}>
              <Form.Item label="Closing hours" name="closingHour" rules={[{ required: true }]}>
                <TimePicker defaultOpenValue={moment("00:00:00", "HH:mm:ss")} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col md={4} sm={12} xs={24}>
              <Form.Item name="departurePoint" label="Departure Point" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col md={4} sm={12} xs={24}>
              <Form.Item name="reportingTime" label="Reporting Point" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>

            <Col md={4} sm={12} xs={24}>
              <Form.Item label="Star Rating" name="Starrating" rules={[{ required: true }]}>
                <Select>
                  <Select.Option value={1}>1 Star</Select.Option>
                  <Select.Option value={2}>2 Star</Select.Option>
                  <Select.Option value={3}>3 Star</Select.Option>
                  <Select.Option value={4}>4 Star</Select.Option>
                  <Select.Option value={5}>5 Star</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={4} sm={12} xs={24} className="infant-number">
              <Form.Item name="amount" label="Amount" rules={[{ required: true }]}>
                <InputNumber />
              </Form.Item>
            </Col>
            <Col md={4} sm={12} xs={24}>
              <Form.Item label="Language" name="tourLanguage">
                <Select placeholder="Select Language">
                  <Option value="English">English </Option>
                  <Option value="Hindi">Hindi</Option>
                  <Option value="French">French</Option>
                  <Option value="Arabic">Arabic</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={4} sm={12} xs={24}>
              <Form.Item label="Child Age Range">
                <Form.Item
                  name="childFromAge"
                  noStyle
                  // rules={[{ required: true }]}
                >
                  <InputNumber min={0} style={{ width: "50%" }} />
                </Form.Item>
                <Form.Item
                  name="childToAge"
                  noStyle
                  // rules={[{ required: true }]}
                >
                  <InputNumber min={0} style={{ width: "50%" }} />
                </Form.Item>
              </Form.Item>
            </Col>
            <Col md={4} sm={12} xs={24}>
              <Form.Item label="Infant Age Range">
                <Form.Item
                  name="infantFromAge"
                  noStyle
                  // rules={[{ required: true }]}
                >
                  <InputNumber min={0} style={{ width: "50%" }} />
                </Form.Item>
                <Form.Item
                  name="infantToAge"
                  noStyle
                  // rules={[{ required: true }]}
                >
                  <InputNumber min={0} style={{ width: "50%" }} />
                </Form.Item>
              </Form.Item>
            </Col>

            <Col md={4} sm={12} xs={24} className="infant-number">
              <Form.Item name="infantCount" label="Infant Count">
                <InputNumber min={0} />
              </Form.Item>
            </Col>

            <Col md={4} sm={12} xs={24} className="infant-number">
              <Form.Item name="discount" label="Discount">
                <InputNumber min={0} />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item name="meal" label="Meal">
                <TextArea rows={1} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col md={4} sm={12} xs={24}>
              <Form.Item label="Slot" name="isSlot">
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={4} sm={12} xs={24}>
              <Form.Item label="Show on Home Page" name="showOnHomePage">
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={4} sm={12} xs={24}>
              <Form.Item label="recommended" name="Recommended">
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={4} sm={12} xs={24}>
              <Form.Item label="Transport" name="isTransport">
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col md={4} sm={12} xs={24}>
              <Form.Item label="Only Child" name="onlyChild">
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={4} sm={12} xs={24}>
              <Form.Item label="Private" name="isPrivate">
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Collapse accordion>
            <Panel header={"Upload Images"} key="15">
              <Row gutter={8}>
                <Col md={6} sm={24} xs={24}>
                  <p>Thumbnail Image</p>

                  <ActivitiesImageUpload
                    category="thumbnailimage"
                    fileList={thumbnailImage}
                    setFileList={setThumbnailImage}
                    formName="thumbnailImage"
                    limit={true}
                    limitSize={1}
                    deleteBanner={DeleteThumbnailImage}
                  />
                </Col>
                <Col md={18} sm={24} xs={24}>
                  <p>Slider Images</p>

                  <div className="upload-logo">
                    <ActivitiesImageUpload
                      category="sliderimage"
                      fileList={sliderImage}
                      setFileList={setSliderImage}
                      formName="sliderImage"
                      limit={true}
                      limitSize={3}
                      deleteBanner={DeleteBannerImage}
                    />
                  </div>
                </Col>
              </Row>
            </Panel>

            <Panel header="Tour Long Description" key="1">
              <Form.Item>
                <ReactQuill modules={modules} value={content.tourDescription} placeholder="please input ..." onChange={(e) => getContent(e, "tourDescription")} />
              </Form.Item>
            </Panel>
            <Panel header="FAQ Details" key="2">
              <Form.Item>
                <ReactQuill modules={modules} value={content.faqDetails} placeholder="please input ..." onChange={(e) => getContent(e, "faqDetails")} />
              </Form.Item>
            </Panel>
            <Panel header="Cancellation Policy" key="3">
              <p>Name</p>
              <Form.Item>
                <Input style={{ maxWidth: "350px" }} value={content.cancellationPolicyName} onChange={(e) => getContent(e.target.value, "cancellationPolicyName")} />
              </Form.Item>
              <p>Description</p>

              <Form.Item>
                <ReactQuill modules={modules} value={content.cancellationPolicyDescription} placeholder="please input ..." onChange={(e) => getContent(e, "cancellationPolicyDescription")} />
              </Form.Item>
            </Panel>

            <Panel header="Child Cancellation Policy" key="13">
              <p>Name</p>
              <Form.Item>
                <Input style={{ maxWidth: "350px" }} value={content.childCancellationPolicyName} onChange={(e) => getContent(e.target.value, "childCancellationPolicyName")} />
              </Form.Item>
              <p>Description</p>
              <Form.Item>
                <ReactQuill modules={modules} value={content.childCancellationPolicyDescription} placeholder="please input ..." onChange={(e) => getContent(e, "childCancellationPolicyDescription")} />
              </Form.Item>
            </Panel>

            <Panel header="Exclusion" key="5">
              <Form.Item>
                <ReactQuill modules={modules} value={content.tourExclusion} placeholder="please input ..." onChange={(e) => getContent(e, "tourExclusion")} />
              </Form.Item>
            </Panel>
            <Panel header="Inclusion" key="6">
              <Form.Item>
                <ReactQuill modules={modules} value={content.tourInclusion} placeholder="please input ..." onChange={(e) => getContent(e, "tourInclusion")} />
              </Form.Item>
            </Panel>

            <Panel header="Important Information" key="7">
              <Form.Item>
                <ReactQuill modules={modules} value={content.importantInformation} placeholder="please input ..." onChange={(e) => getContent(e, "importantInformation")} />
              </Form.Item>
            </Panel>
            <Panel header="Itenarary Description" key="8">
              <Form.Item>
                <ReactQuill modules={modules} value={content.itenararyDescription} placeholder="please input ..." onChange={(e) => getContent(e, "itenararyDescription")} />
              </Form.Item>
            </Panel>
            <Panel header="Useful Information" key="9">
              <Form.Item>
                <ReactQuill modules={modules} value={content.usefulInformation} placeholder="please input ..." onChange={(e) => getContent(e, "usefulInformation")} />
              </Form.Item>
            </Panel>
            <Panel header="Terms and Conditions" key="10">
              <Form.Item>
                <ReactQuill modules={modules} value={content.termsAndConditions} placeholder="please input ..." onChange={(e) => getContent(e, "termsAndConditions")} />
              </Form.Item>
            </Panel>

            <Panel header="Common Questions" key="11">
              <Form.Item>
                <ReactQuill modules={modules} value={content.questions} placeholder="please input ..." onChange={(e) => getContent(e, "questions")} />
              </Form.Item>
            </Panel>
          </Collapse>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdatedComponent(ActivitiesPackages);
