import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSytContext } from "../../../common/providers/SytProvider";
import Banner from "../../../components/banner/Banner";
import Hotels from "../../../components/Hotels/Hotels";
import hot from "../../../assets/images/hotels/ht-1.png";
// import Tree from "./tree";
// import WaterSurface from "./WaterSurface";
import ApiClient from "../../../helpers/ApiClient";
import queryString from "query-string";
import Subscribe from "../../../components/subscribe/Subscribe";
import moment from "moment";
import Reviews from "../../../common/Reviews/Reviews";
import { AppConstants } from "../../../helpers/constants";
import "../HotelSearch/HotelSearch.scss";
import OffersSlider from "../../../common/LandingPageOffers/LandingPageOffers";
import HomeCarousel from "../../../components/HomeCarousel/HomeCarousel";
const HotelSearch = (props) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const { hotelBanners } = useSytContext();
  const [cityHotelData, setCityHotelData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [deal, setDeal] = useState([])
  const [recentSearchResults, setRecentSearchResults] = useState([]);

  const { promoDataSource } = useSytContext();

  const getCityHotelList = () => {
    ApiClient.get("admin/cityHotels")
      .then((res) => {
        // {console.log(res,"ht-res")}
        if (res.status === 200) {
          let data = res.data.filter(
            (item) => item.Status === 0 && item.Servicetype === 2
          );
          let result = data.reduce(function (obj, key) {
            obj[`${key.CityName}, ${key.CountryName}`] =
              obj[`${key.CityName}, ${key.CountryName}`] || [];
            obj[`${key.CityName}, ${key.CountryName}`].push(key);
            return obj;
          }, {});

          setCityHotelData(result);
        }
      })
      .catch((e) => console.log("api error", e));
  };

  useEffect(() => {
    getCityHotelList();
    if (promoDataSource.length) {
      let data = promoDataSource.filter((item) => item.ServiceType === 2);
      setDataSource(data);
    }
  }, [promoDataSource]);

  // const [background, setBackground] = useState("radial-gradient(circle, #004cff, #0097ff)");

  // let timeout;

  // function handleMouseMove(event) {
  //   clearTimeout(timeout);
  //   timeout = setTimeout(() => {
  //     const x = event.clientX; // get the X coordinate of the cursor
  //     const y = event.clientY; // get the Y coordinate of the cursor

  //     const width = window.innerWidth; // get the width of the viewport
  //     const height = window.innerHeight; // get the height of the viewport

  //     const centerX = width / 2; // calculate the X coordinate of the center of the viewport
  //     const centerY = height / 2; // calculate the Y coordinate of the center of the viewport

  //     const distance = Math.sqrt((x - centerX) ** 2 + (y - centerY) ** 2); // calculate the distance between cursor and center

  //     const maxDistance = Math.sqrt(centerX ** 2 + centerY ** 2); // calculate the maximum distance from the center to the corner

  //     const gradientRadius = (distance / maxDistance) * 100; // calculate the radius of the gradient circle

  //     const newBackground = `radial-gradient(circle at ${x}px ${y}px, #004cff,  #0097ff ${gradientRadius}%, transparent ${gradientRadius + 5}%, transparent 100%)`;

  //     setBackground(newBackground); // update the background color
  //   }, 10); // Adjust debounce time as needed for smoother transition
  // }
  useEffect(() => {
    getRecentSearches();
    getDeal();
  }, []);
  const getRecentSearches = () => {
    let data = sessionStorage.getItem("hotelRecentSearchesResults");

    if (data.length) {
      setRecentSearchResults(JSON.parse(data));
    }
  };
  const [docTitle, setDocTitle] = useState("Book Best Luxury Hotels Online");

  useEffect(() => {
    const handleBlur = () => {
      document.title = "Come Back, We Got Best Hotels";
    };

    const handleFocus = () => {
      document.title = docTitle + " - " + AppConstants.DOMAIN_NAME;
    };

    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    };
  }, [docTitle]);



  const getHotelUrl = (url) => {
    if (url) {
      let params = queryString.parse(url);
      params.checkInDate = moment().add(1, "days").format("YYYY-MM-DD");
      params.checkOutDate = moment().add(2, "days").format("YYYY-MM-DD");
      params = queryString.stringify(params);
      return `/hotels/listing?${params}`;
    } else {
      return "";
    }
  };
  const getDeal = () => {
    ApiClient.get("admin/dealsimages")
      .then((res) => {
        if (res.status === 200) {
          // console.log(resServiceType, "ddd");

          let data = res.data.filter((item) => item.ServiceType === 2);




          setDeal(data);

        }
      })
      .catch((e) => {
        setDeal([]);
      });
  };

  return (
    <div className="hotels_search_container">
      <Helmet>
        <title>
          {docTitle} - {AppConstants.DOMAIN_NAME}
          {/* Book Best Luxury and Budget Hotels Online - {AppConstants.DOMAIN_NAME} */}
        </title>
        <meta
          name="description"
          content="Best hotel deals are available at AnjMal. Book budget hotels, luxury hotels or resorts at cost effective rates."
        />
      </Helmet>
      <section className="hotel_banner">
        <div className="head" >
          <Banner banner={hotelBanners} />
          {/* <Banner banner={2} /> */}

        </div>

        <div className="heading_text ">
          <h2>
            {/* Hotels and Accommodation. Book safely with the option of
            cancellation. */}
            Find Next Best Place To Stay
          </h2>
        </div>
        <div className="hotel_search">
          <Hotels hotelSearch={props.hotelContext} />
        </div>
      </section>

      {recentSearchResults.length ? (
        <section className="home-best-24 recentSearchesRow">
          {/* <div className="container">
            <Row gutter={16} className="justify-content-end align-items-center">
              <Col md={4} xs={24}>
                <p className="m-0 text-right">Recent Searches: </p>
              </Col>
              {recentSearchResults.map((item, i) => {
                item.checkInDate = moment(item.checkInDate, "YYYY-MM-DD")
                  .add(2, "days")
                  .format("YYYY-MM-DD");
                item.checkOutDate = moment(item.checkOutDate, "YYYY-MM-DD")
                  .add(2, "days")
                  .format("YYYY-MM-DD");
                let newString = queryString.stringify(item);

                return (
                  <Col md={4} xs={24}>
                    <div key={i + "recentresults"}>
                      <Link to={`hotels/listing?${newString}`}>
                        <div
                          style={{
                            boxShadow: "0 1px 5px black",
                            borderRadius: "6px",
                            textAlign: "center",
                            overflow: "hidden",
                          }}
                        >
                          <div className="ducarosuel">
                            <div className="city-due ">
                              <i
                                className="fa fa-building iconsDiv pr-2"
                                aria-hidden="true"
                              ></i>

                              {item.hotelCityCode.split("=")[1].split(",")[0]}
                            </div>
                            <div className="time-due">
                              {moment(item.checkInDate).format("DD, MMM YYYY")}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div> */}
        </section>
      ) : (
        ""
      )}

      {/* <section className="bus-support-24">
        <div className="container">
          <Row>
            <Col md={12} xs={24}>
              <Link
                exact={true}
                to="/hotelOfflineRequest"
                target={"_blank"}
                className="hovered-link"
              >
                <div className="offers-bal-cart hovered-text ">
                  <img
                    className="deals-coloums-24"
                    src={
                      require("../../../assets/images/one-icon-ts.png").default
                    }
                    alt="icon"
                  />
                  &nbsp;
                  <div className="price-deals-24 animate__flash ">
                    <p className="font-weight-bold ">
                      FOR INDEPENDENT TRAVELERS (FIT)
                    </p>
                    <p>
                      Please submit your details here to get discounted fares .
                    </p>
                  </div>
                </div>{" "}
              </Link>
            </Col>
            <Col md={12} xs={24} className="support-24">
              <Link
                exact={true}
                to="/hotelGroupEnquiry"
                target={"_blank"}
                className="hovered-link"
              >
                <div className="offers-bal-cart hovered-text  ">
                  <img
                    className="deals-coloums-24"
                    src={
                      require("../../../assets/images/one-icon-ts1.png").default
                    }
                    alt="icon hotel"
                  />
                  &nbsp;
                  <div className="price-deals-24 animate__flash">
                    <p className="font-weight-bold">
                      GROUP INCLUSIVE TOURS (GIT)
                    </p>
                    <p>Please submit your group pax details here.</p>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      </section> */}
      <section className="home-best-24">
        <div className="container">
          <Row>
            <Col md={12} xs={24}>
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../../assets/images/one-icon-ts.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>BEST PRICE GUARANTEED</p>
                  <p>
                    Trying our level best to fetch lower price than others, try
                    us once!!
                  </p>
                </div>
              </div>
            </Col>
            <Col md={12} xs={24} className="support-24">
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../../assets/images/one-icon-ts1.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>24×7 SUPPORT</p>
                  <p>
                    We’re always here for you – reach us 24 hours a day, 7 days
                    a week.{" "}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <div className="container">
        {/*--start----end--*/}

        <Row className="heading-hotel">
          <Col md={24} xs={24}>
            <h3 style={{ color: "#bd0c21" }}>Only here! New, larger accommodation base</h3>
            <p className="mb-0">
              Hotels, apartments, bungalows &amp; homestays - just the way you
              like it
            </p>
          </Col>
        </Row>

        {Object.keys(cityHotelData).length > 0
          ? Object.keys(cityHotelData).map((cityHotel) => (
            <>
              <Row gutter={[6, 6]}>
                <Col md={24} xs={24}>
                  <p style={{background:"radial-gradient(#bd0c2157, transparent)", borderRadius:"10px"}} className="text-center font-weight-bold mb-0">
                    {cityHotel}
                  </p>
                </Col>
                {cityHotelData[cityHotel].map((item) => (
                  <Col md={6} xs={24}>
                    <Link
                      to={getHotelUrl(item.SearchURL)}
                      className="d-block"
                    >
                      <img
                        className="cart-shopping-img"
                        src={
                          item.HotelImage1
                            ? `${BASE}${item.HotelImage1.substring(1)}`
                            : require("../../../assets/images/hotels/hotel-fil-2.jpg")
                              .default
                        }
                        alt="Hotel Image"
                      />
                    </Link>
                    <p className="text-center font-weight-bold mb-0">
                      {item.Title}
                    </p>
                  </Col>
                ))}
              </Row>{" "}
            </>
          ))
          : null}
      </div>

      <section className="hotels_advantages">
        <div className="hotels-advantages-wrapper">
          <div className="hotels-adv-box left-box">
            <img

              src={
                require("../../../assets/images/hotels/save_up_to.png").default
              }
              alt="hotel icon"
            />
            <div className="hotels-adv-box-wrapper">
              <p style={{ color: "#35459c" }}> SAVE UP TO 30%!</p>
              <small>
                We've made deals with over 1.3 M hotels so you can book up to
                30% cheaper
              </small>
            </div>
          </div>
          <div className="hotels-adv-box right-box">
            <img
              src={require("../../../assets/images/hotels/no_tf.png").default}
              alt="hotel icon"
            />
            <div className="hotels-adv-box-wrapper">
              <p style={{ color: "#35459c" }}> NO HIDDEN CHARGES</p>
              <small>
                We tell you exactly what you'll pay from the very beginning -
                transparent reservation policies mean no nasty surprises
              </small>
            </div>
          </div>
        </div>
        {deal.length > 1 ?
          <div className="imgbanner-ht">
            <HomeCarousel deal={deal} />
          </div> : null}
      </section>
      <section className="queries_about_hotels">
        <div className="query-container">
          
          <div className="query-hotels-heading">
            <h2 style={{ color: "#bd0c21" }}>Any Questions about Hotel or your Booking?</h2>
            <p>You will find the answers in our Travel Guide</p>
          </div>
          {/* for the paintball */}
          {/* style={{ background: background }}
            onMouseMove={handleMouseMove} */}
             {/* <div>
            <h1>Water Surface Simulation</h1>
            <WaterSurface />
        </div> */}
          <div className="query-hotels-body">
          {/* <Tree /> */}
            {/* <div className="snake-container">
              <div className="light"></div>
            </div> */}
            <Col md={4} className="d-flex">
              <div className="query-box col position-relative">
                {/* <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/hotel-box-1.jpg")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div> */}
                <div className="box-body">
                  <a href="/">What do hotel stars mean?</a>
                  <p>
                    The number of stars indicates the standard and amenities
                    that you can expect in a hotel. The more, the more luxurious
                    the conditions are. However,..
                  </p>
                <img className="ht-img-l" src={hot} alt="" />
                </div>
                <div className="pulse"></div>
                {/* <div className="light2"></div>
                <div className="light"></div> */}
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col position-relative">
                {/* <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/hotel-box-2.jpg")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div> */}
                <div className="box-body">
                  <a href="/">Different types of hotel rooms</a>
                  <p>
                    Hotel rooms may be divided by the number of Guests and type of
                    beds. Single Room, Double Room, Executive, Vip Rooms,...
                  </p>
                  <img className="ht-img-l" src={hot} alt="" />
                </div>
               
                <div className="pulse"></div>
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col position-relative">
                {/* <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/hotel-box-3.jpg")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div> */}
                <div className="box-body">
                  <a href="/">Types of breakfast</a>
                  <p>
                    In different hotels around the world you can find various
                    kinds of breakfast. It's worth checking the breakfast offer
                    during the booking process...
                  </p>
                  <img className="ht-img-l" src={hot} alt="" />
                </div>
                <div className="pulse"></div>
               
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col position-relative">
                <div className="box-body">
                  <a href="/">
                    Check-in procedures for hotels
                  </a>
                  <p>
                    All hotels or similar establishments usually require guests
                    to reach at their mentioned Check-In time, Carry Id Proof...
                  </p>
                  <img className="ht-img-l" src={hot} alt="" />
                </div>
                <div className="pulse"></div>
                {/* <div className="pulse-1"></div> */}
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col col position-relative">
                <div className="box-body">
                  <a href="/">Types of Boarding's</a>
                  <p>There are several basic types of hotel beds: single, Room Only, Half-Board, Full-Board, Only Breakfast..</p>
                  <img className="ht-img-l" src={hot} alt="" />
                </div>
                <div className="pulse"></div>
                {/* <div className="particle-container">
                  {Array.from({ length: 50 }).map((_, index) => (
                    <div className="particle" key={index}></div>
                  ))}
                </div> */}
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col position-relative">
                <div className="box-body">
                  <a href="/">
                    Additional services available and offered in hotels
                  </a>
                  <p>
                    Many hotels offer additional services such as: wake-up
                    calls, laundry, Transportation...
                  </p>
                  <img className="ht-img-l" src={hot} alt="" />
                </div>
                <div className="pulse"></div>
                {/* <div className="water-surface"></div> */}
              </div>
            </Col>
          </div>
        </div>
      </section>
      <section className="top-route-bb">
        <Reviews serviceType={2} />
      </section>
      <section className="top-route-bb">
        <OffersSlider dataSource={dataSource} serviceType="Hotel" />
      </section>
      <section className="discounts_container">
        <Subscribe />
      </section>
    </div>
  );
};

export default HotelSearch;
