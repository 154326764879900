import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Card, Col, Layout, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BusIcon from "../../../assets/images/trus-bus-tic.png";
import "../Busticketsuccess/Busticketsuccess.scss";
const { Content } = Layout;

const CarTransferTicketSuccess = ({ carTicketsDetails }) => {
    const [loading, setLoading] = useState(true);
    // console.log(carTicketsDetails, "carTicketsDetails");
    useEffect(() => {
        if (carTicketsDetails?.ticketAllData?.BookingRefNo) {
            setLoading(false);
        }
        setLoading(false);
    }, [carTicketsDetails]);
    return (
        <Content className="admin-container cms-pages-width retrieve-linebar">
            <Card className="retrieve-booking busmtb">
                <Row gutter={[16, 16]}>
                    <Col lg={12} md={16} sm={16} xs={24}>
                        <h4 className="ticket-lti-bus">Car Transfer</h4>

                        {loading ? (
                            <Skeleton active paragraph={{ rows: 2 }} />
                        ) : carTicketsDetails?.ticketAllData?.BookingRefNo ?
                            (
                                <div className="fic-tickets-data-bus">
                                    <div className="bd-bus-info">
                                        <Row gutter={[8, 8]}>
                                            {carTicketsDetails?.ticketAllData?.TransferName ?
                                                <Col md={7} sm={24} xs={24}>
                                                    <p className="travel-bus"><i class="fa fa-car" style={{ fontSize: '20px', color: '#bd0c21' }}></i> {" "}
                                                        {carTicketsDetails?.ticketAllData?.TransferName}
                                                    </p>
                                                </Col> : null}
                                            {(carTicketsDetails?.ticketAllData?.PickUpName && carTicketsDetails?.ticketAllData?.DropOffName) ?
                                                <Col md={9} sm={12} xs={24}>
                                                    <div className="tic-indi-details-bus">
                                                        <span>{carTicketsDetails?.ticketAllData?.PickUpName}</span>
                                                        <span className="airline-tic-files-bus"></span>

                                                        <span>
                                                            {carTicketsDetails?.ticketAllData?.DropOffName}
                                                        </span>
                                                    </div>
                                                </Col> : null}
                                            {carTicketsDetails?.ticketAllData?.PickUpDate ?
                                                <Col md={8} sm={12} xs={24}>
                                                    <div className="airline-dte-tic-bus-add-value">
                                                        <span>
                                                            {carTicketsDetails?.ticketAllData?.PickUpDate}
                                                        </span>
                                                    </div>
                                                </Col> : null}
                                        </Row>
                                    </div>

                                    <Row gutter={[8, 8]}>
                                        {/* <Col md={3} sm={6} xs={24}>
                                            <img
                                                className="bus-success-pay"
                                                src={BusIcon}
                                                alt="image bus "
                                            />
                                            <i class="fa fa-car" style={{ fontSize: '20px', color: '#bd0c21' }}></i>
                                        </Col> */}
                                        {(carTicketsDetails?.ticketAllData?.PickUpDetailName && carTicketsDetails?.ticketAllData.DropOffDetailName) ?
                                            <Col md={16} sm={18} xs={24}>
                                                <div className="airline-dte-tic-bus">
                                                    <div className="points">
                                                        <p className="title" style={{ color: "#bd0c21" }}>Pick-up point</p>
                                                        <p>{carTicketsDetails?.ticketAllData?.PickUpDetailName}</p>
                                                    </div>

                                                    <ArrowRightOutlined />

                                                    <div className="points ml-2">
                                                        <p className="title" style={{ color: "#bd0c21" }}>Dropping point</p>
                                                        <p>{carTicketsDetails?.ticketAllData.DropOffDetailName}</p>
                                                    </div>
                                                </div>
                                            </Col> : null}

                                        <Col md={8} sm={24} xs={24}>
                                            <p className="pnr">
                                                PNR:{" "}
                                                <span className="pnr-ca" style={{ color: "#bd0c21" }}>{carTicketsDetails?.ticketAllData?.ConfirmationNo}</span>
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            ) : (
                                <div className="fic-tickets-data-bus">
                                    {carTicketsDetails?.ticketAllData?.Error?.errorMessage}
                                </div>
                            )}
                    </Col>

                    <Col lg={12} md={8} sm={8} xs={24} className="car-tran-sucss">
                        {loading ? (
                            <Skeleton active paragraph={{ rows: 2 }} />
                        ) : carTicketsDetails?.ticketAllData?.hasOwnProperty(
                            "ConfirmationNo"
                        ) ? (
                            <>
                                <div className="paymet-booking-tic">
                                    <h4>Car Booking Payment is Successful</h4>
                                    <p>
                                        A confirmation email has been sent your provided email
                                        address
                                    </p>
                                    <p>
                                        Ref-No : {carTicketsDetails?.ticketAllData?.BookingRefNo}
                                    </p>
                                    <p>
                                        Status : <span className="succes-pay-value" style={{ color: "green" }}>Success</span>
                                    </p>
                                </div>

                                <Row>
                                    <Col lg={12} md={18} xs={24}>
                                        <Button className="tic-retrive-book" htmlType="submit">
                                            <Link
                                                to={`cartransfer/ticket?ref=${carTicketsDetails?.ticketAllData?.BookingRefNo}`}
                                            >
                                                Retrieve Booking
                                            </Link>
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        ) : null}

                    </Col>
                </Row>
            </Card>
        </Content>
    );
};

export default CarTransferTicketSuccess;
