import React, { useEffect, useState } from "react";

import * as ANTD from "antd";
import { PlusCircleFilled, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import moment from "moment";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
// import word from "../assets/vector-icons/word.png";
import search from "../assets/vector-icons/search.png";
// import exchange from "../assets/vector-icons/exchange.png";
import ApiClient from "../../helpers/ApiClient";
import { getCarTicketStatus } from "../../common/AllTickets/AllTicketStatus";
import { Link } from "react-router-dom";

const { Content } = ANTD.Layout;

const Carsorder = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const [deleteModal, setdeleteModal] = useState(false);

  const [dataSource, setDataSource] = useState([]);

  const [isEditMode, setIsEditMode] = useState(true);

  const [form] = ANTD.Form.useForm();

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };
  const handleSubmit = (formvalues) => {
    if (isEditMode) {
      submitUpdateCarOrder(formvalues);
    } else {
      submitNewCarOrder(formvalues);
    }
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const getAllCarOrders = () => {
    ApiClient.get("carextranet/getallcarorder").then((res) => {
      if (res.status == 200) {
        setDataSource(res.data);
      }
    });
    ApiClient.get("admin/myBookings/report")
      .then((res) => {
        // console.log(res,"res")
      })
  };

  // const deleteCarOrder = (id) => {
  //   ApiClient.delete("carextranet/deletecarorder/" + id).then((res) => {
  //     if (res.status == 200) {
  //       ANTD.message.success("CarOrders Deleted  successfully", 3);
  //       getAllCarOrders();
  //     }
  //   });
  // };

  const submitUpdateCarOrder = (formvalues) => {
    // // console.log(formvalues);
    const updatedFormData = {
      ...formData,
      ...formvalues,
    };
    // console.log("formData", formData.OrderID);

    ApiClient.put("carextranet/updatecarorder/" + formData.OrderID, {}, updatedFormData).then((response) => {
      // console.log(response);
      if (response.status === 200) {
        setModalVisible(false);
        ANTD.message.success("CarOrder updated successfully");
        getAllCarOrders();
      } else {
        ANTD.message.error(" Error Updating CarOrder");
      }
    });
  };

  const handleEditClick = (rec) => {
    // console.log(rec);
    form.setFieldsValue({
      ...rec,
      // CarType: getCarTypeName(rec.CarType),
      AvailabilityID: rec.AvailabilityID.AvailabilityID,
    });
    setFormData({ ...rec });

    setIsEditMode(true);

    showModal();
  };

  const handleAddClick = () => {
    showModal();
    setIsEditMode(false);
  };

  useEffect(() => {
    getAllCarOrders();
  }, []);

  const [formData, setFormData] = useState({});

  const submitNewCarOrder = (formvalues) => {
    setFormData(formvalues);
    // // console.log("fieldsvalue", fieldsvalues);
    ApiClient.post("carextranet/createcarorder", formvalues).then((response) => {
      if (response.status === 200) {
        setModalVisible(false);
        ANTD.message.success("CarOrder created successfully");
        getAllCarOrders();
      } else {
        ANTD.message.error(" Error Creating CarOrder ");
      }
    });
  };

  // const tableActions = (rec) => {
  //   return (
  //     <div style={{ display: "flex", justifyContent: "space-evenly" }}>
  //       <div className="edit-icon" style={{ textAlign: "center" }}>
  //         <EditOutlined onClick={() => handleEditClick(rec)} style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }} />
  //       </div>
  //       <div className="edit-icon" style={{ textAlign: "center" }}>
  //         <ANTD.Popconfirm title="Are you sure to delete?" onConfirm={() => deleteCarOrder(rec.OrderID)}>
  //           <DeleteOutlined style={{ color: "red", fontSize: "20px", textAlign: "center" }} />
  //         </ANTD.Popconfirm>
  //       </div>
  //     </div>
  //   );
  // };

  const { Option } = ANTD.Select;
  const prefixSelector = (
    <ANTD.Form.Item name="prefix" noStyle>
      <ANTD.Select style={{ width: 80 }} placeholder="+91">
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </ANTD.Select>
    </ANTD.Form.Item>
  );

  const TravelType = (type) => {
    switch (type) {
      case 1:
        return <p>Local</p>;

      case 2:
        return <p>Out-Station</p>;
    }
  };

  const TripType = (triptype) => {
    switch (triptype) {
      case 1:
        return <p>One way</p>;
      case 2:
        return <p>Round trip</p>;
      case 3:
        return <p>4 hours</p>;
      case 4:
        return <p>8 hours</p>;
      case 5:
        return <p>12 hours</p>;
      case 6:
        return <p>24 hours</p>;
    }
  };

  const columns = [
    // {
    //   title: "Name",
    //   dataIndex: "name",
    // },

    // {
    //   title: "Reference No",
    //   dataIndex: "referenceno",
    // },

    {
      title: "Refenrece No.",
      dataIndex: "BookingRefNo",
      render: (text) => {
        return <Link to={`/admin/cars/ticket?ref=${text}`}>{text}</Link>;
      },
    },

    // {
    //   title: "User ID",
    //   dataIndex: "userid",
    // },

    {
      title: "BookingDate",
      dataIndex: "CreatedDate",
      render: (date) => moment(date).format('DD-MM-YYYY'),
  sorter: (a, b) => moment(b.CreatedDate).valueOf() - moment(a.CreatedDate).valueOf(),
  defaultSortOrder: 'desc',
    },

    {
      title: "Passenger Name",
      dataIndex: "GuestName",
    },

    {
      title: "Email Address",
      dataIndex: "EmailId",
    },

    {
      title: "Mobile Number",
      dataIndex: "MobileNo",
    },
    {
      title: "Source",
      dataIndex: "Source",
    },
    // {
    //   title: "PickUp Address",
    //   dataIndex: "PickUpAddress",
    // },
    {
      title: "Destination",
      dataIndex: "Destination",
    },
    {
     title:"Travel Type",
     render: (item) => {
      const status = TravelType(item.TravelType);
      return  (
        <span>{status}</span>
      );
    },
    },
    {
      title:"Trip Type",
      render: (item) => {
        const status = TripType(item.TripType);
        return (
          <span>{status}</span>
        );
      },
     },

    // {
    //   title: "Drop Address",
    //   dataIndex: "DropAddress",
    // },

    // {
    //   title: "Promo Code",
    //   dataIndex: "PromoCode",
    // },

    // {
    //   title: "Advance Paid ",
    //   dataIndex: "CollectedFare",
    // },

    // {
    //   title: "Full Amount Paid",
    //   dataIndex: "totalPrice",
    // },
    // {
    //   title: "Driver",
    //   dataIndex: "driver",
    //   render: (item) => `${item.driver.Name}`
    //   // render: (text, index) => {
    //   //   return text.Name;
    //   // },
    // },

    {
      title: "Vendor",
      dataIndex: "Vendor",
      render: (text, rec) => {
        if (text) {
          return text.CompanyName;
        }
        return "";
      },
    },
    // {
    //   title: "Booking Status",
    //   dataIndex: "BookingStatus",
    //   render: (text) => {
    //     return getCarTicketStatus(text);
    //   },
    // },

    // {
    //   title: "Created Date",
    //   dataIndex: "credate",
    // },
    // {
    //   title: "LastModified Date",
    //   dataIndex: "lmdate",
    // },

    /*   {
            title: 'Status',
            dataIndex: 'no',
            key: 'airline',
            render: (text, order) =>
            <ANTD.Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked />
        }, */
    // {
    //   title: "Actions",
    //   render: (rec) => {
    //     return tableActions(rec);
    //   },
    // },
    /*  {
            title: 'Delete',
            dataIndex: 'delete'

        } */
  ]

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    {/* <div className="table-heading-count"> */}
                    <div className="rows-count">
                      <div>
                        <h5>
                          Cars Orders {""}
                          <HelpInfoHelper screenName={"/admin/carsorder"} />
                        </h5>
                        {/*   <p>3 rows found !</p> */}
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />
                      <img src={excel} alt="excel" />
                      <img src={pdf} alt="pdf" />
                      {/* <img src={word} alt="word" /> */}

                      {/*  <img src={exchange} alt="exchange" /> */}
                      {/* <p className="add-deposit-icon" onClick={handleAddClick}>
                        <PlusCircleFilled />
                      </p> */}
                    </div>
                    {/* </div> */}
                  </div>

                  <div>
                    <ANTD.Table
                      className="table-scroll-none"
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />

                    {/* <ANTD.Modal visible={this.state.visible} onCancel={closeModal}>
                                                <h6>This is the modal bodyyyyy</h6>
                                        </ANTD.Modal> */}
                  </div>
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>

      {/* <ANTD.Modal
        title="CarOrders"
        className="promo-modal-header modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <ANTD.Form layout={"vertical"} name="basic" onFinish={handleSubmit} form={form}>
          <ANTD.Row gutter={16}>
             <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name={["Reference No"]}
                label="Reference No"
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    required: true,
                    message: "Please input your Reference No!",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>  

            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name="AvailabilityID"
                label="Availability ID"
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

             <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name={["User ID"]}
                label="User ID"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>  

            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name="PassengerName"
                label="Passenger Name"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please input your Name",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item label="Status" name="Status">
                <ANTD.Radio.Group rules={[{ required: true, message: "Please choose Status!" }]}>
                  <ANTD.Radio value={1}>Yes</ANTD.Radio>
                  <ANTD.Radio value={2}>No</ANTD.Radio>
                </ANTD.Radio.Group>
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Row gutter={16}>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name="EmailAddress"
                label="Email Address"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your Email Address",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item name="MobileNumber" label="Mobile Number">
                <ANTD.Input addonBefore={prefixSelector} style={{ width: "100%" }} />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name="PickUpAddress"
                label="PickUp Address"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please enter Pick Address",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name="PromoCode"
                label="Promo Code"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please enter your Promo Code",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Row gutter={16}>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name="AdvancePaid"
                label="Advance Paid"
                rules={[
                  {
                    pasttern: /^[0-9]*$/,
                    required: true,
                    message: "Please enter Advance Paid",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name="FullAmountPaid"
                label="Full Amount Paid"
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    required: true,
                    message: "Please enter Full Amount Paid",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name="VendorID"
                label="Vendor ID"
                rules={[
                  {
                    pattern: /^[0-9]*$/,
                    required: true,
                    message: "Please enter Vendor ID",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
            <ANTD.Button type="primary" htmlType="submit">
              {isEditMode ? "Update" : "Add"}
            </ANTD.Button>
            <ANTD.Button type="primary" danger className="cancel-btn">
              Clear
            </ANTD.Button>
          </ANTD.Form.Item>
        </ANTD.Form>
      </ANTD.Modal> */}
    </div>
  );
};

export default Carsorder;
