import { Col, Row, Form } from "antd";
import React, { useContext, useEffect, useState } from "react";
import ApiClient from "../../helpers/ApiClient";
import Banner from "../../components/banner/Banner";
import Homesearch from "../../components/search-box/SearchBox";
import HomeCarousel from "../HomeCarousel/HomeCarousel";
import "./Home.scss";
import TopFlights from "./TopFlights/TopFlights";
import TopCities from "./TopCities/TopCities";
import { useSytContext } from "../../common/providers/SytProvider";

import { Carousel } from "antd";
import AgentHomePage from "../../components/Agenthomepage/Agenthomepage";
import { useAuthContext } from "../../common/providers/AuthProvider";
import Subscribe from "../subscribe/Subscribe";
import { Link } from "react-router-dom";
import Reviews from "../../common/Reviews/Reviews";
import FooterBanner from "./FooterBanner/FooterBanner";
import HomeSlider from "./HomeSlider/HomeSlider";
import TopDomesticRoutes from "./TopDomesticRoutes/topDomesticRoutes";
import GlobalStatesContext from "../../common/providers/GlobalStatesContext";
import OffersSlider from "../../common/LandingPageOffers/LandingPageOffers";
import queryString from "query-string";
import moment from "moment";

const Home = () => {
  const {
    blog,
    flightBanners,
    footerBanner,
    bigHomeSliderBanners,
    smallHomeSliderBanners,
    promoDataSource,
  } = useSytContext();

  const [dataSource, setDataSource] = useState([]);
  const [recentSearchResults, setRecentSearchResults] = useState([]);

  const {
    isLogin: { agent },
    user,
    isGuest,
  } = useAuthContext();

  const [dealsList, setDealsList] = useState([]);
  const [deal, setDeal] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const [dealsResp, dealResp] = await Promise.all([
        ApiClient.get("admin/getAllServicesTopCities"),
        ApiClient.get("admin/dealsimages"),
      ]);

      if (dealsResp.status === 200) {
        setDealsList(dealsResp.data);
      }

      if (dealResp.status === 200) {
        const data = dealResp.data.filter(
          (item) => item.ServiceType === 1
        );
        setDeal(data);
      }

      const data = sessionStorage?.getItem("recentSearchesResults");
      if (data?.length) {
        setRecentSearchResults(JSON.parse(data));
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (promoDataSource.length) {
      const data = promoDataSource.filter(
        (item) => item.ServiceType === 1
      );
      setDataSource(data);
    }
  }, [promoDataSource]);

  return (
    <div id="home-page">
      {(agent && user?.Role?.RoleId === 5) || user?.Role?.RoleId === 7 ? (
        <section className="hero-banner">
          <Banner banner={flightBanners} />
          {(isGuest || user?.Services.includes("1")) && <AgentHomePage />}
        </section>
      ) : (
        <section className="hero-banner">
          <Banner banner={flightBanners} />
          <Homesearch />
        </section>
      )}

      {recentSearchResults.length ? (
        <section className="home-best-24 recentSearchesRow">
          <div className="container">
            <Row gutter={16} className="justify-content-end align-items-center" style={{ display: "none" }}>
              <Col md={4} xs={24}>
                <p className="m-0 text-right">Recent Searches: </p>
              </Col>
              {recentSearchResults.map((item, i) => {
                const newdepartureDate = moment(
                  item.departureDateTime,
                  "YYYY-MM-DD"
                )
                  .add(1, "days")
                  .format("YYYY-MM-DD");
                const newstringObj = {
                  ...item,
                  departureDateTime: newdepartureDate,
                };
                const newString = queryString.stringify(newstringObj);

                return (
                  <Col key={i + "recentresults"} md={4} xs={24}>
                    <div className="recentResults">
                      <Link to={`flight/results?${newString}`}>
                        <div
                          style={{
                            boxShadow: "0 1px 5px black",
                            borderRadius: "6px",
                            textAlign: "center",
                            overflow: "hidden",
                          }}
                        >
                          <div className="ducarosuel">
                            <div className="city-due">{item.origin}</div>
                            <div className="iconsDiv city-due">
                              <i
                                className="fa fa-long-arrow-right"
                                aria-hidden="true"
                              ></i>
                            </div>

                            <div className="city-due">{item.destination}</div>
                            <div className="time-due">
                              {moment(item.departureDateTime, "YYYY-MM-DD")
                                .add(1, "days")
                                .format("DD, MMM YYYY")}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </section>
      ) : (
        ""
      )}
      <section className="home-best-24">
        <div className="container">
          <Row>
            <Col md={12} xs={24}>
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../assets/images/one-icon-ts.png")
                    .default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>BEST PRICE GUARANTEED</p>
                  <p>
                    Trying our level best to fetch lower price than others, try
                    us once!!
                  </p>
                </div>
              </div>
            </Col>
            <Col md={12} xs={24} className="support-24">
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../assets/images/one-icon-ts1.png")
                    .default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>24×7 SUPPORT</p>
                  <p>
                    We’re always here for you – reach us 24 hours a day, 7 days
                    a week.{" "}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="container">
        {footerBanner.length > 0 && (
          <div className="domestic-airsearch faqs-flight-sec">
            <FooterBanner footerBanner={footerBanner} />
          </div>
        )}
      </section>
      <section className="img-ft-img-sec">
        {bigHomeSliderBanners.length > 0 && (
          <div className="container">
            <Row>
              <Col md={14} xs={24}>
                <Carousel effect="fade" autoplay={true}>
                  {bigHomeSliderBanners.map((banner, idx) => (
                    <div key={"bannersIDX" + idx}>
                      <img
                        className="step-img-value"
                        src={
                          process.env.REACT_APP_BASE_URL +
                          banner.path.substring(1)
                        }
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  ))}
                </Carousel>
              </Col>
              <Col md={10} xs={24}>
                {smallHomeSliderBanners.length > 0 && (
                  <HomeSlider images={smallHomeSliderBanners} />
                )}
              </Col>
            </Row>
          </div>
        )}
      </section>
      {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) ||
        agent === false) && (
        <>
          <HomeCarousel deal={deal} />
          <TopDomesticRoutes deals={dealsList} serviceType="Flight" type={"Flights"} />
          <OffersSlider dataSource={dataSource} serviceType="Flight" type={"Flights"} />
          <TopFlights dealsList={dealsList} />
        </>
      )}

      <section className="home-best-trip">
        <div className="container">
          <Row>
            <Col md={24} xs={24}>
              <h2 className="top-fl-route route-trip-3">Why AnjMal?</h2>
              <p>
                Our competitive rates and exclusive offers are what gives us a
                top notch over our competitors. We promise 'Unbeatable' services
                both in pricing and quality. Here is the one stop destination
                for your Dream Destination. AnjMal provide you the best travel
                packages at the lowest possible pricing that gives the best
                value for your each penny. We are your Travel Companion and
                works for you so that can get the best travel experience and
                live some memorable moments.
              </p>
              <p>
                We give you the pros and cons for all the different travel
                products and allow you to decide what works best for you and
                your family. We combine first-hand knowledge with exceptional
                special offers, and we take care of every detail to create a
                holiday as unique as you are. You will no more need to worry
                about coordinating flight bookings, hotel reservations, visa
                stamps or tours as all your needs are taken care of under one
                roof.
              </p>
              <p>
                AnjMal can satisfy all your travel needs. Here, You can book
                Flights, Hotels, Visas, and Car Service's
                packages at a cost-effective price. So, why go anywhere else?
                Visit us for a memorable travel experience in your budget.
              </p>
            </Col>
          </Row>
        </div>
      </section>

      <section className="top-route-bb">
        <Reviews serviceType={1} />
      </section>

      <section>{/* <FlightDeal /> */}</section>
      <Subscribe />

      
    </div>
  );
};

export default Home;


