// import React, { createContext, useContext, useState } from "react";
// import { useSessionStorage, useLocalStorage } from "../../helpers/useStorage";
// import ApiClient from "../../helpers/ApiClient";

// const AuthContext = createContext();

// export function useAuthContext() {
//   return useContext(AuthContext);
// }

// const AuthProvider = ({ children }) => {
//   const [isLogin, setLogin] = useState({
//     flag: false,
//     role: false,
//     agent: false,
//     //admin:false,
//     adminAsUser: {
//       status: false,
//       id: -1,
//     },
//   });
//   const [user, setUser] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [accessToken, setAccessToken] = useSessionStorage("accessToken", null);
//   const [adminAsUser, setAdminAsUser] = useSessionStorage("extraUser", {
//     status: false,
//     id: -1,
//   });
//   const [extraUserInfo, setExtraUserInfo] = useLocalStorage(
//     "extraUserInfo",
//     null,
//     true
//   );
//   const [accessList, setAccessList] = useState([]);

//   const resetIsLogin = () => {
//     setLogin({
//       flag: false,
//       role: false,
//       agent: false,
//      // admin: false,
//     });
//     setUser(null);
//     localStorage.clear();
//     sessionStorage.clear();
//   };

//   const updateIsLogin = (attribute, value) => {
//     setLogin((prev) => ({ ...prev, [attribute]: value }));
//   };

//   const updateAgentLoginFlag = (value) => updateIsLogin("agent", value);
//   const updateLoginFlag = (value) => updateIsLogin("flag", value);
//   const updateDashboardFlag = (value) => updateIsLogin("role", value);
//   //const updateAdminFlag = (value) => updateIsLogin("admin", value);

//   const tryLocalSignin = () => {
//     if (accessToken) {
//       setIsLoading(true);
//       ApiClient.get("admin/getUserByauthToken")
//         .then((resp) => {
//           // console.log(resp,"auth");
//           if (resp?.status === 200) {
//             setUser(resp.data);
//             if (resp.data.Role.RoleLevel == 1) {
//               let screens = [];
//               resp.data.Screens.forEach((element) => {
//                 screens.push(element.Name);
//               });
//               setAccessList(screens);
//               updateDashboardFlag(true);
//               //updateAdminFlag(true)
//             } else if (resp.data.Role.RoleLevel == 3) {
//               updateAgentLoginFlag(true);
//             } else if (resp.data.Role.RoleLevel == 2) {
//               updateLoginFlag(true);
//             }
//           } else {
//             setAccessToken(null);
//           }
//           setIsLoading(false);
//         })
//         .catch((e) => {
//           setIsLoading(false);
//           console.log("api error", e);
//         });
//     } else {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <AuthContext.Provider
//       value={{
//         isLogin,
//         user,
//         isLoading,
//         setUser,
//         accessToken,
//         adminAsUser,
//         setAccessToken,
//         setAdminAsUser,
//         accessList,
//         extraUserInfo,
//         setExtraUserInfo,
//         setAccessList,
//         updateAgentLoginFlag,
//         updateLoginFlag,
//         updateDashboardFlag,
//         resetIsLogin,
//         tryLocalSignin,
//       }}
//     >
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export default AuthProvider;

import React, { createContext, useContext, useState, useEffect } from "react";
import { useSessionStorage, useLocalStorage } from "../../helpers/useStorage";
import ApiClient from "../../helpers/ApiClient";
import EncHelper from "../../helpers/EncHelper";

const AuthContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

const AuthProvider = ({ children }) => {
  const [isLogin, setLogin] = useState({
    flag: false,
    role: false,
    agent: false,
    //admin:false,
    adminAsUser: {
      status: false,
      id: -1,
    },
  });

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
   const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
  //const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
  const [adminAsUser, setAdminAsUser] = useSessionStorage("extraUser", {
    status: false,
    id: -1,
  });
  const [extraUserInfo, setExtraUserInfo] = useLocalStorage(
    "extraUserInfo",
    null,
    true
  );

  const [accessList, setAccessList] = useState([]);

  useEffect(() => {
    EncHelper.setItem("login", JSON.stringify(isLogin));
  }, [isLogin]);

  useEffect(() => {
    sessionStorage.setItem("accessToken", JSON.stringify(accessToken));
    localStorage.setItem("accessToken", JSON.stringify(accessToken));
  }, [accessToken]);

  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(user));
  }, [user]);



  const updateIsLogin = (attribute, value) => {
    let temp = {
      ...isLogin,
      [attribute]: value,
    };
    setLogin((prev) => ({ ...prev, [attribute]: value }));
    sessionStorage.setItem("login", JSON.stringify(temp))
  };

  // const resetIsLogin = () => {
  //   setLogin({
  //     flag: false,
  //     role: false,
  //     agent: false,
  //    // admin: false,
  //   });
  //   setUser(null);
  //   localStorage.clear();
  //   sessionStorage.clear();
  // };
  const resetIsLogin = () => {
    localStorage.setItem("logoutFlag", Date.now().toString());
    setLogin({
      flag: false,
      role: false,
      agent: false,
      adminAsUser: {
        status: false,
        id: -1,
      },
    });

    setUser(null);

    // Clear user-related data from localStorage and sessionStorage
    sessionStorage.removeItem("accessToken");
    localStorage.removeItem("accessToken");
    sessionStorage.removeItem("userData")
    localStorage.removeItem("userData");
    sessionStorage.removeItem("login")
    sessionStorage.removeItem("extraUser");
    sessionStorage.removeItem("extraUserInfo");
    sessionStorage.removeItem("accessList");


  };
  useEffect(() => {
    const handleStorageChange = () => {
     
      if (localStorage.getItem("logoutFlag")) {
        
        resetIsLogin();
       
        localStorage.removeItem("logoutFlag");
      }
    };

    
    window.addEventListener("storage", handleStorageChange);

    
    return () => {
      
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);




  const updateAgentLoginFlag = (value) => updateIsLogin("agent", value);
  const updateLoginFlag = (value) => updateIsLogin("flag", value);
  const updateDashboardFlag = (value) => updateIsLogin("role", value);
  //const updateAdminFlag = (value) => updateIsLogin("admin", value);

  const tryLocalSignin = () => {
    const storedAccessToken = JSON.parse(localStorage.getItem("accessToken"));
    const storedUserData = JSON.parse(
      sessionStorage.getItem("userData") ||
        localStorage.getItem("userData")
    );
    // console.log("stored",storedAccessToken,storedUserData)
    if (storedAccessToken) {
      setAccessToken(storedAccessToken);
      
    }
    if (storedUserData) {
      setUser(storedUserData);
      
    }

    if (accessToken) {
      setIsLoading(true);
      ApiClient.get("admin/getUserByauthToken")
        .then((resp) => {
          // console.log(resp, "auth");
          if (resp?.status === 200) {
            setUser(resp.data);
            localStorage.setItem("userData", JSON.stringify(resp.data));
            sessionStorage.setItem("userData", JSON.stringify(resp.data));
            if (resp.data.Role.RoleLevel == 1) {
              let screens = [];
              resp.data.Screens.forEach((element) => {
                screens.push(element.Name);
              });
              setAccessList(screens);
              // sessionStorage.setItem("accessList", JSON.stringify(screens));
              updateDashboardFlag(true);
              //updateAdminFlag(true)
            } else if (resp.data.Role.RoleLevel == 3) {
              updateAgentLoginFlag(true);
            } else if (resp.data.Role.RoleLevel == 2) {
              updateLoginFlag(true);
            }
          }
          else {
            // setAccessToken(null);
            //  console.log(storedUserData,"stored")
             if (storedUserData && storedUserData.Role.RoleLevel === 2) {
              updateLoginFlag(true);
            } else if (storedUserData && storedUserData.Role.RoleLevel === 1) {
              let screens = [];
              storedUserData.Screens.forEach((element) => {
                screens.push(element.Name);
              });
              setAccessList(screens);
              updateDashboardFlag(true);
            } else {
              updateAgentLoginFlag(true);
            }
          }
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log("api error", e);
        });
    } else {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isLogin,
        user,
        isLoading,
        setUser,
        accessToken,
        adminAsUser,
        setAccessToken,
        setAdminAsUser,
        accessList,
        extraUserInfo,
        setExtraUserInfo,
        setAccessList,
        updateAgentLoginFlag,
        updateLoginFlag,
        updateDashboardFlag,
        resetIsLogin,
        tryLocalSignin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
