import React, { useEffect, useState } from "react";
import excel from "../../assets/vector-icons/excel.png";
import search from "../../assets/vector-icons/search.png";
import exchange from "../../assets/vector-icons/exchange.png";
import HelpInfoHelper from "../../../common/HelpInfoHelper/HelpInfoHelper";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Table,
  Layout,
  Modal,
  Select,
  Radio,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import ApiClient from "../../../helpers/ApiClient";
import UpdatedComponent from "../../ExportExcel";
import "./Gstdetails.scss";
const { Content } = Layout;
const { Option } = Select;

const GSTDetails = ({ exportExcel }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [GSTDetailsForm] = Form.useForm();
  const [gstDetails, setGSTDetails] = useState([]);
  const [companiesList, setComapniesList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [currID, setCurrID] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [excelList, setExcelList] = useState([]);

  useEffect(() => {
    getGstDetails();
    getCompaniesList();
  }, []);

  const getCompaniesList = () => {
    ApiClient.get("CorporateExtranet/GetCompany")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setComapniesList(data);
        } else {
          setComapniesList([]);
        }
      })
      .catch((e) => {
        setComapniesList([]);
      });
  };

  const getGstDetails = () => {
    ApiClient.get("CorporateExtranet/get_gst")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              CompanyUserName: item.Company.UserName,
            };
          });
          setGSTDetails(data);
          defineExcelData(data);
        } else {
          setGSTDetails([]);
        }
      })
      .catch((e) => {
        setGSTDetails([]);
      });
  };

  const submitForm = (values) => {
    let data = {
      ...values,
    };

    isEdit ? updateGstDetails(data, currID) : addGstDetails(data);
  };

  const addGstDetails = (data) => {
    ApiClient.post("CorporateExtranet/create_gst", data)
      .then((response) => {
        if (response.status == 200) {
          message.success("GST Details added successfully !");

          getGstDetails();
          setModalVisible(false);
          GSTDetailsForm.resetFields();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateGstDetails = (data, recId) => {
    ApiClient.put("CorporateExtranet/update_gst/" + recId, {}, data)
      .then((response) => {
        if (response.status == 200) {
          message.success("GST Details updated successfully !");

          getGstDetails();
          setModalVisible(false);
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteGstRequest = (id) => {
    ApiClient.delete("CorporateExtranet/delete_gstbyid/" + id)
      .then((response) => {
        if (response.status == 200) {
          message.success("GST Details Deleted successfully !");
          getGstDetails();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const showModal = () => {
    setIsEdit(false);
    GSTDetailsForm.resetFields();

    setModalVisible({
      visible: true,
    });
  };

  const onUpdate = (currentData) => {
    setIsEdit(true);
    setModalVisible(true);
    setCurrID(currentData.Id);
    GSTDetailsForm.setFieldsValue({
      ...currentData,
    });
  };

  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        SrNo: item.SNo,
        GSTNumber: item.GSTNumber,
        CompanyUserName: item.CompanyUserName,
        City: item.City,
        BillingAddress1: item.BillingAddress1,
        BillingAddress2: item.BillingAddress2,
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const tableActions = (currentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}
          onClick={() => onUpdate(currentItem)}
        >
          <EditOutlined
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteGstRequest(currentItem.Id)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "SNo",
      dataIndex: "SNo",
    },
    {
      title: "GST Number",
      dataIndex: "GSTNumber",
    },
    {
      title: "Company User Name",
      dataIndex: "CompanyUserName",
    },
    {
      title: "City",
      dataIndex: "City",
    },
    {
      title: "BillingAddress1",
      dataIndex: "BillingAddress1",
    },
    {
      title: "BillingAddress2",
      dataIndex: "BillingAddress2",
    },

    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = gstDetails.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setGSTDetails(searchResults);
    } else {
      getGstDetails();
      setGSTDetails(gstDetails);
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  return (
    <div className="promo providerapi">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          View GST Details{" "}
                          <HelpInfoHelper screenName={"/admin/gstdetails"} />
                        </h5>
                        {gstDetails.length > 0 && (
                          <p>{gstDetails.length} rows found !</p>
                        )}
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img
                        src={excel}
                        alt="excel"
                        onClick={(e) => exportExcel(excelList, "GSTDetails")}
                      />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getGstDetails()}
                      />
                      <p className="add-icon" onClick={showModal}>
                        <PlusOutlined />
                      </p>
                    </div>
                  </div>
                  {gstDetails.length > 0 ? (
                    <div>
                      <Table
                        className="table-scroll-none"
                        bordered
                        dataSource={gstDetails}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  ) : (
                    <b>No record Found</b>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>
                {isEdit ? "Update GST Details" : "Add GST Details"}
              </strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              htmlType="submit"
              type="primary"
              onClick={GSTDetailsForm.submit}
            >
              {isEdit ? "Update" : "Save"}
            </Button>
            <Button type="danger" onClick={() => GSTDetailsForm.resetFields()}>
              Clear
            </Button>
          </div>,
        ]}
      >
        <Form layout={"vertical"} form={GSTDetailsForm} onFinish={submitForm}>
          <p className="gst-group">
            As per Air India, corporates need to register themselves on the
            airline’s website to get GST Credits. Please register yourselves on
            the links mentioned below to claim GST Credits. This is a one-time
            task, and you can register with your HQ GST details because airlines
            will only provide IGST.
          </p>

          <p className="gst-data-row">Please enter GST details</p>
          <Row gutter={(12, 12)} className="mb-2">
            <Col className="gutter-row" md={12} xs={24}>
              <Form.Item
                label="GST Number"
                name="GSTNumber"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={12} xs={24}>
              <Form.Item
                label="Company"
                name="CompanyId"
                rules={[{ required: true }]}
              >
                <Select showSearch placeholder="Select Package">
                  {companiesList.map((item, index) => (
                    <Option key={index + "CategoryId"} value={item.CompanyId}>
                      {item.UserName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={12} xs={24}>
              <Form.Item
                label="Billing Address 1"
                name="BillingAddress1"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={12} xs={24}>
              <Form.Item
                label="Billing Address 2"
                name="BillingAddress2"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={12} xs={24}>
              <Form.Item label="City" name="City" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={12} xs={24}>
              <Form.Item
                label="PINCode"
                name="PINCode"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdatedComponent(GSTDetails);
