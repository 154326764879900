import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./Offers.scss";
import { Card, Row, Col, Popconfirm, Tooltip, Badge } from "antd";
import noImage from "../../assets/images/image-not-found-scaled-1150x647.png";

const moment = require('moment');
function OffersSlider(props) {
 
  let currentDate = new Date()
  let Slides = props?.dataSource;
  const currentTodayDate = moment(currentDate).format('YYYY-MM-DD');
  // { console.log(props.dataSource, filterPromo, Slides?.filter((p) => currentTodayDate <= p?.ValidTill)?.length, "fffamil") }
  const MobileSlidersettings = {
    arrows: false,
    dots: false,
    // slidesToShow:2,
    slidesToShow: Slides?.filter((p) => currentTodayDate <= p?.ValidTill)?.length > 4 ? 4 : Slides?.filter((p) => currentTodayDate <= p?.ValidTill)?.length,
    speed: 500,

    slidesToScroll: 1,
    initialSlide: 1,
    infinite: true,
    autoplay: true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Slides?.filter((p) => currentTodayDate <= p?.ValidTill)?.length > 4 ? 4 : Slides?.filter((p) => currentTodayDate <= p?.ValidTill)?.length,
          // slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
 
  function copyToClipboard(text) {
    const elem = document.createElement("textarea");
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  }
  const filterPromoData = Slides.filter((p) => currentTodayDate <= p.ValidTill)

  return (
    filterPromoData.length > 0 ? (<div className="offerSlider">
      <div className="col-12">
        {props?.dataSource?.length == 0 ? (null) : (
          <h3 className="mb-3 offers-discounts-v">
            {props?.serviceType} Discounts For You
          </h3>)}
      </div>
      
      <div className="col-12">
        <div className="offer-wrapper1 mb-4 ">
          {/* <h3>Offers</h3> */}
          <Slider {...MobileSlidersettings}>
            {filterPromoData.map((item, i) => {
              return (
                <div key={"offerslider" + i} className="card-wrapper" md={3}>
                  <div className="card card-offer" key={i} style={{ justifyContent: 'space-evenly', display: 'flex', flexWrap: 'wrap' }}>
                    
                    <div style={{ justifyContent: 'space-evenly', display: 'flex', flexWrap: 'wrap', padding: '2%', height: '100%' }} >
                      <div className="card-offer-image-box" >
                        <img
                          src={
                            item.Image
                              ? `${process.env.REACT_APP_BASE_URL +
                              item.Image.substring(1)
                              }`
                              : noImage
                          }
                          alt="book-img"
                          loading="lazy"
                        />
                      </div>
                      <div className="card-body card-offer-body" style={{ width: "60%", paddingLeft: '4%' }}>

                        <h5 style={{ color: 'black', fontWeight: '900' }}> {item.Name}</h5>
                        <p style={{ color: 'grey', fontSize: '12px' }}> {item.Description}</p><br />
                        {/* <Tooltip title="Copy" placement="bottom"> */}
                        <Popconfirm
                          title="Code Copied, Click on OK"
                          onConfirm={() => copyToClipboard(item.Code)}
                          onCancel={false}
                          okText="Ok"
                          cancelText="No"
                        ><h4 className="card-offer-title card-title">
                            CODE : <span style={{ color: '#330064' }} >{item.Code} <i class="fa fa-copy"></i></span>
                          </h4>
                        </Popconfirm>
                        {/* </Tooltip> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>

     
      </div>
    </div>
    ) : null
  );
}

export default OffersSlider;
