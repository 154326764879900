import React, { useContext, useEffect, useState, useReducer } from "react";
import { useHistory, Link } from "react-router-dom";
import { Card, Col, message, Row, Modal, Statistic } from "antd";
import {
  ArrowLeftOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import GlobalStatesContext from "../../../common/providers/GlobalStatesContext";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { useHotelContext } from "../../../common/providers/Hotels/HotelProvider";
import HotelDetailsBox from "../../../components/HotelDetailsBox/HotelDetailsBox";
import VerifyOtpModal from "../../../common/OtpModal/VerifyOtpModal";
import ApiClient from "../../../helpers/ApiClient";
import HotelBookPayCard from "../HotelCheckout/HotelBookPayCard";
import timeoutimg from "../../../assets/images/timeout.jpg"
import HotelFairBox, {
  getHotelGrandTotal,
} from "../HotelCheckout/HotelFairBox";
import "../HotelPreview/HotelPreview.scss";
import HotelPassengers from "./HotelPassengers/HotelPassengers";
import moment from "moment";
import PaymentComponent from "../../../helpers/CCavanuePayCheckout";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import CashFreeNewVersionCheckout from "../../../helpers/CashFreeNewVersionpayCheckout";
import queryString from "query-string";
const PGTYPE = "PGTYPE";
const PGDISPLAY = "PGDISPLAY";
const PGDATA = "PGDATA";

const initialState = { pgDisplay: false, pgData: {}, pgType: -1 };

function reducer(state, action) {
  switch (action.type) {
    case PGTYPE:
      return { ...state, pgType: action.payload };
    case PGDISPLAY:
      return { ...state, pgDisplay: action.payload };
    case PGDATA:
      return { ...state, pgData: action.payload };
    default:
      return state;
  }
}
const { Countdown } = Statistic;
const HotelPreview = () => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const {
    state: { hotelCheckOutData, sessiontimeout, status },
  } = useHotelContext();
 
  const { activeCurrency, currencyValue, currencyRatio } = useCurrencyContext();
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [loadSpin, setLoadSpin] = useState(false)
  const [pgDetails, dispatchPgDetails] = useReducer(reducer, initialState);
  const [openCashfreeNewVersionCheckout, setOpenCashfreeNewVersionCheckout] = useState(false);
  const [openCCavanueCheckout, setOpenCCavanueCheckout] = useState(false)
  const [pgData, setPgData] = useState({});
  const [pgIsLoading, setPgIsLoading] = useState({
    direct: false,
    hold: false,
  });
  const {
    state: {
      otherData: { promoData, ConvFee, selectedInsuranceData, redeemAmount },
    },
  } = useContext(GlobalStatesContext);
  let history = useHistory();
  useEffect(() => {
    if (Object.keys(hotelCheckOutData).length <= 0) {
      history.goBack();
    }
  }, [hotelCheckOutData]);
  
  const [currencies, setCurrencies] = useState({ INR: 1 });

  useEffect(() => {
    ApiClient.get("admin/currencyConversionLatest")
      .then((resp) => {
      
        if (resp?.status == 200) {
          setCurrencies(resp.data);
        } else {
          
        }
      })
      .catch((e) => {
        
      });
  }, []);

 
  const navigateToTicket = ({ pgType = null, blockType = 1 }) => {
    let guestsList = hotelCheckOutData.guests.map((pax) => {
      return pax.paxInfoList.map((item) => {
        return item;
      });
    });

    let lastCancellationDate = "";
    let lastVoucherDate = "";
    let cancellationDates = [];
    let voucherDate = [];

    if (hotelCheckOutData?.hotelPriceData?.rooms?.length > 0) {
      cancellationDates = hotelCheckOutData?.hotelPriceData?.rooms?.map(
        (roomDates) => {
          return roomDates.ratePlans[0]?.lastCancellationDate;
        }
      );

      voucherDate = hotelCheckOutData?.hotelPriceData?.rooms?.map(
        (roomDates) => {
          return roomDates.ratePlans[0]?.lastVoucherDate;
        }
      );
    }

    if (cancellationDates?.length > 1) {
      lastCancellationDate = cancellationDates.reduce((prev, cur, i) => {
        if (prev && cur) {
          return moment(prev).isSameOrBefore(cur) ? prev : cur;
        } else if (prev) {
          return prev;
        } else {
          return cur;
        }
      });
    } else if (cancellationDates?.length === 1) {
      lastCancellationDate = cancellationDates[0] ? cancellationDates[0] : "";
    }

    if (voucherDate?.length > 1) {
      lastVoucherDate = voucherDate.reduce((prev, cur) => {
        if (prev && cur) {
          return moment(prev).isSameOrBefore(cur) ? prev : cur;
        } else if (prev) {
          return prev;
        } else return cur;
      });
    } else if (voucherDate?.length === 1) {
      lastVoucherDate = voucherDate[0] ? voucherDate[0] : "";
    }

    let allAmount = getHotelGrandTotal(
      hotelCheckOutData.hotelPriceData,
      hotelCheckOutData.hotelSearchData,
      ConvFee,
      promoData,
      selectedInsuranceData,
      redeemAmount
    );

    let guestsDetails = [];

    if (guestsList.length > 0) {
      guestsList.forEach((item) => {
        if (item && item?.length > 0) {
          item.forEach((guestInfo) => {
            guestsDetails.push(guestInfo);
          });
        }
      });
    }

    let commissionAmount = 0;
    let agentMarkupAmount = 0;
    let adminCommissionAmount = 0;
    if (user?.Role?.RoleId === 5) {
      commissionAmount = Number(allAmount.totalCommission);
      agentMarkupAmount = Number(allAmount.agentMarkup);
      adminCommissionAmount = Number(allAmount.adminCommission);
    }
    let data = {
      userId: user?.UserID ?? 1,
      roleType: user?.Role?.RoleId ?? 4,
      membership: user?.Membership ?? 1,
      traceId: hotelCheckOutData.hotelPriceData.traceId,
      guests: guestsDetails,
      addressInfo: hotelCheckOutData.addressInfo,
      arrivalTransport: hotelCheckOutData.arrivalTransport,
      departureTransport: hotelCheckOutData.departureTransport,
      flightInfo: {
        arrivingByFlight: "",
        flightName: "",
        flightNumber: "",
        hour: "",
        minutes: "",
        remarks: "",
        airlineName: "",
        airlinePnr: "",
      },
      docIdentification: {
        type: "",
        value: "",
      },

      creditCardInfo: {
        securityId: "",
        cardNumber: "",
        expirationMonth: "",
        expirationYear: "",
        firstName: "",
        lastName: "",
        billingAmount: "",
        billingCurrency: "",
        cardHolderAddress: {
          addressLine1: "",
          addressLine2: "strng",
          city: "",
          zipcode: "",
          countryCode: "",
        },
      },
      paymentModeType: "",
      
      promoCode: promoData.Code ?? "",
      convienenceId: ConvFee.id ?? 0,
      insuranceRequired: hotelCheckOutData?.insuranceRequired,
      totalPrice: Number(allAmount.totalAmount),
      currency: user?.Currency ? user?.Currency : activeCurrency || "INR",
      currencyRatio: user?.Role?.RoleId === 5 ? Number(currencies[user?.Currency]) : Number(currencyRatio),
     
      AdminMarkup: allAmount.markup,
      AgentMarkup: agentMarkupAmount,
      LastCancellationDate: lastCancellationDate ? lastCancellationDate : "",
      LastVoucherDate: lastVoucherDate ? lastVoucherDate : "",
      pgType: pgType ? pgType : pgDetails.pgType,
      bookingType: blockType,
      isCouponReedem: redeemAmount.status ?? false,
      supplier: hotelCheckOutData?.hotelSearchData?.supplier ?? "",
    };

    let paymentPaxInfo = {
      CustomerName: guestsList[0][0].firstName,
      CustomerEmail: hotelCheckOutData.addressInfo.email,
      CustomerPhone: hotelCheckOutData.addressInfo.phoneNo,
    };
   

    setLoadingSpin(true);
    setLoadSpin(true)
    ApiClient.post("hotels-v2/hotelblock", data)
      .then((result) => {
        return result;
      })
      .then((res) => {
        setPgIsLoading({
          direct: false,
          hold: false,
        });
        setLoadingSpin(false);
        setLoadSpin(false)
        
        if (res.status === 200) {
          if (blockType === 2) {
            history.push(`/hotel/ticket?ref=${res.data.RefNumber}`);
          } else if (res?.data?.payment_link?.pgType == 1) {
            ccavanueCheckoutData(res?.data?.payment_link)
            
          }
          else if (res?.data?.payment_link?.pgType == 2) {
         
            window.location.href = res?.data?.payment_link?.payment_link;
          } else {
            window.location.href = res?.data?.payment_link?.url;
           
          }
        } else {
          if (res?.message) message.error(res.message, 3);
          else if (res?.data[0]?.errorDetail)
            message.error(res?.data[0]?.errorDetail, 3);
          else message.error("Failed", 3);
        }
      })
      .catch((err) => {
        setPgIsLoading({
          direct: false,
          hold: false,
        });
        setLoadingSpin(false);
        setLoadSpin(false)
      });
  };

  const ccavanueCheckoutData = (resp) => {
    // console.log("comecc");
    setPgData(resp)
    setOpenCCavanueCheckout(true)
  }

  const CashFreeNewVersionCheckoutData = (resp) => {
    setPgData(resp)
    setOpenCashfreeNewVersionCheckout(true)
  }
  const handleVerifyOtp = (otpValue) => {
    ApiClient.post("admin/verifyserviceOtp", {
      Mobile: hotelCheckOutData?.addressInfo?.phoneNo,
      DialingCode: hotelCheckOutData?.addressInfo?.areaCode,
      Otp: Number(otpValue),
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          navigateToTicket({ pgType: pgDetails.pgType });
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const handleSendOTP = () => {
    ApiClient.post("admin/sendserviceOtp", {
      Mobile: hotelCheckOutData?.addressInfo?.phoneNo,
      DialingCode: hotelCheckOutData?.addressInfo?.areaCode,
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(true);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const ValidateBookingLimit = () => {
    let { totalAmount } = getHotelGrandTotal(
      hotelCheckOutData.hotelPriceData,
      hotelCheckOutData.hotelSearchData,
      ConvFee,
      promoData,
      selectedInsuranceData,
      redeemAmount
    );
    ApiClient.post("admin/validatebookinglimit", {
      serviceType: 2,
      roleType: user?.Role?.RoleId ?? 4,
      bookingAmount: totalAmount ? totalAmount : 0,
    })
      .then((res) => {
        if (res?.status === 200 && res?.isValid) {
          handleSendOTP();
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const blockApiReq = (pgType) => {
    dispatchPgDetails({ type: PGTYPE, payload: pgType });
    if (pgType) {
     
      navigateToTicket({ pgType: pgType });
     
    }
  };

  const processPayGateway = (blockType) => {
    setPgIsLoading({
      direct: blockType === 1 ? true : false,
      hold: blockType === 2 ? true : false,
    });
    if (blockType === 2) {
      navigateToTicket({ blockType: blockType, pgType: 1 });
    }
  };

  const timeout = () => {
    return (

      <div>

        <div className="timeout-popup-main">
          <div className="timeout-popup-main1">
            <img className="timeout-img" src={timeoutimg} alt="time" />
          </div>
          <div className="timeout-popup-main2">
            <h4 style={{ color: "red", }}><strong>SESSION TIMEOUT</strong></h4>
            <p className="popup-session-timeout-p-tag">Your Session is Expired</p>
            <p className="popup-session-timeout-p-tag">Click on "OK" to continue with New Search</p>
          </div>
        </div>

      </div>
    );
  }
  const handelCountdown = () => {

    //const query = queryString.stringify(searchhotelobj);
    let query = queryString.stringify(JSON.parse(localStorage.getItem('HotelSearchBar')));

    Modal.warning({
      icon: <></>,
      //title: "",
      content: timeout(),
      onOk() {
        history.push("/hotels/listing?" + query);

      },
    });
  };

  return (
    <div className="hotel-preview-container">
      <div className="checkout-heading">
        <div style={{ marginTop: "65px" }} fluid className="checkout-heading-container">
          <div className="goback">
            <Link
              onClick={() => {
                history.go(-1);
              }}
            >
              <ArrowLeftOutlined />
              <span>Go back and Update the Details</span>
            </Link>
          </div>
          <h3>Check out the form below and book your stay now!</h3>
        </div>
      </div>
      <div className="form-body ">
        <div className="form-body-container">
         
          {Object.keys(hotelCheckOutData).length > 0 ? (
            <Row gutter={[16, 16]}>
              <Col md={16} sm={24} xs={24}>
                
                <Card bordered={false} className="hotel-card-wrapper">
                  <HotelDetailsBox
                    hotelDetailsObj={hotelCheckOutData.hotelPriceData}
                    hotelSearchData={hotelCheckOutData.hotelSearchData}
                  />
                </Card>
                <div className="contact-header">
                  <div className="contact-title">
                    <p className="booking-summary-div">Guests Details</p>
                  </div>
                </div>

                <Card bordered={false} className="hotel-card-wrapper">
                  <HotelPassengers passengersInfo={hotelCheckOutData.guests} />
                </Card>
              </Col>
              <Col md={8} sm={24} xs={24}>
                <Card className="fare-card-hot">
                  <div className="hotel-price-wrapper">
                    <p className="hdng">Fare Details</p>
                    <HotelFairBox
                      hotelDetailsObj={hotelCheckOutData.hotelPriceData}
                      hotelSearchData={hotelCheckOutData.hotelSearchData}
                      isPromoVisible={false}
                    />
                  </div>
                  <HotelBookPayCard
                    isLoading={false}
                    pgIsLoading={pgIsLoading.direct}
                    holdLoading={pgIsLoading.hold}
                    purchaseType={
                      hotelCheckOutData?.hotelPriceData?.purchaseType
                    }
                    bookpaycardinfo={"hotel-review"}
                   
                    agent={agent}
                    blockApiReq={blockApiReq}
                    processPayGateway={processPayGateway}
                    loadingSpin={loadingSpin}
                    loadSpin={loadSpin}
                  />
                </Card>
              </Col>
            </Row>
          ) : (
            "No Hotel Selected "
          )}
        </div>
      </div>
      {verifyModalVisible && (
        <VerifyOtpModal
          visible={verifyModalVisible}
          setVerifyModalVisible={setVerifyModalVisible}
          handleVerifyOtp={handleVerifyOtp}
          handleResendOtp={handleSendOTP}
          mobile={hotelCheckOutData?.addressInfo?.phoneNo}
        />
      )}
      
      {
        openCCavanueCheckout && (
          <PaymentComponent
            pgData={pgData}

          />
        )
      }
    </div>
  );
};

export default HotelPreview;
