// import React, { createContext, useContext, useEffect, useState } from "react";
// import ApiClient from "../../helpers/ApiClient";
// import { useLocalStorage } from "../../helpers/useStorage";
// import { useAuthContext } from "./AuthProvider";

// const CurrencyContext = createContext();

// export function useCurrencyContext() {
//   return useContext(CurrencyContext);
// }

// const CurrencyProvider = ({ children }) => {
//   const {
//     isLogin: { agent },
//     user,
//   } = useAuthContext();


//   const initialCurrency =  'INR';
//   // console.log('Initial Currency:', initialCurrency);

//   const [activeCurrency, setActiveCurrency] = useLocalStorage(
//     'currency',
//     initialCurrency
//   );
//   // console.log('ActiveCurrency:', activeCurrency);


//   const [currencies, setCurrencies] = useState({ INR: 1 });

//   useEffect(() => {
//     ApiClient.get("admin/currencyConversionLatest")
//       .then((resp) => {
//         // {console.log(resp,"currency12")}
//         if (resp?.status == 200) {
//           setCurrencies(resp.data);
//         } else {
//           // if (window.location.search !== "?error=Currency%20Error")
//           //   window.location.href = "/error?error=Currency Error";
//         }
//       })
//       .catch((e) => {
//         // if (window.location.search !== "?error=Currency%20Error")
//         //   window.location.href = "/error?error=Currency Error";
//       });
//   }, []);

//   useEffect(() => {

//     if (agent) {
//       setActiveCurrency(user?.Currency);
//     }
//   }, [agent, user, setActiveCurrency]);

//   const getCurrencyValue = (amount, currency) => {
//     console.log(currency, amount, "curr");
//     let value;
//     {
//       currency != undefined ?
//         value = Number(parseFloat(amount) / parseFloat(currencies[currency])).toFixed(2) :
//         // console.log(Number(parseFloat(amount) / parseFloat(currencies[activeCurrency])).toFixed(2),"curr");
//         value = Number(parseFloat(amount) / parseFloat(currencies[activeCurrency])).toFixed(2)
//     }
//     // console.log(Number(parseFloat(amount) / parseFloat(currencies[activeCurrency]).toFixed(0)),"curvipaku");
//     if (value?.toString()?.split(".")?.[1] === "00") return Number(value)?.toFixed(0);
// console.log(value,"cuur-amnt");
//     // console.log(parseFloat(amount) / parseFloat(currencies[activeCurrency]),"current");
//     return Number(
//       parseFloat(amount) / parseFloat(currencies[activeCurrency])
//     ).toFixed(2);
//   };
//   return (
//     <CurrencyContext.Provider
//       value={{
//         activeCurrency: activeCurrency,
//         currencyValue: (a, b) => getCurrencyValue(a, b),
//         setActiveCurrency: (a) => setActiveCurrency(a),
//         currencyRatio: currencies[activeCurrency],
//       }}
//     >
//       {children}
//       {/* {console.log(setActiveCurrency,currencies,children,activeCurrency,"cu")} */}
//       {/* {console.log(children,"currency1")} */}
//     </CurrencyContext.Provider>
//   );
// };

// export default CurrencyProvider;

import React, { createContext, useContext, useEffect, useState } from "react";
import ApiClient from "../../helpers/ApiClient";
import { useLocalStorage } from "../../helpers/useStorage";
import { useAuthContext } from "./AuthProvider";
const CurrencyContext = createContext();

export function useCurrencyContext() {
  return useContext(CurrencyContext);
}

const CurrencyProvider = ({ children }) => {
  const [activeCurrency, setActiveCurrency] = useLocalStorage(
    "currency",
    "INR"
  );
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const [currencies, setCurrencies] = useState({ INR: 1 });

  useEffect(() => {
    ApiClient.get("admin/currencyConversionLatest")
      .then((resp) => {
        // { console.log(resp, "currency12") }
        if (resp?.status == 200) {
          setCurrencies(resp.data);
        } else {
          // if (window.location.search !== "?error=Currency%20Error")
          //   window.location.href = "/error?error=Currency Error";
        }
      })
      .catch((e) => {
        // if (window.location.search !== "?error=Currency%20Error")
        //   window.location.href = "/error?error=Currency Error";
      });
  }, []);

  const getCurrencyValue = (amount, currency) => {
    // console.log(currency, amount,c, "amnt");
    // console.log(activeCurrency, "amnt-1");
    if (currency != undefined) {
      // console.log("cmgggg");
      // setActiveCurrency("currency", currency);
      let value = Number(parseFloat(amount) / parseFloat(currencies[currency])).toFixed(2);
      if (value?.toString()?.split(".")?.[1] === "00") return Number(value)?.toFixed(0);
      return Number(
        value
      ).toFixed(2);
    }
    // console.log(Number(parseFloat(amount) / parseFloat(currencies[activeCurrency])).toFixed(2),"curr");
    let value1 = Number(parseFloat(amount) / parseFloat(currencies[activeCurrency])).toFixed(2);
    // console.log(Number(parseFloat(amount) / parseFloat(currencies[activeCurrency]).toFixed(0)),"curvipaku");
    if (value1?.toString()?.split(".")?.[1] === "00") return Number(value1)?.toFixed(0);


    // console.log(activeCurrency, "amnt-2");
    // console.log(parseFloat(amount) / parseFloat(currencies[activeCurrency]),"current");
    return Number(
      value1
    ).toFixed(2);
  };
  return (
    <CurrencyContext.Provider
      value={{
        activeCurrency: activeCurrency,
        currencyValue: (a, b) => getCurrencyValue(a, b),
        setActiveCurrency: (a) => setActiveCurrency(a),
        currencyRatio: user?.Role?.RoleId===3 ? currencies[user?.Currency] : currencies[activeCurrency],
      }}
    >
      {children}
      {/* {console.log(setActiveCurrency, currencies, children, activeCurrency, "cu")} */}
      {/* {console.log(children,"currency1")} */}
    </CurrencyContext.Provider>
  );
};

export default CurrencyProvider;
