import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Layout,
  Switch,
} from "antd";

import React, { useEffect, useState } from "react";
import ApiClient from "../../../src/helpers/ApiClient";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import UpdatedComponent from "../ExportExcel";
import "./Propertytype.scss";

const Propertytype = (props) => {
  const { Content } = Layout;

  const { TextArea } = Input;

  const [actHotelForm] = Form.useForm();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [nameList, setNameList] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [excelList, setExcelList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const [addMarkupModal, setAddMarkupModal] = useState(false);

  useEffect(() => {
    getPropertytype();
  }, []);

  const getPropertytype = () => {
    ApiClient.get("hotelsextranet/getpropertytype")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,

              ...item,
            };
          });
          let filteredNmaes = data?.filter(
            (item) => item.PropertyTypeId != 2 && item.PropertyTypeId != 5
          );
          setNameList(filteredNmaes);
          defineExcelData(filteredNmaes);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFinish = (val) => {
    let nameObj = {
      Status: 0,
      Name: val.Name,
      // Icon: "string",
      Description: val.Description,

      CreatedBy: 0,
      CreatedDate: new Date(),
      ModifiedBy: 0,
      ModifiedDate: new Date(),
    };

    isEdit ? UpdateHotelType(nameObj) : AddHoteltype(nameObj);
  };

  const UpdateHotelType = (nameObj) => {
    ApiClient.put(
      "hotelsextranet/updatepropertytypebyId/" + currentId,
      {},
      nameObj
    ).then((res) => {
      if (res.status == 200) {
        getPropertytype();
        message.success("Property Type Updated successfully", 3);
        setAddMarkupModal(false);
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  const AddHoteltype = (nameObjtest) => {
    ApiClient.post("hotelsextranet/createpropertytype", nameObjtest)
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.status == 200) {
          setAddMarkupModal(false);
          message.success("Successfully added name", 3);
          getPropertytype();
        } else if (response.status == 400) {
          message.error("Property Type Already existed", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        Name: item.Name,
        Description: item.Description,
        CreatedDate: item.CreatedDate,
        ModifiedDate: item.ModifiedDate,
        Status: item.status === 1 ? "Active" : "Inactive",
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = nameList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setNameList(searchResults);
    } else {
      getPropertytype();
      setNameList(nameList);
    }
  };

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    lastIndex++;
    return lastIndex;
  };
  const tableActions = (currentRecord) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord)}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteName(currentRecord.PropertyTypeId)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const editCurrentItem = (rec) => {
    setCurrentId(rec.PropertyTypeId);
    actHotelForm.setFieldsValue({
      Name: rec.Name,
      Description: rec.Description,
    });

    setIsEdit(true);
    setAddMarkupModal(true);
  };

  const deleteName = (id) => {
    ApiClient.delete("hotelsextranet/deletepropertytypebyId/" + id)
      .then((res) => {
        if (res.status == 200) {
          message.success("Amenities Deleted  successfully", 3);
          getPropertytype();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
    },

    {
      title: "Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },

    {
      title: "Description",
      dataIndex: "Description",
      sorter: (a, b) => a.Description.localeCompare(b.Description),
    },

    {
      title: "Status",
      dataIndex: "no",
      key: "airline",
      render: (text, order) => (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      ),
    },
    {
      title: "Actions",
      render: (rec) => tableActions(rec),
    },
  ];

  /***** Radio-btn*****/

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Property Type{" "}
                          <HelpInfoHelper screenName={"/admin/cartypes"} />
                        </h5>
                        <p>{nameList.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img
                        src={excel}
                        alt="excel"
                        onClick={(e) =>
                          props.exportExcel(excelList, "Property Type")
                        }
                      />
                      <p
                        className="add-deposit-icon"
                        onClick={(e) => {
                          if (isEdit) {
                            setIsEdit(false);
                          }

                          setAddMarkupModal(true);
                          actHotelForm.resetFields();
                        }}
                      >
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>

                  <div>
                    <Table
                      className="table-scroll-none"
                      bordered
                      dataSource={nameList}
                      rowKey={uniqueKey}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>{isEdit ? "Update" : "Add"} Property Type</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header hotel-extranet"
        visible={addMarkupModal}
        onCancel={(e) => setAddMarkupModal(false)}
        footer={null}
        width={"1100px"}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={actHotelForm.submit}
            >
              {isEdit ? "Update" : "Add"}
            </Button>
            <Button
              key="submit"
              type="danger"
              onClick={() => actHotelForm.resetFields()}
            >
              Clear
            </Button>
          </div>,
        ]}
      >
        <Form
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          form={actHotelForm}
        >
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label="Name"
                name="Name"
                rules={[{ required: true, message: "Please enter nmae" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                name="Description"
                label="Description"
                rules={[{ required: true }]}
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdatedComponent(Propertytype);
