import React, { useState } from "react";
// import hotelNoImage from "../../assets/images/hotels/no_img.png";
import fli8 from "../../assets/images/Icons/plane-ic.png"

const CustomFliFilterImg = ({ src,alt }) => {
  const [showImage, setShowImage] = useState(true);

  const [img, setimg] = useState(alt === 'IX1' ||alt === 'I51' || alt === 'YY' ? 'I5' : alt);

//   {console.log(src,"ima")}
  return (
    <img
      className="logo-fli"
      src={`http://www.gstatic.com/flights/airline_logos/70px/${img}.png`}
      alt="image"
      style={{
            width: 20,
            height: 20,
            borderRadius: 4,
          }}
      onError={() => setShowImage(false)}
    />
  );
};
export default CustomFliFilterImg;