import React, { useContext, useState, useEffect, useRef, } from "react";
import {
  Button,
 
  Row,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Select,
 
  Statistic,
 
} from "antd";
import moment from "moment/moment";
//import queryString from "query-string";
import timeoutimg from "../../../assets/images/timeout.jpg";
//import ApiClient from "../../../helpers/ApiClient";
import { Link, useHistory } from "react-router-dom";
import CountryList from "../../../common/CountryList";

import {
  ArrowLeftOutlined,
 
} from "@ant-design/icons";
import { GlobalStatesContext } from "../../../common/providers";
//import { useAuthContext } from "../../../common/providers/AuthProvider";
import { CarDetails, TransferDetails } from "./CarDetails";
import FareDetails from "./FareDetails";
import "./CarsCheckout.scss";
//import { getPassengerData } from "../../../helpers/PassegerData";
const { Option } = Select;
const disabledPastDate = (currentDate) => {
  return currentDate <= moment();
};
//const { Countdown } = Statistic;
const CarCheckout = ({ location }) => {
  let history = useHistory();
 // const [gstRequired, setGSTRequired] = useState(false);
  const [onlyPassengersDetails, setOnlyPassengersDetails] = useState({});
  const {
    state: { carBookingData, carSearchObj, sessiontimeout, status },
    updateCarPassengerData,
    RemovePromoConvFee,
    getsessiontimeout,
  } = useContext(GlobalStatesContext);
 
  const [form] = Form.useForm();
  const mobileNo = useRef();
 
  const mobileRef = useRef(null);
  const areaCodeRef = useRef(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");

  const updatePassengersData = () => {
    form
      .validateFields()
      .then((data) => {
        
        let carPaxArr = [];
        carPaxArr.push({
          name: data?.FirstName,
          lastName: data?.LastName,
          tittle: data.Title_,
          pickUpAddress: data.PickUpAddress_
            ? data.PickUpAddress_
            : carBookingData.pickUpLocation,
          droppingAddress: data.DroppingAddress_
            ? data.DroppingAddress_
            : carBookingData.dropLocation,
          additionalRequests: data.AdditionalRequests_ || "",

          emailId: data.emailId,
          mobileNo: data.mobileNo,
          countryCode: data.areaCode,
          panCard: data.PanDetails || "",
          gstDetails: {
            gstNumber: data.gstNumber,
            gstEmailId: data.gstEmailId,
            gstPhoneNo: data.gstPhoneNo,
            gstAddressLine1: data.gstAddressLine1,
            gstCompanyName: data.gstCompanyName,
          },
        });
        updateCarPassengerData(carPaxArr);
       
        history.push("/cars/review");
      })
      .catch((e) => { });
  };
  const handleAreaCodeChange = (value) => {
    
    setSelectedCountryCode(value);
    
    if (mobileRef.current) {
      mobileRef.current.focus();
    }
  };
  useEffect(() => {
    RemovePromoConvFee();
    getsessiontimeout();
  }, []);
 
  const timeout = () => {
    return (

      <div>

        <div className="timeout-popup-main">
          <div className="timeout-popup-main1">
            <img className="timeout-img" src={timeoutimg} alt="time" />
          </div>
          <div className="timeout-popup-main2">
            <h4 style={{ color: "red", }}><strong>SESSION TIMEOUT</strong></h4>
            <p className="popup-session-timeout-p-tag">Your Session is Expired</p>
            <p className="popup-session-timeout-p-tag">Click on "OK" to continue with New Search</p>
          </div>
        </div>

      </div>
    );
  };

 
  return (
    <div className="buscheckout_bg">
      <div className="checkout-heading">
        <div style={{ marginTop: "90px" }} fluid className="checkout-heading-container">
          <div className="goback">
            <Link
              onClick={() => {
                history.go(-1);
              }}
            >
              <ArrowLeftOutlined />
              <span>Go back and Select Other Car</span>
            </Link>
          </div>
          <h3>Fill Out the Form Below, and Book right now!</h3>
        </div>
      </div>
   
    
      <div className="buscheckout_wrapper">
        <Row gutter={[16, 16]}>
          <Col md={24} sm={24} xs={24}>
            <Form form={form} layout="vertical" scrollToFirstError={true}>
              <h3 className="title" style={{ color: "#bd0c21" }}>Car Details</h3>
              <Row gutter={[16, 16]}>
              
                <Col className="car-checkout-detail-part" >
                  <div className="left_wrapper">
                    {carBookingData.type === "transfer" ? (
                      <TransferDetails carBookingData={carBookingData} />
                    ) : (
                      <CarDetails carBookingData={carBookingData} />
                      

                    )}
                    {/*  */}
                  </div>
                  <div className="paxinfo_wrapper">
                    <h3 className="title" style={{ color: "#bd0c21" }}>Passenger Details</h3>
                    <Card>
                      <div className="paxcontact_wrapper mt-2">
                        <h3 className="title">Passenger</h3>
                        {carBookingData ? (
                          <div>
                            <Row gutter={[16, 16]} className="main-row-chec-page">
                              <Col md={4} sm={12} xs={24}>
                                <Form.Item
                                  name={"Title_"}
                                  label="Title"
                                  rules={[
                                    { required: true, message: "Required" },
                                  ]}
                                >
                                  <Select
                                    size="large"
                                    onSelect={(val) => {
                                      setOnlyPassengersDetails({
                                        ...onlyPassengersDetails,
                                        title: val,
                                      });
                                    }}
                                    placeholder="Title"
                                    className="inputbg"
                                  >
                                    <Option value="Mr">Mr</Option>
                                    <Option value="Ms">Ms</Option>
                                    <Option value="Mrs">Mrs</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col md={10} sm={12} xs={24}>
                                <Form.Item
                                  name="FirstName"
                                  label="First Name"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    className="inputbg"
                                    autoComplete="off"
                                    placeholder="Enter Your First Name"
                                    onChange={(e) =>
                                      form.setFieldsValue({
                                        [`FirstName`]: e.target.value.trimStart(),
                                      })
                                    }
                                    onBlur={(e) => {
                                      if (e.target.value.trim()) {
                                        setOnlyPassengersDetails({
                                          ...onlyPassengersDetails,
                                          name: e.target.value.trim(),
                                        });
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={10} sm={12} xs={24}>
                                <Form.Item
                                  name="LastName"
                                  label="Last Name"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                    className="inputbg"
                                    autoComplete="off"
                                    placeholder="Enter your Last Name"
                                    size="large"
                                    onChange={(e) =>
                                      form.setFieldsValue({
                                        [`LastName`]: e.target.value.trimStart(),
                                      })
                                    }
                                    onBlur={(e) => {
                                      if (e.target.value.trim()) {
                                        setOnlyPassengersDetails({
                                          ...onlyPassengersDetails,
                                          name: e.target.value.trim(),
                                        });
                                      }
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col className="car-mobile col-main-check-page" md={8} sm={12} xs={24}>
                                <Form.Item

                                  label="Phone number"
                                  name="mobileNo"
                                  className="phno"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                    
                                  ]}
                                >
                                  <Input
                                    ref={mobileRef}
                                    addonBefore={
                                      <Form.Item
                                        style={{ width: "30px" }}
                                        name="areaCode"
                                        className="area"
                                        rules={[
                                          {
                                            required: true,
                                            message: "",
                                          },
                                        ]}
                                        placeholder="Select ISD"
                                        noStyle
                                      >
                                        <Select

                                          showSearch
                                          showArrow={false}
                                          placeholder="Select ISD"
                                          style={{ width: "auto" }}
                                          onChange={handleAreaCodeChange}
                                          ref={areaCodeRef}
                                          focusRef={mobileNo}
                                          filterOption={(input, option) =>
                                            option.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                        >
                                          {CountryList.map((item) => (
                                            <Option
                                              className="car-opt"
                                              
                                              key={item.dial_code}
                                              value={item.dial_code}
                                            >
                                              {item.dial_code}
                                            </Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                    }
                                    className="inputbg"
                                    placeholder="Enter Mobile Number"
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    size="large"
                                    width={"70px"}
                                  />
                                
                                </Form.Item>
                              </Col>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Email"
                                  name="emailId"
                                  rules={[
                                    { required: true, message: "Required" },
                                    { type: "email", message: "Invalid Email" },
                                  ]}
                                >
                                  <Input placeholder="Enter Email-Id" size="large" className="inputbg" />
                                </Form.Item>
                              </Col>
                              
                              {carBookingData?.IsPANMandatory &&
                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    name="PanDetails"
                                    label="Pan Number"
                                    rules={[
                                      {
                                        required: true,
                                        //message: "Required" 
                                      },
                                      {
                                        pattern:
                                          "^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$",
                                        message:
                                          "Please Enter A Valid PAN No",
                                      },
                                    ]}
                                  >
                                    <Input
                                      onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                                      size="large" placeholder="Enter PAN Number" className="inputbg" />
                                  </Form.Item>
                                </Col>
                              }


                            </Row>
                          </div>
                        ) : null}
                      </div>

                    </Card>
                  </div>
                 
                  
                </Col>

                <Col className="car-checkout-price-part">
                  <Card>
                    <div className="right_wrapper">
                      <h3 className="title1"> Fare Details</h3>
                      <FareDetails
                        selectedCarData={carBookingData}
                        isPromoVisible={true}
                        location={location}
                      />
                    </div>
                  </Card>
                </Col>

                <Col style={{ textAlign: "center" }} md={17} sm={16} xs={24}>

                  <Form.Item
                    name="agreement"
                    className="chk-boc-car"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject("Should accept agreement"),
                      },
                    ]}
                  >
                    <Checkbox>
                      I Agree To All The{" "}
                      <a
                        href="/termsofconditions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {"  Terms & Conditions"}
                      </a>{" "}
                      and{" "}
                      <a
                        href="/privacypolicy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        Privacy Policy.
                      </a>
                    </Checkbox>
                  </Form.Item>

                  <div className="btn_wrapper">
                    <Button
                      type="primary"
                      onClick={() => {
                        updatePassengersData();
                      }}
                      style={{ backgroundColor: '#bd0c21', borderRadius: '4px', border: '#bd0c21' }}
                    >
                      Continue to Payment
                    </Button>
                  </div>

                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default CarCheckout;
