import {
  AccountBookOutlined,
  ApiOutlined,
  AuditOutlined,
  BankOutlined,
  BlockOutlined,
  BuildOutlined,
  CalendarOutlined,
  CarOutlined,
  CloseSquareOutlined,
  ContainerOutlined,
  CreditCardOutlined,
  DisconnectOutlined,
  DollarOutlined,
  FileDoneOutlined,
  FilePdfOutlined,
  FileSearchOutlined,
  FileTextOutlined,
  FormOutlined,
  HighlightOutlined,
  InsertRowRightOutlined,
  InteractionOutlined,
  IssuesCloseOutlined,
  LayoutOutlined,
  PushpinOutlined,
  RocketOutlined,
  SecurityScanOutlined,
  SelectOutlined,
  SendOutlined,
  SettingOutlined,
  ShopOutlined,
  SplitCellsOutlined,
  SwitcherOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  UserSwitchOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";

export const SubMenus = [
  {
    submenukey: "sub1",
    icon: <UserOutlined />,
    title: "Masters",
    className: "left-menu-decre",
    children: [
      {
        key: "/admin/role",
        icon: <UserOutlined />,
        title: "Roles",
      },
      {
        key: "/admin/user",
        icon: <TeamOutlined />,
        title: "Admin Users",
      },
      {
        key: "/admin/accesspermissions",
        icon: <UserSwitchOutlined />,
        title: "Access Permissions",
      },
      {
        key: "/admin/agentregistration",
        icon: <UserOutlined />,
        title: "Site Agents",
      },
      {
        key: "/admin/siteusers",
        icon: <UserOutlined />,
        title: "Site Users",
      },

      // {
      //   key: "/admin/manageairlines",
      //   icon: <SendOutlined />,
      //   title: "Manage Airlines",
      // },
      {
        key: "/admin/operator",
        icon: <UsergroupAddOutlined />,
        title: "Operators",
      },
      {
        key: "/admin/membershipinfo",
        icon: <UserSwitchOutlined />,
        title: "Membershipinfo",
      },
      {
        key: "/admin/provider",
        icon: <AuditOutlined />,
        title: "Providers",
      },
      {
        key: "/admin/apiprovider",
        icon: <ApiOutlined />,
        title: "API Providers",
      },

      {
        key: "/admin/set-commission",
        icon: <UsergroupAddOutlined />,
        title: "Set Commission",
      },
      {
        key: "/admin/margins",
        icon: <UsergroupAddOutlined />,
        title: "Margins",
      },
      {
        key: "/admin/screens",
        icon: <UsergroupAddOutlined />,
        title: "Screens",
      },
      {
        key: "/admin/CmsFareRules",
        icon: <UsergroupAddOutlined />,
        title: "Cms Fare Rules",
      },
      // {
      //   key: "/admin/cmstype",
      //   icon: <AuditOutlined />,
      //   title: "Cms Type",
      // },
      // {
      //   key: "/admin/BookingLimit",
      //   icon: <AuditOutlined />,
      //   title: "Booking Limit",
      // },
      {
        key: "/admin/serviceType",
        icon: <SettingOutlined />,
        title: "Service Type",
      },
      // {
      //   key:"/admin/sessiontime",
      //   icon:<UserOutlined />,
      //   title:"Screen Time-Out",
      // },
      // {
      //   key: "/admin/configurations",
      //   icon: <SettingOutlined />,
      //   title: "Configurations",
      // },
    ],
  },
  {
    submenukey: "sub29",
    icon: <UserOutlined />,
    title: "Marketing",
    className: "left-menu-decre",
    children: [
      {
        key: "/admin/banners",
        icon: <SwitcherOutlined />,
        title: "Banners",
      },
      {
        key: "/admin/promo",
        icon: <DisconnectOutlined />,
        title: "Promo Code",
      },
      {
        key: "/admin/cms",
        icon: <SecurityScanOutlined />,
        title: "CMS Screens",
      },
      {
        key: "/admin/cityhotel",
        icon: <LayoutOutlined />,
        title: "City Hotel",
      },
      // {
      //   key: "/admin/deals",
      //   icon: <ContainerOutlined />,
      //   title: "Deals",
      // },
      {
        key: "/admin/dealimages",
        icon: <ContainerOutlined />,
        title: "Deals",
      },
      // {
      //   key: "/admin/blog",
      //   icon: <BlockOutlined />,
      //   title: "Blog",
      // },
      // {
      //   key: "/admin/commentsblog",
      //   icon: <BlockOutlined />,
      //   title: "Blog Comments",
      // },
      // {
      //   key: "/admin/blogCategories",
      //   icon: <BlockOutlined />,
      //   title: "Blog Category",
      // },
      // {
      //   key: "/admin/crm",
      //   icon: <AuditOutlined />,
      //   title: "CRM",
      // },
      {
        key: "/admin/HelpInfo",
        icon: <AuditOutlined />,
        title: "Help Info",
      },
      {
        key: "/admin/topCities",
        icon: <AuditOutlined />,
        title: "Top Cities",
      },
     
    ],
  },
  
  {
    submenukey: "sub4",
    icon: <DollarOutlined />,
    title: "Accounts",

    children: [
      {
        key: "/admin/ledger-statement",
        icon: <FileDoneOutlined />,
        title: "Ledger Statement",
      },
      {
        key: "/admin/b2b",
        icon: <SplitCellsOutlined />,
        title: "B2B Pg",
      },
      // {
      //   key: "/admin/coupons",
      //   icon: <DisconnectOutlined />,
      //   title: "Coupons",
      // },
      // {
      //   key: "/admin/partpayment",
      //   icon: <DisconnectOutlined />,
      //   title: "PartPayment",
      // },
      {
        key: "/admin/managebalance",
        icon: <AccountBookOutlined />,
        title: "Manage Balance",
      },
      {
        key: "/admin/bankdetails",
        icon: <BankOutlined />,
        title: "Bank Details",
      },
    ],
  },
  // {
  //   submenukey: "sub11",
  //   icon: <UserOutlined />,
  //   title: "Packages",
  //   className: "left-menu-decre",

  //   children: [
  //     {
  //       key: "/admin/viewpackages",
  //       icon: <SettingOutlined />,
  //       title: "Packages",
  //     },
  //   ],
  // },
  {
    submenukey: "sub3",
    icon: <AuditOutlined />,
    title: "Commissions",
    className: "left-menu-decre",

    children: [
      // {
      //   key: "/admin/activitiescommissions",
      //   // icon: <SendOutlined />,
      //   title: "Activities Commission",
      // },
      {
        key: "/admin/flightcommissions",
        // icon: <SendOutlined />,
        title: "Flight Commission",
      },
      {
        key: "/admin/hotelcommissions",
        // icon: <BuildOutlined />,
        title: "Hotel Commission",
      },
      // {
      //   key: "/admin/buscommissions",
      //   // icon: <SelectOutlined />,
      //   title: "Bus Commission",
      // },
      {
        key: "/admin/TranfersCommissions",
        // icon: <SelectOutlined />,
        title: "Transfers Commissions",
      },
      // {
      //   key: "/admin/visacommissions",
      //   // icon: <SelectOutlined />,
      //   title: "Visa Commission",
      // },
      // {
      //   key: "/admin/cruisecommissions",
      //   // icon: <SelectOutlined />,
      //   title: "Cruise Commission",
      // },
      // {
      //   key: "/admin/holidaycommissions",
      //   // icon: <SelectOutlined />,
      //   title: "Holiday Commission",
      // },

      // {
      //   key: "/admin/cflightscommissions",
      //   // icon: <SelectOutlined />,
      //   title: "Charter Flights  Commission",
      // },
      // {
      //   key: "/admin/rbcommissions",
      //   // icon: <SelectOutlined />,
      //   title: "R/B Commission",
      // },
    ],
  },
  {
    submenukey: "sub5",
    icon: <FilePdfOutlined />,
    title: "Ticketing",
    className: "left-menu-decre",

    children: [
      {
        key: "/admin/travelcalendar",
        icon: <CalendarOutlined />,
        title: "Travel Calendar",
      },
      // {
      //   key: "/admin/bookoffline",
      //   icon: <IssuesCloseOutlined />,
      //   title: "Book Offline",
      // },
      {
        key: "/admin/cancelrequest",
        icon: <CloseSquareOutlined />,
        title: "Cancel Requests",
      },
      {
        key: "/admin/reshedule-request",
        icon: <CloseSquareOutlined />,
        title: "Reshedule Requests",
      },
    ],
  },
  {
    submenukey: "sub6",
    icon: <FileSearchOutlined />,
    title: "Reports",
    className: "left-menu-decre",

    children: [
      {
        key: "/admin/reports",
        icon: <FileTextOutlined />,
        title: "All Reports",
      },
      {
        key: "/admin/reports/flightReport",
        icon: <FileTextOutlined />,
        title: "Flight Reports",
      },
      {
        key: "/admin/reports/hotelReport",
        icon: <FileTextOutlined />,
        title: "Hotel Reports",
      },
      {
        key: "/admin/reports/transferReports",
        icon: <FileTextOutlined />,
        title: "Transfer Reports",
      },
      // {
      //   key: "/admin/reports/busReport",
      //   icon: <FileTextOutlined />,
      //   title: "Bus Reports",
      // },
      // {
      //   key: "/admin/reports/activityReport",
      //   icon: <FileTextOutlined />,
      //   title: "Activity Reports",
      // },
      // {
      //   key: "/admin/r&b-orders",
      //   icon: <SettingOutlined />,
      //   title: "R&B Reports",
      // },

      // {
      //   key: "/admin/storevistors",
      //   icon: <SettingOutlined />,
      //   title: "Store Vistors",
      // },
      {
        key: "/admin/contactforms",
        icon: <FormOutlined />,
        title: "Contact Forms",
      },
      // {
      //   key: "/admin/flightenquiries",
      //   icon: <ThunderboltOutlined />,
      //   title: "Flight Enquiries",
      // },
      // {
      //   key: "/admin/helicopterenquiries",
      //   icon: <ThunderboltOutlined />,
      //   title: "Helicopter Enquiries",
      // },
      {
        key: "/admin/offlineEnquiries",
        icon: <ThunderboltOutlined />,
        title: "Offline Enquiries",
      },
      {
        key: "/admin/subscriberReports",
        icon: <UserOutlined />,
        title: "Subscriber Report",
      },
      // {
      //   key: "/admin/insurancereports",
      //   icon: <UserOutlined />,
      //   title: "Insurance Reports",
      // },
      // {
      //   key: "/admin/reviews-rating",
      //   icon: <UserOutlined />,
      //   title: "Review & Rating/Photos",
      // },
      // {
      //   key: "/admin/hotelFITBooking",
      //   icon: <UserOutlined />,
      //   title: "Hotel FIT  Booking",
      // },
      // {
      //   key: "/admin/hotelGroupBooking",
      //   icon: <UserOutlined />,
      //   title: "Hotel Group Booking",
      // },
      // {
      //   key: "/admin/newsletter-subscribers",
      //   icon: <SettingOutlined />,
      //   title: "News Letter",
      // },
    ],
  },
  // {
  //   submenukey: "credit",
  //   icon: <PushpinOutlined />,
  //   title: "Credit Notes",
  //   className: "left-menu-decre",

  //   children: [
  //     {
  //       key: "/admin/flightcreditnote",

  //       title: "Flight Credit Note",
  //     },
  //     {
  //       key: "/admin/buscreditnote",

  //       title: "Bus Credit Note",
  //     },
  //     {
  //       key: "/admin/hotelcreditnote",

  //       title: "Hotel Credit Note",
  //     },
  //     {
  //       key: "/admin/activitycreditnote",

  //       title: "Activity Credit Note",
  //     },
  //     {
  //       key: "/admin/packagecreditnote",

  //       title: "Package Credit Note",
  //     },
  //     {
  //       key: "/admin/holidaycreditnote",

  //       title: "Holiday Credit Note",
  //     },
  //     {
  //       key: "/admin/visacreditnote",

  //       title: "Visa Credit Note",
  //     },
  //     {
  //       key: "/admin/carscreditnote",

  //       title: "Cars Credit Note",
  //     },
  //     {
  //       key: "/admin/cruisecreditnote",

  //       title: "Cruise Credit Note",
  //     },
  //     {
  //       key: "/admin/cflightcreditnote",

  //       title: "Charter Flight Credit Note",
  //     },
  //     {
  //       key: "/admin/rbcreditnote",

  //       title: "R/B Credit Note",
  //     },
  //   ],
  // },
  {
    submenukey: "sub7",
    icon: <UserOutlined />,
    title: "Logs",
    className: "left-menu-decre",

    children: [
      {
        key: "/admin/pglogs",
        icon: <ApiOutlined />,
        title: "PG Log",
      },
      {
        key: "/admin/apilogs",
        icon: <ApiOutlined />,
        title: "API Log",
      },
      // {
      //   // key: "/admin/subscribers",
      //   key: "/admin/apilogs",
      //   icon: <ApiOutlined />,
      //   title: "News Letter Subscribed",
      // },
    ],
  },
  {
    submenukey: "sub8",
    icon: <SettingOutlined />,
    title: "Settings",
    className: "left-menu-decre",

    children: [
      {
        key: "/admin/settings",
        icon: <SettingOutlined />,
        title: "Settings",
      },
    ],
  },
  {
    submenukey: "sub13",
    icon: <CarOutlined />,
    title: "Car Extranet",
    className: "left-menu-decre",

    children: [
      {
        key: "/admin/cartypes",
        icon: <SettingOutlined />,
        title: "Car Types",
      },
      {
        key: "/admin/carcancellation",
        icon: <SettingOutlined />,
        title: "Car Cancellation",
      },
      {
        key: "/admin/driverinfo",
        icon: <SettingOutlined />,
        title: "DriverInfo",
      },
      {
        key: "/admin/carsmapping",
        icon: <SettingOutlined />,
        title: "Cars Mapping",
      },
      {
        key: "/admin/carsavailability",
        icon: <SettingOutlined />,
        title: "Cars Availability",
      },
      {
        key: "/admin/carsorder",
        icon: <SettingOutlined />,
        title: "Cars Reports",
      },
      {
        key: "/admin/carDeals",
        icon: <SettingOutlined />,
        title: "Cars Deals",
      },
    ],
  },
  // {
  //   submenukey: "sub14",
  //   icon: <PushpinOutlined />,
  //   title: "Bus Extranet",
  //   className: "left-menu-decre",

  //   children: [
  //     {
  //       key: "/admin/managebus",
  //       icon: <SettingOutlined />,
  //       title: "Manage Bus",
  //     },
  //   ],
  // },
  {
    submenukey: "sub17",
    icon: <CreditCardOutlined />,
    title: "Visa Extranet",
    className: "left-menu-decre",

    children: [
      {
        key: "/admin/visaDocs",
        icon: <SettingOutlined />,
        title: "VisaEssential Documents",
      },
      {
        key: "/admin/managevisa",
        icon: <SettingOutlined />,
        title: "ManageVisa Master",
      },
      {
        key: "/admin/visaType",
        icon: <SettingOutlined />,
        title: "Manage Visa Type",
      },
      {
        key: "/admin/visaGuide",
        icon: <SettingOutlined />,
        title: "Visa Guide",
      },
      {
        key: "/admin/docsReqdVisa",
        icon: <SettingOutlined />,
        title: "Documents required for Visa",
      },
      {
        key: "/admin/visafaqs",
        icon: <SettingOutlined />,
        title: "Visa FAQs",
      },
      {
        key: "/admin/embassyDetails",
        icon: <SettingOutlined />,
        title: "Embassy Details",
      },
      {
        key: "/admin/visa-customer-reports",
        icon: <SettingOutlined />,
        title: "Visa Reports",
      },
      // {
      //   key: "/visa/getVisaBookignReportsByFilter",
      //   icon: <SettingOutlined />,
      //   title: "Visa Reports",
      // },
    ],
  },

  // {
  //   submenukey: "sub15",
  //   icon: <InteractionOutlined />,
  //   title: "Tours Extranet",
  //   className: "left-menu-decre",

  //   children: [
  //     {
  //       key: "/admin/toursextranet-category",
  //       icon: <ShopOutlined />,
  //       title: "Category",
  //     },
  //     {
  //       key: "/admin/toursextranet-subcategory",
  //       icon: <ShopOutlined />,
  //       title: "Sub Category",
  //     },
  //     {
  //       key: "/admin/toursextranet-media",
  //       icon: <ShopOutlined />,
  //       title: "Media",
  //     },
  //     {
  //       key: "/admin/toursextranet-orders",
  //       icon: <ShopOutlined />,
  //       title: "Orders",
  //     },
  //     {
  //       key: "/admin/toursextranet-packages",
  //       icon: <ShopOutlined />,
  //       title: "Packages",
  //     },
  //     {
  //       key: "/admin/toursextranet-cms",
  //       icon: <SettingOutlined />,
  //       title: "CMS",
  //     },
  //     {
  //       key: "/admin/toursextranet-price",
  //       icon: <SettingOutlined />,
  //       title: "Price",
  //     },
  //     {
  //       key: "/admin/toursextranet-deals",
  //       icon: <SettingOutlined />,
  //       title: "Deals",
  //     },
  //     {
  //       key: "/admin/toursextranet-overview",
  //       icon: <SettingOutlined />,
  //       title: "Overview",
  //     },
  //     {
  //       key: "/admin/toursextranet-cancelpolicy",
  //       icon: <SettingOutlined />,
  //       title: "Cancelation Policy",
  //     },
  //     {
  //       key: "/admin/toursextranet-cancelrequest",
  //       icon: <SettingOutlined />,
  //       title: "Cancel Request",
  //     },
  //     {
  //       key: "/admin/toursextranet-reschedulerequest",
  //       icon: <SettingOutlined />,
  //       title: "Reschedule Request",
  //     },
  //   ],
  // },
  // {
  //   submenukey: "sub16",
  //   icon: <InsertRowRightOutlined />,
  //   title: "Hotel Extranet",
  //   className: "left-menu-decre",

  //   children: [
  //     {
  //       key: "/admin/hotelfacilities",
  //       icon: <SettingOutlined />,
  //       title: "Hotel Facilities",
  //     },
  //     {
  //       key: "/admin/hotelextranetinclusions",
  //       icon: <InsertRowRightOutlined />,
  //       title: "Hotel Inclusions",
  //     },
  //     {
  //       key: "/admin/hotelroomtypes",
  //       icon: <InsertRowRightOutlined />,
  //       title: "Hotel Room Types",
  //     },
  //     {
  //       key: "/admin/Hotelamenities",
  //       icon: <InsertRowRightOutlined />,
  //       title: "Hotel Amenities",
  //     },
  //     {
  //       key: "/admin/Hotelimagetypes",
  //       icon: <InsertRowRightOutlined />,
  //       title: "Hotel Image Types",
  //     },
  //     {
  //       key: "/admin/propertytype",
  //       icon: <InsertRowRightOutlined />,
  //       title: "Property Type",
  //     },
  //     {
  //       key: "/admin/aroundthishotel",
  //       icon: <InsertRowRightOutlined />,
  //       title: "Around This Hotel",
  //     },
  //     {
  //       key: "/admin/bedtype",
  //       icon: <InsertRowRightOutlined />,
  //       title: "Bed Type",
  //     },
  //     {
  //       key: "/admin/managehotel",
  //       icon: <InsertRowRightOutlined />,
  //       title: "Manage Hotels",
  //     },
  //     {
  //       key: "/admin/managerooms",
  //       icon: <InsertRowRightOutlined />,
  //       title: "Manage Rooms",
  //     },
  //     {
  //       key: "/admin/managepolicies",
  //       icon: <InsertRowRightOutlined />,
  //       title: "Manage Policies",
  //     },
  //     {
  //       key: "/admin/managearoundhotel",
  //       icon: <InsertRowRightOutlined />,
  //       title: "Manage Around Hotel",
  //     },
  //     {
  //       key: "/admin/manageimages",
  //       icon: <InsertRowRightOutlined />,
  //       title: "Manage Media",
  //     },
  //     {
  //       key: "/admin/managevideos",
  //       icon: <InsertRowRightOutlined />,
  //       title: "Manage Videos",
  //     },
  //     {
  //       key: "/admin/hotelextranetbookings",
  //       icon: <InsertRowRightOutlined />,
  //       title: "Manage Bookings",
  //     },
  //   ],
  // },
  // {
  //   submenukey: "sub25",
  //   icon: <HighlightOutlined />,
  //   title: "Cruise Extranet",
  //   className: "left-menu-decre",

  //   children: [
  //     // {
  //     //   key: "/admin/cruiseextranet-type",
  //     //   icon: <SettingOutlined />,
  //     //   title: "Cruise Type",
  //     // },
  //     {
  //       key: "/admin/cruiseextranet-package",
  //       // icon: <SettingOutlined />,
  //       title: "Package",
  //     },
  //     {
  //       key: "/admin/cruiseextranet-sailingdate",
  //       // icon: <SettingOutlined />,
  //       title: "Sailing Date",
  //     },
  //     {
  //       key: "/admin/cruiseextranet-media",
  //       // icon: <SettingOutlined />,
  //       title: "Media",
  //     },
  //     {
  //       key: "/admin/cruiseextranet-schedule",
  //       // icon: <SettingOutlined />,
  //       title: "Schedule",
  //     },
  //     {
  //       key: "/admin/cruiseextranet-rooms",
  //       // icon: <SettingOutlined />,
  //       title: "Rooms",
  //     },
  //     {
  //       key: "/admin/cruiseextranet-features",
  //       // icon: <SettingOutlined />,
  //       title: "Features",
  //     },
  //     {
  //       key: "/admin/cruiseextranet-cms",
  //       // icon: <SettingOutlined />,
  //       title: "Cms",
  //     },
  //     {
  //       key: "/admin/cruiseextranet-faqs",
  //       // icon: <SettingOutlined />,
  //       title: "Faqs",
  //     },
  //     {
  //       key: "/admin/cruiseextranet-orders",
  //       // icon: <SettingOutlined />,
  //       title: "Orders",
  //     },
  //     {
  //       key: "/admin/cruiseextranet-cancelpolicy",
  //       // icon: <SettingOutlined />,
  //       title: "Cancelation Policy",
  //     },
  //     {
  //       key: "/admin/cruiseextranet-cancelrequest",

  //       title: "Cancel Request",
  //     },
  //     {
  //       key: "/admin/cruiseextranet-reschedulerequest",

  //       title: "Reschedule Request",
  //     },
  //   ],
  // },

  {
    submenukey: "sub26",
    icon: <PushpinOutlined />,
    title: "Flight Extranet",
    className: "left-menu-decre",

    children: [
      {
        key: "/admin/fExtranet",
        icon: <FileTextOutlined />,
        title: "Flight Extranet",
      },
    ],
  },

  // {
  //   submenukey: "sub9",
  //   icon: <ShopOutlined />,
  //   title: "Activity Extranet",
  //   className: "left-menu-decre",
  //   children: [
  //     {
  //       key: "/admin/activities",
  //       icon: <ShopOutlined />,
  //       title: "Activities",
  //     },
  //     {
  //       key: "/admin/activitiesoption",
  //       icon: <ShopOutlined />,
  //       title: "Activities Options",
  //     },
  //     {
  //       key: "/admin/activitytourrate",
  //       icon: <ShopOutlined />,
  //       title: "Activities Tour Rate",
  //     },
  //     {
  //       key: "/admin/activitytimeslot",
  //       icon: <ShopOutlined />,
  //       title: "Activities Time Slot",
  //     },
  //     {
  //       key: "/admin/activitiescategory",
  //       icon: <ShopOutlined />,
  //       title: "Activities Category",
  //     },
  //   ],
  // },

  // {
  //   submenukey: "sub23",
  //   icon: <SendOutlined />,
  //   title: "CharterFlights Extranet",
  //   className: "left-menu-decre",
  //   children: [
  //     {
  //       key: "/admin/fleetMaster",
  //       icon: <SettingOutlined />,
  //       title: "Fleet Master",
  //     },
  //     {
  //       key: "/admin/charterflights",
  //       icon: <SettingOutlined />,
  //       title: "Charter Flights",
  //     },
  //     {
  //       key: "/admin/charterFlightFaq",
  //       icon: <SettingOutlined />,
  //       title: "Charter Flights FAQ's",
  //     },

  //     {
  //       key: "/admin/charter-orders",
  //       icon: <SettingOutlined />,
  //       title: "Charter Flights Orders",
  //     },
  //     {
  //       key: "/admin/charter-cancelpolicy",
  //       icon: <SettingOutlined />,
  //       title: "Cancelation Policy",
  //     },
  //     {
  //       key: "/admin/charter-cancelrequest",
  //       icon: <SettingOutlined />,
  //       title: "Cancel Request",
  //     },
  //     {
  //       key: "/admin/charter-reschedulerequest",
  //       icon: <SettingOutlined />,
  //       title: "Reschedule Request",
  //     },
  //   ],
  // },
  // {
  //   submenukey: "sub24",
  //   icon: <PushpinOutlined />,
  //   title: "Corporate Extranet",
  //   className: "left-menu-decre",
  //   isnestedsubkey: 1,
  //   children: [
  //     {
  //       submenukey: "sub20",
  //       icon: <PushpinOutlined />,
  //       title: "Manage Company",
  //       className: "individual-menu",
  //       children: [
  //         {
  //           key: "/admin/managecompany",
  //           icon: <SettingOutlined />,
  //           title: "Manage Company",
  //         },
  //         {
  //           key: "/admin/managebranch",
  //           icon: <SettingOutlined />,
  //           title: "Manage Branch",
  //         },
  //         {
  //           key: "/admin/gstdetails",
  //           icon: <SettingOutlined />,
  //           title: "Gst Details",
  //         },
  //       ],
  //     },
  //     {
  //       submenukey: "sub19",
  //       icon: <PushpinOutlined />,
  //       title: "Masters",
  //       className: "individual-menu",
  //       children: [
  //         {
  //           key: "/admin/managedepartment",
  //           icon: <SettingOutlined />,
  //           title: "Manage Departments",
  //         },
  //         {
  //           key: "/admin/manageteams",
  //           icon: <SettingOutlined />,
  //           title: "Manage Teams",
  //         },
  //         {
  //           key: "/admin/managegrades",
  //           icon: <SettingOutlined />,
  //           title: "Manage Grades",
  //         },
  //         {
  //           key: "/admin/managedesignations",
  //           icon: <SettingOutlined />,
  //           title: "Manage Designation",
  //         },
  //       ],
  //     },
  //     {
  //       submenukey: "sub27",
  //       icon: <PushpinOutlined />,
  //       title: "Travel Management",
  //       className: "individual-menu",
  //       children: [
  //         {
  //           key: "/admin/travelreports",
  //           icon: <SettingOutlined />,
  //           title: "Travel Reports",
  //         },
  //         {
  //           key: "/admin/travelinvoices",
  //           icon: <SettingOutlined />,
  //           title: "Travel Invoices",
  //         },
  //         {
  //           key: "/admin/travelpolicy",
  //           icon: <SettingOutlined />,
  //           title: "Travel Policy",
  //         },
  //         {
  //           key: "/admin/tagatrip",
  //           icon: <SettingOutlined />,
  //           title: "Tag a Trip",
  //         },
  //         {
  //           key: "/admin/domesticflightspolicy",
  //           icon: <SettingOutlined />,
  //           title: " Domestic Flight Policy",
  //         },
  //         {
  //           key: "/admin/domestichotelpolicy",
  //           icon: <SettingOutlined />,
  //           title: "Domestic Hotel Policy",
  //         },
  //         {
  //           key: "/admin/airportcabpolicy",
  //           icon: <SettingOutlined />,
  //           title: "Airport Cab Policy",
  //         },
  //         {
  //           key: "/admin/outstationcabpolicy",
  //           icon: <SettingOutlined />,
  //           title: "Outstation Cab Policy",
  //         },
  //         {
  //           key: "/admin/internationalflightpolicy",
  //           icon: <SettingOutlined />,
  //           title: "International Flight Policy",
  //         },
  //         {
  //           key: "/admin/internationalhotelpolicy",
  //           icon: <SettingOutlined />,
  //           title: "International Hotel Policy",
  //         },
  //         {
  //           key: "/admin/buspolicy",
  //           icon: <SettingOutlined />,
  //           title: "Bus Policy",
  //         },
  //         {
  //           key: "/admin/corporateAddons",
  //           icon: <SettingOutlined />,
  //           title: "Corporate Addons",
  //         },
  //       ],
  //     },
  //     {
  //       submenukey: "sub28",
  //       icon: <PushpinOutlined />,
  //       title: "Expense Management",
  //       className: "individual-menu",
  //       children: [
  //         {
  //           key: "/admin/expensecategories",
  //           icon: <SettingOutlined />,
  //           title: "Expense Categories",
  //         },
  //         {
  //           key: "/admin/expensepolicies",
  //           icon: <SettingOutlined />,
  //           title: "Expense Policies",
  //         },
  //         {
  //           key: "/admin/expensesettings",
  //           icon: <SettingOutlined />,
  //           title: "Expense Settings",
  //         },
  //         {
  //           key: "/admin/expensereports",
  //           icon: <SettingOutlined />,
  //           title: "Expense Reports",
  //         },
  //         {
  //           key: "/admin/expensereimbursement",
  //           icon: <SettingOutlined />,
  //           title: "Expense Reimbursement",
  //         },
  //       ],
  //     },
  //     {
  //       submenukey: "sub18",
  //       icon: <PushpinOutlined />,
  //       title: "Manage Employees",
  //       className: "individual-menu",
  //       children: [
  //         {
  //           key: "/admin/managepersonaldetails",
  //           icon: <SettingOutlined />,
  //           title: "Employee Details",
  //         },
  //         {
  //           key: "/admin/preferences",
  //           icon: <SettingOutlined />,
  //           title: "Preferences",
  //         },
  //         {
  //           key: "/admin/employeegroups",
  //           icon: <SettingOutlined />,
  //           title: "Groups",
  //         },
  //         {
  //           key: "/admin/employment",
  //           icon: <SettingOutlined />,
  //           title: "Employment",
  //         },
  //         {
  //           key: "/admin/passportdetails",
  //           icon: <SettingOutlined />,
  //           title: "Passport Details",
  //         },

  //         {
  //           key: "/admin/managevisadetails",
  //           icon: <SettingOutlined />,
  //           title: "Manage Visa Details",
  //         },
  //       ],
  //     },
  //     // {
  //     //   submenukey: "sub22",
  //     //   icon: <PushpinOutlined />,
  //     //   title: "Permissions",
  //     //   className: "individual-menu",
  //     //   children: [
  //     //     {
  //     //       key: "/admin/cadmin",
  //     //       icon: <SettingOutlined />,
  //     //       title: "Admin",
  //     //     },
  //     //   ],
  //     // },
  //     // {
  //     //   submenukey: "sub21",
  //     //   icon: <PushpinOutlined />,
  //     //   title: "C Policies",
  //     //   className: "individual-menu",
  //     //   children: [
  //     //     {
  //     //       key: "/admin/travelpolicies",
  //     //       icon: <SettingOutlined />,
  //     //       title: "Travel Ploicies",
  //     //     },
  //     //     {
  //     //       key: "/admin/companypreferences",
  //     //       icon: <SettingOutlined />,
  //     //       title: "Company Preferences",
  //     //     },
  //     //   ],
  //     // },
  //   ],
  // },

  {
    submenukey: "sub32",
    icon: <RocketOutlined />,
    title: "Flight Extranet",
    className: "left-menu-decre",
    children: [
      {
        key: "/admin/flight-extranet/search-criteria",
        icon: <SettingOutlined />,
        title: "Search Criteria",
      },
      {
        key: "/admin/flight-extranet/segments",
        icon: <SettingOutlined />,
        title: "Flight Segments",
      },
      {
        key: "/admin/flight-extranet/fare-details",
        icon: <SettingOutlined />,
        title: "Fare Details",
      },

      {
        key: "/admin/flight-extranet/fare-rules",
        icon: <SettingOutlined />,
        title: "Fare Rules",
      },
      {
        key: "/admin/flight-extranet/pnr",
        icon: <SettingOutlined />,
        title: "Flight PNR",
      }
    ],
  },


  {
    submenukey: "sub33",
    icon: <MoneyCollectOutlined />,
    title: "Currency Conversion",
    className: "left-menu-decre",
    children: [
      {
        key: "/admin/currency-conversion/currency-conversion",
        icon: <SettingOutlined />,
        title: "Currency Conversion",
      },
    ],
  },
];
