
import React, { useState, useEffect } from "react"
import { Button, Card, Col, Form, Modal, Row, Layout, Table, Collapse, Popconfirm, Switch, Select, message } from "antd";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
import search from "../assets/vector-icons/search.png";
import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import { TripIndicator,FlightCabinClass,JourneyTypeEnum } from "./FlightExtranetHelper"
//import moment from "moment";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import ReactQuill from "react-quill";
import modules from "../../helpers/reactQuillModules";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const FlightExtranetFareRules = () => {
    const history=useHistory();
    const { Panel } = Collapse;
    const { Option } = Select;
    const { Content } = Layout;
    const [form] = Form.useForm()
    const [isModalVisibal, setIsModalVisibal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [currentId, setCurrentId] = useState(0)
    const { user } = useAuthContext();
    //const dateFormat = "DD-MM-YYYY"
    const [dataSource, setDataSource] = useState([])
    const [flightSearchCriteria, setFlightSearchCriteria] = useState(null)
    const [content, setContent] = useState("");

    useEffect(() => {
        getFlightFareRules()
        const localStorageResp = localStorage.getItem("FlightExtranet_SearchCriteria")
        setFlightSearchCriteria(JSON.parse(localStorageResp))

    }, [])



    const handleAddClick = () => {
        form.resetFields();
        setIsModalVisibal(true);
        setIsEdit(false);
    };

    const handleEditClick = (rec) => {
        form.setFieldsValue({
            ...rec,
        })
        setIsEdit(true)
        setIsModalVisibal(true)
    }

    const tableActions = (rec) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <EditOutlined
                        onClick={() => {
                            handleEditClick(rec);
                            setCurrentId(rec.FareRuleId);
                        }}
                        style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => deleteFlightFareRules(rec.FareRuleId)}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </Popconfirm>
                </div>
            </div>
        );
    };

    const handelStatus = (data) => {
        if (data.Status === 1) {
            return (
                <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    defaultChecked
                    onChange={() => changeStatus(data, 0)}
                />
            );
        } else {
            return (
                <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    onChange={() => changeStatus(data, 1)}
                />
            );
        }
    };

    const changeStatus = (info) => {

    }

    const handelFormSubmit = (value) => {

        const reqBody = {
            TripIndicator: value.TripIndicator ?? 1,
            FlightId: flightSearchCriteria.FlightId ?? 0,
            FareRules: value.FareRules ?? null,
            Status: 1
        }

        if (isEdit) {
            reqBody["ModifiedBy"] = user?.UserID ?? 1
            updateFlightFareRules(reqBody)
        } else {
            reqBody["CreatedBy"] = user?.UserID ?? 1
            reqBody["ModifiedBy"] = user?.UserID ?? 1
            createFlightFareRules(reqBody)
        }

    }

    const handleDescription = (content) => {
        if (content === "<p><br></p>") {
            content = "";
        }
        setContent(content);
    };

    //#region API CALLING



    const getFlightFareRules = () => {
        ApiClient.get("flight-farerules/list").then((response) => {
            if (response.statusCode === 200) {
                const data = response.data
                setDataSource(data)
            }
        }).catch((e) => message.error(e.message))
    }

    const createFlightFareRules = (reqBody) => {
        ApiClient.post("create/flight-farerules", reqBody).then(
            (response) => {
                if (response.statusCode === 200) {
                    setIsModalVisibal(false);
                    message.success(response.message);
                    getFlightFareRules();
                    Modal.confirm({
                        title: 'Fare Rules Created successfully!!',
                        content: 'Please click OK to manage Pnr for this Updated Fare rules',
                        onOk() {
                            history.push('/admin/flight-extranet/pnr')
                        },
                    })
                } else {
                    message.error(response.message);
                }
            }
        );
    }

    const updateFlightFareRules = (reqBody) => {
        ApiClient.put(
            "update/flight-farerules/" + currentId,
            {},
            reqBody
        ).then((response) => {
            if (response.statusCode === 200) {
                setIsModalVisibal(false);
                message.success(response.message);
                getFlightFareRules();
                Modal.confirm({
                    title: 'Fare Rules Updated successfully!!',
                    content: 'Please click OK to manage Pnr for this Updated Fare rules',
                    onOk() {
                        history.push('/admin/flight-extranet/pnr')
                    },
                })
            } else {
                message.error("Error Updating");
            }
        }).catch((error) => message.error(error.message));
    }

    const deleteFlightFareRules = (FareRuleId) => {
        ApiClient.delete("delete/flight-farerules/" + FareRuleId).then(
            (res) => {
                if (res.statusCode === 200) {
                    message.success("Deleted  successfully", 3);
                    getFlightFareRules();
                } else message.error(res.message);
            }
        ).catch((er) => message.error(er.message));
    }

    //#endregion API CALLING


    const columns = [
        {
            title: "Actions",
            render: (rec) => {
                return tableActions(rec);
            },
        },
        {
            title: "JourneyType",
            //dataIndex: "JourneyType",
            render: (jt) => Object.keys(JourneyTypeEnum).find(key => JourneyTypeEnum[key] === jt?.FlightSearchCriteria?.JourneyType)
        },
        {
            title: "Origin",
            // dataIndex: "Origin",
            ellipsis: true,
            render: (item) => item?.FlightSearchCriteria?.Origin ?? ""
        },
        {
            title: "Destination",
            // dataIndex: "Destination",
            ellipsis: true,
            render: (item) => item?.FlightSearchCriteria?.Destination ?? ""
        },
        {
            title: "JourneyDate",
            // dataIndex: "JourneyDate",,
            render: (item) => item?.FlightSearchCriteria?.JourneyDate ?? ""
        },

        {
            title: "CabinClass",
            // dataIndex: "CabinClass",
            ellipsis: true,
            render: (cc) => Object.keys(FlightCabinClass).find(key => FlightCabinClass[key] === cc?.FlightSearchCriteria?.CabinClass)
        },

        {
            title: "ReturnDate",
            // dataIndex: "ReturnDate",
            render: (item) => item?.FlightSearchCriteria?.ReturnDate ?? "NA"
        },
        {
            title: "TripIndicator",
            dataIndex: "TripIndicator",
            render: (tp) => Object.keys(TripIndicator).find(key => TripIndicator[key] === tp)
        },

        {
            title: "FareRules",
            dataIndex: "FareRules",
            ellipsis: true
        },

        {
            title: "Status",
            render: (currentItem) => handelStatus(currentItem),
        },
    ]



    return (<div>
        <div>
            <Content>
                <Row>
                    <Col span={24}>
                        <div>               <div className="navigationmain-box" >
                                <a className="navigation-color"  href="/admin/flight-extranet/search-criteria">Route</a><i class="arrow right"></i>
                                <a className="navigation-color"  href="/admin/flight-extranet/segments">Segments</a><i class="arrow right"></i>
                                <a className="navigation-color" href="/admin/flight-extranet/fare-details">Fares</a><i class="arrow right"></i>
                                <a className="navigation-color" style={{color:"orange"}}>Fare Rules</a><i class="arrow right"></i>
                                <a className="navigation-color" href="/admin/flight-extranet/pnr">Pnr</a><i class="arrow right"></i>
               
                            </div>
                            <Card bordered={false}>
                                <div>
                                    <h5>
                                        Flights FareRules{" "}
                                        <HelpInfoHelper screenName={"/admin/cartypes"} />
                                    </h5>
                                </div>
                                <div className="action-images">
                                    <img src={search} alt="search" />
                                    <img src={excel} alt="excel" />
                                    <img src={pdf} alt="pdf" />
                                    <p className="add-plus-icon" onClick={handleAddClick}>
                                        <PlusCircleFilled />
                                    </p>
                                </div>
                                {dataSource.length > 0 ? (
                                    <div>
                                        <Table
                                            className="table-scroll-none"
                                            bordered
                                            dataSource={dataSource}
                                            columns={columns}
                                            pagination={{
                                                defaultPageSize: 25,
                                                showSizeChanger: true,
                                                pageSizeOptions: ["25", "50", "100", "125"],
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <b>No record Found</b>
                                )}
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Content>
        </div>
        <Modal
            title={[
                <div>
                    <h6><strong>{isEdit ? "Update FareRule" : "Add FareRule"}</strong></h6>
                </div>
            ]}
            onCancel={(e) => setIsModalVisibal(false)}
            onOk={(e) => setIsModalVisibal(false)}
            visible={isModalVisibal}
            footer={[
                <div>
                    <Button htmlType="submit" type="primary" onClick={() => form.submit()}>
                        {isEdit ? "Update" : "Add"}
                    </Button>
                    <Button onClick={() => form.resetFields()}>
                        Clear
                    </Button>
                </div>
            ]}
            width={"1100px"}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handelFormSubmit}
            >
                <Row gutter={16} >
                    {flightSearchCriteria?.JourneyType === 2 && (
                        <Col xs={24} md={24}>
                            <Form.Item
                                label="Trip Indicator"
                                name="TripIndicator"
                            >
                                <Select
                                >
                                    <Option value={1}>Onward</Option>
                                    <Option value={2}>Return</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    )}

                    <Col xs={24} md={24}>
                        <Collapse accordion>
                            <Panel header="Fare Rules" key="1">
                                <Form.Item name="FareRules">
                                    <ReactQuill
                                        modules={modules}
                                        value={content}
                                        placeholder="please input ..."
                                        onChange={handleDescription}
                                    />
                                </Form.Item>
                            </Panel>
                        </Collapse>
                    </Col>

                </Row>

            </Form>

        </Modal>
    </div >)
}

export default FlightExtranetFareRules