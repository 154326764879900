import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Layout,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Table,
} from "antd";

import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";

import ApiClient from "../../helpers/ApiClient";

import "../Promo/Promo.scss";
import {
  EditOutlined,
  PlusCircleFilled,
  CloseOutlined,
} from "@ant-design/icons";

import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const CarDeals = (props) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const uploadActionUrl = BASE + "media/upload/singleImage";
  const { Option } = Select;
  const { Content } = Layout;
  const [modalVisible, setModalVisible] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [countryLists, setCountryList] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [CurrecyList, setCurrencyList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [dealID, setDealID] = useState(null);
  const [form] = Form.useForm();
  const [travelType, setTravelType] = useState(2);
  const [cities, setCities] = useState([]);

  const changeTripTtype = (value) => {
    if (value === 1) {
      setTravelType(value);
    }
    if (value === 2) {
      setTravelType(value);
    }
    if (value === 3) {
      setTravelType(value);
    }
  };

  const [serviceType, setServiceType] = useState(1);

  const showModal = () => {
    setTravelType();
    form.resetFields();
    if (isEdit) {
      setIsEdit(false);
      setTravelType();
      form.resetFields();
    }
    setModalVisible({
      visible: true,
    });
  };

  const EditDealModel = (record) => {
    setIsEdit(true);
    setServiceType(record.serviceType);
    setTravelType(record.TravelType);
    form.setFieldsValue({
      ...record,
      Source: `${record.Source}~${record.SourceId}`,
      Destination: `${record.Destination}~${record.DesitnationId}`,
    });
    setModalVisible(true);
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}
          onClick={() => {
            EditDealModel(rec);
            setDealID(rec.Id);
          }}
        >
          <EditOutlined
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "Service Type",
      dataIndex: "serviceType",
      render: (value) => (value === 1 ? "Car Deals" : ""),
    },
    {
      title: "Travel Type",
      dataIndex: "TravelType",
      render: (value) =>
        value === 1
          ? "Local"
          : value === 2
          ? "OutStation"
          :"",
          // : value == 3
          // ? "Transfer"
          // : "",
    },
    {
      title: "Trip Type",
      dataIndex: "TripType",
      render: (value) =>
        value == 1
          ? "One-Way"
          : value == 2
          ? "Round-Trip"
          : value == 3
          ? "4hrs"
          : value == 4
          ? "8hrs"
          : value == 5
          ? "12hrs"
          : value == 6
          ? "24hrs"
          : value == 7
          ? "Railway Station"
          : value == 8
          ? "AirPort"
          : value == 9
          ? "Hotel/City"
          : "",
    },
    {
      title: "Source",
      dataIndex: "Source",
    },
    {
      title: "Currency",
      dataIndex: "Currency",
    },
    {
      title: "From Amount",
      dataIndex: "FromAmount",
    },
    {
      title: "Destination",
      dataIndex: "Destination",
    },

    {
      title: "Actions",
      render: (currentDeal) => tableActions(currentDeal),
    },
  ];

  const selectCustomFilter = (input, option) => {
    let istext =
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    if (istext) {
      return true;
    }
  };
  /*==========Api Calls=====*/

  useEffect(() => {
    getDeals();
    getCountriesLists();
    getCities();
    getCurrencies();
  }, []);

  const getCurrencies = () => {
    ApiClient.get("currency/getallcurrency")
      .then((res) => {
        if (res.status === 200) {
          setCurrencyList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getCities = (skip = 1, take = 42000) => {
    ApiClient.get("carextranet/getAllCity" + "?skip=" + skip + "&take=" + take)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          setCities(data);
        } else {
        }
      })
      .catch((error) => {});
  };

  const getDeals = () => {
    ApiClient.get("carextranet/getCarDeals")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          setDataSource(data);
        } else {
          message.error("Server Error");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCountriesLists = () => {
    ApiClient.get("admin/country")
      .then((res) => {
        if (res.status === 200) {
          setCountryList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const addApiCall = (values) => {
    let DealsData = {
      serviceType: values.serviceType,
      TravelType: values.TravelType,
      TripType: values.TripType,
      Source: values.Source.split("~")[0],
      FromAmount: values.FromAmount,
      Currency: values.Currency,
      Destination: values.Destination ? values.Destination.split("~")[0] : "",
      SourceId: values.Source.split("~")[1],
      DesitnationId: values.Destination ? values.Destination.split("~")[1] : 0,
      CreatedBy: 1,
    };

    isEdit ? putDealData(DealsData) : postDealData(DealsData);
  };

  const putDealData = (DealsData) => {
    ApiClient.put("carextranet/updateCarDeals/" + dealID, {}, DealsData).then(
      (res) => {
        if (res.status === 200) {
          message.success("Data Updated  successfully", 3);
          setModalVisible(false);
          form.resetFields();
          getDeals();
        } else {
          message.error(" Error Uploading Data", 3);
        }
      }
    );
  };

  const postDealData = (DealsData) => {
    if (serviceType === 1) {
      ApiClient.post("carextranet/carDeals", DealsData).then((res) => {
        if (res.status === 200) {
          message.success("Deal added successfully", 3);
          getDeals();
          setModalVisible(false);
          form.resetFields();
        } else {
          message.error(" Error Uploading Data", 3);
        }
      });
    } else {
      ApiClient.post("admin/topRoutes", DealsData).then((res) => {
        if (res.status === 200) {
          message.success("Deal added successfully", 3);
          getDeals();
          setModalVisible(false);
          form.resetFields();
        } else {
          message.error(" Error Uploading Data", 3);
        }
      });
    }
  };

  /*======= Search and Export Options=====*/

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = dataSource.filter(function (item) {
        if (item !== undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      getDeals();
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Cars Deals{" "}
                          <HelpInfoHelper screenName={"/admin/deals"} />
                        </h5>
                        <p>{dataSource.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData(dataSource)}
                      />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getDeals()}
                      />
                      <p className="add-deposit-icon" onClick={showModal}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>
                  <div>
                    <Table
                      className="table-scroll-none"
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title="Deals"
        className="promo-modal-header admin-bc-level modal-header-bg12"
        visible={modalVisible}
        onCancel={(e) => setModalVisible(false)}
        width={"1100px"}
        footer={[
          <Button onClick={() => setModalVisible(false)}>Cancel</Button>,
          <Button type="primary" onClick={form.submit}>
            {isEdit ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <Form form={form} layout={"vertical"} onFinish={addApiCall}>
          <Row gutter={18}>
            <Col md={8} xs={24}>
              <Form.Item
                label="Service Type"
                rules={[{ required: true }]}
                name="serviceType"
              >
                <Select
                  placeholder="Please Select"
                  onSelect={(e) => {
                    setServiceType(e);
                  }}
                  value={serviceType}
                >
                  <Option value={1}>Car Deals</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                name="TravelType"
                label="Travel Type"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Travel Type",
                  },
                ]}
              >
                <Select
                  placeholder="Please select"
                  onChange={(e) => {
                    changeTripTtype(e);
                  }}
                >
                  <Option value={1}>Local</Option>
                  <Option value={2}>Outstation</Option>
                  {/* <Option value={3}>Transfer</Option> */}
                </Select>
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                name="TripType"
                label="Trip Type"
                rules={[
                  {
                    required: true,
                    message: "Please Enter your Trip Type",
                  },
                ]}
              >
                {travelType == 2 ? (
                  <Select placeholder="Please select Trip Type">
                    <Option value={1}>One Way</Option>
                    <Option value={2}>Round Trip</Option>
                  </Select>
                ) : travelType == 1 ? (
                  <Select placeholder="Please select Trip Type">
                    <Option value={3}>4 hours/Day </Option>
                    <Option value={4}>8 hours/Day </Option>
                    <Option value={5}>12 hours/Day </Option>
                    <Option value={6}>24 hours/Day </Option>
                  </Select>
                ) : (
                  <Select placeholder="Please select Trip Type">
                    <Option value={7}>Railway Station</Option>
                    <Option value={8}>AirPort</Option>
                    <Option value={9}>Hotel/City</Option>
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label="Source"
                rules={[{ required: true, message: "Please Select Source" }]}
                name="Source"
              >
                <Select placeholder="Please select" showSearch>
                  {cities.length > 0 ? (
                    cities.map((item) => (
                      <Option
                        key={"src" + item.Id}
                        value={`${item.Name}~${item.Id}`}
                      >
                        {item.Name}
                      </Option>
                    ))
                  ) : (
                    <Option value={0}>No Cities</Option>
                  )}
                </Select>
              </Form.Item>
            </Col>

            {travelType == 2 && (
              <Col md={8} xs={24}>
                <Form.Item
                  label="Destination"
                  rules={[
                    { required: true, message: "Please Select Destination" },
                  ]}
                  name="Destination"
                >
                  <Select placeholder="Please select" showSearch>
                    {cities.length > 0 ? (
                      cities.map((item) => (
                        <Option
                          key={"dest" + item.Id}
                          value={`${item.Name}~${item.Id}`}
                        >
                          {item.Name}
                        </Option>
                      ))
                    ) : (
                      <Option value={0}>No Cities</Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            )}

            <Col md={8} xs={24}>
              {serviceType == 1 && (
                <Form.Item
                  label="From Amount"
                  name="FromAmount"
                  rules={[
                    {
                      required: true,
                      message: "From Amount is required",
                    },
                  ]}
                >
                  <Input type="number" placeholder="Enter Fare" />
                </Form.Item>
              )}
            </Col>

            <Col md={8} xs={24}>
              {serviceType == 1 && (
                <Form.Item
                  label="Currency"
                  name="Currency"
                  rules={[{ required: true, message: "Currency is required" }]}
                >
                  <Select placeholder="Please Select Your Currency" showSearch>
                    {CurrecyList.length
                      ? CurrecyList.map((item, index) => {
                          return (
                            <Option key={"Currency" + index} value={item.Code}>
                              {item.Code}
                            </Option>
                          );
                        })
                      : ""}
                  </Select>
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default CarDeals;
