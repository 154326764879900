import React, { useEffect, useState } from "react";
import "./OperatorsService.scss";
import excel from "../assets/vector-icons/excel.png";

import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";

import * as ANTD from "antd";
import {
  PlusOutlined,
  EditOutlined,
  IdcardOutlined,
  CloseOutlined,
  DeleteOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { Modal } from "antd";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import AirlineAutoCompleteSelect from "../../common/AutoCompleteSelect/AirlineAutoCompleteSelect";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const OperatorsService = (props) => {
  const { Content } = ANTD.Layout;
  const { Option } = ANTD.Select;
  const [modalVisible, setModalVisible] = useState(false);
  const [OperatorsForm] = ANTD.Form.useForm();
  const [operators, setOperators] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [currID, setCurrID] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [inputBox, setInputBox] = useState(-1);
  useEffect(() => {
    GetOperators();
  }, []);

  const GetOperators = async () => {
    const response = await ApiClient.get("admin/operators");
    if (response.data) {
      setOperators(response.data);
    }
  };

  const AddOperators = async (values) => {
        let data = {
      BusType: 1,
      CreatedBy: "1",
      DeviceOS: 1,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
      ...values,
    };

    const response = await ApiClient.post("admin/operator", data);
    if (response.status === 200) {
      ANTD.message.success("Operator added successfully !");
      GetOperators();
      setModalVisible(false);
    } else {
      ANTD.message.error(response.message, 3);
    }
  };

  const UpdateOperator = async (values) => {
    
    let data = {
      BusType: 1,
      CreatedBy: "1",
      DeviceOS: 1,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
      ...values,
    };
    const response = await ApiClient.put("admin/operator/" + currID, {}, data);
    if (response.status === 200) {
      ANTD.message.success("Operator updated successfully !");
      GetOperators();
      setIsEdit(false);
      setModalVisible(false);
    } else {
      ANTD.message.error(response.message, 3);
    }
  };

  const DeleteOperator = async (id) => {
    const response = await ApiClient.delete("admin/operator/" + id);
    if (response.status === 200) {
      ANTD.message.success("Operator Deleted successfully !");
      GetOperators();
    } else {
      ANTD.message.error(response.message, 3);
    }
  };

  const add = () => {
    ANTD.message.success("Markup Added Successfully...", 3);
    setModalVisible(false);
  };
  const clear = () => {
    setModalVisible(false);
  };

  const showModal = () => {
    if (isEdit) {
      setIsEdit(false);
      setInputBox(-1);
    }
    OperatorsForm.resetFields();
    setModalVisible({
      visible: true,
    });
  };

  const closeModal = () => {
    setModalVisible({
      visible: false,
    });
  };

  const tableDelete = () => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}
          onClick={showModal}
        >
          <EditOutlined
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm title="Are you sure to delete?">
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };

  const columnsserviceoper = [
    {
      title: "Service Name",
      dataIndex: "ServiceType",
      render: (text) => {
        switch (text) {
          case 3:
            return "Transfers";
          case 2:
            return "Hotels";
          case 1:
            return "Flight";
          case 4:
            return "Activity";

          default:
            break;
        }
      },
      sorter: (a, b) => a.ServiceType - b.ServiceType,
    },

    {
      title: "Operator Name",
      dataIndex: "OperatorName",
      sorter: (a, b) => a.OperatorName.localeCompare(b.OperatorName),
    },

    {
      title: "Actions",
      dataIndex: "OperatorServiceID",
      render: (text, record, index) => (
        <>
          <span
            style={{ marginRight: 20, marginLeft: 20 }}
            className="edit-icon"
            onClick={() => {
              setCurrID(text);
              setIsEdit(true);
              setModalVisible(true);
              setInputBox(record.ServiceType);
              OperatorsForm.setFieldsValue({
                ServiceType: record.ServiceType,
                OperatorName: record.OperatorName,
                BusType: record.BusType ? record.BusType : "",
              });
            }}
          >
            <i className="fa fa-edit"></i>
          </span>
          <ANTD.Popconfirm
            title="Are u sure to delete?"
            onConfirm={() => DeleteOperator(record.OperatorServiceID)}
          >
            <span className="delete-icon">
              <i className="fa fa-trash"></i>
            </span>
          </ANTD.Popconfirm>
        </>
      ),
    },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = operators.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setOperators(searchResults);
    } else {
      GetOperators();
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  const handleExcel = () => {
    const excelData = operators.map((item) => {
      return {
        ...item,
        ServiceType: getName(item.ServiceType),
      };
    });

    props.exportExcel(excelData, "OperatorsDetails");
  };

  const getName = (id) => {
    switch (id) {
      case 3:
        return "Transfers";
      case 2:
        return "Hotels";
      case 1:
        return "Flight";
      case 4:
        return "Activity";
      default:
        break;
    }
  };

  const handleServiceType = (val) => {
    setInputBox(val);
  };

  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section">
          <Content className="admin-container">
            <ANTD.Row>
              <ANTD.Col span={24}>
                <div className="card-bt-gap">
                  <ANTD.Card bordered={false}>
                    <div className="card-add-heading">
                      <div className="rows-count">
                        <div>
                          <h5>
                            Add Operator{" "}
                            <HelpInfoHelper screenName={"/admin/operator"} />
                          </h5>
                          <p>{operators.length} rows found !</p>
                        </div>
                      </div>
                      <div className="action-images">
                        {showSearchBox && (
                          <ANTD.Input
                            placeholder="Search"
                            onChange={(e) => searchTableData(e)}
                            suffix={closeSearchInput}
                            style={{ padding: "0px 12px" }}
                          />
                        )}
                        &nbsp;&nbsp;
                        <img
                          src={search}
                          alt="search"
                          onClick={(e) => searchData()}
                        />
                        <img src={excel} alt="excel" onClick={handleExcel} />
                        {/*  <img src={exchange} alt="exchange" /> */}
                        <p className="add-icon" onClick={showModal}>
                          <PlusOutlined />
                        </p>
                      </div>
                      {/* </div> */}
                    </div>

                    <div>
                      <ANTD.Table
                        className="table-scroll-none"
                        bordered
                        dataSource={operators}
                        columns={columnsserviceoper}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  </ANTD.Card>
                </div>
              </ANTD.Col>
            </ANTD.Row>
          </Content>
        </div>

        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>Add Operator</strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header modal-header-bg12"
          visible={modalVisible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          footer={[
            <div>
              <ANTD.Button
                htmlType="submit"
                type="primary"
                onClick={OperatorsForm.submit}
              >
                {isEdit ? "Update" : "Add"}
              </ANTD.Button>
              <ANTD.Button
                type="danger"
                onClick={() => OperatorsForm.resetFields()}
              >
                Clear
              </ANTD.Button>
            </div>,
          ]}
        >
          <ANTD.Form
            layout={"vertical"}
            form={OperatorsForm}
            onFinish={isEdit ? UpdateOperator : AddOperators}
          >
            <ANTD.Row>
              <ANTD.Col md={24} xs={24}>
                <ANTD.Form.Item
                  label="Service List"
                  rules={[{ required: true }]}
                  name="ServiceType"
                >
                  <ANTD.Select
                    size={"large"}
                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                    placeholder="Please Select"
                    onSelect={handleServiceType}
                  >
                    <Option value={1}>Flight</Option>
                    {/* <Option value={2}>Hotel</Option> */}
                    <Option value={3}>Transfers</Option>
                    {/* <Option value={4}>Activities</Option> */}
                  </ANTD.Select>
                </ANTD.Form.Item>
              </ANTD.Col>
              {inputBox === 3 ? (
                <>
                  <ANTD.Col md={24} xs={24}>
                    <ANTD.Form.Item
                      label="Car Type"
                      name="BusType"
                      rules={[{ required: true }]}
                    >
                      <ANTD.Select placeholder="Please Select">
                        <Option value={1}>All</Option>
                        {/* <Option value={2}>Private Travels</Option> */}
                      </ANTD.Select>
                    </ANTD.Form.Item>
                  </ANTD.Col>

                  <ANTD.Col md={24} xs={24}>
                    <ANTD.Form.Item
                      label="Operator Name"
                      name="OperatorName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Operator Name",
                        },
                      ]}
                    >
                      <ANTD.Input placeholder="Operator Name" size={"large"} />
                    </ANTD.Form.Item>
                  </ANTD.Col>
                </>
              ) : null}

              {inputBox === 1 ? (
                <ANTD.Col md={24} xs={24}>
                  <AirlineAutoCompleteSelect
                    formItemProps={{
                      label: "Airline Name",
                      name: "OperatorName",
                      rules: [{ required: true }],
                    }}
                    selectProps={{ placeholder: "" }}
                    api={"flights/airlineNames/search/"}
                    keytext="nameAirline"
                    keyvalue="codeIataAirline"
                  />
                </ANTD.Col>
              ) : null}
              {inputBox === 2 ? (
                <ANTD.Col md={24} xs={24}>
                  <ANTD.Form.Item
                    label="Hotel Name"
                    name="OperatorName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Hotel Name",
                      },
                    ]}
                  >
                    <ANTD.Input placeholder="Hotel Name" size={"large"} />
                  </ANTD.Form.Item>
                </ANTD.Col>
              ) : null}
              {inputBox === 4 ? (
                <ANTD.Col md={24} xs={24}>
                  <ANTD.Form.Item
                    label="Activity Name"
                    name="OperatorName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Activity Name",
                      },
                    ]}
                  >
                    <ANTD.Input placeholder="Activity Name" size={"large"} />
                  </ANTD.Form.Item>
                </ANTD.Col>
              ) : null}
            </ANTD.Row>
          </ANTD.Form>
        </Modal>
      </div>
    </div>
  );
};

export default UpdatedComponent(OperatorsService);
