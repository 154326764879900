import React from "react";
import * as ANTD from "antd";
// import "./PrintTicket.scss";
import FlightTicket from "../../admin/FlightTicket/FlightTicket";

import { Form, Input, Modal, Select, Menu } from "antd";
import {
  PrinterOutlined,
  MessageOutlined,
  MailOutlined,
  DownloadOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Option } from "antd/lib/mentions";

const PrintTicket = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [modalVisible, setModalVisible] = React.useState(false);
  const [formLayout, setFormLayout] = React.useState("vertical");

  const tailLayout = {
    wrapperCol: { span: 24 },
  };

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const Print = (
    <ANTD.Menu>
      <ANTD.Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Print Confirmation With Price
        </a>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Print Confirmation Without Price
        </a>
      </ANTD.Menu.Item>
    </ANTD.Menu>
  );

  const Email = (
    <ANTD.Menu>
      <ANTD.Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Email With Price
        </a>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Email Without Price
        </a>
      </ANTD.Menu.Item>
    </ANTD.Menu>
  );

  const [form] = ANTD.Form.useForm();
  const submitForm = (val) => {
    // Sample ID to test
    // AJML-F24579134
    // AJML-B29043503
    // AJML-H29530801

    let url_formed = val.ServiceType + "?ref=" + val.RefId;

    document.location.replace(url_formed);
  };
  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section">
          <Content className="admin-container">
            <ANTD.Row>
              <ANTD.Col span={24}>
                <div className="card-bt-gap">
                  <div className="card-add-heading">
                    <h3>My trips</h3>
                  </div>
                </div>
              </ANTD.Col>
            </ANTD.Row>

            <ANTD.Row>
              <ANTD.Col span={24}>
                <ANTD.Card>
                  <div className="">
                    <h5>Enter Ticket Details</h5>
                  </div>
                  <ANTD.Form form={form} onFinish={submitForm}>
                    <ANTD.Row gutter={24}>
                      <ANTD.Col className="gutter-class" lg={6} md={12} xs={24}>
                        <ANTD.Form.Item
                          label="Service Type"
                          name="ServiceType"
                          rules={[{ required: true, message: " " }]}
                        >
                          <Select placeholder="Select Service Type">
                            <Option value="flight/ticket">Flights</Option>
                            {/* <Option value="bus/ticket">Bus</Option>
                            <Option value="hotel/ticket">Hotel</Option> */}
                          </Select>
                        </ANTD.Form.Item>
                      </ANTD.Col>

                      <ANTD.Col className="gutter-class" lg={6} md={12} xs={24}>
                        <ANTD.Form.Item
                          label="Reference ID"
                          name="RefId"
                          rules={[{ required: true, message: " " }]}
                        >
                          <ANTD.Input />
                        </ANTD.Form.Item>
                      </ANTD.Col>

                      <ANTD.Col className="gutter-class" lg={6} md={12} xs={24}>
                        <ANTD.Form.Item
                          label="Mobile"
                          name="Mobile"
                          rules={[{ required: true, message: " " }]}
                        >
                          <ANTD.Input />
                        </ANTD.Form.Item>
                      </ANTD.Col>

                      <ANTD.Col className="gutter-class" lg={6} md={12} xs={24}>
                        <ANTD.Form.Item>
                          <ANTD.Button
                            type="primary"
                            className="retrive-btn"
                            htmlType="submit"
                            style={{ width: "100%" }}
                          >
                            Retrieve
                          </ANTD.Button>
                        </ANTD.Form.Item>
                      </ANTD.Col>
                    </ANTD.Row>
                  </ANTD.Form>
                </ANTD.Card>
              </ANTD.Col>
            </ANTD.Row>
          </Content>
        </div>

        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>Print Ticket</strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header cancel-modal"
          visible={modalVisible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          footer={[
            <div>
              {/*  <ANTD.Button key="add" type="primary"  >Print Ticket</ANTD.Button> */}
            </div>,
          ]}
        >
          <ANTD.Row className="print-ticket-btn">
            <ANTD.Dropdown overlay={Print} placement="bottomCenter">
              <ANTD.Button className="all-print-btn" type="primary">
                Print
                <DownOutlined className="printmail" />
              </ANTD.Button>
            </ANTD.Dropdown>

            {/* <ANTD.Button className="all-print-btn" type="primary"><PrinterOutlined />Print</ANTD.Button> */}
            <ANTD.Button className="all-print-btn" type="primary">
              <MessageOutlined />
              SMS
            </ANTD.Button>

            <ANTD.Dropdown overlay={Email} placement="bottomCenter">
              <ANTD.Button className="all-print-btn" type="primary">
                <MailOutlined />
                Email
              </ANTD.Button>
            </ANTD.Dropdown>
            {/* <ANTD.Button className="all-print-btn" type="primary"><MailOutlined />Email</ANTD.Button> */}
            <ANTD.Button className="all-print-btn" type="primary">
              <DownloadOutlined />
              Download
            </ANTD.Button>

            <ANTD.Col md={24} xs={24}></ANTD.Col>
          </ANTD.Row>

          <ANTD.Row>
            <ANTD.Col md={24} xs={24}>
              <FlightTicket />

              {/* 


                        <div className="invoice-body-first-line-1">
   <div className="invoice-from-to-details-1">
      <div className="from-to-names-1">
         <ul>
            <li>Hyderabad</li>
            <li><i className="fa fa-arrow-right"></i></li>
            <li>visakhapatnam</li>
         </ul>
      </div>
      <div className="invoice-date-time-1">Saturday,Feb 28,2020 - static</div>
   </div>
   <div className="invoiceticket-number-1">
      <ul>
         <li>Ticket no: 9874560</li>
         <li>PNR no: 02314</li>
      </ul>
   </div>
</div>




<div className="invoice-second-line-1">
   <ul>
      <li className="invoice-travelsdetails-1">
         <div>orange travels</div>
         <div>AC</div>
      </li>
      <li className="invoice-reportingtimedetails-1">
         <div>02:55pm</div>
         <div>Reporting Time</div>
      </li>
      <li className="invoice-departingdetails-1">
         <div>12:20</div>
         <div>Departing Time</div>
      </li>
      <li className="invoice-numberdetails-1">
         <div>1</div>
         <div>Number Of Passengers</div>
      </li>
   </ul>
</div>


<div className="invoice-third-line-3">
   <ul>
      <li className="invoice-travelsdetails-3">
         <div>Boarding Point Details</div>
      </li>
      <li className="invoice-reportingtimedetails-3">
         <div>Moulali</div>
         <div>9874563210</div>
         <div>Location</div>
      </li>
      <li className="invoice-departingdetails-3">
         <div>Moulali Housing Board Colony</div>
         <div>Landmark</div>
      </li>
      <li className="invoice-numberdetails-3">
         <div>Moulali</div>
         <div>Address</div>
      </li>
   </ul>
</div>



<div className="invoice-fourth-line-4">
   <ul>
      <li className="invoice-travelsdetails-4">
         <div>Dropping Point Details</div>
      </li>
      <li className="invoice-reportingtimedetails-4">
         <div>02:23pm</div>
         <div>Dropping Point Time</div>
      </li>
      <li className="invoice-departingdetails-4">
         <div>01-Mar-2020 - static</div>
         <div>Dropping Point Date</div>
      </li>
      <li className="invoice-numberdetails-4">
         <div>NTR Circle</div>
         <div>Address</div>
      </li>
   </ul>
</div>



<div className="invoice-fifth-line-5">
   <ul>
      <li className="invoice-travelsdetails-5">
         <div>Passenger Details(Age,Gender)</div>
         <div>i2space 28,male)</div>
      </li>
      <li className="invoice-reportingtimedetails-5">
         <div>Seat Number</div>
         <div>L9</div>
      </li>
   </ul>
</div>


<div className="invoice-note-6">
   <div className="note-indication-6">NOTE: This operator accpts mTicket,you need to carry out a print out</div>
   <div className="total-fare-6">
      <div>
         Total Fare : <span className="invoice-price-6">Rs.1280</span>
         <h6 className="invoice-tax-info-6">(Rs.1280 Inclusive of GST and service charge if any)</h6>
      </div>
   </div>
</div>
 */}
            </ANTD.Col>
          </ANTD.Row>
        </Modal>
      </div>
    </div>
  );
};
export default PrintTicket;
