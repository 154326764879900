import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Switch,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Upload,
  DatePicker,
  Select,
  Table,
  Image,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  IdcardOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  UserOutlined,
} from "@ant-design/icons";
import search from "../../assets/vector-icons/search.png";
import excel from "../../assets/vector-icons/excel.png";
import pdf from "../../assets/vector-icons/pdf.png";
import ApiClient from "../../../helpers/ApiClient";

const ManageTeams = () => {
  const { Content } = Layout;
  const [modalVisible, setModalVisible] = React.useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [Department, setDepartment] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [formManageTeam] = Form.useForm();
  const [currentID, setCurrentID] = useState(-1);
  const [formLayout, setFormLayout] = React.useState("vertical");

  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 23 },
        }
      : null;

  const add = () => {
    message.success("Buses Markup Added Successfully...", 3);
    setModalVisible(false);
  };
  const clear = () => {
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const closeModal = () => {
    setModalVisible({
      visible: false,
    });
  };
  const handleAddClick = () => {
    formManageTeam.resetFields();
    showModal();
    setIsEditMode(false);
  };
  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              handleEditClick(rec);
              setCurrentID(rec.TeamId);
            }}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteTeam(rec.TeamId)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const handleEditClick = (rec) => {
    formManageTeam.setFieldsValue({
      TeamName: rec.TeamName ?? "",
      Description: rec.Description ?? "",
      DepartmentId: rec.DepartmentId ?? "",
      Status: rec.Status ?? "",
    });

    setIsEditMode(true);

    showModal();
  };
  const deleteTeam = (id) => {
    ApiClient.delete("CorporateExtranet/DeleteTeams/" + id).then((res) => {
      if (res.status == 200) {
        message.success("team Deleted  successfully", 3);
        getAllTeams();
      }
    });
  };
  const handleSubmit = (formvalues) => {
    if (isEditMode) {
      submitUpdateTeam(formvalues);
    } else {
      submitNewTeam(formvalues);
    }
  };
  const submitNewTeam = (formvalues) => {
    ApiClient.post("CorporateExtranet/CreateTeams", formvalues).then(
      (response) => {
        if (response.status === 200) {
          setModalVisible(false);
          message.success("Team created successfully");
          getAllTeams();
          formManageTeam.resetFields();
        } else {
          message.error(" Error Creating Team");
        }
      }
    );
  };
  const submitUpdateTeam = (formvalues) => {
    const updatedFormData = {
      ...formvalues,
    };
    ApiClient.put(
      "CorporateExtranet/UpdateTeams/" + currentID,
      {},
      updatedFormData
    ).then((response) => {
      // console.log(response);
      if (response.status === 200) {
        setModalVisible(false);
        message.success("Team updated successfully");
        getAllTeams();
        formManageTeam.resetFields();
      } else {
        message.error(" Error Updating Team");
      }
    });
  };
  const columns = [
    {
      title: "Team Name",
      dataIndex: "TeamName",
    },
    {
      title: "Department Name",
      dataIndex: "DepartmentName",
    },
    {
      title: "Description",
      dataIndex: "Description",
    },
    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];
  const handelStatus = (data) => {
    if (data.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };

  const changeStatus = (info) => {
    // let obj = {
    //   Id: info.CarTypeID,
    //   status: Status,
    // };
    // ApiClient.put("carextranet/toggleCarTypeStatus/" + info.CarTypeID)
    //   .then((response) => {
    //     if (response.status == 200) {
    //       message.success("Car Type Status updated successfully !");
    //       getAllCarType();
    //     } else {
    //       message.error(response.message, 3);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };
  useEffect(() => {
    getAllDepartment();
    getAllTeams();
  }, []);
  const getAllDepartment = () => {
    ApiClient.get("CorporateExtranet/GetDepartment")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setDepartment(data);
        }
      })
      .catch((error) => {});
  };
  const getAllTeams = () => {
    ApiClient.get("CorporateExtranet/GetTeams")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setDataSource(data);
          setTeamList(data.length);
        }
      })
      .catch((error) => {});
  };
  return (
    <div className="promo providerapi">
      <div className="manage-markup-section">
        <Row>
          <Col span={24}>
            <Card>
              <div className="card-add-heading">
                <div className="rows-count">
                  <div>
                    <h5>Manage Teams</h5>
                    <p>{teamList ?? 0} rows found !</p>
                  </div>
                </div>
                <div
                  className="exlpdf"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    src={search}
                    alt="search"
                    style={{ maxHeight: "35px", maxWidth: "35px" }}
                  />
                  <img
                    src={excel}
                    alt="excel"
                    style={{ maxHeight: "35px", maxWidth: "35px" }}
                  />

                  <p className="add-icon" onClick={() => handleAddClick()}>
                    <PlusOutlined />
                  </p>
                </div>
              </div>

              <Table
                className="table-scroll-none"
                style={{ color: "red" }}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                  defaultPageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100", "125"],
                }}
              />
            </Card>
          </Col>
        </Row>

        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>Manage Teams</strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header modal-header-bg12"
          visible={modalVisible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          footer={[
            <div>
              <Button type="primary" onClick={() => formManageTeam.submit()}>
                {isEditMode ? "Update" : "Add"}
              </Button>
              <Button
                type="primary"
                danger
                className="cancel-btn"
                onClick={() => formManageTeam.resetFields()}
              >
                Clear
              </Button>
            </div>,
          ]}
          width={"625px"}
        >
          <Form
            {...formItemLayout}
            layout={formLayout}
            initialValues={{ layout: formLayout }}
            onFinish={handleSubmit}
            form={formManageTeam}
          >
            <Row>
              <Col md={12} xs={24}>
                <Form.Item
                  name="TeamName"
                  label="Team Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Team Name!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Team Name"
                  />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  name="Description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Team Description!",
                    },
                  ]}
                >
                  <Input
                    prefix={<EditOutlined className="site-form-item-icon" />}
                    placeholder="Team Description"
                  />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  name="DepartmentId"
                  label="Department"
                  rules={[
                    {
                      required: true,
                      message: "Please select your Team Department!",
                    },
                  ]}
                >
                  <Select
                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                    placeholder="Select Your Department Name"
                  >
                    {Department.length > 0 ? (
                      Department.map((i) => (
                        <Select.Option value={i.DepartmentId}>
                          {i.DepartmentName}
                        </Select.Option>
                      ))
                    ) : (
                      <Select.Option value="demo">No Department</Select.Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="Status"
                  label="Status"
                  rules={[
                    { required: true, message: "Please Select Your Status" },
                  ]}
                >
                  <Select
                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                    placeholder="Select Status"
                  >
                    <Select.Option value={0}>InActive</Select.Option>
                    <Select.Option value={1}>Active</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </div>
  );
};
export default ManageTeams;
