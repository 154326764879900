import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Table,
  TimePicker,
} from "antd";

import ReactQuill from "react-quill";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import ApiClient from "../../helpers/ApiClient";

import modules from "../../helpers/reactQuillModules";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
import search from "../assets/vector-icons/search.png";
import "rc-datepicker/lib/style.css";
import "./CarCancellation.scss";
import { Delete } from "react-feather";
import { FormLabel } from "react-bootstrap";

const CarCancellation = () => {
  const { Content } = Layout;
  const { Option } = Select;
  const [form] = Form.useForm();
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [formLayout, setFormLayout] = useState("vertical");
  const [dataSource, setDataSource] = useState([]);

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const getAllCarCancelation = () => {
    ApiClient.get("carextranet/getCancellationRecord").then((res) => {
      if (res.status == 200) {
        setDataSource(res.data);
      }
    });
  };

  useEffect(() => {
    getAllCarCancelation();
  }, []);

  const handleSubmit = (values) => {
    let formvalues = {
      ...values,
      CancellationAmount: Number(values.CancellationAmount ?? 0),
      CancellationCharges: Number(values.CancellationCharges ?? 0),
      RefundAmount: Number(values.RefundAmount ?? 0),
      RefundStatus: Number(values.RefundStatus ?? 0),
      RefundType: values.RefundType ?? null,
      Remarks: values.Remarks ?? null,
      RefundStatus: values.RefundStatus ?? null,
    };
    if (isEditMode) {
      submitUpdateCarsAvailability(formvalues);
    }
  };

  const submitUpdateCarsAvailability = (values) => {
    const updatedFormData = {
      ...values,
    };
    ApiClient.post("carextranet/carCancel", updatedFormData).then(
      (response) => {
        if (response.status === 200) {
          setModalVisible(false);
          message.success("CarCanceled Successfully");
          getAllCarCancelation();
          form.resetFields();
        } else {
          message.error(" Error CarCancelation");
        }
      }
    );
  };

  const handleEditClick = (rec) => {
    form.resetFields();
    form.setFieldsValue({
      BookingRefNo: rec.BookingRefNo ?? "",
      CancellationID: rec.CancellationID ?? "",
      Id: rec.Id ?? "",
    });

    setIsEditMode(true);
    showModal();
  };

  const columns = [
    {
      title: "Cancellation ID",
      dataIndex: "CancellationID",
    },

    {
      title: "Booking Ref No",
      dataIndex: "BookingRefNo",
    },

    {
      title: "Guest Name",
      render: (currentData) =>
        `${currentData.GuestTitle}.${" "}${currentData.GuestName}`,
    },
    {
      title: "PNR",
      dataIndex: "PNR",
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];
  const tableActions = (rec) => {
    return rec.CancellationStatus === 0 ? (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => handleEditClick(rec)}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
      </div>
    ) : null;
  };
  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Car Cancellation Requests{" "}
                          <HelpInfoHelper
                            screenName={"/admin/carcancellation"}
                          />
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div>
                    <Table
                      className="table-scroll-none"
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title="Car Cancellation"
        className="promo-modal-header modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <Form
          form={form}
          layout={formLayout}
          initialValues={{ layout: formLayout }}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <h5>Car Cancellation</h5>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={6} xs={24}>
              <Form.Item name="BookingRefNo" label="BookingRefNo">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item name="CancellationID" label="Cancellation ID">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item name="Id" label="Cancellation Record ID">
                <Input disabled />
              </Form.Item>
            </Col>

            <Col md={6} xs={24}>
              <Form.Item
                name="CancellationCharges"
                label="Cancellation Charges"
                rules={[
                  {
                    required: true,
                    message: "Please input your CancellationCharges",
                  },
                ]}
              >
                <Input placeholder="Please Enter Charges" type="number"  min={1}/>
              </Form.Item>
            </Col>

            <Col md={6} xs={24}>
              <Form.Item
                label="Cancellation Amount"
                name="CancellationAmount"
                rules={[
                  {
                    required: true,
                    message: "Please select Your Cancellation Amounts",
                  },
                ]}
              >
                <Input placeholder="Please Enter Amount" type="number"  min={1}/>
              </Form.Item>
            </Col>

            <Col md={6} xs={24}>
              <Form.Item
                label="Refund Amount"
                name="RefundAmount"
                rules={[
                  {
                    required: true,
                    message: "Please select Your Refund Amount",
                  },
                ]}
              >
                <Input placeholder="Please Enter Refund Amount" type="number" min={1} />
              </Form.Item>
            </Col>

            {/* <Col md={6} xs={24}>
              <Form.Item label="Refund Status" name="RefundStatus">
                <Select placeholder="Please select">
                  <Option value={0}>Refundable</Option>
                  <Option value={1}>Non-Refundable</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item label="Refund Type" name="RefundType">
                <Input placeholder="Please Enter Refund Type" />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item label="Remarks" name="Remarks">
                <Input placeholder="Please Enter Remarks" />
              </Form.Item>
            </Col> */}
          </Row>
          <Row>
            <Col md={24}>
              <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
                <Button type="primary" htmlType="submit">
                  Done
                </Button>
                <Button type="primary" danger className="cancel-btn">
                  Clear
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default CarCancellation;
