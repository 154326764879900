import React from "react";
import { Card, Row, Col } from "antd";
import Slider from "react-slick";

import "./FooterBanner.scss";

const FooterBanner = ({ footerBanner }) => {
  const MobileSlidersettings = {
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="FooterBanner mt-4">
      <Row>
        <Col md={24} xs={24}>
          <Slider {...MobileSlidersettings}>
            {footerBanner.map((item) => {
              return (
                <div>
                  <img
                    className="ads-baneer-img w-100"
                    src={
                      process.env.REACT_APP_BASE_URL + item.path.substring(1)
                    }
                    alt="ads-Banner"
                  />
                </div>
              );
            })}
          </Slider>
        </Col>
      </Row>
    </div>
  );
};

export default FooterBanner;
