import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import "./HomeCarousel.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slide_1_1 from "../../assets/images/HomeCarousel/slide_1_1.jpg";
import topFlightNames from "./../flight-search/FlightsTopCities.json";
import moment from "moment";

const CarouselNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-table", background: "#bd0c21" }}
      onClick={onClick}
    />
  );
};
const cardStyle = {
  width: "600px",
  height: "400px",
}
const CarouselPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline-table", background: "#bd0c21" }}
      onClick={onClick}
    />
  );
};

const HomeCarousel = ({ deal }) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const { activeCurrency, currencyValue } = useCurrencyContext();
  let tomorrowDate = moment().add(5, "days").format("YYYY-MM-DD");

  const [deals, setDeals] = useState([]);
  const [mobileDeals, setmobileDeals] = useState([]);
  const settings = {
    infinite: true,
    className: "center",
    centerMode: true,
    slidesToShow: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    rows: 1,
    slidesPerRow: 1,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
  };

  const MobileSlidersettings = {
    infinite: true,
    className: "center",
    lazyLoad: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    rows: 1,
    slidesPerRow: 1,
    pauseOnHover: true,
    arrows: false,
  };

  useEffect(() => {
    if (deal.length > 0) {
      mapDeals(deal);
    }
  }, [deal]);

  const slider_slides = [];

  

  const mapDeals = (deal) => {
    setmobileDeals(deal);
    setDeals(deal);
  };

  

  const Slide = ({ i, deal, span }) => (
    <Col key={i + "desk" + deal.DealID} span={span}>
      <div className="slide-wrapper1">
        <img
          className="carousel-img"
          src={deal.Image ? `${BASE}${deal.Image.substring(1)}` : slide_1_1}
          alt="slide_1_1"
          style={{ backgroundImage: `url(${deal.Image ? `${BASE}${deal.Image.substring(1)}` : slide_1_1})` }}
        />
      </div>
    </Col>
  );

  const MobSlide = ({ key, deal }) => (
    <Col key={key} span={8}>
     
        <div className="slide-wrapper1">
          <img
            className="carousel-img"
            src={deal.Image ? `${BASE}${deal.Image.substring(1)}` : slide_1_1}
            alt="slide_1_1"
          />
        </div>
     
    </Col>
  );

  return (
    <div key={"homecarousel-1"} className="home_carousel">
      <h3 style={{ color: "#bd0c21" }}>Travel For Less With Our Great Deals</h3>
      <section className="carousel-section desktop_crousal">
        <div className="right-layer d-none"></div>
        <div className="left-layer d-none"></div>
        <div className="container">
          <div className="slides-section">
            <Slider {...settings}>{deals.map((item, i) => {
              return (
                <Slide
                  key={i + "desk" + item.DealID}
                  deal={item}
                  span={23}
                />
              );
            })}</Slider>
          </div>
        </div>
      </section>
      <section className="carousel-section mobile_crousal">
        <div className="right-layer d-none"></div>
        <div className="left-layer d-none"></div>
        <div className="container">
          <div className="slides-section">
            <Slider {...MobileSlidersettings}>
              {mobileDeals.map((item, i) => {
                return (
                  <MobSlide
                    key={"mob" + item.DealID + i}
                    deal={item}
                    span={24}
                  />
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    </div>
  );
};



export default HomeCarousel;
