
import "../banner/Banner.scss";
import { Carousel } from "antd";

const Banner = ({ banner = [] }) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const images = banner.map((item, index) => (
    <img
      key={`banners${index}`}
      className="d-block w-100"
      src={`${BASE}${item.path.substring(1)}`}
      alt="Slide"
      style={{ opacity: 0.01 }}
      onLoad={({ target }) => (target.style.opacity = 1)}
    />
  ));

  return (
    <div id="banner" className="fh-main-cc2">
      {images.length ? (
        <Carousel autoplay dots={false}>
          {images}
        </Carousel>
      ) : (
        <div
          className="d-block w-100"
          style={{
            height: "294px",
            backgroundColor: "#2f81ed",
            backgroundImage: "linear-gradient( 90deg,#2f80ed,#56ccf2)",
            paddingBottom: " 20px",
          }}
        />
      )}
    </div>
  );
};

export default Banner;

