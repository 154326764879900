import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";

import arrow from "../../../assets/images/bg/rightArrow.png";
import moment from "moment";

import LogoImg from "../../../assets/images/Anjmallogo.jpg";

import bg from "../../../assets/images/bg/ticketbg.png";
let dateFormat = "MMM, DD-YYYY";

const styles = StyleSheet.create({
    textDanger: {
      color: "#dc3545",
    },
  
    mb: {
      marginBottom: 10,
    },
  
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
      flexGrow: 1,
    },
  
    wHalf: {
      flex: 0.5,
      marginBottom: 10,
    },
    tw: {
      flex: 0.2,
    },
    fourthHalf: {
      flex: 0.25,
      paddingRight: 5,
    },
  
    width32: {
      flex: 0.32,
    },
    trw: {
      flex: 0.3,
    },
    fw: {
      flex: 0.4,
    },
    sw: {
      flex: 0.6,
    },
    sew: {
      flex: 0.7,
    },
  
    mb8: {
      marginBottom: 8,
    },
    description: {
      paddingLeft: 5,
    },
  
    hr: {
      borderBottom: "1px solid #e2e2e2",
      flex: 1,
      margin: "10px 0",
    },
  
    page: {
      flexDirection: "row",
      padding: "15px 10px",
    },
    section: {
      flex: 1,
    },
    innerSection: {
      flex: 1,
      position: "relative",
    },
    pageBg: {
      position: "absolute",
      flex: 1,
      width: "100%",
      height: 100,
    },
    busWrapper: {
      padding: "25px 10px 10px 10px",
    },
  
    whiteText: { color: "#fff", fontSize: 12, marginBottom: 10, fontWeight: 600 },
    title: { fontSize: 12, fontWeight: 600, wordBreak: "break-word" },
    fadetitle: { color: "#a8a8a8", fontSize: 9 },
    smallText: { fontSize: 10 },
    xsmallText: {
      fontSize: 9,
      marginBottom: 3,
    },
    greytext: { color: "#666", fontSize: 9 },
    card: {
      padding: "10px 5px",
      marginBottom: 10,
      border: "1px solid #e2e2e2",
      borderRadius: 4,
      backgroundColor: "#FFFFFF",
    },
  
    insuranceCard: {
      border: "1px solid #02cb66",
      borderRadius: 6,
      textAlign: "center",
      padding: "10px 5px",
    },
  
    insurancetitle: {
      color: "#18a160",
      fontSize: 10,
    },
  
    dottedBorder: {
      borderBottom: "1px dotted #a8a8a8",
      width: "100%",
    },
  
    coverage: {
      color: "#294782",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: "10px 0",
    },
  
    coveragetitle: {
      color: "#294782",
      fontSize: 9,
    },
    wordBreak: {
      wordBreak: "break-word",
    },
  });
  const getBusBookingStatus = (status) => {
    switch (status) {
      case 2:
        return <Text style={{ color: "#FFA500" }}>BLOCKED</Text>;
  
      case 3:
        return <Text style={{ color: "#008000" }}>CONFIRMED</Text>;
  
      case 5:
        return <Text style={{ color: "#bd0c21" }}>CANCELLED</Text>;
  
      case 7:
        return <Text style={{ color: "#008000" }}>PARTIALLY CANCELLED</Text>;
  
      default:
        return "";
    }
  };
  const CarsDoc=({ ticketData, withFare, agent, Logo})=>{
    const BASE = process.env.REACT_APP_BASE_URL;
      // console.log(ticketData,"ticketdata");
      let discount=0

  const totalAmount =Number(ticketData.totalAmount);
  let promoData = JSON.parse(ticketData.promoData);
  if (promoData) {
    if (promoData.DiscountType == 1) {
      discount = Number((Number(totalAmount) / 100) * promoData?.Discount);
    } else {
      discount = Number(promoData.Discount);
    }
  }
  let convienenceData = JSON.parse(ticketData.convienenceData);
    return (
        <Document>
      <Page size="A4" style={styles.page} wrap={true}>
        {ticketData?.BookingStatus ? (
          <View style={styles.section}>
            {Logo ? (
              <Image
                src={BASE + Logo.substring(1)}
                style={{ height: 50, width: 100, marginBottom: 10 }}
              ></Image>
            ) : (
              <Image style={{ width: 100, heigh: 50 }} src={LogoImg} />
            )}
            <View style={styles.innerSection}>
              <Image src={bg} style={styles.pageBg} />
              <View style={styles.busWrapper}>
                <Text style={styles.whiteText}>
                {ticketData.Destination === "" ? 
            <>
            {ticketData.Source} 
            </>
             : 
            <>
            {ticketData.Source} To {ticketData.Destination}
            </>
             }
                </Text>

                <View style={styles.card} wrap={false}>
                  <View style={styles.row}>
                    <View style={styles.fw}>
                      <View style={styles.row}>
                        <View style={styles.fw}>
                          <Text style={styles.title}>
                            {ticketData.Source}
                          </Text>
                          <Text style={styles.fadetitle}>
                            {ticketData.operator}
                          </Text>
                        </View>
                        {ticketData.Destination === "" ? "" :
                    <>
                        <View style={styles.tw}>
                          <Image
                            src={arrow}
                            style={{ width: 16, height: 10, marginTop: 1 }}
                          />
                        </View>

                        <View style={styles.fw}>
                          <Text style={styles.title}>
                            {ticketData.Destination}
                          </Text>
                        </View>
                        </>
            }
                      </View>
                    </View>
                    <View style={styles.sw}>
                      <View style={styles.row}>
                        <View style={styles.trw}>
                          <Text style={styles.title}>
                            {moment(
                              ticketData.JourneyDate
                            ).format(dateFormat)}
                          </Text>
                          <Text style={styles.fadetitle}>Journey Date</Text>
                        </View>
                        <View style={styles.sew}>
                          <Text
                            style={{ ...styles.title, ...styles.wordBreak }}
                          >
                            {ticketData.BookingConfirmationNo}
                          </Text>
                          <Text style={styles.fadetitle}>PNR/Booking No</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={styles.hr}></View>

                  <View style={styles.row}>
                    <View style={styles.fourthHalf}>
                      <Text style={styles.title}>
                        {ticketData.BookingRefNo}
                      </Text>
                      <Text style={styles.fadetitle}>Ticket Ref Number</Text>
                    </View>
                    <View style={styles.fourthHalf}>
                      <Text style={styles.title}>
                        {getBusBookingStatus(ticketData.BookingStatus)}
                      </Text>
                      <Text style={styles.fadetitle}>Status</Text>
                    </View>
                    <View style={styles.fourthHalf}>
                      <Text style={styles.title}>{ticketData.car.SeatCapacity}</Text>
                      <Text style={styles.fadetitle}>Seat(s)</Text>
                    </View>
                    <View style={styles.fourthHalf}>
                      <Text style={styles.title}>Paid</Text>
                      <Text style={styles.fadetitle}>Payment Status</Text>
                    </View>
                  </View>
                </View>

                {/* bus info */}

                <View style={styles.card} wrap={false}>
                  <View style={styles.mb}>
                    <Text style={styles.title}>Car Details</Text>
                    <Text style={styles.fadetitle}>
                      Here are the details of your car from{" "}
                      {ticketData.Source} to {ticketData.Destination} on{" "}
                      {moment(ticketData.JourneyDate).format(
                        dateFormat
                      )}
                    </Text>
                  </View>

                  <View style={{ ...styles.row, ...styles.mb }}>
                    <View style={styles.fw}>
                      <Text style={styles.smallText}>
                        {ticketData.Vendor.BrandName}
                      </Text>
                      <Text style={styles.fadetitle}>Operator</Text>
                    </View>

                    <View style={styles.sw}>
                      <View style={{ ...styles.row, alignItems: "flex-start" }}>
                        <View style={styles.fw}>
                          <Text style={styles.title}>
                            {ticketData.Source}
                          </Text>
                          <Text style={styles.fadetitle}>
                            {ticketData.PickUpTime??0}
                          </Text>
                        </View>
                        {ticketData.Destination === "" ? "" :
                      <>
                        <View
                          style={{
                            flex: 1,
                            margin: "5px 2px 0 2px",
                            paddingRight: 5,
                          }}
                        >
                          <View style={styles.dottedBorder}></View>
                        </View>

                        <View style={styles.fw}>
                          <Text style={styles.title}>
                            {ticketData.Destination}
                          </Text>
                          <Text style={styles.fadetitle}>
                            {ticketData.ArrivalTime??0}
                          </Text>
                        </View>
                        </>
  }
                      </View>
                    </View>
                  </View>

                  <View style={styles.row}>
                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>Car Type</Text>
                      <Text
                        style={{ ...styles.fadetitle, ...styles.textDanger }}
                      >
                        {ticketData.carTypeName??""}
                      </Text>
                    </View>
                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>Departure Time</Text>
                      <Text
                        style={{ ...styles.fadetitle, ...styles.textDanger }}
                      >
                        {ticketData.PickUpTime}
                      </Text>
                    </View>
                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>Boarding Point</Text>
                      <Text
                        style={{ ...styles.fadetitle, ...styles.textDanger }}
                      >
                        {ticketData.PickUpAddress}
                      </Text>
                    </View>
                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>Droping Point</Text>
                      <Text
                        style={{ ...styles.fadetitle, ...styles.textDanger }}
                      >
                        {ticketData.DropAddress}
                      </Text>
                    </View>
                  </View>
                </View>

                {/* Contact Details */}

                <View style={styles.card} wrap={false}>
                  <View style={styles.mb}>
                    <Text style={styles.title}>Passenger Details</Text>
                  </View>

                  <View style={styles.row}>
                    <View style={styles.width32}>
                      <Text style={styles.fadetitle}>Name</Text>
                      <Text style={styles.smallText}>
                        {ticketData.GuestTitle}{" "}
                        {ticketData.GuestName}
                      </Text>
                    </View>

                    <View style={styles.width32}>
                      <Text style={styles.fadetitle}>Mobile Number</Text>
                      <Text style={styles.smallText}>
                        {ticketData.MobileNo}
                      </Text>
                    </View>
                    <View style={styles.width32}>
                      <Text style={styles.fadetitle}>Email</Text>
                      <Text style={styles.smallText}>{ticketData.EmailId}</Text>
                    </View>
                  </View>
                </View>
                {/* Driver Details */}

                <View style={styles.card} wrap={false}>
                  <View style={styles.mb}>
                    <Text style={styles.title}>Driver Details</Text>
                  </View>

                  <View style={styles.row}>
                    <View style={styles.width32}>
                      <Text style={styles.fadetitle}>Name</Text>
                      <Text style={styles.smallText}>
                        {ticketData.driver.Name}
                      </Text>
                    </View>

                    <View style={styles.width32}>
                      <Text style={styles.fadetitle}>Mobile Number</Text>
                      <Text style={styles.smallText}>
                        {ticketData.driver.MobileNo}
                      </Text>
                    </View>
                    <View style={styles.width32}>
                      <Text style={styles.fadetitle}>Gender</Text>
                      <Text style={styles.smallText}>{ticketData.driver.Gender==="Male"?"Male":"Felmale"}</Text>
                    </View>
                    <View style={styles.width32}>
                      <Text style={styles.fadetitle}>Age</Text>
                      <Text style={styles.smallText}>{ticketData.driver.Age}</Text>
                    </View>
                  </View>
                </View>
                {/* insurance  */}

             

                {/* fare */}
                {withFare ? (
                  <View>
                    <View style={styles.card} wrap={false}>
                      <View style={styles.mb}>
                        <Text style={styles.title}>Fare Details</Text>
                      </View>

                      <View style={{ ...styles.row, ...styles.mb8 }}>
                        <View style={styles.sw}>
                          <Text style={styles.smallText}>Base Fare</Text>
                        </View>

                        <View style={styles.fourthHalf}>
                          <Text style={styles.smallText}>
                          {ticketData?.currency}  {parseFloat((Number(ticketData.BasicRate) / Number(ticketData?.currencyRatio)).toFixed(2))}
                          </Text>
                        </View>
                      </View>

                      
                      <View style={{ ...styles.row, ...styles.mb8 }}>
                        <View style={styles.sw}>
                          <Text style={styles.smallText}>Driver Charges</Text>
                        </View>

                        <View style={styles.fourthHalf}>
                          <Text style={styles.smallText}>
                          {ticketData?.currency}  {parseFloat((Number(ticketData.DriverCharges) / Number(ticketData?.currencyRatio)).toFixed(2))}
                          </Text>
                        </View>
                      </View>
                      <View style={{ ...styles.row, ...styles.mb8 }}>
                        <View style={styles.sw}>
                          <Text style={styles.smallText}>GST</Text>
                        </View>

                        <View style={styles.fourthHalf}>
                          <Text style={styles.smallText}>
                          {ticketData?.currency}  {parseFloat((Number(ticketData.GST) / Number(ticketData?.currencyRatio)).toFixed(2))}
                          </Text>
                        </View>
                      </View>
                      <View style={{ ...styles.row, ...styles.mb8 }}>
                        <View style={styles.sw}>
                          <Text style={styles.smallText}>State Toll Tax</Text>
                        </View>

                        <View style={styles.fourthHalf}>
                          <Text style={styles.smallText}>
                          {ticketData?.currency}  {parseFloat((Number(ticketData.StateTollTax??0) / Number(ticketData?.currencyRatio)).toFixed(2))}
                          </Text>
                        </View>
                      </View>
                      {convienenceData > 0 ? 
                      <View style={{ ...styles.row, ...styles.mb8 }}>
                        <View style={styles.sw}>
                          <Text style={styles.smallText}>Convenience Fee</Text>
                        </View>

                        <View style={styles.fourthHalf}>
                          <Text style={styles.smallText}>
                          {ticketData?.currency}  {parseFloat((Number(convienenceData.amount) / Number(ticketData?.currencyRatio)).toFixed(2))}
                           </Text>
                        </View>
                      </View>
                      : null}
                     
                      {discount > 0 ? 
                      <View style={{ ...styles.row, ...styles.mb8 }}>
                        <View style={styles.sw}>
                          <Text style={styles.smallText}>Discount</Text>
                        </View>

                        <View style={styles.fourthHalf}>
                          <Text style={styles.smallText}>
                          {ticketData?.currency}  {parseFloat((Number(discount) / Number(ticketData?.currencyRatio)).toFixed(2))}
                          </Text>
                        </View>
                      </View>
                      : null}

                      <View style={{ ...styles.row, ...styles.mb8 }}>
                        <View style={styles.sw}>
                          <Text style={styles.smallText}>Total</Text>
                        </View>

                        <View style={styles.fourthHalf}>
                          <Text style={styles.smallText}>
                            
                            {ticketData?.currency}  {parseFloat((Number(ticketData.totalPrice) / Number(ticketData?.currencyRatio)).toFixed(2))}
                            </Text>
                        </View>
                      </View>

                      {ticketData?.BookingStatus != 3 ? (
                        <View style={{ ...styles.row, ...styles.mb8 }}>
                          <View style={styles.sw}>
                            <Text style={styles.smallText}>Refund Amount</Text>
                          </View>

                          <View style={styles.fourthHalf}>
                            <Text style={styles.smallText}>
                              INR 00
                            </Text>
                          </View>
                        </View>
                      ) : null}
                    </View>
                  </View>
                ) : null}
                {/*  */}
              </View>
            </View>
          </View>
        ) : null}
      </Page>
    </Document>
    );
  }
  export default CarsDoc;