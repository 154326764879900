import React, { useEffect, useState } from "react";
import excel from "../../assets/vector-icons/excel.png";
import search from "../../assets/vector-icons/search.png";
import exchange from "../../assets/vector-icons/exchange.png";
import HelpInfoHelper from "../../../common/HelpInfoHelper/HelpInfoHelper";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Table,
  Layout,
  Modal,
  Upload,
  Image,
  Select,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  CloseOutlined,
  EyeOutlined,
  LoadingOutlined,
  DeleteOutlined,
  UserOutlined,
  IdcardOutlined,
  PhoneOutlined,
  FileOutlined,
  MailOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import ApiClient from "../../../helpers/ApiClient";
import UpdatedComponent from "../../ExportExcel";
import moment from "moment";
import "./ManageCompany.scss";

const { TextArea } = Input;
const { Content } = Layout;
const { Option } = Select;

const ManageCompany = ({ exportExcel }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [countryLists, setCountryList] = useState([]);
  const [formManageCompany] = Form.useForm();
  const [companyList, setCompanyList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [currID, setCurrID] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [excelList, setExcelList] = useState([]);
  const [cityLists, setCityList] = useState([]);

  useEffect(() => {
    getAllComapany();
    getCountriesLists();
  }, []);

  const getCountriesLists = () => {
    ApiClient.get("admin/country")
      .then((res) => {
        if (res.status == 200) {
          setCountryList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getAllComapany = () => {
    ApiClient.get("CorporateExtranet/GetCompany")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setCompanyList(data);
          defineExcelData(data);
        } else {
          setCompanyList([]);
        }
      })
      .catch((e) => {
        setCompanyList([]);
      });
  };

  const countryCityList = (val) => {
    let countryName = {
      country: val,
    };
    ApiClient.post("admin/citiesbycountry", countryName)
      .then((res) => {
        if (res.status == 200) {
          setCityList(res.data);
        } else {
          message(res.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitForm = (values) => {
    isEdit ? updateManageCompany(values, currID) : addManageCompany(values);
  };

  const addManageCompany = (data) => {
    ApiClient.post("CorporateExtranet/CreateCompany", data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Company added successfully !");

          getAllComapany();
          setModalVisible(false);
          formManageCompany.resetFields();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateManageCompany = (data, recId) => {
    ApiClient.put("CorporateExtranet/UpdateCompany/" + recId, {}, data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Company updated successfully !");

          getAllComapany();
          setModalVisible(false);
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteManageCompany = (id) => {
    ApiClient.delete("CorporateExtranet/DeleteCompany/" + id)
      .then((response) => {
        if (response.status == 200) {
          message.success(" Company Deleted successfully !");
          getAllComapany();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const showModal = () => {
    setIsEdit(false);
    formManageCompany.resetFields();

    setModalVisible({
      visible: true,
    });
  };

  const onUpdate = (currentData) => {
    formManageCompany.setFieldsValue({
      ...currentData,
    });
    setIsEdit(true);
    setModalVisible(true);
    setCurrID(currentData.CompanyId);
  };

  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        UserName: item.UserName,
        Email: item.Email,
        ContactNumber: item.ContactNumber,
        Pincode: item.Pincode,
        Text: item.Text,
        City: item.City,
        Country: item.Country,
        Address: item.Address,
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const tableActions = (currentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}
          onClick={() => onUpdate(currentItem)}
        >
          <EditOutlined
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteManageCompany(currentItem.CompanyId)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const getCmsType = (type) => {
    switch (type) {
      case 1:
        return "Terms and Conditions";
      case 2:
        return "Cancel Policy ";
      default:
        return "";
    }
  };

  const columns = [
    {
      title: "UserName",
      dataIndex: "UserName",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Contact Number",
      dataIndex: "ContactNumber",
    },
    {
      title: "Pincode",
      dataIndex: "Pincode",
    },
    {
      title: "Text",
      dataIndex: "Text",
    },
    {
      title: "Country",
      dataIndex: "Country",
    },
    {
      title: "City",
      dataIndex: "City",
    },
    {
      title: "Address",
      dataIndex: "Address",
    },
    // {
    //   title: "Status",
    //   // render: (currentItem) => handelStatus(currentItem),
    // },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];

  // const handelStatus = (data) => {
  //   <Switch
  //     checkedChildren="Active"
  //     unCheckedChildren="Inactive"
  //     checked={data.Status == 1 ? true : false}
  //     onChange={() => {
  //       changeStatus(data, data.Status == 1 ? 0 : 1);
  //     }}
  //   />;
  // };

  // const changeStatus = (info, Status) => {
  //   delete info.SNo;
  //   delete info.MediaFor;
  //   delete info.HotelImageTypeId;
  //   delete info.RoomName;
  //   delete info.HotelId;
  //   let obj = {
  //     ...info,
  //     Status: Status,
  //   };
  //   let id = info?.Images[0]?.MediaId;

  //   // UpdateCategory(obj, id);
  // };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = companyList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setCompanyList(searchResults);
    } else {
      getAllComapany();
      setCompanyList(companyList);
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };
  const selectCustomFilter = (input, option) => {
    let istext =
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    if (istext) {
      return true;
    }
  };
  return (
    <div className="promo providerapi">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          View Company{" "}
                          <HelpInfoHelper screenName={"/admin/managecompany"} />
                        </h5>
                        {companyList.length > 0 && (
                          <p>{companyList.length} rows found !</p>
                        )}
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img
                        src={excel}
                        alt="excel"
                        onClick={(e) => exportExcel(excelList, "ManageCompany")}
                      />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getAllComapany()}
                      />
                      <p className="add-icon" onClick={showModal}>
                        <PlusOutlined />
                      </p>
                    </div>
                  </div>
                  {companyList.length > 0 ? (
                    <div>
                      <Table
                        className="table-scroll-none"
                        bordered
                        dataSource={companyList}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  ) : (
                    <b>No record Found</b>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>{isEdit ? "Update Company" : "Add Company"}</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header admin-preference modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              htmlType="submit"
              type="primary"
              onClick={formManageCompany.submit}
            >
              {isEdit ? "Update" : "Add"}
            </Button>
            <Button
              type="danger"
              className="cancel-btn"
              onClick={() => formManageCompany.resetFields()}
            >
              Clear
            </Button>
          </div>,
        ]}
      >
        <Form
          layout={"vertical"}
          name="normal_login"
          className="login-form"
          form={formManageCompany}
          onFinish={submitForm}
        >
          <Row gutter={[32, 16]}>
            <Col xs={24} md={6}>
              <Form.Item
                name="UserName"
                label="User Name"
                rules={[
                  { required: true, message: "Please input your Username!" },
                ]}
                style={{ marginBottom: "0px" }}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Username"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                name="Text"
                label="Text"
                style={{ marginBottom: "0px" }}
              >
                <Input
                  prefix={<FileOutlined className="site-form-item-icon" />}
                  placeholder="Text"
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={6}>
              <Form.Item
                name="Address"
                label="Address"
                rules={[
                  { required: true, message: "Please input your Address!" },
                ]}
                style={{ marginBottom: "0px" }}
              >
                <Input
                  prefix={<IdcardOutlined className="site-form-item-icon" />}
                  placeholder="Enter Your Address"
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={6}>
              <Form.Item
                name="AddressLine2"
                label="Address Line 2"
                rules={[
                  {
                    required: true,
                    message: "Please input your AddressLine2!",
                  },
                ]}
                style={{ marginBottom: "0px" }}
              >
                <Input
                  prefix={<IdcardOutlined className="site-form-item-icon" />}
                  placeholder="Address Line2"
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={6}>
              <Form.Item
                name="Country"
                label="Country"
                rules={[
                  { required: true, message: "Please input your Country!" },
                ]}
                style={{ marginBottom: "0px" }}
              >
                <Select
                  prefix={<IdcardOutlined className="site-form-item-icon" />}
                  showSearch
                  placeholder="Select Country"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(val, _) => {
                    countryCityList(val);
                  }}
                >
                  {countryLists.map((i) => (
                    <Select.Option value={i.Country}>{i.Country}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={6}>
              <Form.Item
                name="City"
                label="City"
                rules={[{ required: true, message: "Please input your City!" }]}
                style={{ marginBottom: "0px" }}
              >
                <Select
                  prefix={<IdcardOutlined className="site-form-item-icon" />}
                  placeholder="Select Your City"
                  filterOption={(val, op) => selectCustomFilter(val, op)}
                  showSearch
                >
                  {cityLists.map((i) => (
                    <Select.Option value={i}>{i}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={6}>
              <Form.Item
                name="Pincode"
                label="Pincode"
                rules={[
                  { required: true, message: "Please input your Pincode!" },
                ]}
                style={{ marginBottom: "0px" }}
              >
                <Input
                  prefix={<IdcardOutlined className="site-form-item-icon" />}
                  placeholder="Pincode"
                  type="number"
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={6}>
              <Form.Item
                name="ContactNumber"
                label="Contact Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your contact number!",
                  },
                  {
                    minLength: 10,
                    maxLength: 10,
                    pattern: "^[0-9]{10}$",
                    message: "Must be 10 digits Numeric Value",
                  },
                ]}
                style={{ marginBottom: "0px" }}
              >
                <Input
                  prefix={<PhoneOutlined className="site-form-item-icon" />}
                  placeholder="Contact No"
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={6}>
              <Form.Item
                name="Email"
                label="Email"
                rules={[
                  { type: "email" },
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
                style={{ marginBottom: "0px" }}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={6}>
              <Form.Item
                name="Status"
                label="Status"
                rules={[
                  { required: true, message: "Please Select Your Status" },
                ]}
                style={{ marginBottom: "0px" }}
              >
                <Select
                  prefix={<IdcardOutlined className="site-form-item-icon" />}
                  placeholder="Select Status"
                >
                  <Select.Option value={0}>yes</Select.Option>
                  <Select.Option value={1}>No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdatedComponent(ManageCompany);
