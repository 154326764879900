import { CaretRightOutlined, CloseOutlined, EditOutlined, } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Layout,
  Row,
  Modal,
  Select,
  Spin,
  Table,
  Tooltip,
  message,
} from "antd";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../components/Bookingreports/Bookingreports.scss";
import ApiClient from "../../helpers/ApiClient";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import UpdatedComponent from "../ExportExcel";
import {
  allColumns,
  activitiesColumns,
  busColumns,
  renderReffrence,
  buildPackageColumns,
  flightColumns,
} from "./superReportHelper";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
let uiDateFormat = "DD-MM-YYYY";
const { Option } = Select;
const { Panel } = Collapse;
const { Content } = Layout;
export const renderSingleRef = (ref, rec) => {
  const type = rec.type;
  // console.log(type,"typ");
  let url = "";
  switch (type) {
    case "Flight": {
      url = "/admin/flight/ticket";
      break;
    }
    case "Bus": {
      url = "/admin/bus/ticket";
      break;
    }
    case "Hotel": {
      url = "/admin/hotel/ticket";
      break;
    }
    case "Activities": {
      url = "/admin/activities/ticket";
      break;
    }
    case "BuildPackage": {
      url = "/admin/buildpackage/ticket";
      break;
    }
    case "transfer": {
      url = "/admin/cartransfer/ticket";
      break;
    }
    case "Visa": {
      url = "/admin/visa/ticket";
      break;
    }
  }
  return <Link to={url + "?ref=" + ref}>{ref}</Link>;
};
export const getHotelStatus = (status) => {
  // console.log(status, "stt");
  switch (status) {
    case 1: {
      return <li style={{ color: "#FFA500" }}>FAILED</li>;
    }
    case 2: {
      return <li style={{ color: "#008000" }}>CONFIRMED</li>;
    }
    case 3: {
      return <li style={{ color: "#bd0c21" }}>CANCELED</li>;
    }
    case 4: {
      return <li style={{ color: "#bd0c21" }}>PENDING</li>;
    }
    case 5: {
      return <li style={{ color: "#f9e218" }}>REJECTED</li>;
    }
    case 6: {
      return <li style={{ color: "#bd0c21" }}>HOLD</li>;
    }
    case 7: {
      return <li style={{ color: "#bd0c21" }}>CANCELLATION REQUESTED</li>;
    }
    case 8: {
      return <li style={{ color: "#bd0c21" }}>CANCELLATION PENDING</li>;
    }
    case 9: {
      return <li style={{ color: "#bd0c21" }}>CANCELLATION PROGRESS</li>;
    }
    case "FAILED": {
      return <li style={{ color: "#FFA500" }}>FAILED</li>;
    }
    case "CONFIRMED": {
      return <li style={{ color: "#008000" }}>CONFIRMED</li>;
    }
    case "CANCELED": {
      return <li style={{ color: "#bd0c21" }}>CANCELED</li>;
    }
    case "CANCELLED": {
      return <p style={{ color: "#bd0c21" }}>CANCELLED</p>;
    }
    case "PENDING": {
      return <p style={{ color: "#bd0c21" }}>PENDING</p>;
    }
    case "REJECTED": {
      return <li style={{ color: "#f9e218" }}>REJECTED</li>;
    }
    case "HOLD": {
      return <li style={{ color: "#bd0c21" }}>HOLD</li>;
    }
    case "CANCELLATIONREQUESTED": {
      return <li style={{ color: "#bd0c21" }}>CANCELLATION REQUESTED</li>;
    }
    case "CANCELLATIONliENDING": {
      return <li style={{ color: "#bd0c21" }}>CANCELLATION PENDING</li>;
    }
    case "CancellationInlirogress": {
      return <li style={{ color: "#bd0c21" }}>CANCELLATION PROGRESS</li>;
    }
    case "NO": {
      return <li style={{ color: "#bd0c21" }}>NOT BOOKED</li>;
    }
   

    default:
      return;
  }
};

export const getStatus = (status) => {
  switch (status) {
    case "BLOCKED": {
      return <p style={{ color: "#FFA500" }}>{status}</p>;
    }
    case "CONFIRMED": {
      return <p style={{ color: "#008000" }}>{status}</p>;
    }
    case "BOOKED": {
      return <p style={{ color: "#008000" }}>{status}</p>;
    }
    case "CANCELLED": {
      return <p style={{ color: "#bd0c21" }}>{status}</p>;
    }
    case "CREATED": {
      return <p style={{ color: "#f9e218" }}>{status}</p>;
    }
    case "HOLD": {
      return <p style={{ color: "#f9e218" }}>{status}</p>;
    }
    case "PARTIALLY CANCELLED": {
      return <p style={{ color: "#bd0c21" }}>{status}</p>;
    }
    case "ALREADYCANCELLED": {
      return <p style={{ color: "#bd0c21" }}>{status}</p>;
    }
    case "PENDING": {
      return <p style={{ color: "#bd0c21" }}>{status}</p>;
    }
    case "FAILED": {
      return <p style={{ color: "#bd0c21" }}>{status}</p>;
    }
    case "REJECTED": {
      return <p style={{ color: "#bd0c21" }}>{status}</p>;
    }
    case "CancellationRequest": {
      return <p style={{ color: "#bd0c21" }}>{status}</p>;
    }
    case "CancellationPending": {
      return <p style={{ color: "#bd0c21" }}>{status}</p>;
    }
    case "CancellationInProgress": {
      return <p style={{ color: "#bd0c21" }}>{status}</p>;
    }
    default:
      return;
  }
};
export const dateFormat = "DD-MM-YYYY";
export const oriDateFormat = "YYYY-MM-DD";
const HotelBookingReports = (props) => {
  const [reportRole, setReportRole] = useState(0);
  const [travelType, setTravelType] = useState(2);
  const [agentList, setAgentList] = useState([]);
  const [form] = Form.useForm();
  const [sConfUpdate, setsConfUpdate] = useState({});
  const [showTable, setShowTable] = useState(false);

  const [isDateDisplay, setIsDateDisplay] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  // console.log(sConfUpdate, "ddddd");
  const hotelColumns = [
    {
      title: "Bked By",
      dataIndex: "BookedBy",
    },
    {
      title: "RefNo",
      dataIndex: "referenceNumber",
      render: (text, record) => renderSingleRef(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },
    {
      title: "Update",
      render: (rec) => {
        return (rec?.type === "Hotel" && rec?.BookingStatus === "CONFIRMED" && rec?.SupplierConfirmationNo == "") ? (
          tableActions(rec)
        ) : (rec?.SupplierConfirmationNo != "" && rec?.BookingStatus === "CONFIRMED" ? "Updated" : null);
      }
    },

    // {
    //   title: "Update",
    //   render: (rec) => {
    //     (rec?.type === "Hotel" && rec?.BookingStatus === "CONFIRMED") ? (
    //       <span
    //         style={{ cursor: "pointer" }}
    //         onClick={() => tableActions(rec)}
    //       >
    //         Update
    //       </span>
    //     ) : null
    //     // return tableActions(rec);
    //   }
    // },
    {
      title: "Htl Name",
      dataIndex: "HotelName",
      key: "HotelName",
    },
    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
    },
    {
      title: "ChkIn Date",
      dataIndex: "CheckInDate",
      key: "CheckInDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
    },
    {
      title: "ChkOut Date",
      dataIndex: "CheckOutDate",
      key: "CheckOutDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
    },
    // {
    //   title: "Jrny Date",
    //   dataIndex: "journeyDate",
    // },
    {
      title: "Bkng Status",
      dataIndex: "BookingStatus",
      key: "BookingStatus",
      render: (status) => {
        if (status === undefined) {
          return getHotelStatus("NO");
        } else {
          return getHotelStatus(status);
        }
      },
    },
    {
      title: "Rooms",
      dataIndex: "NoOfRooms",
    },
    {
      title: "Adults",
      dataIndex: "pax",
      render: (value) => value.adults,
    },
    {
      title: "Childs",
      dataIndex: "pax",
      render: (value) => value.childCount,
    },
    {
      title: "Amt(Rs.)",
      dataIndex: "totalPrice",
    },
    {
      title: "Pax Email",
      dataIndex: "guestEmaiId",
    },
    {
      title: "Pax Mobile ",
      dataIndex: "guestMobileNo",
    },
    {
      title: "Star Rtng",
      dataIndex: "StarRating",
    },
  ];
  const tableActions = (rec) => {
    // { console.log(rec, id, "id-2") }
    // setsConfUpdate(true);
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>

        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              // setId(rec.ServiceID);
              handleClick(rec);
            }}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
      </div>
    );
  };
  const handleClick = (rec) => {
    // console.log(rec, "id");
    form.resetFields();
    form.setFieldsValue({
      refNumber: rec.referenceNumber,
    });

    // setIsEditMode(true);

    showModal();
  };
  const showModal = () => {

    setModalVisible({
      visible: true,
    });
  };
  const handleSubmit = (values) => {
    let data = {
      RefNumber: values.refNumber,
      supplierConfirmationNo: values.confirmationNo
    }
    ApiClient.put(`admin/updateHotelSupplierConfirmationNo/${values.refNumber}`, {}, data)
      .then((res) => {
        if (res.status === 200) {

          getBookingListData(sConfUpdate);
          setModalVisible(false);
          message.success("Supplier Confirmation updated successfully");
          // setsConfUpdate(true);
        }
        else {
          message.error(" Error Updating Confirmation No ");
        }
      })
      .catch((error) => {
        console.log(error)
      });

  };
  const [modalVisible, setModalVisible] = useState(false);
  // const [isEditMode, setIsEditMode] = useState(true);
  const [loading, setLoading] = useState(false);
  // const [id, setId] = useState(-1);
  const [toDisableDate, setToDisableDate] = useState(moment());
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  useEffect(() => {
    getProviders();

  }, []);
  const getProviders = () => {
    ApiClient.get("admin/b2b/user")
      .then((res) => {
        if (res.status == 200) {
          // let filterProvider = res.data.filter((item) => item.Status === 1);
          setAgentList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );


  const searchData = () => {
    setShowSearchBox(true);
  };
  const handleExcel = () => {
    const excelData = dataSource;
    props.exportExcel(excelData, "Booking_Reports");
  };

  /* Creating service dynamic column headers */
  const setTableHeaders = (travelType) => {
    switch (travelType) {
      case 1:
        setColumns(flightColumns);
        break;
      case 2:
        setColumns(hotelColumns);
        break;
      case 3:
        setColumns(busColumns);
        break;
      case 4:
        setColumns(activitiesColumns);
        break;
      case 5:
        setColumns(buildPackageColumns);
        break;
      default:
        setColumns(allColumns);
    }
  };

  const getBookingListData = (values) => {
    setLoading(true);

    ApiClient.get("admin/myBookings/report", values)
      .then((res) => {
        setLoading(false);

        if (res.statusCode == 200) {
          setDataSource(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    let prevData = dataSource;
    if (searchVal.length > 0) {
      const searchResults = dataSource.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      setDataSource(prevData);
    }
  };

  const handleTimeAndDate = (value) => {
    if (value === 5) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };
  const onFormSubmit = (values) => {
    if (values.options == 5) {
      values.fromDate = moment(values.fromDate).format(oriDateFormat);
      values.toDate = moment(values.toDate).format(oriDateFormat);
    } else {
      values.fromDate = "";
      values.toDate = "";
    }
    if (values.journeyDate) {
      values.journeyDate = moment(values.journeyDate).format(oriDateFormat);
    } else {
      values.journeyDate = "";
    }

    setDataSource([]);
    setTableHeaders(2);
    setsConfUpdate({ ...values, travelType: 2 });
    getBookingListData({ ...values, travelType: 2 });
  };

  const BookingReportsHeader = () => (
    <>
      Hotel Reports{" "}
      <HelpInfoHelper screenName={"/admin/reports/flightReport"} />
    </>
  );

  const disabledFutureDate = (currentDate) => {
    return currentDate >= moment();
  };
  const onChangeFromDate = (momentdate, _) => {
    setToDisableDate(momentdate);
  };
  const disabledSelectedDate = (currentDate) => {
    return currentDate < moment(toDisableDate).startOf("day");
  };

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <div>
                  <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <Tooltip
                        placement="top"
                        title={isActive ? "Show" : "Hide"}
                      >
                        <CaretRightOutlined rotate={isActive ? 90 : -90} />
                      </Tooltip>
                    )}
                    expandIconPosition="right"
                    className="panel_wrapper"
                    defaultActiveKey={["1"]}
                  >
                    <Panel header={<BookingReportsHeader />} key="1">
                      <Form
                        form={form}
                        layout={"vertical"}
                        initialValues={{
                          options: 3,
                          bookingStatus: "",
                          emailId: "",
                          journeyDate: "",
                          paxName: "",
                          phoneNumber: "",
                          referenceNo: "",
                          fareType: "",
                          travelType: 3,
                          Role: 0,
                          tripType: "",
                        }}
                        onFinish={onFormSubmit}
                      >
                        <Row gutter={16}>
                          <Col className="gutter-row" md={6} xs={24}>
                            <Form.Item
                              label="Time and Date"
                              name="options"
                              rules={[{ required: true }]}
                            >
                              <Select
                                placeholder="Please select"
                                onChange={handleTimeAndDate}
                              >
                                <Option value={1}>Last One Hour</Option>
                                <Option value={2}>Yesterday</Option>
                                <Option value={3}>Last Week</Option>
                                <Option value={5}>Custom Dates</Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          {isDateDisplay ? (
                            <>
                              <Col md={6} sm={12} xs={24}>
                                <Form.Item label="From Date" name="fromDate">
                                  <DatePicker
                                    // format="DD-MM-YYYY"
                                    style={{ width: "100%" }}
                                    format={uiDateFormat}
                                    disabledDate={disabledFutureDate}
                                    onChange={(date, dateString) =>
                                      onChangeFromDate(date, dateString)
                                    }
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={6} sm={12} xs={24}>
                                <Form.Item label="To Date" name="toDate">
                                  <DatePicker
                                    format="DD-MM-YYYY"
                                    style={{ width: "100%" }}
                                    disabledDate={disabledSelectedDate}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          ) : null}

                          <Col md={6} xs={24}>
                            <Form.Item label="Reports of" name="Role">
                              <Select
                                placeholder="Select"
                                onSelect={(val) => setReportRole(val)}
                              >
                                <Option value={0}>All</Option>
                                <Option value={2}>Users</Option>
                                <Option value={4}>Guest</Option>
                                <Option value={5}>Partner</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          {reportRole === 5 && (
                            <Col md={6} xs={24}>
                              <Form.Item label="Partner" name="UserId">
                                <Select placeholder="Select Partner">
                                  {agentList.map((data, index) => (
                                    <Option key={index} value={data.UserID}>
                                      {data.userDetails.FirstName}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          )}

                          {/* <Col className="gutter-row" md={6} xs={24}>
                            <Form.Item label="Service" name="travelType">
                              <Select
                                placeholder="Select"
                                onSelect={(val) => setTravelType(val)}
                              >
                                <Option value={0}>All</Option>
                                <Option value={1}>Hotel</Option>
                                <Option value={2}>Hotels</Option>
                                <Option value={3}>Bus</Option>
                                <Option value={4}>Activities</Option>
                              
                              </Select>
                            </Form.Item>
                          </Col> */}

                          {travelType === 1 ? (
                            <>
                              <Col md={6} xs={24}>
                                <Form.Item label="Coupan Type" name="fareType">
                                  <Select placeholder="Select Travel Type">
                                    <Option value="PUBLISH">Publish</Option>
                                    <Option value="GENERAL">General </Option>
                                    <Option value="COUPONFARE">
                                      Coupon Fare
                                    </Option>
                                    <Option value="CORPORATEFARE">
                                      Corporate Fare
                                    </Option>
                                    <Option value="SMEFARE">SME Fare</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col md={6} xs={24}>
                                <Form.Item label="Trip Type" name="tripType">
                                  <Select placeholder="Select">
                                    <Option value="oneWay">One way</Option>
                                    <Option value="roundTrip">
                                      Round Trip
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </Col>{" "}
                            </>
                          ) : null}

                          <Col md={6} xs={24}>
                            <Form.Item
                              label="Reference number"
                              name="referenceNo"
                            >
                              <Input placeholder="Enter Reference Number" />
                            </Form.Item>
                          </Col>
                          <Col md={6} xs={24}>
                            <Form.Item label="Booking Date" name="journeyDate">
                              <DatePicker
                                style={{ width: "100%" }}
                                placeholder="Select Journey date"
                                format={dateFormat}
                              />
                            </Form.Item>
                          </Col>

                          {/* <Col md={6} xs={24}>
                                <Form.Item label="PNR Number" name="pnr">
                                  <Input />
                                </Form.Item>
                              </Col> */}
                          <Col md={6} xs={24}>
                            <Form.Item
                              label="Pax Email"
                              name="emailId"
                              rules={[{ type: "email" }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col md={6} xs={24}>
                            <Form.Item
                              label="Pax Mobile"
                              name="phoneNumber"
                              rules={[
                                {
                                  minLength: 10,
                                  maxLength: 10,
                                  pattern: "^[0-9]{10}$",
                                  message: "Must be 10 digits",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </Col>

                          <Col md={6} xs={24}>
                            <Form.Item
                              label="Booking Status"
                              name="bookingStatus"
                            >
                              <Select placeholder="Please Select">
                                <Option value={2}>Blocked</Option>
                                <Option value={3}>Confirmed</Option>
                                <Option value={4}>Cancelled</Option>
                                {/* <Option value={4}>Cancel Sucess</Option>
                                    <Option value={5}>Refund Sucess</Option>
                                    <Option value={6}>Refund Failed</Option> */}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="reports-btns">
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => setShowTable(true)}
                          >
                            Search
                          </Button>
                          <Button
                            type="danger"
                            onClick={() => {
                              setReportRole(0);
                              //   setTravelType(0);
                              form.resetFields();
                            }}
                          >
                            Reset
                          </Button>
                        </Row>
                      </Form>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </Col>
            {showTable && (
              <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    {dataSource.length > 0 ? (
                      <>
                        <div className="card-add-heading">
                          <div className="rows-count">
                            <div>
                              <h5>Hotel Reports {dataSource.length}</h5>
                            </div>
                          </div>
                          <div className="action-images">
                            {showSearchBox && (
                              <Input
                                placeholder="Search"
                                onChange={(e) => searchTableData(e)}
                                suffix={closeSearchInput}
                                style={{ padding: "0px 12px" }}
                              />
                            )}
                            &nbsp;&nbsp;
                            <img
                              src={search}
                              alt="search"
                              onClick={(e) => searchData()}
                            />
                            <img
                              src={excel}
                              alt="excel"
                              onClick={handleExcel}
                            />
                          </div>
                        </div>
                        <div>
                          <div>
                            <Table
                              scroll={{ x: true }}
                              bordered
                              dataSource={dataSource}
                              columns={columns}
                              pagination={{
                                defaultPageSize: 10,
                                showSizeChanger: true,
                                pageSizeOptions: ["25", "50", "100", "125"],
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ) : loading ? (
                      <Spin
                        size="large"
                        tip="Loading..."
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <b>No Results Found!</b>
                    )}
                  </Card>
                </div>
              </Col>
            )}
          </Row>
        </Content>
      </div>
      <Modal
        title="Confirmation Id"
        className="promo-modal-header cartypes-value modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <Form
          layout="vertical"
          name="basic"
          form={form}
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label="RefNumber"
                rules={[{ required: true }]}
                name="refNumber"
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="ConfirmationNo"
                rules={[
                  { required: true }
                ]}
                name="confirmationNo"
              >
                <Input placeholder="Please Enter ConfirmationNo" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit">
              {/* {isEditMode ? "Update" : "Add"} */}
              Update
            </Button>
            <Button
              type="primary"
              onClick={() => {
                form.resetFields();
              }}
              danger
              className="cancel-btn"
            >
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdatedComponent(HotelBookingReports);
