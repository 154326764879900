// import React, { useEffect } from "react";
// import { Card, Col, Row, Popover } from "antd";
// import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
// import { getFareTotal } from "./flightPriceHelper";
// import { QuestionCircleOutlined } from "@ant-design/icons";
// import "./FlightsCheckout.scss";

// const FareSummaryCard = ({ flightDetObj, flightSearchObj, title, index }) => {
//   if (flightSearchObj.airTravelType.toUpperCase() === "MULTIDESTINATION" && flightSearchObj.resultsType.toUpperCase() === "SEPARATE") {
//     if (index === 0) {
//       title = "First Flight (" + flightSearchObj.originDestinations[index].origin + "-" +
//         flightSearchObj.originDestinations[index].destination + ") Fare"
//     }
//     if (index === 1) {
//       title = "Second Flight (" + flightSearchObj.originDestinations[index].origin + "-" +
//         flightSearchObj.originDestinations[index].destination + ") Fare"
//     }
//     if (index === 2) {
//       title = "Third Flight (" + flightSearchObj.originDestinations[index].origin + "-" +
//         flightSearchObj.originDestinations[index].destination + ") Fare"
//     }
//     if (index === 3) {
//       title = "Fourth Flight (" + flightSearchObj.originDestinations[index].origin + "-" +
//         flightSearchObj.originDestinations[index].destination + ") Fare"
//     }
//   }

//   const { activeCurrency, currencyValue } = useCurrencyContext();

//   let { flightFares } = flightDetObj.fareFamilies[0];

//   const {
//     adultPrice,
//     ChildPrice,
//     infantPrice,
//     totalBaseFare,
//     tax,
//     totalAmount,
//   } = getFareTotal(flightSearchObj, flightFares);

//   const paxFares = () => {
//     return (
//       <>
//         {flightFares
//           .filter((fareObj) => fareObj.fareTag === "Base")
//           .map((paxFare, index) => (
//             <div key={"paxList" + index} className="pax-count-acc-body">
//               <p>
//                 {paxFare.paxType === "ADT" ? (
//                   <>Adult(s) {flightSearchObj.adultCount}</>
//                 ) : paxFare.paxType === "CHD" ? (
//                   <>Child(s) {flightSearchObj.childCount}</>
//                 ) : (
//                   <>Infant(s) {flightSearchObj.infantCount}</>
//                 )}
//               </p>
//               <p>
//                 {activeCurrency}{" "}
//                 {paxFare.paxType === "ADT" ? (
//                   <>{currencyValue(adultPrice)}</>
//                 ) : paxFare.paxType === "CHD" ? (
//                   <>{currencyValue(ChildPrice)}</>
//                 ) : (
//                   <>{currencyValue(infantPrice)}</>
//                 )}
//               </p>
//             </div>
//           ))}
//       </>
//     );
//   };

//   return (
//     <>
//       <div className="sticky-card-container1">
//         <div className="pax-count-card">
//           <p className="fareTitle1">{title}</p>
//           <Row justify={"space-between"} className="grand_total_card_row">
//             <Col>
//               <p>Base Fare</p>
//             </Col>
//             <Col className="d-flex">
//               <p>
//                 {activeCurrency} {currencyValue(totalBaseFare)}
//               </p>

//               {/* <Popover
//                 overlayClassName="pricepopup"
//                 placement="left"
//                 content={paxFares()}
//                 title=" Base Fare"
//               >
//                 <QuestionCircleOutlined />
//               </Popover> */}
//             </Col>
//           </Row>
//           <Row justify={"space-between"} className="grand_total_card_row">
//             <Col>
//               <p>Taxes and Fees</p>
//             </Col>
//             <Col className="d-flex">
//               <p>
//                 {activeCurrency} {currencyValue(tax)}
//               </p>
//               {/* <Popover
//                 overlayClassName="pricepopup"
//                 placement="left"
//                 content={
//                   <div className="pax-count-acc-body">
//                     <div className="pax-type">
//                       <p>Service fee</p>
//                     </div>

//                     <div className="service-price">
//                       <p>
//                         {activeCurrency} {currencyValue(tax)}
//                       </p>
//                     </div>
//                   </div>
//                 }
//                 title="Taxes and Fees"
//               >
//                 <QuestionCircleOutlined />
//               </Popover> */}
//             </Col>
//           </Row>

//           <div className="pax-total-price">
//             <div className="pax-type">
//               <p>Total:</p>
//             </div>
//             <div className="total">
//               <p> {activeCurrency} </p>
//               <p style={{color:"#35459c"}} className="amount ml-1"> {currencyValue(totalAmount)}</p>
//             </div>
//           </div>
//           <p className="all-taxes">Including all taxes and fees</p>
//         </div>
//       </div>
//     </>
//   );
// };

// export default FareSummaryCard;

import React, { useEffect, useState } from "react";
import { Card, Col, Row, Popover } from "antd";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import { getFareTotal } from "./flightPriceHelper";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "./FlightsCheckout.scss";

const FareSummaryCard = ({ flightDetObj, flightSearchObj, title, index }) => {
  // console.log(flightSearchObj,"search");
  if (flightSearchObj.airTravelType.toUpperCase() === "MULTIDESTINATION" && flightSearchObj.resultsType.toUpperCase() === "SEPARATE") {
    if (index === 0) {
      title = "First Flight (" + flightSearchObj.originDestinations[index].origin + "-" +
        flightSearchObj.originDestinations[index].destination + ") Fare"
    }
    if (index === 1) {
      title = "Second Flight (" + flightSearchObj.originDestinations[index].origin + "-" +
        flightSearchObj.originDestinations[index].destination + ") Fare"
    }
    if (index === 2) {
      title = "Third Flight (" + flightSearchObj.originDestinations[index].origin + "-" +
        flightSearchObj.originDestinations[index].destination + ") Fare"
    }
    if (index === 3) {
      title = "Fourth Flight (" + flightSearchObj.originDestinations[index].origin + "-" +
        flightSearchObj.originDestinations[index].destination + ") Fare"
    }
  }

  const { activeCurrency, currencyValue } = useCurrencyContext();
  // const [active, setActive] = useState(['1']);
  let { flightFares } = flightDetObj.fareFamilies[0];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const {
    adultPrice,
    ChildPrice,
    infantPrice,
    totalBaseFare,
    tax,
    totalAmount,
  } = getFareTotal(flightSearchObj, flightFares);

  const paxFares = () => {
    return (
      <>
        {flightFares
          .filter((fareObj) => fareObj.fareTag === "Base")
          .map((paxFare, index) => (
            <div key={"paxList" + index} className="pax-count-acc-body">

              <p>
                {paxFare.paxType === "ADT" ? (
                  <>Adult(s) {" x "}{flightSearchObj.adultCount}</>
                ) : paxFare.paxType === "CHD" ? (
                  <>Child(s) {" x "} {flightSearchObj.childCount}</>
                ) : (
                  <>Infant(s) {" x "} {flightSearchObj.infantCount}</>
                )}
              </p>
              <p>
                {activeCurrency}{" "}
                {paxFare.paxType === "ADT" ? (
                  <>{currencyValue(adultPrice)}</>
                ) : paxFare.paxType === "CHD" ? (
                  <>{currencyValue(ChildPrice)}</>
                ) : (
                  <>{currencyValue(infantPrice)}</>
                )}
              </p>
            </div>
          ))}
      </>
    );
  };
  const getTotalPassengers = (data) => {
    const { adultCount, childCount, infantCount } = data;
    return adultCount + childCount + infantCount;
  };

  const totalPassengers = getTotalPassengers(flightSearchObj);

  return (
    <>
      <div className="sticky-card-container1">
        <div className="pax-count-card">
          <p className="fareTitle1">{title}</p>
          <Row
            // justify={"space-between"} className="grand_total_card_row"
            justify={"space-between"}
            className="grand_total_card_row"
          >
            <Col>
              <p style={{ marginBottom: "0px" }}>Base Fare </p>
              <p onClick={toggleDropdown} // Toggle dropdown on row click
                 style={{ marginBottom: "1px", color: "blue", fontSize: "12px", cursor: "pointer" }}>view more <span style={{color:"grey"}} className="usr"><i class="fa fa-users" aria-hidden="true"></i> {" x "} {totalPassengers}</span></p>
            </Col>
            <Col className="d-flex">
              <p style={{display:"flex", alignItems:"center", marginBottom:"0"}}>
                {activeCurrency} {currencyValue(totalBaseFare)}
              </p>

              {/* <Popover
                overlayClassName="pricepopup"
                placement="left"
                content={paxFares()}
                title=" Base Fare"
              >
                <QuestionCircleOutlined />
              </Popover> */}
            </Col>
          </Row>
          {isDropdownOpen && (
            <Row>
              <Col span={24}>
                <div className="fare-breakdown">
                  {paxFares()}
                </div>
              </Col>
            </Row>
          )}
          <Row justify={"space-between"} className="grand_total_card_row" style={{marginTop:"4px"}}>
            <Col>
              <p>Taxes and Fees</p>
            </Col>
            <Col className="d-flex">
              <p>
                {activeCurrency} {currencyValue(tax)}
              </p>
              {/* <Popover
                overlayClassName="pricepopup"
                placement="left"
                content={
                  <div className="pax-count-acc-body">
                    <div className="pax-type">
                      <p>Service fee</p>
                    </div>

                    <div className="service-price">
                      <p>
                        {activeCurrency} {currencyValue(tax)}
                      </p>
                    </div>
                  </div>
                }
                title="Taxes and Fees"
              >
                <QuestionCircleOutlined />
              </Popover> */}
            </Col>
          </Row>

          <div className="pax-total-price">
            <div className="pax-type">
              <p>Total:</p>
            </div>
            <div className="total">
              <p> {activeCurrency} </p>
              <p style={{ color: "#35459c" }} className="amount ml-1"> {currencyValue(totalAmount)}</p>
            </div>
          </div>
          <p className="all-taxes">Including all taxes and fees</p>
        </div>
      </div>
    </>
  );
};

export default FareSummaryCard;

