// import React from "react";
// import { Select } from "antd";

// import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
// import currencyList from "./currencyList";
// import { useAuthContext } from "../providers/AuthProvider";

// const { Option } = Select;

// const FlagsList = () => {
//   const { activeCurrency, setActiveCurrency } = useCurrencyContext();
//   const {
//     isLogin: { agent },
//     user,
//   } = useAuthContext();

//   const defaultCurrency = agent ? user.Currency : activeCurrency;
//   const handleCurrencyChange = (val) => {
    
//     if (agent) {
//       setActiveCurrency(defaultCurrency);
//     }
//     else{
//       setActiveCurrency(val);
//     }
    
//   };
 
// // console.log(defaultCurrency,"gggg")
//   return (

//     <Select
//       defaultValue={defaultCurrency}
//       onSelect={ handleCurrencyChange}
//       className="for-currency-border-workcss"
//       style={{ maxWidth: "100px", borderRadius: "20px" }}
//     >
//       {currencyList.map((item) => (
//         <Option value={item.name} key={item.name}>
//           <img src={item.url} alt={item.name} className="currency-flag-img" />{" "}
//           {item.name}
//         </Option>
//       ))}
//       {/* {console.log(currencyList,"clist")} */}
//     </Select>
//   );
// };

// export default FlagsList;
import React from "react";
import { Select } from "antd";

import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import currencyList from "./currencyList";

const { Option } = Select;

const FlagsList = () => {
  const { activeCurrency, setActiveCurrency } = useCurrencyContext();
 // console.log(setActiveCurrency, activeCurrency, "active");
  return (

    <Select
      defaultValue={activeCurrency}
      onSelect={(val) => {
        setActiveCurrency(val);
      }}
      className="for-currency-border-workcss"
      style={{ maxWidth: "100px", borderRadius: "20px" }}
    >
      {currencyList.map((item) => (
        <Option value={item.name} key={item.name}>
          <img src={item.url} alt={item.name} className="currency-flag-img" />{" "}
          {item.name}
        </Option>
      ))}
      {/* {console.log(currencyList,"clist")} */}
    </Select>
  );
};

export default FlagsList;
