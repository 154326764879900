import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Popconfirm,
  message,
  Table,
  Row,
  Spin,
} from "antd";
import { Link } from "react-router-dom";
import moment from "moment";

import { useAuthContext } from "../../common/providers/AuthProvider";

import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import APIClient from "../../helpers/ApiClient";
import "./MyQuotation.scss";

let dateFormat = "DD-MM-YYYY";
let oriDateFormat = "YYYY-MM-DD";

const MyPackage = () => {
  const { user } = useAuthContext();
  const [form] = Form.useForm();
  const [packagesData, setPackagesData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const disabledOriginDate = (currentDate) => {
    return currentDate >= moment();
  };
  const [showTable, setShowTable] = useState(false);

  const packageColumn = [
    {
      title: "Reference No",
      dataIndex: "PackageRefNo",
      render: (ref, rec) =>
        rec?.Status === "Saved" ? (
          ref
        ) : (
          <Link to={`buildpackage/ticket?ref=${ref}`}>{ref}</Link>
        ),
    },

    {
      title: "Name",
      dataIndex: "UserName",
    },
    {
      title: " Travel Date ",
      dataIndex: "TravelDate",
      sorter: (a, b) => a.TravelDate.localeCompare(b.TravelDate),
    },
    {
      title: " Total ",
      dataIndex: "TotalFare",
    },

    {
      title: " Status ",
      dataIndex: "Status",
    },
    {
      title: "View Detail",
      render: (currentItem) =>
        currentItem?.Status === "Saved" ? viewPackage(currentItem) : "",
    },
    {
      title: "Created Date",
      dataIndex: "CreatedDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
    },
    {
      title: "Delete",
      render: (currentItem) =>
        currentItem?.Status === "Saved" ? deletecolumn(currentItem) : "",
    },
  ];
  const viewPackage = (currentItem) => {
    let query = `/buildquotationtotal?packageRefNo=${currentItem.PackageRefNo}`;
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Link to={query}>
            <EyeOutlined />
          </Link>
        </div>
      </div>
    );
  };

  const deletecolumn = (currentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deletePackage(currentItem.PackageRefNo)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const getPackageRecords = (formData) => {
    let query = {
      ...formData,
      UserID: user?.UserID ?? 1,
      RoleType: user?.Role?.RoleId ?? 4,
    };

    setIsLoading(true);
    setShowTable(true);
    setPackagesData([]);
    APIClient.get("buildPackage/packageRecord", query)
      .then((res) => {
        if (res.status == 200) {
          setPackagesData(res.data);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  };

  const deletePackage = (pkgRfNo) => {
    APIClient.delete("buildPackage/deletePackage/" + pkgRfNo)
      .then((res) => {
        if (res.status == 200) {
          message.success("Package Deleted Successfully", 3);
          form.submit();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitForm = (val) => {
    // console.log("val", val);
    val.fromDate = val.fromDate
      ? moment(val.fromDate).startOf("hour").format("YYYY-MM-DD hh:mm:ss")
      : "";
    val.toDate = val.toDate
      ? moment(val.toDate).endOf("hour").format("YYYY-MM-DD hh:mm:ss")
      : "";
    getPackageRecords(val);
  };

  return (
    <div className="manage-markup-section ourteam-bg">
      <div className="container-fluid">
        <div className="card-bt-gap">
          <Card bordered={false} className="retrieve-booking">
            <Form
              layout="vertical"
              form={form}
              initialValues={{
                fromDate: "",
                toDate: "",
                packageRefNo: "",
              }}
              onFinish={submitForm}
            >
              <h5 className="view-pkg-v1">View Packages</h5>

              <Row gutter={16}>
                <Col md={6} sm={12} xs={24}>
                  <Form.Item label="From Date" name="fromDate">
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={disabledOriginDate}
                      format={dateFormat}
                    />
                  </Form.Item>
                </Col>
                <Col md={6} sm={12} xs={24}>
                  <Form.Item label="To Date" name="toDate">
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={disabledOriginDate}
                      format={dateFormat}
                    />
                  </Form.Item>
                </Col>
                <Col md={6} sm={12} xs={24}>
                  <Form.Item label="Reference No" name="packageRefNo">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="start">
                <Button
                  type="primary"
                  onClick={(e) => setShowTable(true)}
                  htmlType="submit"
                  className="view-agent-bc"
                >
                  Search
                </Button>
              </Row>
            </Form>
          </Card>
        </div>
        <div className="card-bt-gap">
          {showTable && (
            <Card bordered={false} className="retrieve-booking">
              {isLoading ? (
                <Spin size="large" tip="Loading..." style={{ width: "100%" }} />
              ) : packagesData?.length > 0 ? (
                <>
                  <h5>View Results</h5>
                  <Row>
                    <Col md={24} xs={24}>
                      <>
                        <Table
                          scroll={{ x: true }}
                          bordered
                          dataSource={packagesData}
                          columns={packageColumn}
                          pagination={{
                            defaultPageSize: 25,
                            showSizeChanger: true,
                            pageSizeOptions: ["25", "50"],
                          }}
                        />
                      </>
                    </Col>
                  </Row>
                </>
              ) : (
                <b>No Results Found!</b>
              )}
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyPackage;
