import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Radio,
  Col,
  Collapse,
  Form,
  Row,
  Modal,
  message,
  Spin,
  Select,
  Image,
} from "antd";
import { useAuthContext } from "../../providers/AuthProvider";
import LogoImg from "../../../assets/images/logos/trip-logo.png";
import { useSytContext } from "../../providers/SytProvider";
import queryString from "query-string";
import APIClient from "../../../helpers/ApiClient";
import { getDateRange } from "../../../helpers/activitiesHelper";
import TicketSidebar from "../TicketSidebar";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import TicketBuildPackage from "./TicketBuildPackage";

const { Panel } = Collapse;
const { Option } = Select;
const dateFormat = "DD MMM YYYY";

const BuildPackageTicketScreen = ({ mode }) => {
  const {
    user,
    isLogin: { agent },
  } = useAuthContext();
  const { agentLogo, logo } = useSytContext();
  const ticketSearchParams = queryString.parse(window.location.search);
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [cacellationType, setCacellationType] = useState("1");
  const [loading, setLoading] = useState(false);

  const [ticketData, setTicketData] = useState({});
  const [loadingTicket, setLoadingTicket] = useState(true);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const mapBPData = (resp) => {
    let data = {
      ...resp.data,
      tourDetails: [],
    };

    if (Object.keys(resp.data?.request).length > 0) {
      const req = resp.data?.request;

      const tourAvlDate = getDateRange(req?.checkInDate, req?.checkOutDate);

      if (resp.data.activitiesPackageDetails.length > 0) {
        resp.data?.activitiesPackageDetails?.map((tour) => {
          tour?.optionData?.map((optionData) => {
            let i = tourAvlDate.indexOf(
              moment(optionData.tourDate).format("DD-MM-YYYY")
            );

            if (
              data.tourDetails.hasOwnProperty(i) &&
              data.tourDetails[i]?.length > 0
            ) {
              data.tourDetails[i].push(optionData);
            } else {
              data.tourDetails[i] = [optionData];
            }
          });
        });
      }
    }
    setTicketData(data);
  };

  const getTicketDetails = () => {
    setLoadingTicket(true);
    APIClient.get("buildPackage/packageTicketDetails/" + ticketSearchParams.ref)
      .then((resp) => {
        if (resp.status == 200) {
          if (mode === "USER") {
            let userId = user?.UserID ?? 1;
            if (resp.data.UserId === userId) {
              mapBPData(resp);
            } else {
              setTicketData({});
            }
          } else {
            mapBPData(resp);
          }
        }
        setLoadingTicket(false);
      })
      .catch((error) => {
        setTicketData({});
        setLoadingTicket(false);
      });
  };

  const cancelTicket = () => {
    setModalVisible(true);
  };

  useEffect(() => {
    getTicketDetails();
  }, [ticketSearchParams.ref]);

  const submitCancelForm = (val) => {
    setLoading(true);
    if (val.type === "1") {
      let tourReqData = val.optionId.split("~");
      let data = {
        traceId: "string",
        tourOptionId: tourReqData[0],
        referenceNo: tourReqData[1],
        cancellationReason: "Test cancellation",
      };

      APIClient.post("activities/tours/cancellation", data)
        .then((results) => results)
        .then((res) => {
          setLoading(false);

          if (res?.statusCode == 200) {
            message.success(`Success`, 10);
            getTicketDetails();
            setModalVisible(false);

            form.resetFields();
          } else {
            message.error("Failed", 10);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    } else {
      let hotelReqData = val.hotelIds.split("~");

      let data = {
        traceId: hotelReqData[1],
        RefNumber: hotelReqData[0],
      };

      APIClient.post("hotels-v2/hotelCancel/", data)
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            if (
              [
                "CancellationRequest ",
                "CancellationPending ",
                "CancellationInProgress ",
              ].includes(res.data.bookingStatus)
            ) {
              hotelCancelStatus(data);
            } else {
              message.success(
                `${res.data.Message}. Your total refund amount is Rs. ${res.data.refundAmount} `,
                10
              );

              getTicketDetails();
              setModalVisible(false);
            }
          } else if (res.status === 400) {
            if (res.data.length > 0) {
              res.data.map((err) => {
                if (err.errorCode === "6033") {
                  //message.error("Ticket has cancelled already.");
                }
              });
              setModalVisible(false);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  const hotelCancelStatus = (data) => {
    APIClient.post("hotels-v2/hotelCancelStatus/", data)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          message.success(
            `${res.data.Message}. Your total refund amount is Rs. ${res.data.refundAmount} `,
            10
          );

          getTicketDetails();
          setModalVisible(false);
        } else if (res.status === 400) {
          if (res.data.length > 0) {
            res.data.map((err) => {
              if (err.errorCode === "6033") {
                //message.error("Ticket has cancelled already.");
              }
            });
            setModalVisible(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const BASE = process.env.REACT_APP_BASE_URL;
  return (
    <div className="flight-ticket-collapse">
      <div className="print-agentlogo">
        {agent ? (
          agentLogo ? (
            <Image
              className="printImageStyle"
              src={BASE + agentLogo.substring(1)}
            ></Image>
          ) : logo ? (
            <Image
              className="printImageStyle"
              src={BASE + logo.substring(1)}
            ></Image>
          ) : (
            <Image className="printImageStyle" src={LogoImg}></Image>
          )
        ) : logo ? (
          <Image
            className="printImageStyle"
            src={BASE + logo.substring(1)}
          ></Image>
        ) : (
          <Image className="printImageStyle" src={LogoImg}></Image>
        )}
      </div>
      <Card>
        <div className="fligh-ticket-container">
          {loadingTicket ? (
            <div style={{ textAlign: "center" }} className="flight-ticket">
              <Spin indicator={antIcon} tip="Loading..." />
            </div>
          ) : Object.keys(ticketData).length > 0 &&
            ticketData.bookingRefNo != "" ? (
            <div className="flight-ticket">
              <Row gutter={[32, 16]} className="ticket-row">
                <Col md={18}>
                  <Collapse
                    bordered={true}
                    className="collapsed-data"
                    defaultActiveKey={["1"]}
                    accordion={true}
                  >
                    <Panel key="1">
                      <TicketBuildPackage ticketData={ticketData} />
                    </Panel>
                  </Collapse>
                </Col>

                <Col xs={24} md={6} className="tic-info-flight">
                  <div className="web-tic-info">
                    <TicketSidebar
                      ticketData={ticketData}
                      ticketSearchParams={ticketSearchParams}
                      type="BuildPackage"
                      onCancelTicket={cancelTicket}
                      getTicketDetails={getTicketDetails}
                    />
                  </div>

                  <div className="mobile-tic-info">
                    <Collapse accordion>
                      <Panel header="Manage Tickets" key="1">
                        <TicketSidebar
                          ticketData={ticketData}
                          ticketSearchParams={ticketSearchParams}
                          type="BuildPackage"
                          onCancelTicket={cancelTicket}
                          getTicketDetails={getTicketDetails}
                        />
                      </Panel>
                    </Collapse>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <p className="no-tickt">No Ticket Found</p>
          )}
        </div>
      </Card>

      <Modal
        title="Cancel Ticket"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              onClick={form.submit}
              loading={loading}
            >
              Cancel Ticket
            </Button>
          </div>,
        ]}
        width={"600px"}
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{ type: "1" }}
          onFinish={submitCancelForm}
        >
          <Form.Item
            label="Type"
            name="type"
            rules={[{ required: true, message: "Required" }]}
          >
            <Select
              placeholder="Select Cancellation Type"
              defaultValue={cacellationType}
              onSelect={(val) => setCacellationType(val)}
            >
              <Select.Option value="1">Tours</Select.Option>
              <Select.Option value="2">Hotels</Select.Option>
            </Select>
          </Form.Item>
          {Object.keys(ticketData).length > 0 ? (
            cacellationType == "1" &&
            ticketData?.activitiesPackageDetails?.length > 0 ? (
              <>
                <Form.Item
                  label="Choose Tour:"
                  name="optionId"
                  className="font-weight-bold"
                  rules={[{ required: true, message: "Please Choose Tour" }]}
                >
                  <Radio.Group>
                    <Row>
                      <Col md={24}>
                        {ticketData?.tourDetails?.length > 0
                          ? ticketData?.tourDetails.map((tour) =>
                              tour?.map((item) => (
                                <Radio
                                  key={item.optionId}
                                  value={`${item.optionId}~${item.BookingRefNo}`}
                                  className="ml-0 mb-2"
                                >
                                  {item.optionName}
                                </Radio>
                              ))
                            )
                          : ""}
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </>
            ) : ticketData.hotelsPackageDetails?.length > 0 ? (
              ticketData.hotelsPackageDetails.map((hotel) => (
                <Form.Item
                  label="Choose Hotel:"
                  name="hotelIds"
                  className="font-weight-bold"
                  rules={[{ required: true, message: "Please Choose hotel" }]}
                >
                  <Radio.Group>
                    <Row>
                      {ticketData?.hotelsPackageDetails.length > 0
                        ? ticketData?.hotelsPackageDetails.map((hotel, i) => (
                            <Radio
                              key={i}
                              value={`${hotel.RefNumber}~${hotel.traceId}`}
                              className="ml-0"
                            >
                              {hotel.HotelName}
                            </Radio>
                          ))
                        : ""}
                    </Row>
                  </Radio.Group>
                </Form.Item>
              ))
            ) : null
          ) : null}
        </Form>
      </Modal>
    </div>
  );
};
export default BuildPackageTicketScreen;
