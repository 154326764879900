import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Modal,
  message,
  Select,
  Spin,
  Table,
  Row,
  Upload,
} from "antd";
import moment from "moment";
import queryString from "query-string";
import excel from "../../assets/vector-icons/excel.png";
import search from "../../assets/vector-icons/search.png";
import { useAuthContext } from "../../common/providers/AuthProvider";
import APIClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import PaymentGateway from "../../helpers/PaymentGateway";
import {
  getDepositType,
  getPaymentType,
  getStatus,
  getAddRevoke,
} from "../../helpers/Deposits/depositsHelpers";
import "../Deposits/Deposits.scss";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import VerifyOtpModal from "../../common/OtpModal/VerifyOtpModal";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";

const { TextArea } = Input;
const { Option } = Select;

const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/singleImage";
let dateFormat = "DD-MM-YYYY";

const ImageUpload = ({
  category,
  fileList,
  setFileList,
  formName,
  limit,
  limitSize,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );

  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    const checkFileSize = file.size < 1126400;
    if (!checkFileSize) {
      message.error(" Image must be smaller than 1Mb!");
    }

    return checkJpgOrPng && checkFileSize;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      setIsUploading(true);
      return;
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item name={formName} label="Upload Image">
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={true}
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList
              ? fileList.length >= limitSize
                ? null
                : uploadLogoButton
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        destroyOnClose={true}
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

const Deposits = (props) => {
  const { user } = useAuthContext();
  const [form] = Form.useForm();
  const [depositForm] = Form.useForm();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [depositeTypeVal, setDepositeTypeVal] = useState("");
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [pgDisplay, setPgDisplay] = useState(false);
  const [Details, setDetails] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [Pgselecteddata, setPgselecteddata] = useState({});
  const [Banklist, setBanklist] = useState([]);
  const [amount, setAmount] = useState(0);
  const [tdrAmount, setTdrAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [modal, setModal] = useState(false);
  const [modall, setModall] = useState(false);
  const [pgList, setPgList] = useState([]);
  const [pgData, setPgData] = useState({});
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const { activeCurrency, currencyValue, currencyRatio } = useCurrencyContext()
  useEffect(() => {
    getqueryparam();
    getBankdetails();
    getPgdetails();
  }, []);
  // { console.log(activeCurrency, currencyRatio, "ratio") }

  const onFormSearch = (values) => {
    values.fromdate = values.fromdate
      ? values.fromdate.format("YYYY-MM-DD")
      : "";
    values.todate = values.todate ? values.todate.format("YYYY-MM-DD") : "";
    values.UserId = user.UserID;
    setShowTable(true);
    setLoading(true);
    APIClient.get("admin/agentDepositRequest", values)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              DepositeType: getDepositType(item.DepositeType),
              RequestStatus: getStatus(item.RequestStatus),
              AddrRevoke: getAddRevoke(item.AddrRevoke),
              TransactionId: item.TransactionId,
              ReferenceNumber: item.ReferenceNumber,
              CreditAmount: item.CreditAmount,
              Amount: item.Amount,
              Remarks: item.Remarks,
              currencyRatio: currencyRatio,
              DepositeDate: item.DepositeDate
                ? moment(item.DepositeDate).format("DD-MM-YYYY")
                : "",
              CreatedDate: item.CreatedDate
                ? moment(item.CreatedDate).format("DD-MM-YYYY")
                : "",
              ExpiryDate: item.ExpiryDate
                ? moment(item.ExpiryDate).format("DD-MM-YYYY")
                : "",
            };
          });

          setDetails(data);
          setTableData(data);
        } else {
          setDetails([]);
          setTableData([]);
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const banklistformfilling = (id) => {
    let data = Banklist.filter((item) => item.ID === id);
    if (data.length > 0) {
      depositForm.setFieldsValue({
        AccountNumber: data[0].AccountNumber,
      });
    }
  };
  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const searchTableData = (e) => {
    var searchVal = e.target.value;

    if (searchVal.length > 0) {
      const searchResults = Details.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });

      setTableData(searchResults);
    } else {
      setTableData(Details);
    }
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const handleExcel = () => {
    const excelData = tableData.map((item) => {
      return {
        S_No: item.SNo,
        DepositType: item.DepositeType,
        DepositStatus: item.RequestStatus
          ? item.RequestStatus
          : item.AddrRevoke,

        RefNo: item.ReferenceNumber,
        CreditAmount: item.CreditAmount,
        Amount: item.Amount,
        TxnNo: item.TransactionId,
        DepositeDate: item.DepositeDate,
        ExpiryDate: item.ExpiryDate,
        Remarks: item.Remarks,
      };
    });

    props.exportExcel(excelData, "DepositRequest");
  };

  const getBankdetails = () => {
    APIClient.get("admin/bankDetails")
      .then((res) => {
        if (res.status === 200) {
          setBanklist(res.data);
        }
      })
      .catch(() => {
        setBanklist([]);
      });
  };

  const changeTdrValues = (data, amount) => {
    // console.log(data, amount, "checkk");
    if (data && Object.keys(data).length > 0) {
      let tdrAmount =
        data.Type === 1
          ? (data.Amount / 100) * amount
          : parseInt((data.Amount / currencyRatio), 10);

      let totalAmount =
        data.Type === 1
          ? (data.Amount / 100) * amount + parseInt(amount, 10)
          : parseInt((data.Amount / currencyRatio), 10) + parseInt(amount, 10);

      setTdrAmount(Number(tdrAmount).toFixed(2));
      setTotalAmount(Number(totalAmount).toFixed(2));
    }
  };

  const handleAmountChange = (e) => {
    if (Number(e.target.value) > 0) {
      setAmount(e.target.value);
      changeTdrValues(Pgselecteddata, Number(e.target.value));
    } else {
      setAmount(0);
      changeTdrValues(Pgselecteddata, 0);
    }
  };
  const getqueryparam = () => {
    // console.log("cmmg-1");
    const params = queryString.parse(document.location.search);
    if (params.Status === "Success") {
      setModal(true);
    } else if (params.Status === "Failed") {
      setModall(true);
    }
  };

  const handleTimeAndDate = (value) => {
    if (value === 5) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };
  const disabledDate = (current) => {
    return current && current.valueOf() > Date.now();
  };

  const onFinish = (values) => {
    // console.log(values, "deposittype");
    if (values?.DepositeType == "3" || values?.DepositeType == 3) {
      let data = {
        OrderId: "User-" + Math.floor(Math.random() * Math.floor(100000000)),
        OrderAmount: Number(totalAmount * currencyRatio),
        OrderCurrency: user?.Currency,
        currencyRatio: currencyRatio,
        OrderNote: values.remarks,
        CustomerName: `${user.FirstName} ${user.LastName}`,
        CustomerEmail: user.Email,
        CustomerPhone: user.Mobile,
        TDS: Number(tdrAmount * currencyRatio),
        GST: 0,
        Commission: 0,
      };
      // console.log(data, "dda");
      setPgData(data);
      setPgDisplay(true);
    } else {
      let imgUrl = "";

      if (values.UploadImage && Object.keys(values.UploadImage).length > 0) {
        if (
          values.UploadImage.fileList.length > 0 &&
          values.UploadImage.fileList[0].response.status === 200
        ) {
          imgUrl = values.UploadImage.fileList[0].response.data.filepath;
        }
      }

      const data = {
        DepositeType: values.DepositeType,
        UserID: user?.UserID,
        Amount: values?.Amount
          ? parseFloat(values?.Amount) === NaN
            ? 0
            : (parseFloat(values.Amount * currencyRatio))
          : 0,
        DepositeDate: moment().format(),
        ExpiryDate: values.ExpiryDate ?? "",
        AccountNumber: values.AccountNumber,
        TransactionId: values.TransactionId,
        PaymentType: values.PaymentType,
        BenificiaryBankId: values.BenificiaryBankId,
        Remarks: values.remarks,
        Image: imgUrl,
        CreditAmount: values?.CreditAmount
          ? parseFloat(values?.CreditAmount) === NaN
            ? 0
            : parseFloat(values?.CreditAmount)
          : 0,
        DeviceOS: 0,
        DeviceOSVersion: "string",
        ApplicationType: 0,
        DeviceToken: "string",
        FirBaseToken: "string",
        CreatedBy: user?.UserID,
        currency: user?.Currency,
        currencyratio: currencyRatio,
      };

      submitMapDetails(data);
    }
  };
  const submitMapDetails = (formMapData) => {
    APIClient.post("admin/DepositRequest", formMapData)
      .then((response) => {
        if (response.status === 200) {
          message.success("Requested SuccessFully", 3);
          setModalVisible(false);

          form.resetFields();
        } else if (response.status === 201) {
          message.error("Inserted Successfully", 3);
          setModalVisible(false);
        } else if (response.status === 400) {
          message.error("Enter Required fields", 3);
        } else if (response.status === 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => { });
  };

  const getPgdetails = () => {
    APIClient.get("admin/b2bPG")
      .then((res) => {
        if (res.status === 200) {
          setPgList(res.data);
        }
      })
      .catch(() => {
        setPgList([]);
      });
  };
  const handlePG = (value) => {
    APIClient.get(`admin/b2bPG/${value}`)
      .then((res) => {
        if (res.status === 200) {
          setPgselecteddata(res.data);

          changeTdrValues(res.data, amount);
        }
      })
      .catch((error) => { });
  };

  const showModal = (type) => {
    depositForm.resetFields();
    setImage([]);
    setAmount(0);

    setPgselecteddata({});
    setTotalAmount(0);
    setTdrAmount(0);

    setModalVisible({
      visible: true,
    });
    if (type === "All") {
      setDepositeTypeVal("");
    } else {
      setDepositeTypeVal("3");
      depositForm.setFieldsValue({
        DepositeType: "3",
      });
    }
  };

  const handleDepositType = (val) => {
    setDepositeTypeVal(val);
  };

  const columns = [
    {
      title: "Deposit Type",
      dataIndex: "DepositeType",

      sorter: (a, b) => a.DepositeType?.localeCompare(b.DepositeType),
    },
    {
      title: "Deposit Status",
      dataIndex: "RequestStatus",
    },
    {
      title: "Ref No",
      dataIndex: "ReferenceNumber",
    },
    // {
    //   title: `Commission (${user?.Currency})`,
    //   render: (_, currentData) =>
    //     currentData.serviceType === "Flight"
    //       ? getCommission(
    //         currentData.BaseFareCommission,
    //         currentData.BaseFareCommissionType
    //       )
    //       : getCommission(currentData.Commission, currentData.CommissionType),
    // },
    {
      title: `Amount (${user?.Currency})`,
      dataIndex: "Amount",
      // sorter: (a, b) => a.Amount - b.Amount,
      render: (e, f) => (

        // Number(parseFloat(Number(e) / Number(currencyRatio)))
        <>{currencyValue(e, user?.Currency)}
        </>
      ),
    },
    {
      title: "Txn No",
      dataIndex: "TransactionId",
    },
    {
      title: "Remarks",
      dataIndex: "Remarks",
    },
    {
      title: "Deposite Date",
      dataIndex: "DepositeDate",

      sorter: (a, b) => a.DepositeDate - b.DepositeDate,
    },
    {
      title: `CreditAmount (${user?.Currency})`,
      dataIndex: "CreditAmount",

      sorter: (a, b) => a.CreditAmount - b.CreditAmount,
      render: (e) => (
        Number(parseFloat(e) / parseFloat(currencyRatio))
        // <>{currencyValue(e, user?.Currency)}</>
      ),
    },
    // {
    //   title: "ExpiryDate",
    //   dataIndex: "ExpiryDate",
    // },
  ];

  const validateMessages = {
    required: "",
  };

  const handleVerifyOtp = (otpValue) => {
    APIClient.post("admin/verifyserviceOtp", {
      Mobile: user?.Mobile,
      DialingCode: "+91",
      Otp: Number(otpValue),
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          depositForm.submit();
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Deposite Failed", 3);
        }
      })
      .catch();
  };

  const handleSendOTP = () => {
    if (user?.Mobile) {
      APIClient.post("admin/sendserviceOtp", {
        Mobile: user?.Mobile,
        DialingCode: "+91",
      })
        .then((res) => {
          if (res?.status === 200) {
            setVerifyModalVisible(true);
          } else {
            if (res?.message) message.error(res.message, 3);
            else message.error("Deposite Failed", 3);
          }
        })
        .catch();
    } else {
      message.error("Please Update Your Moblie No. in your Profile");
    }
  };

  return (
    <>
      <div className="manage-markup-section ourteam-bg">
        <div className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false} className="retrieve-booking">
              <div className="card-add-heading">
                <div className="rows-count">
                  <div>
                    <h5>My Deposits</h5>
                    {showTable ? <p>{Details.length} rows found !</p> : ""}
                  </div>
                </div>
                <div className="action-images">
                  <Button
                    style={{ backgroundColor: '#bd0c21' }}
                    type="primary"
                    className="mr-2"
                    onClick={() => showModal("All")}
                  >
                    <i className="fa fa-ils" aria-hidden="true"></i>
                    &nbsp;Deposits
                  </Button>
                  <Button
                    style={{ backgroundColor: '#bd0c21' }} type="primary" onClick={() => showModal("INS")}>
                    <i className="fa fa-gg" aria-hidden="true"></i>&nbsp;
                    Instant Recharge
                  </Button>
                </div>
              </div>
              <Form
                form={form}
                initialValues={{
                  options: 1,
                  fromdate: "",
                  todate: "",

                  Status: 2,
                }}
                layout={"vertical"}
                onFinish={onFormSearch}
              >
                <Row gutter={[16, 16]} align="middle">
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item label="Time and Date" name="options">
                      <Select
                        placeholder="Please select"
                        onChange={handleTimeAndDate}
                      >
                        <Option value={1}>Last One Hour</Option>
                        <Option value={2}>Yesterday</Option>
                        <Option value={3}>Last Week</Option>

                        <Option value={5}>Custom Dates</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {isDateDisplay ? (
                    <>
                      <Col md={6} sm={12} xs={24}>
                        <Form.Item label="From Date" name="fromdate">
                          <DatePicker
                            format="DD-MM-YYYY"
                            style={{ width: "100%" }}
                            disabledDate={disabledDate}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={6} sm={12} xs={24}>
                        <Form.Item label="To Date" name="todate">
                          <DatePicker
                            format="DD-MM-YYYY"
                            style={{ width: "100%" }}
                            disabledDate={disabledDate}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  ) : null}
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                      label="Status"
                      name="Status"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Select placeholder="Please Select">
                        <Option value={2}>Accepted</Option>
                        <Option value={1}>Pending </Option>
                        <Option value={3}>Rejected</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col md={6} sm={12} xs={24}>
                    <Button style={{ backgroundColor: '#bd0c21' }} type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
              {showTable && (
                <div className="table mt-2">
                  {Details.length > 0 ? (
                    <>
                      <div className="action-images">
                        {showSearchBox && (
                          <Input
                            placeholder="Search"
                            onChange={(e) => searchTableData(e)}
                            suffix={closeSearchInput}
                            style={{ padding: "0px 12px", maxWidth: "250px" }}
                          />
                        )}
                        &nbsp;&nbsp;
                        <img
                          src={search}
                          alt="search"
                          onClick={(e) => searchData()}
                        />
                        <img src={excel} alt="excel" onClick={handleExcel} />
                      </div>

                      <Table
                        bordered
                        scroll={{ x: true }}
                        dataSource={tableData}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </>
                  ) : loading ? (
                    <Spin
                      size="large"
                      tip="Loading..."
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <b>No Results Found!</b>
                  )}
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>

      <Modal
        title="Partner Deposit Request Form"
        visible={modalVisible}
        className="deposit-modal-popup instant-rechare-21"
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              type="primary"
              onClick={(e) => setModalVisible(false)}
              danger
              className="cancel-btn"
            >
              Close
            </Button>
            <Button
              key="add"
              type="primary"
              onClick={depositForm.submit}
              // onClick={() => handleSendOTP()}
            >
              Submit
            </Button>
          </div>,
        ]}
      >
        <Form
          layout={"vertical"}
          name="basic"
          form={depositForm}
          initialValues={{
            UploadImage: {},
          }}
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <Row gutter={[8, 8]}>
            <Col md={8} sm={12} xs={24}>
              <Form.Item
                label="Deposit Type"
                name="DepositeType"
                rules={[{ required: true }]}
              >
                <Select placeholder="Deposit Type" onSelect={handleDepositType}>
                  <Option value="11">Cash Deposit ATM</Option>
                  <Option value="1">Cash Deposit Bank</Option>
                  <Option value="6">Direct NetTransfer</Option>
                  <Option value="7">GooglePay Transfer</Option>
                  <Option value="8">PhonePay Transfer</Option>
                  <Option value="9">AmazonPay Transfer</Option>
                  <Option value="10">RTGS (Real-Time Gross Settlement)</Option>

                  <Option value="2">
                    NEFT (National Electronic Funds Transfer)
                  </Option>
                  <Option value="4">IMPS (Immediate Payment Service)</Option>
                  <Option value="5">Credit</Option>
                  <Option value="3">Instant Recharge</Option>
                </Select>
              </Form.Item>
            </Col>

            {depositeTypeVal === "3" ? (
              <>
                <Col md={8} sm={12} xs={24}>
                  <Form.Item
                    label="Amount"
                    name="Amount"
                    rules={[
                      { required: true, message: "Number Required" },
                      {
                        min: 2,
                        pattern: "^[1-9][0-9]*$",

                        message: "Amount should be more than 10 ",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Amount"
                      onChange={(e) => handleAmountChange(e)}
                    />
                  </Form.Item>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <Form.Item
                    label="Payment Type"
                    name="PaymentType"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="PaymentType"
                      onChange={(e) => {
                        handlePG(e);
                      }}
                    >
                      {pgList.map((data, index) => (
                        <Option key={index} value={data.PaymentTypeID}>
                          {getPaymentType(data.PaymentType)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            ) : null}

            {depositeTypeVal !== "3" && depositeTypeVal !== "5" ? (
              <>
                <Col md={8} sm={12} xs={24}>
                  <Form.Item
                    label="Amount"
                    name="Amount"
                    rules={[
                      { required: true },
                      {
                        min: 2,
                        pattern: "^[1-9][0-9]*$",

                        message: "Number Required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Amount"
                      onChange={(e) => handleAmountChange(e)}
                    />
                  </Form.Item>
                </Col>
                <Col md={8} sm={12} xs={24}>
                  <Form.Item
                    label="Benificiary Bank Name"
                    name="BenificiaryBankId"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Select BanificiaryBank"
                      onChange={banklistformfilling}
                    >
                      {Banklist.map((data, index) => (
                        <Option key={index} value={data.ID}>
                          {data.BankName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col md={8} sm={12} xs={24}>
                  <Form.Item
                    label="Account Number"
                    name="AccountNumber"
                    readOnly={true}
                    rules={[
                      { required: true },
                      {
                        min: 11,
                        pattern: "^[0-9]*$",
                        max: 17,
                        message: "Must be in between 11 to 17 digits",
                      },
                    ]}
                  >
                    <Input disabled={true} placeholder="AccountNumber" />
                  </Form.Item>
                </Col>

                <Col md={8} sm={12} xs={24}>
                  <Form.Item
                    label="Transaction No"
                    name="TransactionId"
                    rules={[
                      { required: true },
                      {
                        min: 12,
                        pattern: "^[a-zA-Z0-9]*$",
                        max: 18,
                        message: "Must be in between 12 to 18 Alphanumeric",
                      },
                    ]}
                  >
                    <Input placeholder="TransactionId" />
                  </Form.Item>
                </Col>
              </>
            ) : null}
            {depositeTypeVal !== "3" ? (
              <Col md={8} sm={12} xs={24}>
                <Form.Item label="Deposit Date" rules={[{ required: false }]}>
                  <DatePicker
                    style={{ width: "100%" }}
                    defaultValue={moment()}
                    format={dateFormat}
                    disabled
                  />
                </Form.Item>
              </Col>
            ) : null}

            {depositeTypeVal === "5" ? (
              <Col md={8} sm={12} xs={24}>
                <Form.Item
                  label="Credit Amount"
                  name="CreditAmount"
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Credit Amount" />
                </Form.Item>
              </Col>
            ) : null}

            <Col md={8} sm={12} xs={24}>
              <Form.Item
                label="Remarks"
                name="remarks"
              // rules={[
              //   { 
              //     required: false
              //   }]}
              >
                <TextArea
                  placeholder="Remarks"
                  rows={2}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            {depositeTypeVal === "3" ? (
              <Row>
                <Col md={16} offset={4} xs={24}>
                  <div className="payment-details-block deposits-offset">
                    <h5>Payment Details : </h5>
                    <ul>
                      <li>
                        <p>Amount</p>
                        <p>: {amount}</p>
                      </li>
                      <li>
                        <p>
                          TDR Charges ({" "}
                          {Pgselecteddata.Amount}
                          {Pgselecteddata.Type == 1 ? " %" : " /-"})
                        </p>
                        <p>: {tdrAmount}</p>
                      </li>
                      <li>
                        <p>Total Transaction Amount</p>
                        <p>:{activeCurrency} {totalAmount}</p>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={24} sm={24}>
                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject("Required"),
                      },
                    ]}
                  >
                    <Checkbox>
                      I certifies that the Credit/Debit Card/Net Banking Account
                      being used for this transaction belongs to My Company or
                      an authorized employee of My Company. In particular, this
                      Card/Net Banking Account does not belong to a customer or
                      passenger.
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Col md={8} sm={12} xs={24}>
                <ImageUpload
                  category="agentdeposits"
                  fileList={image}
                  setFileList={setImage}
                  formName="UploadImage"
                  limit={true}
                  limitSize={1}
                />
              </Col>
            )}
          </Row>
          {pgDisplay && <PaymentGateway data={pgData} directPayment={true} />}
          {/* {pgDisplay && <PaymentGateway data={pgData} directPayment={true} />} */}
          {/* {pgDisplay && <RazorpayCheckout pgData={pgData} />} */}
        </Form>
      </Modal>

      {/* {verifyModalVisible && (
        <VerifyOtpModal
          visible={verifyModalVisible}
          setVerifyModalVisible={setVerifyModalVisible}
          handleVerifyOtp={handleVerifyOtp}
          handleResendOtp={handleSendOTP}
          mobile={user?.Mobile ?? ""}
        />
      )} */}

      <Modal
        title="Successful"
        className="successModal"
        visible={modal}
        onOk={(e) => setModal(false)}
        onCancel={(e) => setModal(false)}
        footer={[null]}
      >
        <div className="alert alert-success" role="alert">
          Instant Recharge is successful
        </div>
      </Modal>
      <Modal
        title="Failure"
        className="successModal"
        visible={modall}
        onOk={(e) => setModall(false)}
        onCancel={(e) => setModall(false)}
        footer={[null]}
      >
        <div className="alert alert-danger" role="alert">
          Instant Recharge is Failed
        </div>
      </Modal>
    </>
  );
};
export default UpdatedComponent(Deposits);
