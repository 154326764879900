import { Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import noVisaImage from "../../../assets/images/image-not-found-scaled-1150x647.png";
import moment from "moment";

const BASE = process.env.REACT_APP_BASE_URL;

const VisaDetailsCard = ({ visaBookingData }) => {
  const [bestTimeToVisit, setBestTimeToVisit] = useState([]);

  useEffect(() => {
    if (visaBookingData.embassyDetails?.bestTimeToVisit) {
      setBestTimeToVisit(
        JSON.parse(visaBookingData.embassyDetails?.bestTimeToVisit)
      );
    }
  }, []);
  return (
    <div className="visaDetailCard card_wrapper">
      <Card className="Details-card">
        <Row className="align-items-center">
          <Col md={6} className="overflow-hidden">
            <img
              className="visaDetailImage"
              src={
                visaBookingData.Images.length
                  ? BASE + visaBookingData.Images[0].Image.substring(1)
                  : noVisaImage
              }
            />
          </Col>
          <Col md={18}>
            <div className="visa-details1">
              <h3 className="tit-1">{visaBookingData.VisaName}</h3>
              <p className="visa-det-cont-alig">
                <strong style={{color:'#bd0c21'}}>Description: </strong>
                <span style={{color:'black'}}>{visaBookingData?.VisaDescription}</span>
              </p>
              <Row>
                <Col md={12} xs={24} className="visa-det-cont-alig">
                  <p>
                    <strong>Type Of Visa: </strong>
                    <span>{visaBookingData?.TypeofVisa}</span>
                  </p>
                  <p>
                    <strong>Entry: </strong>
                    <span>{visaBookingData?.Entry}</span>
                  </p>

                  <p>
                    <strong>Processing Time Up to : </strong>
                    <span>{visaBookingData?.ProcessingTime} Day(s)</span>
                  </p>
                  <p>
                    <strong>Stay Period: </strong>
                    <span>{visaBookingData?.Stayperiod} Month(s)</span>
                  </p>
                </Col>

                <Col md={12} xs={24} className="visa-det-cont-alig">
                  {" "}
                  <p>
                    <strong>Visa Valid Up To: </strong>
                    <span>{visaBookingData?.Validity} Month(s)</span>
                  </p>
                  <p>
                    <strong>Country: </strong>
                    <span>{visaBookingData?.Country}</span>
                  </p>
                  {bestTimeToVisit.length === 2 ? (
                    <p>
                      <strong>Best Time To Visit :</strong>{" "}
                      <span>
                        {moment(bestTimeToVisit[0]).format("MMM-YYYY") +
                          " To " +
                          moment(bestTimeToVisit[1]).format("MMM-YYYY")}
                      </span>
                    </p>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default VisaDetailsCard;
