import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import "rc-datepicker/lib/style.css";
import React, { useEffect, useState } from "react";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import ApiClient from "../../helpers/ApiClient";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";

import search from "../assets/vector-icons/search.png";
import "./VisaDocument.scss";

const VisaDocument = () => {
  const { Content } = Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [RecID, setId] = useState(-1);
  const [form] = Form.useForm();

  const { TextArea } = Input;

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const handleAddClick = () => {
    form.resetFields();
    showModal();
    setIsEditMode(false);
  };

  const handleEditClick = (rec) => {
    form.setFieldsValue({
      Type: rec.Type,
      Information: rec.Information,
      Status: rec.Status,
      Detail: rec.Detail,
    });
    setId(rec.Id);

    setIsEditMode(true);

    showModal();
  };

  const [dataSource, setDataSource] = useState([]);
  const getAllVisaDocuments = () => {
    ApiClient.get("visa/getVisaDocuments").then((res) => {
      if (res.status == 200) {
        setDataSource(res.data);
      }
    });
  };
  useEffect(() => {
    getAllVisaDocuments();
  }, []);

  const submitVisaDocuments = (formvalues) => {
    ApiClient.post("visa/visaDocuments", formvalues).then((response) => {
      if (response.status === 200) {
        setModalVisible(false);
        message.success("Record Added Successfully");

        getAllVisaDocuments();
        form.resetFields();
      } else {
        message.error(response.message);
      }
    });
  };

  const handleSubmit = (formvalues) => {
    if (isEditMode) {
      submitUpdateCarType(formvalues);
    } else {
      submitVisaDocuments(formvalues);
    }
  };

  const submitUpdateCarType = (formvalues) => {
    const updatedFormData = {
      ...formvalues,
    };

    ApiClient.put(
      "visa/updateVisaDocuments/" + RecID,
      {},
      updatedFormData
    ).then((response) => {
      if (response.status === 200) {
        setModalVisible(false);
        message.success(response.message);

        getAllVisaDocuments();
        form.resetFields();
      } else {
        message.error(response.message);
      }
    });
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const deleteCarType = (Id) => {
    ApiClient.delete("visa/deleteVisaDocuments/" + Id).then((res) => {
      if (res.status == 200) {
        message.success(res.message, 3);
        getAllVisaDocuments();
      }
    });
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => handleEditClick(rec)}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteCarType(rec.Id)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const { Option } = Select;

  const columns = [
    {
      title: "Type",
      dataIndex: "Type",
    },

    {
      title: "Information",
      dataIndex: "Information",
    },
    {
      title: "Detail",
      dataIndex: "Detail",
    },

    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];

  const handelStatus = (data) => {
    if (data.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      );
    } else {
      return <Switch checkedChildren="Active" unCheckedChildren="Inactive" />;
    }
  };

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Visa Document{" "}
                          <HelpInfoHelper screenName={"/admin/cartypes"} />
                        </h5>
                        <p></p>
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />
                      <img src={excel} alt="excel" />
                      <img src={pdf} alt="pdf" />
                      <p className="add-deposit-icon" onClick={handleAddClick}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>

                  {dataSource.length ? (
                    <div>
                      <Table
                        className="table-scroll-none"
                        bordered
                        dataSource={dataSource}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  ) : (
                    <b>No record Found</b>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title="Visa Document"
        className="promo-modal-header cartypes-value"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <Form
          layout="vertical"
          name="basic"
          form={form}
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item label="Type" rules={[{ required: true }]} name="Type">
                <Input placeholder="Please Enter Type" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Details"
                rules={[{ required: true }]}
                name="Detail"
              >
                <Input placeholder="Please Enter Details" />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item label="Information" name="Information">
                <TextArea
                  placeholder="Please Enter Information"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item label="Status" name="Status">
                <Radio.Group
                  rules={[{ required: true, message: "Please choose Status!" }]}
                >
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>In Active</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
                <Button type="primary" htmlType="submit">
                  {isEditMode ? "Update" : "Add"}
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    form.resetFields();
                  }}
                  danger
                  className="cancel-btn"
                >
                  Clear
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default VisaDocument;
