import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Switch,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Menu,
} from "antd";
import "./ManageBranch.scss";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  BankOutlined,
  MailOutlined,
  IdcardOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import search from "../../assets/vector-icons/search.png";
import excel from "../../assets/vector-icons/excel.png";
import pdf from "../../assets/vector-icons/pdf.png";
import ApiClient from "../../../helpers/ApiClient";
import moment from "moment";

const { Option } = Select;

const ManageBranch = () => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [formLayout, setFormLayout] = React.useState("vertical");
  const [isEditMode, setIsEditMode] = useState(true);

  const [companyList, setCompanyList] = useState([]);
  const [branchList, setbranchList] = useState([]);
  const [formManageBranch] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [currentID, setCurrentID] = useState(-1);

  const handleAddClick = () => {
    formManageBranch.resetFields();
    showModal();
    setIsEditMode(false);
  };
  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const handelCostCenter = (Item) => {
    if (Item.CostCenter === 0) {
      return "Yes";
    } else {
      return "No";
    }
  };
  const columns = [
    {
      title: "Branch Name",
      dataIndex: "BranchName",
    },
    {
      title: "Company Name",
      dataIndex: "CompanyId",
      render: (text) => {
        return handleCompanyName(text);
      },
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "City",
      dataIndex: "City",
      // render: (City) => handelCity(City),
    },
    {
      title: "Contact No",
      dataIndex: "ContactNumber",
    },
    {
      title: "PinCode",
      dataIndex: "Pincode",
    },
    {
      title: "Branch Address",
      dataIndex: "BranchAddress",
    },
    {
      title: "CostCenter",
      render: (currentItem) => handelCostCenter(currentItem),
    },

    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];

  const handleCompanyName = (key) => {
    let companyName = companyList.find((x) => x.CompanyId === key);
    if (companyName !== undefined && Object.keys(companyName).length > 0) {
      return companyName.UserName;
    }
  };
  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              handleEditClick(rec);
              setCurrentID(rec.BranchId);
            }}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteBranch(rec.BranchId)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const handleEditClick = (rec) => {
    formManageBranch.setFieldsValue({
      BranchName: rec.BranchName ?? "",
      CostCenter: rec.CostCenter ?? "",
      ContactNumber: rec.ContactNumber ?? "",
      City: rec.City ?? "",
      Pincode: rec.Pincode ?? "",
      Email: rec.Email ?? "",
      BranchAddress: rec.BranchAddress ?? "",
      AddressLine2: rec.AddressLine2 ?? "",
      Status: rec.Status ?? "",
    });

    setIsEditMode(true);

    showModal();
  };
  const deleteBranch = (id) => {
    ApiClient.delete("CorporateExtranet/DeleteBranch/" + id).then((res) => {
      if (res.status == 200) {
        message.success("Barnch Deleted  successfully", 3);
        getAllBranches();
      }
    });
  };

  const handleSubmit = (formvalues) => {
    if (isEditMode) {
      submitUpdateBranch(formvalues);
    } else {
      submitNewBranch(formvalues);
    }
  };
  const submitNewBranch = (formvalues) => {
    formvalues = {
      ...formvalues,
    };
    ApiClient.post("CorporateExtranet/CreateBranch", formvalues).then(
      (response) => {
        if (response.status === 200) {
          setModalVisible(false);
          message.success("Branch created successfully");
          getAllBranches();
          formManageBranch.resetFields();
        } else {
          message.error(" Error Creating Branch");
        }
      }
    );
  };
  const submitUpdateBranch = (formvalues) => {
    const updatedFormData = {
      ...formvalues,
    };
    ApiClient.put(
      "CorporateExtranet/UpdateBranch/" + currentID,
      {},
      updatedFormData
    ).then((response) => {
      // console.log(response);
      if (response.status === 200) {
        setModalVisible(false);
        message.success("Branch updated successfully");
        getAllBranches();
        formManageBranch.resetFields();
      } else {
        message.error(" Error Updating Branch");
      }
    });
  };

  const getAllBranches = () => {
    ApiClient.get("CorporateExtranet/GetBranch")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setDataSource(data);
          setbranchList(data.length);
        }
      })
      .catch((error) => {});
  };

  const getAllComapany = () => {
    ApiClient.get("CorporateExtranet/GetCompany")
      .then((res) => {
        if (res.status == 200) {
          setCompanyList(res.data);
        } else {
          setCompanyList([]);
        }
      })
      .catch((e) => {
        setCompanyList([]);
      });
  };
  useEffect(() => {
    getAllBranches();
    getAllComapany();
  }, []);
  return (
    <div className="promo providerapi">
      <div className="manage-markup-section">
        <div className="manage-department-input-container">
          <Card>
            <div className="card-add-heading">
              <div className="rows-count">
                <div>
                  <h5>Manage Branch Details</h5>
                  <p>{branchList ?? 0} rows found !</p>
                </div>
              </div>
              <div className="action-images">
                <div
                  className="exlpdf"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <img
                    src={search}
                    alt="search"
                    style={{ maxHeight: "35px", maxWidth: "35px" }}
                  />
                  <img
                    src={excel}
                    alt="excel"
                    style={{ maxHeight: "35px", maxWidth: "35px" }}
                  />
                  <p className="add-icon" onClick={() => handleAddClick()}>
                    <PlusOutlined />
                  </p>
                </div>
              </div>
            </div>

            <Table
              className="table-scroll-none"
              columns={columns}
              dataSource={dataSource}
              pagination={{
                defaultPageSize: 25,
                showSizeChanger: true,
                pageSizeOptions: ["25", "50", "100", "125"],
              }}
            />
          </Card>
        </div>
      </div>
      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Manage Branch</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary" onClick={() => formManageBranch.submit()}>
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button
              type="primary"
              danger
              className="cancel-btn"
              onClick={() => formManageBranch.resetFields()}
            >
              Clear
            </Button>
          </div>,
        ]}
      >
        <Form
          form={formManageBranch}
          layout={"vertical"}
          onFinish={handleSubmit}
        >
          <Row gutter={10}>
            <Col xs={24} md={12}>
              <Form.Item
                name="CompanyId"
                label="Company Name"
                rules={[
                  { required: true, message: "Please Select Company Name!" },
                ]}
              >
                <Select>
                  {companyList.length
                    ? companyList.map((item) => (
                        <Option
                          key={"company" + item.CompanyId}
                          value={item.CompanyId}
                        >
                          {item.UserName}
                        </Option>
                      ))
                    : ""}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="BranchName"
                label="Branch Name"
                rules={[
                  { required: true, message: "Please input your BranchName!" },
                ]}
              >
                <Input
                  prefix={<BankOutlined className="site-form-item-icon" />}
                  placeholder="Branch Name"
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name="Email"
                label="Email"
                rules={[
                  { type: "email" },
                  { required: true, message: "Please Enter Your Email" },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Enter Your Email"
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name="BranchAddress"
                label="Branch Address"
                rules={[
                  {
                    required: true,
                    message: "Please input your Branch Address!",
                  },
                ]}
              >
                <Input
                  prefix={<IdcardOutlined className="site-form-item-icon" />}
                  placeholder="Branch Address"
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name="AddressLine2"
                label="Address Line 2"
                rules={[
                  { required: true, message: "Please Enter Your Address" },
                ]}
              >
                <Input
                  prefix={<IdcardOutlined className="site-form-item-icon" />}
                  placeholder="Address Line2"
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name="City"
                label="City"
                rules={[{ required: true, message: "Please Enter Your City" }]}
              >
                <Input
                  prefix={<IdcardOutlined className="site-form-item-icon" />}
                  placeholder="Address Line2"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="Pincode"
                label="Pincode"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Your Pincode",
                  },
                ]}
              >
                <Input
                  prefix={<IdcardOutlined className="site-form-item-icon" />}
                  placeholder="Pincode"
                />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name="ContactNumber"
                label="Contact Number"
                rules={[
                  { required: true, message: "Please Enter Your Contact No" },
                  {
                    minLength: 10,
                    maxLength: 10,
                    pattern: "^[0-9]{10}$",
                    message: "Must be 10 digits Numeric Value",
                  },
                ]}
              >
                <Input
                  prefix={<PhoneOutlined className="site-form-item-icon" />}
                  placeholder="Contact No"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="CostCenter"
                label="Cost Center"
                rules={[
                  { required: true, message: "Please Select Your CostCenter" },
                ]}
              >
                <Select
                  prefix={<IdcardOutlined className="site-form-item-icon" />}
                  placeholder="Select Cost Center"
                >
                  <Select.Option value={0}>Yes</Select.Option>
                  <Select.Option value={1}>No</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                name="Status"
                label="Status"
                rules={[
                  { required: true, message: "Please Select Your Status" },
                ]}
              >
                <Select
                  prefix={<IdcardOutlined className="site-form-item-icon" />}
                  placeholder="Select Status"
                >
                  <Select.Option value={0}>yes</Select.Option>
                  <Select.Option value={1}>No</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default ManageBranch;
