import React from "react";
import { Button, Col, Row, Tooltip } from "antd";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { useHistory } from "react-router-dom";
import CustomFImg from "../../../common/CustomImg/CusfliLog"
import Flight, { FlightSelection, FlightSe } from "../Flight/Flight";
import CustomNoResultFound from "../../ErrorPages/CustomNoResultFound";
import { useEffect, useState } from "react";
import "../SeperatedView/SeperatedView.scss";
import { useSytContext } from "../../../common/providers/SytProvider";
import InfiniteScroll from "react-infinite-scroll-component";
import { Modal } from 'antd';
import APIClient from "../../../helpers/ApiClient";
const SeperatedView = ({
  flightSearchObj,
  selectedFlight,
  combinedOnewayFlightList,
  changeFlightResultsView,
  setOnwardFlights,
  changeReturnFlights,
  setFlightFareRules,
  journeyType,
  showNetFare,
  promoData,
  setMultiCityIndex,
  MultiCityIndex,
  changeMulticityResults,
  traceId
}) => {
  // console.log(combinedOnewayFlightList, "flight details");
  // console.log(flightSearchObj);
  const { flightSearchBanners } = useSytContext();
  const PromoData = promoData;
  // useEffect(() => {
  //   let visibleData = flightDetails.filter((item) => item.isVisible);
  //   setFlightList(visibleData);
  // }, [flightDetails]);

  const [flightList, setFlightList] = useState([]);
  const [flightReturn, setFlightReturn] = useState(true);
  const flightResultLength = 10;
  const [displayFlightResult, updateDisplayFlightResult] = useState([])
  const [activeCollapse, setActiveCollapse] = useState("");
  const fetchMoreBuses = () => {
    setTimeout(() => {
      updateDisplayFlightResult((prev) => [...prev, ...flightList.slice(prev.length, prev.length + flightResultLength)])
    }, 100)
  }

  useEffect(() => {
    // if (selectedFlight.length > 1) {
    //   setFlightReturn(true);
    // }

    let visibleData = combinedOnewayFlightList.filter((item) => {
      if (selectedFlight?.length === 1 && journeyType === "return") {
        return selectedFlight[0]?.supplierKey === item.supplierKey && item.isVisible;
      }
      return item.isVisible;
    });
    updateDisplayFlightResult(visibleData.slice(0, flightResultLength))
    setFlightList(visibleData);
    // console.log(visibleData, "filter");
  }, [combinedOnewayFlightList]);
  //console.log(flightList);
  let bannreIndex = -1;
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const history = useHistory();
  const editOnclick = (type) => {
    if (type == 1) {
      setOnwardFlights();
      if (selectedFlight.length > 1) {
        // console.log(selectedFlight[1], "iamm");
        selectedFlight.splice(1, 1);

      }
      // setFlightReturn(false);
      // changeReturnFlights();
    } else {
      changeReturnFlights();
    }
  };



  const goToCheckoutPage = () => {
    const API_URL = "flights/updatedAirPort/search/";
    if (flightSearchObj.resultsType.toUpperCase() === "SEPARATE" &&
      flightSearchObj.airTravelType.toUpperCase() === "ROUNDTRIP") {
      const searchOrigin1 = flightSearchObj.originDestinations[0].origin;
      const searchDestination1 = flightSearchObj.originDestinations[0].destination;
      const searchOrigin2 = flightSearchObj.originDestinations[1].origin;
      const searchDestination2 = flightSearchObj.originDestinations[1].destination;
      const selectedOrigin1 = selectedFlight[0].origin;
      const selectedDestination1 = selectedFlight[0].destination;
      const selectedOrigin2 = selectedFlight[1].origin;
      const selectedDestination2 = selectedFlight[1].destination;
      const selectedOriginDescription1 = selectedFlight[0].originName;
      const selectedDestinationDescription1 = selectedFlight[0].destiantionName;
      const selectedOriginDescription2 = selectedFlight[1].originName;
      const selectedDestinationDescription2 = selectedFlight[1].destiantionName
      // console.log(selectedFlight,"jjj")
      Promise.all([
        APIClient.get(`${API_URL}${searchOrigin1}`),
        APIClient.get(`${API_URL}${searchDestination1}`),
        APIClient.get(`${API_URL}${searchOrigin2}`),
        APIClient.get(`${API_URL}${searchDestination2}`)
      ]).then(([originRes1, destinationRes1, originRes2, destinationRes2]) => {
        if (originRes1.statusCode === 200 && destinationRes1.statusCode === 200 &&
          originRes2.statusCode === 200 && destinationRes2.statusCode === 200) {

          if (searchOrigin1 === selectedOrigin1 &&
            searchDestination1 === selectedDestination1 &&
            searchOrigin2 === selectedOrigin2 &&
            searchDestination2 === selectedDestination2) {
            history.push("/flight/checkout");
          } else {
            const searchOriginDescription1 = originRes1.data.find(airport => airport.AirportCode === searchOrigin1)?.AirportDesc || searchOrigin1;
            const searchDestinationDescription1 = destinationRes1.data.find(airport => airport.AirportCode === searchDestination1)?.AirportDesc || searchDestination1;
            const searchOriginDescription2 = originRes2.data.find(airport => airport.AirportCode === searchOrigin2)?.AirportDesc || searchOrigin2;
            const searchDestinationDescription2 = destinationRes2.data.find(airport => airport.AirportCode === searchDestination2)?.AirportDesc || searchDestination2;

            Modal.confirm({
              title: 'Sector Change',
              width: 800,
              content: (
                <div>

                  <p>You have searched for <span style={{ color: 'red' }}>{searchOriginDescription1}({searchOrigin1}) - {searchDestinationDescription1}({searchDestination1}) and {searchOriginDescription2}({searchOrigin2}) - {searchDestinationDescription2}({searchDestination2})</span>, however you selected <span style={{ color: 'green' }}>{selectedOriginDescription1}({selectedOrigin1}) - {selectedDestinationDescription1}({selectedDestination1}) and {selectedOriginDescription2}({selectedOrigin2}) - {selectedDestinationDescription2}({selectedDestination2})</span>.</p>
                  <p>Are you sure you want to continue with the selected sectors?</p>
                </div>
              ),
              okText: 'CONTINUE',
              cancelText: 'CANCEL',
              onOk() {
                history.push("/flight/checkout");
              },
            });
          }
        }
      }).catch((error) => {
        console.log("Error fetching data:", error);
      });

      // const searchOrigin1 = flightSearchObj.originDestinations[0].origin;
      // const searchDestination1 = flightSearchObj.originDestinations[0].destination;
      // const searchOrigin2 = flightSearchObj.originDestinations[1].origin;
      // const searchDestination2 = flightSearchObj.originDestinations[1].destination;
      // const selectedOrigin1 = selectedFlight[0].origin;
      // const selectedDestination1 =selectedFlight[0].destination;
      // const selectedOrigin2 = selectedFlight[1].origin;
      // const selectedDestination2 = selectedFlight[1].destination;

      // //console.log("Roundtrip check:", { searchOrigin1, searchDestination1, searchOrigin2, searchDestination2, selectedOrigin1, selectedDestination1, selectedOrigin2, selectedDestination2 });

      // if (searchOrigin1 === selectedOrigin1 &&
      //     searchDestination1 === selectedDestination1 &&
      //     searchOrigin2 === selectedOrigin2 &&
      //     searchDestination2 === selectedDestination2) {
      //     //  console.log("jjjj")
      //     history.push("/flight/checkout");
      // } else {
      //   //  console.log("roundtrip mismatch", flightSearchObj, selectedFlight);
      //     // Handle mismatch or show modal if needed
      //     Modal.confirm({
      //       title: 'Sector Change',
      //       content: (
      //           <div>
      //               <p>You have searched for <span style={{ color: 'red' }}>{searchOrigin1} - {searchDestination1} and {searchOrigin2} - {searchDestination2}</span>, however you selected <span style={{ color: 'green' }}>{selectedOrigin1} - {selectedDestination1} and {selectedOrigin2} - {selectedDestination2}</span>.</p>
      //               <p>Are you sure you want to continue with the selected sectors?</p>
      //           </div>
      //       ),
      //       okText: 'CONTINUE',
      //       //okType: 'danger',
      //       cancelText: 'CANCEL',
      //       onOk() {
      //           history.push("/flight/checkout");
      //       },
      //       onCancel() {
      //         //  console.log('Cancel clicked');
      //       },
      //   });
      // }
    }

    // if (!true){
    // history.push("/flight/checkout");
    // }
  };

  const toolBreakCity = (breakCityDest) => {
    return (
      <div className="tooltip-data">
        <h4>Change</h4>
        <p>{breakCityDest}</p>
      </div>
    );
  };
  const getSelectedTotalFare = (selectedflightsArray) => {
    let totalAmount = 0;
    if (selectedflightsArray.length > 1) {
      totalAmount = selectedflightsArray.reduce((prev, cur) => {
        return prev + Number(cur.fareFamilies.fareFamilies[0].totalPublishFare);
      }, 0);
    }
    return Number(totalAmount).toFixed(2);
  };

  return (
    <div>
      {/* {console.log(selectedFlight, "selectflight")} */}
      {selectedFlight.length > 0 && flightSearchObj?.airTravelType != "multidestination" ? (
        <>
          <div className="edit-onward">
            <div className="card-results">
              <Row gutter={[16, 8]}>
                <Col md={10} sm={10} xs={24} className="flight-border-right">
                  <div className="card-results-travel-wrapper">
                    <p className="title"> Onward Selection</p>
                    <div className="card-results-travel-details">
                      <div className="itinary_wrapper">
                        <div className="itinary-details">
                          <div className="itinary-flight-name">
                            <CustomFImg

                              className="img-hot"
                              src={selectedFlight[0].airLineLogo}
                              alt={selectedFlight[0].airLineName}
                            />
                            {/* <img
                              className="airline-img"
                              src={selectedFlight[0].airLineLogo}
                              alt={selectedFlight[0].airLineName}
                            /> */}
                            <p className="name">
                              {selectedFlight[0].airLineName}
                            </p>
                          </div>
                          <div className="fromTo-wrapper">
                            <div className="cities-from-to">
                              <div className="loc_wrapper">
                                <span className="code">
                                  {/* {selectedFlight[0].flightSegments[0].origin} */}
                                  {selectedFlight[0].originCity}
                                </span>

                                <div className="break-journey-city">
                                  <div className="inner_wrapper">
                                    {selectedFlight[0].flightSegments.map(
                                      (flightSegInfo, index) =>
                                        index !== 0 ? (
                                          <div
                                            className="stop_points"
                                          // key={flightSegInfo.segId}
                                          >
                                            <Tooltip
                                              placement="top"
                                              title={toolBreakCity(
                                                flightSegInfo.origin +
                                                " " +
                                                flightSegInfo.originName
                                              )}
                                              className="citynames-tooltip"
                                            >
                                              <span className="break-city-name pointer_cursor">
                                                {flightSegInfo.origin}
                                              </span>
                                            </Tooltip>
                                          </div>
                                        ) : null
                                    )}
                                  </div>
                                </div>

                                <span className="code">
                                  {/* {
                                    selectedFlight[0].flightSegments[
                                      selectedFlight[0].flightSegments.length -
                                        1
                                    ].destination
                                  } */}
                                  {selectedFlight[0].destinationCity}
                                </span>
                              </div>
                            </div>

                            <div className="flight-stops-wrapper">
                              <span className="stops-wrapper">
                                {selectedFlight[0].flightSegments.length - 1 ===
                                  0 ? (
                                  <>Direct</>
                                ) : (
                                  <>
                                    {selectedFlight[0].flightSegments.length -
                                      1}{" "}
                                    stops{" "}
                                  </>
                                )}{" "}
                                | {flightSearchObj.cabinClass}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btncontainer">
                      <p className="price">
                        <span> {activeCurrency}</span>
                        {currencyValue(
                          selectedFlight[0].fareFamilies.fareFamilies[0]
                            .totalPublishFare
                        )}
                      </p>
                      <button
                        onClick={() => editOnclick(1)}
                        className="tg-primary-btn mob-chn-wor"
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </Col>{" "}

                {selectedFlight.length > 1 && flightReturn ? (
                  <>
                    <Col
                      md={10}
                      sm={10}
                      xs={24}
                      className="flight-border-right"
                    >
                      <div className="card-results-travel-wrapper">
                        <p className="title"> Return Selection</p>
                        <div className="card-results-travel-details">
                          <div className="itinary_wrapper">
                            <div className="itinary-details">
                              <div className="itinary-flight-name">
                                <CustomFImg

                                  className="img-hot"
                                  src={selectedFlight[1].airLineLogo}
                                  alt={selectedFlight[1].airLineName}
                                />
                                {/* <img
                                  className="airline-img"
                                  src={selectedFlight[1].airLineLogo}
                                  alt={selectedFlight[1].airLineName}
                                /> */}
                                <p className="name">
                                  {selectedFlight[1].airLineName}
                                </p>
                              </div>
                              <div className="fromTo-wrapper">
                                <div className="cities-from-to">
                                  <div className="loc_wrapper">
                                    <span className="code">
                                      {/* {
                                        selectedFlight[1].flightSegments[0]
                                          .origin
                                      } */}
                                      {selectedFlight[1].originCity}
                                    </span>

                                    <div className="break-journey-city">
                                      <div className="inner_wrapper">
                                        {selectedFlight[1].flightSegments.map(
                                          (flightSegInfo, index) =>
                                            index !== 0 ? (
                                              <div
                                                className="stop_points"
                                              // key={flightSegInfo.segId}
                                              >
                                                <Tooltip
                                                  placement="top"
                                                  title={toolBreakCity(
                                                    flightSegInfo.origin +
                                                    " " +
                                                    flightSegInfo.originName
                                                  )}
                                                  className="citynames-tooltip"
                                                >
                                                  <span className="break-city-name pointer_cursor">
                                                    {flightSegInfo.origin}
                                                  </span>
                                                </Tooltip>
                                              </div>
                                            ) : null
                                        )}
                                      </div>
                                    </div>

                                    <span className="code">
                                      {/* {
                                        selectedFlight[1].flightSegments[
                                          selectedFlight[1].flightSegments
                                            .length - 1
                                        ].destination
                                      } */}
                                      {selectedFlight[1].destinationCity}
                                    </span>
                                  </div>
                                </div>

                                <div className="flight-stops-wrapper">
                                  <span className="stops-wrapper">
                                    {selectedFlight[1].flightSegments.length -
                                      1 ===
                                      0 ? (
                                      <>Direct</>
                                    ) : (
                                      <>
                                        {selectedFlight[1].flightSegments
                                          .length - 1}{" "}
                                        stops{" "}
                                      </>
                                    )}{" "}
                                    | {flightSearchObj.cabinClass}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="btncontainer">
                          <p className="price">
                            <span> {activeCurrency}</span>
                            {currencyValue(
                              selectedFlight[1].fareFamilies.fareFamilies[0]
                                .totalPublishFare
                            )}
                          </p>
                          <button
                            onClick={() => editOnclick(2)}
                            className="tg-primary-btn mob-chn-wor"
                          >
                            Change
                          </button>
                        </div>
                      </div>
                    </Col>

                    <Col md={4} sm={4} xs={24}>
                      <div className="selected-flights-price-wrapper">
                        <div className="selected-flights-price">
                          <div className="price-box">
                            <p className="totalFare">
                              {activeCurrency} {currencyValue(getSelectedTotalFare(selectedFlight))}
                            </p>
                          </div>

                          <Button className="choose-button" type="primary" onClick={goToCheckoutPage}>

                            Book
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </>
                ) : null}

              </Row>
            </div>
          </div>

          <div className="edit-onward">
            <p className="returntext">
              Select For {journeyType === "return" ? "Return" : "Onward"}{" "}
            </p>
          </div>
        </>
      ) : null
      }
      {
        selectedFlight.length > 0 && flightSearchObj?.airTravelType == "multidestination" ? (
          <>
            <div className="edit-onward">
              <div className="card-results">
                <Row gutter={[16, 8]}>
                  <Col md={10} sm={10} xs={24} className="flight-border-right">
                    <div className="card-results-travel-wrapper">
                      <p className="title"> First Flight Selection</p>
                      <div className="card-results-travel-details">
                        <div className="itinary_wrapper">
                          <div className="itinary-details">
                            <div className="itinary-flight-name">
                              <img
                                className="airline-img"
                                src={selectedFlight[0].airLineLogo}
                                alt={selectedFlight[0].airLineName}
                              />
                              <p className="name">
                                {selectedFlight[0].airLineName}
                              </p>
                            </div>
                            <div className="fromTo-wrapper">
                              <div className="cities-from-to">
                                <div className="loc_wrapper">
                                  <span className="code">
                                    {/* {selectedFlight[0].flightSegments[0].origin} */}
                                    {selectedFlight[0].originCity}
                                  </span>

                                  <div className="break-journey-city">
                                    <div className="inner_wrapper">
                                      {selectedFlight[0].flightSegments.map(
                                        (flightSegInfo, index) =>
                                          index !== 0 ? (
                                            <div
                                              className="stop_points"
                                            // key={flightSegInfo.segId}
                                            >
                                              <Tooltip
                                                placement="top"
                                                title={toolBreakCity(
                                                  flightSegInfo.origin +
                                                  " " +
                                                  flightSegInfo.originName
                                                )}
                                                className="citynames-tooltip"
                                              >
                                                <span className="break-city-name pointer_cursor">
                                                  {flightSegInfo.origin}
                                                </span>
                                              </Tooltip>
                                            </div>
                                          ) : null
                                      )}
                                    </div>
                                  </div>

                                  <span className="code">
                                    {/* {
                                    selectedFlight[0].flightSegments[
                                      selectedFlight[0].flightSegments.length -
                                        1
                                    ].destination
                                  } */}
                                    {selectedFlight[0].destinationCity}
                                  </span>
                                </div>
                              </div>

                              <div className="flight-stops-wrapper">
                                <span className="stops-wrapper">
                                  {selectedFlight[0].flightSegments.length - 1 ===
                                    0 ? (
                                    <>Direct</>
                                  ) : (
                                    <>
                                      {selectedFlight[0].flightSegments.length -
                                        1}{" "}
                                      stops{" "}
                                    </>
                                  )}{" "}
                                  | {flightSearchObj.cabinClass}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="btncontainer">
                        <p className="price">
                          <span> {activeCurrency}</span>
                          {currencyValue(
                            selectedFlight[0].fareFamilies.fareFamilies[0]
                              .totalPublishFare
                          )}
                        </p>
                        <button
                          onClick={() => setMultiCityIndex(0)}
                          className="tg-primary-btn mob-chn-wor"
                        >
                          Change
                        </button>
                      </div>
                    </div>
                  </Col>{" "}

                  {selectedFlight.length > 1 && selectedFlight.length <= flightSearchObj?.originDestinations?.length ? (
                    <>
                      <Col
                        md={10}
                        sm={10}
                        xs={24}
                        className="flight-border-right"
                      >
                        <div className="card-results-travel-wrapper">
                          <p className="title">Second Flight Selection</p>
                          <div className="card-results-travel-details">
                            <div className="itinary_wrapper">
                              <div className="itinary-details">
                                <div className="itinary-flight-name">
                                  <img
                                    className="airline-img"
                                    src={selectedFlight[1]?.airLineLogo}
                                    alt={selectedFlight[1].airLineName}
                                  />
                                  <p className="name">
                                    {selectedFlight[1].airLineName}
                                  </p>
                                </div>
                                <div className="fromTo-wrapper">
                                  <div className="cities-from-to">
                                    <div className="loc_wrapper">
                                      <span className="code">
                                        {/* {
                                        selectedFlight[1].flightSegments[0]
                                          .origin
                                      } */}
                                        {selectedFlight[1].originCity}
                                      </span>

                                      <div className="break-journey-city">
                                        <div className="inner_wrapper">
                                          {selectedFlight[1].flightSegments.map(
                                            (flightSegInfo, index) =>
                                              index !== 0 ? (
                                                <div
                                                  className="stop_points"
                                                // key={flightSegInfo.segId}
                                                >
                                                  <Tooltip
                                                    placement="top"
                                                    title={toolBreakCity(
                                                      flightSegInfo.origin +
                                                      " " +
                                                      flightSegInfo.originName
                                                    )}
                                                    className="citynames-tooltip"
                                                  >
                                                    <span className="break-city-name pointer_cursor">
                                                      {flightSegInfo.origin}
                                                    </span>
                                                  </Tooltip>
                                                </div>
                                              ) : null
                                          )}
                                        </div>
                                      </div>

                                      <span className="code">
                                        {/* {
                                        selectedFlight[1].flightSegments[
                                          selectedFlight[1].flightSegments
                                            .length - 1
                                        ].destination
                                      } */}
                                        {selectedFlight[1].destinationCity}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="flight-stops-wrapper">
                                    <span className="stops-wrapper">
                                      {selectedFlight[1].flightSegments.length -
                                        1 ===
                                        0 ? (
                                        <>Direct</>
                                      ) : (
                                        <>
                                          {selectedFlight[1].flightSegments
                                            .length - 1}{" "}
                                          stops{" "}
                                        </>
                                      )}{" "}
                                      | {flightSearchObj.cabinClass}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="btncontainer">
                            <p className="price">
                              <span> {activeCurrency}</span>
                              {currencyValue(
                                selectedFlight[1].fareFamilies.fareFamilies[0]
                                  .totalPublishFare
                              )}
                            </p>
                            <button
                              onClick={() => setMultiCityIndex(1)}
                              className="tg-primary-btn mob-chn-wor"
                            >
                              Change
                            </button>
                          </div>
                        </div>
                      </Col>

                      {flightSearchObj?.originDestinations?.length == 2 ? <Col md={4} sm={4} xs={24}>
                        <div className="selected-flights-price-wrapper">
                          <div className="selected-flights-price">
                            <div className="price-box">
                              <p className="totalFare">
                                {activeCurrency} {currencyValue(getSelectedTotalFare(selectedFlight))}
                              </p>
                            </div>

                            <Button className="choose-button" type="primary" onClick={goToCheckoutPage}>

                              Book
                            </Button>
                          </div>
                        </div>
                      </Col> : null}
                    </>
                  ) : null}
                  {selectedFlight.length > 2 && selectedFlight.length <= flightSearchObj?.originDestinations?.length ? (
                    <>
                      <Col
                        md={10}
                        sm={10}
                        xs={24}
                        className="flight-border-right"
                      >
                        <div className="card-results-travel-wrapper">
                          <p className="title">Third Flight Selection</p>
                          <div className="card-results-travel-details">
                            <div className="itinary_wrapper">
                              <div className="itinary-details">
                                <div className="itinary-flight-name">
                                  <img
                                    className="airline-img"
                                    src={selectedFlight[2].airLineLogo}
                                    alt={selectedFlight[2].airLineName}
                                  />
                                  <p className="name">
                                    {selectedFlight[2].airLineName}
                                  </p>
                                </div>
                                <div className="fromTo-wrapper">
                                  <div className="cities-from-to">
                                    <div className="loc_wrapper">
                                      <span className="code">
                                        {/* {
                                        selectedFlight[1].flightSegments[0]
                                          .origin
                                      } */}
                                        {selectedFlight[2].originCity}
                                      </span>

                                      <div className="break-journey-city">
                                        <div className="inner_wrapper">
                                          {selectedFlight[2].flightSegments.map(
                                            (flightSegInfo, index) =>
                                              index !== 0 ? (
                                                <div
                                                  className="stop_points"
                                                // key={flightSegInfo.segId}
                                                >
                                                  <Tooltip
                                                    placement="top"
                                                    title={toolBreakCity(
                                                      flightSegInfo.origin +
                                                      " " +
                                                      flightSegInfo.originName
                                                    )}
                                                    className="citynames-tooltip"
                                                  >
                                                    <span className="break-city-name pointer_cursor">
                                                      {flightSegInfo.origin}
                                                    </span>
                                                  </Tooltip>
                                                </div>
                                              ) : null
                                          )}
                                        </div>
                                      </div>

                                      <span className="code">
                                        {/* {
                                        selectedFlight[1].flightSegments[
                                          selectedFlight[1].flightSegments
                                            .length - 1
                                        ].destination
                                      } */}
                                        {selectedFlight[2].destinationCity}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="flight-stops-wrapper">
                                    <span className="stops-wrapper">
                                      {selectedFlight[2].flightSegments.length -
                                        1 ===
                                        0 ? (
                                        <>Direct</>
                                      ) : (
                                        <>
                                          {selectedFlight[2].flightSegments
                                            .length - 1}{" "}
                                          stops{" "}
                                        </>
                                      )}{" "}
                                      | {flightSearchObj.cabinClass}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="btncontainer">
                            <p className="price">
                              <span> {activeCurrency}</span>
                              {currencyValue(
                                selectedFlight[2].fareFamilies.fareFamilies[0]
                                  .totalPublishFare
                              )}
                            </p>
                            <button
                              onClick={() => setMultiCityIndex(2)}
                              className="tg-primary-btn"
                            >
                              Change
                            </button>
                          </div>
                        </div>
                      </Col>

                      {flightSearchObj?.originDestinations?.length === 3 ? <Col md={4} sm={4} xs={24}>
                        <div className="selected-flights-price-wrapper">
                          <div className="selected-flights-price">
                            <div className="price-box">
                              <p className="totalFare">
                                {activeCurrency} {currencyValue(getSelectedTotalFare(selectedFlight))}
                              </p>
                            </div>

                            <Button className="choose-button" type="primary" onClick={goToCheckoutPage}>

                              Book
                            </Button>
                          </div>
                        </div>
                      </Col> : null}
                    </>
                  ) : null}
                  {selectedFlight.length > 3 && selectedFlight.length <= flightSearchObj?.originDestinations?.length ? (
                    <>
                      <Col
                        md={10}
                        sm={10}
                        xs={24}
                        className="flight-border-right"
                      >
                        <div className="card-results-travel-wrapper">
                          <p className="title">Fourth Flight Selection</p>
                          <div className="card-results-travel-details">
                            <div className="itinary_wrapper">
                              <div className="itinary-details">
                                <div className="itinary-flight-name">
                                  <img
                                    className="airline-img"
                                    src={selectedFlight[3].airLineLogo}
                                    alt={selectedFlight[3].airLineName}
                                  />
                                  <p className="name">
                                    {selectedFlight[3].airLineName}
                                  </p>
                                </div>
                                <div className="fromTo-wrapper">
                                  <div className="cities-from-to">
                                    <div className="loc_wrapper">
                                      <span className="code">
                                        {/* {
                                        selectedFlight[1].flightSegments[0]
                                          .origin
                                      } */}
                                        {selectedFlight[3].originCity}
                                      </span>

                                      <div className="break-journey-city">
                                        <div className="inner_wrapper">
                                          {selectedFlight[3].flightSegments.map(
                                            (flightSegInfo, index) =>
                                              index !== 0 ? (
                                                <div
                                                  className="stop_points"
                                                // key={flightSegInfo.segId}
                                                >
                                                  <Tooltip
                                                    placement="top"
                                                    title={toolBreakCity(
                                                      flightSegInfo.origin +
                                                      " " +
                                                      flightSegInfo.originName
                                                    )}
                                                    className="citynames-tooltip"
                                                  >
                                                    <span className="break-city-name pointer_cursor">
                                                      {flightSegInfo.origin}
                                                    </span>
                                                  </Tooltip>
                                                </div>
                                              ) : null
                                          )}
                                        </div>
                                      </div>

                                      <span className="code">
                                        {/* {
                                        selectedFlight[1].flightSegments[
                                          selectedFlight[1].flightSegments
                                            .length - 1
                                        ].destination
                                      } */}
                                        {selectedFlight[3].destinationCity}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="flight-stops-wrapper">
                                    <span className="stops-wrapper">
                                      {selectedFlight[3].flightSegments.length -
                                        1 ===
                                        0 ? (
                                        <>Direct</>
                                      ) : (
                                        <>
                                          {selectedFlight[3].flightSegments
                                            .length - 1}{" "}
                                          stops{" "}
                                        </>
                                      )}{" "}
                                      | {flightSearchObj.cabinClass}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="btncontainer">
                            <p className="price">
                              <span> {activeCurrency}</span>
                              {currencyValue(
                                selectedFlight[3].fareFamilies.fareFamilies[0]
                                  .totalPublishFare
                              )}
                            </p>
                            <button
                              onClick={() => setMultiCityIndex(3)}
                              className="tg-primary-btn"
                            >
                              Change
                            </button>
                          </div>
                        </div>
                      </Col>

                      {flightSearchObj?.originDestinations?.length === 4 ? <Col md={4} sm={4} xs={24}>
                        <div className="selected-flights-price-wrapper">
                          <div className="selected-flights-price">
                            <div className="price-box">
                              <p className="totalFare">
                                {activeCurrency} {currencyValue(getSelectedTotalFare(selectedFlight))}
                              </p>
                            </div>

                            <Button className="choose-button" type="primary" onClick={goToCheckoutPage}>

                              Book
                            </Button>
                          </div>
                        </div>
                      </Col> : null}
                    </>
                  ) : null}
                </Row>
              </div>
            </div>
            <div className="edit-onward">
              <p className="returntext">
                Select For {MultiCityIndex === 0 ? "First Flight" : MultiCityIndex === 1 ? "Second Flight" : MultiCityIndex === 2 ? "Third Flight" : "Fourth Flight"}{" "}
              </p>
            </div>

          </>
        ) : null
      }
      {/* {console.log(displayFlightResult, "flightresults")} */}
      {
        displayFlightResult.length > 0 ? (

          <InfiniteScroll
            dataLength={displayFlightResult.length}
            next={fetchMoreBuses}
            hasMore={displayFlightResult.length >= flightList.length ? false : true}
            endMessage={"End Of Flight Result"}
            loader={<h4>Loading...</h4>}
          >
            {displayFlightResult.map((flight, i) => {
              let filteredPromoData =
                PromoData.length > 0
                  ? PromoData?.filter((item) => item?.airlineCode === flight.airLine)
                    .length > 0
                    ? promoData?.filter(
                      (item) =>
                        item.airlineCode?.toUpperCase() ===
                        flight.airLine?.toUpperCase()
                    )
                    : promoData?.filter((item) => item.airlineCode === "ALL")
                  : null;
              return (
                <>
                  {i != 0 && i % 6 == 0 && flightSearchBanners[++bannreIndex] && (
                    <div className="combine-roundtrip-view">
                      <img
                        style={{
                          width: "100%",
                          height: "90px",
                          objectFit: "cover",
                        }}
                        src={
                          process.env.REACT_APP_BASE_URL +
                          flightSearchBanners[bannreIndex].path.substring(1)
                        }
                        data={
                          flightSearchBanners[bannreIndex + 1]
                            ? null
                            : (bannreIndex = -1)
                        }
                      />{" "}
                    </div>
                  )}

                  <div className="combine-roundtrip-view">
                    <Row>
                      <Col md={18} xs={24}>
                        <Flight
                          flightInfo={flight}
                          key={flight.flightId}
                          flightSearchObj={flightSearchObj}
                          setFlightFareRules={setFlightFareRules}
                          fareFamilies={flight.fareFamilies}
                          promoData={filteredPromoData}
                          index={`FLC-${i + 1}`}
                          activeCollapse={activeCollapse}
                          setActiveCollapse={setActiveCollapse}
                        />
                      </Col>
                      <Col md={6} xs={24}>
                        <div className="choose-btn-wrapper">
                          <FlightSelection
                            flightInfo={flight}
                            changeFlightResultsView={changeFlightResultsView}
                            flightSearchObj={flightSearchObj}
                            selectedFlight={selectedFlight}
                            showNetFare={showNetFare}
                            journeyType={journeyType}
                            MultiCityIndex={MultiCityIndex}
                            index={`FLC-${i + 1}`}
                            activeCollapse={activeCollapse}
                            setActiveCollapse={setActiveCollapse}
                          />
                        </div>
                      </Col>
                    </Row><Row><Col>
                      <FlightSe
                        flightInfo={flight}
                        changeFlightResultsView={changeFlightResultsView}
                        flightSearchObj={flightSearchObj}
                        selectedFlight={selectedFlight}
                        showNetFare={showNetFare}
                        journeyType={journeyType}
                        MultiCityIndex={MultiCityIndex}
                        index={`FLC-${i + 1}`}
                        activeCollapse={activeCollapse}
                        setActiveCollapse={setActiveCollapse}
                        traceId={traceId}
                      />
                    </Col></Row>
                  </div>
                  {" "}
                </>
              );
            })}</InfiniteScroll>

        ) : (
          <CustomNoResultFound title={"No Flights Available"} />
        )
      }
    </div >
  );
};

export default SeperatedView;
