import React, { useState, useEffect, useContext } from "react";
import { Button, Radio } from "antd";
import { GlobalStatesContext } from "../../../common/providers";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";

import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useAuthContext } from "../../../common/providers/AuthProvider";
const VisaSort = ({ setVisaList }) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const {
    state: { visaSearchResultObj, carResultObj },
    updateVisaSearchResultObj,
    updateCarSearchResultObj,
  } = useContext(GlobalStatesContext);

  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [sorting, setSorting] = useState("price");
  const [sortDirection, setSortDirection] = useState("asc");
  const handleChange = (e) => {
    const selSorting = e.target.value;
    setSorting(selSorting);
    setSortDirection("asc");
    sortVisas(selSorting, "asc");
  };

  const getMinumumTimeFromCriteria = (selectedSort) => {
    let Visa = [];
    let fare = 0;

    Visa = visaSearchResultObj.data.Visas[0];
    fare = visaSearchResultObj.data.Visas[0].Fees;

    switch (selectedSort) {
      case "price":
        return fare;

      case "name":
        return Visa.VisaName;

      case "visaType":
        return Visa.TypeofVisa;

      case "entry":
        return Visa.Entry;
    }
    return " ";
  };

  const getSortIcon = (val) => {
    return val === "asc" ? <CaretUpOutlined /> : <CaretDownOutlined />;
  };

  let VisaResults = visaSearchResultObj.data.Visas;

  const sortVisas = (selSorting, dir) => {
    let sortedList = [];

    sortedList = VisaResults.sort((visaA, visaB) => {
      if (selSorting == "price") {
        return dir === "asc"
          ? compareVisas(visaA.Fees, visaB.Fees, selSorting)
          : compareVisas(visaB.Fees, visaA.Fees, selSorting);
      }
      if (selSorting == "name") {
        return dir === "asc"
          ? compareVisas(visaA.VisaName, visaB.VisaName, selSorting)
          : compareVisas(visaB.VisaName, visaA.VisaName, selSorting);
      }
      if (selSorting == "entry") {
        return dir === "asc"
          ? compareVisas(visaA.Entry, visaB.Entry, selSorting)
          : compareVisas(visaB.Entry, visaA.Entry, selSorting);
      }
    });

    if (
      selSorting == "price" ||
      selSorting == "name" ||
      selSorting == "entry"
    ) {
      if (sortedList.length) {
        setVisaList({ ...visaSearchResultObj.data, Visas: sortedList });
      }
    }
  };

  const compareVisas = (a, b, selSorting) => {
    if (
      selSorting === "name" ||
      selSorting === "price" ||
      selSorting === "entry"
    ) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    } else {
      return a - b;
    }
  };

  const handleClick = (e) => {
    const selSorting = e.target.value;
    if (sorting === selSorting) {
      let dir = sortDirection === "asc" ? "desc" : "asc";
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      sortVisas(selSorting, dir);
    }
  };

  return (
    <div className="sort-block sort-bus-block-22">
      <div className="results-sort-block p-3 align-items-center">
        <p className="sort-header mobile-sort-22 m-0">Sort by:</p>
        <Radio.Group onChange={handleChange} value={sorting} className="ml-4"  style={{display:"flex",width:"90%", justifyContent:"space-between"}}>
          
          <Radio value={"name"} onClick={handleClick}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type">
                  Name {sorting === "name" ? getSortIcon(sortDirection) : ""}
                </p>
                <span>{getMinumumTimeFromCriteria("name")}</span>
              </div>
            </span>
          </Radio>

          <Radio value={"visaType"} onClick={handleClick}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type">
                  Visa Type{" "}
                  {sorting === "visaType" ? getSortIcon(sortDirection) : ""}
                </p>
                <span>{getMinumumTimeFromCriteria("visaType")}</span>
              </div>
            </span>
          </Radio>
          <Radio value={"entry"} onClick={handleClick}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type">
                  Entry {sorting === "entry" ? getSortIcon(sortDirection) : ""}
                </p>
                <span>{getMinumumTimeFromCriteria("entry")}</span>
              </div>
            </span>
          </Radio>

        
          <Radio value={"price"} onClick={handleClick}>
            <span className="checkmark">
              <div className="active-background">
                <p className="price-type">
                  Fare {sorting === "price" ? getSortIcon(sortDirection) : ""}
                </p>
                <span>{activeCurrency} {currencyValue(getMinumumTimeFromCriteria("price"))}</span>
              </div>
            </span>
          </Radio>
        </Radio.Group>
      </div>
    </div>
  );
};

export default VisaSort;
