import React, { useState, useEffect } from "react";
import "../../admin/Adminstyles.scss";
import {
  Button,
  Card,
  Col,
  Input,
  InputNumber,
  Row,
  Form,
  Select,
  Table,
  Layout,
  message,
  Modal,
  Popconfirm,
  //Popover,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
 // PlusCircleFilled,
  //InfoCircleTwoTone,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import UpdatedComponent from "../ExportExcel";
import APIClient from "../../helpers/ApiClient";
import { getRoleType, getTypeSign, getBusType } from "./commissionhelper";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const VisaCommissions = (props) => {
  const { Option } = Select;
  const { Content } = Layout;
  const [roleType, setRoleType] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [busComm, setBusComm] = useState([]);
  const [operatorList, setOperatorList] = useState([]);
  const [filterOperatorList, setFilterOperatorList] = useState([]);
  const [membershipList, setMembershipList] = useState([]);

  const [id, setId] = useState(-1);
  const [form] = Form.useForm();
  const validateMessages = {
    required: "",
  };

  useEffect(() => {
    // getBusCommissionsList();
    // getOperators();
    // getMembership();
  }, []);

  const handleRoleType = (val) => setRoleType(val);
  const getMembership = () => {
    APIClient.get("admin/memberships")
      .then((res) => {
        if (res.status === 200) {
          setMembershipList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getBusCommissionsList = () => {
    APIClient.get("admin/busCommissions")
      .then((res) => {
        if (res.status === 200) {
          mapBusCommData(res.data);
        } else {
          setBusComm([]);
        }
      })
      .catch((error) => {
        setBusComm([]);
        console.error(error);
      });
  };

  function mapBusCommData(busCommData) {
    let data = busCommData.map((item, index) => {
      return {
        ...item,
        Sno: index + 1,
        RoleTypeName: getRoleType(item.RoleType),
        BusTypeName: getBusType(item.BusType),
        MembershipID: item?.MembershipID?.MembershipID ?? "",
        MembershipName: item?.MembershipID?.Name ?? "",
        OperatorID: item?.OperatorID?.OperatorServiceID ?? "",
        OperatorName: item?.OperatorID?.OperatorName ?? "",
      };
    });
    setBusComm(data);
  }

  const getOperators = () => {
    APIClient.get("admin/operators")
      .then((res) => {
        if (res.status === 200) {
          let filterOperators = res.data.filter(
            (item) => item.ServiceType === 3
          );
          setOperatorList(filterOperators);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleOperator = (value) => {
    let filterOperator = operatorList.filter((item) => item.BusType === value);
    setFilterOperatorList(filterOperator);
  };

  function deleteMap(BusCommissionID) {
    APIClient.delete("admin/busCommissions/" + BusCommissionID)
      .then((res) => {
        if (res.status === 200) {
          message.success("Deleted  successfully", 3);
          getBusCommissionsList();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const updateMap = (currentData) => {
    setIsEditMode(true);
    setId(currentData.BusCommissionID);
    handleRoleType(currentData.RoleType);
    form.setFieldsValue({
      ...currentData,
    });
    setModalVisible(true);
  };

  const showModal = () => {
    if (isEditMode) {
      setIsEditMode(false);
      form.resetFields();
    }
    handleRoleType(1);
    setModalVisible(true);
  };

  const tableActions = (currentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => updateMap(currentItem)}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteMap(currentItem.BusCommissionID)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const clear = () => {
    setModalVisible(false);
  };

  const submitForm = (value) => {
    const formMapData = {
      CreatedBy: "1",
      DeviceOS: 1,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
      ...value,
      MembershipID: value.RoleType === 5 ? value.MembershipID : 1,
      Commission: value.RoleType === 5 ? value.Commission : 0,
      CommissionType: value.RoleType === 5 ? value.CommissionType : 0,
    };
    isEditMode
      ? submitUpdateBusComm(formMapData)
      : submitBusCommDetails(formMapData);
  };

  const submitBusCommDetails = (formMapData) => {
    APIClient.post("admin/busCommissions", formMapData)
      .then((response) => {
        if (response.status === 200) {
          getBusCommissionsList();
          message.success("Details Added SuccessFully", 3);
          setModalVisible(false);
          form.resetFields();
        } else if (response.status === 404) {
          message.error("Not Found", 3);
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitUpdateBusComm = (formMapData) => {
    APIClient.put(`admin/busCommissions/${id}`, {}, formMapData)
      .then((response) => {
        if (response.status === 200) {
          getBusCommissionsList();
          message.success("Details Updated SuccessFully", 3);
          setModalVisible(false);
          form.resetFields();
        } else if (response.status === 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = busComm.filter((item)=> {
        if (item !== undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setBusComm(searchResults);
    } else {
      getBusCommissionsList();
    }
  };

  const columns = [
    {
      title: "S. No.",
      dataIndex: "Sno",
    },
    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
    {
      title: "Roles",
      dataIndex: "RoleTypeName",
      sorter: (a, b) => a.RoleTypeName.localeCompare(b.RoleTypeName),
    },
    {
      title: "Membership",
      dataIndex: "MembershipName",
      sorter: (a, b) => a.MembershipName.localeCompare(b.MembershipName),
    },
    {
      title: "Bus Type",
      dataIndex: "BusTypeName",
      sorter: (a, b) => a.BusTypeName.localeCompare(b.BusTypeName),
    },

    {
      title: "Bus Operator",
      dataIndex: "OperatorName",
      sorter: (a, b) => a.OperatorName.localeCompare(b.OperatorName),
    },

    {
      title: "Markup",
      dataIndex: "Markup",
      render: (value, currentData, index) => (
        <>{value + " " + getTypeSign(currentData.MarkupType)}</>
      ),
    },
    {
      title: "Commission",
      dataIndex: "Commission",
      render: (value, currentData, index) => (
        <>{value + " " + getTypeSign(currentData.CommissionType)}</>
      ),
    },
  ];

  const handleExcel = () => {
    const excelData = busComm.map((item) => {
      return {
        S_No: item.Sno,
        Bus_Type: item.BusTypeName,
        Roles: item.RoleTypeName,
        Membership_Name: item.MembershipName,
        Bus_Operator: item.OperatorName,
        Markup: item.Markup,
        Commission: item.Commission,
      };
    });
    props.exportExcel(excelData, "BusCommissionDetails");
  };

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          View Visa Commission{" "}
                          <HelpInfoHelper
                            screenName={"/admin/visacommissions"}
                          />
                        </h5>
                        <p>{busComm.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img src={excel} alt="excel" onClick={handleExcel} />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getBusCommissionsList()}
                      />
                      {/* <p className="add-deposit-icon" onClick={showModal}>
                        <PlusCircleFilled />
                      </p> */}
                    </div>
                  </div>
                  <div>
                    <Table
                      scroll={{ x: true }}
                      bordered
                      dataSource={busComm}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong> {isEditMode ? "Update" : "Add"} Bus Commission</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header markup-modal modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        width={"800px"}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={form.submit}
            >
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button key="submit" type="danger" onClick={clear}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        <Form
          layout={"vertical"}
          form={form}
          onFinish={submitForm}
          validateMessages={validateMessages}
        >
          <div>
            <Row gutter={16}>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Role Type"
                  name="RoleType"
                  rules={[{ required: true }]}
                >
                  <Select
                    placeholder="Please Select"
                    onSelect={(val) => handleRoleType(val)}
                  >
                    <Option value={2}>Users</Option>
                    <Option value={4}>Guest</Option>
                    <Option value={5}>Partner</Option>
                  </Select>
                </Form.Item>
              </Col>
              {roleType === 5 && (
                <Col className="gutter-row" md={8} xs={24}>
                  <Form.Item
                    label="Membership"
                    name="MembershipID"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Select Membership"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {membershipList.map((data, index) => (
                        <Option key={index} value={data.MembershipID}>
                          {data.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Bus Type"
                  name="BusType"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Please Select" onChange={handleOperator}>
                    <Option value={1}>RTC Buses</Option>
                    <Option value={2}>Private Travels</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Bus Operator"
                  name="OperatorID"
                  //rules={[{ required: true }]}
                >
                  <Select placeholder="Please Select">
                    {operatorList.map((oper, index) => (
                      <Option key={index} value={oper.OperatorServiceID}>
                        {oper.OperatorName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>

          <div className="seperation-line">
            <Row gutter={16}>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Markup Type"
                  name="MarkupType"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Please select">
                    <Option value={0}>Fixed</Option>
                    <Option value={1}>Percentage</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Markup"
                  name="Markup"
                  rules={[{ required: true }]}
                >
                  <InputNumber style={{ width: "100%" }} min={0} />
                </Form.Item>
              </Col>
              {roleType === 5 && (
                <>
                  <Col className="gutter-row" md={8} xs={24}>
                    <Form.Item
                      label="Commission Type"
                      name="CommissionType"
                      rules={[{ required: true }]}
                    >
                      <Select placeholder="Please select">
                        <Option value={0}>Fixed</Option>
                        <Option value={1}>Percentage</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" md={8} xs={24}>
                    <Form.Item
                      label="Commission"
                      name="Commission"
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        min={0}
                        type="number"
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </Form>
      </Modal>
    </div>
  );
};
export default UpdatedComponent(VisaCommissions);
