export const getPromoDiscount = (promoData, total) => {
  let promoAmount = 0;
  if (promoData && promoData?.Discount && promoData?.status) {
    if (promoData.DiscountType == 1) {
      promoAmount = Number((total / 100) * promoData.Discount);
    } else {
      promoAmount = Number(promoData.Discount);
    }
    // {console.log(total,promoAmount,"misam");}
  }

  return Number(promoAmount);
};

export const getConvFee = (ConvFee, total) => {

  // console.log(ConvFee, "convinfee")
  // console.log(total, "total")
  let convamount = 0;
  // if (ConvFee && ConvFee?.amount && ConvFee?.status) {
  //   if (ConvFee.type == 1) {
  //     convamount = Number(ConvFee.amount);
  //   } else {
  //     convamount = Number(
  //       (total / 100) * Number(ConvFee.amount ? ConvFee.amount : 0)
  //     );
  //   }
  // }

  if (ConvFee && ConvFee?.amount) {
    if (ConvFee.type == 1) {
      convamount = Number(ConvFee.amount);
    } else {
      convamount = Number(
        (total / 100) * Number(ConvFee.amount ? ConvFee.amount : 0)
      );
    }
  }

  return Number(convamount);
};
