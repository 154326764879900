import React from "react";
import { Route, Switch } from "react-router-dom";

import AgentProtectedRoute from "../../helpers/AgentProtectedRoute";

// import BusSearch from "./../../pages/Buses/BusSearch/BusSearch";
// import BusResults from "./../../pages/Buses/BusResults/BusResults";
// import BusCheckout from "./../../pages/Buses/Checkout/BusCheckout";
// import BusReview from "../../pages/Buses/Review/BusReview";
import HotelSearch from "./../../pages/Hotels/HotelSearch/HotelSearch";
import HotelResults from "./../../pages/Hotels/HotelResults/HotelResults";
import HotelDet from "./../../pages/Hotels/HotelDet/HotelDet";
import HotelCheckout from "./../../pages/Hotels/HotelCheckout/HotelCheckout";
import HotelPreview from "./../../pages/Hotels/HotelPreview/HotelPreview";
import HotelInvoice from "./../../pages/Hotels/HotelInvoice/HotelInvoice";
import Map from "./../../components/Map/Map";
import UserRegistration from "./../../components/UserRegistration/UserRegistration";
import Wallet from "../../components/Wallet/Wallet";
import TransactionReports from "../../components/TransactionReports/TransactionReports";
import AgentRegistration from "../../components/AgentRegistration/AgentRegistration";
import Login from "./../../components/Login/Login";

import FlightCheckout from "./../../components/FlightsCheckout/FlightsCheckout";
import SearchResults from "./../../components/SearchResults/SearchResults";
import Home from "./../../components/home/Home";
import FlightReview from "./../../components/Flights-Review/Flights-Review";

import CheckInformation from "../../components/CheckInformation/CheckInformation";
import Deals from "../../components/Deals/Deals";
// import DealImages from "../../admin/Deals/DealImages.jsx";

import DealsDetails from "../../components/home/DealsDetails/DealsDetails";
import Offers from "../../components/Offers/Offers";
import Error404 from "../../components/ErrorPages/Error404";

import TravellerDetails from "../../components/TravellerDetails/TravellerDetails";
import ChangePassword from "../../components/ChangePassword/ChangePassword";
import MyPackage from "../../components/Myquotations/MyQuotation";
import HotelBookingList from "../../pages/Hotels/HotelBookingsList/HotelBookingsList";

import TicketCancel from "../../common/TicketCancel/TicketCancel";
import PrintTicket from "../../common/PrintTicket/PrintTicket";
import OurTeam from "../../components/OurTeam/OurTeam";
import Testimonials from "../../components/Testimonials/Testimonials";
import Faqs from "../../components/Faqs/Faqs";
import Termsofconditions from "../../components/Termsofconditions/Termsofconditions";
import Privacypolicy from "../../components/Privacypolicy/Privacypolicy";
import Disclaimer from "../../components/Disclaimer/Disclaimer";
import Careers from "../../components/Careers/Careers";
import Socialresponsibility from "../../components/Socialresponsibility/Socialresponsibility";
import Checkflightinfo from "../../components/Checkflightinfo/Checkflightinfo";
import Refundpolicy from "../../components/Refundpolicy/Refundpolicy";
import Investorrelations from "../../components/Investorrelations/Investorrelations";
import CombinedBooking from "../../components/CombinedBooking/CombinedBooking";
import Profile from "../../components/Profile/Profile";
import Bookingreports from "../../components/Bookingreports/Bookingreports";
import PgrequestTest from "../../PgRequestTest";
import ContactUsForm from "../../components/ContactUsForm/ContactUsForm";

import FlightEnquiry from "../../components/FlightEnquiry/FlightEnquiry";
import PaymentFailure from "../../admin/PaymentFailure/PaymentFailure";
import AboutUS from "../../components/AboutUS/AboutUs";
import Mytrips from "../../components/Mytrips/Mytrips";

import Unauthorized from "../../components/ErrorPages/Unauthorized";
import NotFound from "../../components/ErrorPages/NotFound";
import ProtectedRoute from "../../helpers/RouteProtection";
import ErrorScreen from "../../helpers/ErrorScreen";
import PaymentSucces from "../../admin/PaymentSuccess/PaymentSuccess";



import Combinedbuildpkg from "../../components/Combinedbuildpkg/Combinedbuildpkg";

//import Quotationcheckout from "../../pages/Activitespkg/Quotationcheckout/Quotationcheckout";
import MyProfile from "../../components/MyProfile/MyProfile";
import Storevistors from "../../components/Storevistors/Storevistors";
import BankDetails from "../../components/BankDetails/BankDetails";
import Deposits from "../../components/Deposits/Deposits";
import AgentLogo from "../../components/AgentLogo/AgentLogo";
import Agentmarkup from "../../components/Agentmarkup/Agentmarkup";
import Commissiondetails from "../../components/Commissiondetails/Commissiondetails";

import UserBuildPackageTicket from "../../components/Bookingreports/UserTickets/UserBuildPackageTicket";

import UserFlightTicket from "../../components/Bookingreports/UserTickets/UserFlightTicket";
import UserHotelTicket from "../../components/Bookingreports/UserTickets/UserHotelTicket.jsx";


import Travelitinerary from "../../components/Travelitinerary/Travelitinerary";
import CouponWallet from "../../components/CouponWallet/CouponWallet";

import VisaSearch from "../../pages/Visa/Visasearch/Visasearch";
import Dubaivisa from "../../pages/Visa/Dubaivisa/Dubaivisa";
import Travellersvisadetails from "../../pages/Visa/Travellersvisadetails/Travellersvisadetails";
import Agenthomepage from "../../components/Agenthomepage/Agenthomepage";
import Statement from "../../components/statement/Statement";
import Webcheckin from "../../components/Webcheckin/Webcheckin";
import Discounts from "../../components/Discounts/Discounts";

import CarHire from "../../components/CarHire/CarHire";
import Thankyou from "../../components/Thankyou/Thankyou";
import Visa from "../../components/Visa/Visa";

import CarsSearch from "./../../pages/Cars/CarsSearch/CarsSearch";
import CarsResults from "./../../pages/Cars/CarsResults/CarsResults";
import TransferResults from "./../../pages/Cars/CarsResults/TransferResults";
import CarsCheckout from "./../../pages/Cars/CarsCheckout/CarsCheckout";
import CarReview from "./../../pages/Cars/CarsReview/CarReview";
import UserCarTicket from "../../components/Bookingreports/UserTickets/UserCarTicket";
import UserCarTransferTicket from "../../components/Bookingreports/UserTickets/UserCarTransferTicket";
import VisaResults from "../../pages/Visa/VisaResults/visaResults";
import VisaDetails from "../../pages/Visa/visaDetails/visaDetails";
import Travelrequests from "../../components/Travelrequests/Travelrequests";
import VisaCheckout from "../../pages/Visa/VisaCheckout/visaCheckout";
import VisaReview from "../../pages/Visa/VisaReview/VisaReview";




import VisaGuide from "../../components/VisaGuide/VisaGuide";
import UserVisaTicket from "../../components/Bookingreports/UserTickets/UserVisaTicket.jsx";



import visaOfflineSuccess from "../../components/TicketSuccessPages/VisaTicketSucess/visaOfflineSuccess";
import HolidayOfflineSuccess from "../../components/TicketSuccessPages/HolidayTicketSuccess/HolidayOfflineSuccess";

import {
  HotelOfflineGourpBookingRequest,
  HotelOfflineRequest,
} from "../../components/HotelOfflineRequest/HotelOfflineRequest";
import VisaTicketScreen from "../AllTickets/Visa/VisaTicketScreen.jsx";

import CashFreeNewVersionCheckout from "../../helpers/CashFreeNewVersionpayCheckout";

const NavigationItems = () => {
  return (
    <div className="mob-M-Top">
      <Switch>
        <Route exact path={"/"} component={Home} />
        <Route exact path={"/flight/results"} component={SearchResults} />

        <AgentProtectedRoute
          routeKey="2"
          exact
          path={"/hotels"}
          component={HotelSearch}
        />
        <Route exact path={"/hotels/listing"} component={HotelResults} />

        <Route exact path={"/hotel-location"} component={Map} />

        <Route exact path={"/hotels/hotel-details"} component={HotelDet} />
        <Route
          exact
          path={"/hotels/hotel-checkout"}
          component={HotelCheckout}
        />
        <Route exact path={"/hotels/hotel-review"} component={HotelPreview} />
        <Route exact path={"/hotel-invoice"} component={HotelInvoice} />
        <Route exact path={"/hotel/ticket"} component={UserHotelTicket} />
        <Route exact path={"/agenthomepage"} component={Agenthomepage} />
       
        
       
        <Route exact path={"/visasearch"} component={VisaSearch} />
        <Route exact path={"/dubaivisa"} component={Dubaivisa} />
        <Route
          exact
          path={"/travellersvisadetails"}
          component={Travellersvisadetails}
        />

        

       
        <Route exact path={"/user-registration"} component={UserRegistration} />
        
        <Route
          exact
          path={"/agent-registration"}
          component={AgentRegistration}
        />
        <Route exact path={"/login"} component={Login} />
        <Route exact path={"/flight/checkout"} component={FlightCheckout} />
        <Route exact path={"/flight/review"} component={FlightReview} />
        
        
        <Route exact path={"/deals"} component={Deals} />
        {/* <Route exact path={"/dealsimages"} component={DealsImages} /> */}
        <Route exact path={"/deals-details"} component={DealsDetails} />
        <Route exact path={"/ticketcancel"} component={TicketCancel} />
        <Route exact path={"/printticket"} component={PrintTicket} />
        <Route exact path={"/ourteam"} component={OurTeam} />
        <ProtectedRoute
          exact
          path={"/store-vistors"}
          component={Storevistors}
        />
        <ProtectedRoute exact path={"/myprofile"} component={MyProfile} />
        <ProtectedRoute exact path={"/statements"} component={Statement} />
        <ProtectedRoute exact path={"/margins"} component={Discounts} />
        <ProtectedRoute exact path={"/bank-details"} component={BankDetails} />
        <Route path={"/Travelitinerary"} component={Travelitinerary} />
        <ProtectedRoute path={"/deposits"} component={Deposits} />
        <Route path={"/agent-logo"} component={AgentLogo} />
        <ProtectedRoute path={"/agent-markup"} component={Agentmarkup} />
        <ProtectedRoute
          path={"/commission-details"}
          component={Commissiondetails}
        />
        <ProtectedRoute
          exact
          path={"/booking-reports"}
          component={Bookingreports}
        />
        <Route exact path={"/testimonials"} component={Testimonials} />
        <Route exact path={"/faqs"} component={Faqs} />
        <Route exact path={"/mytrips"} component={Mytrips} />
        <Route
          exact
          path={"/termsofconditions"}
          component={Termsofconditions}
        />
        <Route exact path={"/privacypolicy"} component={Privacypolicy} />
        <Route exact path={"/disclaimer"} component={Disclaimer} />
        <Route exact path={"/careers"} component={Careers} />
        <Route
          exact
          path={"/Socialresponsibility"}
          component={Socialresponsibility}
        />
        <Route exact path={"/checkflightinfo"} component={Checkflightinfo} />
        <Route exact path={"/Refundpolicy"} component={Refundpolicy} />
        <Route exact path={"/Thankyou"} component={Thankyou} />
        <Route exact path={"/Travelrequests"} component={Travelrequests} />
        <Route
          exact
          path={"/Investorrelations"}
          component={Investorrelations}
        />
        <Route exact path={"/offers"} component={Offers} />
        <ProtectedRoute
          exact
          path={"/traveller-details"}
          component={TravellerDetails}
        />
        <ProtectedRoute
          exact
          path={"/change-password"}
          component={ChangePassword}
        />
        <Route exact path={"/booking-list"} component={HotelBookingList} />
        <Route exact path={"/combinedbooking"} component={CombinedBooking} />

        <ProtectedRoute exact path={"/profile"} component={Profile} />

        <Route exact path={"/404"} component={Error404} />
        <Route exact path={"/pg_test"} component={PgrequestTest} />
        <Route exact path={"/flight/ticket"} component={UserFlightTicket} />
        
        
        {/* <Route exact path={"/cruise/ticket"} component={CruiseTicketScreen} /> */}
        <Route
          exact
          path={"/buildpackage/ticket"}
          component={UserBuildPackageTicket}
        />

        
        <ProtectedRoute exact path={"/mypackage"} component={MyPackage} />
        <ProtectedRoute
          exact
          path={"/coupon-wallet"}
          component={CouponWallet}
        />
        <ProtectedRoute
          exact
          path={"/transaction-reports"}
          component={TransactionReports}
        />
        <ProtectedRoute exact path={"/wallet"} component={Wallet} />
        <Route exact path={"/contact_us_form"} component={ContactUsForm} />
        <Route exact path={"/flightGroupEnquiry"} component={FlightEnquiry} />
        <Route exact path={"/paymentFailure"} component={PaymentFailure} />
        <Route exact path={"/pg_failure"} component={PaymentFailure} />
        <Route exact path={"/pg_success"} component={PaymentSucces} />
        <Route exact path={"/about-us"} component={AboutUS} />

       

       

              
        <Route exact path={"/combinedbuildpkg"} component={Combinedbuildpkg} />

       
       
        <Route exact path="/unauthorized" component={Unauthorized} />
       
        <Route exact path={"/web-check-in"} component={Webcheckin} />
       
        <Route exact path={"/car-hire"} component={CarHire} />
        <Route exact path={"/visa"} component={Visa} />
       
        {/*Cars*/}
        <Route exact path={"/cars"} component={CarsSearch} />
        <Route exact path={"/cars/result"} component={CarsResults} />
        <Route exact path={"/transfer/result"} component={TransferResults} />
        <Route exact path={"/cars/checkout"} component={CarsCheckout} />
        <Route exact path={"/cars/review"} component={CarReview} />
        <Route exact path={"/cars/ticket"} component={UserCarTicket} />
        <Route exact path={"/cartransfer/ticket"} component={UserCarTransferTicket} />

        {/*Holidays*/}
        
        
       
       
        <Route
          exact
          path={"/holiday/offline"}
          component={HolidayOfflineSuccess}
        />
        {/* Visa Pages */}
        <Route exact path={"/visa/result"} component={VisaResults} />
        <Route exact path={"/visa/visaDetails"} component={VisaDetails} />
        <Route exact path={"/visa/checkout"} component={VisaCheckout} />
        <Route exact path={"/visa/visaReview"} component={VisaReview} />
        <Route exact path={"/visa/visaGuidelines"} component={VisaGuide} />
        <Route
          exact
          path={"/visa/offline"}
          component={visaOfflineSuccess}
        />
        <Route exact path={"/visa/ticket"} component={UserVisaTicket} />
        {/* Charter Flifhts */}
        {/* <Route exact path={"/charterFlights"} component={CharterFlightHome} />
        <Route
          exact
          path={"/charterFlights/results"}
          component={CharterFlightResults}
        /> */}
        {/* <Route
          exact
          path={"/charterFlights/details"}
          component={CharterFlightDetail}
        /> */}
       
        {/* <Route
          exact
          path={"/charterFlights/review"}
          component={CharterFlightReview}
        /> */}
        {/*Cruise*/}
        {/* <Route exact path={"/cruise"} component={CruiseSearch} />
        <Route exact path={"/cruise/results"} component={CruiseResults} />
        <Route exact path={"/cruise/detail"} component={CruiseTourDetail} />
        <Route exact path={"/cruise/checkout"} component={CruiseTourCheckOut} />
        <Route exact path={"/cruise/review"} component={CruiseReview} />
        <Route
          exact
          path={"/cruise/offline"}
          component={CruiseOfflineSuccess}
        /> */}
        {/* <Route exact path={"/r&b"} component={RechargeBill} />
        <Route exact path={"/r&b-orders"} component={RBReports} />
        <Route exact path={"/check-status"} component={CheckStatus} />
        <Route exact path={"/cashFree"} component={CashFreeNewVersionCheckout} /> */}

        {/* Hotel Offline */}

        {/* <Route
          exact
          path={"/hotelOfflineRequest"}
          component={HotelOfflineRequest}
        />
        <Route
          exact
          path={"/hotelGroupEnquiry"}
          component={HotelOfflineGourpBookingRequest}
        />
        <Route
          exact
          path={"/charterflight/ticket"}
          component={CharterFlightTicketScreen}
        /> */}

        <Route exact path="/error" component={ErrorScreen} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};
export default NavigationItems;
