import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Table,
  TimePicker,
} from "antd";

import ReactQuill from "react-quill";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import ApiClient from "../../helpers/ApiClient";

import modules from "../../helpers/reactQuillModules";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
import search from "../assets/vector-icons/search.png";
import "rc-datepicker/lib/style.css";
import "./Carsavailability.scss";


const Carsavailability = () => {
  const { Content } = Layout;
  const { Option } = Select;
  const [form] = Form.useForm();
  const { Panel } = Collapse;

  const [modalVisible, setModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [cities, setCities] = useState([]);
  const [driver, setDriver] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [formLayout, setFormLayout] = useState("vertical");
  const [formData, setFormData] = useState({});
  const [carType, setCarType] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [travelType, setTravelType] = useState(2);

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const handleAddClick = () => {
    form.resetFields();
    showModal();
    setIsEditMode(false);
  };

  const getCities = (skip = 1, take = 42000) => {
    ApiClient.get("carextranet/getAllCity" + "?skip=" + skip + "&take=" + take)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;

          setCities(data);
        } else {
        }
      })
      .catch((error) => {console.log(error)});
  };

  const getDriver = () => {
    ApiClient.get("carextranet/getallcardriverinfo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          setDriver(data);
        } else {
        }
      })
      .catch((error) => {});
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const getAllCarAvailability = () => {
    ApiClient.get("carextranet/getallcaravailability").then((res) => {
      if (res.statusCode === 200) {
        setDataSource(res.data);
      }
    });
  };

  const getVendor = () => {
    ApiClient.get("carextranet/getAllVendor")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          setVendor(data);
        } else {
        }
      })
      .catch((error) => {});
  };

  const getCarType = () => {
    ApiClient.get("carextranet/getallcartype")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data;
          setCarType(data);
        } else {
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getAllCarAvailability();
    getCities();
    getDriver();
    getVendor();
    getCarType();
  }, []);

  const handleSubmit = (values) => {
    let formvalues = {
      ...values,
      FromDate: moment(values.FromDate).format("YYYY-MM-DD HH:mm:ss"),
      ToDate: moment(values.ToDate).format("YYYY-MM-DD HH:mm:ss"),
      Source: values.Source.split("~")[0],
      SourceID: values.Source.split("~")[1],
      Destination:
        values.TravelType === 1 || values.TravelType === 3
          ? ""
          : values.Destination
          ? values.Destination.split("~")[0]
          : "",
      DestinationID:
        values.TravelType === 1 || values.TravelType === 3
          ? ""
          : values.Destination
          ? values.Destination.split("~")[1]
          : "",
    };
    if (formvalues.Destination === "") {
      delete formvalues.Destination;
      delete formvalues.DestinationID;
    }
    getCities();
    if (isEditMode) {
      submitUpdateCarsAvailability(formvalues);
    } else {
      submitNewCarsAvailability(formvalues);
    }
  };

  const submitUpdateCarsAvailability = (values) => {
    const updatedFormData = {
      ...values,
      FromDate: moment(values.FromDate).format("YYYY-MM-DD HH:mm:ss"),
      ToDate: moment(values.ToDate).format("YYYY-MM-DD HH:mm:ss"),
      StartTime: moment(values.StartTime),
      EndTime: moment(values.EndTime),
      NightChargesStarttime: moment(values.NightChargesStarttime).format(
        "HH:mm"
      ),
      NightChargesEndtime: moment(values.NightChargesEndtime).format("HH:mm"),
      Source: values.Source ?? "",
      SourceID: values.SourceID ?? "",
      Destination: values.Destination ?? "",
      DestinationID: values.DestinationID ?? "",
    };
    if (updatedFormData.Destination === "") {
      delete updatedFormData.Destination;
      delete updatedFormData.DestinationID;
    }
    ApiClient.put(
      "carextranet/updatecaravailability/" + formData.AvailabilityID,
      {},
      updatedFormData
    ).then((response) => {
      if (response.status === 200) {
        setModalVisible(false);
        message.success("CarsAvailability updated successfully");
        getAllCarAvailability();
      } else {
        message.error(" Error Updating CarsAvailability ");
      }
    });
  };

  const handleEditClick = (rec) => {
    setTravelType(rec.TravelType);

    form.setFieldsValue({
      ...rec,
      DriverID: rec.DriverID,
      TravelType: rec.TravelType,
      TripType: rec.TripType,
      Source: `${rec.Source}~${rec.SourceID}`,
      Destination: `${rec.Destination}~${rec.DestinationID}`,
      CarTypeID: rec.CarTypeID,
      IncludedKMs: Number(rec.IncludedKMs),
      AvailabilityID: rec.AvailabilityID,
      FromDate: moment(rec.FromDate),
      PickUpLocation: rec.PickUpLocation ?? "",
      DroppingLocation: rec.DroppingLocation ?? "",
      ToDate: moment(rec.ToDate),
      StartTime: moment(rec.StartTime, "HH:mm"),
      EndTime: moment(rec.EndTime, "HH:mm"),
      VendorID: rec.VendorID,
      NightChargesEndtime: rec.NightChargesEndtime
        ? moment(rec.NightChargesEndtime, " HH:mm")
        : "",
      NightChargesStarttime: rec.NightChargesStarttime
        ? moment(rec.NightChargesStarttime, "HH:mm")
        : "",
    });

    setFormData({
      ...rec,
      AvailabilityID: rec.AvailabilityID,
    });

    setIsEditMode(true);
    //  console.log(
    //   `${rec.Source}~${rec.SourceID}`,
    //   `${rec.Destination}~${rec.DestinationID}`
    // );

    showModal();
  };

  const submitNewCarsAvailability = (formvalues) => {
    ApiClient.post("carextranet/createcaravailability", formvalues).then(
      (response) => {
        if (response.status === 200) {
          setModalVisible(false);
          message.success("CarsAvailability created successfully");
          getAllCarAvailability();
        } else {
          message.error(" Error Creating CarsAvailability ");
        }
      }
    );
  };

  const deleteAvailability = (id) => {
    ApiClient.delete("carextranet/deletecaravailability/" + id).then((res) => {
      if (res.status === 200) {
        message.success("CarsAvailability Deleted  successfully", 3);
        getAllCarAvailability();
      }
    });
  };

  const addGst = (value) => {
    var gstPrice = Number(value) * 0.18;
    form.setFieldsValue({ GST: gstPrice });
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => handleEditClick(rec)}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteAvailability(rec.AvailabilityID)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const changeTripTtype = (value) => {
    if (value === 1) {
      setTravelType(value);
    }
    if (value === 2) {
      setTravelType(value);
    }
    if (value === 3) {
      setTravelType(value);
    }
  };

  const getTime = (time) => {
    switch (time) {
      case 0:
        return "1AM";
      case 1:
        return "2AM";

      case 2:
        return "11AM";

      case 3:
        return "10AM";

      case 4:
        return "3PM";

      case 5:
        return "9AM";

      default:
        return "";
    }
  };

  const columns = [
    {
      title: "Travel Type",
      dataIndex:"TravelType",
      render: (text, record) => {
        return <div>{text === 1 ? "Local" : "Out-station"}</div>;
      },
    },
    {
      title: "Trip Type",
      dataIndex:"TripType",
      render: (text, record) => {
        return <div>{text === 1 ? "One-way" : "Round-Trip"}</div>;
      },

    },
    {
      title: "Car Type",
      dataIndex: "carType",
      render: (text, record) => {
        return <div>{text?.CarType}</div>;
      },
    },
    {
      title: "Source",
      dataIndex: "Source",
    },


    {
      title: "Destination",
      dataIndex: "Destination",
    },

    {
      title: "PickUp Location",
      dataIndex: "PickUpLocation",
      render: (text, record) => {
        return <div>{text.split(',')[0]}</div>;
      },
    },

    {
      title: "Dropping Location",
      dataIndex: "DroppingLocation",
      render: (text, record) => {
        return <div>{text.split(',')[0]}</div>;
      },
    },
    {
      title: "Valid From date time",
      dataIndex: "FromDate",
      render: (text, record) => {
        return <div>{moment(text).format("DD, MMM-YYYY")}</div>;
      },
    },
    {
      title: "Valid to date time	",
      dataIndex: "ToDate",
      render: (text, record) => {
        return <div>{moment(text).format("DD, MMM-YYYY")}</div>;
      },
    },

    {
      title: "Actions",

      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];

  // const prefixSelector = (
  //   <Form.Item name="prefix" noStyle>
  //     <Select style={{ width: 80 }} placeholder="+91">
  //       <Option value="86">+86</Option>
  //       <Option value="87">+87</Option>
  //     </Select>
  //   </Form.Item>
  // );

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Cars Availability{" "}
                          <HelpInfoHelper
                            screenName={"/admin/carsavailability"}
                          />
                        </h5>
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />
                      <img src={excel} alt="excel" />
                      <img src={pdf} alt="pdf" />
                      <p className="add-deposit-icon" onClick={handleAddClick}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>

                  <div>
                    <Table
                      className="table-scroll-none"
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title="Availability"
        className="promo-modal-header modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <Form
          form={form}
          layout={formLayout}
          initialValues={{ layout: formLayout }}
          name="basic"
          // initialValues={{ remember: true }}
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <h5>Cars Availability</h5>
            </Col>
          </Row>

          <Collapse accordion>
            <Panel header="Cars Availability" key="1">
              <Row gutter={16}>
                <Col md={6} xs={24}>
                  <Form.Item
                    name="TravelType"
                    label="Travel Type"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter your Travel Type",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please select"
                      onChange={(e) => {
                        changeTripTtype(e);
                      }}
                    >
                      <Option value={1}>Local</Option>
                      <Option value={2}>Outstation</Option>
                      {/* <Option value={3}>Transfer</Option> */}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item
                    name="TripType"
                    label="Trip Type"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter your Trip Type",
                      },
                    ]}
                  >
                    {travelType === 2 ? (
                      <Select placeholder="Please select Trip Type">
                        <Option value={1}>One Way</Option>
                        <Option value={2}>Round Trip</Option>
                      </Select>
                    ) : travelType === 1 ? (
                      <Select placeholder="Please select Trip Type">
                        <Option value={3}>4 hours/Day </Option>
                        <Option value={4}>8 hours/Day </Option>
                        <Option value={5}>12 hours/Day </Option>
                        <Option value={6}>24 hours/Day </Option>
                      </Select>
                    ) : (
                      <Select placeholder="Please select Trip Type">
                        <Option value={7}>Railway Station</Option>
                        <Option value={8}>AirPort</Option>
                        <Option value={9}>Hotel/City</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item
                    label="Source"
                    rules={[
                      { required: true, message: "Please Select Source" },
                    ]}
                    name="Source"
                  >
                    <Select placeholder="Please select" showSearch>
                      {cities.length > 0 ? (
                        cities.map((item) => (
                          <Option
                            key={"src" + item.Id}
                            value={`${item.Name}~${item.Id}`}
                          >
                            {item.Name}
                          </Option>
                        ))
                      ) : (
                        <Option value={0}>No Cities</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>

                {travelType === 2 && (
                  <Col md={6} xs={24}>
                    <Form.Item
                      label="Destination"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Destination",
                        },
                      ]}
                      name="Destination"
                    >
                      <Select placeholder="Please select" showSearch>
                        {cities.length > 0 ? (
                          cities.map((item) => (
                            <Option
                              key={"dest" + item.Id}
                              value={`${item.Name}~${item.Id}`}
                            >
                              {item.Name}
                            </Option>
                          ))
                        ) : (
                          <Option value={0}>No Cities</Option>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                )}

                <Col md={6} xs={24}>
                  <Form.Item
                    name="PickUpLocation"
                    label="PickUp Location"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Please input your Pick Up Location",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    name="DroppingLocation"
                    label="Dropping Location"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Please input your Dropping Location",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    label="From Date"
                    name="FromDate"
                    rules={[
                      {
                        required: true,
                        message: "Please select Your From Date",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="Select From date"
                    />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    label="To Date"
                    name="ToDate"
                    rules={[
                      {
                        required: true,
                        message: "Please select Your To Date",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      placeholder="Select To date"
                    />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    label="Start Time"
                    rules={[
                      {
                        required: true,
                        message: "Please select Your Start Time",
                      },
                    ]}
                    name="StartTime"
                  >
                    {/* <Select placeholder="Please select">
                      <Option value={1}>1AM</Option>
                      <Option value={2}>2AM</Option>
                      <Option value={3}>11PM</Option>
                      <Option value={4}>12PM</Option>
                    </Select> */}

                    <TimePicker
                      style={{ width: "100%", height: "100%" }}
                      className="search-inputs from-return-date"
                      showSearch
                      placeholder="Start Time"
                      format={"HH:mm"}
                    />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    label="End Time"
                    rules={[
                      {
                        required: true,
                        message: "Please select Your End Time",
                      },
                    ]}
                    name="EndTime"
                  >
                    <TimePicker
                      style={{ width: "100%", height: "100%" }}
                      className="search-inputs from-return-date"
                      showSearch
                      placeholder="Start Time"
                      format={"HH:mm"}
                    />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    label="Vendor Name"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please Enter Your Vendor Name",
                    //   },
                    // ]}
                    name="VendorID"
                  >
                     <Input />
                    {/* <Select placeholder="Please select">
                      {vendor.length > 0
                        ? vendor.map((item) => (
                            <Option
                              key={"vend" + item.VendorID}
                              value={item.VendorID}
                            >
                              {item.CompanyName}
                            </Option>
                          ))
                        : null}
                    </Select> */}
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    label="Cars Type"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Your Car Type ",
                      },
                    ]}
                    name="CarTypeID"
                  >
                    <Select placeholder="Please select">
                      {carType.length > 0 ? (
                        carType.map((item) => (
                          <Option
                            key={"carType" + item.CarTypeID}
                            value={item.CarTypeID}
                          >
                            {item.CarType}
                          </Option>
                        ))
                      ) : (
                        <Option value={0}>No Model</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    label="Driver Name"
                    rules={[
                      { required: false, message: "Please Select Driver Name" },
                    ]}
                    name="DriverID"
                  >
                    <Select placeholder="Please select" showSearch>
                      {driver.length > 0
                        ? driver.map((item) => (
                            <Option
                              key={"Driver" + item.DriverID}
                              value={item.DriverID}
                            >
                              {item.Name}
                            </Option>
                          ))
                        : null}
                    </Select>
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    name="DisplayInfoForUser"
                    label="Display Info for User"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Please Enter Display Info for User",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    name="BaseFare"
                    label="Base Fare"
                    rules={[
                      {
                        pattern: /^[0-9]*$/,
                        required: true,
                        message: "Please input your Base Fare",
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        addGst(e.target.value);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item
                    name="Vehicle_FuelCharges"
                    label="Vehicle & Fuel Charges"
                    rules={[
                      {
                        pattern: /^[0-9]*$/,
                        required: true,
                        message: "Please enter your Vehicle_FuelCharges",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item label="Included KMs" name="IncludedKMs">
                    <Input
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Included KMs",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    name="GST"
                    label="GST (5% On Base Fare)"
                    rules={[
                      {
                        required: false,
                        message: "Please Enter your GST",
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    name="StateTollTax"
                    label="State, Toll Tax"
                    // rules={[
                    //   {
                    //     pattern: /^[0-9]*$/,
                    //     required: true,
                    //     message: "Please Enter your StateTollTax",
                    //   },
                    // ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                {travelType === 3 && (
                  <Col md={6} xs={24}>
                    <Form.Item
                      name="Parking_AirportEntryFees"
                      label="Parking & Airport EntryFees"
                      // rules={[
                      //   {
                      //     pattern: /^[0-9]*$/,
                      //     required: true,
                      //     message:
                      //       "Please Enter your Parking & Airport EntryFees",
                      //   },
                      // ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                )}

                <Col md={6} xs={24}>
                  <Form.Item
                    name="ParkingCharges"
                    label="Parking Charges"
                    // rules={[
                    //   {
                    //     pattern: /^[0-9]*$/,
                    //     required: true,
                    //     message: "Please Enter your Parking Charges",
                    //   },
                    // ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    name="StateBorderCharges"
                    label="State Border Charges"
                    // rules={[
                    //   {
                    //     pattern: /^[0-9]*$/,
                    //     required: true,
                    //     message: "Please Enter State Border Charges",
                    //   },
                    // ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    name="DriverAllowance"
                    label="Driver Allowance"
                    rules={[
                      {
                        pattern: /^[0-9]*$/,
                        required: true,
                        message: "Please Enter Driver Allowance",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    name="ChargePerKM"
                    label="ChargePerKM"
                    rules={[
                      {
                        pattern: /^[0-9]*$/,
                        required: true,
                        message: "Please Enter your ChargePerKM",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    name="ChargePerExtraKM"
                    label="Charge Per Extra KM"
                    rules={[
                      {
                        pattern: /^[0-9]*$/,
                        required: true,
                        message: "Please Enter your Charge Per ExtraKM",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    name="EarlyPickupCharges"
                    label="Early Pickup Charges"
                    rules={[
                      {
                        pattern: /^[0-9]*$/,
                        required: true,
                        message: "Please Enter your Early Pickup Charges",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                  <Form.Item
                    name="EarlyReturnCharges"
                    label="Early Return Charges"
                    rules={[
                      {
                        pattern: /^[0-9]*$/,
                        required: true,
                        message: "Please Enter your Early Return Charges",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    name="LatePickupCharges"
                    label="Late Pickup Charges"
                    rules={[
                      {
                        pattern: /^[0-9]*$/,
                        required: true,
                        message: "Please Enter your Late Pickup Charges",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    name="LateReturnCharges"
                    label="Late Return Charges"
                    rules={[
                      {
                        pattern: /^[0-9]*$/,
                        required: true,
                        message: "Please Enter your Late Return Charges",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    name="NightCharges"
                    label="NightCharges"
                    rules={[
                      {
                        pattern: /^[0-9]*$/,
                        required: true,
                        message: "Please Enter your NightCharges",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    label="Night Charges Start time"
                    name="NightChargesStarttime"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter your Night Charges Start time",
                      },
                    ]}
                  >
                    <TimePicker
                      style={{ width: "100%", height: "100%" }}
                      className="search-inputs from-return-date"
                      showSearch
                      placeholder="Start Time"
                      format={"HH:mm"}
                    />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item
                    label="Night Charges End time"
                    name="NightChargesEndtime"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter your Night Charges End time",
                      },
                    ]}
                  >
                    <TimePicker
                      style={{ width: "100%", height: "100%" }}
                      className="search-inputs from-return-date"
                      showSearch
                      placeholder="Start Time"
                      format={"HH:mm"}
                    />
                  </Form.Item>
                </Col>

                <Col md={6} xs={24}>
                  <Form.Item label="Status" name="Status">
                    <Radio.Group
                      rules={[
                        { required: true, message: "Please choose Status!" },
                      ]}
                    >
                      <Radio value={1}>Active</Radio>
                      <Radio value={2}>Inactive</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>

          {/* first--collese--close */}

          <Row gutter={16} className="cars-new-modal">
            <Col md={24} xs={24}>
              <h5>Details</h5>
            </Col>
          </Row>

          <Row gutter={16} className="last-cars-btm">
            <Col md={24} xs={24}>
              <Collapse accordion>
                <Panel header="Cancellation Policy" key="1">
                  <p>Description</p>

                  <Form.Item
                    name="CancelPolicy"
                    rules={[{ required: true, message: "Please CancelPolicy" }]}
                  >
                    <ReactQuill
                      modules={modules}
                      placeholder="please input ..."
                    />
                  </Form.Item>
                </Panel>

                {/* panel--one--close */}

                <Panel header="Car Pickup Procedure (Number Of Points)" key="2">
                  <p>Description</p>

                  <Form.Item name="CarPickupProcedure">
                    <ReactQuill
                      modules={modules}
                      placeholder="please input ..."
                    />
                  </Form.Item>
                </Panel>

                {/* panel--two--close */}

                <Panel header="How to Inspect the Car" key="3">
                  <Form.Item name="HowToInspectTheCar">
                    <ReactQuill
                      modules={modules}
                      placeholder="please input ..."
                    />
                  </Form.Item>
                </Panel>

                {/* panel--three--close */}

                <Panel header="Terms And Conditions" key="4">
                  <Form.Item name="TermsAndConditions">
                    <ReactQuill
                      modules={modules}
                      placeholder="please input ..."
                    />
                  </Form.Item>
                </Panel>

                {/* panel--four--close */}

                <Panel header="Required Documents for pick up" key="5">
                  <Form.Item name="RequiredDocumentsForPickup">
                    <ReactQuill
                      modules={modules}
                      placeholder="please input ..."
                    />
                  </Form.Item>
                </Panel>
              </Collapse>
            </Col>
          </Row>

          <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit">
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button type="primary" danger className="cancel-btn">
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Carsavailability;
