import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  message,
  Radio,
  Row,
  Select,
  InputNumber,
  TimePicker,
  Skeleton,
} from "antd";

import moment from "moment";
import queryString from "query-string";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import CarAutoSelect from "../../common/AutoCompleteSelect/CarAutoSelect";
import TransferAutoCompleteSelect from "../../common/AutoCompleteSelect/CarTransferAutoSelect";
import { GlobalStatesContext } from "../../common/providers";
import ApiClient from "../../helpers/ApiClient";
import { OriDesValidate } from "../../helpers/CustomValidators";
import "./Car.scss";

const { Option } = Select;

const Cars = ({ modifySearch = false, carSearchAPI }) => {
  const {
    state: {
      carPersist: { result },
      carSearchObj,
    },
    updateCarSearchResultObj,
    updateCarSearchObj,
  } = useContext(GlobalStatesContext);

  const departureDate = moment();
  const [arrivalDate, setArrivalDate] = useState(moment());

  const [toDisableDate, setToDisableDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(true);
  const onChangeFromDate = (momentdate, _) => {
    setToDisableDate(momentdate);
  };

  const city = useRef(null);
  const searchBtn = useRef();

  let history = useHistory();
  const tocityBox = useRef(null);
  const dropLocation = useRef(null);
  const pickUpLocation = useRef(null);
  const pickUppoint = useRef(null);
  const fromDate = useRef(null);
  const toDateBox = useRef(null);

  const dateFormat = "YYYY-MM-DD";
  const [form] = Form.useForm();

  const validateMessages = {
    required: "",
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment(departureDate).startOf("day");
  };

  const disabledDestDate = (currentDate) => {
    return currentDate < moment(arrivalDate).startOf("day");
  };

  const [showFromDate, setShowFromDate] = useState(false);
  const [showToDate, setShowToDate] = useState(false);
  const [dataFlip, setDataFlip] = useState(false);
  const [travelType, setTravelType] = useState(3);
  const [tripType, setTripType] = useState(3);
  const [tripAddress, setTripAddress] = useState();
  const format = "HH:mm";
 
  const [pickUpAddresses, setPickUpAdresses] = useState([]);
  const [dropAddresses, setDropAdresses] = useState([]);
 
  const [pickupTransferType, setPickUpTransferType] = useState(null);
  const [dropoffTransferType, setDropOffTransferType] = useState(null);

  const handleOnSubmit = (ref) => {
    ref.current.focus();
    if (ref === fromDate) {
      setShowFromDate(true);
    } else if (ref === toDateBox) {
      setShowToDate(true);
    }
  };

  const submitForm = (values) => {
   
    if (travelType !== 3) {
      let source = queryString.parse(values.sourceId);

      let destination = queryString.parse(values.destinationId);
      let pickUpLocation = values.pickUpLocation;
      let dropLocation = values.dropLocation;

      if (source.cityId === destination.cityId) {
        message.error("Origin and Destination cannot be same", 3);
        return false;
      }

      let formData = {
        sourceID: source.cityId ?? "",
        destinationID: destination.cityId ?? "",
        fromDate: values.fromDate
          ? moment(values.fromDate, "YYYY-MM-DD").format("YYYY-MM-DD")
          : "",
        toDate: values.toDate ? moment(values.toDate).format("YYYY-MM-DD") : "",
        sourceName: source.cityName ?? "",
        destinationName: destination.cityName ?? "",
        pickUpLocation: pickUpLocation ? pickUpLocation : "",
        dropLocation: dropLocation ? dropLocation : "",
        TravelType: travelType ?? "",
        TripType: tripType ?? "",
      };
      updateCarSearchResultObj(formData);
      updateCarSearchObj(formData);
      const query = queryString.stringify(formData);
      history.push("/cars/result?" + query);
      if (modifySearch) {
        carSearchAPI();
      }
    } else {
    
      if (values.pickUpLocation === values.dropLocation) {
        message.error("Origin and Destination cannot be same", 3);
        return false;
      }
      let requestData = {
        TransferTime: moment(values.TransferTime).format("HHMM"),
        TransferDate: moment(values.fromDate, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        ),
        AdultCount: 1,
        ChildCount: 0,
        ChildAge: [],
        PreferredLanguage: 4,
        AlternateLanguage: 5,
        PreferredCurrency: "INR",
        PickUpCode:
          pickupTransferType === 4
            ? 0
            : pickupTransferType == 1
              ? 1
              : pickupTransferType == 2
                ? 3
                : pickupTransferType == 3
                  ? 2
                  : 4,
        PickUpPointCode: values.pickUpLocation,
        CityId: result?.DestinationId,
        DropOffCode:
          dropoffTransferType === 4
            ? 0
            : dropoffTransferType == 1
              ? 1
              : dropoffTransferType == 2
                ? 3
                : dropoffTransferType == 3
                  ? 2
                  : 4,
        DropOffPointCode: values.dropLocation,
        CountryCode: result?.CountryCode.trim(),
        roleType: 4,
        TravelType: travelType,
        cityName: result?.CityName,
        dropoffTransferType: dropoffTransferType,
        pickupTransferType: pickupTransferType,
      };

      updateCarSearchObj(requestData);
      updateCarSearchObj(requestData);
      localStorage.setItem("CarSearch", JSON.stringify(requestData));
      const query = queryString.stringify(requestData);

      history.push("/transfer/result?" + query);
      if (modifySearch) {
        window.location.reload();
        carSearchAPI();
      }
    }

  };
  useEffect(() => {
    if (modifySearch) {
      let param = queryString.parse(document.location.search);
      setTravelType(Number(param.TravelType));
      setTripType(Number(param.TripType));

      form.setFieldsValue({
        sourceId: `cityName=${param.sourceName}&cityId=${param.sourceID}`,
        destinationId: `cityName=${param.destinationName}&cityId=${param.destinationID}`,
        fromDate: moment(param.fromDate, "YYYY-MM-DD"),
        toDate: param.toDate ? moment(param.toDate, "YYYY-MM-DD") : "",
        // pickUpLocation: param.pickUpLocation,
        // dropLocation: param.dropLocation,
      });

      if (param.TravelType == 3) {
        GetPickUpAddresses(param?.pickupTransferType);
        GetDropOffAddresses(param?.dropoffTransferType);
        setPickUpTransferType(param?.pickupTransferType);
        setDropOffTransferType(param?.dropoffTransferType);
        form.setFieldsValue({
          sourceId: param.cityName,

          fromDate: moment(param.TransferDate, "YYYY-MM-DD"),
          TransferTime: moment(param.TransferTime, "HHMM"),

          pickUpLocation:
            param?.pickupTransferType == 1
              ? param.PickUpPointCode
              : Number(param.PickUpPointCode),
          dropLocation:
            param?.pickupTransferType == 1
              ? param.DropOffPointCode
              : Number(param.DropOffPointCode),
        });
      }
    }

   
  }, []);

  const GetPickUpAddresses = (transferCatType) => {
    setPickUpAdresses([]);
    let reqObj = {
      CityId: result?.DestinationId,
      TransferCategoryType: transferCatType,
    };

    ApiClient.post("transfers/GetTransferStaticData", reqObj)
      .then((res) => {
        if (res.statusCode === 200) {
          setPickUpAdresses(res.data);
        }
      })
      .catch((e) => {
        setPickUpAdresses([]);
      });
  };

 
  const GetDropOffAddresses = (transferCatType) => {
    let reqObj = {
      CityId: result?.DestinationId,
      TransferCategoryType: transferCatType,
    };
    setDropAdresses([]);

    ApiClient.post("transfers/GetTransferStaticData", reqObj)
      .then((res) => {
        if (res.statusCode === 200) {
          setDropAdresses(res.data);
        }
      })
      .catch((e) => {
        setDropAdresses([]);
      });
  };

  const handleSwap = () => {
    const sourceId = form.getFieldValue("sourceId");
    const destinationId = form.getFieldValue("destinationId");

    if (sourceId && destinationId) {
      form.setFieldsValue({
        sourceId: destinationId,
        destinationId: sourceId,
      });
      setDataFlip((prev) => !prev);
    }
  };
  const changeTravelType = (type) => {
    if (type === 1) {
      setTripType(3);
    } else if (type === 2) {
      setTripType(2);
    } else {
      setTripType(7);
    }
    setTravelType(type);
  };
  const onchangeTripType = (value) => {
    setTripType(value);
  };
  const onChangeAddress = (value) => {
    setTripAddress(value);
  };
  const [date, setDate] = useState(new Date());

  const onChange = (newDate) => {
    setDate(newDate);
  };
  const onChangeOriginDate = (momentdate, _) => {
    let originDate = momentdate ? moment(momentdate).startOf("day") : "";
    setArrivalDate(momentdate);
    const toDate = form.getFieldValue("toDate");
    if ((tripType === 2 || travelType === 3) && toDate) {
      let a = moment(toDate).startOf("day");
      let diffDays = a.diff(originDate, "days");
      if (diffDays < 0) {
        let newTodate = moment(momentdate).add(2, "days");
        form.setFieldsValue({
          toDate: newTodate,
        });
      }
    }
  };

  return (
    <>
      <div className="bus-search-wrapper car-taget-img" >
        <Form
          form={form}
          className="bus-panel-bg"
          name="carForm"
          validateMessages={validateMessages}
          onFinish={submitForm}
        >
          <Row className="car-search-option">
            <Col md={3} sm={24} xs={8}>
              <div>
                <Button
                  className={`car-search-option-button ${travelType == 1 ? "activeBtn" : ""
                    }`}
                  onClick={() => {
                    changeTravelType(1);
                    form.resetFields();
                  }}
                >
                  Local
                </Button>
              </div>
            </Col>
            <Col md={3} sm={24} xs={8}>
              <div>
                <Button
                  className={`car-search-option-button ${travelType == 2 ? "activeBtn" : ""
                    }`}
                  onClick={() => {
                    changeTravelType(2);
                    form.resetFields();
                  }}
                >
                
                  Out-Station{" "}
                </Button>
              </div>
            </Col>
            <Col md={3} sm={24} xs={8}>
              <div className="trans-rad">
                <Button
                  className={`car-search-option-button ${travelType == 3 ? "activeBtn" : ""
                    }`}
                  onClick={() => {
                    changeTravelType(3);
                    form.resetFields();
                  }}
                >
                  Transfer
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={24}>
              {travelType === 2 ? (
                <Row className="car-search-radio-button">
                  <Col
                    className="car-search-radio-button-col"
                    md={4}
                    sm={16}
                    xs={24}
                  >
                    <Radio.Group
                      onChange={(e) => onchangeTripType(e.target.value)}
                      value={tripType}
                    >
                      <Radio
                        value={1}
                        className={
                          tripType === 1
                            ? "tripTypeActive1"
                            : "normalhighlight1"
                        }
                      >
                        ONE-WAY
                      </Radio>
                      <Radio
                        value={2}
                        className={
                          tripType === 2
                            ? "tripTypeActive1"
                            : "normalhighlight1"
                        }
                      >
                        ROUND-TRIP
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              ) : travelType === 1 ? (
                <Row className="car-search-radio-button">
                  <Col
                    className="car-search-radio-button-col"
                    md={4}
                    sm={16}
                    xs={24}
                  >
                    <Radio.Group
                      onChange={(e) => onchangeTripType(e.target.value)}
                      value={tripType}
                    >
                      <Radio
                        value={3}
                        className={
                          tripType === 3
                            ? "tripTypeActive1"
                            : "normalhighlight1"
                        }
                      >
                        4 hours/day
                      </Radio>
                      <Radio
                        value={4}
                        className={
                          tripType === 4
                            ? "tripTypeActive1"
                            : "normalhighlight1"
                        }
                      >
                        8 hours/day
                      </Radio>
                      <Radio
                        value={5}
                        className={
                          tripType === 5
                            ? "tripTypeActive1"
                            : "normalhighlight1"
                        }
                      >
                        12 hours/day
                      </Radio>
                      <Radio
                        value={6}
                        className={
                          tripType === 6
                            ? "tripTypeActive1"
                            : "normalhighlight1"
                        }
                      >
                        24 hours/day
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              {travelType === 3 ? (
                <>
                  {" "}
                  <Row
                    className="search-row bus-alert-sec bus-cr-search"
                    style={{ marginBottom: "10px" }}
                  >
                    <Col
                      md={12}
                      xs={24}
                      className="from-to-inputs bus-cols-info"
                    >
                      <TransferAutoCompleteSelect
                        formItemProps={{
                          name: "sourceId",
                          rules: [
                            {
                              required: true,
                              message: "  Specify The City",
                            },
                            {
                              validator: (_, value) => {
                                let destinationId =
                                  form.getFieldValue("destinationId");
                                return OriDesValidate(destinationId, value);
                              },
                            },
                          ],
                        }}
                        selectProps={{
                          size: "large",
                          placeholder: "City",
                        }}
                        api={"transfers/searchCity/"}
                        refName={city}
                        focusRef={pickUpLocation}
                        handleOnSubmit={handleOnSubmit}
                        modifySearch={modifySearch ? modifySearch : false}
                        dataFlip={dataFlip}
                      />
                    </Col>
                  </Row>
                  <Row
                    className="search-row bus-alert-sec bus-cr-search outer-div-flight-searchfieldscar"
                    style={{ marginBottom: "10px" }}
                    gutter={[16, 8]}
                  >
                    <Col md={20} className="">
                      <Row gutter={[10, 10]}>
                        <Col
                          className="car-search-radio-button-col"
                          md={8}
                          sm={24}
                          xs={24}
                        >
                          <div className="d-flex justify-content-between align-item-center">
                            <div className="tooltip-button">
                              {" "}
                              <p className="mb-0">Pick Up</p>
                            </div>

                            <Select
                              className="w-75"
                              ref={pickUpLocation}
                              focusRef={pickUppoint}
                              placeholder="Pick Up Type"
                              onSelect={(e) => {
                                setPickUpTransferType(e);
                                GetPickUpAddresses(e);
                              }}
                              
                              value={
                                pickupTransferType === null
                                  ? ""
                                  : parseInt(pickupTransferType)
                              }
                            >
                              <Option value={1}>Airport</Option>
                              <Option value={2}>Port</Option>
                              <Option value={3}>Railway Station</Option>
                              <Option value={4}>Accomodation/Hotel</Option>
                            </Select>
                          </div>
                        </Col>
                        <Col
                          md={6}
                          xs={24}
                          className="from-to-inputs bus-cols-info"
                        >

                          <Form.Item name="pickUpLocation">
                            <Select
                              ref={pickUppoint}
                              focusRef={dropLocation}
                              placeholder="Pick Up Location"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {pickUpAddresses.length && pickupTransferType == 1
                                ? pickUpAddresses.map((item, ind) => (
                                  <Option
                                    key={"air" + item.AirportCode}
                                    value={item.AirportCode}
                                  >
                                    {item?.AirportName}-{item?.AirportCode}
                                  </Option>
                                ))
                                : pickUpAddresses.length &&
                                  pickupTransferType == 2
                                  ? pickUpAddresses.map((item, ind) => (
                                    <Option
                                      key={"port" + item.PortId}
                                      value={item.PortId}
                                    >
                                      {item?.PortName}
                                    </Option>
                                  ))
                                  : pickUpAddresses.length &&
                                    pickupTransferType == 3
                                    ? pickUpAddresses.map((item, ind) => (
                                      <Option
                                        key={"railway" + ind}
                                        value={item.StationId}
                                      >
                                        {item?.StationName}
                                      </Option>
                                    ))
                                    : pickUpAddresses.length &&
                                      pickupTransferType == 4
                                      ? pickUpAddresses.map((item, ind) => (
                                        <Option
                                          key={"Hotel" + item.HotelId}
                                          value={item.HotelId}
                                        >
                                          {item?.HotelName}
                                        </Option>
                                      ))
                                      : null}
                            </Select>
                          </Form.Item>

                        </Col>

                      </Row>
                    </Col>

                    <Col md={20} className="">
                      <Row gutter={[10, 10]}>
                        <Col
                          className="car-search-radio-button-col"
                          md={8}
                          sm={24}
                          xs={24}
                        >
                          <div className="d-flex justify-content-between align-item-center">
                            <div className="tooltip-button">
                              {" "}
                              <p className="mb-0">Drop Off</p>
                            </div>
                            <Select
                              className="w-75"
                              placeholder="Drop Off Type"
                              onSelect={(e) => {
                                setDropOffTransferType(e);
                                GetDropOffAddresses(e);
                              }}
                             
                              value={
                                dropoffTransferType === null
                                  ? ""
                                  : Number(dropoffTransferType)
                              }
                            >
                              <Option value={1}>Airport</Option>
                              <Option value={2}>Port</Option>
                              <Option value={3}>Railway Station</Option>
                              <Option value={4}>Accomodation/Hotel</Option>
                            </Select>
                          </div>
                        </Col>

                        <Col
                          md={6}
                          xs={24}
                          className="from-to-inputs bus-cols-info inputForm"
                        >
                          <Form.Item name="dropLocation" className="droplocation-ffrm">
                            <Select placeholder="Drop Location"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }>
                              {dropAddresses.length && dropoffTransferType == 1
                                ? dropAddresses.map((item, ind) => (
                                  <Option
                                    key={"air" + ind}
                                    value={item.AirportCode}
                                  >
                                    {item?.AirportName}-{item?.AirportCode}
                                  </Option>
                                ))
                                : dropAddresses.length &&
                                  dropoffTransferType == 2
                                  ? dropAddresses.map((item, ind) => (
                                    <Option
                                      key={"port" + item.PortId}
                                      value={item?.PortId}
                                    >
                                      {item?.PortName}
                                    </Option>
                                  ))
                                  : dropAddresses.length &&
                                    dropoffTransferType == 3
                                    ? dropAddresses.map((item, ind) => (
                                      <Option
                                        key={"air" + ind}
                                        value={item?.StationId}
                                      >
                                        {item?.StationName}
                                      </Option>
                                    ))
                                    : dropAddresses.length &&
                                      dropoffTransferType == 4
                                      ? dropAddresses.map((item, ind) => (
                                        <Option
                                          key={"air" + ind}
                                          value={item.HotelId}
                                        >
                                          {item?.HotelName}
                                        </Option>
                                      ))
                                      : null}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col
                          md={4}
                          xs={24}
                          className="from-to-inputs bus-cols-info"
                        >
                          <Form.Item
                            name="fromDate"
                            rules={[
                              { required: true, message: "Specify the Date" },
                            ]}
                          >
                            <DatePicker
                              allowClear={false}
                              className="search-inputs from-return-date"
                              placeholder="From Date"
                              ref={fromDate}
                              format={dateFormat}
                              open={showFromDate}
                              disabledDate={disabledOriginDate}
                              onOpenChange={() => {
                                setShowFromDate((prev) => !prev);
                              }}
                              style={{ width: "100%", height: "100%" }}
                              dropdownClassName="custom-mob-calendar"
                              inputReadOnly={true}
                              panelRender={(originalPanel) => {
                                return (
                                  <div className="original-panel">
                                    <p className="mb-0 text-center mt-1 py-2 font-weight-bold h6 custom-mob-calendar-title">
                                      From Date
                                    </p>
                                    {originalPanel}
                                  </div>
                                );
                              }}
                              onChange={(date, dateString) => {
                                onChangeOriginDate(date, dateString);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={4} xs={24} className="bus-cols-info">
                          <Form.Item name="TransferTime">
                            <TimePicker
                              style={{ width: "100%", height: "100%" }}
                              className="search-inputs from-return-date"
                              showSearch
                              placeholder="Start Time"
                              format={format}
                            />
                          </Form.Item>
                        </Col>
                       
                      </Row>
                    </Col>

                   

                    <Col md={4} xs={24} className="bus-cols-info">
                      <div className="srch-btn-tr d-flex">
                        <Button
                          className="primary-btn train-search-bt-1 transfer-search-btn"
                          ref={searchBtn}
                          htmlType="submit"
                        >
                          <SearchOutlined /> Search
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <Row className="search-row bus-alert-sec bus-cr-search justify-content-center outer-div-flight-searchfields">
                  <Col md={4} xs={24} className="from-to-inputs bus-cols-info">
                    <CarAutoSelect
                      formItemProps={{
                        name: "sourceId",
                        rules: [
                          {
                            required: true,
                            message: "   Specify The City",
                          },
                          {
                            validator: (_, value) => {
                              let destinationId =
                                form.getFieldValue("destinationId");
                              return OriDesValidate(destinationId, value);
                            },
                          },
                        ],
                      }}
                      selectProps={{
                        size: "large",
                        placeholder: "From",
                      }}
                      api={"carextranet/getCitiesBySearch/"}
                      refName={city}
                      focusRef={tocityBox}
                      handleOnSubmit={handleOnSubmit}
                      modifySearch={modifySearch ? modifySearch : false}
                      dataFlip={dataFlip}
                    />
                  </Col>
                  <Col
                    md={4}
                    xs={24}
                    className="from-to-inputs bus-cols-info destination"
                  >
                    <CarAutoSelect
                      formItemProps={
                        travelType === 1
                          ? null
                          : {
                            name: "destinationId",
                            rules: [
                              {
                                required: true,
                                message: "  Specify The City",
                              },
                              {
                                validator: (_, value) => {
                                  let sourceId =
                                    form.getFieldValue("sourceId");
                                  return OriDesValidate(sourceId, value);
                                },
                              },
                            ],
                          }
                      }
                      selectProps={{
                        size: "large",
                        placeholder: "To",
                      }}
                      api={"carextranet/getCitiesBySearch/"}
                      refName={tocityBox}
                      focusRef={fromDate}
                      handleOnSubmit={handleOnSubmit}
                      modifySearch={modifySearch ? modifySearch : false}
                      dataFlip={dataFlip}
                      disabled={travelType === 1 ? true : false}
                    />
                  </Col>
                  <Col md={4} xs={24} className="from-to-inputs bus-cols-info">
                    <Form.Item
                      name="fromDate"
                      rules={[{ required: true, message: "Specify the Date" }]}
                    >
                      <DatePicker
                        allowClear={false}
                        size="large"
                        className="search-inputs from-return-date"
                        placeholder="From Date"
                        ref={fromDate}
                        focusRef={toDateBox}
                        format={dateFormat}
                        open={showFromDate}
                        disabledDate={disabledOriginDate}
                        onOpenChange={() => {
                          setShowFromDate((prev) => !prev);
                        }}
                        style={{ width: "100%", height: "100%" }}
                        dropdownClassName="custom-mob-calendar"
                        inputReadOnly={true}
                        onChange={(date, dateString) =>
                          onChangeOriginDate(date, dateString)
                        }
                        panelRender={(originalPanel) => {
                          return (
                            <div className="original-panel">
                              <p className="mb-0 text-center mt-1 py-2 font-weight-bold h6 custom-mob-calendar-title">
                                From Date
                              </p>
                              {originalPanel}
                            </div>
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={4} xs={24} className="bus-cols-info">
                    <TimePicker
                      style={{ width: "100%", height: "100%" }}
                      className="search-inputs from-return-date"
                      showSearch
                      placeholder="Start Time"
                      size="large"
                      format={format}
                    />
                  
                  </Col>
                  <Col md={4} xs={24} className="from-to-inputs bus-cols-info">
                    <Form.Item
                      name="toDate"
                      rules={
                        tripType === 1 || travelType === 1
                          ? null
                          : [{ required: true, message: "Specify Date" }]
                      }
                    >
                      <DatePicker
                        allowClear={false}
                        size="large"
                        className="search-inputs from-return-date"
                        placeholder="To Date"
                        ref={toDateBox}
                        format={dateFormat}
                        open={showToDate}
                        disabledDate={disabledDestDate}
                        onOpenChange={() => {
                          setShowToDate((prev) => !prev);
                        }}
                        style={{ width: "100%", height: "100%" }}
                        disabled={
                          tripType === 1 || travelType === 1 ? true : false
                        }
                        dropdownClassName="custom-mob-calendar"
                        inputReadOnly={true}
                        panelRender={(originalPanel) => {
                          return (
                            <div className="original-panel">
                              <p className="mb-0 text-center mt-1 py-2 font-weight-bold h6 custom-mob-calendar-title">
                                To Date
                              </p>
                              {originalPanel}
                            </div>
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={4} xs={24} className="bus-cols-info">
                    <Button
                      size="large"
                      className="primary-btn train-search-btn bus-btn-pri"
                      ref={searchBtn}
                      htmlType="submit"
                    >
                      <SearchOutlined /> Search
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default Cars;
