import React, { useState, useContext } from "react";
import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import deal_list_img_1 from "../../../assets/images/DealsImages/listViewImg.jpg";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import moment from "moment";
import "./ListView.scss";

const DealsListView = ({ dealsData }) => {
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const BASE = process.env.REACT_APP_BASE_URL;
  let tomorrowDate = moment().add(5, "days").format("YYYY-MM-DD");
  const [showMoreDeals1, setShowMoreDeals1] = useState({ isToggle: false });

  const moreDealsToggle = (e) => {
    setShowMoreDeals1({
      isToggle: !showMoreDeals1.isToggle,
    });
  };

  return (
    <div className="list-view-wrapper">
      {dealsData.map((deal) => {
        return (
          <div key={deal.DealID} className="deal-list-item">
            <div className="">
              <Row>
                <Col md={5} sm={0} xs={0}>
                  <div className="deal-list-img">
                    <img
                      src={
                        deal.Image
                          ? BASE + deal.Image.substring(1)
                          : deal_list_img_1
                      }
                      alt="deal_list_img_1"
                    />
                     {/* <p>{deal.FromCountryID.Country}</p> */}
                  </div>
                </Col>
                <Col md={19} sm={24} xs={24}>
                  <div className="list-item-content">
                    <div className="item-left-text">
                    <h3>
                        {deal.FromAirportName} 
                        ({deal.FromAirportCode})
                      </h3> {'(To)'}
                      {/* <i className="fa fa-plane"></i> */}
                      <h3>
                        {deal.ToAirportName} 
                        ({deal.ToAirportCode})
                      </h3>
                      {/* <p>{deal.FromCountryID.Country}</p> */}
                    </div>
                    <div className="item-right-text">
                      <div className="item-price">
                        <span className="price-prefix">FROM</span>
                        <span className="price">
                          {currencyValue(deal.FromAmount)}
                        </span>
                        <span className="price-suffix">{activeCurrency}</span>
                      </div>
                      <div className="deals-btn">
                        <Link
                          to={`/flight/results?adultCount=1&airTravelType=oneWay&cabinclassName=Economy&childCount=0&infantCount=0&departureDateTime=${tomorrowDate}&flightDateFlex=1&origin=${deal.source}&destination=${deal.destination}`}
                        >
                          <Button>Grab</Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        );
      })}

      {/* <div className="deal-list-item">
        <div className="">
          <Row>
            <Col span={5}>
              <div className="deal-list-img">
                <img src={deal_list_img_1} alt="deal_list_img_1" />
              </div>
            </Col>
            <Col span={19}>
              <div className="list-item-content">
                <div className="item-left-text">
                  <h3>Miami (FL)</h3>
                  <p>United States of America</p>
                </div>
                <div className="item-right-text">
                  <div className="item-price">
                    <span className="price-prefix">FROM</span>
                    <span className="price">26</span>
                    <span className="price-suffix">USD</span>
                  </div>
                  <div className="deals-btn">
                    <Button onClick={moreDealsToggle}>
                      5 deals <i className="fa fa-angle-down"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div> */}
      {/* showMoreDeals1 && 

      <div
        style={{ display: showMoreDeals1.isToggle ? "block" : "none" }}
        className=""
      >
        <div className="more-deals-wrapper">
          <Row>
            <Col offset={4} span={20}>
              <div className="deal-list-item more-background">
                <Link to="/flight/results">
                  <Row>
                    <Col span={5}>
                      <div className="more-deal-list-img">
                        <img src={more_deal_logo} alt="more_deal_logo" />
                      </div>
                    </Col>
                    <Col span={19}>
                      <div className="list-item-content">
                        <div className="item-left-text">
                          <h6>
                            Miami (FL), Miami Intl Airport <span>(MIA)</span>
                          </h6>
                          <p>
                            from <span>New York(NY), LaGuardia</span>(LGA)
                          </p>
                        </div>
                        <div className="item-right-text">
                          <div className="more-item-price">
                            <span className="price-prefix">FROM</span>
                            <span className="price">26</span>
                            <span className="price-suffix">USD</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <div className="more-deals-wrapper">
          <Row>
            <Col offset={4} span={20}>
              <div className="deal-list-item more-background">
                <Row>
                  <Col span={5}>
                    <div className="more-deal-list-img">
                      <img src={more_deal_logo} alt="more_deal_logo" />
                    </div>
                  </Col>
                  <Col span={19}>
                    <div className="list-item-content">
                      <div className="item-left-text">
                        <h6>
                          Miami (FL), Miami Intl Airport <span>(MIA)</span>
                        </h6>
                        <p>
                          from <span>New York(NY), LaGuardia</span>(LGA)
                        </p>
                      </div>
                      <div className="item-right-text">
                        <div className="more-item-price">
                          <span className="price-prefix">FROM</span>
                          <span className="price">26</span>
                          <span className="price-suffix">USD</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <div className="more-deals-wrapper">
          <Row>
            <Col offset={4} span={20}>
              <div className="deal-list-item more-background">
                <Row>
                  <Col span={5}>
                    <div className="more-deal-list-img">
                      <img src={more_deal_logo} alt="more_deal_logo" />
                    </div>
                  </Col>
                  <Col span={19}>
                    <div className="list-item-content">
                      <div className="item-left-text">
                        <h6>
                          Miami (FL), Miami Intl Airport <span>(MIA)</span>
                        </h6>
                        <p>
                          from <span>New York(NY), LaGuardia</span>(LGA)
                        </p>
                      </div>
                      <div className="item-right-text">
                        <div className="more-item-price">
                          <span className="price-prefix">FROM</span>
                          <span className="price">26</span>
                          <span className="price-suffix">USD</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>

     

      <div className="deal-list-item">
        <div className="">
          <Row>
            <Col span={5}>
              <div className="deal-list-img">
                <img src={list2} alt="list2" />
              </div>
            </Col>
            <Col span={19}>
           
              <div className="list-item-content">
                <div className="item-left-text">
                  <h3>Las Vegas (NV)</h3>
                  <p>United States of America</p>
                </div>
                <div className="item-right-text">
                  <div className="item-price">
                    <span className="price-prefix">FROM</span>
                    <span className="price">9</span>
                    <span className="price-suffix">USD</span>
                  </div>
                  <div className="deals-btn">
                  
                    <Button onClick={moreDealsToggle}>
                      19 deals <i className="fa fa-angle-down"></i>
                    </Button>
                  
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
     

      <div
        style={{ display: showMoreDeals1.isToggle ? "block" : "none" }}
        className=""
      >
        <div className="more-deals-wrapper">
          <Row>
            <Col offset={4} span={20}>
              <div className="deal-list-item more-background">
                <Link to="/flight/results">
                  <Row>
                    <Col span={5}>
                      <div className="more-deal-list-img">
                        <img src={more_deal_logo} alt="more_deal_logo" />
                      </div>
                    </Col>
                    <Col span={19}>
                      <div className="list-item-content">
                        <div className="item-left-text">
                          <h6>
                            Miami (FL), Miami Intl Airport <span>(MIA)</span>
                          </h6>
                          <p>
                            from <span>New York(NY), LaGuardia</span>(LGA)
                          </p>
                        </div>
                        <div className="item-right-text">
                          <div className="more-item-price">
                            <span className="price-prefix">FROM</span>
                            <span className="price">26</span>
                            <span className="price-suffix">USD</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <div className="more-deals-wrapper">
          <Row>
            <Col offset={4} span={20}>
              <div className="deal-list-item more-background">
                <Row>
                  <Col span={5}>
                    <div className="more-deal-list-img">
                      <img src={more_deal_logo} alt="more_deal_logo" />
                    </div>
                  </Col>
                  <Col span={19}>
                    <div className="list-item-content">
                      <div className="item-left-text">
                        <h6>
                          Miami (FL), Miami Intl Airport <span>(MIA)</span>
                        </h6>
                        <p>
                          from <span>New York(NY), LaGuardia</span>(LGA)
                        </p>
                      </div>
                      <div className="item-right-text">
                        <div className="more-item-price">
                          <span className="price-prefix">FROM</span>
                          <span className="price">26</span>
                          <span className="price-suffix">USD</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <div className="more-deals-wrapper">
          <Row>
            <Col offset={4} span={20}>
              <div className="deal-list-item more-background">
                <Row>
                  <Col span={5}>
                    <div className="more-deal-list-img">
                      <img src={more_deal_logo} alt="more_deal_logo" />
                    </div>
                  </Col>
                  <Col span={19}>
                    <div className="list-item-content">
                      <div className="item-left-text">
                        <h6>
                          Miami (FL), Miami Intl Airport <span>(MIA)</span>
                        </h6>
                        <p>
                          from <span>New York(NY), LaGuardia</span>(LGA)
                        </p>
                      </div>
                      <div className="item-right-text">
                        <div className="more-item-price">
                          <span className="price-prefix">FROM</span>
                          <span className="price">26</span>
                          <span className="price-suffix">USD</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>

     
      <div className="deal-list-item">
        <div className="">
          <Row>
            <Col span={5}>
              <div className="deal-list-img">
                <img src={list3} alt="list3" />
              </div>
            </Col>
            <Col span={19}>
             
              <div className="list-item-content">
                <div className="item-left-text">
                  <h3>Los Angeles (CA)</h3>
                  <p>United States of America</p>
                </div>
                <div className="item-right-text">
                  <div className="item-price">
                    <span className="price-prefix">FROM</span>
                    <span className="price">50</span>
                    <span className="price-suffix">USD</span>
                  </div>
                  <div className="deals-btn">
                   
                    <Button onClick={moreDealsToggle}>
                      15 deals <i className="fa fa-angle-down"></i>
                    </Button>
                   
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
     

      <div
        style={{ display: showMoreDeals1.isToggle ? "block" : "none" }}
        className=""
      >
        <div className="more-deals-wrapper">
          <Row>
            <Col offset={4} span={20}>
              <div className="deal-list-item more-background">
                <Link to="/flight/results">
                  <Row>
                    <Col span={5}>
                      <div className="more-deal-list-img">
                        <img src={more_deal_logo} alt="more_deal_logo" />
                      </div>
                    </Col>
                    <Col span={19}>
                      <div className="list-item-content">
                        <div className="item-left-text">
                          <h6>
                            Miami (FL), Miami Intl Airport <span>(MIA)</span>
                          </h6>
                          <p>
                            from <span>New York(NY), LaGuardia</span>(LGA)
                          </p>
                        </div>
                        <div className="item-right-text">
                          <div className="more-item-price">
                            <span className="price-prefix">FROM</span>
                            <span className="price">26</span>
                            <span className="price-suffix">USD</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <div className="more-deals-wrapper">
          <Row>
            <Col offset={4} span={20}>
              <div className="deal-list-item more-background">
                <Row>
                  <Col span={5}>
                    <div className="more-deal-list-img">
                      <img src={more_deal_logo} alt="more_deal_logo" />
                    </div>
                  </Col>
                  <Col span={19}>
                    <div className="list-item-content">
                      <div className="item-left-text">
                        <h6>
                          Miami (FL), Miami Intl Airport <span>(MIA)</span>
                        </h6>
                        <p>
                          from <span>New York(NY), LaGuardia</span>(LGA)
                        </p>
                      </div>
                      <div className="item-right-text">
                        <div className="more-item-price">
                          <span className="price-prefix">FROM</span>
                          <span className="price">26</span>
                          <span className="price-suffix">USD</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <div className="more-deals-wrapper">
          <Row>
            <Col offset={4} span={20}>
              <div className="deal-list-item more-background">
                <Row>
                  <Col span={5}>
                    <div className="more-deal-list-img">
                      <img src={more_deal_logo} alt="more_deal_logo" />
                    </div>
                  </Col>
                  <Col span={19}>
                    <div className="list-item-content">
                      <div className="item-left-text">
                        <h6>
                          Miami (FL), Miami Intl Airport <span>(MIA)</span>
                        </h6>
                        <p>
                          from <span>New York(NY), LaGuardia</span>(LGA)
                        </p>
                      </div>
                      <div className="item-right-text">
                        <div className="more-item-price">
                          <span className="price-prefix">FROM</span>
                          <span className="price">26</span>
                          <span className="price-suffix">USD</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>

     
      <div className="deal-list-item">
        <div className="">
          <Row>
            <Col span={5}>
              <div className="deal-list-img">
                <img src={list4} alt="list4" />
              </div>
            </Col>
            <Col span={19}>
              
              <div className="list-item-content">
                <div className="item-left-text">
                  <h3>Chicago (IL)</h3>
                  <p>United States of America</p>
                </div>
                <div className="item-right-text">
                  <div className="item-price">
                    <span className="price-prefix">FROM</span>
                    <span className="price">45</span>
                    <span className="price-suffix">USD</span>
                  </div>
                  <div className="deals-btn">
                  
                    <Button onClick={moreDealsToggle}>
                      6 deals <i className="fa fa-angle-down"></i>
                    </Button>
                  
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    

      <div
        style={{ display: showMoreDeals1.isToggle ? "block" : "none" }}
        className=""
      >
        <div className="more-deals-wrapper">
          <Row>
            <Col offset={4} span={20}>
              <div className="deal-list-item more-background">
                <Link to="/flight/results">
                  <Row>
                    <Col span={5}>
                      <div className="more-deal-list-img">
                        <img src={more_deal_logo} alt="more_deal_logo" />
                      </div>
                    </Col>
                    <Col span={19}>
                      <div className="list-item-content">
                        <div className="item-left-text">
                          <h6>
                            Miami (FL), Miami Intl Airport <span>(MIA)</span>
                          </h6>
                          <p>
                            from <span>New York(NY), LaGuardia</span>(LGA)
                          </p>
                        </div>
                        <div className="item-right-text">
                          <div className="more-item-price">
                            <span className="price-prefix">FROM</span>
                            <span className="price">26</span>
                            <span className="price-suffix">USD</span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <div className="more-deals-wrapper">
          <Row>
            <Col offset={4} span={20}>
              <div className="deal-list-item more-background">
                <Row>
                  <Col span={5}>
                    <div className="more-deal-list-img">
                      <img src={more_deal_logo} alt="more_deal_logo" />
                    </div>
                  </Col>
                  <Col span={19}>
                    <div className="list-item-content">
                      <div className="item-left-text">
                        <h6>
                          Miami (FL), Miami Intl Airport <span>(MIA)</span>
                        </h6>
                        <p>
                          from <span>New York(NY), LaGuardia</span>(LGA)
                        </p>
                      </div>
                      <div className="item-right-text">
                        <div className="more-item-price">
                          <span className="price-prefix">FROM</span>
                          <span className="price">26</span>
                          <span className="price-suffix">USD</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <div className="more-deals-wrapper">
          <Row>
            <Col offset={4} span={20}>
              <div className="deal-list-item more-background">
                <Row>
                  <Col span={5}>
                    <div className="more-deal-list-img">
                      <img src={more_deal_logo} alt="more_deal_logo" />
                    </div>
                  </Col>
                  <Col span={19}>
                    <div className="list-item-content">
                      <div className="item-left-text">
                        <h6>
                          Miami (FL), Miami Intl Airport <span>(MIA)</span>
                        </h6>
                        <p>
                          from <span>New York(NY), LaGuardia</span>(LGA)
                        </p>
                      </div>
                      <div className="item-right-text">
                        <div className="more-item-price">
                          <span className="price-prefix">FROM</span>
                          <span className="price">26</span>
                          <span className="price-suffix">USD</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="show-more-btn">
        <button>
          Show me more deals <i className="fa fa-angle-down"></i>
        </button>
        <div className="scroll-to-top">
          <p>Back to top of page</p>
        </div>
      </div>*/}
    </div>
  );
};

export default DealsListView;
