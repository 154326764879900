import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  message,
  Modal,
  Popconfirm,
  Input,
  Layout,
  Radio,
  Row,
  Select,
  Switch,
  Table,
  //Popover,
} from "antd";
import {
  PlusCircleFilled,
  EditOutlined,
  DeleteOutlined,
  //InfoCircleTwoTone,
} from "@ant-design/icons";
import moment from "moment";
import "./AdminUser.scss";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import ApiClient from "../../helpers/ApiClient";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

import { useAuthContext } from "../../common/providers/AuthProvider";
import VerifyPasswordModal from "../../common/VerifyPasswordModal/VerifyPasswordModal";
//import { convertNwSeToNeSw } from "google-map-react";

const { Content } = Layout;

const AdminUser = () => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [userList, setUserList] = useState([]);
  const [userId, setUserId] = useState(-1);
  const [rolesList, setRolesList] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const [formType, setFormType] = useState(-1);

  const { user } = useAuthContext();

  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [userStatus, setUserStatus] = useState({});

  const showModal = () => {
    if (editMode) {
      setEditMode(false);
      setUserId(-1);
      form.resetFields();
    }
    setModalVisible(true);
  };
  const onFinish = (values) => {
    if (editMode) {
      updateAdminUser(values);
    } else {
      submitAdminUser(values);
    }
  };

  const updateAdminUser = (data) => {
    if (!data.Password) delete data.Password;

    if (!data.TransactionPassword) delete data.TransactionPassword;

    ApiClient.put("admin/user/" + userId, {}, data)
      .then((response) => {
        if (response.status === 200) {
          message.success(" Updated SuccessFully", 3);
          getAdminUsers();
          form.resetFields();
          setModalVisible(false);
          setEditMode(false);
          setUserId(-1);
        } else if (response.status === 404) {
          message.error("Not Found", 3);
        } else if (response.status === 409) {
          message.error(response.message, 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleVerifyPassword = (values) => {
    ApiClient.post("admin/verifyTransactionPassword", {
      UserID: user.UserID,
      Password: values,
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          message.success(res.message);
          if (formType == 1) {
            form.submit();
          } else if (formType === 2) {
            deleteAdmin(userId);
          } else if (formType === 3) {
            changeStatus(userId, userStatus);
          }
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const submitAdminUser = (data) => {
    ApiClient.post("admin/user", data)
      .then((response) => {
        if (response.status === 200) {
          message.success(" Added SuccessFully", 3);
          getAdminUsers();
          form.resetFields();
          setModalVisible(false);
        } else if (response.status === 404) {
          message.error("Not Found", 3);
        } else if (response.status === 409) {
          message.error(response.message, 3);
        } else if (response.status === 500) {
          message.error("User Already Exists!", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getRolesList = () => {
    ApiClient.get("admin/role")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) => item.Level === 1);

          setRolesList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getAdminUsers = () => {
    ApiClient.get("admin/user")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data
            .filter((item) => item?.role?.Level === 1)
            .map((item, i) => ({
              ...item,
              SrNo: i + 1,
            }));

          setUserList(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getAdminUsers();
    getRolesList();
  }, []);

  const handleReload = () => {
    getAdminUsers();
    getRolesList();
  };

  const deleteAdmin = (UserID) => {
    ApiClient.delete("admin/user/" + UserID)
      .then((res) => {
        if (res.status === 200) {
          message.success("Deleted  successfully", 3);
          getAdminUsers();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editRecord(rec)}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => {
              setVerifyModalVisible(true);
              setFormType(2);
              setUserId(rec.UserID);
            }}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const editRecord = (rec) => {
    setEditMode(true);
    setUserId(rec.UserID);

    let userData = rec.userDetails;

    form.setFieldsValue({
      Role: rec.Role,
      FirstName: userData.FirstName,
      LastName: userData.LastName,
      Email: rec.Email,
      DailingCode: rec.DailingCode,
      Mobile: rec.Mobile,
      Gender: userData.Gender,
      DOB: userData?.DOB ? moment(userData.DOB) : "",
      AlternateEmail: rec.AlternateEmail,
      AlternateMobile: rec.AlternateMobile,
      City: userData.City ? userData.City : "",
      Status: rec.Status,
    });
    setModalVisible(true);
  };

  const { Option } = Select;
  const prefixSelector = (
    <Form.Item name="DailingCode" noStyle>
      <Select style={{ width: 80 }} placeholder="---" disabled={editMode}>
        <Option value="91">+91</Option>
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );

  const getRoleName = (data) => {
    let roleName = data;
    rolesList.forEach((role) => {
      if (role.RoleID === data) {
        roleName = role.Name;
      }
    });
    return roleName;
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SrNo",
    },
    {
      title: "Name",
      dataIndex: "userDetails",
      render: (text) =>
        text
          ? text.FirstName
            ? text.FirstName
            : "" + " " + text.LastName
            ? text.LastName
            : ""
          : "",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Mobile",
      dataIndex: "Mobile",
    },
    {
      title: "Role",
      dataIndex: "Role",
      render: (role) => getRoleName(role),
    },
    {
      title: "Status",

      render: (status) => handelStatus(status),
    },
    {
      dataIndex: "actions",
      title: "Actions",
      render: (_, rec) => tableActions(rec),
    },
  ];
  const handelStatus = (data) => (
    <Switch
      checkedChildren="Active"
      unCheckedChildren="Inactive"
      checked={data.Status == 1 ? true : false}
      onChange={() => {
        setVerifyModalVisible(true);
        setFormType(3);
        setUserId(data.UserID);
        setUserStatus(data.Status == 1 ? 0 : 1);
      }}
    />
  );

  const changeStatus = (userId, Status) => {
    let data = {
      UserID: userId,
      Status: Status,
    };
    ApiClient.put("admin/updateUserStatus", {}, data)
      .then((response) => {
        if (response.status == 200) {
          message.success(response.message, 3);
          getAdminUsers();
        } else if (response.status == 400) {
          message.error(response.message, 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          View Admin Users{" "}
                          <HelpInfoHelper screenName={"/admin/user"} />
                        </h5>
                        <p>{userList.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />
                      <img src={excel} alt="excel" />

                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={handleReload}
                      />
                      <p className="add-deposit-icon" onClick={showModal}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>

                  <div>
                    <Table
                      className="table-scroll-none"
                      bordered
                      dataSource={userList}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title={editMode ? "Update User" : "Add User"}
        className="promo-modal-header admin-bc-level modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        width={"1100px"}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              onClick={() => {
                setVerifyModalVisible(true);
                setFormType(1);
              }}
            >
              {editMode ? "Update" : "Add"}
            </Button>
            <Button key="submit" type="danger">
              Clear
            </Button>
          </div>,
        ]}
      >
        <Form
          layout="vertical"
          name="basic"
          initialValues={{ DailingCode: "+91" }}
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col md={6} sm={12} xs={24}>
              <Form.Item label="Role" rules={[{ required: true }]} name="Role">
                <Select placeholder="Select Role">
                  {rolesList.map((item, i) => (
                    <Option key={i} value={item.RoleID}>
                      {item.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                name="FirstName"
                label="First Name"
                rules={[
                  { required: true, message: "Please input your firstname!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={6} sm={12} xs={24}>
              <Form.Item
                name="LastName"
                label="Last Name"
                rules={[
                  { required: true, message: "Please input your lastname!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={6} sm={12} xs={24}>
              <Form.Item
                name="Email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input disabled={editMode} />
              </Form.Item>
            </Col>

            <Col md={6} sm={12} xs={24}>
              <Form.Item
                name="Mobile"
                label="Mobile Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your mobile number!",
                  },
                ]}
              >
                <Input
                  addonBefore={prefixSelector}
                  style={{ width: "100%" }}
                  disabled={editMode}
                />
              </Form.Item>
            </Col>
            {!editMode ? (
              <Col md={6} sm={12} xs={24}>
                <Form.Item
                  name="Password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            ) : null}

            {!editMode ? (
              <Col md={6} sm={12} xs={24}>
                <Form.Item
                  name="TransactionPassword"
                  label="Transaction Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Transaction password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
              </Col>
            ) : null}

            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label="Gender"
                name="Gender"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={1}>Male</Radio>
                  <Radio value={2}>Female</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col md={6} sm={12} xs={24}>
              <Form.Item
                name="DOB"
                label="Date of Birth"

                // rules={[{ required: true }]}
              >
                <DatePicker style={{ width: "100%" }} format="DD-MM-YYYY" />
              </Form.Item>
            </Col>

            <Col md={6} sm={12} xs={24}>
              <Form.Item
                name="AlternateEmail"
                label="Alternate Email"
                rules={[
                  {
                    type: "email",
                    // required: true,
                    message: "Please input your alternate email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={6} sm={12} xs={24}>
              <Form.Item name="AlternateMobile" label="Alternate Mobile Number">
                <Input addonBefore={prefixSelector} style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col md={6} sm={12} xs={24}>
              <Form.Item
                label="City"
                name="City"
                rules={[{ required: true, message: "Please input your city!" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={6} sm={12} xs={24}>
              <Form.Item label="Status" name="Status">
                <Radio.Group
                  rules={[{ required: true, message: "Please choose Status!" }]}
                >
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {verifyModalVisible && (
        <VerifyPasswordModal
          visible={verifyModalVisible}
          setVerifyModalVisible={setVerifyModalVisible}
          handleVerifyPassword={handleVerifyPassword}
        />
      )}
    </div>
  );
};

export default AdminUser;
