import React, { useState } from "react";
import * as ANTD from "antd";
import { Form, Input, Modal, Select, Checkbox } from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  BankOutlined,
  MailOutlined,
  IdcardOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";

const Admin = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [modalVisible, setModalVisible] = React.useState(false);
  const [formLayout, setFormLayout] = React.useState("vertical");

  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 23 },
        }
      : null;

  const clear = () => {
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const closeModal = () => {
    setModalVisible({
      visible: false,
    });
  };
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section">
          <Content className="admin-container">
            <ANTD.Row>
              <ANTD.Col span={24}>
                <div className="card-bt-gap">
                  <ANTD.Card bordered={false}>
                    <div className="card-add-heading">
                      <h5>Admin</h5>
                      <p className="add-icon" onClick={showModal}>
                        <PlusOutlined />
                      </p>
                    </div>
                  </ANTD.Card>
                </div>
              </ANTD.Col>
            </ANTD.Row>

            {/* <ANTD.Row>
                            <ANTD.Col span={24}>
                                
                                <h5 style={{ float: "right" }}>
                                                <PlusOutlined />Create New Role
                                            </h5> 
                              
                            </ANTD.Col>
                        </ANTD.Row>      */}
          </Content>
        </div>

        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>Admin</strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header admin-preference"
          visible={modalVisible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          footer={[
            <div>
              <ANTD.Button key="add" type="primary">
                Save
              </ANTD.Button>
            </div>,
          ]}
        >
          <ANTD.Form
            {...formItemLayout}
            layout={formLayout}
            initialValues={{ layout: formLayout }}
          >
            <ANTD.Row gutter={[32, 16]}>
              <ANTD.Col xs={24} md={8}>
                <ANTD.Form.Item
                  style={{
                    marginBottom: "0px",
                    border: "1px solid #b6b6b6",
                    borderRadius: "5px",
                    padding: "3px 5px",
                  }}
                >
                  <ANTD.Checkbox>Manage Company</ANTD.Checkbox>
                </ANTD.Form.Item>
              </ANTD.Col>

              <ANTD.Col xs={24} md={8}>
                <ANTD.Form.Item
                  style={{
                    marginBottom: "0px",
                    border: "1px solid #b6b6b6",
                    borderRadius: "5px",
                    padding: "3px 5px",
                  }}
                >
                  <ANTD.Checkbox>Manage Maters</ANTD.Checkbox>
                </ANTD.Form.Item>
              </ANTD.Col>

              <ANTD.Col xs={24} md={8}>
                <ANTD.Form.Item
                  style={{
                    marginBottom: "0px",
                    border: "1px solid #b6b6b6",
                    borderRadius: "5px",
                    padding: "3px 5px",
                  }}
                >
                  <ANTD.Checkbox>Manage Users</ANTD.Checkbox>
                </ANTD.Form.Item>
              </ANTD.Col>

              <ANTD.Col xs={24} md={8}>
                <ANTD.Form.Item
                  style={{
                    marginBottom: "0px",
                    border: "1px solid #b6b6b6",
                    borderRadius: "5px",
                    padding: "3px 5px",
                  }}
                >
                  <ANTD.Checkbox>Manage Permissions</ANTD.Checkbox>
                </ANTD.Form.Item>
              </ANTD.Col>

              <ANTD.Col xs={24} md={8}>
                <ANTD.Form.Item
                  style={{
                    marginBottom: "0px",
                    border: "1px solid #b6b6b6",
                    borderRadius: "5px",
                    padding: "3px 5px",
                  }}
                >
                  <ANTD.Checkbox>Manage Travel Policy</ANTD.Checkbox>
                </ANTD.Form.Item>
              </ANTD.Col>

              <ANTD.Col xs={24} md={8}>
                <ANTD.Form.Item
                  style={{
                    marginBottom: "0px",
                    border: "1px solid #b6b6b6",
                    borderRadius: "5px",
                    padding: "3px 5px",
                  }}
                >
                  <ANTD.Checkbox>Manage Company Preference</ANTD.Checkbox>
                </ANTD.Form.Item>
              </ANTD.Col>

              <ANTD.Col xs={24} md={8}>
                <ANTD.Form.Item
                  style={{
                    marginBottom: "0px",
                    border: "1px solid #b6b6b6",
                    borderRadius: "5px",
                    padding: "3px 5px",
                  }}
                >
                  <ANTD.Checkbox>Book A Trip</ANTD.Checkbox>
                </ANTD.Form.Item>
              </ANTD.Col>

              <ANTD.Col xs={24} md={8}>
                <ANTD.Form.Item
                  style={{
                    marginBottom: "0px",
                    border: "1px solid #b6b6b6",
                    borderRadius: "5px",
                    padding: "3px 5px",
                  }}
                >
                  <ANTD.Checkbox>Reports</ANTD.Checkbox>
                </ANTD.Form.Item>
              </ANTD.Col>

              <ANTD.Col xs={24} md={8}>
                <ANTD.Form.Item
                  style={{
                    marginBottom: "0px",
                    border: "1px solid #b6b6b6",
                    borderRadius: "5px",
                    padding: "3px 5px",
                  }}
                >
                  <ANTD.Checkbox>Approval Screen</ANTD.Checkbox>
                </ANTD.Form.Item>
              </ANTD.Col>

              <ANTD.Col xs={24} md={8}>
                <ANTD.Form.Item
                  style={{
                    marginBottom: "0px",
                    border: "1px solid #b6b6b6",
                    borderRadius: "5px",
                    padding: "3px 5px",
                  }}
                >
                  <ANTD.Checkbox>Escalation</ANTD.Checkbox>
                </ANTD.Form.Item>
              </ANTD.Col>

              <ANTD.Col xs={24} md={8}>
                <ANTD.Form.Item
                  style={{
                    marginBottom: "0px",
                    border: "1px solid #b6b6b6",
                    borderRadius: "5px",
                    padding: "3px 5px",
                  }}
                >
                  <ANTD.Checkbox>View Approvals</ANTD.Checkbox>
                </ANTD.Form.Item>
              </ANTD.Col>

              <ANTD.Col xs={24} md={8}>
                <ANTD.Form.Item
                  style={{
                    marginBottom: "0px",
                    border: "1px solid #b6b6b6",
                    borderRadius: "5px",
                    padding: "3px 5px",
                  }}
                >
                  <ANTD.Checkbox>Can Book For Guest User </ANTD.Checkbox>
                </ANTD.Form.Item>
              </ANTD.Col>

              <ANTD.Col xs={24} md={8}>
                <ANTD.Form.Item
                  style={{
                    marginBottom: "0px",
                    border: "1px solid #b6b6b6",
                    borderRadius: "5px",
                    padding: "3px 5px",
                  }}
                >
                  <ANTD.Checkbox>Unlock User </ANTD.Checkbox>
                </ANTD.Form.Item>
              </ANTD.Col>
            </ANTD.Row>
          </ANTD.Form>
        </Modal>
      </div>
    </div>
  );
};
export default Admin;
