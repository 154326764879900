import React, { useState, useRef, useEffect } from "react";
import { Modal, Form, Row, Col, Statistic, Input, Button, message } from "antd";
import "./verifyPasswordModal.scss";
import sendImg from "../../assets/images/send-otp-2.png";

function VerifyPasswordModal({ visible, setVerifyModalVisible, handleVerifyPassword }) {
  const [passwordVerify] = Form.useForm();

  const submitForm = (data) => {
    handleVerifyPassword(data.Password);
  };

  useEffect(() => {
    passwordVerify.resetFields();
  }, [visible]);

  return (
    <Modal className="modal-login-new-1" centered visible={visible} onOk={() => setVerifyModalVisible(false)} onCancel={() => setVerifyModalVisible(false)}>
      <Form form={passwordVerify} onFinish={submitForm}>
        <Row>
          <Col md={24} xs={24}>
            <div className="otp-shadow">
              <p>Enter Transaction Password </p>
              <Row gutter={8}>
                <Col className=" " md={24} xs={24}>
                  <Form.Item
                    className="otp-margin-bottom"
                    name="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Your Transaction Password.",
                      },
                    ]}
                  >
                    <Input type={"password"} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>

          <Col md={24} xs={24}>
            <Button className="google-btn" htmlType="submit">
              Continue
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default VerifyPasswordModal;
