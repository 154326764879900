import { CloseOutlined } from "@ant-design/icons";
import { Card, Input, Image, message, Table } from "antd";
import React, { useEffect, useState } from "react";
import excel from "../../assets/vector-icons/excel.png";
import search from "../../assets/vector-icons/search.png";
import APIClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
const BASE = process.env.REACT_APP_BASE_URL;
const BankDetails = (props) => {
  const [Details, setDetails] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = Details.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDetails(searchResults);
    } else {
      getAllBankDetails();
    }
  };
  const handleExcel = () => {
    const excelData = Details.map((item) => {
      return {
        S_No: item.SNo,
        BankName: item.BankName,
        AccountNumber: item.AccountNumber,
        BranchName: item.BankName,
        IFSCNumber: item.IFSCNumber,
        Comment: item.Comment,
      };
    });

    props.exportExcel(excelData, "BankDetails");
  };
  useEffect(() => {
    getAllBankDetails();
  }, []);
  const getAllBankDetails = () => {
    APIClient.get("admin/bankDetails")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            let imgUrl = "";
            if (item.Image) {
              imgUrl = BASE + item.Image.substring(1);
            }
            return {
              ...item,
              SNo: index + 1,
              imgUrl: imgUrl,
            };
          });
          setDetails(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const columnsbank = [
    {
      title: "Bank Logo",
      dataIndex: "imgUrl",
      render: (imgUrl) =>
        imgUrl ? (
          <div style={{ maxHeight: "60px" }}>
            <Image style={{ width: "100%", maxHeight: "60px" }} src={imgUrl} />
          </div>
        ) : null,
    },
    {
      title: "Bank Name",
      dataIndex: "BankName",
    },
    {
      title: "Account Number",
      dataIndex: "AccountNumber",
    },
    {
      title: "Branch Name",
      dataIndex: "BranchName",
    },
    {
      title: "IFSC Code",
      dataIndex: "IFSCNumber",
    },
    {
      title: "Comment",
      dataIndex: "Comment",
    },
  ];

  return (
    <div className="manage-markup-section ourteam-bg">
      <div className="admin-container">
        <div className="card-bt-gap">
          <Card bordered={false} className="retrieve-booking">
            <div className="card-add-heading">
              <div className="rows-count">
                <div>
                  <h5>Bank Details</h5>
                </div>
              </div>
              <div className="action-images">
                {showSearchBox && (
                  <Input
                    placeholder="Search"
                    onChange={(e) => searchTableData(e)}
                    suffix={closeSearchInput}
                    style={{ padding: "0px 12px" }}
                  />
                )}
                &nbsp;&nbsp;
                <img src={search} alt="search" onClick={(e) => searchData()} />
                <img src={excel} alt="excel" onClick={handleExcel} />
              </div>
            </div>

            <Table
              scroll={{ x: true }}
              bordered
              dataSource={Details}
              columns={columnsbank}
              pagination={{
                defaultPageSize: 25,
                showSizeChanger: true,
                pageSizeOptions: ["25", "50", "100", "125"],
              }}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default UpdatedComponent(BankDetails);
