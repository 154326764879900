import React, { useState, useEffect } from "react";
import "../Promo/Promo.scss";

import { Button, Card, Col, Input, Row, Form, Select, Table, Layout, message, Modal, Popconfirm, Switch, Radio, Popover } from "antd";

import search from "../assets/vector-icons/search.png";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { PlusCircleFilled, CloseOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import { filter } from "@antv/util";

import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import VerifyPasswordModal from "../../common/VerifyPasswordModal/VerifyPasswordModal";

const { Option } = Select;
const { Content } = Layout;

const PartPayment = (props) => {
  const { user } = useAuthContext();
  const [id, setId] = useState(-1);

  const [modalVisible, setModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [form] = Form.useForm();

  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [formType, setFormType] = useState(-1);
  const [currID, setCurrID] = useState(-1);

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const updatePartPaymentModel = (rec) => {
    setId(rec.ID);

    form.setFieldsValue({
      ...rec,
    });

    setIsEditMode(true);
    showModal();
  };

  const addNewPartPayment = () => {
    setIsEditMode(false);
    form.resetFields();
    showModal();
  };

  const changeStatus = (info, status) => {
    const id = info.ID;
    delete info.SNo;
    delete info.serviceName;
    let obj = {
      status: status,
    };

    updatePartPaymentStatus(obj, id);
  };

  const handelStatus = (data) => {
    if (data.Status == 1) {
      return <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked onChange={() => changeStatus(data, 0)} />;
    } else {
      return <Switch checkedChildren="Active" unCheckedChildren="Inactive" onChange={() => changeStatus(data, 1)} />;
    }
  };

  const tableActions = (rec) => {
    return (
      <div>
        <span className="edit-icon" onClick={() => updatePartPaymentModel(rec)}>
          <i className="fa fa-edit"></i>
        </span>
        &nbsp;&nbsp;&nbsp;
        <Popconfirm
          title="Are u sure to delete?"
          onConfirm={() => {
            setCurrID(rec?.ID);
            setVerifyModalVisible(true);
            setFormType(2);
          }}
        >
          <span className="delete-icon">
            <i className="fa fa-trash"></i>
          </span>
        </Popconfirm>
      </div>
    );
  };

  const getCategories = (ids) => {
    let catArray = [];
    if (ids?.length > 0) {
      ids.forEach((id) => {
        let filteredCat = categoryList.filter((item) => item.categoryId == id);
        if (filteredCat.length > 0) {
          catArray.push(`${filteredCat[0].categoryName} `);
        }
      });
    }
    return <p style={{ minWidth: "100px", marginBottom: "0", whiteSpace: "pre-wrap" }}> {catArray.toString()} </p>;
  };

  const columns = [
    {
      title: "SNo",
      dataIndex: "SNo",
    },
    {
      title: "serviceName",
      dataIndex: "serviceName",
      sorter: (a, b) => a.serviceName.localeCompare(b.serviceName),
    },
    {
      title: "Business Type",
      dataIndex: "BusinessType",
      render: (text, record) => (text == 5 ? "Agent" : "User"),
    },

    {
      title: "PartPayment Percentage",
      dataIndex: "PartPaymentPercentage",
    },
    {
      title: "Full Pay Limit Days",
      dataIndex: "FullPayLimitDays",
    },
    {
      title: "Min Days To Enable PartPay",
      dataIndex: "MinDaysToEnablePartPay",
    },
    // {
    //   title: "Category",
    //   dataIndex: "CategoryID",
    //   render: (val) => getCategories(val),
    // },

    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },

    {
      title: "Status",
      key: "category",
      render: (currentItem) => handelStatus(currentItem),
    },
  ];

  /* =============== API calls ================*/

  useEffect(() => {
    getPartPayment();
    getActivitiesCategory();
  }, []);

  const getActivitiesCategory = () => {
    ApiClient.get(`extranet/categories`)
      .then((resp) => {
        if (resp.status == 200) {
          setCategoryList(resp.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // api call to get all avaialble promo Codes
  const getPartPayment = () => {
    ApiClient.get("admin/getpartPayment").then((res) => {
      if (res.status == 200) {
        let data = res.data.map((item, index) => {
          let catId = item?.CategoryID
            ? item.CategoryID.split(",")
                .map((item) => {
                  if (item) {
                    return parseInt(item);
                  }
                })
                .filter((item) => item)
            : [];
          return {
            ...item,
            SNo: index + 1,
            CategoryID: catId,
          };
        });

        setDataSource(data);
      } else {
        setDataSource([]);
      }
    });
  };

  // Delete specific promo Codes
  const deletePartPayment = (id) => {
    ApiClient.delete("admin/deletepartPayment/" + id).then((res) => {
      if (res.status == 200) {
        message.success("Record Deleted  successfully", 3);
        getPartPayment();
      }
    });
  };

  const addPartPayment = (data) => {
    ApiClient.post("admin/createpartPayment", data).then((res) => {
      if (res.status == 200) {
        message.success("Record added successfully", 3);
        setModalVisible(false);
        form.resetFields();
        getPartPayment();
      } else if (res.status == 400) {
        message.error(res.message, 3);
      } else {
        message.error(res.message, 3);
      }
    });
  };

  const updatePartPayment = (data, id) => {
    ApiClient.put("admin/updatepartPayment/" + id, {}, data).then((res) => {
      if (res.status == 200) {
        message.success("Record Updated  successfully", 3);
        setModalVisible(false);
        //setFormData(initialFormData);
        getPartPayment();
      } else if (res.status == 400) {
        message.error(res.message, 3);
      } else {
        message.error(res.message, 3);
      }
    });
  };

  const updatePartPaymentStatus = (data, id) => {
    ApiClient.put("admin/updatePartPaymentStatus/" + id, {}, data).then((res) => {
      if (res.status == 200) {
        message.success(res.message, 3);
        setModalVisible(false);
        //setFormData(initialFormData);
        getPartPayment();
      } else if (res.status == 400) {
        message.error(res.message, 3);
      } else {
        message.error(res.message, 3);
      }
    });
  };

  const submitFormData = (formvalues) => {
    let catId = "";
    if (formvalues?.CategoryID?.length > 0) {
      catId = formvalues.CategoryID.toString();
    }
    const data = {
      ...formvalues,
      BusinessType: 2,
      CategoryID: catId,
      CreatedBy: user?.UserID ?? 1,
      ModifiedBy: user?.UserID ?? 1,
    };

    if (isEditMode) {
      updatePartPayment(data, id);
    } else {
      addPartPayment(data);
    }
  };

  /*======= Search and Export Options=====*/

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = dataSource.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item).toLowerCase().includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      getPartPayment();
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );
  const handleVerifyPassword = (values) => {
    ApiClient.post("admin/verifyTransactionPassword", {
      UserID: user.UserID,
      Password: values,
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          message.success(res.message);

          if (formType == 1) {
            form.submit();
          } else if (formType == 2) {
            deletePartPayment(currID);
          }
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    {/* <div className="table-heading-count"> */}
                    <div className="rows-count">
                      <div>
                        <h5>
                          View PartPayment <HelpInfoHelper screenName={"/admin/partpayment"} />
                        </h5>
                        <p>{dataSource.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && <Input placeholder="Search" onChange={(e) => searchTableData(e)} suffix={closeSearchInput} style={{ padding: "0px 12px" }} />}
                      &nbsp;&nbsp;
                      <img src={search} alt="search" onClick={(e) => searchData(dataSource)} />
                      <p
                        className="add-deposit-icon"
                        onClick={() => {
                          addNewPartPayment();
                        }}
                      >
                        <PlusCircleFilled />
                      </p>
                    </div>
                    {/* </div> */}
                  </div>

                  <div>
                    <Table
                      className="table-scroll-none"
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title="Part Payment"
        className="promo-modal-header modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <>
            <Button
              type="primary"
              onClick={() => {
                setVerifyModalVisible(true);
                setFormType(1);
              }}
            >
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button
              type="primary"
              danger
              className="cancel-btn"
              onClick={(e) => {
                setModalVisible(false);
              }}
            >
              Cancel
            </Button>
          </>,
        ]}
        width={"1100px"}
      >
        <Form
          layout="vertical"
          initialValues={{
            FullPayLimitDays: 0,
            MinDaysToEnablePartPay: 0,
            BusinessType: 2,
          }}
          form={form}
          onFinish={submitFormData}
        >
          <Row gutter={16}>
            <Col md={6} sm={8} xs={24}>
              <Form.Item label="Service" name="ServiceType" rules={[{ required: true, message: "Service is required" }]}>
                <Select placeholder="Select Service Type">
                  <Option value={4}>Activities</Option>
                  <Option value={5}>BuildPackage</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={6} sm={8} xs={24}>
              <Form.Item label="Business Type" name="BusinessType" rules={[{ required: true, message: "Business Type is required" }]}>
                <Select placeholder="Select Business Type" disabled>
                  <Option value={2}>user</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={6} sm={8} xs={24}>
              <Form.Item label="PartPayment Percentage" name="PartPaymentPercentage" rules={[{ required: true, message: " Amount is required" }]}>
                <Input type="number" min={0} />
              </Form.Item>
            </Col>

            <Col md={6} sm={8} xs={24}>
              <Form.Item label="Full Pay Limit Days" name="FullPayLimitDays" rules={[{ required: true, message: "Required" }]}>
                <Input type="number" min={0} />
              </Form.Item>
            </Col>
            <Col md={6} sm={8} xs={24}>
              <Form.Item label="Min Days To Enable PartPay" name="MinDaysToEnablePartPay" rules={[{ required: true, message: "Required" }]}>
                <Input type="number" min={0} />
              </Form.Item>
            </Col>
            <Col md={6} sm={8} xs={24}>
              <Form.Item label="Category" name="CategoryID" rules={[{ required: true, message: "Required" }]}>
                <Select mode="multiple" allowClear placeholder="Please Select">
                  {categoryList.map((item) => (
                    <Option key={item.categoryId} value={item.categoryId}>
                      {item.categoryName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col md={6} sm={8} xs={24}>
              <Form.Item label="Status" name="Status" rules={[{ required: true, message: "Required" }]}>
                <Radio.Group>
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {verifyModalVisible && <VerifyPasswordModal visible={verifyModalVisible} setVerifyModalVisible={setVerifyModalVisible} handleVerifyPassword={handleVerifyPassword} />}
    </div>
  );
};
export default UpdatedComponent(PartPayment);
