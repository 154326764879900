import React, { useEffect, useState } from "react";
import "./CmsTermsOffers.scss";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";

import * as ANTD from "antd";
import {
  PlusOutlined,
  EditOutlined,
  IdcardOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Modal } from "antd";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";

const CmsTermsOffers = (props) => {
  const { Content } = ANTD.Layout;
  const { Option } = ANTD.Select;
  const [modalVisible, setModalVisible] = React.useState(false);
  const [formLayout, setFormLayout] = React.useState("vertical");
  const [OperatorsForm] = ANTD.Form.useForm();
  const [operators, setOperators] = useState({
    allData: [],
    busOperators: [],
    flightOperators: [],
  });
  const [cmsList, setCmsList] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [currID, setCurrID] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [inputBox, setInputBox] = useState(-1);
  const [excelList, setExcelList] = useState([]);

  useEffect(() => {
    GetOperators();
    GetCms();
  }, []);

  const GetOperators = () => {
    ApiClient.get("admin/operators")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          let busOperators = data.filter((item) => item.ServiceType === 3);
          let flightOperators = data.filter((item) => item.ServiceType === 1);
          setOperators((prev) => ({
            allData: [...prev.allData, ...res.data],
            busOperators: [...prev.busOperators, ...busOperators],
            flightOperators: [...prev.flightOperators, ...flightOperators],
          }));
        }
      })
      .catch((e) => {});
  };
  const GetCms = () => {
    ApiClient.get("admin/cmsTermsOffers")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          // let busOperators = data.filter((item) => item.ServiceType === 3);
          // let flightOperators = data.filter((item) => item.ServiceType === 1);
          setCmsList(data);
          defineExcelData(data);
        }
      })
      .catch((e) => {});
  };

  const submitForm = (values) => {
    let operatorName = "";
    let operatorId = 0;
    if (values.ServiceType === 2) {
      operatorName = values.OperatorName;
    } else {
      let opr = operators.allData.filter(
        (item) => item.OperatorServiceID === values.OperatorID
      );
      operatorName = opr[0].OperatorName;
      operatorId = values.OperatorID;
    }

    let data = {
      ServiceType: values.ServiceType,
      OperatorID: operatorId,
      OperatorName: operatorName,
      TermsAndConditions: values.TermsAndConditions,
      OfferInfo: values.OfferInfo,
      DeviceOS: 0,
      ApplicationType: 0,
      CreatedBy: 0,
      DeviceOSVersion: "",
      DeviceToken: "",
      FirBaseToken: "",
    };

    isEdit ? UpdateOperator(data) : AddOperators(data);
  };

  const AddOperators = (data) => {
    ApiClient.post("admin/cmsTermsOffer", data)
      .then((response) => {
        if (response.status == 200) {
          ANTD.message.success("CmsTermsOffer added successfully !");
          GetOperators();
          GetCms();
          setModalVisible(false);
        } else if (response.status == 404) {
          ANTD.message.error("Not Found", 3);
        } else {
          ANTD.message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateOperator = (data) => {
    ApiClient.put("admin/cmsTermsOffer/" + currID, {}, data)
      .then((response) => {
        if (response.status == 200) {
          ANTD.message.success("Operator added successfully !");
          GetOperators();
          GetCms();
          setModalVisible(false);
        } else if (response.status == 404) {
          ANTD.message.error("Not Found", 3);
        } else {
          ANTD.message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const DeleteCms = (id) => {
    ApiClient.delete("admin/cmsTermsOffer/" + id)
      .then((response) => {
        if (response.status == 200) {
          ANTD.message.success("Operator Deleted successfully !");
          GetCms();
        } else {
          ANTD.message.error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 23 },
        }
      : null;

  const add = () => {
    ANTD.message.success("Buses Markup Added Successfully...", 3);
    setModalVisible(false);
  };
  const clear = () => {
    setModalVisible(false);
  };

  const showModal = () => {
    if (isEdit) {
      setInputBox(-1);
      setIsEdit(false);

      OperatorsForm.resetFields();
    }
    setModalVisible({
      visible: true,
    });
  };

  const closeModal = () => {
    setModalVisible({
      visible: false,
    });
  };

  const updateCms = (currentData) => {
    setIsEdit(true);
    setModalVisible(true);
    setCurrID(currentData.CMSTermsOfferID);
    setInputBox(currentData.ServiceType);
    OperatorsForm.setFieldsValue({
      ServiceType: currentData.ServiceType,
      TermsAndConditions: currentData.TermsAndConditions,
      OfferInfo: currentData.OfferInfo,
      OperatorID: currentData.OperatorID,
      OperatorName: currentData.OperatorName,
    });
  };
  const getServiceName = (data) => {
    let roleName = data === 1 ? "Flight" : data === 2 ? "Hotels" : "Bus";

    return roleName;
  };
  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        ServiceType: getServiceName(item.ServiceType),
        TermsAndConditions: item.TermsAndConditions,
        OperatorName: item.OperatorName,
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const tableActions = (currentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}
          onClick={() => updateCms(currentItem)}
        >
          <EditOutlined
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => DeleteCms(currentItem.CMSTermsOfferID)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };
  const columnsserviceoper = [
    {
      title: "Service Name",
      dataIndex: "ServiceType",
      render: (currentItem) => getServiceName(currentItem),
      sorter: (a, b) => a.ServiceType - b.ServiceType,
    },
    {
      title: "Operator Name",
      dataIndex: "OperatorName",
    },
    {
      title: "Terms  conditions & offers",
      dataIndex: "TermsAndConditions",
    },

    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = cmsList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setCmsList(searchResults);
    } else {
      GetCms();
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  const handleExcel = () => {
    const excelData = operators.map((item) => {
      return {
        ...item,
        ServiceType: getName(item.ServiceType),
      };
    });

    props.exportExcel(excelData, "OperatorsDetails");
  };

  const getName = (id) => {
    switch (id) {
      case 3:
        return "Bus";
      case 2:
        return "Hotels";
      case 1:
        return "Flight";
      default:
        break;
    }
  };

  const handleServiceType = (val) => {
    setInputBox(val);
  };
  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section">
          <Content className="admin-container">
            <ANTD.Row>
              <ANTD.Col span={24}>
                <div className="card-bt-gap">
                  <ANTD.Card bordered={false}>
                    <div className="card-add-heading">
                      <div className="rows-count">
                        <div>
                          <h5>Add Cms Terms Offers</h5>
                          <p>{cmsList.length} rows found !</p>
                        </div>
                      </div>
                      <div className="action-images">
                        {showSearchBox && (
                          <ANTD.Input
                            placeholder="Search"
                            onChange={(e) => searchTableData(e)}
                            suffix={closeSearchInput}
                            style={{ padding: "0px 12px" }}
                          />
                        )}
                        &nbsp;&nbsp;
                        <img
                          src={search}
                          alt="search"
                          onClick={(e) => searchData()}
                        />
                        <img
                          src={excel}
                          alt="excel"
                          // onClick={handleExcel}
                          onClick={(e) =>
                            props.exportExcel(excelList, "CMS Terms & Offers")
                          }
                        />
                        {/*  <img src={exchange} alt="exchange" /> */}
                        <p className="add-icon" onClick={showModal}>
                          <PlusOutlined />
                        </p>
                      </div>
                      {/* </div> */}
                    </div>

                    <div>
                      <ANTD.Table
                        className="table-scroll-none"
                        bordered
                        dataSource={cmsList}
                        columns={columnsserviceoper}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  </ANTD.Card>
                </div>
              </ANTD.Col>
            </ANTD.Row>
          </Content>
        </div>

        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>
                  {isEdit ? "Update Cms Terms Offers" : "Add Cms Terms Offers"}
                </strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header admin-bc-level modal-header-bg12"
          visible={modalVisible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          footer={[
            <div>
              <ANTD.Button
                htmlType="submit"
                type="primary"
                onClick={OperatorsForm.submit}
              >
                {isEdit ? "Update" : "Add"}
              </ANTD.Button>
              <ANTD.Button
                type="danger"
                onClick={() => OperatorsForm.resetFields()}
              >
                Clear
              </ANTD.Button>
            </div>,
          ]}
        >
          <ANTD.Form
            {...formItemLayout}
            layout={formLayout}
            initialValues={{ layout: formLayout }}
            form={OperatorsForm}
            onFinish={submitForm}
          >
            <ANTD.Row>
              <ANTD.Col md={24} xs={24}>
                <ANTD.Form.Item
                  label="Service List"
                  rules={[{ required: true }]}
                  name="ServiceType"
                >
                  <ANTD.Select
                    size={"large"}
                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                    placeholder="Please Select"
                    onSelect={handleServiceType}
                  >
                    <Option value={1}>Flight</Option>
                    <Option value={2}>Hotel</Option>
                    <Option value={3}>Bus</Option>
                  </ANTD.Select>
                </ANTD.Form.Item>
              </ANTD.Col>
              {inputBox === 3 ? (
                <>
                  <ANTD.Col md={24} xs={24}>
                    {/* <ANTD.Form.Item
                      label="Operator Name"
                      name="OperatorName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Operator Name",
                        },
                      ]}
                    >
                      <ANTD.Input placeholder="Operator Name" size={"large"} />
                    </ANTD.Form.Item> */}
                    <ANTD.Form.Item
                      label="Operator Name"
                      rules={[{ required: true }]}
                      name="OperatorID"
                    >
                      <ANTD.Select size={"large"} placeholder="Please Select">
                        {operators.busOperators.map((item, index) => {
                          return (
                            <Option key={index} value={item.OperatorServiceID}>
                              {item.OperatorName}
                            </Option>
                          );
                        })}
                      </ANTD.Select>
                    </ANTD.Form.Item>
                  </ANTD.Col>
                </>
              ) : null}
              {inputBox === 2 ? (
                <ANTD.Col md={24} xs={24}>
                  <ANTD.Form.Item
                    label="Hotel Name"
                    name="OperatorName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Hotel Name",
                      },
                    ]}
                  >
                    <ANTD.Input placeholder="Hotel Name" size={"large"} />
                  </ANTD.Form.Item>
                </ANTD.Col>
              ) : null}
              {inputBox === 1 ? (
                <ANTD.Col md={24} xs={24}>
                  <ANTD.Form.Item
                    label="Operator Name"
                    rules={[{ required: true }]}
                    name="OperatorID"
                  >
                    <ANTD.Select size={"large"} placeholder="Please Select">
                      {operators.flightOperators.map((item, index) => {
                        return (
                          <Option key={index} value={item.OperatorServiceID}>
                            {item.OperatorName}
                          </Option>
                        );
                      })}
                    </ANTD.Select>
                  </ANTD.Form.Item>
                </ANTD.Col>
              ) : null}

              <ANTD.Col md={24} xs={24}>
                <ANTD.Form.Item
                  label="Terms And Conditions"
                  name="TermsAndConditions"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Terms And Conditions",
                    },
                  ]}
                >
                  <ANTD.Input.TextArea rows={4} />
                </ANTD.Form.Item>
              </ANTD.Col>
              <ANTD.Col md={24} xs={24}>
                <ANTD.Form.Item
                  label="Offer Info"
                  name="OfferInfo"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Offer Info",
                    },
                  ]}
                >
                  <ANTD.Input.TextArea rows={4} />
                </ANTD.Form.Item>
              </ANTD.Col>
            </ANTD.Row>
          </ANTD.Form>
        </Modal>
      </div>
    </div>
  );
};

export default UpdatedComponent(CmsTermsOffers);
