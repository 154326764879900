

import React, { useState, useEffect } from "react"
import { Button, Card, Col, Form, Modal, Row, Layout, Table, Popconfirm, Switch, Select,Collapse, message, InputNumber } from "antd";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
import search from "../assets/vector-icons/search.png";
import { DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import { PaxType, TripIndicator, FareTag, JourneyTypeEnum, FlightCabinClass } from "./FlightExtranetHelper"
//import moment from "moment";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const FlightExtranetFareDetails = () => {
    const history = useHistory();
    const { Option } = Select;
    const { Panel } = Collapse;
    const { Content } = Layout;
    const [form] = Form.useForm()
    const [isModalVisibal, setIsModalVisibal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [currentId, setCurrentId] = useState(0)
    const { user } = useAuthContext();
    //const dateFormat = "DD-MM-YYYY"
    const [dataSource, setDataSource] = useState([])
    const [flightSearchCriteria, setFlightSearchCriteria] = useState(null)


    useEffect(() => {
        getFlightFareDetails()
        const localStorageResp = localStorage.getItem("FlightExtranet_SearchCriteria")
        setFlightSearchCriteria(JSON.parse(localStorageResp))

    }, [])



    const handleAddClick = () => {
        form.resetFields();
        setIsModalVisibal(true);
        setIsEdit(false);
    };

    const handleEditClick = (rec) => {
        form.setFieldsValue({
            ...rec,
        })
        setIsEdit(true)
        setIsModalVisibal(true)
    }

    const tableActions = (rec) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <EditOutlined
                        onClick={() => {
                            handleEditClick(rec);
                            setCurrentId(rec.FareId);
                        }}
                        style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => deleteFlightFareDetails(rec.FareId)}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </Popconfirm>
                </div>
            </div>
        );
    };

    const handelStatus = (data) => {
        if (data.Status === 1) {
            return (
                <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    defaultChecked
                    onChange={() => changeStatus(data, 0)}
                />
            );
        } else {
            return (
                <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    onChange={() => changeStatus(data, 1)}
                />
            );
        }
    };

    const changeStatus = (info) => {

    }

    const handelFormSubmit = (value) => {

        const reqBody = {
            TripIndicator: value?.TripIndicator ?? 1,
            PaxType: value.PaxType,
            FareTag: value.FareTag,
            FlightId: flightSearchCriteria?.FlightId ?? 0,
            Amount: value.Amount ?? 0,
            Status: 1
        }

        if (isEdit) {
            reqBody["ModifiedBy"] = user?.UserID ?? 1
            updateFlightFareDetails(reqBody)
        } else {
            reqBody["CreatedBy"] = user?.UserID ?? 1
            reqBody["ModifiedBy"] = user?.UserID ?? 1
            createFlightFareDetails(reqBody)
        }

    }

    //#region API CALLING



    const getFlightFareDetails = () => {
        ApiClient.get("flight-faredetails/list").then((response) => {
            if (response.statusCode === 200) {
                const data = response.data
                setDataSource(data)
            }
        }).catch((e) => message.error(e.message))
    }

    const createFlightFareDetails = (reqBody) => {
        ApiClient.post("create/flight-faredetails", reqBody).then(
            (response) => {
                if (response.statusCode === 200) {
                    setIsModalVisibal(false);
                    message.success(response.message);
                    getFlightFareDetails();
                    Modal.confirm({
                        title: 'Fares Created successfully!!',
                        content: 'Please click OK to manage Fare Rules for this Created Fares',
                        onOk() {
                            history.push('/admin/flight-extranet/fare-rules')
                        },
                    })
                } else {
                    message.error(response.message);
                }
            }
        );
    }

    const updateFlightFareDetails = (reqBody) => {
        ApiClient.put(
            "update/flight-faredetails/" + currentId,
            {},
            reqBody
        ).then((response) => {
            if (response.statusCode === 200) {
                setIsModalVisibal(false);
                message.success(response.message);
                getFlightFareDetails();
                Modal.confirm({
                    title: 'Fares Updated successfully!!',
                    content: 'Please click OK to manage Fare Rules for this Upadated Fares',
                    onOk() {
                        history.push('/admin/flight-extranet/fare-rules')
                    },
                })
            } else {
                message.error("Error Updating");
            }
        }).catch((error) => message.error(error.message));
    }

    const deleteFlightFareDetails = (fareId) => {
        ApiClient.delete("delete/flight-faredetails/" + fareId).then(
            (res) => {
                if (res.statusCode === 200) {
                    message.success("Deleted  successfully", 3);
                    getFlightFareDetails();
                } else message.error(res.message);
            }
        ).catch((er) => message.error(er.message));
    }

    //#endregion API CALLING


    const columns = [
        {
            title: "Actions",
            render: (rec) => {
                return tableActions(rec);
            },
        },
        {
            title: "JourneyType",
            //dataIndex: "JourneyType",
            render: (jt) => Object.keys(JourneyTypeEnum).find(key => JourneyTypeEnum[key] === jt?.FlightSearchCriteria?.JourneyType)
        },
        {
            title: "Origin",
            // dataIndex: "Origin",
            ellipsis: true,
            render: (item) => item?.FlightSearchCriteria?.Origin ?? ""
        },
        {
            title: "Destination",
            // dataIndex: "Destination",
            ellipsis: true,
            render: (item) => item?.FlightSearchCriteria?.Destination ?? ""
        },
        {
            title: "JourneyDate",
            // dataIndex: "JourneyDate",,
            render: (item) => item?.FlightSearchCriteria?.JourneyDate ?? ""
        },

        {
            title: "CabinClass",
            // dataIndex: "CabinClass",
            ellipsis: true,
            render: (cc) => Object.keys(FlightCabinClass).find(key => FlightCabinClass[key] === cc?.FlightSearchCriteria?.CabinClass)
        },

        {
            title: "ReturnDate",
            // dataIndex: "ReturnDate",
            render: (item) => item?.FlightSearchCriteria?.ReturnDate ?? "NA"
        },

        {
            title: "TripIndicator",
            dataIndex: "TripIndicator",
            render: (tp) => Object.keys(TripIndicator).find(key => TripIndicator[key] === tp)
        },
        {
            title: "PaxType",
            dataIndex: "PaxType",
            render: (pt) => Object.keys(PaxType).find(key => PaxType[key] === pt)
        },

        {
            title: "FareTag",
            dataIndex: "FareTag",
            render: (ft) => Object.keys(FareTag).find(key => FareTag[key] === ft)
        },
        {
            title: "Amount",
            dataIndex: "Amount",
        },

        {
            title: "Status",
            render: (currentItem) => handelStatus(currentItem),
        },
    ]


    return (<div>
        <div>
            <Content>
                <Row>
                    <Col span={24}>
                        <div>               <div className="navigationmain-box" >
                            <a className="navigation-color" href="/admin/flight-extranet/search-criteria">Route</a><i class="arrow right"></i>
                            <a className="navigation-color" href="/admin/flight-extranet/segments">Segments</a><i class="arrow right"></i>
                            <a className="navigation-color" style={{ color: "orange" }}>Fares</a><i class="arrow right"></i>
                            <a className="navigation-color" href="/admin/flight-extranet/fare-rules">Fare Rules</a><i class="arrow right"></i>
                            <a className="navigation-color" href="/admin/flight-extranet/pnr">Pnr</a><i class="arrow right"></i>

                        </div>
                            <Card bordered={false}>
                                <div>
                                    <h5>
                                        Flights FareDetails{" "}
                                        <HelpInfoHelper screenName={"/admin/cartypes"} />
                                    </h5>
                                </div>
                                <div className="action-images">
                                    <img src={search} alt="search" />
                                    <img src={excel} alt="excel" />
                                    <img src={pdf} alt="pdf" />
                                    <p className="add-plus-icon" onClick={handleAddClick}>
                                        <PlusCircleFilled />
                                    </p>
                                </div>
                                {dataSource.length > 0 ? (

                                    <Collapse
                                        defaultActiveKey={["1"]}
                                        accordion
                                        expandIconPosition={"right"}
                                    >
                                        {dataSource.map((item, index) => {
                                            return (<Panel header={`${item.FlightType}: ${item.Origin} To ${item.Destination}`} key={index + "panel"}>
                                                {item.FareDetails.length > 0 ? (<div>
                                                    <Table
                                                        className="table-scroll-none"
                                                        bordered
                                                        dataSource={item.FareDetails}
                                                        columns={columns}
                                                        pagination={{
                                                            defaultPageSize: 25,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ["25", "50", "100", "125"],
                                                        }}
                                                    />
                                                </div>) : (<b>No record Found</b>)}
                                            </Panel>)
                                        })}
                                    </Collapse>

                                ) : (
                                    <b>No record Found</b>
                                )}
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Content>
        </div>
        <Modal
            title={[
                <div>
                    <h6><strong>{isEdit ? "Update FareDetails" : "Add FareDetails"}</strong></h6>
                </div>
            ]}
            onCancel={(e) => setIsModalVisibal(false)}
            onOk={(e) => setIsModalVisibal(false)}
            visible={isModalVisibal}
            footer={[
                <div>
                    <Button htmlType="submit" type="primary" onClick={() => form.submit()}>
                        {isEdit ? "Update" : "Add"}
                    </Button>
                    <Button onClick={() => form.resetFields()}>
                        Clear
                    </Button>
                </div>
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={handelFormSubmit}
            >
                <Row gutter={16} >
                    {flightSearchCriteria?.JourneyType === 2 && (
                        <Col xs={24} md={8}>
                            <Form.Item
                                label="Trip Indicator"
                                name="TripIndicator"
                            >
                                <Select
                                >
                                    <Option value={1}>Onward</Option>
                                    <Option value={2}>Return</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    )}

                    <Col xs={24} md={8}>
                        <Form.Item
                            label="Pax Type"
                            placeholder="pax type"
                            name="PaxType"
                            rules={[{ required: true }]}
                        >
                            <Select
                            >
                                <Option value="ADT">Adult</Option>
                                <Option value="CHD">Child </Option>
                                <Option value="INF">Infant</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item
                            label="Fare Tag"
                            placeholder="Fare Tag"
                            name="FareTag"
                            rules={[{ required: true }]}
                        >
                            <Select
                            >
                                {
                                    Object.keys(FareTag).map((key) => (
                                        <Option value={FareTag[key]}>{key}</Option>
                                    ))
                                }

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Amount"
                            name="Amount"
                            required={[
                                { required: true, message: "Amount is required" }
                            ]}
                        >
                            <InputNumber min={0} defaultValue={0} />
                        </Form.Item>
                    </Col>

                </Row>

            </Form>

        </Modal>
    </div >)
}

export default FlightExtranetFareDetails