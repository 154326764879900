import React, { useState, useEffect } from 'react';
import { Button } from 'antd'; // or any UI library you're using
import { UpOutlined } from '@ant-design/icons'; // Example using Ant Design

const ScrollToTopButton = () => {
    const [visible, setVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 350) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    return (
        
        <div className="scroll-to-top">
            {visible && (
                <Button
                    type="primary"
                    shape="circle"
                    icon={<UpOutlined />}
                    onClick={scrollToTop}
                    style={scrollButtonStyle}
                >
                    Scroll to Top
                </Button>
            )}
        </div>
    );
};

const scrollButtonStyle = {
    position: 'fixed',
    bottom: '50px',
    right: '50px',
    zIndex: 1000,
};

export default ScrollToTopButton;
