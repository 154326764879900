import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Radio,
  message,
  Space,
} from "antd";
import React, { useContext, useState, useReducer ,useEffect} from "react";
import { GlobalStatesContext } from "../../../common/providers";
import PaymentComponent from "../../../helpers/CCavanuePayCheckout";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import {
  ArrowLeftOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";
import VisaDetailsCard from "../VisaCheckout/visaDetailsCard";
import VisaBookPayCard, { getVisaGrandTotal } from "./VisaBookPayCard";
import { Link, useHistory } from "react-router-dom";
import "./VisaReview.scss";

import moment from "moment";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import ApiClient from "../../../helpers/ApiClient";
import VisaBookingPayCard from "../VisaCheckout/VisaBookingPayCard";
const PGTYPE = "PGTYPE";
const PGDISPLAY = "PGDISPLAY";
const PGDATA = "PGDATA";

const dateFormat = "DD-MM-YYYY";
const oriDateFormat = "YYYY-MM-DD";

const initialState = { pgDisplay: false, pgData: {}, pgType: -1 };

function reducer(state, action) {
  switch (action.type) {
    case PGTYPE:
      return { ...state, pgType: action.payload };
    case PGDISPLAY:
      return { ...state, pgDisplay: action.payload };
    case PGDATA:
      return { ...state, pgData: action.payload };
    default:
      return state;
  }
}


const { Option } = Select;

const VisaReview = () => {
  const [reviewForm] = Form.useForm();
  const history = useHistory();
  const { user } = useAuthContext();
  const [loadingSpin, setLoadingSpin] = useState(false);
  const {
    state: {
      visaBookingData,
      otherData: { ConvFee, promoData, selectedInsuranceData },
    },
  } = useContext(GlobalStatesContext);
  const [pgDetails, dispatchPgDetails] = useReducer(reducer, initialState);
  const [pgData, setPgData] = useState({});
  const [openCCavanueCheckout, setOpenCCavanueCheckout] = useState(false);
  const { activeCurrency, currencyValue ,currencyRatio} = useCurrencyContext();
  let {
    totalBaseFare,
    totalGst,
    discount,
    convamount,
    totalFareCalculations,
    totalServiceAmount,
    insuranceTotal,
  } = getVisaGrandTotal(
    visaBookingData,
    ConvFee,
    promoData,

    visaBookingData.passengers.length
  );
  
  
  

  const ccavanueCheckoutData = (resp) => {
    setPgData(resp)
    setOpenCCavanueCheckout(true)
  }

  const [currencies, setCurrencies] = useState({ INR: 1 });

  useEffect(() => {
    ApiClient.get("admin/currencyConversionLatest")
      .then((resp) => {
       
        if (resp?.status == 200) {
          setCurrencies(resp.data);
        } else {
          
        }
      })
      .catch((e) => {
       
      });
  }, []);



  const submitform = (pgType) => {
    let blockObj = {
      visaId: visaBookingData.Id,
      visaName: visaBookingData.VisaName,
      customerEmail: visaBookingData?.passengers[0].email,
      customerMobile: visaBookingData?.passengers[0].mobile,
      customerFirstName: visaBookingData?.passengers[0].firstName,
      customerLastName: visaBookingData?.passengers[0].lastName,
      customerAddress: "",
      customerCity: "",
      customerState: "",
      customerCountry: "",
      customerZip: "",
      customerDOB: visaBookingData?.passengers[0].dob,
      customerGender: "",
      customerNationality: visaBookingData?.passengers[0].nationality,
      passportNumber: visaBookingData?.passengers[0].passportNumber,
      totalAmount: ((totalFareCalculations)),
      currency : user?.Currency ? user?.Currency : activeCurrency || "INR",
      currencyRatio: user?.Role?.RoleId === 5 ? Number(currencies[user?.Currency]) : Number(currencyRatio),
      
      CollectedAmount: ((totalFareCalculations)),
      PromoCode:promoData.Code,
      userId: user ? user.UserID : 1,
      roleId: user ? user.Role.RoleId : 4,
      
      pgType: pgType,
      passengers: visaBookingData.passengers,
    };
   
    ApiClient.post("visa/visaBlock", blockObj)
      .then((res) => {
        
        setLoadingSpin(false);

        if (res.status === 200) {
          if (res?.data?.pgType == 1) {
            
             ccavanueCheckoutData(res.data.payment_link)
           
         }
          else {
         
            window.location.href = res?.data?.payment_link;
          }
        }
        else {
          if (res?.message) message.error(res.message, 3);
          else if (res?.data[0]?.errorDetail)
            message.error(res?.data[0]?.errorDetail, 3);
          else message.error("Failed", 3);
        }
      })
      .catch((err) => {
        
        setLoadingSpin(false);
        
      });
  };

  const processPayGateway = () => {
    dispatchPgDetails({ type: PGDISPLAY, payload: true });
  };

  const blockApiReq = (pgType) => {

    dispatchPgDetails({ type: PGTYPE, payload: pgType });
   
    if (pgType) {
     
      submitform(pgType)
      
    }
  };

  return (
    <div className="buscheckout_bg">
      <div className="checkout-heading">
        <div style={{ marginTop: "65px" }} fluid className="checkout-heading-container">
          <div className="goback">
            <Link
              onClick={() => {
                history.go(-1);
              }}
            >
              <ArrowLeftOutlined />
              <span>Go back and Select Other Service</span>
            </Link>
          </div>
          <h3>Fill the Form Below for Visa, and Book right now!</h3>
        </div>
      </div>
      <div className="visa-Review">
        <Row gutter={18}>
          <Col md={24}>
            <Form
              form={reviewForm}
              layout="vertical"
              scrollToFirstError={true}
              onFinish={submitform}
            >
              <Row gutter={18}>
                <Col className="car-checkout-detail-part">
                  <div className="left_wrapper">
                    <h3 className="title"> Visa Details</h3>
                    <VisaDetailsCard visaBookingData={visaBookingData} />{" "}
                  </div>

                  <div className="pax-info-wrapper">
                    <h3 className="title" style={{ color: "#bd0c21" }}> Passenger Detail's</h3>
                    <Card>
                      <div className="pax-info-visa">
                        {visaBookingData.passengers
                          ? visaBookingData.passengers.map((item, index) => (
                            <Row key={"paxINdex" + index} gutter={8}>
                              <Col md={24}>
                                <p className="hdr-pax font-weight-bold mb-1">
                                  Passenger Details {index + 1}
                                </p>
                              </Col>

                              <Col md={4} sm={12} xs={24}>
                                <Form.Item
                                  label="Title"
                                  rules={[
                                    { required: true, message: "Required" },
                                  ]}
                                >
                                  <Select
                                    size="large"
                                    className="inputbg"
                                    value={item.title}
                                    disabled
                                  >
                                    <Option value="Mr">Mr</Option>
                                    <Option value="Ms">Ms</Option>
                                    <Option value="Mrs">Mrs</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col md={10} sm={12} xs={24}>
                                <Form.Item
                                  label="First Name"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    value={item.firstName}
                                    disabled
                                    className="inputbg"
                                    autoComplete="off"
                                    onBlur={(e) => { }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={10} sm={12} xs={24}>
                                <Form.Item
                                  label="Last Name"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    disabled
                                    value={item.lastName}
                                    className="inputbg"
                                    autoComplete="off"
                                    onBlur={(e) => { }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="DOB"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    size="large"
                                    value={moment(item.dob)}
                                    disabled
                                    format={"DD-MM-YYYY"}
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Passport Number"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    value={item.passportNumber}
                                    disabled
                                    className="inputbg"
                                    autoComplete="off"
                                    onBlur={(e) => { }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="Nationality"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    value={item.nationality}
                                    disabled
                                    className="inputbg"
                                    autoComplete="off"
                                    onBlur={(e) => { }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col md={24}>
                                <Row className="uploadimg">
                                
                                  <Col md={8}>{visaBookingData?.passengers[0]?.uploadDocument?.passport
                                    ? <p className="uplod-p">Passport : {" "}
                                      <span className="uplod-span">
                                        <i class="fa fa-check-square-o" aria-hidden="true"></i>{" "}
                                        Passport Updated</span>
                                    </p> : null}</Col>
                                  <Col md={8}>{visaBookingData?.passengers[0]?.uploadDocument?.photo
                                    ? <p className="uplod-p">PhotoCopy : {" "}
                                      <span className="uplod-span">
                                        <i class="fa fa-check-square-o" aria-hidden="true"></i>{" "}
                                        Photo Updated</span>
                                    </p> : null}</Col>
                                  <Col md={8}>{visaBookingData?.passengers[0]?.uploadDocument?.pan
                                    ? <p className="uplod-p">PanCard : {" "}<span className="uplod-span">
                                      <i class="fa fa-check-square-o" aria-hidden="true"></i>{" "}
                                      PanCard Updated</span>
                                    </p> : null}</Col>
                                </Row>

                              </Col>
                            </Row>
                          ))
                          : ""}
                      </div>
                    </Card>
                  </div>
                  <div className="choose-pay-visa">

                  </div>
                </Col>
                <Col className="car-checkout-price-part">
                  <Card style={{ marginTop: "30px" }}>
                    <div className="right_wrapper">
                      <h3 className="title1"> Fare Details</h3>
                      <VisaBookPayCard
                        visaBookingData={visaBookingData}
                        isPromoVisible={false}
                        visaCount={visaBookingData?.passengers.length}
                      />

                      
                    </div>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Col>
          <VisaBookingPayCard
            blockApiReq={blockApiReq}
            loadingSpin={loadingSpin}
            bookpaycardinfo={"review"}
            pgDisplay={pgDetails.pgDisplay}
            processPayGateway={processPayGateway}
         
          />
          {
            openCCavanueCheckout && (
              <PaymentComponent
                pgData={pgData}

              />
            )
          }
        </Row>
      </div>
    </div>
  );
};

export default VisaReview;
