import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  message,
  Popconfirm,
  Input,
  Layout,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../common/providers/AuthProvider";
import moment from "moment";

import APIClient from "../../helpers/ApiClient";
import EncHelper from "../../helpers/EncHelper";
import exchange from "../assets/vector-icons/exchange.png";
import { CloseOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import search from "../assets/vector-icons/search.png";
import "../ViewPackages/ViewPackages";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Content } = Layout;
const { Option } = Select;
let dateFormat = "DD-MM-YYYY";
let oriDateFormat = "YYYY-MM-DD";
const BASE = process.env.REACT_APP_BASE_DOMAIN;

const ViewPackages = () => {
  const { user, setExtraUserInfo } = useAuthContext();
  const [form] = Form.useForm();
  const [showTable, setShowTable] = useState(false);
  const [packagesData, setPackagesData] = useState([]);
  const [isLoding, setIsLoading] = useState(true);

  const [agentList, setAgentList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [role, setRole] = useState(0);

  const [showSearchBox, setShowSearchBox] = useState(false);

  const viewPackage = (currentItem) => {
    let query = `${BASE}/buildquotationtotal?amIFromAdmin=true&packageRefNo=${currentItem.PackageRefNo}`;
    // let query = `http://localhost:3000/buildquotationtotal?amIFromAdmin=true&packageRefNo=${currentItem.PackageRefNo}`;
    return (
      <div className="d-flex ">
        <div className="edit-icon mx-1" style={{ textAlign: "center" }}>
          <Tooltip placement="rightTop" title="Add/Modify Packages">
            {/* <Link to={query}> <EyeOutlined /> </Link> */}

            <a href={query} target="_blank" rel="noopener noreferrer">
              <EyeOutlined
                onClick={() => getUserCredential(currentItem.UserID)}
              />
            </a>
          </Tooltip>
        </div>

        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deletePackage(currentItem.PackageRefNo)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const deletePackage = (pkgRfNo) => {
    APIClient.delete("buildPackage/deletePackage/" + pkgRfNo)
      .then((res) => {
        if (res.status == 200) {
          message.success("Package Deleted  successfully", 3);
          form.submit();
          showTable(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getUserCredential = (userId) => {
    APIClient.get(`admin/userCredential/${userId}`).then((res) => {
      if (res.status == 200) {
        let data = `username=${res.data?.Email}&&password=${res.data?.Password}&&id=${user?.UserID}`;
        data = EncHelper.encryptData(data);
        setExtraUserInfo(data);
      }
    });
  };

  const packageColumn = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      key: "sno",
    },
    {
      title: "Reference No",
      dataIndex: "PackageRefNo",
      render: (ref, rec) =>
        rec?.Status === "Saved" ? (
          ref
        ) : (
          <Link to={`buildpackage/ticket?ref=${ref}`}>{ref}</Link>
        ),
    },

    {
      title: "Name",
      dataIndex: "UserName",
    },

    {
      title: "Travel Date",
      dataIndex: "TravelDate",
      sorter: (a, b) => a.TravelDate.localeCompare(b.TravelDate),
    },
    {
      title: "Total",
      dataIndex: "TotalFare",
    },
    {
      title: "Status",
      dataIndex: "Status",
    },

    {
      title: "Created Date",
      dataIndex: "CreatedDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
    },
    {
      title: "Action",
      render: (currentItem) =>
        currentItem?.Status === "Saved" ? viewPackage(currentItem) : "",
    },
  ];

  const getPackageRecords = (formData) => {
    setIsLoading(true);
    setPackagesData([]);
    APIClient.get("buildPackage/packageRecord", formData)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setPackagesData(data);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  const disabledDate = (currentDate) => {
    return currentDate >= moment();
  };

  const submitForm = (val) => {
    val.fromDate = val.fromDate
      ? moment(val.fromDate).startOf("hour").format("YYYY-MM-DD hh:mm:ss")
      : "";
    val.toDate = val.toDate
      ? moment(val.toDate).endOf("hour").format("YYYY-MM-DD hh:mm:ss")
      : "";
    getPackageRecords(val);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = packagesData.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setPackagesData(searchResults);
    } else {
      setPackagesData(packagesData);
    }
  };

  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const reset = () => {
    form.resetFields();
    setRole(0);
    setShowTable(false);
    setPackagesData([]);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    lastIndex++;
    return lastIndex;
  };
  const getAllUsers = () => {
    APIClient.get(`admin/user/`)
      .then((res) => {
        if (res.status == 200) {
          let agents = res.data
            .filter((item) => item.Role == 5)
            .map((agent) => agent.userDetails);
          let users = res.data
            .filter((item) => item.Role == 2)
            .map((agent) => agent.userDetails);
          setAgentList(agents);
          setUserList(users);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <div className="manage-markup-section">
      <Content className="container-fluid">
        <div className="card-bt-gap">
          <Card bordered={false}>
            <div className="card-add-heading">
              <h5 className="mb-3">
                View Packages{" "}
                <HelpInfoHelper screenName={"/admin/viewpackages"} />
              </h5>
            </div>
            <div>
              <Form
                form={form}
                layout={"vertical"}
                initialValues={{
                  fromDate: "",
                  toDate: "",
                  packageRefNo: "",
                  UserID: "",
                  RoleType: "",
                }}
                onFinish={submitForm}
              >
                <Row gutter={16}>
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item label="Reports of" name="RoleType">
                      <Select
                        placeholder="Please Select"
                        onSelect={(val) => setRole(val)}
                      >
                        <option value={2}>User</option>
                        <option value={5}>Partner</option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {[2, 5].includes(role) && (
                    <Col md={6} sm={12} xs={24}>
                      <Form.Item
                        label={role === 5 ? "Agent" : "User"}
                        name="UserID"
                      >
                        <Select
                          showSearch
                          placeholder="Please Select"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {role === 5
                            ? agentList.map((item) => (
                                <Option key={item.UserID} value={item.UserID}>
                                  {`${item.FirstName} ${item.LastName}`}
                                </Option>
                              ))
                            : userList.map((item) => (
                                <Option key={item.UserID} value={item.UserID}>
                                  {`${item.FirstName} ${item.LastName}`}
                                </Option>
                              ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}

                  <Col md={6} sm={12} xs={24} className="formQuotation">
                    <Form.Item label="From Date" name="fromDate">
                      <DatePicker
                        style={{ width: "100%" }}
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={24} className="formQuotation">
                    <Form.Item label="To Date" name="toDate">
                      <DatePicker
                        style={{ width: "100%" }}
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item label="Reference No" name="packageRefNo">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="reports-btns">
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={(e) => setShowTable(true)}
                  >
                    Search
                  </Button>

                  <Button type="danger" onClick={reset}>
                    Reset
                  </Button>
                </Row>
              </Form>
            </div>
          </Card>
        </div>

        <div className="card-bt-gap">
          <Card bordered={false}>
            <div className="card-add-heading">
              <div className="rows-count">
                <div>
                  <h5>View Report</h5>
                  <p>{packagesData.length} rows found !</p>
                </div>
              </div>
              <div className="action-images">
                {showSearchBox && (
                  <Input
                    placeholder="Search"
                    onChange={(e) => searchTableData(e)}
                    suffix={closeSearchInput}
                    style={{ padding: "0px 12px" }}
                  />
                )}
                &nbsp;&nbsp;
                <img
                  src={search}
                  alt="search"
                  onClick={(e) => searchData(packagesData)}
                />
                <img src={exchange} alt="exchange" />
              </div>
            </div>
            <div>
              {showTable && (
                <Col md={24} xs={24}>
                  {packagesData.length > 0 ? (
                    <>
                      <Table
                        className="table-scroll-none"
                        bordered
                        rowKey={uniqueKey}
                        dataSource={packagesData}
                        columns={packageColumn}
                      />
                    </>
                  ) : isLoding ? (
                    <Spin
                      size="large"
                      tip="Loading..."
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <b>No Results Found!</b>
                  )}
                </Col>
              )}
            </div>
          </Card>
        </div>
      </Content>
    </div>
  );
};

export default ViewPackages;
