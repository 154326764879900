export const getFareType = (num) => {
  // switch (num) {
  //   case 1:
  //     return "Publish";
  //   case 31:
  //     return "General";
  //   case 21:
  //     return "CouponFare";
  //   case 33:
  //     return "CorpoorateFare";
  //   case 34:
  //     return "SMEFare";
  //   default:
  //     return "";
  // }
  // switch (num) {
  //   case 1:
  //     return "General";
  //   case 2:
  //     return "Publish";
  //   case 3:
  //     return "Instantpur";
  //   case 4:
  //     return "SME";
  //   case 5:
  //     return "PPNR";
  //     case 9:
  //     return "famil";
  //   default:
  //     return "";
  // }
  switch (num) {
    case 1:
      return "ALL";
    case 2:
      return "GENERAL";

    case 3:
      return "PUBLISH";

    case 4:
      return "INSTANTPUR";

    case 5:
      return "SME";

    case 6:
      return "SAVER";
    case 7:
      return "CORPORATE";
    case 8:
      return "COUPON";
    case 9:
      return "FLEXI";
    case 10:
      return "NDC";
    case 11:
      return "TACTICAL";
    case 12:
      return "SUPER6E";
    case 13:
      return "SME.CRPCON";
    case 14:
      return "SPECIAL";
    case 15:
      return "SUPERFARE";

    case 16:
      return "ECONOMY LITE";
    case 17:
      return "ECONOMY SMART";
    case 18:
      return "ECONOMY PRIME";
    case 19:
      return "ECONOMY CLASSIC";
    case 20:
      return "ECONOMY CONVENIENCE";
    case 21:
      return "COMFORT";
    case 22:
      return "BASIC FARE";
    case 23:
      return "VALUE FARE";
    case 24:
      return "EXTRA FARE";
    case 25:
      return "YL|ECONOMY LIGHT";
    case 26:
      return "EC|ECONOMY SMART";
    case 27:
      return "YF|ECONOMY FLEX";
    case 28:
      return "SPECIAL CP";
    case 29:
      return "SPICE FLEX";
    case 30:
      return "XPRESS VALUE CLASS";
    case 31:
      return "XPRESS FLEX CLASS";
    case 32:
      return "CORPORATE VALUE CLASS";

    case 33:
      return "LIGHT";
    case 34:
      return "STANDARD";
    case 35:
      return "PROMO FARE";
    case 36:
      return "CORP CONNECT FARE";
    case 37:
      return "REGULAR FARE";

    case 38:
      return "ECO VALUE";
    case 39:
      return "ECONOMY COMFORT";
    case 40:
      return "ECOFLEX|ECONOMY FLEX";
    case 41:
      return "VALUE";
    case 42:
      return "YS|ECONOMY FLEX";
    case 43:
      return "YR|ECONOMY SPECIAL";
    case 44:
      return "YP|ECONOMY SAVER";
    case 45:
      return "YF|ECONOMY FLEX PLUS";
    case 46:
      return "LITE";
    case 47:
      return "ECONOMY LIGHT";
    case 48:
      return "YF|ECONOMY FLEX";

    default:
      return "";
  }
};

export const getCabinType = (num) => {
  switch (num) {
    case 1:
      return "Economy Premium";
    case 2:
      return "Business";
    case 3:
      return "First Class";
    case 4:
      return "Economy";
    default:
      return "";
  }
};

export const getRoleType = (num) => {
  if (num === 2) {
    return "Users";
  } else if (num === 4) {
    return "Guest";
  } else if (num === 5) {
    return "Agent";
  }
};

export const getTypeSign = (num) => {
  if (num === 1) {
    return "%";
  }
  return "";
};

export const getBusType = (num) => {
  if (num === 1) {
    return "RTC Buses";
  } else if (num === 2) {
    return " Private Travels";
  }
};
