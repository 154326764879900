import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import moment from "moment";
import CurrencyProvider, { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import excel from "../../assets/vector-icons/excel.png";
import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
const { Option } = Select;

const Discounts = (props) => {
  const { user } = useAuthContext();
  const [reportRole, setReportRole] = useState(0);
  const [agentList, setAgentList] = useState([]);
  const [form] = Form.useForm();
  let dateFormat = "DD-MM-YYYY";
  const [showTable, setShowTable] = useState(false);
  const { activeCurrency, currencyValue, currencyRatio } = useCurrencyContext();
  const [dataSource, setDataSource] = useState({
    totalCanncellationCharges: 0,
    totalCollectedFare: 0,
    totalCommission: 0,
    totalConvenienceFee: 0,
    totalMarkup: 0,
    totalRefundAmount: 0,
    cancellationResult: [],
    marginDetails: [],
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProviders();
  }, []);

  const getProviders = () => {
    ApiClient.get("admin/b2b/user")
      .then((res) => {
        if (res.status == 200) {
          let agents = res.data.filter((item) => item.Role == 5);
          setAgentList(agents);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleExcel = (type) => {
    if (type === "booking") {
      if (dataSource?.marginDetails?.length > 0) {
        let copyData = dataSource?.marginDetails;
        const excelData = copyData.map((item) => {
          return {
            Name: item.Name,
            ReferenceNumber: item.ReferenceNumber,
            ServiceType: getServiceType(item.ServiceType),
            CollectedFare: Number(item.CollectedFare) / Number(item.currencyRatio),
            Commission: Number(item.Commission) / Number(item.currencyRatio),
            Markup: Number(item.Markup) / Number(item.currencyRatio),
          };
        });
        props.exportExcel(excelData, "BookingSales");
      }
    } else {
      if (dataSource?.cancellationResult?.length > 0) {
        let copyData = dataSource?.cancellationResult;
        const excelData = copyData.map((item) => {
          return {
            Name: item.Name,
            ReferenceNumber: item.ReferenceNumber,
            ServiceType: getServiceType(item.ServiceType),
            RefundAmount: Number(item.RefundAmount) / Number(item.currencyRatio),
            RefundType: item.RefundType,
            CancellationCharges: Number(item.CancellationCharges) / Number(item.currencyRatio),
          };
        });
        props.exportExcel(excelData, "CancellationSales");
      }
    }
  };

  const getServiceType = (key) => {
    switch (key) {
      case 1:
        return "Flight";
      case 2:
        return "Hotel";
      case 3:
        return "Bus";
      case 4:
        return "Activities";
      case 5:
        return "Transfer";
      case 7:
        return "Cars";
      case 8:
        return "Visa";

      default:
        return;
    }
  };

  const cancelColumns = [
    {
      title: "Sr No",
      dataIndex: "SNo",
      sorter: (a, b) => Number(a.SNo) - Number(b.SNo),
    },
    {
      title: "Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Service Type",
      dataIndex: "ServiceType",
      // sorter: (a, b) => a.Name.localeCompare(b.Name),
      render: (ServiceType) => getServiceType(ServiceType),
    },
    {
      title: "Reference Number",
      dataIndex: "ReferenceNumber",
      sorter: (a, b) => a.ReferenceNumber.localeCompare(b.ReferenceNumber),
    },
    {
      title: "Refund Amount",
      dataIndex: "RefundAmount",
      sorter: (a, b) => a.RefundAmount.localeCompare(b.RefundAmount),
      render: (e) => (<>{currencyValue(e)}</>),
    },
    {
      title: "Refund Type",
      dataIndex: "RefundType",
      sorter: (a, b) => a.RefundType.localeCompare(b.RefundType),
    },
    {
      title: "Cancellation Charges",
      dataIndex: "CancellationCharges",
      sorter: (a, b) =>
        a.CancellationCharges.localeCompare(b.CancellationCharges),
      render: (e) => (<>{currencyValue(e)}</>),
    },
    {
      title: "Created Date",
      dataIndex: "CreatedDate",
      // sorter: (a, b) => a.RefundType.localeCompare(b.RefundType),
      render: (CreatedDate) => moment(CreatedDate).format("DD-MM-YYYY"),
    },
    {
      title: "Created By",
      dataIndex: "CreatedBy",
      sorter: (a, b) => a.CreatedBy.localeCompare(b.CreatedBy),
    },
    {
      title: "Modified Date",
      dataIndex: "Modified Date",
      // sorter: (a, b) => a.RefundType.localeCompare(b.RefundType),
      render: (ModifiedDate) => moment(ModifiedDate).format("DD-MM-YYYY"),
    },
    {
      title: "Modified By",
      dataIndex: "ModifiedBy",
      sorter: (a, b) => a.ModifiedBy.localeCompare(b.ModifiedBy),
    },
    // {
    //   title: "TransactionId",
    //   dataIndex: "TransactionId",
    //   sorter: (a, b) => a.TransactionId.localeCompare(b.TransactionId),
    // },
  ];

  const columns = [
    {
      title: "Sr No",
      dataIndex: "SNo",
      sorter: (a, b) => Number(a.SNo) - Number(b.SNo),
    },
    {
      title: "Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Service Type",
      dataIndex: "ServiceType",
      // sorter: (a, b) => a.Name.localeCompare(b.Name),
      render: (ServiceType) => getServiceType(ServiceType),
    },
    {
      title: "ReferenceNumber",
      dataIndex: "ReferenceNumber",
      sorter: (a, b) => a.ReferenceNumber.localeCompare(b.ReferenceNumber),
    },
    // {
    //   title: "TransactionId",
    //   dataIndex: "TransactionId",
    //   sorter: (a, b) => a.TransactionId.localeCompare(b.TransactionId),
    // },

    {
      title: `CollectedFare (${user?.Currency})`,
      dataIndex: "CollectedFare",
      sorter: (a, b) => Number(a.CollectedFare) - Number(b.CollectedFare),
      // render: (e) => (<>{currencyValue(e)}</>),
      render: (collectedFare, record) => (
        <>


          <>
            {(Number(collectedFare) / Number(record.currencyRatio)).toFixed(2)}
          </>

        </>
      ),
    },
    {
      title: `Commission (${user?.Currency})`,

      dataIndex: "Commission",
      // render: (e) => (<>{currencyValue(e)}</>),
      render: (commission, record) => (
        <>


          <>
            {(Number(commission) / Number(record.currencyRatio)).toFixed(2)}
          </>

        </>
      ),
      // sorter: (a, b) => Number(a.CollectedFare) - Number(b.CollectedFare),
    },

    // {
    //   title: "Email",
    //   dataIndex: "Email",
    //   sorter: (a, b) => a.Email.localeCompare(b.Email),
    // },
    {
      title: `Markup (${user?.Currency})`,
      dataIndex: "Markup",
      // render: (e) => (<>{Number(e)/Number(dataIndex.currencyRatio)}</>),
      render: (markup, record) => (
        <>


          <>
            {(Number(markup) / Number(record.currencyRatio)).toFixed(2)}
          </>

        </>
      ),
    },
  ];

  const getDiscountsData = (values) => {
    setLoading(true);
    setDataSource({
      totalCanncellationCharges: 0,
      totalCollectedFare: 0,
      totalCommission: 0,
      totalConvenienceFee: 0,
      totalMarkup: 0,
      totalRefundAmount: 0,
      cancellationResult: [],
      marginDetails: [],
    });

    ApiClient.get("margin/getspecificmargin", values)
      .then((res) => {
        if (res.status == 200) {
          let cancellationResult = res.data.cancellationResult.map(
            (item, index) => ({
              SNo: index + 1,
              ...item,
              CreatedOn: moment(item.CreatedOn).format(dateFormat),
            })
          );

          let marginDetails = res.data.marginDetails.map((item, index) => ({
            SNo: index + 1,
            ...item,
            CreatedOn: moment(item.CreatedOn).format(dateFormat),
          }));

          setDataSource({ ...res.data, cancellationResult, marginDetails });
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFormSubmit = (values) => {
    let data = {
      fromDate: values.fromDate ? moment(values.fromDate).format() : "",
      toDate: values.toDate ? moment(values.toDate).format() : "",
      UserID: user?.UserID,
      serviceType: values.serviceType,
    };
    getDiscountsData(data);
  };

  const disabledFutureDate = (currentDate) => {
    return currentDate >= moment();
  };

  return (
    <div className="manage-markup-section ourteam-bg">
      <div className="container-fluid">
        <div className="card-bt-gap">
          <Card bordered={false} className="retrieve-booking">
            <h5 className="view-pkg-v1">Margins</h5>
            <Form
              form={form}
              layout={"vertical"}
              initialValues={{
                dateType: 5,
                reportsOf: 0,
              }}
              onFinish={onFormSubmit}
            >
              <Row gutter={16}>
                {user?.Role?.RoleId === 1 ? (
                  <>
                    <Col md={8} xs={24}>
                      <Form.Item
                        label="Reports of"
                        name="reportsOf"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Select
                          placeholder="Select"
                          onSelect={(val) => setReportRole(val)}
                        >
                          <Option value={0}>Self</Option>
                          <Option value={5}>Specific Partner</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {reportRole === 5 && (
                      <Col md={8} xs={24}>
                        <Form.Item
                          label="Partner"
                          name="UserID"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Select placeholder="Select Agent">
                            {agentList.map((data, index) => (
                              <Option key={index} value={data.UserID}>
                                {data.userDetails.FirstName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                  </>
                ) : null}
                <Col className="gutter-row" md={8} xs={24}>
                  <Form.Item
                    label="Service Type"
                    name="serviceType"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select placeholder="Please Select">
                      <Option value={1}>Flights</Option>
                      <Option value={2}>Hotels</Option>
                      <Option value={5}>Transfer</Option>
                      <Option value={7}>Car Extranet</Option>
                      <Option value={8}>Visa Extranet</Option>
                      {/* <Option value={3}>Buses</Option> */}
                      {/* <Option value={4}>Activities</Option> */}
                      {/* <Option value={5}>Build Package</Option> */}
                      {/* <Option value={6}>Sasta Europe</Option> */}
                    </Select>
                  </Form.Item>
                </Col>

                <>
                  <Col className="gutter-row" md={8} xs={24}>
                    <Form.Item
                      label="From Date"
                      name="fromDate"
                    // rules={[{ required: true, message: "Required" }]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder="Select From date"
                        format={dateFormat}
                        disabledDate={disabledFutureDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" md={8} xs={24}>
                    <Form.Item
                      label="To Date"
                      name="toDate"
                    // rules={[{ required: true, message: "Required" }]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder="Select To date"
                        format={dateFormat}
                        disabledDate={disabledFutureDate}
                      />
                    </Form.Item>
                  </Col>
                </>
              </Row>
              <Row className="reports-btns">
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => setShowTable(true)}
                >
                  Search
                </Button>
                <Button
                  type="danger"
                  onClick={() => {
                    form.resetFields();
                    setReportRole(0);
                  }}
                >
                  Reset
                </Button>
              </Row>
            </Form>
          </Card>
        </div>
        <div className="card-bt-gap">
          {showTable && (
            <Card bordered={false}>
              {/* <div className="total-wrapper mb-2">
                {/* <b>Total   (<span className="text-danger mx-2">
                  {activeCurrency}
                </span>) : .
                </b> */}

              {/* <span>
                  Collected Fare:.
                  <span className="text-danger mx-2">
                    {currencyValue(dataSource.totalCollectedFare)}
                    
                  {/* </span>
                  ,
                </span> */}
              {/* <span>
                  Commission: .
                  <span className="text-danger mx-2">
                    {currencyValue(dataSource.totalCommission)}
                  
                  </span>
                  ,
                </span> */}
              {/* <span>
                  Markup: .
                  <span className="text-danger mx-2">
                    {currencyValue(dataSource.totalMarkup)}
                    
                  </span>
                  ,
                </span> */}
              {/* <span>
                  Refund Amount: .
                  <span className="text-danger mx-2">
                    {currencyValue(dataSource.totalRefundAmount)}
                  
                  </span>
                  ,
                </span> */}
              {/* <span>
                  Can. Charges: .
                  <span className="text-danger mx-2">
                    {currencyValue(dataSource.totalCanncellationCharges)}
                   
                  </span>
                  ,
                </span> */}
              {/* <span>
                  Convenience Fee:  .
                  <span className="text-danger mx-2">
                    {currencyValue(dataSource.totalConvenienceFee)}
                    {/* {dataSource.totalConvenienceFee.toFixed(2)} */}
              {/* </span>
                </span> */}
              {/* </div>  */}
              <Row
                justify="space-between
                  "
              >
                <Col md={12} xs={24}>
                  <h5>Booking Sales Report:</h5>
                </Col>

                <Col md={12} xs={24}>
                  <div className="action-images">
                    <img
                      src={excel}
                      alt="excel"
                      onClick={() => handleExcel("booking")}
                    />
                  </div>
                </Col>
              </Row>

              {loading ? (
                <Spin size="large" tip="Loading..." style={{ width: "100%" }} />
              ) : dataSource?.marginDetails?.length > 0 ? (
                <Table
                  scroll={{ x: true }}
                  bordered
                  dataSource={dataSource.marginDetails}
                  columns={columns}
                  // summary={() => {
                  //   return (
                  //     <>
                  //       <Table.Summary.Row
                  //         style={{ fontWeight: 600, fontSize: 13 }}
                  //       >
                  //         <Table.Summary.Cell></Table.Summary.Cell>
                  //         <Table.Summary.Cell></Table.Summary.Cell>
                  //         <Table.Summary.Cell></Table.Summary.Cell>

                  //         <Table.Summary.Cell>Total :  (<span className="text-danger mx-2">
                  //           {activeCurrency}
                  //         </span>)</Table.Summary.Cell>

                  //         <Table.Summary.Cell>
                  //           {currencyValue(dataSource.totalCollectedFare)}
                  //           {/* Rs. {dataSource.totalCollectedFare.toFixed(2)} */}
                  //         </Table.Summary.Cell>

                  //         <Table.Summary.Cell>
                  //           {currencyValue(dataSource.totalCommission)}
                  //           {/* Rs. {dataSource.totalCommission.toFixed(2)} */}
                  //         </Table.Summary.Cell>

                  //         <Table.Summary.Cell>
                  //           {(dataSource.totalMarkup)}
                  //           {/* Rs. {dataSource.totalMarkup.toFixed(2)} */}
                  //         </Table.Summary.Cell>
                  //       </Table.Summary.Row>
                  //     </>
                  //   );
                  // }}
                  pagination={false}
                // pagination={{
                //   defaultPageSize: 25,
                //   showSizeChanger: true,
                //   pageSizeOptions: ["25", "50", "100", "125"],
                // }}
                />
              ) : (
                <b> No Results Found!</b>
              )}

              <Row
                justify="space-between
                  "
                className="mt-3"
              >
                <Col md={12} xs={24}>
                  <h5>Cancellation Sales Report:</h5>
                </Col>

                <Col md={12} xs={24}>
                  <div className="action-images">
                    <img
                      src={excel}
                      alt="excel"
                      onClick={() => handleExcel("cancel")}
                    />
                  </div>
                </Col>
              </Row>

              {loading ? (
                <Spin size="large" tip="Loading..." style={{ width: "100%" }} />
              ) : dataSource?.cancellationResult?.length > 0 ? (
                <Table
                  scroll={{ x: true }}
                  bordered
                  dataSource={dataSource.cancellationResult}
                  columns={cancelColumns}
                  // summary={() => {
                  //   return (
                  //     <>
                  //       <Table.Summary.Row
                  //         style={{ fontWeight: 600, fontSize: 13 }}
                  //       >
                  //         <Table.Summary.Cell></Table.Summary.Cell>
                  //         <Table.Summary.Cell></Table.Summary.Cell>
                  //         <Table.Summary.Cell></Table.Summary.Cell>

                  //         <Table.Summary.Cell>Total :  (<span className="text-danger mx-2">
                  //           {activeCurrency}
                  //         </span>)</Table.Summary.Cell>

                  //         <Table.Summary.Cell>
                  //           {currencyValue(dataSource.totalRefundAmount)}
                  //           {/* Rs. {dataSource.totalRefundAmount.toFixed(2)} */}
                  //         </Table.Summary.Cell>
                  //         <Table.Summary.Cell> </Table.Summary.Cell>
                  //         <Table.Summary.Cell>
                  //           {currencyValue(dataSource.totalCanncellationCharges)}
                  //           {/* Rs. {dataSource.totalCanncellationCharges.toFixed(2)} */}
                  //         </Table.Summary.Cell>
                  //         <Table.Summary.Cell> </Table.Summary.Cell>
                  //       </Table.Summary.Row>
                  //     </>
                  //   );
                  // }}
                  pagination={false}
                // pagination={{
                //   defaultPageSize: 25,
                //   showSizeChanger: true,
                //   pageSizeOptions: ["25", "50", "100", "125"],
                // }}
                />
              ) : (
                <b> No Results Found!</b>
              )}
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdatedComponent(Discounts);
