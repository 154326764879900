// import HotelCardImage from "./HotelCardImage";
import { EnvironmentOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, message, Row, Skeleton } from "antd";
import moment from "moment";
import queryString from "query-string";
import React, { useContext, useEffect, useRef, useState } from "react";
import * as ReactBootstrap from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { Link, useHistory } from "react-router-dom";
import VisaPopulerDestinations from "../../../admin/VisaPopulerDestinations/visaPopulerDestinations";
import noVisaImage from "../../../assets/images/image-not-found-scaled-1150x647.png";
import { GlobalStatesContext } from "../../../common/providers";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { useSytContext } from "../../../common/providers/SytProvider";
import VisaImageLightBox from "../../../components/ImagesLightbox/visaLightBox";
import ApiClient from "../../../helpers/ApiClient";

import "./visaDetails.scss";
import {
  ArrowLeftOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";

const { Panel } = Collapse;

const VisaDetails = () => {
  let history = useHistory();

  const { user } = useAuthContext();
  const { updateSelectedVisaData } = useContext(GlobalStatesContext);
  const { activeCurrency, currencyValue } = useCurrencyContext();

  const [visaDetailsRespObj, setVisaDetailsRespObj] = useState({});

  const [loading, setLoading] = useState(true);

  const [selectedRooms, setSelectedRooms] = useState({});
  const [bestTimeToVisit, setBestTimeToVisit] = useState([]);

  const { visaGuideFaqs } = useSytContext();

  useEffect(() => {
    fetchHotelDetails();
  }, []);

  const fetchHotelDetails = () => {
    const params = queryString.parse(window.location.search);
    fetchHotelRooms(params.visaCode);
  };

  const fetchHotelRooms = (visaCode) => {
    setLoading(true);
    setVisaDetailsRespObj({});
    ApiClient.get("visa/getVisaMasterByVisaCode/" + visaCode)
      .then((res) => res)
      .then((res) => {
        if (res.status === 200) {
          updateSelectedVisaData(res.data);
          setVisaDetailsRespObj(res.data);
          setBestTimeToVisit(
            res.data.embassyDetails.bestTimeToVisit
              ? JSON.parse(res.data.embassyDetails.bestTimeToVisit)
              : []
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSelectedRooms = (hotelRoom, key) => {
    let copyData = { ...selectedRooms };
    copyData[key] = hotelRoom;
    setSelectedRooms(copyData);
  };

  const handleCheckout = () => {};

  const handleRedirect = () => {
    history.push("/visa/checkout");
  };

  const backToList = () => {
    history.goBack("/hotels/listing");
  };

  let myRef1 = useRef(null);
  let myRef2 = useRef(null);
  let myRef3 = useRef(null);
  let myRef4 = useRef(null);
  let myRef5 = useRef(null);
  let myRef6 = useRef(null);

  const scrollToRef = (ref) => {
    try {
      ref.current.scrollIntoView({
        behavior: "smooth",
      });
    } catch (error) {}
  };

  /* Triggering rooms images modal */

  return (
    <div className="visa-details visa-main-header-bar">
       <div className="checkout-heading">
        <div style={{ marginTop: "65px" }} fluid className="checkout-heading-container">
          <div className="goback">
            <Link
              onClick={() => {
                history.go(-1);
              }}
            >
              <ArrowLeftOutlined />
              <span>Go back and Select Other Service</span>
            </Link>
          </div>
          <h2 style={{color:"#fff"}}>Check out this Visa, and Book right now!</h2>
        </div>
      </div>
      <section className="visa-details-header">
        <div className="details-header-container">
          {loading ? (
            <div className="hotel-full-address">
              <div className="hotel-header-wrapper">
                <Row className="hotel-detail-header-row" align="middle">
                  <Col md={18} xs={24} className="visa-details-header-right">
                    <Skeleton active={true} paragraph={{ rows: 1 }} />
                  </Col>
                  <Col md={6} xs={0} className="show-moreskeleton-btn">
                    <Skeleton.Button active={true} size={"large"} />
                  </Col>
                </Row>
              </div>
            </div>
          ) : (
            Object.keys(visaDetailsRespObj).length > 0 && (
              <div className="hotel-full-address">
                <div className="hotel-header-wrapper">
                  <div className="hotel-name">
                    <div className="hotel-name-wrapper">
                      <h4>{visaDetailsRespObj?.VisaName}</h4>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}

          <div className="sticky-links">
            <ul>
              <li>
                <a onClick={() => scrollToRef(myRef1)}>Visa Info</a>
              </li>
              <li>
                <a onClick={() => scrollToRef(myRef5)}>Why Choose Us</a>
              </li>
              <li>
                <a onClick={() => scrollToRef(myRef6)}>How We Can Help</a>
              </li>
              <li>
                <a onClick={() => scrollToRef(myRef2)}>Documents Required</a>
              </li>

              <li>
                <a onClick={() => scrollToRef(myRef3)}>Embassy Details </a>
              </li>
              <li>
                <a onClick={() => scrollToRef(myRef4)}>Visa FAQ's </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {loading ? (
        <div className="cp-visa-inf0-top mt-2">
          <Row gutter={[16, 16]}>
            <Col md={10} xs={24} className="visa-details-carsl-img">
              <Skeleton.Image />
            </Col>
            <Col md={7} xs={24}>
              <Skeleton active />
              <Skeleton active />
            </Col>
            <Col md={7} xs={24}>
              <Skeleton active />
              <Skeleton active />
            </Col>
          </Row>
        </div>
      ) : (
        Object.keys(visaDetailsRespObj).length > 0 && (
          <Row className="cp-visa-inf0-top mt-2">
            <Col md={17} xs={24}>
              <section
                className="hotel-detail-images cp-slider-hotel"
                ref={myRef1}
              >
                <div className="light-box-wrapper overflow-hidden">
                  <ReactBootstrap.Container>
                    <ReactBootstrap.Row>
                      <ReactBootstrap.Col md={12}>
                        {visaDetailsRespObj?.Images.length ? (
                          <VisaImageLightBox
                            visaImages={visaDetailsRespObj.Images}
                          />
                        ) : (
                          <img
                            className="novisaImage"
                            src={noVisaImage}
                            alt="no-photo"
                          />
                        )}
                      </ReactBootstrap.Col>
                    </ReactBootstrap.Row>
                  </ReactBootstrap.Container>
                </div>
              </section>
            </Col>

            <Col md={7} xs={24} className="cp-rooms-guest">
              <div className="cp-bdr-info mb-2">
                <h4 className="visa-hdr">{visaDetailsRespObj.VisaName}</h4>
                <div className="cp-location-address" style={{paddingLeft:'2%'}}>
                  <p>
                    <strong style={{color:'#bd0c21'}}>Description: </strong>
                    <span>{visaDetailsRespObj?.VisaDescription}</span>
                  </p>
                  <p>
                    <strong style={{color:'#bd0c21'}}>Type Of Visa: </strong>
                    <span>{visaDetailsRespObj?.TypeofVisa}</span>
                  </p>
                  <p>
                    <strong style={{color:'#bd0c21'}}>Entry: </strong>
                    <span>{visaDetailsRespObj?.Entry}</span>
                  </p>
                  <p>
                    <strong style={{color:'#bd0c21'}}>Processing Time Up to : </strong>
                    <span>{visaDetailsRespObj?.ProcessingTime} Day(s)</span>
                  </p>
                  <p>
                    <strong style={{color:'#bd0c21'}}>Stay Period: </strong>
                    <span>{visaDetailsRespObj?.Stayperiod} Month(s)</span>
                  </p>
                  <p>
                    <strong style={{color:'#bd0c21'}}>Visa Valid Up To: </strong>
                    <span>{visaDetailsRespObj?.Validity} Month(s)</span>
                  </p>

                  <p>
                    <strong style={{color:'#bd0c21'}}>Country: </strong>
                    <span>{visaDetailsRespObj?.Country}</span>
                  </p>
                </div>
              </div>
            </Col>
              <div className="cp-bdr-info2">
                <div className="cp-location-date">
                  <div className="cp-other-rooms d-block border-0 text-right">
                    <h4 style={{color:"black",fontWeight:"600"}}>{currencyValue(visaDetailsRespObj.Fees)} {activeCurrency}</h4>

                    <Button
                      className="cp-book-this"
                      style={{backgroundColor:'#bd0c21'}}
                      onClick={() => handleRedirect()}
                    >
                      <span className="book-btn">BOOK NOW</span>
                    </Button>
                    {visaGuideFaqs.length ? (
                      <div className="visaGuide">
                        <Link
                          exact={true}
                          to="/visa/visaGuidelines"
                          target={"_blank"}
                        >
                          Visa Guidelines
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
          </Row>
        )
      )}
      <div className="visa-info-card">
        <section className="visa-information mb-3">
          <div className="visa-wrapper">
            <div>
              <h5 className="visa-wrapper-heading">OverView</h5>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: visaDetailsRespObj?.OverView }}
              style={{ whiteSpace: "break-spaces", lineHeight: "23px" }}
            ></div>
          </div>
        </section>
        <section className="visa-information mb-3" ref={myRef5}>
          <div className="visa-wrapper">
            <div>
              <h5 className="visa-wrapper-heading">Why Choose Us?</h5>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: visaDetailsRespObj?.whychooseUs,
              }}
              style={{ whiteSpace: "break-spaces", lineHeight: "23px" }}
            ></div>
          </div>
        </section>
        <section className="visa-information mb-3" ref={myRef6}>
          <div className="visa-wrapper">
            <div>
              <h5 className="visa-wrapper-heading">How We Can Help?</h5>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: visaDetailsRespObj?.howWeCanHelp,
              }}
              style={{ whiteSpace: "break-spaces", lineHeight: "23px" }}
            ></div>
          </div>
        </section>
        <section className="visa-information" ref={myRef2}>
          <div className="visa-wrapper">
            <div>
              <h5 className="visa-wrapper-heading">
                Documents Required For Visa
              </h5>
            </div>

            <div className="visa-embassy-description">
              {loading ? (
                <div className="visa-details-block">
                  <div className="visa-details-room-card-container">
                    {/* ----Room Skeleton Card---- */}

                    <div key={"skeleton"} className="room-card">
                      <Row gutter={16}>
                        <Col md={4}>
                          <div className="room-image-skel">
                            <Skeleton.Image />
                          </div>
                        </Col>
                        <Col md={16}>
                          <Skeleton active />
                        </Col>
                        <Col md={4}>
                          <div className="choose-btn-s">
                            <Skeleton paragraph={{ rows: 0 }} />
                            <Skeleton.Button active={true} size={"large"} />
                          </div>
                        </Col>
                      </Row>
                    </div>

                    {/* ----End Of Room Skeleton Card---- */}
                  </div>
                </div>
              ) : visaDetailsRespObj.docsReqForVisa.length ? (
                <div className="docList d-flex ">
                  <div>
                    <h5>Essential Documents: </h5>
                    <ol>
                      {JSON.parse(
                        visaDetailsRespObj.docsReqForVisa[0].essentialsDocs
                      ).map((item, i) => {
                        return <li key={"docList" + i}>{item}</li>;
                      })}
                    </ol>{" "}
                    <h5>Other Documents: </h5>
                    {visaDetailsRespObj.docsReqForVisa[0].otherDocs
                      ? ReactHtmlParser(
                          visaDetailsRespObj.docsReqForVisa[0].otherDocs
                        )
                      : ""}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
        <section className="visa-embassy-description" ref={myRef3}>
          <div className="visa-embassy-description-wrapper">
            <div className="description-block">
              <div className="embassyDetails">
                <h5 className="visa-wrapper-heading mb-3">Embassy Details</h5>
                {visaDetailsRespObj.embassyDetails ? (
                  <>
                    <p>
                      <strong>Country :</strong>{" "}
                      {visaDetailsRespObj.embassyDetails.capital}
                    </p>
                    <p>
                      <strong>Major City :</strong>{" "}
                      {visaDetailsRespObj.embassyDetails.majorCity}
                    </p>
                    <p>
                      <strong>International Airports :</strong>{" "}
                      {visaDetailsRespObj.embassyDetails.internationalAirport}
                    </p>
                    <p>
                      <strong>Main Airline :</strong>{" "}
                      {visaDetailsRespObj.embassyDetails.mainAirline}
                    </p>
                    <p>
                      <strong>Preferred Lanugage :</strong>{" "}
                      {visaDetailsRespObj.embassyDetails.language}
                    </p>

                    {bestTimeToVisit.length === 2 ? (
                      <p>
                        <strong>Best Time To Visit :</strong>{" "}
                        {moment(bestTimeToVisit[0]).format("MMM-YYYY") +
                          " To " +
                          moment(bestTimeToVisit[1]).format("MMM-YYYY")}
                      </p>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                <p className="mt-3">
                  <strong style={{color:"#bd0c21"}}>Embassy Addresses:-</strong>
                </p>
                {visaDetailsRespObj.embassyDetails ? (
                  <Row className="embassyAddresses">
                    {visaDetailsRespObj.embassyDetails.embassyAddress &&
                    visaDetailsRespObj.embassyDetails.embassyContact &&
                    visaDetailsRespObj.embassyDetails.embassyEmail ? (
                      <Col className="addressColumns" md={8} xs={24}>
                        <>
                          <p>
                            {visaDetailsRespObj.embassyDetails.embassyAddress}
                          </p>
                          <p>
                            <strong>Contact:</strong>&nbsp;
                            {visaDetailsRespObj.embassyDetails.embassyContact}
                          </p>
                          <p>
                            <strong>Email:</strong>&nbsp;
                            {visaDetailsRespObj.embassyDetails.embassyEmail}
                          </p>
                          <p>
                            <strong>Website Url:</strong>&nbsp;
                            {visaDetailsRespObj.embassyDetails.embassyUrl}
                          </p>
                        </>
                      </Col>
                    ) : (
                      ""
                    )}
                    {visaDetailsRespObj.embassyDetails.embassyAddress2 &&
                    visaDetailsRespObj.embassyDetails.embassyContact2 &&
                    visaDetailsRespObj.embassyDetails.embassyEmail2 ? (
                      <Col className="addressColumns" md={8} xs={24}>
                        <>
                          <p>
                            {visaDetailsRespObj.embassyDetails.embassyAddress2}
                          </p>
                          <p>
                            <strong>Contact:</strong>&nbsp;
                            {visaDetailsRespObj.embassyDetails.embassyContact2}
                          </p>
                          <p>
                            <strong>Email:</strong>&nbsp;
                            {visaDetailsRespObj.embassyDetails.embassyEmail2}
                          </p>
                          <p>
                            <strong>Website Url:</strong>&nbsp;
                            {visaDetailsRespObj.embassyDetails.embassyUrl}
                          </p>
                        </>
                      </Col>
                    ) : (
                      ""
                    )}
                    {visaDetailsRespObj.embassyDetails.embassyAddress3 &&
                    visaDetailsRespObj.embassyDetails.embassyContact3 &&
                    visaDetailsRespObj.embassyDetails.embassyEmail3 ? (
                      <Col className="addressColumns" md={8} xs={24}>
                        {" "}
                        <>
                          <p>
                            {visaDetailsRespObj.embassyDetails.embassyAddress3}
                          </p>
                          <p>
                            <strong>Contact:</strong>&nbsp;
                            {visaDetailsRespObj.embassyDetails.embassyContact3}
                          </p>
                          <p>
                            <strong>Email:</strong>&nbsp;
                            {visaDetailsRespObj.embassyDetails.embassyEmail3}
                          </p>
                          <p>
                            <strong>Website Url:</strong>&nbsp;
                            {visaDetailsRespObj.embassyDetails.embassyUrl}
                          </p>
                        </>
                      </Col>
                    ) : (
                      ""
                    )}
                    {visaDetailsRespObj.embassyDetails.embassyAddress4 &&
                    visaDetailsRespObj.embassyDetails.embassyContact4 &&
                    visaDetailsRespObj.embassyDetails.embassyEmail4 ? (
                      <Col className="addressColumns" md={8} xs={24}>
                        {" "}
                        <>
                          <p>
                            {visaDetailsRespObj.embassyDetails.embassyAddress4}
                          </p>
                          <p>
                            <strong>Contact:</strong>&nbsp;
                            {visaDetailsRespObj.embassyDetails.embassyContact4}
                          </p>
                          <p>
                            <strong>Email:</strong>&nbsp;
                            {visaDetailsRespObj.embassyDetails.embassyEmail4}
                          </p>
                          <p>
                            <strong>Website Url:</strong>&nbsp;
                            {visaDetailsRespObj.embassyDetails.embassyUrl}
                          </p>
                        </>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                ) : (
                  "No Embassy Details Found!"
                )}
              </div>
            </div>

            <div className="description-block facilities-list" ref={myRef4}>
              <div className="visa-Faqs  ">
                {" "}
                <h5 className="visa-wrapper-heading mb-3">Visa FAQ's</h5>
                {loading ? (
                  <Skeleton active />
                ) : visaDetailsRespObj.visaFaq.length ? (
                  <Collapse accordion>
                    {visaDetailsRespObj.visaFaq.map((item, i) => {
                      return (
                        <Panel header={item.title} key={"faq" + i}>
                          <p>{ReactHtmlParser(item.description)}</p>
                        </Panel>
                      );
                    })}
                  </Collapse>
                ) : (
                  "No FAQ's Found!"
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <VisaPopulerDestinations />
    </div>
  );
};

export default VisaDetails;
