import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Radio,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import ApiClient from "../../helpers/ApiClient";
import modules from "../../helpers/reactQuillModules";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
import search from "../assets/vector-icons/search.png";
import "./VisaGuide.scss";

const VisaGuide = () => {
  const { Content } = Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [RecId, setId] = useState(-1);
  const [form] = Form.useForm();

  const [content, setContent] = useState({
    Description: "",
  });

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const handleAddClick = () => {
    showModal();
    setIsEditMode(false);
  };
  const handleEditClick = (rec) => {
    form.setFieldsValue({
      Status: rec.Status,
      Statement: rec.Statement,
      Type: rec.Type,
    });
    setContent({
      Description: rec.Description,
    });

    setId(rec.Id);

    setIsEditMode(true);

    showModal();
  };

  const [dataSource, setDataSource] = useState([]);
  const getAllVisaGuide = () => {
    setDataSource([]);
    ApiClient.get("visa/getAllVisaGuides").then((res) => {
      if (res.status == 200) {
        setDataSource(res.data);
      }
    });
  };
  useEffect(() => {
    getAllVisaGuide();
  }, []);

  const getContent = (e, type) => {
    setContent((Prev) => ({ ...Prev, [type]: e }));
  };

  const submitVisaGuide = (formvalues) => {
    ApiClient.post("visa/addVisaGuide", formvalues).then((response) => {
      if (response.status === 200) {
        setModalVisible(false);
        message.success(response.message);

        getAllVisaGuide();
        form.resetFields();
        setContent({ Description: "" });
      } else {
        message.error(response.message);
      }
    });
  };

  const handleSubmit = (formvalues) => {
    formvalues.Description = content.Description;

    if (isEditMode) {
      updateVisaGuide(formvalues);
    } else {
      submitVisaGuide(formvalues);
    }
  };

  const updateVisaGuide = (formvalues) => {
    const updatedFormData = {
      ...formvalues,
    };

    ApiClient.put("visa/updateVisaGuide/" + RecId, {}, updatedFormData).then(
      (response) => {
        if (response.status === 200) {
          setModalVisible(false);
          getAllVisaGuide();
          message.success(response.message);

          form.resetFields();
          setContent({ description: "" });
        } else {
          message.error(response.message);
        }
      }
    );
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const deleteVisaGuide = (id) => {
    ApiClient.delete("visa/deleteVisaGuide/" + id).then((res) => {
      if (res.status == 200) {
        getAllVisaGuide();
        message.success(res.message, 3);
      }
    });
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => handleEditClick(rec)}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteVisaGuide(rec.Id)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const { Option } = Select;

  const columns = [
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
    {
      title: "Statement",
      dataIndex: "Statement",
    },

    {
      title: "Type",
      dataIndex: "Type",
    },

    // {
    //   title: "Description",
    //   dataIndex: "Description",
    //   render: (text) => {
    //     return (
    //       <div
    //         dangerouslySetInnerHTML={{ __html: text }}
    //         style={{ whiteSpace: "break-spaces", lineHeight: "13px" }}
    //       ></div>
    //     );
    //   },
    // },
    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
  ];
  const handelStatus = (data) => {
    if (data.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      );
    } else {
      return <Switch checkedChildren="Active" unCheckedChildren="Inactive" />;
    }
  };

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Visa Guide{" "}
                          <HelpInfoHelper screenName={"/admin/cartypes"} />
                        </h5>
                        <p></p>
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />
                      <img src={excel} alt="excel" />
                      <img src={pdf} alt="pdf" />
                      <p className="add-deposit-icon" onClick={handleAddClick}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>

                  {dataSource.length ? (
                    <div>
                      <Table
                        className="table-scroll-none"
                        bordered
                        dataSource={dataSource}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 5,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  ) : (
                    <b>No record Found</b>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title="Visa Guide"
        className="promo-modal-header cartypes-value"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Row gutter={12}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Type"
                name="Type"
                rules={[{ required: true, message: "Required" }]}
              >
                <Select placeholder="Pleas Select Guide Type">
                  <Option value="Most Popular Visas">Most Popular Visas</Option>
                  <Option value="What Is a Visa?">What Is a Visa?</Option>
                  <Option value="What Does a Visa Look Like?">
                    What Does a Visa Look Like?
                  </Option>
                  <Option value="A Brief History of Travel Visas">
                    A Brief History of Travel Visas
                  </Option>
                  <Option value="Visa Types by Purpose">
                    Visa Types by Purpose
                  </Option>
                  <Option value="Visa-Free Travel">Visa-Free Travel</Option>
                  <Option value="Ways to Get a Travel Visa">
                    Ways to Get a Travel Visa
                  </Option>
                  <Option value="Common Reasons for Visa Denial">
                    Common Reasons for Visa Denial
                  </Option>
                  <Option value="Visa Duration and Validity">
                    Visa Duration and Validity
                  </Option>
                  <Option value="Worldwide Travel Visa Tips">
                    Worldwide Travel Visa Tips
                  </Option>
                  <Option value="What is the Difference Between a Passport and a Visa?">
                    What is the Difference Between a Passport and a Visa?
                  </Option>
                  <Option
                    value="What Is the Difference Between a Visa and a Residence
                    Permit?"
                  >
                    What Is the Difference Between a Visa and a Residence
                    Permit?
                  </Option>
                  <Option>What Are Electronic Travel Authorizations?</Option>
                  <Option>Joint Visa Schemes</Option>
                  <Option>What Is an Exit Visa?</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                label="Statement"
                name="Statement"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item label="Status" name="Status">
                <Radio.Group
                  rules={[{ required: true, message: "Please choose Status!" }]}
                >
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>In Active</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Description"
            rules={[{ required: true, message: "Required" }]}
          >
            <ReactQuill
              modules={modules}
              value={content.Description}
              onChange={(e) => {
                getContent(e, "Description");
              }}
              placeholder="Type Here ...."
            />
          </Form.Item>
          <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit">
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                form.resetFields();
              }}
              danger
              className="cancel-btn"
            >
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default VisaGuide;
