import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Card, Col, Layout, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BusIcon from "../../../assets/images/trus-bus-tic.png";
import "../Busticketsuccess/Busticketsuccess.scss";
const { Content } = Layout;

const CarTicketSuccess = ({ carTicketsDetails }) => {
  const [loading, setLoading] = useState(true);
  // console.log(carTicketsDetails,"carTicketsDetails");
  useEffect(() => {
    if (carTicketsDetails?.ticketAllData.hasOwnProperty("BookingRefNo")) {
      setLoading(false);
    }
    setLoading(false);
  }, [carTicketsDetails]);
  return (
    <Content className="admin-container cms-pages-width retrieve-linebar">
      <Card className="retrieve-booking busmtb">
        <Row gutter={[16, 16]}>
          <Col lg={12} md={16} sm={16} xs={24}>
            <h4 className="ticket-lti-bus">Car Itinerary</h4>

            {loading ? (
              <Skeleton active paragraph={{ rows: 2 }} />
            ) : carTicketsDetails.ticketAllData.hasOwnProperty(
                "BookingRefNo"
              ) ? (
              <div className="fic-tickets-data-bus">
                <div className="bd-bus-info">
                  <Row gutter={[8, 8]}>
                    <Col md={7} sm={24} xs={24}>
                      <p className="travel-bus">
                        {carTicketsDetails?.ticketAllData?.VendorName}
                      </p>
                    </Col>
                    <Col md={9} sm={12} xs={24}>
                      <div className="tic-indi-details-bus">
                        <span>{carTicketsDetails?.ticketAllData?.Source}</span>
                        <span className="airline-tic-files-bus"></span>

                        <span>
                          {carTicketsDetails?.ticketAllData?.Destination}
                        </span>
                      </div>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <div className="airline-dte-tic-bus-add-value">
                        <span>
                          {carTicketsDetails?.ticketAllData?.JourneyDate}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>

                <Row gutter={[8, 8]}>
                  <Col md={3} sm={6} xs={24}>
                    {/* <img
                      className="bus-success-pay"
                      src={BusIcon}
                      alt="image bus "
                    /> */}
                    <i class="fa fa-car" style={{fontSize:'20px', color:'#bd0c21'}}></i>
                  </Col>

                  <Col md={12} sm={18} xs={24}>
                    <div className="airline-dte-tic-bus">
                      <div className="points">
                        <p className="title">Boarding point</p>
                        <p>{carTicketsDetails?.ticketAllData?.PickUpAddress}</p>
                      </div>

                      <ArrowRightOutlined />

                      <div className="points">
                        <p className="title">Dropping point</p>
                        <p>{carTicketsDetails?.ticketAllData?.DropAddress}</p>
                      </div>
                    </div>
                  </Col>

                  <Col md={9} sm={24} xs={24}>
                    <p className="pnr">
                      PNR:{" "}
                      {carTicketsDetails?.ticketAllData?.BookingConfirmationNo}
                    </p>
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="fic-tickets-data-bus">
                {carTicketsDetails.errorMessage}
              </div>
            )}
          </Col>

          <Col lg={12} md={8} sm={8} xs={24} className="flight-email-dd-bus">
            {loading ? (
              <Skeleton active paragraph={{ rows: 2 }} />
            ) : carTicketsDetails.ticketAllData.hasOwnProperty(
                "BookingRefNo"
              ) ? (
              <>
                <div className="paymet-booking-tic">
                  <h4>Car Ticket Booking Payment Successful</h4>
                  <p>
                    A confirmation email has been sent your provided email
                    address
                  </p>
                  <p>
                    Ref-No : {carTicketsDetails?.ticketAllData?.BookingRefNo}
                  </p>
                  <p>
                    Status : <span className="succes-pay-value">Success</span>
                  </p>
                </div>

                <Row>
                  <Col lg={12} md={18} xs={24}>
                    <Button className="tic-retrive-book" htmlType="submit">
                      <Link
                        to={`cars/ticket?ref=${carTicketsDetails?.ticketAllData?.BookingRefNo}`}
                      >
                        Retrieve Booking
                      </Link>
                    </Button>
                  </Col>
                </Row>
              </>
            ) : null}
            {/* {carTicketsDetails.twoWay ? (
              ticket2.loading ? (
                <Skeleton active paragraph={{ rows: 2 }} />
              ) : carTicketsDetails.refNo2 === null && ticket2.bookingRefNo === "" ? null : (
                <>
                  <div style={{ marginTop: "10px" }} className="paymet-booking-tic">
                    <p>Ref-No : {carTicketsDetails.refNo2}</p>
                    <p>
                      Status : <span className="succes-pay-value">Success</span>
                    </p>
                  </div>

                  <Row>
                    <Col lg={12} md={18} xs={24}>
                      <Button className="tic-retrive-book" htmlType="submit">
                        <Link to={`bus/ticket?ref=${carTicketsDetails.refNo2}`}>Retrieve Booking</Link>
                      </Button>
                    </Col>
                  </Row>
                </>
              )
            ) : null} */}
          </Col>
        </Row>
      </Card>
    </Content>
  );
};

export default CarTicketSuccess;
