import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Switch,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  IdcardOutlined,
  DeleteOutlined,
  UserOutlined,
} from "@ant-design/icons";
import search from "../../assets/vector-icons/search.png";
import excel from "../../assets/vector-icons/excel.png";
import pdf from "../../assets/vector-icons/pdf.png";
import ApiClient from "../../../helpers/ApiClient";

const ManageBands = () => {
  const { Content } = Layout;
  const [modalVisible, setModalVisible] = React.useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [department, setDepartment] = useState([]);
  const [Grades, setGrades] = useState([]);
  const [brandList, setBrandlist] = useState([]);
  const [formManageBrand] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [currentID, setCurrentID] = useState(-1);

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const handleAddClick = () => {
    formManageBrand.resetFields();
    showModal();
    setIsEditMode(false);
  };
  const handleSubmit = (formvalues) => {
    if (isEditMode) {
      submitUpdateBrand(formvalues);
    } else {
      submitNewBrand(formvalues);
    }
  };
  const submitNewBrand = (formvalues) => {
    formvalues = {
      ...formvalues,
    };
    ApiClient.post("CorporateExtranet/CreateBands", formvalues).then(
      (response) => {
        if (response.status === 200) {
          setModalVisible(false);
          message.success("Brand created successfully");
          getAllBrands();
          formManageBrand.resetFields();
        } else {
          message.error(" Error Creating Brand");
        }
      }
    );
  };
  const submitUpdateBrand = (formvalues) => {
    const updatedFormData = {
      ...formvalues,
    };
    ApiClient.put(
      "CorporateExtranet/UpdateBands/" + currentID,
      {},
      updatedFormData
    ).then((response) => {
      // console.log(response);
      if (response.status === 200) {
        setModalVisible(false);
        message.success("Department updated successfully");
        getAllBrands();
        formManageBrand.resetFields();
      } else {
        message.error(" Error Updating Department");
      }
    });
  };
  const columns = [
    {
      title: "Band Name",
      dataIndex: "BandName",
    },
    {
      title: "Department Name",
      dataIndex: "DepartmentName",
    },
    {
      title: "Grade",
      dataIndex: "Grade",
    },
    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];
  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              handleEditClick(rec);
              setCurrentID(rec.BandId);
            }}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteBrand(rec.BandId)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const handleEditClick = (rec) => {
    formManageBrand.setFieldsValue({
      BandName: rec.BandName ?? "",
      GradeId: rec.GradeId ?? "",
      DepartmentId: rec.DepartmentId ?? "",
      Status: rec.Status ?? "",
    });

    setIsEditMode(true);

    showModal();
  };
  const deleteBrand = (id) => {
    ApiClient.delete("CorporateExtranet/DeleteBands/" + id).then((res) => {
      if (res.status == 200) {
        message.success("Brand Deleted  successfully", 3);
        getAllBrands();
      }
    });
  };
  const handelStatus = (data) => {
    if (data.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };

  const changeStatus = (info) => {
    // let obj = {
    //   Id: info.CarTypeID,
    //   status: Status,
    // };
    // ApiClient.put("carextranet/toggleCarTypeStatus/" + info.CarTypeID)
    //   .then((response) => {
    //     if (response.status == 200) {
    //       message.success("Car Type Status updated successfully !");
    //       getAllCarType();
    //     } else {
    //       message.error(response.message, 3);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };
  useEffect(() => {
    getAllBrands();
    getAllDepartment();
    getAllGrades();
  }, []);
  const getAllGrades = () => {
    ApiClient.get("CorporateExtranet/GetGrades")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setGrades(data);
        }
      })
      .catch((error) => {});
  };
  const getAllBrands = () => {
    ApiClient.get("CorporateExtranet/GetBands")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setDataSource(data);
          setBrandlist(data.length);
        }
      })
      .catch((error) => {});
  };
  const getAllDepartment = () => {
    ApiClient.get("CorporateExtranet/GetDepartment")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setDepartment(data);
        }
      })
      .catch((error) => {});
  };
  return (
    <div>
      <div className="promo providerapi">
        <Layout>
          <div className="manage-markup-section">
            <Content className="admin-container">
              <Row>
                <Col span={24}>
                  <Card>
                    <div className="card-add-heading">
                      <div className="rows-count">
                        <div>
                          <h5>Manage Bands</h5>
                          <p>{brandList ?? 0} rows found !</p>
                        </div>
                      </div>
                      <div className="action-images">
                        <div
                          className="exlpdf"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <img
                            src={search}
                            alt="search"
                            style={{ maxHeight: "35px", maxWidth: "35px" }}
                          />
                          <img
                            src={excel}
                            alt="excel"
                            style={{ maxHeight: "35px", maxWidth: "35px" }}
                          />
                          <p
                            className="add-icon"
                            onClick={() => handleAddClick()}
                          >
                            <PlusOutlined />
                          </p>
                        </div>
                      </div>
                    </div>
                    <Table
                      className="table-scroll-none"
                      style={{ color: "red" }}
                      columns={columns}
                      dataSource={dataSource}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </Card>
                </Col>
              </Row>
            </Content>
          </div>
        </Layout>

        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>Manage Bands</strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header modal-header-bg12"
          visible={modalVisible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          footer={[
            <div>
              <Button type="primary" onClick={() => formManageBrand.submit()}>
                {isEditMode ? "Update" : "Add"}
              </Button>
              <Button
                type="primary"
                danger
                className="cancel-btn"
                onClick={() => formManageBrand.resetFields()}
              >
                Clear
              </Button>
            </div>,
          ]}
          width={"620px"}
        >
          <Form
            form={formManageBrand}
            layout={"vertical"}
            from={formManageBrand}
            onFinish={handleSubmit}
          >
            <Row gutter={16}>
              <Col md={12} xs={24}>
                <Form.Item
                  name="BandName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Band Name!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Band Name"
                  />
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  name="DepartmentId"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Your Department",
                    },
                  ]}
                >
                  <Select
                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                    placeholder="Select Your Department Name"
                  >
                    {department.length > 0 ? (
                      department.map((i) => (
                        <Select.Option value={i.DepartmentId}>
                          {i.DepartmentName}
                        </Select.Option>
                      ))
                    ) : (
                      <Select.Option value="demo">No Department</Select.Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={12} xs={24}>
                <Form.Item
                  name="GradeId"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Your Grade",
                    },
                  ]}
                >
                  <Select
                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                    placeholder="Select Your Grade "
                  >
                    {Grades.length > 0 ? (
                      Grades.map((i) => (
                        <Select.Option value={i.GradeId}>
                          {i.Grade}
                        </Select.Option>
                      ))
                    ) : (
                      <Select.Option value="demo">No Grades</Select.Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  name="Status"
                  rules={[
                    { required: true, message: "Please Select Your Status" },
                  ]}
                >
                  <Select
                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                    placeholder="Select Status"
                  >
                    <Select.Option value={0}>yes</Select.Option>
                    <Select.Option value={1}>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </div>
  );
};
export default ManageBands;
