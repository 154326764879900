import React, { useState, useCallback, useEffect } from "react";
import { Spin, Select } from "antd";
import APIClient from "../../helpers/ApiClient";
import { Form } from "antd";
import flag from '../../assets/country-flag-svg/in.svg';
import defaultimage from "../../assets/images/Icons/defaultflagicon.png"
import { useLocalStorage } from "../../helpers/useStorage";
import './Autocomplete.scss'

const API_URL = "flights/updatedAirPort/search/";
const AirportAutoCompleteSelectByCategory = (props) => {
  const [topCities, settopCities] = useState([]);
  const [hasFetched, setHasFetched] = useState(false); // Track API call status
  const { Option, OptGroup } = Select;
  const [options, setOptions] = useState({
    data: [],
    value: [],
    fetching: false,
  });

  useEffect(() => {
    let value = "";
    if (props.isfieldKey) {
      value = props.refName.current[props.fieldKey].props.value;
    } else {
      value = props.refName.current.props.value;
    }

    if (!value || value === "" || value === " ") {
      return;
    }

    if (!hasFetched) {
      fetchData(value);
    }
  }, [
    props.isfieldKey
      ? props.refName.current[props.fieldKey]
      : props.refName.current,
    props.dataFlip,
  ]);

  const check = () => {
    if (props.focusRef) {
      if (props.isfieldKey)
        props.handleOnSubmit(props.focusRef, props.isfieldKey, props.fieldKey);
      else props.handleOnSubmit(props.focusRef);
    }
  };

  const debounceOnChange = useCallback(debounce(fetchData, 400), []);
  
  const createOptions = (results) => {
    let arr = [];

    results.forEach((result) => {
      arr.push({
        value: result._source.code,
        display: result._source.suggestionWithTags,
        _class: "parent",
        label: result.suggestion,
      });

      if (result._type === "multiport") {
        result._source.airports_data.forEach((result_2) => {
          arr.push({
            value: result_2._source.code,
            display: result_2._source.suggestionWithTags,
            _class: "child",
            label: result_2.suggestion,
          });
        });
      }
    });

    return arr;
  };

  function fetchData(value) {
    if (hasFetched) return; // Stop further API calls if already fetched successfully

    setOptions({ data: [], fetching: true });
    if (value === "" || value === " ") {
      return;
    }

    APIClient.get(`${API_URL}${value}`)
      .then((res) => {
        if (res.statusCode === 200 && res) {
          setOptions({ data: res.data, fetching: false });
          setHasFetched(true); // Mark as fetched successfully
          settopCities([]);
          return;
        }
      })
      .catch((error) => {
        console.error("error", error);
        setOptions({ data: [], fetching: false });
      });
  }

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }

  const [FlightRecentSearches, setFlightRecentSearches] = useLocalStorage(
    props.recentKey,
    []
  );

  const recentSearches = (e) => {
    if (e && options.data.length) {
      const optionObj = options.data.find((item) => item.AirportCode === e);
      if (optionObj) {
        if (FlightRecentSearches.length > 0) {
          let array = [];
          array = [...FlightRecentSearches];
          if (array.length > 4) {
            array.pop();
          }

          if (optionObj) {
            setFlightRecentSearches([
              optionObj,
              ...array.filter((item) => item.AirportCode !== e),
            ]);
          }

          return;
        }

        setFlightRecentSearches([optionObj]);
      }
    } else {
      setFlightRecentSearches([]);
    }
  };

  const getflag = (code) => {
   // console.log(code,"hhh")
    if (code === "yu" ||  code === "tp" ||code === "i" ||code === ""|| code === "#FIELD!"|| code === "ac"|| code === "cs"|| code === "fp"|| code === "oh") {
      let codeflag = defaultimage;
      return codeflag;
    } else {
      if (code === "yu") {
        // console.log("yu is coming");
      }
    //  console.log(code,"code")
      let codeflag = require(`../../assets/country-flag-svg/${code}.svg`).default;
      return codeflag;
    }
  }

  return (
    <Form.Item
      {...props.formItemProps}
      className="airport_auto_complete notranslate"
    >
      <Select
        style={{ width: "100%" }}
        showSearch
        ref={(el) => {
          props.isfieldKey
            ? (props.refName.current[props.fieldKey] = el)
            : (props.refName.current = el);
        }}
        notFoundContent={options.fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={debounceOnChange}
        onChange={props.onChange}
        {...props.selectProps}
        onSelect={(e) => {
          check();
          recentSearches(e);
        }}
        dropdownClassName={"airpot_names_dropdown"}
        dropdownStyle={{ width: "100%" }}
        className="notranslate"
      >
        {topCities.length > 0 && (
          <OptGroup>
            {topCities.map((item, index) => {
              if (props?.formItemProps?.name === "destination") {
                return (
                  <Option
                    key={"topcitydest" + index}
                    value={item?.destination?.split("-")[2]}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <p className="m-0">
                          {item.destination.split("-")[0]},{" "}
                          {item.destination.split("-")[1]}
                        </p>
                        <p
                          className="m-0 city-column"
                          style={{ fontSize: "11px", color: "#9B9B9B" }}
                        >
                          {item.destination.split("-")[3]}
                        </p>
                      </div>
                      <div className="city-column">
                        <p className="m-0" style={{ color: "#9B9B9B" }}>
                          {item.destination.split("-")[2]}
                        </p>
                      </div>
                    </div>
                  </Option>
                );
              } else {
                return (
                  <Option
                    key={"topcityori" + index}
                    value={item?.origin?.split("-")[2]}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <p className="m-0">
                          {item.origin.split("-")[0]},{" "}
                          {item.origin.split("-")[1]}
                        </p>
                        <p
                          className="m-0 city-column"
                          style={{ fontSize: "11px", color: "#9B9B9B" }}
                        >
                          {item.origin.split("-")[3]}
                        </p>
                      </div>
                      <div className="city-column">
                        <p className="m-0" style={{ color: "#9B9B9B" }}>
                          {item.origin.split("-")[2]}
                        </p>
                      </div>
                    </div>
                  </Option>
                );
              }
            })}
          </OptGroup>
        )}
        {options?.data?.length > 0 && (
          <OptGroup>
            {options.data.map((d, index) => (
              <Option
                className="notranslate"
                value={d.AirportCode}
                key={"key" + index}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <i className="fa fa-plane forplace-wor-dropdownflight"></i>
                  <div className="for-elepsis-work-dropdownflight">
                    <p className="m-0 for-elepsis-work-dropdownflight">
                      {d.City}, {d.Country}
                    </p>
                    <p
                      className="m-0 city-column for-elepsis-work-dropdownflight"
                      style={{ fontSize: "11px", color: "#9B9B9B" }}
                    >
                      {d.AirportDesc}
                    </p>
                  </div>
                  <div className="city-column">
                    <p className="m-0" style={{ color: "#9B9B9B" }}>
                      <span> {d.AirportCode}</span>
                      &nbsp;
                      <img
                        src={d?.CountryCode ? getflag(d?.CountryCode?.toLowerCase()) : ""}
                        width="20"
                        alt={d?.Country}
                      ></img>
                    </p>
                  </div>
                </div>
              </Option>
            ))}
          </OptGroup>
        )}
      </Select>
    </Form.Item>
  );
};

export default AirportAutoCompleteSelectByCategory;
