//#region lates code
import React, { useContext, useEffect, useState } from "react";
import "./VisaFareDetails.scss";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Radio,
  message,
  Popover,
  Divider,
  List,
  Modal,
} from "antd";
import moment from "moment";
import { GlobalStatesContext } from "../../../common/providers";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { CarPriceHelper } from "../../../helpers/CarsHelpers/carPriceHelper";
import {
  getConvFee,
  getPromoDiscount,
} from "../../../common/AllTickets/promoConvhelper";
import APIClient from "../../../helpers/ApiClient";
import Login from "../../../components/Login/Login";
import { CloseOutlined } from "@ant-design/icons";

export const getVisaGrandTotal = (
  visaBookingData,
  ConvFee,
  promoData,

  visaCount
) => {
  let discount = 0;
  let convamount = 0;

  let fareInfoData = visaBookingData
    ? Number(visaBookingData.Fees) * Number(visaCount)
    : null;

  let totalBaseFare = fareInfoData;

  let totalGst = 0;
 

  let total = Number(totalBaseFare) + Number(totalGst);
  discount = getPromoDiscount(promoData, total);
  convamount = getConvFee(ConvFee, total);

 
  total = total + Number(convamount);

 
  total -= discount;

  let totalFareCalculations = Number(Number(total)).toFixed(2);

 
  return {
    totalBaseFare: Number(totalBaseFare).toFixed(2),
    totalGst: Number(totalGst).toFixed(2),
    discount: Number(discount).toFixed(2),
    totalFareCalculations,
    totalServiceAmount: Number(total).toFixed(2),
    convamount: Number(convamount).toFixed(2),
  };
};

const VisaFareDetails = ({ visaBookingData, isPromoVisible, visaCount, location }) => {
  const [form] = Form.useForm();
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const {
    state: {
      otherData: { ConvFee, promoData, selectedInsuranceData },
    },
    AddConvFee,
    validatePromoCode,
    RemovePromo,
    getInsuranceByServiceType,
  } = useContext(GlobalStatesContext);

  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [promoCode, setPromoCode] = useState([]);
  let dateFormat = "YYYY-MM-DD";
  let {
    totalBaseFare,
    totalGst,
    discount,
    convamount,
    totalFareCalculations,
    totalServiceAmount,
    insuranceTotal,
  } = getVisaGrandTotal(
    visaBookingData,
    ConvFee,
    promoData,

    visaCount
  );
  
  useEffect(() => { }, []);

  useEffect(() => {
    if (!agent) {
      AddConvFee(8);
      getpromo();
    }
  }, [agent]);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, dateFormat).isSameOrAfter(moment() , 'day')
          );
          if (data.length > 0) {
            let VisaPromo = data.filter(
              (promo) =>
                promo.ServiceType === 8 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );

            setPromoCode(VisaPromo);
          }
        }
      })
      .catch((error) => {
        setPromoCode([]);

        console.error(error);
      });
  };

  const handleApply = (code) => {
    if (user != null) {
      form.setFieldsValue({
        promo: code,
      });

      form.submit();
    } else {
      message.error("please Sign-In to Avail Offers")
    }
  };
  const [Loc, setLoc] = useState({});
  useEffect(() => {
    if (location) {
      setLoc(location);
    }
  }, [location]);
  const [modalVisible, setModalVisible] = useState({
    visible: false,
    type: "USER",
  });
  const showModal1 = (type) => {
    /*============= check user logged or not =========== */
    setModalVisible({ visible: true, type: type });

  };
  return (
    <>
      <div className="busFareDetail_wrapper">

        <div className="price_wrapper">
          <p className="text">Base Fee</p>
          <p className="price">
            {activeCurrency}{" "}
            {visaBookingData
              ? `${currencyValue(visaBookingData.Fees)}${visaCount > 1 ? "x" + visaCount : ""
              }`
              : ""}
          </p>
        </div>
       

       
        {!agent && user?.Role?.RoleLevel !== 3 && (
          <>
            {convamount > 0 ?
              <div className="price_wrapper">
                <p className="text">Convenience Fee</p>
                <p className="price">
                  {activeCurrency} {currencyValue(convamount)}
                </p>
              </div> : null}
            {promoData.status && (
              <div className="price_wrapper">
                <p className="text">Discount</p>
                <p className="price">
                  - {activeCurrency}{" "}
                  {promoData.status ? currencyValue(discount) : " 0.00"}
                </p>
              </div>
            )}
          </>
        )}

       
        <div className="price_wrapper total_price">
          <p className="text" style={{ whiteSpace: "nowrap" }}>Grand Total:</p>
          <p style={{ color: "#35459c", whiteSpace: "nowrap" }} className="price">
            {activeCurrency}{" "}
            {totalFareCalculations
              ? currencyValue(totalFareCalculations)
              : ""}
          </p>
        </div>

      </div>

      <div className="busFareDetail_wrapper">
        
        {selectedInsuranceData.status === 1 &&
          selectedInsuranceData?.serviceType === 3 ? (
          <div className="price_wrapper">
            <p className="text">Insurance Amount</p>
            <p className="price">
              {activeCurrency} {currencyValue(insuranceTotal)}
            </p>
          </div>
        ) : null}





       
      </div>


      {!agent && user?.Role?.RoleLevel !== 3 && isPromoVisible ? (
        <div className="buspromo_wrapper">
          {promoData.status == false ? (
            <div>
              <p className="name">Apply Promo</p>
              {user != null ?
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={(d) => {
                    validatePromoCode({
                      ServiceType: 8,
                      Amount: Number(totalServiceAmount),
                      PromoCode: d.promo,
                      UserId: user?.UserID ?? 1,
                      userMail: user?.Email,
                    });
                  }}
                >
                  <Row gutter={[16, 16]}>
                    <Col md={16} sm={12} xs={12}>
                      <Form.Item name="promo">
                        <Input
                          className="inputbg"
                          placeholder="Enter Your Promo code"
                          autoComplete="off"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={12}>
                      <Form.Item>
                        <Button type="primary" onClick={() => form.submit()}>
                          Apply
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form> : <div>
                  <p>Please <span style={{ color: "blue", cursor: "pointer" }} onClick={() => showModal1("USER")}>Sign-In</span> to Avail Offers</p>
                </div>
              }
              {promoCode.length ? (
                promoCode.map((item, key) => {
                  return (
                    <>
                      <div className="promo-cp-coupons" key={key}>
                        <div className="inline-cp-promo">
                          <Form>
                            <Form.Item>
                              <Radio
                                onClick={() => handleApply(item.Code)}
                              ></Radio>
                            </Form.Item>
                          </Form>
                          <p className="promo-key-cp">{item.Code}</p>
                          {item.DiscountType === 1 ? (
                            <p className="save-cp-offer">
                              Save {Math.floor(item.Discount) + " %"}
                            </p>
                          ) : (
                            <p className="save-cp-offer">
                              Save {activeCurrency}&nbsp;
                              {currencyValue(item.Discount)}
                            </p>
                          )}
                        </div>

                        <div className="promo-percentage-cp">
                          <p>{item.Description}</p>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="promo-cp-coupons">
                  <div className="promo-percentage-cp pl-0 pt-0">
                    <p>No Promo Code Available</p>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Card className="flight-cards-details">
              <div className="promo-card-header">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="name"
                >
                  Promo Coupon{" "}
                  <CloseOutlined
                    onClick={() => {
                      RemovePromo();
                      form.resetFields();
                    }}
                  />
                </p>
              </div>
              <div className="promo-input">
                <p className="mb-0">
                  <span className="applied"> {promoData.Code} </span> Promo Code
                  Applied
                </p>
              </div>
            </Card>
          )}
        </div>
      ) : null}
      <Modal
        centered
        visible={modalVisible.visible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        className="login-modal"
        footer={false}
      >
        <Login
          location={Loc}
          setModalVisible={(value) => setModalVisible(value)}
          type={modalVisible.type}
        />
      </Modal>
    </>
  );
};
export default VisaFareDetails;
