import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Layout, Row, Carousel } from "antd";
import "../Busticketsuccess/Busticketsuccess.scss";
import NoImage from "../../../assets/images/image-not-found-scaled-1150x647.png";
const { Content } = Layout;

const HolidayTicketSuccess = ({ holidayTicketDetails }) => {
  return (
    <Content className="admin-container cms-pages-width retrieve-linebar">
      <Card className="retrieve-booking busmtb">
        <Row gutter={[16, 16]}>
          <Col lg={12} md={16} sm={16} xs={24}>
            <h4 className="ticket-lti-bus">Holiday ltinerary</h4>
            {/* sub--row--start */}

            {holidayTicketDetails.message === "FAILED" && <p>Failed</p>}
            {
              holidayTicketDetails?.ToursExPackagesId && (
                <div className="fic-tickets-data-bus">
                  <h6 className="round-bus-tic">Holiday Name</h6>
                  <div className="bd-bus-info">
                    <Row gutter={[8, 8]}>
                      <Col md={6} sm={10} xs={24}>
                        <div style={{ width: "100%", maxHeight: "110px" }}>
                          <Carousel autoplay className="holiday-carousel">
                            {holidayTicketDetails?.TourMedia?.length > 0 ? (
                              holidayTicketDetails?.TourMedia[0]?.Images
                                ?.length > 0 ? (
                                holidayTicketDetails?.TourMedia[0].Images.map(
                                  (x, idx) => (
                                    <div key={idx + "image"}>
                                      <img
                                        className="checkout-slider-img"
                                        src={x}
                                        alt="slider-img"
                                        style={{
                                          width: "100%",
                                          maxHeight: "110px",
                                        }}
                                      />
                                    </div>
                                  )
                                )
                              ) : (
                                <img
                                  className="checkout-slider-img"
                                  src={NoImage}
                                  alt="slider-img"
                                  style={{ width: "100%", maxHeight: "110px" }}
                                />
                              )
                            ) : (
                              <img
                                className="slider-img-holidays"
                                src={NoImage}
                                alt="slider-img"
                                style={{ width: "100%", maxHeight: "110px" }}
                              />
                            )}
                          </Carousel>
                        </div>
                      </Col>
                      <Col md={18} sm={14} xs={24}>
                        <h6>{holidayTicketDetails.TourName}</h6>
                      </Col>
                    </Row>
                  </div>

                  <Row gutter={[8, 8]}>
                    <Col md={12} sm={12} xs={24}>
                      <p className="pnr text-left">
                        Name: {holidayTicketDetails.Name}
                      </p>
                      <p className="pnr text-left">
                        Email: {holidayTicketDetails.Email}
                      </p>
                    </Col>
                  </Row>
                </div>
              )
              // ))
            }
          </Col>

          <Col lg={12} md={8} sm={8} xs={24} className="flight-email-dd-bus">
            {holidayTicketDetails?.ToursExPackagesId ? (
              <>
                <div className="paymet-booking-tic">
                  <h4>Holiday Ticket Booking Payment Successful</h4>
                  <p>
                    A confirmation email has been sent your provided email
                    address
                  </p>
                  <p>Ref-No : {holidayTicketDetails?.ReferenceNo}</p>
                  <p>
                    Status : <span className="succes-pay-value">Success</span>
                  </p>
                </div>

                <Row>
                  <Col lg={12} md={18} xs={24}>
                    <Button
                      className="tic-retrive-book"
                      htmlType="submit"
                      // disabled
                    >
                      <Link
                        to={`holiday/ticket?ref=${holidayTicketDetails?.ReferenceNo}`}
                      >
                        Retrieve Booking
                      </Link>
                    </Button>
                  </Col>
                </Row>
              </>
            ) : null}
          </Col>
        </Row>
      </Card>
    </Content>
  );
};

export default HolidayTicketSuccess;
