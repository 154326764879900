import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Table,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import search from "../../../src/assets/vector-icons/search.png";
import { useAuthContext } from "../../common/providers/AuthProvider";
import APIClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import {
  getCabinType,
  getTypeSign,
  getBusType,
} from "../../admin/AdminMarkup/commissionhelper";

import "./Agentmarkup.scss";

const { Option } = Select;

const getAgentFareType = (num) => {
  // switch (num) {
  //   case 1:
  //     return "General";
  //   case 2:
  //     return "Publish";
  //   case 3:
  //     return "Instantpur";
  //   case 4:
  //     return "SME";
  //   case 5:
  //     return "PPNR";
  //   default:
  //     return "";
  // }
  switch (num) {
    case 1:
      return "ALL";
    case 2:
      return "GENERAL";

    case 3:
      return "PUBLISH";

    case 4:
      return "INSTANTPUR";

    case 5:
      return "SME";

    case 6:
      return "SAVER";
    case 7:
      return "CORPORATE";
    case 8:
      return "COUPON";
    case 9:
      return "FLEXI";
    case 10:
      return "NDC";
    case 11:
      return "TACTICAL";
    case 12:
      return "SUPER6E";
    case 13:
      return "SME.CRPCON";
    case 14:
      return "SPECIAL";
    case 15:
      return "SUPERFARE";

    case 16:
      return "ECONOMY LITE";
    case 17:
      return "ECONOMY SMART";
    case 18:
      return "ECONOMY PRIME";
    case 19:
      return "ECONOMY CLASSIC";
    case 20:
      return "ECONOMY CONVENIENCE";
    case 21:
      return "COMFORT";
    case 22:
      return "BASIC FARE";
    case 23:
      return "VALUE FARE";
    case 24:
      return "EXTRA FARE";
    case 25:
      return "YL|ECONOMY LIGHT";
    case 26:
      return "EC|ECONOMY SMART";
    case 27:
      return "YF|ECONOMY FLEX";
    case 28:
      return "SPECIAL CP";
    case 29:
      return "SPICE FLEX";
    case 30:
      return "XPRESS VALUE CLASS";
    case 31:
      return "XPRESS FLEX CLASS";
    case 32:
      return "CORPORATE VALUE CLASS";

    case 33:
      return "LIGHT";
    case 34:
      return "STANDARD";
    case 35:
      return "PROMO FARE";
    case 36:
      return "CORP CONNECT FARE";
    case 37:
      return "REGULAR FARE";

    case 38:
      return "ECO VALUE";
    case 39:
      return "ECONOMY COMFORT";
    case 40:
      return "ECOFLEX|ECONOMY FLEX";
    case 41:
      return "VALUE";
    case 42:
      return "YS|ECONOMY FLEX";
    case 43:
      return "YR|ECONOMY SPECIAL";
    case 44:
      return "YP|ECONOMY SAVER";
    case 45:
      return "YF|ECONOMY FLEX PLUS";
    case 46:
      return "LITE";
    case 47:
      return "ECONOMY LIGHT";
    case 48:
      return "YF|ECONOMY FLEX";

    default:
      return "";
  }
};

const Agentmarkup = () => {
  const { user } = useAuthContext();
  const [form] = Form.useForm();
  const { activeCurrency, currencyValue, currencyRatio } = useCurrencyContext();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [serviceType, setServiceType] = useState(0);
  const [column, setColumn] = useState([]);
  const [Markuplist, setMarkuplist] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [id, setId] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const [operatorList, setOperatorList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [currencies, setCurrencies] = useState({ INR: 1 });
  useEffect(() => {
    APIClient.get("admin/currencyConversionLatest")
      .then((resp) => {

        if (resp?.status == 200) {
          setCurrencies(resp.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (user && user?.UserID) {
      getAgentMarkup(user?.UserID);
    }
    getActivitiesCategory();
  }, [user]);

  useEffect(() => {
    GetOperators();
  }, [serviceType]);

  const searchData = () => {
    setShowSearchBox(true);
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );
  const getActivitiesCategory = () => {
    APIClient.get(`extranet/categories`)
      .then((resp) => {
        if (resp.status == 200) {
          setCategoryList(resp.data);
        }
      })
      .catch((error) => { });
  };

  /********** Filter Table Data *********************/

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = Markuplist.filter((item) => {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableData(searchResults);
    } else {
      setTableData(Markuplist);
    }
  };

  /************************ API CALLS **********************/

  const getAgentMarkup = (id, ServiceType = 0) => {
    setMarkuplist([]);
    setTableData([]);
    APIClient.get(`admin/agentMarkupByUserId/${id}`)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              RoleTypeName: "Agent",
              CabinTypeName: getCabinType(item?.CabinType),
              FareTypeName: getAgentFareType(item?.FareType),
              BusTypeName: getBusType(item.BusType),
              OperatorName:
                item.Operator != null ? item?.Operator?.OperatorName : "",
              CategoryName:
                item.ServiceType === 4 ? item?.Category?.categoryName : "",
              CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          setMarkuplist(data);
          if (ServiceType === 0) {
            setTableData(data);
          } else {
            filterDataByServiceType(data, ServiceType);
          }
        }
      })
      .catch((error) => { });
  };

  function filterDataByServiceType(data, type = false) {
    const serId = type ? type : serviceType;

    let filteredData = data.filter((item) => item.ServiceType === serId);

    setTableData(filteredData);
  }

  const GetOperators = () => {
    APIClient.get("admin/operators")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.filter(
            (item) => item.ServiceType === serviceType
          );
          setOperatorList(data);
        }
      })
      .catch((error) => { });
  };

  const deleteMarkup = (rec) => {
    APIClient.delete(`admin/agentMarkup/${rec.ID}`)

      .then((res) => {
        if (res.status == 200) {
          getAgentMarkup(user?.UserID, rec.ServiceType);
          message.success("Deleted  successfully", 3);
        }
      })
      .catch((error) => { });
  };
  const CurRatio = user?.Role?.RoleId === 5 ? Number(currencies[user?.Currency]) : Number(currencyRatio);

  const Updatemarkup = (currentData) => {
    setId(currentData.ID);
    if (currentData) {
      form.setFieldsValue({
        ...currentData,
        ServiceType: currentData.ServiceType,
        StarRating: currentData.StarRating,
        FareType: currentData.FareType,
        CabinType: currentData.CabinType,
        BusType: currentData.BusType,
        CategoryID:
          currentData.ServiceType === 4
            ? currentData?.Category?.categoryId
            : "",
        OperatorID: currentData.Operator.OperatorServiceID,
        MarkupType: currentData.MarkupType,
        Amount:
          currentData.MarkupType === 0
            ? currentData.Markup / CurRatio
            : currentData.Markup,
      });
    }
    setIsEditMode(true);
  };
  const updatedetails = (formdata) => {
    APIClient.put(`admin/agentMarkup/${id}`, {}, formdata)
      .then((response) => {
        if (response.status == 200) {
          setId(-1);
          setIsEditMode(false);
          form.resetFields();
          form.setFieldsValue({
            ServiceType: formdata.ServiceType,
          });
          getAgentMarkup(user?.UserID, formdata.ServiceType);
          message.success("Markup  Updated SuccessFully", 3);
        } else if (response.status == 404 && response.status == 500) {
          message.error(response.message, 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => { });
  };

  const Submitvalues = (values) => {
    // console.log(CurRatio, values, "ratio");
    const data = {
      UserId: user?.UserID ?? 1,
      ServiceType: values.ServiceType,
      RoleType: 5,
      StarRating: values.StarRating,
      FareType: values.FareType,
      CabinType: values.CabinType,
      BusType: values.BusType,
      CategoryID: values.CategoryID,
      OperatorID: values.OperatorID,
      Status: 0,
      MarkupType: values.MarkupType,
      Markup:
        values.MarkupType === 0 ? values.Amount * CurRatio : values.Amount,
      currencyRatio: CurRatio,
      currency: user?.Currency,
      DeviceOS: 1,
      ApplicationType: 1,
      CreatedBy: 5,
      ModifiedBy: 0,
      DeviceToken: "string",
      DeviceType: "string",
      FirBaseToken: "string",
      DeviceOSVersion: "string",
    };

    const upData = {
      UserId: user?.UserID ?? 1,
      ServiceType: values.ServiceType,
      RoleType: 5,
      StarRating: values.StarRating,
      FareType: values.FareType,
      CabinType: values.CabinType,
      BusType: values.BusType,
      CategoryID: values.CategoryID,
      OperatorID: values.OperatorID,
      Status: 0,
      MarkupType: values.MarkupType,
      Markup:
        values.MarkupType === 0 ? values.Amount * CurRatio : values.Amount,
      currencyRatio: CurRatio,
      currency: user?.Currency,
      DeviceOS: 1,
      ApplicationType: 1,
      CreatedBy: 5,
      ModifiedBy: 0,
      DeviceToken: "string",
      DeviceType: "string",
      FirBaseToken: "string",
      DeviceOSVersion: "string",
    };
    isEditMode ? updatedetails(upData) : postdata(data);
  };

  const postdata = (formMapData) => {
    APIClient.post("admin/agentMarkup", formMapData)
      .then((response) => {
        if (response.status == 200) {
          message.success("Markup Added SuccessFully", 3);
          getAgentMarkup(user?.UserID, formMapData.ServiceType);
          form.resetFields();
          form.setFieldsValue({
            ServiceType: formMapData.ServiceType,
          });
        } else if (response.status == 201) {
          message.error("Markup Inserted Successfully", 3);
        } else if (response.status == 404 && response.status == 500) {
          message.error(response.message, 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /************************ SELCTED SERVICE *******************/

  const selectServiceType = (value) => {
    if (isEditMode) {
      setId(-1);
      setIsEditMode(false);
      form.resetFields();
      form.setFieldsValue({
        ServiceType: value,
      });
    }
    setServiceType(value);
    filterDataByServiceType(Markuplist, value);
    if (value == 1) {
      setColumn(Flightcolumn);
    } else if (value == 2) {
      setColumn(hotelcolumn);
      // } else if (value == 3) {
      //   setColumn(buscolumn);
      // } else if (value == 4) {
      //   setColumn(Activitycolumn);
    } else if (value == 5) {
      setColumn(Transfercolumn);
    } else {
      setColumn(AllServices);
    }
  };

  /***************** Table Action ******************/

  const tableActions = (CurrentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => Updatemarkup(CurrentItem)}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteMarkup(CurrentItem)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  /**************************** SERVICE COLUMNS *****************************/
  // const buscolumn = [
  //   {
  //     title: "Service",
  //     dataIndex: "ServiceName",
  //   },

  //   {
  //     title: "Markup",
  //     dataIndex: "Markup",
  //     render: (value, currentData) => <>{value + " " + getTypeSign(currentData.MarkupType)}</>,
  //   },

  //   {
  //     title: "OperatorName",
  //     dataIndex: "OperatorName",
  //   },
  //   {
  //     title: "Bus Type",
  //     dataIndex: "BusTypeName",
  //   },
  //   {
  //     title: "CreatedDate",
  //     dataIndex: "CreatedDate",
  //   },
  //   {
  //     render: (currentItem) => tableActions(currentItem),
  //     title: "Actions",
  //   },
  // ];
  const AllServices = [
    {
      title: "Service",
      dataIndex: "ServiceName",
    },

    {
      title: `Markup (${user?.Currency})`,
      dataIndex: "Markup",
      render: (value, currentData) =>
        currentData?.MarkupType === 1
          ? value + " " + getTypeSign(currentData.MarkupType)
          : currencyValue(value, user?.Currency),
      // render: (value, currentData) => <>{value + " " + getTypeSign(currentData.MarkupType)}</>,
    },
    {
      title: "StarRating",
      dataIndex: "StarRating",
    },

    {
      title: "CreatedDate",
      dataIndex: "CreatedDate",
    },
    {
      title: "OperatorName",
      dataIndex: "OperatorName",
    },
    {
      title: "CabinType",
      dataIndex: "CabinTypeName",
    },
    {
      title: "Fare Type",
      dataIndex: "FareTypeName",
    },
    {
      title: "Bus Type",
      dataIndex: "BusTypeName",
    },
    {
      title: "Category",
      dataIndex: "CategoryName",
    },
    {
      title: "CreatedDate",
      dataIndex: "CreatedDate",
    },
    {
      render: (currentItem) => tableActions(currentItem),
      title: "Actions",
    },
  ];
  const hotelcolumn = [
    {
      title: "Service",
      dataIndex: "ServiceName",
    },

    {
      title: `Markup (${user?.Currency})`,
      dataIndex: "Markup",
      render: (value, currentData) =>
        currentData?.MarkupType === 1
          ? value + " " + getTypeSign(currentData.MarkupType)
          : currencyValue(value, user?.Currency),
    },

    // {
    //   title: "Markup",
    //   dataIndex: "Markup",
    //   render: (value, currentData) => <>{value + " " + getTypeSign(currentData.MarkupType)}</>,
    // },

    {
      title: "StarRating",
      dataIndex: "StarRating",
    },
    {
      title: "OperatorName",
      dataIndex: "OperatorName",
    },
    {
      title: "CreatedDate",
      dataIndex: "CreatedDate",
    },
    {
      render: (currentItem) => tableActions(currentItem),
      title: "Actions",
    },
  ];
  const getCommission = (value, type) => {
    // console.log(value, type, "ttttt");
    return (
      <>
        {type != 1 ? (
          <>{currencyValue(value)} </>
        ) : (
          <>
            {value}
            {"  %"}
          </>
        )}
      </>
    );
  };
  const Flightcolumn = [
    {
      title: "Service",
      dataIndex: "ServiceName",
    },

    {
      title: `Markup (${user?.Currency})`,
      dataIndex: "Markup",
      render: (value, currentData) =>
        currentData?.MarkupType === 1
          ? value + " " + getTypeSign(currentData.MarkupType)
          : currencyValue(value, user?.Currency),
      // render: (value, currentData) => <> {getCommission(value, currentData.MarkupType)}</>,
      //* {value + " " + getTypeSign(currentData.MarkupType)}</>, */
    },

    {
      title: "OperatorName",
      dataIndex: "OperatorName",
    },

    {
      title: "CabinType",
      dataIndex: "CabinTypeName",
    },
    {
      title: "Fare Type",
      dataIndex: "FareTypeName",
    },
    {
      title: "CreatedDate",
      dataIndex: "CreatedDate",
    },
    {
      render: (currentItem) => tableActions(currentItem),
      title: "Actions",
    },
  ];
  const Activitycolumn = [
    {
      title: "Service",
      dataIndex: "ServiceName",
    },
    {
      title: `Markup (${user?.Currency})`,
      dataIndex: "Markup",
      render: (value, currentData) =>
        currentData?.MarkupType === 1
          ? value + " " + getTypeSign(currentData.MarkupType)
          : currencyValue(value, user?.Currency),
      // render: (value, currentData) => <>{value + " " + getTypeSign(currentData.MarkupType)}</>,
    },

    {
      title: "StarRating",
      dataIndex: "StarRating",
    },

    {
      title: "Category",
      dataIndex: "CategoryName",
    },
    {
      title: "CreatedDate",
      dataIndex: "CreatedDate",
    },
    {
      render: (currentItem) => tableActions(currentItem),
      title: "Actions",
    },
  ];
  const Transfercolumn = [
    {
      title: "Service",
      dataIndex: "ServiceName",
    },

    {
      title: `Markup (${user?.Currency})`,
      dataIndex: "Markup",
      render: (value, currentData) =>
        currentData?.MarkupType === 1
          ? value + " " + getTypeSign(currentData.MarkupType)
          : currencyValue(value, user?.Currency),
      // render: (value, currentData) => <>{value + " " + getTypeSign(currentData.MarkupType)}</>,
    },
    {
      title: "CreatedDate",
      dataIndex: "CreatedDate",
    },
    {
      render: (currentItem) => tableActions(currentItem),
      title: "Actions",
    },
  ];
  const selectCustomFilter = (input, option) => {
    let istext =
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    if (istext) {
      return true;
    }
  };

  return (
    <div className="manage-markup-section ourteam-bg">
      <div className="container-fluid">
        <div className="card-bt-gap">
          <Card bordered={false} className="retrieve-booking">
            <div className="card-add-heading">
              <h5 className="mb-3">Partner Markup</h5>
            </div>

            <Form form={form} layout={"vertical"} onFinish={Submitvalues}>
              <Row gutter={[8, 8]}>
                <Col md={6} sm={12} xs={24}>
                  <Form.Item
                    label="Service Type"
                    name="ServiceType"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Please Select"
                      onSelect={selectServiceType}
                    >
                      <Option value={1}>Flights</Option>
                      <Option value={2}>Hotel</Option>
                      <Option value={5}>Transfer</Option>
                      {/* <Option value={3}>Bus</Option> */}
                      {/* <Option value={4}>Activities</Option> */}
                    </Select>
                  </Form.Item>
                </Col>
                {serviceType === 1 && (
                  <>
                    <Col md={6} sm={12} xs={24}>
                      <Form.Item
                        label="Operators"
                        name="OperatorID"
                        rules={[{ required: true }]}
                      >
                        <Select
                          showSearch
                          placeholder="Select Operator"
                          filterOption={(val, op) =>
                            selectCustomFilter(val, op)
                          }
                        >
                          {operatorList.map((item, index) => (
                            <option key={index} value={item.OperatorServiceID}>
                              {item.OperatorName}
                            </option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={24}>
                      <Form.Item
                        label="FareType"
                        name="FareType"
                        rules={[{ required: true }]}
                      >
                        <Select placeholder="Flights">
                          {/* <option value={1}>General</option>
                          <option value={2}>CoupanFare</option>
                          <option value={3}>CorporateFare</option>
                          <option value={4}>SMEFare</option>
                          <option value={5}>PPNR</option> */}
                          <Option value={1}>ALL</Option>
                          <Option value={2}>GENERAL</Option>
                          <Option value={3}>PUBLISH</Option>
                          <Option value={4}>INSTANTPUR</Option>
                          <Option value={5}>SME</Option>
                          <Option value={6}>SAVER</Option>
                          <Option value={7}>CORPORATE</Option>
                          <Option value={8}>COUPON</Option>
                          <Option value={9}>FLEXI</Option>
                          <Option value={10}>NDC</Option>
                          <Option value={11}>TACTICAL</Option>
                          <Option value={12}>SUPER6E</Option>
                          <Option value={13}>SME.CRPCON</Option>
                          <Option value={14}>SPECIAL</Option>
                          <Option value={15}>SUPERFARE</Option>
                          <Option value={16}>ECONOMY LITE</Option>
                          <Option value={17}>ECONOMY SMART</Option>
                          <Option value={18}>ECONOMY PRIME</Option>
                          <Option value={19}>ECONOMY CLASSIC</Option>
                          <Option value={20}>ECONOMY CONVENIENCE</Option>
                          <Option value={21}>COMFORT</Option>
                          <Option value={22}>BASIC FARE</Option>
                          <Option value={23}>VALUE FARE</Option>
                          <Option value={24}>EXTRA FARE</Option>
                          <Option value={25}>YL|ECONOMY LIGHT</Option>
                          <Option value={26}>EC|ECONOMY SMART</Option>
                          <Option value={27}>YL|ECONOMY FLEX</Option>
                          <Option value={28}>SPECIAL CP</Option>
                          <Option value={29}>SPICE FLEX</Option>
                          <Option value={30}>XPRESS VALUE CLASS</Option>
                          <Option value={31}>XPRESS FLEX CLASS</Option>
                          <Option value={32}>CORPORATE VALUE CLASS</Option>
                          <Option value={33}>LIGHT</Option>
                          <Option value={34}>STANDARD</Option>
                          <Option value={35}>PROMO FARE</Option>
                          <Option value={36}>CORP CONNECT FARE</Option>
                          <Option value={37}>REGULAR FARE</Option>
                          <Option value={38}>ECO VALUE</Option>
                          <Option value={39}>ECONOMY COMFORT</Option>
                          <Option value={40}>ECOFLEX|ECONOMY FLEX</Option>
                          <Option value={41}>VALUE</Option>
                          <Option value={42}>YS|ECONOMY FLEX</Option>
                          <Option value={43}>YR|ECONOMY SPECIAL</Option>
                          <Option value={44}>YP|ECONOMY SAVER</Option>
                          <Option value={45}>YF|ECONOMY FLEX PLUS</Option>
                          <Option value={46}>LITE</Option>
                          <Option value={47}>ECONOMY LIGHT</Option>
                          <Option value={48}>YF|ECONOMY FLEX</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={24}>
                      <Form.Item
                        label="Select Cabin Type"
                        name="CabinType"
                        rules={[{ required: true }]}
                      >
                        <Select placeholder="Select Cabin Type">
                          <Option value={1}>Economy Premium</Option>
                          <Option value={2}>Business</Option>
                          <Option value={3}>First Class</Option>
                          <Option value={4}>Economy</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                )}
                {/* {serviceType === 3 && (
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item label="Bus Type" name="BusType" rules={[{ required: true }]}>
                      <Select placeholder="Please Select">
                        <Option value={1}>RTC Buses</Option>
                        <Option value={2}>Private Travels</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                )} */}

                {[2, 4].includes(serviceType) && (
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                      name="StarRating"
                      label="Rating"
                      rules={[{ required: true }]}
                    >
                      <Select placeholder="Rating">
                        <option value={1}>1 Star</option>
                        <option value={2}>2 Star</option>
                        <option value={3}>3 Star</option>
                        <option value={4}>4 Star</option>
                        <option value={5}>5 Star</option>
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {[3].includes(serviceType) && (
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                      label="Operators"
                      name="OperatorID"
                      rules={[{ required: true }]}
                    >
                      <Select
                        showSearch
                        placeholder="Select Operator"
                        filterOption={(val, op) => selectCustomFilter(val, op)}
                      >
                        {operatorList.map((item, index) => (
                          <option key={index} value={item.OperatorServiceID}>
                            {item.OperatorName}
                          </option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {serviceType === 4 && (
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                      label="Category"
                      name="CategoryID"
                      rules={[{ required: true }]}
                    >
                      <Select placeholder="Category">
                        {categoryList.map((data, index) => (
                          <Select.Option key={index} value={data.categoryId}>
                            {data.categoryName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {serviceType != 0 && (
                  <>
                    <Col md={6} sm={12} xs={24}>
                      <Form.Item
                        label="Markup Type"
                        name="MarkupType"
                        rules={[
                          {
                            required: true,
                            message: "markup is required",
                          },
                        ]}
                      >
                        <Select placeholder="Please select">
                          <Option value={0}>Fixed</Option>
                          <Option value={1}>Percentage</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={6} sm={12} xs={24}>
                      <Form.Item
                        label="Markup Amount"
                        name="Amount"
                        rules={[
                          {
                            required: true,
                            message: "markup is required",
                          },
                        ]}
                      >
                        <Input placeholder="Amount" />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>

              {serviceType != 0 && (
                <Row className="reports-btns">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ backgroundColor: "#bc0c21" }}
                  >
                    {isEditMode ? "Update" : "Add"}
                  </Button>
                  {isEditMode ? (
                    <Button
                      type="danger"
                      onClick={() => {
                        form.resetFields();
                        setServiceType(0);
                        setIsEditMode(false);
                        setId(-1);
                      }}
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      type="danger"
                      onClick={() => {
                        form.resetFields();
                        setServiceType(0);
                      }}
                    >
                      Reset
                    </Button>
                  )}
                </Row>
              )}
            </Form>
          </Card>
        </div>
        {serviceType != 0 && (
          <div className="card-bt-gap">
            <Card bordered={false} className="retrieve-booking">
              <div className="card-add-heading">
                <div className="rows-count">
                  <div>
                    <h5>View Report</h5>
                    <p>{tableData.length} rows found !</p>
                  </div>
                </div>
                <div className="action-images">
                  {showSearchBox && (
                    <Input
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px", width: "250px" }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <img
                    src={search}
                    alt="search"
                    onClick={(e) => searchData()}
                  />
                </div>
              </div>
              <Table
                scroll={{ x: true }}
                columns={column}
                dataSource={tableData}
                pagination={{
                  defaultPageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100", "125"],
                }}
              />
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdatedComponent(Agentmarkup);
