import React from "react";
import { Button, Card, Checkbox, Form } from "antd";
import PayGateway from "../../helpers/PayGateway";
import "./FlightsCheckout.scss";

const BookPayCard = (props) => {
  const [form] = Form.useForm();

  const goTo = () => {
    if (props.bookpaycardinfo === "flight-review") {
      props.processPayGateway();
    } else if (props.bookpaycardinfo === "flight-checkout") {
      props.redirectToPreview();
    }
  };
  const { Insurance } = props;

  return (
    <div className="book-pay-section">
      <div className="book-pay-card">
        {props.pgDisplay ? (
          <PayGateway
            blockApiReq={props.blockApiReq}
            loadingSpin={props.loadingSpin}
          />
        ) : (
          <Form form={form} onFinish={goTo}>
            <div className="book-pay-tc">
              {props.bookpaycardinfo === "flight-checkout" ? (
                <>
                  <Form.Item
                    name="remember"
                    rules={[
                      {
                        validator: (rule, value) => {
                          return value
                            ? Promise.resolve()
                            : Promise.reject("Accept Terms & Conditions");
                        },
                      },
                    ]}
                    valuePropName="checked"
                  >
                    <Checkbox>
                      I Agree To All The{" "}
                      <a
                        href="/termsofconditions"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" Terms & Conditions"}{" "}
                      </a>{" "}
                      and{" "}
                      <a
                        href="/privacypolicy"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        Privacy Policy.
                      </a>
                    </Checkbox>
                  </Form.Item>
                </>
              ) : null}
            </div>
            <div className="book-pay-btn">
              <Button
                disabled={props.isLoading}
                className="btn btn-md"
                htmlType="submit"
              >
                Continue
              </Button>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};

export default BookPayCard;
