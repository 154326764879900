import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import moment from "moment";

const TopFlights = ({ dealsList }) => {
  const tomorrowDate = moment().add(1, "days").format("YYYY-MM-DD");
  const filteredList = dealsList?.filter(({ serviceType }) => serviceType === 2) || [];

  return filteredList.length ? (
    <div className="container">
      <Row>
        <Col md={24} xs={24}>
          <h2 className="top-fl-route">Top Flight Routes</h2>
        </Col>
      </Row>
      <div className="sec-bg-top">
        <Row gutter={16}>
          {filteredList.map(({ FromAirportCode, ToAirportCode }, index) => (
            <Col key={`flight-${index}`} md={12} xs={24} className="gap-cols-sec">
              <div className="tab-content current">
                <ul>
                  <li className="top-flight-li">
                    <a className="hm_link">{`${FromAirportCode} to ${ToAirportCode} (${FromAirportCode} to ${ToAirportCode})`}</a>
                    <Link
                      to={`/flight/results?adultCount=1&airTravelType=oneWay&cabinclassName=Economy&childCount=0&infantCount=0&departureDateTime=${tomorrowDate}&flightDateFlex=1&origin=${FromAirportCode}&destination=${ToAirportCode}`}
                      className="srch_btn"
                    >
                      Search Flight
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  ) : null;
};

export default TopFlights;
