import moment from "moment";

export const createReportFromApiReponse = (bookings) => {
  let response = {
    flights: [],
    hotels: [],
    transfers: [],
    activities: [],
  };
  bookings.forEach((booking) => {
    if (booking.type === "Bus") {
      booking.month = moment(booking.journeyDate, "DD-MM-YYYY").month();
      booking.year = moment(booking.journeyDate, "DD-MM-YYYY").year();
      booking.formatedDate = booking.journeyDate;
    } else if(booking.type==="transfer") {
      booking.month = moment(booking.journeyDate).month();
      booking.year = moment(booking.journeyDate).year();
      booking.formatedDate = moment(booking.journeyDate, "YYYY-MM-DD").format(
        "DD-MM-YYYY"
      );
    }else if(booking.type==="Hotel") {
      booking.month = moment(booking.CheckInDate).month();
      booking.year = moment(booking.CheckInDate).year();
      booking.formatedDate = moment(booking.CheckInDate, "YYYY-MM-DD").format(
        "DD-MM-YYYY"
      );
    }
    else {
      booking.month = moment(booking.journeyDate).month();
      booking.year = moment(booking.journeyDate).year();
      booking.formatedDate = moment(booking.journeyDate, "YYYY-MM-DD").format(
        "DD-MM-YYYY"
      );
    }

    if (booking.type == "Hotel" && booking.BookingStatus == "CONFIRMED") {
      response.hotels.push(booking);
    } else if (
      booking.type == "Flight" &&
      booking.BookingStatus == "CONFIRMED"
    ) {
      response.flights.push(booking);
    } else if (booking.type == "transfer" && booking.BookingStatus == "CONFIRMED") {
      response.transfers.push(booking);
    } else if (
      booking.type == "Activities" &&
      booking.BookingStatus == "CONFIRMED"
    ) {
      response.activities.push(booking);
    }
  });

  return response;
};
