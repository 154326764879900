import { IdcardOutlined } from "@ant-design/icons";
import * as ANTD from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "../Dubaivisa/Dubaivisa.scss";

const Dubaivisa = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const { TabPane } = ANTD.Tabs;
  const { Panel } = ANTD.Collapse;

  return (
    <div>
      <div className="promo providerapi dubai-bg-img">
        <ANTD.Layout className="ourteam-bg">
          <div className="">
            <Content className="admin-container">
              <ANTD.Row>
                <ANTD.Col md={24} xs={24}>
                  <ANTD.Breadcrumb>
                    <ANTD.Breadcrumb.Item>Home</ANTD.Breadcrumb.Item>
                    <ANTD.Breadcrumb.Item>
                      <a href="">Visa</a>
                    </ANTD.Breadcrumb.Item>
                    <ANTD.Breadcrumb.Item>
                      <a href="">Dubai Visa</a>
                    </ANTD.Breadcrumb.Item>
                  </ANTD.Breadcrumb>
                </ANTD.Col>
              </ANTD.Row>

              {/* --visa--online--startt */}

              <ANTD.Row>
                <ANTD.Col md={24} xs={24}>
                  <h3 className="online-visa-form">Dubai Visa</h3>

                  <div className="online-visa-form-1">
                    <div className="hero-text-block f1">
                      <p>Processing time</p>
                      <span>4 to 5 days</span>
                    </div>

                    <div className="hero-text-block2">
                      <p>Starting from</p>
                      <span>1,950/-*</span>
                    </div>
                  </div>
                </ANTD.Col>
              </ANTD.Row>

              {/* --visa--online--close */}
            </Content>
          </div>
        </ANTD.Layout>
      </div>

      <ANTD.Layout className="ourteam-bg">
        <Content className="admin-container cms-pages-width">
          <ANTD.Row>
            <ANTD.Col md={18} xs={24}>
              <div className="">
                <div className="">
                  <ANTD.Tabs defaultActiveKey="1">
                    <TabPane tab="Types Of Visas" key="1">
                      <h5>Type of Dubai Visas</h5>
                      {/* --first--row */}
                      <ANTD.Row>
                        <ANTD.Col md={12} xs={24}>
                          <ANTD.Card
                            title="96 Hours Transit Visa + Insurance"
                            className="card-inu"
                          >
                            <div className="card-new-visa">
                              <ul className="visa-type left">
                                <li>
                                  Processing time:
                                  <span>Upto 5 days</span>
                                </li>
                                <li>
                                  Stay period :<span>4 days</span>
                                </li>
                                <li>
                                  Validity :<span>30 days</span>
                                </li>
                                <li>
                                  Entry:
                                  <span>Single</span>
                                </li>
                                <li>
                                  Fees:
                                  <span className="visa-price">
                                    {" "}
                                    INR 2,499/-
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </ANTD.Card>
                        </ANTD.Col>

                        <ANTD.Col md={12} xs={24}>
                          <ANTD.Card
                            title="96 Hours Transit Visa + Insurance"
                            className="card-inu"
                          >
                            <div className="card-new-visa">
                              <ul className="visa-type left">
                                <li>
                                  Processing time:
                                  <span>Upto 5 days</span>
                                </li>
                                <li>
                                  Stay period :<span>4 days</span>
                                </li>
                                <li>
                                  Validity :<span>30 days</span>
                                </li>
                                <li>
                                  Entry:
                                  <span>Single</span>
                                </li>
                                <li>
                                  Fees:
                                  <span className="visa-price">
                                    {" "}
                                    INR 2,499/-
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </ANTD.Card>
                        </ANTD.Col>
                      </ANTD.Row>

                      {/* --first--row */}

                      {/* --second--row */}
                      <ANTD.Row className="visa-days-pol">
                        <ANTD.Col md={12} xs={24}>
                          <ANTD.Card
                            title="14 Days Tourist Visa (Express) + Insurance"
                            className="card-inu"
                          >
                            <div className="card-new-visa">
                              <ul className="visa-type left">
                                <li>
                                  Processing time:
                                  <span>Upto 5 days</span>
                                </li>
                                <li>
                                  Stay period :<span>4 days</span>
                                </li>
                                <li>
                                  Validity :<span>30 days</span>
                                </li>
                                <li>
                                  Entry:
                                  <span>Single</span>
                                </li>
                                <li>
                                  Fees:
                                  <span className="visa-price">
                                    {" "}
                                    INR 2,499/-
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </ANTD.Card>
                        </ANTD.Col>

                        <ANTD.Col md={12} xs={24}>
                          <ANTD.Card
                            title="30 Days Tourist Visa (Popular) + Insurance"
                            className="card-inu"
                          >
                            <div className="card-new-visa">
                              <ul className="visa-type left">
                                <li>
                                  Processing time:
                                  <span>Upto 5 days</span>
                                </li>
                                <li>
                                  Stay period :<span>4 days</span>
                                </li>
                                <li>
                                  Validity :<span>30 days</span>
                                </li>
                                <li>
                                  Entry:
                                  <span>Single</span>
                                </li>
                                <li>
                                  Fees:
                                  <span className="visa-price">
                                    {" "}
                                    INR 2,499/-
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </ANTD.Card>
                        </ANTD.Col>
                      </ANTD.Row>

                      {/* --second--row */}

                      {/* --third--row */}

                      <ANTD.Row className="visa-days-pol">
                        <ANTD.Col md={12} xs={24}>
                          <ANTD.Card
                            title="30 Days Tourist Visa (Express) + Insurance"
                            className="card-inu"
                          >
                            <div className="card-new-visa">
                              <ul className="visa-type left">
                                <li>
                                  Processing time:
                                  <span>Upto 5 days</span>
                                </li>
                                <li>
                                  Stay period :<span>4 days</span>
                                </li>
                                <li>
                                  Validity :<span>30 days</span>
                                </li>
                                <li>
                                  Entry:
                                  <span>Single</span>
                                </li>
                                <li>
                                  Fees:
                                  <span className="visa-price">
                                    {" "}
                                    INR 2,499/-
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </ANTD.Card>
                        </ANTD.Col>

                        <ANTD.Col md={12} xs={24}>
                          <ANTD.Card
                            title="90 Days Tourist Visa + Insurance"
                            className="card-inu"
                          >
                            <div className="card-new-visa">
                              <ul className="visa-type left">
                                <li>
                                  Processing time:
                                  <span>Upto 5 days</span>
                                </li>
                                <li>
                                  Stay period :<span>4 days</span>
                                </li>
                                <li>
                                  Validity :<span>30 days</span>
                                </li>
                                <li>
                                  Entry:
                                  <span>Single</span>
                                </li>
                                <li>
                                  Fees:
                                  <span className="visa-price">
                                    {" "}
                                    INR 2,499/-
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </ANTD.Card>
                        </ANTD.Col>
                      </ANTD.Row>
                      {/* --third--row */}
                      {/* --fouth--row */}
                      <ANTD.Row className="visa-days-pol">
                        <ANTD.Col md={24} xs={24}>
                          <h5 className="visa-includes">Visa Price includes</h5>
                          <ul className="list-style-visa">
                            <li>Consulate fees</li>
                            <li>
                              Medical insurance (Covers Coronavirus and
                              everything else)
                            </li>
                            <li>Service charges</li>
                            <li>All taxes</li>
                          </ul>
                        </ANTD.Col>
                      </ANTD.Row>
                      {/* --fouth--row */}

                      {/* --fifth--row */}

                      <ANTD.Row className="visa-days-pol">
                        <ANTD.Col md={24} xs={24}>
                          <p>
                            Depending on the purpose of your planned visit and
                            duration of trip, you can apply for 96 hours, 14
                            days, 30 days (short term) or 90 days (long term)
                            Dubai Visa (Single & Multiple entry). We are one of
                            the leading Dubai Visa Experts offering visa
                            services at the lowest prices and minimum
                            documententation. Apply for your Dubai Visa online
                            from cities like Mumbai, Delhi, Pune, Bangalore,
                            Kochi, Hyderabad, Chennai, Amritsar & many more.
                          </p>
                        </ANTD.Col>
                      </ANTD.Row>
                      {/* --fifth--close */}
                    </TabPane>

                    {/* --f--tab--close */}

                    <TabPane tab="Documents" key="2">
                      <h5 className="visa-includes">
                        Documents required for Dubai Visa Online
                      </h5>

                      <ANTD.Row>
                        <ANTD.Col md={24} xs={24}>
                          <ANTD.Collapse>
                            <Panel
                              header="Must have Documents for Dubai Visa:"
                              key="1"
                            >
                              <ul className="list-style-visa">
                                <li>
                                  Scanned colour copy of first and last page of
                                  your valid Passport
                                </li>
                                <li>
                                  Scanned colour copy of your passport size
                                  photograph with white background
                                </li>
                                <li>
                                  Confirmed return air ticket (required for Ok
                                  to Board processing)
                                </li>
                              </ul>
                            </Panel>
                          </ANTD.Collapse>
                        </ANTD.Col>
                      </ANTD.Row>
                    </TabPane>
                    {/* --second-tab--close */}

                    <TabPane tab="Process" key="3">
                      <h5 className="visa-includes">Visa Process</h5>

                      <ANTD.Row>
                        <ANTD.Col md={24} xs={24}>
                          <ANTD.Collapse>
                            <Panel
                              header="Click here for detailed Visa process & Requirements"
                              key="1"
                            >
                              <img
                                src={
                                  require("../../../assets/images/visa/dubai-visa-process.jpg")
                                    .default
                                }
                                alt=""
                              />
                            </Panel>
                          </ANTD.Collapse>
                        </ANTD.Col>
                      </ANTD.Row>
                    </TabPane>

                    {/* --third-tab--close */}
                    <TabPane tab="Why choose us" key="4">
                      <h5 className="visa-includes">
                        Why choose us? Because we are Awesome!
                      </h5>
                      <ANTD.Row>
                        <ANTD.Col md={8} xs={24}>
                          <img
                            className="visa-data-img"
                            src={
                              require("../../../assets/images/visa/visa-p1.png")
                                .default
                            }
                            alt=""
                          />
                          <p className="visa-img-process">
                            Visa Services for all Countries
                          </p>
                        </ANTD.Col>

                        <ANTD.Col md={8} xs={24}>
                          <img
                            className="visa-data-img"
                            src={
                              require("../../../assets/images/visa/visa-p2.png")
                                .default
                            }
                            alt=""
                          />
                          <p className="visa-img-process">
                            40 years of experience in Visa processing
                          </p>
                        </ANTD.Col>

                        <ANTD.Col md={8} xs={24}>
                          <img
                            className="visa-data-img"
                            src={
                              require("../../../assets/images/visa/visa-p3.png")
                                .default
                            }
                            alt=""
                          />
                          <p className="visa-img-process">
                            150+ Branches Worldwide
                          </p>
                        </ANTD.Col>
                      </ANTD.Row>

                      <ANTD.Row>
                        <ANTD.Col md={8} xs={24}>
                          <img
                            className="visa-data-img"
                            src={
                              require("../../../assets/images/visa/visa-p4.png")
                                .default
                            }
                            alt=""
                          />
                          <p className="visa-img-process">
                            End-to-End Visa Assistance
                          </p>
                        </ANTD.Col>

                        <ANTD.Col md={8} xs={24}>
                          <img
                            className="visa-data-img"
                            src={
                              require("../../../assets/images/visa/visa-p5.png")
                                .default
                            }
                            alt=""
                          />
                          <p className="visa-img-process">
                            Pick Up & Drop of Documents from your Doorstep
                          </p>
                        </ANTD.Col>

                        <ANTD.Col md={8} xs={24}>
                          <img
                            className="visa-data-img"
                            src={
                              require("../../../assets/images/visa/visa-p6.png")
                                .default
                            }
                            alt=""
                          />
                          <p className="visa-img-process">
                            Safety & Confidentiality
                          </p>
                        </ANTD.Col>
                      </ANTD.Row>
                    </TabPane>

                    {/* --fourth-tab--close */}

                    <TabPane tab="FAQs" key="5">
                      <ANTD.Row>
                        <ANTD.Col md={24} xs={24}>
                          <ANTD.Collapse>
                            <Panel
                              header="Do I need visa for Dubai from India?"
                              key="1"
                            >
                              <p>
                                Yes, all Indian passport holders require a visit
                                to visit Dubai. A Dubai tourist visa is most
                                appropriate for Indians travelling to the UAE
                                for recreational purposes such as holidaying,
                                sightseeing, or even short family visits. The
                                type of visa to enter Dubai will depend on
                                several factors such as nationality, purpose of
                                your planned visit and duration. Indian passport
                                holders travelling to UAE for tourist purposes
                                can apply for Dubai visa online. Once your visa
                                application is approved, you will receive an
                                approved visa copy via email.
                              </p>
                            </Panel>
                            <Panel header="Is Dubai Visa on Arrival?" key="2">
                              <p>
                                Yes, Citizens of few countries do not require
                                advance visa arrangements to enter the UAE and
                                can obtain a visa on arrival. Indian nationals
                                holding a normal passport and a valid Visa, or a
                                Green Card issued by the USA, or UK or EU
                                Residency, can obtain a Dubai visa on arrival
                                for a maximum stay of 14 days. The US Visa, the
                                Green Card, the UK Resident Permit and the EU
                                Resident Permit must be valid for at least six
                                months from the date of arrival into the UAE.
                                The visa on arrival will be valid for 14 days,
                                extendable for a similar period one time only.
                                The validity of the passport must be not less
                                than six months and the fee for an entry permit
                                will be Dirham 100 (Rs 1,750).
                              </p>
                            </Panel>

                            <Panel
                              header="How do I get a Dubai Visa for Indians?"
                              key="3"
                            >
                              <p>
                                If you plan to visit Dubai for a short period,
                                you can obtain a Dubai visa online in the form
                                of ETA – Electronic Travel Authorization.
                                Applicants can apply for Dubai Visa online with
                                proper scanned copies of the documents from the
                                comfort of their homes. On successful
                                completion, Dubai Tourist Visa will be provided
                                through an email in PDF or JPG format. Akbar
                                Travels processes Dubai visas for Indians in as
                                little as 24 hours.
                              </p>
                            </Panel>
                            <Panel
                              header="What are the Dubai Visa requirements for Indians?"
                              key="4"
                            >
                              <p>
                                The Dubai Visa requirements for Indians are:
                              </p>
                              <ul className="list-style-visa">
                                <li>
                                  Scanned colour copy of first and last page of
                                  your valid Passport
                                </li>
                                <li>
                                  Scanned colour copy of your passport size
                                  photograph with white background
                                </li>
                                <li>
                                  Confirmed return air ticket (required for Ok
                                  to Board processing)
                                </li>
                              </ul>
                            </Panel>

                            <Panel
                              header="What are the eligibility criteria for Dubai Visa application?"
                              key="5"
                            >
                              <p>
                                To enable the authorities to process your
                                application, you must clearly state:
                              </p>
                              <ul className="list-style-visa">
                                <li>why are you visiting Dubai</li>
                                <li>how long you intend to stay in Dubai</li>
                                <li>
                                  how will you support yourself during your time
                                  in Dubai
                                </li>
                                <li>
                                  what is the purpose of your visit to Dubai
                                </li>
                                <li>
                                  type of passport you hold and for how long it
                                  is valid
                                </li>
                                <li>
                                  where do you intend to travel following your
                                  visit to Dubai
                                </li>
                                <li>
                                  if you have a permit to enter the country you
                                  intend to travel to after your stay in Dubai
                                </li>
                              </ul>
                            </Panel>
                          </ANTD.Collapse>
                        </ANTD.Col>
                      </ANTD.Row>
                    </TabPane>

                    {/* --fifth-tab--close */}

                    <TabPane tab="Visit us" key="8">
                      <ANTD.Row>
                        <ANTD.Col md={12} xs={24}>
                          <h5 className="visa-includes">Visit us</h5>
                          <ANTD.Collapse>
                            <Panel header="Hyderabad" key="1">
                              <p>
                                <b>Lorem ipsum</b>
                                <br />
                                1st floor, 62, Janjikar Street,
                                <br />
                                Near Crawford Market,
                                <br />
                                Hyderabad 500072
                              </p>
                            </Panel>
                            <Panel header="Hyderabad" key="2">
                              <p>
                                <b>Lorem ipsum</b>
                                <br />
                                1st floor, 62, Janjikar Street,
                                <br />
                                Near Crawford Market,
                                <br />
                                Hyderabad 500072
                              </p>
                            </Panel>
                            <Panel header="Hyderabad" key="3">
                              <p>
                                <b>Lorem ipsum</b>
                                <br />
                                1st floor, 62, Janjikar Street,
                                <br />
                                Near Crawford Market,
                                <br />
                                Hyderabad 500072
                              </p>
                            </Panel>
                          </ANTD.Collapse>
                        </ANTD.Col>
                        <ANTD.Col md={12} xs={24}>
                          <h5 className="visa-includes">Dubai Embassy</h5>
                          <ANTD.Card>
                            <p>
                              <b>Lorem ipsum</b>
                              <br />
                              1st floor, 62, Janjikar Street,
                              <br />
                              Near Crawford Market,
                              <br />
                              Hyderabad 500072
                            </p>
                          </ANTD.Card>
                        </ANTD.Col>
                      </ANTD.Row>
                    </TabPane>

                    {/* --sixth-tab--close */}

                    <TabPane tab="Embassy" key="9">
                      <ANTD.Row>
                        <ANTD.Col md={24} xs={24}>
                          <h5 className="visa-includes">
                            Basic Requirements to visit Dubai
                          </h5>
                          <ul className="list-style-visa">
                            <li>
                              Have a valid Passport and valid Dubai Tourist Visa{" "}
                            </li>
                            <li>
                              Have sufficient funds to support yourself and all
                              family members during your entire trip.
                            </li>
                            <li>
                              Thorough knowledge of your accommodation and
                              itinerary details to furnish at immigration if
                              asked to
                            </li>
                            <li>
                              Return or onward flight tickets to ensure return
                              to your home country or your next destination once
                              the visa expires
                            </li>
                            <li>
                              Adhere to the moral standards of the country and
                              follow the general rules
                            </li>
                            <li>
                              where do you intend to travel following your visit
                              to Dubai
                            </li>
                          </ul>

                          <h5 className="visa-includes">Travel Checklist</h5>
                          <ul className="list-style-visa">
                            <li>Passport with at least 6 months of validity</li>
                            <li>
                              Valid Dubai Visa depending on the purpose of your
                              visit and duration of stay
                            </li>
                            <li>Confirmed return or onward tickets</li>
                            <li>Details of accommodation</li>
                            <li>Travel itinerary</li>
                            <li>Foreign currency</li>
                            <li>
                              Any additional documents to support your purpose
                              of visit, especially for business visa travelers
                            </li>
                            <li>
                              Have all documents authenticated before traveling
                            </li>
                          </ul>

                          <h5 className="visa-includes">
                            What to do when you arrive in Dubai
                          </h5>
                          <ul className="list-style-visa">
                            <li>
                              Keep all your travel documents ready to be
                              produced for verification by the immigration
                              officers
                            </li>
                            <li>
                              Ensure you are not carrying any forbidden items
                              that will not pass through customs, including ‘any
                              immoral items liable to cause a breach of the
                              peace’ like books, audiobooks, audio and video
                              tapes
                            </li>
                            <li>Confirmed return or onward tickets</li>
                          </ul>
                        </ANTD.Col>
                      </ANTD.Row>
                    </TabPane>
                  </ANTD.Tabs>
                </div>
              </div>
            </ANTD.Col>
            {/* --col--18---close-- */}

            <ANTD.Col md={6} xs={24}>
              <div className="form-elements">
                <ANTD.Form>
                  <ANTD.Row>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Collapse accordion defaultActiveKey={["1"]}>
                        <Panel header="Apply Online" key="1">
                          <ANTD.Card>
                            <ANTD.Form.Item
                              name="Team Name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Email Id",
                                },
                              ]}
                            >
                              <ANTD.Input
                                placeholder="Email ID"
                                size={"large"}
                              />
                            </ANTD.Form.Item>
                            <ANTD.Form.Item
                              name="Contact No"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Contact No",
                                },
                              ]}
                            >
                              <ANTD.Input
                                placeholder="Contact No"
                                size={"large"}
                              />
                            </ANTD.Form.Item>

                            <ANTD.Form.Item>
                              <ANTD.Select
                                size={"large"}
                                prefix={
                                  <IdcardOutlined className="site-form-item-icon" />
                                }
                                placeholder="Visa type"
                              >
                                <ANTD.Select.Option value="demo">
                                  Visa type
                                </ANTD.Select.Option>
                                <ANTD.Select.Option value="demo">
                                  96 Hours Transit Visa
                                </ANTD.Select.Option>
                                <ANTD.Select.Option value="demo">
                                  14 Days Tourist Visa
                                </ANTD.Select.Option>
                                <ANTD.Select.Option value="demo">
                                  14 Days Tourist Visa (Express)
                                </ANTD.Select.Option>
                                <ANTD.Select.Option value="demo">
                                  30 Days Tourist Visa (Popular){" "}
                                </ANTD.Select.Option>
                                <ANTD.Select.Option value="demo">
                                  30 Days Tourist Visa (Express)
                                </ANTD.Select.Option>
                                <ANTD.Select.Option value="demo">
                                  90 Days Tourist Visa
                                </ANTD.Select.Option>
                              </ANTD.Select>
                            </ANTD.Form.Item>

                            <ANTD.Form.Item>
                              <ANTD.Select
                                size={"large"}
                                prefix={
                                  <IdcardOutlined className="site-form-item-icon" />
                                }
                                placeholder="Travellers"
                              >
                                <ANTD.Select.Option value="demo">
                                  Travellers
                                </ANTD.Select.Option>
                                <ANTD.Select.Option value="demo">
                                  1
                                </ANTD.Select.Option>
                                <ANTD.Select.Option value="demo">
                                  2
                                </ANTD.Select.Option>
                                <ANTD.Select.Option value="demo">
                                  3
                                </ANTD.Select.Option>
                                <ANTD.Select.Option value="demo">
                                  4{" "}
                                </ANTD.Select.Option>
                                <ANTD.Select.Option value="demo">
                                  5
                                </ANTD.Select.Option>
                                <ANTD.Select.Option value="demo">
                                  6
                                </ANTD.Select.Option>
                              </ANTD.Select>
                            </ANTD.Form.Item>
                            <Link to="/travellersvisadetails">
                              <ANTD.Button
                                className="visa-apply-btn"
                                type="primary"
                              >
                                Apply Now
                              </ANTD.Button>
                            </Link>
                          </ANTD.Card>
                        </Panel>

                        <Panel header="Lets us Call you" key="2">
                          <ANTD.Card>
                            <ANTD.Form.Item
                              name="Name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Name",
                                },
                              ]}
                            >
                              <ANTD.Input placeholder="Name" size={"large"} />
                            </ANTD.Form.Item>
                            <ANTD.Form.Item
                              name="Team Name"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Email Id",
                                },
                              ]}
                            >
                              <ANTD.Input
                                placeholder="Email ID"
                                size={"large"}
                              />
                            </ANTD.Form.Item>
                            <ANTD.Form.Item
                              name="Contact No"
                              rules={[
                                {
                                  required: true,
                                  message: "Please Enter Contact No",
                                },
                              ]}
                            >
                              <ANTD.Input
                                placeholder="Contact No"
                                size={"large"}
                              />
                            </ANTD.Form.Item>

                            <ANTD.Button
                              className="visa-apply-btn"
                              type="primary"
                            >
                              Submit
                            </ANTD.Button>
                          </ANTD.Card>
                        </Panel>
                      </ANTD.Collapse>
                    </ANTD.Col>
                  </ANTD.Row>
                </ANTD.Form>

                <ANTD.Row className="whatsapp-btn">
                  <ANTD.Col md={24} xs={24}>
                    <p className="all-detail-visa">Visa on whatsapp</p>
                    <ANTD.Button className="call-buttton" type="primary">
                      <i className="fa fa-whatsapp" aria-hidden="true"></i>
                      &nbsp;+91 7506865623
                    </ANTD.Button>
                  </ANTD.Col>
                </ANTD.Row>

                <ANTD.Row className="whatsapp-btn">
                  <ANTD.Col md={24} xs={24}>
                    <p className="all-detail-visa">Call us on</p>
                    <ANTD.Button className="call-buttton" type="primary">
                      <i className="fa fa-phone-square" aria-hidden="true"></i>
                      &nbsp;+91 7506865623
                    </ANTD.Button>
                  </ANTD.Col>
                </ANTD.Row>

                <ANTD.Row className="whatsapp-btn">
                  <ANTD.Col md={24} xs={24}>
                    <p className="all-detail-visa">Timing</p>
                    <ANTD.Button className="call-buttton" type="primary">
                      <i className="fa fa-clock-o" aria-hidden="true"></i>
                      &nbsp;9am to 9pm
                    </ANTD.Button>
                  </ANTD.Col>
                </ANTD.Row>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </ANTD.Layout>
    </div>
  );
};
export default Dubaivisa;
