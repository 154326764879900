import React, { useState, useCallback, useEffect, useRef } from "react";
import { Spin, Select } from "antd";
import APIClient from "../../helpers/ApiClient";
import { Form } from "antd";

const API_URL = "extranet/airlinenames/search/";
const FlightExtranetAirlineName = (props) => {
    const selectRef = useRef(null);
    useEffect(() => {
        let value = "";
        if (
            selectRef.current.props.value &&
            selectRef.current.props.value.includes("&&")
        )
            value = selectRef.current.props.value.split("&&")[0];
        fetchData(value);
    }, []);
    const { Option } = Select;
    const [details, setDetails] = useState({
        data: [],
        value: [],
        fetching: false,
    });

    const handleSelect = (_, op) => {
        if (props.handleName) {
            props.handleName(op.children);
        }
    };

    const debounceOnChange = useCallback(debounce(fetchData, 800), []);

    function fetchData(value) {
        if (value) {
            setDetails({ data: [], fetching: true });
            APIClient.get(`${props.api}${value}`)
                .then((res) => {
                    if (res.statusCode == 200 && res) {
                        setDetails({ data: res.data, fetching: false });
                        return;
                    }
                    setDetails({ data: [], fetching: false });
                })
                .catch((error) => {
                    console.error(error);
                    setDetails({ data: [], fetching: false });
                });
        }
    }

    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            const context = this;
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        };
    }
    return (
        <Form.Item {...props.formItemProps}>
            <Select
                showSearch
                ref={selectRef}
                notFoundContent={
                    details.fetching ? <Spin size="small" /> : "No Matches found."
                }
                filterOption={false}
                onSearch={debounceOnChange}
                onChange={props.onChange}
                onSelect={handleSelect}
                {...props.selectProps}
            >
                {details.data.map((d, index) => (
                    <Option value={`${d.nameAirline}&&${d.codeIataAirline}`} key={"key" + index}>
                        {d.nameAirline}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default FlightExtranetAirlineName;
