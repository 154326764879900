//#region lates code
import React, { useContext, useEffect, useState } from "react";
import "./FareDetails.scss";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Radio,
  message,
 
  Divider,
  List,
  Modal,
} from "antd";
import moment from "moment";
import { GlobalStatesContext } from "../../../common/providers";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { CarPriceHelper } from "../../../helpers/CarsHelpers/carPriceHelper";
import {
  getConvFee,
  getPromoDiscount,
} from "../../../common/AllTickets/promoConvhelper";
import APIClient from "../../../helpers/ApiClient";
import Login from "../../../components/Login/Login";
import { CloseOutlined } from "@ant-design/icons";

export const getCarGrandTotal = (
  selectedCarData,
  ConvFee,
  promoData,
  selectedInsuranceData
) => {
  let adminCommission = 0;
  let agentMarkup = 0;
  let markup = 0;
  let insuranceTotal = 0;
  let discount = 0;
  let convamount = 0;
  let totalBaseFare = 0;
  let totalGst = 0;
  let fareInfoData = selectedCarData
    ? Number(selectedCarData.BaseFare ? selectedCarData.BaseFare : 0) +
    Number(selectedCarData.StateTollTax ? selectedCarData.StateTollTax : 0) +
    Number(selectedCarData.GST ? selectedCarData.GST : 0) +
    Number(
      selectedCarData.DriverAllowance ? selectedCarData.DriverAllowance : 0
    )
    : null;

  
  if (selectedCarData?.type === "transfer") {
    totalBaseFare = selectedCarData?.Vehicles[0]?.TransferPrice?.totalBasePrice;
    totalGst = selectedCarData?.Vehicles[0]?.TransferPrice?.Tax;
  } else {
    totalBaseFare = fareInfoData;
  }
 
  let total = Number(totalBaseFare) + Number(totalGst);
  discount = getPromoDiscount(promoData, total);
  convamount = getConvFee(ConvFee, total);

  total = total + Number(convamount);

  total -= discount;


  let totalFareCalculations = Number(Number(total)).toFixed(2);

  

  return {
    totalBaseFare: Number(totalBaseFare).toFixed(2),
    totalGst: Number(totalGst).toFixed(2),
    discount: Number(discount).toFixed(2),
    totalFareCalculations,
    totalServiceAmount: Number(total).toFixed(2),
    convamount: Number(convamount).toFixed(2),
    
    insuranceTotal: Number(insuranceTotal).toFixed(2),
  };
};

const FareDetails = ({ selectedCarData, isPromoVisible, location }) => {
  const [form] = Form.useForm();
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const {
    state: {
      otherData: { ConvFee, promoData, selectedInsuranceData },
    },
    AddConvFee,
    validatePromoCode,
    RemovePromo,
    getInsuranceByServiceType,
  } = useContext(GlobalStatesContext);
  const dataChargeSource = [
    `Base Fare : ${selectedCarData.BaseFare ?? "00"} rs.`,
    `StateToll Tax : ${selectedCarData.StateTollTax ?? "00"} rs.`,
    `GST Amount : ${selectedCarData.GST ?? "00"} rs.`,
    `Driver Allowance : ${selectedCarData.DriverAllowance ?? "00"} rs.`,

  ];
  const dataTransferChargeSource = [
    `Base Fare :  ${selectedCarData.type === "transfer" ?
      selectedCarData?.Vehicles[0]?.TransferPrice?.BasePrice ?? "00" : "00"
    } rs.`,
    `GST Amount : ${selectedCarData.type === "transfer" ?
      selectedCarData?.Vehicles[0]?.TransferPrice?.GST ?? "00" : "00"
    } rs.`,
  ];
  const dataExtraChargeSource = [
    `Charge Per Extra KM : ${selectedCarData.ChargePerExtraKM ?? "00"} rs./km`,
    `Late Night Charge : ${selectedCarData.NightCharges ?? "00"} rs./KM`,
    `Late Pickup Charge : ${selectedCarData.LatePickupCharges ?? "00"} rs./km`,
    `Early Pickup Charge : ${selectedCarData.EarlyPickupCharges ?? "00"
    } rs./km`,
  ];
  const [Loc, setLoc] = useState({});
  useEffect(() => {
    if (location) {
      setLoc(location);
    }
  }, [location]);
  const [modalVisible, setModalVisible] = useState({
    visible: false,
    type: "USER",
  });
  const showModal1 = (type) => {
    /*============= check user logged or not =========== */
    setModalVisible({ visible: true, type: type });

  };
  const content = (Data) => {
    return (
      <div>
        <Divider orientation="left">Fare Charges</Divider>
        <List
          size="small"
          bordered
          dataSource={
            selectedCarData.type === "transfer"
              ? dataTransferChargeSource
              : dataChargeSource
          }
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
        <h6 style={{ color: "Red", marginTop: "10px", textAlign: "right" }}>
          {" "}
          TOTAL PRICE: INR{" "}
          {selectedCarData.type === "transfer"
            ? selectedCarData?.Vehicles[0]?.TransferPrice?.PublishedFares
            : `${Number(Data.BaseFare ? Data.BaseFare : 0) +
            Number(Data.StateTollTax ? Data.StateTollTax : 0) +
            Number(Data.GST ? Data.GST : 0) +
            Number(Data.DriverAllowance ? Data.DriverAllowance : 0)
            }`}
        </h6>
        {!selectedCarData.type === "transfer" ? (
          <>
            <Divider></Divider>
            <p style={{ color: "Green" }}>
              Extra Charges for Extra KM's and LateNight Charges:
            </p>
            <List
              size="small"
              bordered
              dataSource={dataExtraChargeSource}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </>
        ) : null}
      </div>
    );
  };
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [promoCode, setPromoCode] = useState([]);
  const [promoCodes, setPromoCodes] = useState([]);
  let dateFormat = "YYYY-MM-DD";
  let {
    totalBaseFare,
    totalGst,
    discount,
    convamount,
    totalFareCalculations,
    totalServiceAmount,
    insuranceTotal,
  } = getCarGrandTotal(
    selectedCarData,
    ConvFee,
    promoData,
    selectedInsuranceData
  );
  //  console.log(totalServiceAmount,"visa")
  useEffect(() => {
    getInsuranceByServiceType(3);
  }, []);

  useEffect(() => {
    if (!agent) {
      if (selectedCarData.type !== "transfer") {
        AddConvFee(7);
        getpromo();
      } else if (selectedCarData.type === "transfer") {
        AddConvFee(5);
        getpromo();
      }
    }
  }, [agent]);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, dateFormat).isSameOrAfter(moment(), 'day')
          );
          if (data.length > 0) {
            let carPromo = data.filter(
              (promo) =>
                promo.ServiceType === 7 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );
            let TrasferPromo = data.filter(
              (promo) =>
                promo.ServiceType === 5 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );

            setPromoCode(carPromo);
            setPromoCodes(TrasferPromo);
          }
        }
      })
      .catch((error) => {
        setPromoCode([]);

        console.error(error);
      });
  };

  const handleApply = (code) => {
    if (user != null) {
      form.setFieldsValue({
        promo: code,
      });

      form.submit();
    } else {
      message.error("please Sign-In to Avail Offers")
    }
  };

  return (
    <>
      {selectedCarData.type !== "transfer" ? (
        <>
          <div className="busFareDetail_wrapper mb-0">
            <div class="charge-details">
              <p><span class="label">Base Fare:</span> <span class="value"> {activeCurrency}{" "}{currencyValue(selectedCarData.BaseFare) ?? "00"}</span></p>
              <p><span class="label">StateToll Tax:</span> <span class="value"> {activeCurrency}{" "}{currencyValue(selectedCarData.StateTollTax) ?? "00"}</span></p>
              <p><span class="label">GST Amount:</span> <span class="value"> {activeCurrency}{" "}{currencyValue(selectedCarData.GST) ?? "00"}</span></p>
              <p><span class="label">Driver Allowance:</span> <span class="value"> {activeCurrency}{" "}{currencyValue(selectedCarData.DriverAllowance) ?? "00"}</span></p>
            </div>
           
            
            {!agent && user?.Role?.RoleLevel !== 3 && (
              <>
                {convamount > 0 ?
                  <div className="price_wrapper">
                    <p className="text">Convenience Fee</p>
                    <p className="price">
                      {activeCurrency} {currencyValue(convamount)}
                    </p>
                  </div> : null}
                {promoData.status && (
                  <div className="price_wrapper">
                    <p className="text">Discount</p>
                    <p className="price">
                      - {activeCurrency}{" "}
                      {promoData.status ? currencyValue(discount) : " 0.00"}
                    </p>
                  </div>
                )}
              </>
            )}

           

          </div>

          <div className="busFareDetail_wrapper">
           

            <div className="price_wrapper">

              <p className="text" style={{ fontWeight: 'bold', fontSize: '20px', margin: '0px 0px 0px' }}>Grand Total:</p>

              <p style={{ color: "#bd0c21", fontWeight: 'bold', fontSize: '20px', margin: '0px 0px 0px' }} className="price">
                {activeCurrency}{" "}
                {totalFareCalculations
                  ? currencyValue(totalFareCalculations)
                  : ""}
              </p>
            </div>
          </div>
        </>
      ) : null}
      {selectedCarData.type === "transfer" ? (<>
        <div className="busFareDetail_wrapper mb-0">
          <div class="charge-details">
            <p><span class="label">Base Fare:</span> <span class="value"> {activeCurrency}{" "}{currencyValue(selectedCarData?.Vehicles[0]?.TransferPrice?.totalBasePrice) ?? "00"}</span></p>
            {selectedCarData.Vehicles[0]?.TransferPrice?.Tax > 0 ?
              <p><span class="label">StateToll Tax:</span> <span class="value"> {activeCurrency}{" "}{currencyValue(selectedCarData.Vehicles[0]?.TransferPrice?.Tax)}</span></p> : null}
           
          </div>
         

          
          {!agent && user?.Role?.RoleLevel !== 3 && (
            <>
              {convamount > 0 ?
                <div className="price_wrapper">
                  <p className="text">Convenience Fee</p>
                  <p className="price">
                    {activeCurrency} {currencyValue(convamount)}
                  </p>
                </div> : null}
              {promoData.status && (
                <div className="price_wrapper">
                  <p className="text">Discount</p>
                  <p className="price">
                    - {activeCurrency}{" "}
                    {promoData.status ? currencyValue(discount) : " 0.00"}
                  </p>
                </div>
              )}
            </>
          )}

         

        </div>
        <div className="busFareDetail_wrapper">
          <div className="price_wrapper">

            <p className="text" style={{ fontWeight: 'bold', fontSize: '20px', margin: '0px 0px 0px', whiteSpace: "nowrap" }}>Grand Total:</p>

            <p style={{ color: "#bd0c21", fontWeight: 'bold', fontSize: '20px', margin: '0px 0px 0px', whiteSpace: "nowrap" }} className="price">
              {activeCurrency}{" "}
              {currencyValue(Number(selectedCarData?.Vehicles[0]?.TransferPrice?.PublishedFares) + Number(convamount) - Number(discount))}
            </p>
          </div>
        </div>
      </>) : null}
      {selectedCarData.type !== "transfer" ? (
        <>
          {!agent && user?.Role?.RoleLevel !== 3 && isPromoVisible ? (
            <div className="buspromo_wrapper">
              {promoData.status == false ? (
                <div>
                  <p className="name">Apply Promo</p>
                  {user != null ?
                    <Form
                      layout="vertical"
                      form={form}
                      onFinish={(d) => {
                        validatePromoCode({
                          ServiceType: 7,
                          Amount: Number(totalServiceAmount),
                          PromoCode: d.promo,
                          UserId: user?.UserID ?? 1,
                          userMail: user?.Email,
                        });
                      }}
                    >
                      <Row gutter={[16, 16]}>
                        <Col md={16} sm={12} xs={12}>
                          <Form.Item name="promo">
                            <Input
                              className="inputbg"
                              placeholder="Enter Your Promo code"
                              autoComplete="off"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={12}>
                          <Form.Item>
                            <Button type="primary" onClick={() => form.submit()}>
                              Apply
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form> : <div>
                      <p>Please <span style={{ color: "blue", cursor: "pointer" }} onClick={() => showModal1("USER")}>Sign-In</span> to Avail Offers</p>
                    </div>
                  }
                  {promoCode.length ? (
                    promoCode.map((item, key) => {
                      return (
                        <>
                          <div className="promo-cp-coupons" key={key}>
                            <div className="inline-cp-promo">
                              <Form>
                                <Form.Item>
                                  <Radio
                                    onClick={() => handleApply(item.Code)}
                                  ></Radio>
                                </Form.Item>
                              </Form>
                              <p className="promo-key-cp">{item.Code}</p>
                              {item.DiscountType === 1 ? (
                                <p className="save-cp-offer">
                                  Save {item.Discount + " %"}
                                </p>
                              ) : (
                                <p className="save-cp-offer">
                                  Save {activeCurrency}&nbsp;
                                  {currencyValue(item.Discount)}
                                </p>
                              )}
                            </div>

                            <div className="promo-percentage-cp">
                              <p>{item.Description}</p>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <div className="promo-cp-coupons">
                      <div className="promo-percentage-cp pl-0 pt-0">
                        <p>No Promo Code Available</p>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <Card className="flight-cards-details">
                  <div className="promo-card-header">
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className="name"
                    >
                      Promo Coupon{" "}
                      <CloseOutlined
                        onClick={() => {
                          RemovePromo();
                          form.resetFields();
                        }}
                      />
                    </p>
                  </div>
                  <div className="promo-input">
                    <p className="mb-0">
                      <span className="applied"> {promoData.Code} </span> Promo Code
                      Applied
                    </p>
                  </div>
                </Card>
              )}
            </div>
          ) : null}
        </>
      ) : null}
      {selectedCarData.type === "transfer" ? (
        <>
          {!agent && user?.Role?.RoleLevel !== 3 && isPromoVisible ? (
            <div className="buspromo_wrapper">
              {promoData.status == false ? (
                <div>
                  <p className="name">Apply Promo</p>
                  {user != null ?
                    <Form
                      layout="vertical"
                      form={form}
                      onFinish={(d) => {
                        validatePromoCode({
                          ServiceType: 5,
                          Amount: Number(totalServiceAmount),
                          PromoCode: d.promo,
                          UserId: user?.UserID ?? 1,
                        });
                      }}
                    >
                      <Row gutter={[16, 16]}>
                        <Col md={16} sm={12} xs={12}>
                          <Form.Item name="promo">
                            <Input
                              className="inputbg"
                              placeholder="Enter Your Promo code"
                              autoComplete="off"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={12}>
                          <Form.Item>
                            <Button type="primary" onClick={() => form.submit()}>
                              Apply
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form> : <div>
                      <p>Please <span style={{ color: "blue", cursor: "pointer" }} onClick={() => showModal1("USER")}>Sign-In</span> to Avail Offers</p>
                    </div>}
                  {promoCodes.length ? (
                    promoCodes.map((item, key) => {
                      return (
                        <>
                          <div className="promo-cp-coupons" key={key}>
                            <div className="inline-cp-promo">
                              <Form>
                                <Form.Item>
                                  <Radio
                                    onClick={() => handleApply(item.Code)}
                                  ></Radio>
                                </Form.Item>
                              </Form>
                              <p className="promo-key-cp">{item.Code}</p>
                              {item.DiscountType === 1 ? (
                                <p className="save-cp-offer">
                                  Save {item.Discount + " %"}
                                </p>
                              ) : (
                                <p className="save-cp-offer">
                                  Save {activeCurrency}&nbsp;
                                  {currencyValue(item.Discount)}
                                </p>
                              )}
                            </div>

                            <div className="promo-percentage-cp">
                              <p>{item.Description}</p>
                            </div>
                          </div>
                        </>
                      );
                    })
                  ) : (
                    <div className="promo-cp-coupons">
                      <div className="promo-percentage-cp pl-0 pt-0">
                        <p>No Promo Code Available</p>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <Card className="flight-cards-details">
                  <div className="promo-card-header">
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className="name"
                    >
                      Promo Coupon{" "}
                      <CloseOutlined
                        onClick={() => {
                          RemovePromo();
                          form.resetFields();
                        }}
                      />
                    </p>
                  </div>
                  <div className="promo-input">
                    <p className="mb-0">
                      <span className="applied"> {promoData.Code} </span> Promo Code
                      Applied
                    </p>
                  </div>
                </Card>
              )}
            </div>
          ) : null}
        </>
      ) : null}
      <Modal
        centered
        visible={modalVisible.visible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        className="login-modal"
        footer={false}
      >
        <Login
          location={Loc}
          setModalVisible={(value) => setModalVisible(value)}
          type={modalVisible.type}
        />
      </Modal>
    </>
  );
};
export default FareDetails;
