import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import {
  PlusOutlined,
  EditOutlined,
  IdcardOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Form, Input, Modal, Select, Menu } from "antd";
import search from "../../assets/vector-icons/search.png";
import excel from "../../assets/vector-icons/excel.png";
import pdf from "../../assets/vector-icons/pdf.png";
import ApiClient from "../../../helpers/ApiClient";

const ManageGrades = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [modalVisible, setModalVisible] = React.useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [formManageGrades] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [gradesList, setGradesList] = useState([]);
  const [currentID, setCurrentID] = useState(-1);
  const [formLayout, setFormLayout] = React.useState("vertical");

  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 23 },
        }
      : null;

  const add = () => {
    ANTD.message.success("Buses Markup Added Successfully...", 3);
    setModalVisible(false);
  };
  const clear = () => {
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const closeModal = () => {
    setModalVisible({
      visible: false,
    });
  };
  const handleAddClick = () => {
    formManageGrades.resetFields();
    showModal();
    setIsEditMode(false);
  };
  const handleSubmit = (formvalues) => {
    if (isEditMode) {
      submitUpdateDepartment(formvalues);
    } else {
      submitNewDepartment(formvalues);
    }
  };
  const submitNewDepartment = (formvalues) => {
    ApiClient.post("CorporateExtranet/CreateGrades", formvalues).then(
      (response) => {
        if (response.status === 200) {
          setModalVisible(false);
          ANTD.message.success("Grades created successfully");
          getAllGrades();
          formManageGrades.resetFields();
        } else {
          ANTD.message.error(" Error Creating Grades");
        }
      }
    );
  };
  useEffect(() => {
    getAllGrades();
  }, []);
  const getAllGrades = () => {
    ApiClient.get("CorporateExtranet/GetGrades")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setDataSource(data);
          setGradesList(data.length);
        }
      })
      .catch((error) => {});
  };
  const columns = [
    {
      title: "Grades",
      dataIndex: "Grade",
    },
    {
      title: "Description",
      dataIndex: "Description",
    },
    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];
  const handelStatus = (data) => {
    if (data.Status == 1) {
      return (
        <ANTD.Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <ANTD.Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };

  const changeStatus = (info) => {
    // let obj = {
    //   Id: info.CarTypeID,
    //   status: Status,
    // };
    // ApiClient.put("carextranet/toggleCarTypeStatus/" + info.CarTypeID)
    //   .then((response) => {
    //     if (response.status == 200) {
    //       message.success("Car Type Status updated successfully !");
    //       getAllCarType();
    //     } else {
    //       message.error(response.message, 3);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };
  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              handleEditClick(rec);
              setCurrentID(rec.GradeId);
            }}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteGrades(rec.GradeId)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };
  const deleteGrades = (id) => {
    ApiClient.delete("CorporateExtranet/DeleteGrades/" + id).then((res) => {
      if (res.status == 200) {
        ANTD.message.success("Grade Deleted  successfully", 3);
        getAllGrades();
      }
    });
  };
  const handleEditClick = (rec) => {
    formManageGrades.setFieldsValue({
      Grade: rec.Grade ?? "",
      Description: rec.Description ?? "",
      Status: rec.Status ?? "",
    });

    setIsEditMode(true);

    showModal();
  };
  const submitUpdateDepartment = (formvalues) => {
    const updatedFormData = {
      ...formvalues,
    };
    ApiClient.put(
      "CorporateExtranet/UpdateGrades/" + currentID,
      {},
      updatedFormData
    ).then((response) => {
      // console.log(response);
      if (response.status === 200) {
        setModalVisible(false);
        ANTD.message.success("Grade updated successfully");
        getAllGrades();
        formManageGrades.resetFields();
      } else {
        ANTD.message.error(" Error Updating Grade");
      }
    });
  };
  return (
    <div>
      <div className="promo providerapi">
        <ANTD.Layout>
          <div className="manage-markup-section w-100">
            <Content className="admin-container">
              <ANTD.Row>
                <ANTD.Col span={24}>
                  <ANTD.Card>
                    <div className="card-add-heading">
                      <div className="rows-count">
                        <div>
                          <h5>Manage Grades</h5>
                          <p>{gradesList ?? 0} rows found !</p>
                        </div>
                      </div>
                      <div
                        className="exlpdf"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <img
                          src={search}
                          alt="search"
                          style={{ maxHeight: "35px", maxWidth: "35px" }}
                        />
                        <img
                          src={excel}
                          alt="excel"
                          style={{ maxHeight: "35px", maxWidth: "35px" }}
                        />
                        <p
                          className="add-icon"
                          onClick={() => handleAddClick()}
                        >
                          <PlusOutlined />
                        </p>
                      </div>
                    </div>

                    <ANTD.Table
                      className="table-scroll-none"
                      style={{ color: "red" }}
                      columns={columns}
                      dataSource={dataSource}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </ANTD.Card>
                </ANTD.Col>
              </ANTD.Row>
            </Content>
          </div>
        </ANTD.Layout>

        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>Manage Grades</strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header modal-header-bg12"
          visible={modalVisible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          footer={[
            <div>
              <ANTD.Button
                type="primary"
                onClick={() => formManageGrades.submit()}
              >
                {isEditMode ? "Update" : "Add"}
              </ANTD.Button>
              <ANTD.Button
                type="primary"
                danger
                className="cancel-btn"
                onClick={() => formManageGrades.resetFields()}
              >
                Clear
              </ANTD.Button>
            </div>,
          ]}
        >
          <ANTD.Form
            {...formItemLayout}
            form={formManageGrades}
            layout={formLayout}
            initialValues={{ layout: formLayout }}
            onFinish={handleSubmit}
          >
            <ANTD.Row>
              <ANTD.Col md={12} xs={24}>
                <ANTD.Form.Item
                  name="Grade"
                  label="Grade"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Grade!",
                    },
                  ]}
                >
                  <ANTD.Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Grade"
                  />
                </ANTD.Form.Item>
              </ANTD.Col>

              <ANTD.Col md={12} xs={24}>
                <ANTD.Form.Item
                  name="Description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Description!",
                    },
                  ]}
                >
                  <ANTD.Input
                    prefix={<EditOutlined className="site-form-item-icon" />}
                    placeholder="Description"
                  />
                </ANTD.Form.Item>
              </ANTD.Col>
              <ANTD.Col xs={24} md={12}>
                <ANTD.Form.Item
                  name="Status"
                  label="Status"
                  rules={[
                    { required: true, message: "Please Select Your Status" },
                  ]}
                >
                  <ANTD.Select
                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                    placeholder="Select Status"
                  >
                    <ANTD.Select.Option value={0}>yes</ANTD.Select.Option>
                    <ANTD.Select.Option value={1}>No</ANTD.Select.Option>
                  </ANTD.Select>
                </ANTD.Form.Item>
              </ANTD.Col>
            </ANTD.Row>
          </ANTD.Form>
        </Modal>
      </div>
    </div>
  );
};
export default ManageGrades;
