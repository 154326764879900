  /* import * as ReactBoostrap from "react-bootstrap"; */
import { SearchOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Layout, Row, Select, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useSytContext } from "../../../common/providers/SytProvider";
import Banner from "../../../components/banner/Banner";
import ApiClient from "../../../helpers/ApiClient";
import "../Visasearch/Visasearch.scss";
import queryString from "query-string";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { VisaSearchPanel } from "./visaSearchPanel";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import OffersSlider from "../../../common/LandingPageOffers/LandingPageOffers";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;

const BASE = process.env.REACT_APP_BASE_URL;

const VisaSearch = (props) => {
  const [DataSource, setDataSource] = useState([]);
  const { activeCurrency, currencyValue } = useCurrencyContext();

  const [promoSource, setPromoSource] = useState([]);

  const { visaBanners, promoDataSource } = useSytContext();

  const [recentSearchResults, setRecentSearchResults] = useState([]);

  const getallVisaRecords = () => {
    ApiClient.get("visa/getAllManageMasterVisa").then((res) => {
      if (res.status == 200) {
        setDataSource(res.data);
      }
    });
  };

  const CarouselNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-table", background: "#bd0c21" }}
        onClick={onClick}
      />
    );
  };
  const CarouselPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-table", background: "#bd0c21" }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    infinite: true,
    className: "center",
    centerMode: true,
    slidesToShow: 1,
    // autoplay: true,
    autoplaySpeed: 2000,
    rows: 2,
    // slidesPerRow: 2,
    infinite: true,
    pauseOnHover: true,
    // arrows: true,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
  };

  useEffect(() => {
    getallVisaRecords();
    if (promoDataSource.length) {
      let data = promoDataSource.filter((item) => item.ServiceType === 8);
      setPromoSource(data);
    }
  }, [promoDataSource]);

  useEffect(() => {
    let data = sessionStorage.getItem("VisaRecentSearcheResults");

    if (data.length) {
      setRecentSearchResults(JSON.parse(data));
    }
  }, []);
  return (
    <div className="holidays_search_container_1 visa-content-search">
      <div className="visa-block">
        <div className="visa-banner">
          <Banner banner={visaBanners} />
          {/* <Banner banner={5} /> */}

        </div>
      </div>

      <div className="promo providerapi visa-search-1">
        <Layout className="holidays-search-apps">
          <div className="visaSearch visapanel-bg">
            <VisaSearchPanel />
          </div>

          {recentSearchResults.length ? (
            <section className="home-best-24 recentSearchesRow">
              <div className="container">
                <Row
                  gutter={16}
                  className="justify-content-end align-items-center"
                >
                  <Col md={4} xs={24}>
                    <p className="m-0 text-right">Recent Searches: </p>
                  </Col>
                  {recentSearchResults.map((item, i) => {
                    let newString = queryString.stringify(item);

                    return (
                      <Col md={4} xs={24}>
                        <div key={i + "recentresults"}>
                          <Link to={`visa/result?${newString}`}>
                            <div
                              style={{
                                boxShadow: "0 1px 5px black",
                                borderRadius: "6px",
                                textAlign: "center",
                                overflow: "hidden",
                              }}
                            >
                              <div className="ducarosuel">
                                <div className="city-due ">
                                  <i
                                    className="fa fa-globe iconsDiv pr-2"
                                    aria-hidden="true"
                                  ></i>

                                  {item.country}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            </section>
          ) : (
            ""
          )}
          <div className="">
            <Content className="admin-container cms-pages-width">
              <h4 className="visa-poplar mt-3" style={{color:"#bd0c21", fontSize:"30px"}}>Popular Visa's</h4>

              {/*--start----end--*/}

              <Row>
                {DataSource.length
                  ? DataSource.filter((item, index) => index <= 5).map(
                      (item, ind) => {
                        return (
                          <Col md={6} xs={24} className="mb-3" style={{position:"relative",zIndex:"60"}}>
                            <Link to={`/visa/result?country=${item.Country}`}>
                              <Card
                                className="visa-card orange-color"
                                bordered={false}
                              >
                                {item.Images.length ? (
                                  <div className="visImage">
                                    <img
                                      src={
                                        BASE + item.Images[0].Image.substring(1)
                                      }
                                    />
                                  </div>
                                ) : (
                                  <div className="visImage">
                                    <img src="" />{" "}
                                  </div>
                                )}
                                <div className="visa-content mt-2">
                                  <h3 className="visa-pkg-name">
                                    {item.VisaName}
                                    <span>{item.Continent}</span>
                                  </h3>
                                  <p className="visa-pkg-price">
                                    {activeCurrency} {currencyValue(item.Fees)}/-
                                  </p>
                                </div>
                              </Card>
                            </Link>
                          </Col>
                        );
                      }
                    )
                  : ""}

               
              </Row>
            </Content>
          </div>
        </Layout>
      </div>

      {/* --first---layout--close-- */}



      <section className="top-route-bb">
        {promoSource.length ? (
          <OffersSlider dataSource={promoSource} serviceType="Visa" />
        ) : (
          ""
        )}
      </section>
    </div>
  );
};

export default VisaSearch;
