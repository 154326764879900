import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Card, Col, Layout, Row, Skeleton } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import VisaIcon from "../../../assets/images/travel.png";
import "../Busticketsuccess/Busticketsuccess.scss";
const { Content } = Layout;

const VisaTicketSuccess = ({ visaTicketDetails }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (visaTicketDetails?.ticketAllData.hasOwnProperty("ReferenceNumber")) {
      setLoading(false);
    }
    setLoading(false);
  }, [visaTicketDetails]);
  return (
    <Content className="admin-container cms-pages-width retrieve-linebar">
      <Card className="retrieve-booking busmtb">
        <Row gutter={[16, 16]}>
          <Col lg={12} md={16} sm={16} xs={24}>
            <h4 className="ticket-lti-bus">Visa Itinerary</h4>

            {loading ? (
              <Skeleton active paragraph={{ rows: 2 }} />
            ) : visaTicketDetails.ticketAllData.hasOwnProperty(
                "ReferenceNumber"
              ) ? (
              <div className="fic-tickets-data-bus">
                <div className="bd-bus-info">
                  <Row gutter={[8, 8]}>
                    <Col md={12} sm={24} xs={24}>
                      <p className="travel-bus">
                        <strong>Visa Name: </strong>{" "}
                        {visaTicketDetails?.ticketAllData?.visaName}
                      </p>
                    </Col>
                    <Col md={12} sm={12} xs={24}>
                      <div className="tic-indi-details-bus">
                        <span>
                          <strong>Country: </strong>
                          {visaTicketDetails?.ticketAllData?.visa?.Country}
                        </span>
                      </div>
                    </Col>
                    <Col md={12} sm={12} xs={24}>
                      <div>
                        <strong>
                          Booking Date:{" "}
                          <span>
                            {moment(
                              visaTicketDetails?.ticketAllData?.createdDate
                            ).format("DD-MMM-YYYY")}
                          </span>
                        </strong>
                      </div>
                    </Col>
                  </Row>
                </div>

                <Row gutter={[8, 8]}>
                  <Col md={3} sm={6} xs={24}>
                    <img
                      className="bus-success-pay"
                      src={VisaIcon}
                      alt="image bus "
                    />
                  </Col>

                  {/* <Col md={12} sm={18} xs={24}>
                    <div className="airline-dte-tic-bus">
                      <div className="points">
                        <p className="title">Boarding point</p>
                        <p>{visaTicketDetails?.ticketAllData?.PickUpAddress}</p>
                      </div>

                      <ArrowRightOutlined />

                      <div className="points">
                        <p className="title">Dropping point</p>
                        <p>{visaTicketDetails?.ticketAllData?.DropAddress}</p>
                      </div>
                    </div>
                  </Col> */}

                  <Col md={21} sm={24} xs={24}>
                    <p className="pnr">
                      PNR:{" "}
                      {
                        visaTicketDetails?.ticketAllData
                          ?.BookingConfirmationNumber
                      }
                    </p>
                    <p className="pnr">
                      Reference Number:{" "}
                      {visaTicketDetails?.ticketAllData?.ReferenceNumber}
                    </p>
                    <p className="pnr">
                      Status:{" "}
                      {visaTicketDetails?.ticketAllData?.BookingStatus === 3
                        ? "SUCCESS"
                        : visaTicketDetails?.ticketAllData?.BookingStatus === 2
                        ? "Blocked"
                        : ""}
                    </p>
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="fic-tickets-data-bus">
                {visaTicketDetails.errorMessage}
              </div>
            )}
          </Col>

          <Col lg={12} md={8} sm={8} xs={24} className="flight-email-dd-bus">
            {loading ? (
              <Skeleton active paragraph={{ rows: 2 }} />
            ) : visaTicketDetails.ticketAllData.hasOwnProperty(
                "ReferenceNumber"
              ) ? (
              <>
                <div className="paymet-booking-tic">
                  <h4>Visa Booking Payment Successful</h4>
                  <p>
                    A confirmation email has been sent your provided email
                    address
                  </p>
                  <p>
                    Ref-No : {visaTicketDetails?.ticketAllData?.ReferenceNumber}
                  </p>
                  <p>
                    Status : <span className="succes-pay-value">Success</span>
                  </p>
                </div>

                <Row>
                  <Col lg={12} md={18} xs={24}>
                    <Button className="tic-retrive-book" htmlType="submit">
                      <Link
                        to={`visa/ticket?ref=${visaTicketDetails?.ticketAllData?.ReferenceNumber}`}
                      >
                        Retrieve Booking
                      </Link>
                    </Button>
                  </Col>
                </Row>
              </>
            ) : null}
          </Col>
        </Row>
      </Card>
    </Content>
  );
};

export default VisaTicketSuccess;
