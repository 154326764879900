import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from "../common/providers/AuthProvider";
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const {
    isLogin: { flag, agent },
    accessToken,
  } = useAuthContext();
  return (
    <Route
      {...rest}
      render={(props) => {
        return (flag || agent) && accessToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/unauthorized",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default ProtectedRoute;
