import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import "rc-datepicker/lib/style.css";
import React, { useEffect, useState } from "react";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import ApiClient from "../../helpers/ApiClient";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";

import search from "../assets/vector-icons/search.png";
import { Logos } from "../ManageVisamaster/Managervisamaste";
import "./VisaType.scss";

const BASE = process.env.REACT_APP_BASE_URL;

const VisaType = () => {
  const { Content } = Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [RecID, setId] = useState(-1);
  const [form] = Form.useForm();
  const [visaimgList, setVisaImagesList] = useState([]);

  const { TextArea } = Input;

  const [countryLists, setCountryList] = useState([]);

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const handleAddClick = () => {
    form.resetFields();
    showModal();
    setIsEditMode(false);
  };

  const handleEditClick = (rec) => {
    setVisaImagesList([]);
    let array = [];
    if (rec.Images) {
      for (let i = 0; i < rec.Images.length; i++) {
        array.push({
          uid: rec.Images[i].Id,
          name: "image.jpg",
          status: "done",
          url: BASE + rec.Images[i].substring(1),
          response: {
            status: 200,
            data: {
              filepaths: [rec.Images[i]],
            },
          },
        });
      }
      setVisaImagesList(array);
    }

    form.setFieldsValue({
      visaType: rec.visaType,
      aboutVisaType: rec.aboutVisaType,
      status: rec.status,
      countryName: rec.countryName,
      Images: { fileList: array },
    });

    setId(rec.Id);
    setIsEditMode(true);
    showModal();
  };

  const [dataSource, setDataSource] = useState([]);

  const getAllVisaTypes = () => {
    ApiClient.get("visa/getVisaType").then((res) => {
      if (res.status == 200) {
        setDataSource(res.data);
      }
    });
  };
  useEffect(() => {
    getAllVisaTypes();
    getCountriesLists();
  }, []);

  const submitVisaType = (formvalues) => {
    ApiClient.post("visa/addVisaType", formvalues).then((response) => {
      if (response.status === 200) {
        setModalVisible(false);
        message.success(response.message);
        setVisaImagesList([]);

        getAllVisaTypes();
        form.resetFields();
      } else {
        message.error(response.message);
      }
    });
  };

  const getCountriesLists = () => {
    ApiClient.get("admin/country")
      .then((res) => {
        if (res.status == 200) {
          setCountryList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSubmit = (formvalues) => {
    if (formvalues.Images.fileList.length > 0) {
      let array = [];

      for (let i = 0; i < formvalues.Images.fileList.length; i++) {
        if (formvalues.Images.fileList[i].response.status === 200) {
          array.push(formvalues.Images.fileList[i].response.data.filepaths[0]);
        }
      }
      if (array.length > 0) {
        formvalues.Images = array;
      }
    }
    if (isEditMode) {
      submitUpdateVisaType(formvalues);
    } else {
      submitVisaType(formvalues);
    }
  };

  const submitUpdateVisaType = (formvalues) => {
    const updatedFormData = {
      ...formvalues,
    };

    ApiClient.put("visa/updateVisaType/" + RecID, {}, updatedFormData).then(
      (response) => {
        if (response.status === 200) {
          setModalVisible(false);
          message.success(response.message);
          setVisaImagesList([]);

          getAllVisaTypes();
          form.resetFields();
        } else {
          message.error(response.message);
        }
      }
    );
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const deleteVisaType = (Id) => {
    ApiClient.delete("visa/deleteVisaType/" + Id).then((res) => {
      if (res.status == 200) {
        message.success(res.message, 3);
        getAllVisaTypes();
      }
    });
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => handleEditClick(rec)}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteVisaType(rec.Id)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const { Option } = Select;

  const columns = [
    {
      title: "Type",
      dataIndex: "visaType",
    },

    {
      title: "Information",
      dataIndex: "aboutVisaType",
    },
    {
      title: "Country Name",
      dataIndex: "countryName",
    },

    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];

  const handelStatus = (data) => {
    if (data.status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      );
    } else {
      return <Switch checkedChildren="Active" unCheckedChildren="Inactive" />;
    }
  };

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Visa Type{" "}
                          <HelpInfoHelper screenName={"/admin/cartypes"} />
                        </h5>
                        <p></p>
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />
                      <img src={excel} alt="excel" />
                      <img src={pdf} alt="pdf" />
                      <p className="add-deposit-icon" onClick={handleAddClick}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>

                  {dataSource.length ? (
                    <div>
                      <Table
                        className="table-scroll-none"
                        bordered
                        dataSource={dataSource}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  ) : (
                    <b>No record Found</b>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title="Visa Type"
        className="promo-modal-header cartypes-value"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <Form
          layout="vertical"
          name="basic"
          form={form}
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Country"
                rules={[{ required: true }]}
                name="countryName"
              >
                <Select showSearch>
                  {countryLists.length ? (
                    countryLists.map((item, index) => {
                      return (
                        <Option value={item.Country}>{item.Country}</Option>
                      );
                    })
                  ) : (
                    <Option value="">No Country Found</Option>
                  )}
                </Select>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Visa Type"
                rules={[{ required: true }]}
                name="visaType"
              >
                <Input placeholder="Please Enter Visa Type" />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item label="About Visa Type" name="aboutVisaType">
                <TextArea
                  placeholder="Please Enter Information About Visa Type"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Logos
                category="visimage"
                fileList={visaimgList}
                setFileList={setVisaImagesList}
                formName="Images"
                limit={true}
                limitSize={1}
              />
            </Col>

            <Col md={12} xs={24}>
              <Form.Item label="Status" name="status">
                <Radio.Group
                  rules={[{ required: true, message: "Please choose Status!" }]}
                >
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>In Active</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
                <Button type="primary" htmlType="submit">
                  {isEditMode ? "Update" : "Add"}
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    form.resetFields();
                  }}
                  danger
                  className="cancel-btn"
                >
                  Clear
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default VisaType;
