import React, { useState } from "react";
import * as ReactBoostrap from "react-bootstrap";
import * as ANTD from "antd";
import {
  PlusCircleFilled,
  UploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import "./PackagesDetailed.scss";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
// import word from "../assets/vector-icons/word.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
//import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
//import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
//import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import { useEffect } from "react";
import moment from "moment";

const PackagesDetailed = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const date = new Date();
  const [updateModal, setUpdateModal] = React.useState(false);
  const [deleteModal, setdeleteModal] = React.useState(false);
  const [moreInfoModal, setInfoModal] = React.useState(false);
  const { TextArea } = ANTD.Input;

  const handleClose = () => setUpdateModal(false);
  const handleShow = () => setUpdateModal(true);
  const handleInfoShow = () => setInfoModal(true);
  const handleInfoClose = () => setInfoModal(false);
  const [imgUrl, setImgUrl] = useState();
  const [loading, setLoading] = useState(false);

  // const toggle = () => {
  //     setUpdateModal(!updateModal)
  // }
  const deleteToggle = () => {
    setdeleteModal(!deleteModal);
  };
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 23 },
  };
  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  /***** Radio-btn*****/
  const [discountBtn, setDiscountBtn] = useState(1);
  // onChange = e => {
  //     setDiscountBtn(e.target.value);
  // };
  /****** User Info ******/
  const [userInfo, setUserInfo] = useState(false);

  const openUserInfo = () => {
    setUserInfo(true);
  };

  const handleOk = (e) => {
    setUserInfo(false);
  };

  const handleCancel = (e) => {
    setUserInfo(false);
  };

  let onChange = (jsDate, dateString) => {};
  const tableActions = () => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={showModal}
            style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
          />
          {/* <InfoCircleOutlined onClick={openUserInfo} style={{ color: '#bd0c21', fontSize: '20px', textAlign: 'center', marginLeft: "25px" }} /> */}
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm title="Are you sure to delete?">
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };
  const { Option } = ANTD.Select;
  const prefixSelector = (
    <ANTD.Form.Item name="prefix" noStyle>
      <ANTD.Select style={{ width: 80 }} placeholder="+91">
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </ANTD.Select>
    </ANTD.Form.Item>
  );
  const config = {
    rules: [{ type: "object", required: true, message: "Please select time!" }],
  };

  const dataSource = [
    {
      dayWise: "Day1",
      location: "Hyderabad",
      description: "loremIpsum",
      distance: "240 k.m",
      travelTime: "2h 29-03-2021",
      availability: "No",
      created: "19-10-2020 06:58:20",
      modified: "19-10-2020 06:58:20",
      actions: tableActions(),
      /* delete: tableDelete(), */
    },
    {
      dayWise: "Day2",
      location: "Hyderabad",
      description: "loremIpsum",
      distance: "250 k.m",
      travelTime: "2h 30-11-2020",
      availability: "Yes",
      created: "19-10-2020 06:58:20",
      modified: "19-10-2020 06:58:20",
      actions: tableActions(),
      /* delete: tableDelete(), */
    },
    {
      dayWise: "Day3",
      location: "Warangal",
      description: "loremIpsum",
      distance: "260 k.m",
      travelTime: "2h 30-11-2020",
      availability: "No",
      created: "19-10-2020 06:58:20",
      modified: "19-10-2020 06:58:20",
      actions: tableActions(),
      /* delete: tableDelete(), */
    },
  ];

  const columns = [
    // {
    //     title: 'Category Id',
    //     dataIndex: 'categoryId'
    // },
    {
      title: "Day",
      dataIndex: "dayWise",
    },
    {
      title: "Location",
      dataIndex: "location",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Distance",
      dataIndex: "distance",
    },
    {
      title: "Travel time from hotel",
      dataIndex: "travelTime",
    },
    {
      title: "Any car available ",
      dataIndex: "availability",
    },
    {
      title: "Created date",
      dataIndex: "created",
    },
    {
      title: "Modified date ",
      dataIndex: "modified",
    },
    {
      title: "Status",
      dataIndex: "no",
      key: "airline",
      render: (text, order) => (
        <ANTD.Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      ),
    },
    {
      dataIndex: "actions",
      title: "Actions",
    },
    /*  {
             title: 'Delete',
             dataIndex: 'delete'
 
         } */
  ];

  /***** Radio-btn*****/
  const [statusBtn, setStatusBtn] = useState(1);
  onChange = (e) => {
    setStatusBtn(e.target.value);
  };
  /****** Image Upload ******/
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImgUrl(imageUrl),
        setLoading(false)
      );
    }
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      ANTD.message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      ANTD.message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  const imageUrl = imgUrl;
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  /***** Date And Time Pick ****/

  function onDatePick(date, dateString) {
    console.log(date, dateString);
  }

  function onTimePick(time, timeString) {
    console.log(time, timeString);
  }
  /***** End of Date And Time Pick ****/

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    {/* <div className="table-heading-count"> */}
                    <div className="rows-count">
                      <div>
                        <h5>View Package details</h5>
                        <p>3 rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />
                      <img src={excel} alt="excel" />
                      <img src={pdf} alt="pdf" />
                      {/* <img src={word} alt="word" /> */}

                      {/*  <img src={exchange} alt="exchange" /> */}
                      <p className="add-deposit-icon" onClick={showModal}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                    {/* </div> */}
                  </div>

                  <div>
                    {/* onChange={onChange} expandedRowRender= {rowExpand}*/}
                    <ANTD.Table
                      className="table-scroll-none table-data"
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>

      <ANTD.Modal
        title="Add package details"
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <ANTD.Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <ANTD.Row gutter={16}>
            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item name={["user", "name"]} label="Day">
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item name={["user", "name"]} label="Location">
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item label="Description">
                <TextArea />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item label="Distance">
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item label="Travel time from hotel">
                <ANTD.DatePicker onChange={onDatePick} />
                <ANTD.TimePicker
                  onChange={onTimePick}
                  defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                  style={{ marginLeft: "10px" }}
                />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item label="Any car available">
                <ANTD.Radio.Group onChange={onChange} value={statusBtn}>
                  <ANTD.Radio value={1}>Yes</ANTD.Radio>
                  <ANTD.Radio value={2}>No</ANTD.Radio>
                </ANTD.Radio.Group>
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Form.Item
            {...tailLayout}
            style={{ marginBottom: 0 }}
            className="modal-btns"
          >
            <ANTD.Button type="primary" htmlType="submit">
              Add
            </ANTD.Button>
            <ANTD.Button type="primary" htmlType="submit" className="edit-btn">
              Update
            </ANTD.Button>
            <ANTD.Button type="primary" danger className="cancel-btn">
              Clear
            </ANTD.Button>
          </ANTD.Form.Item>
        </ANTD.Form>
      </ANTD.Modal>
      <ANTD.Modal
        title="Basic Modal"
        visible={userInfo}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </ANTD.Modal>
    </div>
  );
};

export default PackagesDetailed;
