import React, { useState, useCallback, useEffect } from "react";
import { Spin, Select } from "antd";
import APIClient from "../../helpers/ApiClient";
import { Form } from "antd";
import defaultimage from "../../assets/images/Icons/defaultflagicon.png";


const API_URL = "extranet/airport/search/";

const FlightExtranetAirportAutoComplete = (props) => {
    useEffect(() => {
        let value = "";
        if (props.isfieldKey) {
            value = props.refName.current[props.fieldKey].props.value;
        } else {
            value = props.refName.current.props.value;
        }

        if (!value || value == "" || value == " ") {
            return;
        }

        fetchData(value);
    }, [
        props.isfieldKey
            ? props.refName.current[props.fieldKey]
            : props.refName.current,
        props.dataFlip,
    ]);

    const check = () => {
        if (props.focusRef) {
            if (props.isfieldKey)
                props.handleOnSubmit(props.focusRef, props.isfieldKey, props.fieldKey);
            else props.handleOnSubmit(props.focusRef);
        }
    };
    const { Option } = Select;
    const [options, setOptions] = useState({
        data: [],
        value: [],
        fetching: false,
    });
    const debounceOnChange = useCallback(debounce(fetchData, 400), []);

    const createOptions = (results) => {
        let arr = [];

        results.forEach((result) => {
            arr.push({
                value: result._source.code,
                display: result._source.suggestionWithTags,
                _class: "parent",
                label: result.suggestion,
            });

            //   if (result._type == "multiport") {
            //     result._source.airports_data.forEach((result_2) => {
            //       arr.push({
            //         value: result_2._source.code,
            //         display: result_2._source.suggestionWithTags,
            //         _class: "child",
            //         label: result_2.suggestion,
            //       });
            //     });
            //   }

        });

        return arr;
    };

    function fetchData(value) {
        if (value == "" || value == " ") {
            return;
        }

        setOptions({ data: [], fetching: true });
        APIClient.get(`${API_URL}${value}`)
            .then((res) => {
                if (res.statusCode == 200 && res) {
                    setOptions({ data: res.data, fetching: false });
                    return;
                }
            })
            .catch((error) => {
                console.error("error", error);
                setOptions({ data: [], fetching: false });
            });
    }

    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            const context = this;
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        };
    }

    const getflag = (code) => {
        
        if (code === "yu" || code === "tp") {
            let codeflag = defaultimage;
            return codeflag;
        } else {
            let flag = require(`../../assets/country-flag-svg/${code}.svg`).default;
           
            return flag;
        }
    }

    return (
        <Form.Item
            {...props.formItemProps}
            label={props.label}
            className="airport_auto_complete notranslate"
        >
            <Select
                style={{ width: "100%" }}
                showSearch
                ref={(el) => {
                    props.isfieldKey
                        ? (props.refName.current[props.fieldKey] = el)
                        : (props.refName.current = el);
                }}
                // value={details.value}
                notFoundContent={options.fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={debounceOnChange}
                onChange={props.onChange}
                {...props.selectProps}
                onSelect={check}
                dropdownClassName={"airpot_names_dropdown"}
                dropdownStyle={{ width: "100%" }}
                className="notranslate"
            >
                {options.data.map((d, index) => (
                    <Option
                        className="notranslate"
                        value={d.displayValue}
                        key={"key" + index}
                    >
                        <div className="d-flex align-items-center justify-content-between  ">
                            <div>
                                {" "}
                                <p className="m-0">
                                    {d.City}, {d.Country}
                                </p>
                                <p
                                    className="m-0 city-column"
                                    style={{ fontSize: "11px", color: "#9B9B9B" }}
                                >
                                    {d.AirportDesc}
                                </p>
                            </div>
                            <div className="city-column">
                                <p className="m-0" style={{ color: "#9B9B9B" }}>
                                    <span> {d.AirportCode}</span>
                                    &nbsp;
                                    <img
                                        src={d?.CountryCode ? getflag(d?.CountryCode?.toLowerCase()) : ""}
                                        width="20"
                                        alt={d?.CountryCode?.toLowerCase()}></img>
                                </p>
                            </div>
                        </div>
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default FlightExtranetAirportAutoComplete;
